import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const DisplayInputWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${LightTheme.spaceSizes.m};
`;

export const AddressValueWrapper = styled.div`
  font-weight: bold;
`;

export const DetailsValueWrapper = styled.div`
  font-size: ${LightTheme.spaceSizes.m};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${LightTheme.spaceSizes.m};
`;

export const InputLabel = styled.label`
  font-weight: bold;
  margin-bottom: ${LightTheme.spaceSizes.s};
`;

export const AddressInputWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
`;

export const AddressSuggestionList = styled.ul`
  padding: ${LightTheme.spaceSizes.s} 0;
  z-index: 100;
  list-style-type: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  min-width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  background-color: ${LightTheme.color.white};
  box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
`;

export const AddressSuggestion = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 5px ${LightTheme.spaceSizes.l};
  &:hover {
    background-color: ${LightTheme.color.gray_lightest};
  }
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;

export const PinContainer = styled.span`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    height: 55px;
  }
`;

export const RequiredLabel = styled(InputLabel)`
  &::before {
    content: "* ";
    color: ${LightTheme.color.error};
    font-weight: 300;
  }
`;
