import { DEFAULT_MPS } from 'helpers/constants/variantForm';
import { errorConfig } from './Errors/development.errors';

const config = {
  googleMapsConfig: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    mapId: {
      addressMap: 'd4af7a15d73d7522',
    },
    defaultLocation: {
      lat: 4.655870259288558,
      lng: -74.11303877670542,
    },
    pandasWarehouses: [
      {
        address: 'Autopista Medellin KM 2 800 M Via Parcelas',
        details: 'Bodega 7',
        latitude: '4.7524866093950076',
        longitude: '-74.13645209165763',
        cityCode: 'CUN_COTA',
        cityName: 'Cota',
        departmentCode: 'CUN',
        departmentName: 'Cundinamarca',
      },
    ],
    placesRange: 100,
  },
  defaultPandasMps: {
    _id: DEFAULT_MPS ?? '',
    address: process.env.REACT_APP_DEFAULT_MPS_ADDRESS,
    name: process.env.REACT_APP_DEFAULT_MPS_NAME,
    contactName: process.env.REACT_APP_DEFAULT_MPS_CONTACT_NAME,
    document: process.env.REACT_APP_DEFAULT_MPS_DOCUMENT,
    email: process.env.REACT_APP_DEFAULT_MPS_EMAIL,
    logisticType: process.env.REACT_APP_DEFAULT_MPS_LOGISTIC_TYPE,
    phoneNumber: process.env.REACT_APP_DEFAULT_MPS_PHONE_NUMBER,
  },
  httpPassword: process.env.REACT_APP_API_STOCK_HTTP_PASSWORD ?? '',
  errorConfig,
  stockCsvUpload: {
    actionURL: `${process.env.REACT_APP_API_URL}/stock-dom/stock/${process.env.REACT_APP_PANDAS_DEFAULT_WAREHOUSE}/batch-update`,
  },
  availableSetCsvUpload: {
    actionURL: `${
      process.env.REACT_APP_API_URL
    }/unit-dom/unit/massive-available-set?warehouseId=${
      process.env.REACT_APP_PANDAS_DEFAULT_WAREHOUSE
    }&temporaryId=${
      process.env.REACT_APP_PANDAS_DEFAULT_TEMPORARY ??
      'd378c7ca-a51a-41d9-a977-69fbecdbd22d'
    }`,
  },
  transferCsvUpload: {
    actionURL: `${process.env.REACT_APP_API_URL}/transfer-dom/transfer/csv`,
  },
  variantCsvUpload: {
    actionURL: `${process.env.REACT_APP_API_URL}/variant-csv-dom/product-variant/catalog-csv`,
  },
  routesCsvUpload: {
    actionURL: `${process.env.REACT_APP_API_URL}/routes-dom/routes/csv`,
  },
  pandabonos: {
    actionURL: `${process.env.REACT_APP_API_URL}/finance-dom/pandabonos/upload`,
    downloadTemplateUrl: `${process.env.TEMPLATE_URL}`,
  },
  purchaseRemission: {
    baseURL: `${process.env.REACT_APP_API_URL}/purchase-dom/purchase`,
  },
};

export default config;
