import { useLoadScript } from '@react-google-maps/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import config from 'config';
import { PandasError } from 'config/Errors/error';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { Order, PaymentOptionsKeys } from 'services/order/interface';
import {
  computeOrderTotals,
  createOrder,
  getOrderById,
  updateOrder,
  updateOrderPayment,
  updateOrderStock,
} from 'services/order/order.service';
import { getPandabonosById } from 'services/pandabonos/pandabonos.service';
import { getParameters } from 'services/parameters/parameters.service';
import { getDeliveryDates } from 'services/shipping/shipping.service';

interface Props {
  id?: string;
  orderValues: Order;
  isUpdate?: boolean;
}

const useWriteOrder = ({ id, orderValues, isUpdate }: Props) => {
  // ------------- GET --------------- //
  const {
    data: order,
    isFetching: loadingOrder,
    error: OrderError,
    refetch: refetchOrder,
  } = useQuery({
    queryFn: () => getOrderById(id ?? ''),
    queryKey: ['order', id],
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });
  const orderError = useMemo(() => {
    if (OrderError instanceof AxiosError && OrderError.response) {
      return new PandasError(OrderError.response.data);
    }

    if (OrderError) {
      return new PandasError({
        code: t('ORDC-500100'),
        statusCode: 500,
        message: 'Unhandled Error',
      });
    }

    return undefined;
  }, [OrderError]);

  // ------------- CREATE // UPDATE --------------- //
  const { mutateAsync: create, isLoading: loadingCreate } = useMutation({
    mutationFn: createOrder,
  });

  const CreateOrder = useCallback(
    async (orderData: Order) => {
      const created = await create(orderData);
      return created;
    },
    [create],
  );

  const { mutateAsync: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: updateOrder,
  });

  const { mutateAsync: updatePayment, isLoading: loadingPaymentUpdate } =
    useMutation({
      mutationFn: updateOrderPayment,
    });

  const UpdateOrder = useCallback(
    async (orderData: Order) => {
      const created = await update({ order: orderData });
      return created;
    },
    [update],
  );

  // ------------- UTILS --------------- //
  const payment = useMemo(() => {
    if (order?.payment) {
      const paymentOrd = { ...order.payment };

      const paymentMethods = Object.keys(order.payment) as PaymentOptionsKeys[];

      paymentMethods.forEach((method) => {
        paymentOrd[method] = {
          ...paymentOrd[method],
          use: true,
        };
      });

      return paymentOrd;
    }

    return orderValues?.payment;
  }, [order?.payment, orderValues?.payment]);

  const {
    data: orderTotals,
    isFetching: loadingTotals,
    refetch: RefetchTotals,
  } = useQuery({
    queryFn: () =>
      computeOrderTotals({
        ...orderValues,
        payment,
      }),
    queryKey: [
      'order',
      'totals',
      orderValues?.detail,
      orderValues?.discount,
      orderValues?.user?.localization,
      orderValues?.freeShippingCost,
      orderValues?.isWarranty,
      orderValues?.payment?.pandabonos?.use,
    ],
    refetchOnWindowFocus: false,
    enabled: Boolean(
      orderValues?.detail?.length && orderValues?.user?.localization,
    ),
  });

  const refetchTotals = useCallback(() => {
    RefetchTotals();
  }, [RefetchTotals]);

  const { data: deliveryRangeOptions, refetch: refetchDeliveries } = useQuery({
    queryFn: () => getDeliveryDates({ order: orderValues, isUpdate }),
    queryKey: [
      'delivery-dates',
      { ...(orderValues?.user?.localization ?? {}) },
      isUpdate,
    ],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(
      orderValues?.user?.localization && orderValues?.detail?.length,
    ),
  });

  const { data: segment } = useQuery({
    queryFn: () => getParameters({ keys: 'segmentation' }),
    queryKey: ['segmentation-values'],
    refetchOnWindowFocus: false,
  });
  const { data: reschedule } = useQuery({
    queryFn: () => getParameters({ keys: 'rescheduleReason' }),
    queryKey: ['reschedule-reason'],
    refetchOnWindowFocus: false,
  });
  const { data: pandabonos } = useQuery({
    queryFn: () => getPandabonosById(orderValues?.user?.id),
    queryKey: ['user-pandabonos', orderValues?.user?.id],
    refetchOnWindowFocus: false,
    enabled: Boolean(!isUpdate && orderValues?.user?.id),
  });

  const {
    mutateAsync: updatePendingStock,
    isLoading: loadingPendingStockUpdate,
  } = useMutation({
    mutationFn: () => updateOrderStock(id ?? ''),
    onSuccess() {
      window.location.reload();
    },
  });

  const { isLoaded: isLoadedGoogleScript } = useLoadScript({
    googleMapsApiKey: config.googleMapsConfig.apiKey,
    libraries: ['places'],
  });

  useEffect(() => {
    if (orderValues?.detail?.length && !!orderValues?.user) {
      refetchDeliveries();
    }
  }, [orderValues?.detail, orderValues?.user, refetchDeliveries]);

  return {
    order,
    loadingOrder,
    orderError,
    refetchOrder,
    createOrder: CreateOrder,
    loadingCreate,
    updateOrder: UpdateOrder,
    loadingUpdate,
    updatePayment,
    loadingPaymentUpdate,
    orderTotals,
    loadingTotals,
    refetchTotals,
    segmentationValues: segment?.segmentation?.data ?? [],
    deliveryRangeOptions: deliveryRangeOptions ?? [],
    rescheduleReasonOptions: reschedule?.rescheduleReason?.data ?? [],
    updatePendingStock,
    loadingPendingStockUpdate,
    pandabonos,
    isLoadedGoogleScript,
  };
};

export default useWriteOrder;
