import { Col, Collapse, Row } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

const { Panel } = Collapse;

export const FormWrapper = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  max-height: 80vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${LightTheme.color.gray_light};
    border-radius: 20px;
    border: 3px solid transparent;
  }
`;

export const FormHeader = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

export const PurchasesDropdownWrapper = styled(Col)`
  display: flex;
  gap: 5px;
`;

export const DisplayInputWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${LightTheme.spaceSizes.m};
`;

export const AddressValueWrapper = styled.div`
  font-weight: bold;
`;

export const DetailsValueWrapper = styled.div`
  font-size: ${LightTheme.spaceSizes.m};
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  padding-right: ${LightTheme.spaceSizes.m};
`;

export const ItemParagraph = styled.div`
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const BoldItemParagraph = styled(ItemParagraph)`
  font-weight: bold;
`;

export const OrderFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .ant-row {
    margin: 0 !important;
    padding: 0 !important;
  }
`;
export const PanelGrid = styled(Panel)`
  .ant-collapse-content-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;

    @media screen and (${LightTheme.queries.xs}) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const UploadWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    width: 100%;
  }
`;

export const PurchaseCloneModalTitle = styled.h3`
  color: ${LightTheme.color.warning};
`;

export const RadioMpsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 550px;
  margin-top: 20px;
`;

export const SubmitWrapper = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row-reverse;
`;
