import { Method } from 'axios';

export const endpoints = {
  getStoresList: {
    method: 'GET' as Method,
    url: 'store-dom/store',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createStore: {
    method: 'POST' as Method,
    url: 'store-dom/store',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateStore: {
    method: 'PUT' as Method,
    url: 'store-dom/store/{params.storeId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateStoreUsers: {
    method: 'PUT' as Method,
    url: 'store-dom/store/{params.storeId}/user',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getStoreById: {
    method: 'GET' as Method,
    url: 'store-dom/store/{params.storeId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteStoreById: {
    method: 'DELETE' as Method,
    url: 'store-dom/store/{params.storeId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
