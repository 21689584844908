import WalletContainer from 'containers/Wallet';
import { WalletProvider } from 'context/WalletContext/Wallet.Context';

export const WalletPage = () => {
  return (
    <WalletProvider>
      <WalletContainer />
    </WalletProvider>
  );
};
