import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import { Key } from 'antd/lib/table/interface';
import TableActions from 'components/TableActions/TableActions';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import dateFormat from 'helpers/utils/dateFormat';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ItemLabel, ItemParagraph } from '../AttributeTable.sty';
import { Attribute } from '../interface';

const tableColumns = (
  attributeActions: (attribute: Attribute) => JSX.Element | null,
  t: TFunction<'translation', undefined>,
) => [
  {
    title: t('attributeTableKeys.name'),
    dataIndex: 'name',
  },
  {
    title: t('attributeTableKeys.type'),
    dataIndex: 'type',
  },
  {
    title: t('attributeTableKeys.description'),
    dataIndex: 'description',
  },
  {
    title: t('attributeTableKeys.actions'),
    render: (attribute: Attribute) => attributeActions(attribute),
  },
];

const hiddenDescription = ({
  attribute,
  t,
  isMobile,
  attributeActions,
}: {
  attribute: Attribute;
  t: TFunction<'translation', undefined>;
  isMobile: boolean;
  attributeActions: (attribute: Attribute) => JSX.Element | null;
}) => (
  <Space direction="vertical">
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.attributeId')}
        :&nbsp;
      </ItemLabel>
      {attribute.id}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.name')}
        :&nbsp;
      </ItemLabel>
      {attribute.name}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.type')}
        :&nbsp;
      </ItemLabel>
      {attribute.type}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.description')}
        :&nbsp;
      </ItemLabel>
      {attribute.description}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.createdAt')}
        :&nbsp;
      </ItemLabel>
      {dateFormat(attribute.createdAt || '')}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('attributeTableKeys.updatedAt')}
        :&nbsp;
      </ItemLabel>
      {dateFormat(attribute.updatedAt || '')}
    </ItemParagraph>
    {isMobile && attributeActions(attribute)}
  </Space>
);

const useAttributeTable = ({
  selectedRowKeys,
  onDeleteAttribute,
  setSelectedRowKeys,
}: {
  selectedRowKeys: Key[];
  onDeleteAttribute: (id: string) => void;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('product.editActions');

  const attributeActions = (attribute: Attribute) => {
    const handleDelete = (attr: Attribute) => {
      const { id } = attr;
      onDeleteAttribute(id);
    };

    return (
      <AccessWrapper
        fallback={(
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {t('noAccess')}
          </Tag>
        )}
      >
        <TableActions
          entity={attribute}
          onDelete={handleDelete}
        />
      </AccessWrapper>
    );
  };
  const selectOptions = {
    selectedRowKeys,
    onChange: (checkedRowKeys: Key[]) => {
      setSelectedRowKeys(checkedRowKeys);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const defaultColumns = tableColumns(
    attributeActions,
    t,
  );

  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('attributeTableKeys.description'),
      t('attributeTableKeys.actions'),
    ];
    const filteredColumns = isMobile
      ? defaultColumns.filter(
        (column) => !mobileHidableColumns.includes(column.title),
      ) : defaultColumns;
    return filteredColumns;
  }, [defaultColumns, isMobile]);

  return {
    selectOptions,
    columns,
    hiddenDescription: (attribute: Attribute) =>
      hiddenDescription({
        attribute,
        t,
        isMobile,
        attributeActions,
      }),
  };
};

export default useAttributeTable;
