import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { StockActionEnum } from 'components/VariantForm/interface';
import { User } from 'firebase/auth';
import { UserRoleEnum } from 'services/user/interface';

export enum RoleEnum {
  ADMIN = 'ADMIN',
  MERCHANT = 'MERCHANT',
  WAREHOUSE_ASSISTANT = 'WAREHOUSE_ASSISTANT',
  WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  SELLER_COORDINATOR = 'SELLER_COORDINATOR',
  SELLER = 'SELLER',
  FINANCE = 'FINANCE',
  GROWTH = 'GROWTH',
}

export type Role = keyof typeof RoleEnum;

export const RoleTagColorCode: {
  [key: string]: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
} = {
  [UserRoleEnum.ADMIN]: 'gold',
  [UserRoleEnum.ADMINISTRATIVE_ASSISTANT]: 'geekblue',
  [UserRoleEnum.CUSTOMER_SERVICE]: 'red',
  [UserRoleEnum.MERCHANT]: 'purple',
  [UserRoleEnum.WAREHOUSE_ASSISTANT]: 'magenta',
  [UserRoleEnum.WAREHOUSE_MANAGER]: 'magenta',
  [UserRoleEnum.SELLER]: 'green',
  [UserRoleEnum.SELLER_COORDINATOR]: 'success',
  [UserRoleEnum.FINANCE]: 'yellow',
  [UserRoleEnum.GROWTH]: 'lime',
};

export const ActionTagColorCode: {
  [key: string]: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
} = {
  [StockActionEnum.ADD]: 'success',
  [StockActionEnum.COMMIT]: 'geekblue',
  [StockActionEnum.FREE]: 'purple',
  [StockActionEnum.MISSING]: 'error',
  [StockActionEnum.REMOVE]: 'magenta',
  [StockActionEnum.SELL]: 'lime',
  [StockActionEnum.ADD_CAP]: 'cyan',
  [StockActionEnum.RELEASE]: 'volcano',
  [StockActionEnum.PRICE_UPDATE]: 'yellow',
};

export const RoleEditOptions: {
  [key: string]: Role[];
} = {
  [RoleEnum.ADMIN]: [
    'MERCHANT',
    'WAREHOUSE_ASSISTANT',
    'WAREHOUSE_MANAGER',
    'CUSTOMER_SERVICE',
    'SELLER_COORDINATOR',
    'SELLER',
  ],
  [RoleEnum.MERCHANT]: [],
  [RoleEnum.WAREHOUSE_ASSISTANT]: ['MERCHANT'],
  [RoleEnum.CUSTOMER_SERVICE]: ['MERCHANT'],
  [RoleEnum.SELLER_COORDINATOR]: ['MERCHANT', 'SELLER'],
  [RoleEnum.SELLER]: [],
};

export type Scope = 'BACKOFFICE' | 'PWA' | 'BACKEND';

export interface ActionType {
  roles: Role[];
  scope: Scope[];
}

export interface ActionsTypes {
  [key: string]: { [key: string]: ActionType };
}

export interface FirebaseUser extends User {
  reloadUserInfo: {
    customAttributes: string;
  };
}
