import { cellPhoneRegex, phoneRegex } from 'helpers/constants/regex';
import { TFunction } from 'i18next';

interface GenericValidatorInterface {
  readonly required?: boolean;
  readonly maxLength?: number;
  readonly minLength?: number;
}

interface NumberValidatorInterface extends GenericValidatorInterface {
  readonly min?: number;
  readonly max?: number;
}

interface PhoneValidatorInterface extends GenericValidatorInterface {
  readonly colombianPhone?: boolean;
}

interface StringValidatorInterface extends GenericValidatorInterface {
  readonly regexp?: RegExp;
}

interface IDValidatorInterface extends GenericValidatorInterface {
  readonly actualIds: string[];
}

interface ArrayValidatorInterface extends GenericValidatorInterface {
  readonly enum?: (string | number)[];
}

const searchInTranslator = (
  t: TFunction,
  key: string,
  params: object = {},
): string => t(key, params);

export const stringValidator = (
  value: string | undefined,
  config: StringValidatorInterface,
  t: TFunction,
  customErrors?: { badRegex?: string },
): Promise<void> => {
  if (!config.required && (value ?? '')?.trim().length === 0) {
    return Promise.resolve();
  }
  if (config.required && !value?.trim()) {
    return Promise.reject(searchInTranslator(t, 'BOGENE003'));
  }
  if (
    config.minLength !== undefined &&
    (value ?? '').length < config.minLength
  ) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE004', { minLength: config.minLength }),
    );
  }
  if (
    config.maxLength !== undefined &&
    (value ?? '').length > config.maxLength
  ) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE005', { maxLength: config.maxLength }),
    );
  }
  if (config.regexp && !(value ?? '').match(config.regexp)) {
    return Promise.reject(
      searchInTranslator(t, customErrors?.badRegex ?? 'BOGENE006', {
        acceptedType: 'email',
      }),
    );
  }
  return Promise.resolve();
};

export const IDValidator = (
  value: string | undefined,
  config: IDValidatorInterface,
  t: TFunction,
): Promise<void> => {
  const idIsInActualIds = config.actualIds.find((id) => id === value);
  if (idIsInActualIds) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE009', {
        id: value,
      }),
    );
  }

  return stringValidator(value, config, t);
};

export const numberValidator = (
  value: number | undefined,
  config: NumberValidatorInterface,
  t: TFunction,
): Promise<void> => {
  if (config.required && value?.toString().trim().length === 0) {
    return Promise.reject(searchInTranslator(t, 'BOGENE003'));
  }
  if ((value === undefined || value === null) && config.required) {
    return Promise.reject(searchInTranslator(t, 'BOGENE003'));
  }
  if (value !== undefined && config.min !== undefined && value < config.min) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE002', { min: config.min }),
    );
  }
  if (value !== undefined && config.max !== undefined && value > config.max) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE001', { max: config.max }),
    );
  }
  return Promise.resolve();
};

export const phoneValidator = (
  value: string | number | undefined,
  config: PhoneValidatorInterface,
  t: TFunction,
): Promise<void> => {
  const cleanValue: string = value?.toString().trim() || '';

  if (!config.required && cleanValue.length === 0) {
    return Promise.resolve();
  }
  if (config.required && cleanValue.length === 0) {
    return Promise.reject(searchInTranslator(t, 'BOGENE003'));
  }
  if (config.minLength !== undefined && cleanValue.length < config.minLength) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE004', { minLength: config.minLength }),
    );
  }
  if (config.maxLength !== undefined && cleanValue.length > config.maxLength) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE005', { maxLength: config.maxLength }),
    );
  }
  if (
    config.colombianPhone &&
    !(cleanValue.match(cellPhoneRegex) || cleanValue.match(phoneRegex))
  ) {
    return Promise.reject(searchInTranslator(t, 'BOGENE008'));
  }
  if (Number.isNaN(Number(value))) {
    return Promise.reject(
      searchInTranslator(t, 'BOGENE006', { acceptedType: 'númerico' }),
    );
  }
  return Promise.resolve();
};

export const arrayValidator = (
  values: (string | number)[],
  config: ArrayValidatorInterface,
  t: TFunction,
  customMessages?: Partial<Record<keyof ArrayValidatorInterface, string>>,
): Promise<void> => {
  if (config.required && !values) {
    return Promise.reject(
      searchInTranslator(t, customMessages?.required ?? 'BOGENE003'),
    );
  }
  if (config.minLength !== undefined && values.length < config.minLength) {
    return Promise.reject(
      searchInTranslator(t, customMessages?.minLength ?? 'BOGENE004', {
        minLength: config.minLength,
      }),
    );
  }
  if (config.maxLength !== undefined && values.length > config.maxLength) {
    return Promise.reject(
      searchInTranslator(t, customMessages?.maxLength ?? 'BOGENE005', {
        maxLength: config.maxLength,
      }),
    );
  }
  if (config.enum !== undefined) {
    const enumTable: { [key: string | number]: boolean } = {};

    config.enum.forEach((element: string | number) => {
      enumTable[element] = true;
    });

    try {
      values.forEach((value: string | number) => {
        if (!enumTable[value]) throw new Error('Invalid value in enum');
      });
      return Promise.resolve();
    } catch {
      return Promise.reject(
        searchInTranslator(t, customMessages?.enum ?? 'BOGENE007'),
      );
    }
  }
  return Promise.resolve();
};
