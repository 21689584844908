import { BrandDBEntity } from 'components/BrandForm/interface';
import { ProductFormContent } from 'pages/Products/CreateProduct/interface';
import { Dispatch, SetStateAction } from 'react';

export interface BrandOption {
  value: string;
  label: JSX.Element;
}

export interface ProductFormProps {
  initialValues: ProductFormContent;
  onSubmit: (values: ProductFormContent) => void;
  setBrandValue: Dispatch<SetStateAction<BrandDBEntity>>;
  title?: string;
  formId: string;
  reset?: boolean;
  submitButton?: JSX.Element;
  isUpdate: boolean;
}

export const DISABLED_RICHTEXT_PLUGINS = [
  'about',
  'clean-html',
  'file',
  'focus',
  'dtd',
  'drag-and-drop-element',
  'drag-and-drop',
  'hotkeys',
  'iframe',
  'image',
  'image-processor',
  'image-properties',
  'limit',
  'key-arrow-outside',
  'media',
  'mobile',
  'powered-by-jodit',
  'preview',
  'print',
  'redo-undo',
  'resize-cells',
  'resize-handler',
  'resizer',
  'search',
  'select',
  'select-cells',
  'source',
  'speech-recognize',
  'symbols',
  'table',
  'table-keyboard-navigation',
  'video',
  'wrap-nodes',
  'xpath',
  'class-span',
  'clipboard',
  'copyformat',
  'hr',
];
