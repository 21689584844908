import useNotifications from 'helpers/hooks/useNotifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'services/user/interface';

const useMultiUserAutoComplete = (
  value: User[] | undefined,
  onChange: ((value: User[]) => void) | undefined,
) => {
  const { t } = useTranslation();
  const { notifyWarning } = useNotifications({
    translationFunction: t,
  });
  const handleAddUser = useCallback(
    (addedUser: User) => {
      const previousIndex = value?.findIndex(
        (user: User) => user.id === addedUser.id,
      ) ?? -1;
      if (previousIndex > -1) {
        notifyWarning('multiUserAutoComplete.messages.alreadyInList');
        return;
      }
      onChange?.([addedUser, ...(value || [])]);
    },
    [onChange, value, notifyWarning],
  );

  const handleDeleteUser = useCallback(
    (deletedUser: User) => {
      const filteredArray =
        value?.filter((user: User) => user.id !== deletedUser.id) ?? [];
      onChange?.([...filteredArray]);
    },
    [onChange, value],
  );

  return {
    handleAddUser,
    handleDeleteUser,
  };
};

export default useMultiUserAutoComplete;
