import { AntCloudOutlined, InboxOutlined } from '@ant-design/icons';
import { Divider, Select, Tabs } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import PandacoinsIcon from 'assets/images/Pandacoins.png';
import { InputContainer } from 'components/CsvInput/CsvInput.sty';
import { CustomTag } from 'components/CustomTag';
import PandabonosHistory from 'components/PandabonosHistory/PandabonosHistory';
import TableTabsWrapper from 'components/TableTabsWrapper';
import config from 'config';
import useGetToken from 'helpers/hooks/useGetToken/useGetToken';
import useNotifications from 'helpers/hooks/useNotifications';
import { isString } from 'lodash';
import { customMessage, WalletStatusEnum } from 'pages/Pandabonos/interface';
import { TableWrapperPandabonos } from 'pages/Pandabonos/Pandabonos.sty';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusTagColor } from 'services/order/interface';
import {
  PandabonosFilterContainer,
  UpdatePandabonosWrapper,
  UpdateStockContainer,
  UpdateStockImage,
  UpdateStockImageItem,
  UpdateStockItems,
  UploadButton,
} from './PandabonosContent.sty';

const PandabonosUpload = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { token } = useGetToken();
  const [wallet, setwallet] = useState<string>('');
  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const handleWalletChange = useCallback((status: string) => {
    setwallet(status);
  }, []);

  const handleDownloadFormat = useCallback(async () => {
    try {
      window.open(
        'https://storage.cloud.google.com/dev_assets_backoffice/pandabonos.csv',
        '_blank',
      );
      notifySuccess(t('pandabonos.success'));
    } catch (err) {
      notifyError(t('pandabonos.error'));
    }
  }, [notifyError, notifySuccess, t]);

  const walletFilter = (
    <PandabonosFilterContainer>
      <Paragraph>
        {t('pandabonos.wallet')} <span>{t('mark')}</span>
      </Paragraph>

      <Select
        style={{ minWidth: 'fit-content', width: '100%' }}
        placeholder={t('pandabonos.placeholder')}
        value={wallet}
        onChange={handleWalletChange}
      >
        <Select.Option value="">{t('pandabonos.noWallet')}</Select.Option>
        {Object.keys(WalletStatusEnum).map((statusOption) => (
          <Select.Option value={statusOption} key={statusOption}>
            <CustomTag color={StatusTagColor[statusOption]}>
              {t(`wallet.${statusOption}`) || `${statusOption}` || ''}
            </CustomTag>
          </Select.Option>
        ))}
      </Select>
    </PandabonosFilterContainer>
  );

  const draggerProps = {
    name: 'pandabonos',
    multiple: false,
    action: `${config.pandabonos.actionURL}?wallet=${wallet}`,
    disabled: !wallet,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
    onChange(info: UploadChangeParam) {
      const { status } = info.file;
      if (status === 'done') {
        notifySuccess('pandabonos.actions.success.description');
      } else if (status === 'error') {
        const errorCode = info.file.response.code;
        let message = t(errorCode);
        if (errorCode === message) {
          message = customMessage.error.description;
        }
        if (info.file.response.detail) {
          let { detail } = info.file.response;
          if (!isString(detail)) {
            detail = JSON.stringify(detail, null, 2);
          }
          message += `: [${detail}]`;
        }
        notifyError(message);
      }
    },
    beforeUpload(fileUpload: RcFile) {
      const { name } = fileUpload;
      const extension = name.split('.').pop();
      if (extension !== 'csv') {
        notifyError(t('variantsCsvInput.message.extensionError'));
        return false;
      }
      return true;
    },
    accept: '.csv',
  };

  return (
    <TableTabsWrapper>
      <Tabs defaultActiveKey="1">
        <TabPane key="1" tab={t('pandabonos.uploadPandabonos')}>
          <TableWrapperPandabonos>
            <UpdateStockContainer>
              <UpdatePandabonosWrapper>
                <UpdateStockImage>
                  <Title level={4}>{t('pandabonos.downloadLabel')}</Title>
                  <UpdateStockImageItem src={PandacoinsIcon} />
                </UpdateStockImage>
              </UpdatePandabonosWrapper>
              <UpdateStockItems>
                <div
                  style={{
                    width: '100%',
                    top: '20%',
                    position: 'absolute',
                  }}
                >
                  <Title level={2} style={{ fontWeight: 'bold' }}>
                    {t('pandabonos.useFormat')}
                  </Title>
                  <UploadButton
                    icon={<AntCloudOutlined />}
                    onClick={() => handleDownloadFormat()}
                  >
                    {t('pandabonos.downloadText')}
                  </UploadButton>
                </div>
              </UpdateStockItems>
            </UpdateStockContainer>
            <Divider />
            <div>
              <Title level={5}>{t('pandabonos.uploadPandabonos')}</Title>
              {walletFilter}
              <br />
              <Title style={{ textAlign: 'center' }} level={4}>
                {wallet
                  ? t('pandabonos.uploadMessage')
                  : t('pandabonos.sendMessage')}
              </Title>
            </div>
            <br />
            <InputContainer style={{ height: 'auto' }}>
              <div style={{ width: 1900 }}>
                <Dragger
                  {...draggerProps}
                  style={{ height: 'auto', padding: '0 15px' }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t('variantsCsvInput.inputText')}
                  </p>
                  <p className="ant-upload-hint">{t('pandabonos.hint')}</p>
                </Dragger>
              </div>
            </InputContainer>
          </TableWrapperPandabonos>
        </TabPane>
        <TabPane key="2" tab={t('pandabonos.historialPandabonos')}>
          <TableWrapperPandabonos>
            <PandabonosHistory />
          </TableWrapperPandabonos>
        </TabPane>
      </Tabs>
    </TableTabsWrapper>
  );
};

export default PandabonosUpload;
