import { DefaultOptionType } from 'antd/lib/select';
import { TFunction } from 'i18next';
import { RevisionStatus } from 'services/product/interface';

export function getRevisionStatuses(t: TFunction): DefaultOptionType[] {
  return [
    {
      label: t(`productList.revisionStatus.${RevisionStatus.APPROVED}`),
      value: RevisionStatus.APPROVED,
    },
    {
      label: t(`productList.revisionStatus.${RevisionStatus.REJECTED}`),
      value: RevisionStatus.REJECTED,
    },
    {
      label: t(`productList.revisionStatus.${RevisionStatus.PENDING}`),
      value: RevisionStatus.PENDING,
    },
  ];
}
