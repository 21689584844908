/* eslint-disable no-underscore-dangle */
import { SettingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import LoaderWrapper from 'components/LoadingWrapper';
import { ROUTES } from 'helpers/constants/routes';
import { BaseColors } from 'helpers/constants/theme';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  SettingsCard,
  SettingWrapper,
  Title,
} from '../components/SettingList.sty';
import { useSettingList } from '../context/SettingList.context';
import settingToIconMap from '../helpers/settingToIconMap';
import useReadSettingList from '../hooks/useReadSettingList';
import { SETTING_LIST_ACTION_ENUM } from '../reducer/interface';

const SettingListContainer = () => {
  const { state, dispatch } = useSettingList();
  const navigate = useNavigate();
  const { data, isLoading } = useReadSettingList({ state });
  const { t } = useTranslation();

  const onGoToSetting = useCallback(
    (id: string) => {
      navigate(`${ROUTES.wmsSettings}/${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    dispatch({
      payload: data,
      type: SETTING_LIST_ACTION_ENUM.SET_SETTINGS_LIST,
    });
  }, [data, dispatch]);

  return (
    <SettingWrapper>
      <Title color={BaseColors.deep_cove}>{t('wms-settings.list.title')}</Title>
      <LoaderWrapper loading={isLoading}>
        <Space direction="horizontal" size={16}>
          {data.map((setting) => (
            <SettingsCard
              key={setting._id}
              dir="vertical"
              title={t(`wms-settings.setting.${setting.setting}.title`)}
              actions={[
                <SettingOutlined
                  key="edit"
                  onClick={() => onGoToSetting(setting._id)}
                />,
              ]}
              extra={settingToIconMap(setting.setting)}
            >
              <p>{t(`wms-settings.setting.${setting.setting}.description`)}</p>
            </SettingsCard>
          ))}
        </Space>
      </LoaderWrapper>
    </SettingWrapper>
  );
};

export default SettingListContainer;
