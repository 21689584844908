import { Checkbox, Input, Modal, Typography } from 'antd';
import { t } from 'i18next';
import { StatusOrderEnum, StatusTagColor } from 'services/order/interface';
import { LabelContainer } from '../Modals/UpdateStatusConfirm/UpdateStatusConfirm.sty';

interface OrderConfirmationModalProps {
  statusUpdate: boolean;
  open: boolean;
  loadingUpdate: boolean;
  loadingTotals: boolean;
  updateComment: string;
  status: StatusOrderEnum;
  submit: () => void;
  cancelUpdate: () => void;
  handleCommentChange: (comment: string) => void;
  setReportMissing: (missing: boolean) => void;
}

const OrderConfirmationModal = ({
  statusUpdate,
  open,
  loadingUpdate,
  loadingTotals,
  updateComment,
  status,
  submit,
  cancelUpdate,
  handleCommentChange,
  setReportMissing,
}: OrderConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      title={
        <span
          style={{
            fontWeight: 'bold',
            color: statusUpdate ? StatusTagColor[status] : undefined,
          }}
        >
          {t(
            `orderForm.modal.titles.${statusUpdate ? status : 'normalUpdate'}`,
          )}
        </span>
      }
      onOk={submit}
      onCancel={cancelUpdate}
      okText={t('orderForm.modal.buttonTexts.ok')}
      cancelText={t('orderForm.modal.buttonTexts.cancel')}
      okButtonProps={{
        loading: loadingUpdate,
        disabled: loadingTotals,
        style: {
          border: 'none',
        },
      }}
      zIndex={1250}
    >
      <Input.TextArea
        placeholder={t('orderForm.modal.placeholders.comment')}
        value={updateComment}
        onChange={(e) => handleCommentChange(e.target.value)}
      />
      {status === StatusOrderEnum.DELIVERED ? (
        <LabelContainer>
          <Typography>{t('orderForm.modal.placeholders.missing')}</Typography>
          <Checkbox onChange={(e) => setReportMissing(e.target.checked)} />
        </LabelContainer>
      ) : null}
    </Modal>
  );
};

export default OrderConfirmationModal;
