import { AutoComplete, Input } from 'antd';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { User } from 'services/user/interface';
import useUserAutocomplete from './hooks/useUserAutocomplete';
import { UserAutocompleteProps } from './interface';

const { Search } = Input;

const UserAutocomplete = ({
  value = {} as User,
  onChange,
  onSearchExhaust,
  queryParams,
  disabled,
  size,
  cleanOnSelect = false,
}: UserAutocompleteProps) => {
  const {
    readQuery: { mutateAsync: getUsersAsync, isLoading: isLoadingAutocomplete },
  } = useModuleCRUD('user');
  const {
    searchKey: [searchKey, setSearchKey],
    autocompleteResults: [autocompleteResults],
    userOptions: [userOptions],
    t,
    handleSearchUsers,
    renderText,
  } = useUserAutocomplete(value, getUsersAsync, queryParams);

  const triggerChange = (changedValue: User) => {
    onChange?.({
      ...value,
      ...changedValue,
    });
  };

  const handleSelectAutocomplete = (inputValue: string) => {
    if (inputValue === 'create-new-user') {
      onSearchExhaust?.();
      setSearchKey('');
      return;
    }
    const userToAdd = autocompleteResults.find(
      (result) => result.id === inputValue,
    );
    triggerChange(userToAdd as User);
    if (!cleanOnSelect) {
      setSearchKey(renderText(userToAdd?.info));
    } else {
      setSearchKey('');
    }
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      options={userOptions}
      onSelect={handleSelectAutocomplete}
      onSearch={handleSearchUsers}
      value={searchKey}
      disabled={disabled}
    >
      <Search
        size={size || 'large'}
        placeholder={t('userAutocomplete.placeholder')}
        loading={isLoadingAutocomplete}
      />
    </AutoComplete>
  );
};

export default UserAutocomplete;
