import { notification } from 'antd';
import BannerContainer from 'containers/BannerList/BannerContainer';
import { BannerProvider } from 'containers/BannerList/context/banner.context';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BannerList = () => {
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.bannersList}`);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  return (
    <BannerProvider>
      <BannerContainer />;
    </BannerProvider>
  );
};

export default BannerList;
