/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  PageHeader,
  Skeleton,
  Tag,
} from 'antd';
import { getAuth } from 'firebase/auth';
import { LightTheme } from 'helpers/constants/theme';
import { useAuth } from 'helpers/context/AuthUserContext';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { hasAccess } from 'helpers/hooks/useRoleAccess/useRoleAccess';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import {
  NavigateOptions,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import LetterLogoSvg from '../../assets/images/logo-large-white.svg';
import { RoleTagColorCode } from '../../helpers/hooks/useRoleAccess/interface';
import './Layout.css';
import { LAYOUT_ROUTES } from './Layout.Routes';
import {
  BackLayout,
  CloseDrawer,
  ContainerLayout,
  ContentLayout,
  DrawerBodyStyle,
  DrawerHeaderStyle,
  DrawerStyle,
  LetterLogoContainer,
  LetterLogoImg,
  MenuDrawer,
  MenuSider,
  MenuSiderContainer,
} from './Layout.sty';

const { t } = i18next;
const { SubMenu } = Menu;

const SideMenu = ({
  onRedirect,
}: {
  onRedirect: (path: string, options?: NavigateOptions) => void;
}) => {
  const { state } = useLocation();
  const path = window.location.pathname;
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[path]}
      defaultOpenKeys={LAYOUT_ROUTES.map((route) => route.path)}
    >
      {LAYOUT_ROUTES.map((route) => {
        if (route.subMenus) {
          return (
            <SubMenu
              key={route.path}
              icon={<route.icon />}
              title={t(route.name)}
            >
              {route?.subMenus.map(
                (subMenu) =>
                  hasAccess(`routes.${subMenu.path}`) && (
                    <Menu.Item
                      key={`subMenu-${subMenu.path}`}
                      icon={<subMenu.icon />}
                      onClick={() => onRedirect(subMenu.path, { state })}
                    >
                      {t(subMenu.name)}
                    </Menu.Item>
                  ),
              )}
            </SubMenu>
          );
        }
        return (
          <Menu.Item
            key={route.path}
            icon={<route.icon />}
            onClick={() => onRedirect(route.path, { state })}
          >
            {t(route.name)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export const LayoutApp: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [hasFirebaseUser, setHasFirebaseUser] = React.useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const { logOut, authUser, firebaseRoles } = useAuth();
  const signOutAction = async () => {
    await logOut();
  };

  const onRedirect = (pathToRedirect: string, options?: NavigateOptions) => {
    if (isMobile) {
      setCollapsed(true);
    }
    navigate(pathToRedirect, options);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        {`${authUser?.info.name} ${authUser?.info.lastName || ''}`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        {firebaseRoles &&
          firebaseRoles?.split('|')?.map((role) => (
            <Tag key={role} color={RoleTagColorCode[role] || 'default'}>
              {role}
            </Tag>
          ))}
      </Menu.Item>
      <Menu.Item key="2">{`${authUser?.info.email}`}</Menu.Item>
      <Menu.Item key="3">{`${authUser?.info.phoneNumber}`}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4" onClick={signOutAction}>
        Salir
      </Menu.Item>
    </Menu>
  );

  const letterLogo = (
    <LetterLogoContainer>
      <LetterLogoImg src={LetterLogoSvg} alt="Pandas S. A. S." />
    </LetterLogoContainer>
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      const firebaseUser = getAuth().currentUser;
      const roles = firebaseUser
        ? (await firebaseUser.getIdTokenResult()).claims.role
        : undefined;
      if (roles) {
        setHasFirebaseUser(true);
        clearInterval(interval);
      } else if (firebaseUser) {
        await firebaseUser.getIdToken(true);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout style={{ maxHeight: '100vh', height: '100vh' }}>
      <MenuDrawer
        placement="left"
        title={letterLogo}
        open={!collapsed}
        onClose={() => {
          setCollapsed(!collapsed);
        }}
        drawerStyle={DrawerStyle}
        headerStyle={DrawerHeaderStyle}
        bodyStyle={DrawerBodyStyle}
        closeIcon={<CloseDrawer />}
      >
        {hasFirebaseUser ? (
          <SideMenu onRedirect={onRedirect} />
        ) : (
          <Skeleton loading active avatar />
        )}
      </MenuDrawer>
      <MenuSiderContainer>
        <MenuSider
          width={250}
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={0}
        >
          {letterLogo}
          {hasFirebaseUser ? (
            <SideMenu onRedirect={onRedirect} />
          ) : (
            Array.from({ length: 10 }, (_, i) => i).map((val) => (
              <Skeleton.Button
                key={val}
                style={{
                  width: '200px',
                  marginTop: '30px',
                  marginLeft: '20px',
                }}
                active
                block
              />
            ))
          )}
        </MenuSider>
      </MenuSiderContainer>

      <Layout
        className="site-layout"
        style={{ position: 'relative', backgroundColor: 'white' }}
      >
        <PageHeader
          title={React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              style: { color: LightTheme.color.white },
              onClick: () => setCollapsed(!collapsed),
            },
          )}
          className="header"
          style={{ padding: 0 }}
          extra={[
            <Dropdown overlay={menu} trigger={['click']} key={1}>
              <Avatar
                size="large"
                style={{ cursor: 'pointer' }}
                src={authUser?.info.photoURL}
              />
            </Dropdown>,
          ]}
        />
        <ContainerLayout>
          <BackLayout />
          <ContentLayout>
            {hasFirebaseUser ? <Outlet /> : <Skeleton loading active avatar />}
          </ContentLayout>
        </ContainerLayout>
      </Layout>
    </Layout>
  );
};
