export const ROUTES = {
  home: '/',
  login: '/login',
  maintenance: '/maintenance',
  product: '/product',
  coupons: '/coupons',
  couponsList: '/coupons/list',
  brands: '/brands',
  parameters: '/parameters',
  bannersList: '/parameters/banners',
  brandsList: '/brands/list',
  haulier: '/haulier',
  haulierList: '/haulier/list',
  stores: '/stores',
  storesList: '/stores/list',
  createProduct: '/product/create',
  productList: '/product/list',
  updateVariantsMassive: '/variants-massive/variants',
  updateAvailableMassive: '/variants-massive/available',
  updateStock: '/variants-massive/stock',
  updateProduct: '/product/update',
  order: '/order',
  createOrder: '/order/create',
  orderUpdate: '/order/:id',
  orderPackages: '/order/packages',
  category: '/category',
  createCategory: '/category/create',
  categoryList: '/category/list',
  updateCategory: '/category/update',
  createUser: '/user/create',
  user: '/user',
  userList: '/user/list',
  variantsMassive: '/variants-massive',
  supplierList: '/supplier/list',
  supplier: '/supplier',
  createSupplier: '/supplier/create',
  updateSupplier: '/supplier/update',
  purchase: '/purchase',
  purchaseCreate: '/purchase/create',
  purchaseUpdate: '/purchase/:id',
  wms: '/wms',
  mpsUser: '/mps-user',
  mpsUserList: 'mps-user-list',
  transfers: '/transfers',
  transfersCsv: '/transfers/csv',
  locationTree: '/locationTree',
  wmsSettings: '/wms/settings',
  wmsSettingUpdate: '/wms/settings/:id',
  attributes: '/attributes/list',
  createAttribute: '/attributes/create',
  creditList: '/credit/list',
  pandabonos: '/pandabonos/list',
  routes: '/routes',
  routesCsv: '/routes/csv',
  shippingZones: '/shipping-zones',
  shippingZoneUpdate: '/shipping-zones/:id',
  shippingZoneCreate: '/shipping-zones/create',
  transport: '/transport',
  wallet: '/wallet',
  invoiceReport: '/invoice/reports',
};
