import { Card, Typography } from 'antd';
import { BaseColors, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const SettingWrapper = styled.div`
  background: ${BaseColors.white};
  width: 100%;
  height: 90vh;
  border-radius: 20px;
  padding: 30px;
  overflow-y: scroll;
`;

export const Title = styled(Typography.Title)`
  font-size: ${spaceSizes.ml};
  margin: 0 !important;
`;

export const SettingsCard = styled(Card)`
  max-width: 350px;
`;
