import { Checkbox, Modal } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const StoreFormModal = styled(Modal)`
  .ant-modal-content {
    height: 85vh !important;
    max-height: 85vh !important;
    position: relative;
    overflow-y: hidden;

    .ant-modal-footer {
      padding: ${LightTheme.spaceSizes.xl} ${LightTheme.spaceSizes.xl};
      background: ${LightTheme.color.white};
      opacity: 1 !important;
      box-sizing: border-box !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: fit-content;
    }
  }

  .ant-modal-body {
    height: calc(100% - 137px) !important;
    overflow-y: scroll;
  }
`;

export const PersonalizedCheckbox = styled(Checkbox)<{ size?: number }>`
  .ant-checkbox-wrapper,
  .ant-checkbox:hover::after,
  .ant-checkbox .ant-checkbox-checked,
  .ant-checkbox-inner,
  .ant-checkbox-input {
    ${({ size }) => `transform: scale(${(size ?? 14) / 14})`}
  }
`;
