import { Button, Input } from 'antd';
import styled from 'styled-components';

export const KeyValueInputWrapper = styled.div`
  width: 100%;
`;
export const DetailsContainer = styled.div`
  width: 100%;
  padding: 10px;
  gap: 10px;
`;
export const DetailsKey = styled(Input)`
  width: 100%;
  margin-bottom: 0;
`;
export const DetailsValue = styled(Input)`
  width: 100%;
  margin-bottom: 0;
`;

export const DetailsTableButton = styled(Button)`
  border: 1px solid #1acec3;
  color: #1acec3;
`;
export const CustomHandle = styled.div`
  position: relative;
  display: block;
  cursor: row-resize;
  top: 8px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
`;
