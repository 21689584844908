import { getAPIMethod } from 'helpers/libs/Http';
import { User, UserQueryParams } from './interface';

export const createUser = async (user: User) => {
  const response = await getAPIMethod({ name: 'createUser', data: user });
  return response.data;
};

export const getUserList = async (queryParams?: UserQueryParams) => {
  const response = await getAPIMethod({
    name: 'getUserList',
    params: {
      search: queryParams?.search,
      page: queryParams?.pagination?.page,
      pageSize: queryParams?.pagination?.pageSize,
      roleFilter: queryParams?.roleFilter?.join(','),
      ...queryParams?.pagination,
    },
  });
  return response.data;
};

export const getUserById = async (userId: string) => {
  const response = await getAPIMethod({
    name: 'getUserById',
    pathParams: { userId },
  });
  return response.data;
};

export const updateUserById = async (user: User) => {
  const response = await getAPIMethod({
    name: 'updateUserById',
    pathParams: { userId: user.id || '' },
    data: user,
  });
  return response.data;
};

const deleteUserById = async (userId: string) => {
  const response = await getAPIMethod({
    name: 'deleteUserById',
    pathParams: { userId },
  });
  return response;
};

export default {
  create: createUser,
  read: getUserList,
  update: updateUserById,
  delete: deleteUserById,
  readByParam: getUserById,
};
