import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const MultiAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.m};
  width: 100%;
`;

export const AddressItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LocationContainer = styled.div`
  width: 90%;
  div {
    width: 300px;
  }
`;

export const DeleteButton = styled.span`
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  margin-right: ${LightTheme.spaceSizes.xl};
`;

export const AddressListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.m};
  height: fit-content;
  width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const AddButton = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;
