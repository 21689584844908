/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-confusing-arrow */
import { Button, Divider, notification, Result, Steps } from 'antd';
import { BrandDBEntity } from 'components/BrandForm/interface';
import CreateAndUpdateProduct from 'containers/Products/CreateAndUpdateProduct/CreateAndUpdateProduct';
import CreateAndUpdateVariant from 'containers/Products/CreateAndUpdateVariant/CreateAndUpdateVariant';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { t } from 'i18next';
import { LayoutContainer } from 'pages/App/Layout.sty';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateProductWrapper, FormWrapper } from './CreateProduct.sty';
import useCreateProduct from './hooks/useCreateProduct';

const { Step } = Steps;

const CreateProduct = () => {
  const navigate = useNavigate();
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.createProduct}`);
  const [selectedBrand, setSelectedBrand] = useState<BrandDBEntity>();

  const {
    createStageState: [createStage, setCreateStage],
    createdProductState: [createdProduct, setCreatedProduct],
  } = useCreateProduct();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate]);

  const goToProductList = () => {
    navigate(ROUTES.productList);
  };
  const goToProductEdit = () => {
    navigate(`${ROUTES.updateProduct}/${createdProduct?.urlName}`);
  };

  const customMessage = {
    warning: {
      message: 'productForm.modal.actions.error',
      description: 'productForm.modal.message.product.createWarning',
    },
  };
  const { notifyWarning } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  return (
    <CreateProductWrapper>
      <Steps
        current={createStage}
        type="navigation"
        onChange={(current) => {
          if (createdProduct) {
            setCreateStage(current);
          } else {
            notifyWarning();
            setCreateStage(0);
          }
        }}
      >
        <Step title="Crear producto" />
        <Step title="Añadir variante inicial" />
        <Step title="Finalizar" />
      </Steps>
      <Divider />
      <FormWrapper>
        {createStage === 0 && (
          <CreateAndUpdateProduct
            onFinishStep={(product) => {
              setCreatedProduct(product);
              setCreateStage(1);
            }}
            setSelectedBrand={setSelectedBrand}
          />
        )}
        {createStage === 1 && (
          <CreateAndUpdateVariant
            product={createdProduct}
            onFinishStep={() => {
              setCreateStage(2);
            }}
            selectedBrand={selectedBrand}
          />
        )}
        {createStage === 2 && (
          <LayoutContainer>
            <Result
              status="success"
              title={t('createProductPage.createdProduct')}
              subTitle={
                <span>
                  {' '}
                  <b>{t('createProductPage.name')}</b>
                  <span>{createdProduct?.name}</span>
                  <br />
                  <a
                    target="_blank"
                    href={`https://${
                      process.env.NODE_ENV === 'production'
                        ? 'pandas.com.co'
                        : 'dev.pandas.com.co'
                    }/product-detail/${createdProduct?.urlName}`}
                    rel="noreferrer"
                  >
                    {t('createProductPage.seeProductInApp')}
                  </a>
                </span>
              }
              extra={[
                <Button type="primary" key="console" onClick={goToProductEdit}>
                  {t('createProductPage.addMoreVariants')}
                </Button>,
                <Button key="buy" onClick={goToProductList}>
                  {t('createProductPage.goToProductList')}
                </Button>,
              ]}
            />
          </LayoutContainer>
        )}
      </FormWrapper>
    </CreateProductWrapper>
  );
};

export default CreateProduct;
