import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { isNotNil } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { IBanner } from 'services/parameters/interface';
import { useBannerSortingProps } from '../interface';

const useBannersSorting = ({ items, setItems }: useBannerSortingProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const activeBanner = useMemo(() => {
    if (isNotNil(activeId)) {
      return items.find((item) => item.id === activeId);
    }
    return undefined;
  }, [activeId, items]);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = items.findIndex((a: IBanner) => a.id === active.id);
        const newIndex = items.findIndex(
          (a: IBanner) => a.id === (over?.id ?? ''),
        );

        const swappedBanners = arrayMove<IBanner>(items, oldIndex, newIndex);
        setItems(swappedBanners);
      }

      setActiveId(null);
    },
    [items, setItems],
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return {
    activeBanner,
    activeId,
    handleDragCancel,
    handleDragStart,
    handleDragEnd,
  };
};

export default useBannersSorting;
