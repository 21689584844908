/* eslint-disable import/no-unresolved */
import { Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { MPS } from 'services/MPS/interface';
import {
  BoldItemParagraph,
  ItemParagraph,
  SupplierLogo,
} from './AutoCompleteSupplierItem.sty';

const AutoCompleteSupplierItem = ({ supplier }: { supplier: MPS }) => {
  const { t } = useTranslation();
  const fullName = supplier.name || '';
  return (
    <Row gutter={[16, 8]}>
      <Col
        span={5}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SupplierLogo name={fullName}>
          {`${(supplier.name || 'N')[0].toUpperCase()}${(supplier.name ||
            'NN')[1].toUpperCase()}`}
        </SupplierLogo>
      </Col>
      <Col span={16}>
        <BoldItemParagraph>{fullName}</BoldItemParagraph>
        {!supplier.isSupplier ? (
          <Tag color="red">{t('purchaseForm.fields.autocomplete.mps')}</Tag>
        ) : (
          <Tag color="green">
            {t('purchaseForm.fields.autocomplete.supplier')}
          </Tag>
        )}
        <ItemParagraph>{`NIT. ${supplier.document || ''}`}</ItemParagraph>
        <ItemParagraph>
          {`Contacto: ${supplier.contactName || 'NA'}`}
        </ItemParagraph>
        <ItemParagraph>
          {`Tel. Contacto: ${supplier.phoneNumber || ''}`}
        </ItemParagraph>
        <ItemParagraph>{`Correo: ${supplier.email || 'NA'}`}</ItemParagraph>
      </Col>
    </Row>
  );
};

export default AutoCompleteSupplierItem;
