import { Modal } from 'antd';
import { LocationValue } from 'containers/General/LocationInput/interface';
import LocationInput from 'containers/General/LocationInput/LocationInput';
import { getCoordinates } from 'helpers/utils/getCoordinates';
import { t } from 'i18next';
import { Location } from 'services/user/interface';
import useAddressModal from './hooks/useAddressModal';

export interface AddressModalProps {
  initialValue: Location;
  onConfirmChange?: (address: Location) => void;
  onCancelChange?: () => void;
  visible?: boolean;
}

export const AddressModal = ({
  initialValue,
  visible,
  onCancelChange,
  onConfirmChange,
}: AddressModalProps) => {
  const {
    inputValue: [inputValue, setInputValue],
    mapRef,
    addressSelectedState,
    notifyError,
  } = useAddressModal(initialValue);

  const [addressSelected] = addressSelectedState;

  const recenterMap = (value: LocationValue) => {
    if (mapRef.current) {
      mapRef.current.setZoom(16);
      mapRef.current.setCenter(
        getCoordinates({
          latitude: value.latitude,
          longitude: value.longitude,
        }),
      );
    }
  };

  const handleCancelChange = () => {
    setInputValue(initialValue);
    recenterMap(initialValue);
    onCancelChange?.();
  };

  const handleInputChange = (val: Location) => {
    setInputValue(val);
  };

  const handleConfirmChange = () => {
    if (!addressSelected) {
      notifyError('userForm.errorMessages.badSelectedLocation');
      return;
    }
    if (addressSelected) {
      onConfirmChange?.(inputValue);
      setInputValue(initialValue);
      recenterMap(initialValue);
    }
  };

  return (
    <Modal
      title={t('addressInput.modal.title')}
      visible={visible}
      onCancel={handleCancelChange}
      onOk={handleConfirmChange}
    >
      <LocationInput
        onLoadMap={(map: google.maps.Map) => {
          mapRef.current = map;
        }}
        value={inputValue}
        onChange={handleInputChange}
        addressSelectedState={addressSelectedState}
      />
    </Modal>
  );
};
