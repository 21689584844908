/* eslint-disable react/jsx-props-no-spreading */
import { notification } from 'antd';
import { VariantAvailable } from 'containers/Inventory/VariantAvailable/VariantAvailable';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const UpdateAvailableMassive = () => {
  const { hasAccess } = useRoleAccess(
    `routes.${ROUTES.updateAvailableMassive}`,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);
  return <VariantAvailable />;
};

export default UpdateAvailableMassive;
