import { Tabs } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const CustomTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${LightTheme.color.primary} !important;
    pointer-events: none;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        &:hover {
          color: inherit;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${LightTheme.color.primary} !important;
        }
      }
    }
  }
`;
