import { Select, Space } from 'antd';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { Variant, VariantStatus } from 'services/product/interface';
import { VariantContent, VariantStatusColor } from '../interface';
import { StatusFlag } from '../VariantTable.sty';

const { Option } = Select;

function StatusSelectRender({ variant }: { variant: VariantContent }) {
  const [status, setStatus] = useState<VariantStatus>(
    (variant.status as VariantStatus) ?? 'INACTIVE',
  );

  const onSuccessUpdate = useCallback((data: Variant) => {
    setStatus(data.status as VariantStatus);
  }, []);
  const {
    updateMutation: { mutateAsync },
  } = useModuleCRUD('variant', onSuccessUpdate);

  const handleStatusChange = useCallback(
    (val: VariantStatus) => {
      mutateAsync({ ...variant, status: val });
    },
    [mutateAsync, variant],
  );

  return (
    <Select value={status} onChange={handleStatusChange}>
      <Option value={VariantStatus.ACTIVE} key={VariantStatus.ACTIVE}>
        <Space direction="horizontal">
          <StatusFlag color={VariantStatusColor[VariantStatus.ACTIVE]} />
          {t('variantForm.labels.status.active')}
        </Space>
      </Option>
      <Option value={VariantStatus.INACTIVE} key={VariantStatus.INACTIVE}>
        <Space direction="horizontal">
          <StatusFlag color={VariantStatusColor[VariantStatus.INACTIVE]} />
          {t('variantForm.labels.status.inactive')}
        </Space>
      </Option>
    </Select>
  );
}

export default StatusSelectRender;
