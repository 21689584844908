import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  ShippingZoneUpdateAction,
  ShippingZoneUpdateState,
} from '../reducer/interface';
import {
  INITIAL_SHIPPING_ZONE_UPDATE_STATE,
  shippingZoneUpdateReducer,
} from '../reducer/shipping-zones-update.reducer';

export interface ShippingZoneUpdateContextTypes {
  state: ShippingZoneUpdateState;
  dispatch: Dispatch<ShippingZoneUpdateAction>;
}

const ShippingZoneUpdateContext = createContext<ShippingZoneUpdateContextTypes>(
  {} as ShippingZoneUpdateContextTypes,
);

export const ShippingZoneUpdateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    shippingZoneUpdateReducer,
    INITIAL_SHIPPING_ZONE_UPDATE_STATE,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <ShippingZoneUpdateContext.Provider value={value}>
      {children}
    </ShippingZoneUpdateContext.Provider>
  );
};

export const useShippingZoneUpdate = () =>
  useContext(ShippingZoneUpdateContext);
