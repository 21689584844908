/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { CustomTag } from 'components/CustomTag';
import { getAuth } from 'firebase/auth';
import { FirebaseUser, RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import { dateFormatTable } from 'helpers/utils/dateFormatCol';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  Coupon,
  CouponStatusTagColor,
  CouponType,
} from 'services/coupons/interface';

const tableColumns = (t: TFunction<'translation', undefined>) => [
  {
    title: t('coupons.name'),
    dataIndex: 'name',
    width: '140px',
  },
  {
    title: t('coupons.code'),
    dataIndex: 'id',
    width: '140px',
  },
  {
    title: t('coupons.initialDate'),
    dataIndex: 'startDate',
    width: '120px',
    render: (startDate: string) => dateFormatTable(startDate) || '',
  },
  {
    title: t('coupons.finalDate'),
    dataIndex: 'endDate',
    width: '120px',
    render: (endDate: string) => dateFormatTable(endDate) || '',
  },
  {
    title: t('coupons.status'),
    dataIndex: 'status',
    width: '120px',
    render: (coupon: Coupon, _: any) => (
      <CustomTag color={CouponStatusTagColor[_.status]}>
        {t(`couponStatus.${coupon}`) || `${coupon}` || ''}
      </CustomTag>
    ),
  },
  {
    title: t('coupons.value'),
    dataIndex: 'nominalValue',
    width: '120px',
    render: (value: number, all: any) => {
      if (all.type === CouponType.NOMINAL) {
        return numberToCurrency(value);
      }
      if (all.type === CouponType.PERCENTAGE) {
        return `${all.percentageValue}%`;
      }
      return value;
    },
  },
  {
    title: t('coupons.uses'),
    dataIndex: ['uses', 'usesCount'],
    width: '120px',
    render: (_usesRow: any, data: any) => `${data.usesCount || 0}/${data.uses}`,
  },
  {
    title: t('coupons.couponSpentUsesCount'),
    dataIndex: 'couponSpentUsesCount',
    width: '120px',
    render: (val: number) => numberToCurrency(val ?? 0),
  },
  {
    title: t('coupons.maxCouponSpent'),
    dataIndex: 'maxCouponSpent',
    width: '120px',
    render: (val: number) =>
      (val ?? -1) > 0 ? numberToCurrency(val ?? 0) : 'Ilimitado',
  },
];

const useCouponsTable = () => {
  const { t } = useTranslation();
  const auth = getAuth();
  const firebaseUser: FirebaseUser = auth.currentUser as FirebaseUser;
  const roles = JSON.parse(
    firebaseUser.reloadUserInfo?.customAttributes || '{}',
  ).role;

  const isAdmin = roles.toString().includes(RoleEnum.ADMIN);
  const defaultColumns = tableColumns(t);
  const columns = useMemo(() => {
    const hidableColumns: string[] = [t('coupons.code')];
    return !isAdmin
      ? defaultColumns.filter(
          (column) => !hidableColumns.includes(column.title),
        )
      : defaultColumns;
  }, [defaultColumns, isAdmin, t]);

  return {
    columns,
    t,
  };
};

export default useCouponsTable;
