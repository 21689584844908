/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Result, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import useGetToken from 'helpers/hooks/useGetToken/useGetToken';
import useNotifications from 'helpers/hooks/useNotifications';
import { isString } from 'lodash';
import { LayoutContainer } from 'pages/App/Layout.sty';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './CsvInput.sty';
import { CsvInputProps } from './interface';

const { Dragger } = Upload;

export const CsvInput = ({
  fetchFormat,
  isLoadingFormat,
  draggerName,
  actionUrl,
  filtersValue,
  filters,
  disabled,
  fileName,
  title,
  csvInputHint,
  allowMultiple = false,
  method = 'POST',
}: CsvInputProps) => {
  const { t } = useTranslation();

  const customMessage = {
    success: {
      message: 'variantsCsvInput.modal.actions.success',
      description: 'variantsCsvInput.modal.message.success',
    },
    error: {
      message: 'variantsCsvInput.modal.actions.error',
      description: 'variantsCsvInput.modal.message.error',
    },
  };

  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const { token } = useGetToken();

  const draggerProps: UploadProps = {
    name: draggerName,
    multiple: allowMultiple,
    action: actionUrl,
    method,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
    onChange(info: UploadChangeParam) {
      const { status } = info.file;
      if (status === 'done') {
        notifySuccess();
      } else if (status === 'error') {
        const errorCode = info.file.response.code;
        let message = t(errorCode);
        if (errorCode === message) {
          message = customMessage.error.description;
        }
        if (info.file.response.detail) {
          let { detail } = info.file.response;
          if (!isString(detail)) {
            detail = JSON.stringify(detail, null, 2);
          }
          message += `: [${detail}]`;
        }
        notifyError(message);
      }
    },
    beforeUpload(fileUpload: RcFile) {
      const { name } = fileUpload;
      const extension = name.split('.').pop();
      if (extension !== 'csv') {
        notifyError(t('variantsCsvInput.modal.message.extensionError'));
        return false;
      }
      return true;
    },
    accept: '.csv',
  };

  const handleDownloadFormat = useCallback(async () => {
    const res = await fetchFormat({
      startDeliveryDate: filtersValue?.deliveryDateFilter?.[0],
      endDeliveryDate: filtersValue?.deliveryDateFilter?.[1],
      status: filtersValue?.statusFilter,
    });
    const { data: content } = res;
    const file = new Blob([content], { type: 'text/csv' });
    const csvUrl = window.URL.createObjectURL(file);
    const tempLink = document.createElement('a');
    tempLink.href = csvUrl;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  }, [
    fetchFormat,
    fileName,
    filtersValue?.deliveryDateFilter,
    filtersValue?.statusFilter,
  ]);

  return (
    <LayoutContainer>
      <Result
        status="warning"
        title={t(title)}
        extra={
          <>
            {filters}
            <Button
              type="primary"
              loading={isLoadingFormat}
              disabled={isLoadingFormat || disabled}
              onClick={handleDownloadFormat}
            >
              {t('variantsCsvInput.buttons.fetchFormat')}
            </Button>
            <br />
            <br />
            <InputContainer>
              <Dragger {...draggerProps} style={{ padding: '0 15px' }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('variantsCsvInput.inputText')}
                </p>
                <p className="ant-upload-hint">{t(csvInputHint)}</p>
              </Dragger>
            </InputContainer>
          </>
        }
      />
    </LayoutContainer>
  );
};
