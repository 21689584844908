/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-key */
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { UserSummary } from 'components/UserSummary';
import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import UserAutocomplete from 'containers/Users/UserAutocomplete/UserAutocomplete';
import { format } from 'date-fns';
import { MAXRESCEDULE } from 'helpers/constants/Tags';
import { LightTheme } from 'helpers/constants/theme';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import dateFormat from 'helpers/utils/dateFormat';
import { numberValidator } from 'helpers/utils/formValidators';
import { t } from 'i18next';
import { uniqueId } from 'lodash';
import {
  orderUserValidator,
  validateSaleDate,
} from 'modules/Order/reducer/utils/detail.validator';
import { range2DateOption } from 'modules/Order/reducer/utils/range2options';
import { useCallback, useEffect } from 'react';
import { MPS } from 'services/MPS/interface';
import {
  Order,
  StatusOrderEnum,
  WarrantyOrderDTO,
} from 'services/order/interface';
import {
  DeliveryDateOption,
  DeliveryOption,
} from 'services/shipping/interface';
import { User } from 'services/user/interface';
import WarrantyOrdersSelector from '../../WarrantyOrderSelector';
import {
  DiscountWrapper,
  FormItem,
  FormItemMargin,
  FormNoMarginItem,
  NumberInput,
  TwoGridContainer,
} from '../OrderForm.sty';
import OrderPaymentRaiseForm from '../OrderPaymentRaiseForm';
import { DeliveryDatesImageWrapper, DeliveryDatesWrapper } from './index.sty';

const requiredRule = {
  required: true,
  message: t('ORDC400002'),
};

export interface OrderSummaryDataProps {
  orderData?: Order;
  initialValues?: Order;
  disabled?: boolean;
  disabledEditSeller: boolean;
  deliveryDateOptions: DeliveryDateOption[];
  segmentationsDisabled: boolean;
  maxDiscount: number;
  segmentationValues: string[];
  isAdmin: boolean;
  isUpdate: boolean;
  pandabonos?: number;
  onSelectUser?: (user: User) => void;
  handleDiscountValue: (value: number) => void;
  setSelectedWarrantyOptions?: (orders: WarrantyOrderDTO[]) => void;
  onChangePandabonosUse?: (checked: boolean) => void;
  deliveryOptions?: DeliveryOption[];
  form: FormInstance<Order>;
}

const OrderSummaryData = ({
  orderData,
  initialValues,
  disabled,
  disabledEditSeller,
  deliveryDateOptions,
  segmentationsDisabled,
  maxDiscount,
  segmentationValues,
  isAdmin,
  isUpdate,
  pandabonos,
  onSelectUser,
  handleDiscountValue,
  setSelectedWarrantyOptions,
  onChangePandabonosUse,
  form,
  deliveryOptions = [],
}: OrderSummaryDataProps) => {
  const onSelectDeliveryDate = useCallback(
    (selection: string, sellers: MPS[]) => {
      const actualVal = form.getFieldValue('deliveryDates') ?? {};
      const valToSet = sellers.reduce(
        (acc, seller) => ({
          ...acc,
          [seller._id ?? seller.id]: selection,
        }),
        {},
      );

      form.setFieldValue('deliveryDates', {
        ...actualVal,
        ...valToSet,
      });
    },
    [form],
  );

  // set in the form the first date as initial value
  useEffect(() => {
    const valuesToSet = deliveryOptions.reduce((valAcc, opt) => {
      const finalValue = opt.sellers.reduce(
        (acc, seller) => ({
          ...acc,
          [seller._id ?? seller.id]: opt?.dates[0]?.start?.value,
        }),
        {},
      );

      return {
        ...valAcc,
        ...finalValue,
      };
    }, {});

    form.setFieldValue('deliveryDates', valuesToSet);
  }, [deliveryOptions, form]);

  return (
    <Row gutter={[32, 16]}>
      <Col span={10} offset={2}>
        <FormItem label={t('orderTableKeys.creationDate')}>
          {dateFormat(orderData?.createdAt ?? new Date().toISOString())}
        </FormItem>
        <FormItem
          label={t('orderTableKeys.orderNumber')}
          rules={[
            {
              validator: (_, value) =>
                numberValidator(value, { required: isUpdate }, t),
            },
          ]}
        >
          <FormItem
            name="orderNumber"
            noStyle
            rules={[{ required: isUpdate, message: t('BOGENE003') }]}
          >
            {isAdmin && !isUpdate ? (
              <NumberInput
                disabled={disabled || !isUpdate}
                style={{ width: 'fit-content' }}
              />
            ) : (
              <>
                <span className="ant-form-text" style={{ paddingRight: '0' }}>
                  {`${(orderData?.orderNumber ?? '')?.split('-')?.[0]}`}
                </span>
                <span className="ant-form-text">
                  &nbsp;
                  {` - ${
                    (orderData?.orderNumber ?? '')?.split('-')?.[1] ?? ''
                  }`}
                </span>
              </>
            )}
          </FormItem>
          {initialValues?.package?.id && (
            <FormItem
              name="package"
              label={t('orderForm.tooltip.label.package')}
              style={{ color: LightTheme.color.info }}
            >
              {t('orderForm.tooltip.package', {
                package: orderData?.package?.id,
              })}
            </FormItem>
          )}
          {initialValues?.lastOrders?.length && (
            <FormItem
              label={t('orderForm.tooltip.label.lastOrders')}
              style={{ color: LightTheme.color.info }}
            >
              {t('orderForm.tooltip.lastOrders', {
                orders: initialValues.lastOrders
                  .map((o) => o.orderNumber)
                  .join(', '),
              })}
            </FormItem>
          )}
          <FormItem
            name="legalized"
            label={t('orderTableKeys.legalized')}
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={orderData?.legalized ?? false}
              disabled={
                !isUpdate ||
                disabled ||
                orderData?.status !== StatusOrderEnum.DELIVERED
              }
            />
          </FormItem>
          <FormItem
            name="validatePayment"
            label={t('orderTableKeys.validatePayment')}
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={orderData?.validatePayment ?? false}
              disabled={!isUpdate || disabled}
            />
          </FormItem>
          <FormItem name="mps" label={t('orderTableKeys.mps')}>
            {orderData?.mps?.map((m) => m.name)?.join(' | ') ?? ''}
          </FormItem>
        </FormItem>
        <FormItem
          label={t('orderTableKeys.saleDate')}
          name="saleDate"
          rules={[
            {
              validator: (r, v) =>
                validateSaleDate(r, v, orderData?.delivery?.date ?? ''),
            },
          ]}
        >
          <Input
            defaultValue={format(new Date(), 'yyyy-MM-dd')}
            disabled={
              disabled ||
              orderData?.delivery?.date !== orderData?.delivery?.updatedDate
            }
            type="date"
            max={format(
              new Date(orderData?.delivery?.date ?? new Date().toISOString()),
              'yyyy-MM-dd',
            )}
          />
        </FormItem>
        <FormItem
          label={t('orderTableKeys.deliveryDate')}
          name="deliveryDates"
          rules={[requiredRule]}
        >
          {!isUpdate &&
            deliveryOptions.map((option) => (
              <DeliveryDatesWrapper key={uniqueId()}>
                <Select
                  key={JSON.stringify(option.sellers)}
                  disabled={
                    disabled ||
                    (orderData?.delivery?.reschedule ?? 0) >= MAXRESCEDULE
                  }
                  placeholder={t('createOrder.placeholders.deliveryDate')}
                  options={option.dates.map(range2DateOption)}
                  onSelect={(value) =>
                    onSelectDeliveryDate(value, option.sellers)
                  }
                  defaultValue={option?.dates?.[0]?.start?.value}
                />
                <DeliveryDatesImageWrapper>
                  {option?.variants?.map((variant) => (
                    <ThumbnailRender
                      key={variant.id ?? variant._id}
                      variant={variant}
                      mini
                    />
                  ))}
                </DeliveryDatesImageWrapper>
              </DeliveryDatesWrapper>
            ))}
        </FormItem>
        {isUpdate && (
          <FormItem
            label={t('orderTableKeys.deliveryDate')}
            name={['delivery', 'date']}
            rules={[requiredRule]}
          >
            <Select
              disabled={
                disabled ||
                (orderData?.delivery?.reschedule ?? 0) >= MAXRESCEDULE
              }
              placeholder={t('createOrder.placeholders.deliveryDate')}
            >
              {deliveryDateOptions.map((dateOption) => (
                <Select.Option
                  value={dateOption.value}
                  key={`${dateOption.dayNumber}-${dateOption.isoDate}`}
                  disabled={dateOption.disabled}
                >
                  {dateOption.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        )}

        <DiscountWrapper>
          <FormItem label={t('createOrder.otherDiscount')}>
            <Space
              direction="horizontal"
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <Form.Item
                name="discount"
                rules={[
                  {
                    validator(_: RuleObject, value: StoreValue) {
                      if (Number(value || 0) <= maxDiscount) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('BOORDS012')));
                    },
                  },
                ]}
                noStyle
              >
                <NumberInput
                  disabled={disabled}
                  type="number"
                  max={maxDiscount}
                  min={0}
                  controls={false}
                  style={{
                    width: '100%',
                    maxWidth: '150px',
                    boxSizing: 'border-box',
                    margin: '0 !important',
                  }}
                />
              </Form.Item>
              <Select
                defaultValue="Porcentaje"
                disabled={disabled}
                onChange={(value) => handleDiscountValue(+value)}
                style={{
                  boxSizing: 'border-box',
                  marginTop: '-2px !important',
                  height: '100%',
                }}
              >
                <Select.Option value="0">0%</Select.Option>
                <Select.Option value="10">10%</Select.Option>
                <Select.Option value="20">20%</Select.Option>
                <Select.Option value="30">30%</Select.Option>
                <Select.Option value="40">40%</Select.Option>
                <Select.Option value="50">50%</Select.Option>
              </Select>
            </Space>
          </FormItem>
          <FormItem
            label={t('createOrder.segmentation')}
            name="segmentation"
            style={{ width: '180px', margin: '10px 5px' }}
          >
            <Select
              defaultValue=""
              disabled={disabled || segmentationsDisabled}
            >
              {segmentationValues?.map((value: string) => (
                <Select.Option value={value} key={value}>
                  {t(`couponSeg.${value}`)}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </DiscountWrapper>
        {orderData?.freeShippingCost && (
          <b>{t('createOrder.freeShippingCost')}</b>
        )}
        <FormItem label={t('orderTableKeys.observation')} name="comments">
          <Input.TextArea disabled={disabled} />
        </FormItem>
      </Col>
      <Col span={10}>
        <FormItem
          label={t('orderForm.labels.user')}
          required
          rules={[{ validator: orderUserValidator }]}
          name="user"
        >
          <UserAutocomplete
            onChange={onSelectUser}
            queryParams={{
              pagination: {
                page: 1,
                pageSize: 10,
              },
            }}
            disabled={disabled || isUpdate}
          />
        </FormItem>
        <TwoGridContainer>
          <FormItem
            label={t('orderTableKeys.client')}
            style={{ width: '250px' }}
          >
            {`${orderData?.user?.name ?? ''} ${
              orderData?.user?.firstLastName ?? ''
            }`}
            <br />
            {`${orderData?.user?.documentType ?? ''}. ${
              orderData?.user?.documentNumber ?? ''
            }`}
          </FormItem>
          <FormItemMargin style={{ marginTop: '15px' }}>
            <FormNoMarginItem label={t('orderTableKeys.principal')}>
              {orderData?.user?.localization?.departmentName}
            </FormNoMarginItem>
            <FormNoMarginItem label={t('orderTableKeys.secondary')}>
              {orderData?.user?.localization?.cityName}
            </FormNoMarginItem>
          </FormItemMargin>
        </TwoGridContainer>
        <FormItem label={t('orderForm.labels.seller')} name="seller">
          <UserAutocomplete
            queryParams={{
              roleFilter: [RoleEnum.SELLER],
              pagination: {
                page: 1,
                pageSize: 10,
              },
            }}
            disabled={disabled || disabledEditSeller}
          />
        </FormItem>
        <Row>
          <Col span={24} offset={0}>
            <Divider />
            <FormItem name="sellerCoordinator">
              <Typography.Title level={5}>
                {t('userForm.orderSellerTitle.seller')}
              </Typography.Title>
              {orderData?.seller && <UserSummary user={orderData.seller} />}
            </FormItem>
            <Divider />
            <FormItem>
              <Typography.Title level={5}>
                {t('userForm.orderSellerTitle.sellerCoordinator')}
              </Typography.Title>
              {orderData?.sellerCoordinator && (
                <UserSummary user={orderData.sellerCoordinator} />
              )}
            </FormItem>
            <Divider />
            {isUpdate ? (
              <OrderPaymentRaiseForm
                disabled={disabled ?? false}
                totalRaised={orderData?.totalRaised ?? 0}
              />
            ) : (
              <>
                <FormItem
                  label={t('createOrder.placeholders.usePandabonos')}
                  valuePropName="checked"
                >
                  <Checkbox
                    value={orderData?.payment?.pandabonos?.use ?? false}
                    disabled={(pandabonos ?? 0) === 0}
                    onChange={(e) => onChangePandabonosUse?.(e.target.checked)}
                  />
                </FormItem>
                <FormItem
                  name={['freeShippingCost']}
                  label={t('createOrder.nonDeliveryCost')}
                  valuePropName="checked"
                >
                  <Checkbox />
                </FormItem>
                <FormItem
                  name={['isWarranty']}
                  label={t('createOrder.placeholders.isWarranty')}
                  valuePropName="checked"
                >
                  <Checkbox />
                </FormItem>
                {orderData?.isWarranty && setSelectedWarrantyOptions && (
                  <WarrantyOrdersSelector
                    selectedWarrantyOptions={orderData.warrantyOrders ?? []}
                    setSelectedWarrantyOptions={setSelectedWarrantyOptions}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderSummaryData;
