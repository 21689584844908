/* eslint-disable no-underscore-dangle */
import { Col, Row, Select, Switch, TreeSelect } from 'antd';
import { t } from 'i18next';
import { useMemo } from 'react';
import { TargetBrand } from 'services/coupons/interface';
import { TargetCategory } from '../../Categories/CategoryForm/interface/index';
import { FormItem } from './CouponModal.sty';
import { requiredRule } from './CouponValidations.schema';
import { CouponProductSegSubFormProps } from './interface';

export const CouponProductSegSubForm = ({
  categoryTree,
  isCategorySegmentation,
  isBrandSegmentation,
  brands,
  isSponsoredCoupon,
  sellers,
  isUpdate,
  readOnly,
  getFieldValue,
}: CouponProductSegSubFormProps) => {
  const productSegType = useMemo(() => {
    const isCategorySeg = getFieldValue?.('categorySegmentation');
    const isBrandSeg = getFieldValue?.('brandSegmentation');

    if (isCategorySeg) return t('couponProductSegType.BY_CATEGORY');
    if (isBrandSeg) return t('couponProductSegType.BY_BRAND');

    return t('couponProductSegType.ALL_PRODUCTS');
  }, [getFieldValue]);

  const targetCategories = getFieldValue?.('targetCategories');
  const targetCategoriesList = targetCategories
    ?.map((category: TargetCategory) => category.name)
    .join(', ');

  const targetBrands = getFieldValue?.('targetBrands');
  const targetBrandsList = targetBrands
    ?.map((brand: TargetBrand) => brand.name)
    .join(', ');

  const targetSellers = getFieldValue?.('targetSellers');
  const targetSellersList = targetSellers
    ?.map((brand: TargetBrand) => brand.name)
    .join(', ');

  const rules = useMemo(() => {
    if (!isUpdate) {
      return [requiredRule];
    }
    return [];
  }, [isUpdate]);

  return (
    <Row gutter={[16, 8]}>
      <Col xs={24} md={12}>
        <FormItem
          label={t('couponModal.form.labels.productSegmentationType')}
          name="productSegmentation"
          rules={[requiredRule]}
        >
          {readOnly || isUpdate ? (
            productSegType
          ) : (
            <Select disabled={readOnly}>
              <Select.Option value="NONE">
                {t('couponProductSegType.ALL_PRODUCTS')}
              </Select.Option>
              <Select.Option value="CATEGORY">
                {t('couponProductSegType.BY_CATEGORY')}
              </Select.Option>
              <Select.Option value="BRAND">
                {t('couponProductSegType.BY_BRAND')}
              </Select.Option>
              <Select.Option value="SELLER">
                {t('couponProductSegType.BY_SELLER')}
              </Select.Option>
            </Select>
          )}
        </FormItem>
        {isCategorySegmentation ? (
          <FormItem
            label={t('couponModal.form.labels.targetCategories')}
            name="selectedCategories"
            rules={rules}
          >
            {readOnly || isUpdate ? (
              targetCategoriesList
            ) : (
              <TreeSelect
                treeData={categoryTree || []}
                treeDefaultExpandAll={false}
                multiple
              />
            )}
          </FormItem>
        ) : null}
        {isBrandSegmentation ? (
          <FormItem
            label={t('couponModal.form.labels.targetBrands')}
            name="selectedBrands"
            rules={rules}
          >
            {readOnly || isUpdate ? (
              targetBrandsList
            ) : (
              <Select
                options={brands?.map((b) => ({ label: b.name, value: b.id }))}
                mode="multiple"
                allowClear
              />
            )}
          </FormItem>
        ) : null}
        {isSponsoredCoupon && (
          <FormItem
            label={t('couponModal.form.labels.targetSellers')}
            name="selectedSellers"
            rules={rules}
          >
            {readOnly || isUpdate ? (
              targetSellersList
            ) : (
              <Select
                options={sellers?.map((b) => ({ label: b.name, value: b._id }))}
                allowClear
              />
            )}
          </FormItem>
        )}

        <FormItem hidden name="targetCategories" />
        <FormItem hidden name="targetBrands" />
        <FormItem hidden name="targetSellers" />
      </Col>
    </Row>
  );
};
