import { AxiosError } from 'axios';
import { t } from 'i18next';

export class PandasError {
  code: string;

  statusCode: number;

  message: string;

  detail?: any;

  constructor({
    code,
    statusCode,
    message,
    detail,
  }: {
    code: string;
    statusCode: number;
    message: string;
    detail?: any;
  }) {
    this.code = code;
    this.statusCode = statusCode;
    this.message = message;
    this.detail = detail;
  }
}

export const parsePandasErr = (
  error: unknown,
  fallbackCode: string,
): PandasError => {
  if (error instanceof AxiosError && error.response) {
    return new PandasError(error.response.data);
  }

  return new PandasError({
    code: t(fallbackCode),
    statusCode: 500,
    message: 'Unhandled Error',
  });
};
