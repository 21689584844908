import { Row, Col } from 'antd';
import { t } from 'i18next';
import { SubmitButton } from './CouponModal.sty';
import { CouponModalFooterProps } from './interface';

const CouponModalFooter = ({
  isUpdate,
  isLoading,
  onClickFooter,
  readOnly,
}: CouponModalFooterProps) => {
  if (readOnly) return null;

  return (
    <Row style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Col span={20} />
      <Col span={4}>
        <SubmitButton
          loading={isLoading}
          type="primary"
          onClick={onClickFooter}
        >
          {t(
            isUpdate
              ? 'couponModal.buttonTitles.submit.update'
              : 'couponModal.buttonTitles.submit.create',
          )}
        </SubmitButton>
      </Col>
    </Row>
  );
};

export default CouponModalFooter;
