import { useQuery } from '@tanstack/react-query';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { getWmsSettings } from 'services/wms-settings/wms-settings.service';
import { SettingListState } from '../reducer/interface';

const useReadSettingList = ({ state }: { state: SettingListState }) => {
  const {
    list: data,
    isFetching: isLoading,
    nextPage: nextListOrdersPage,
    listError: ordersListError,
    refetch: RefetchList,
  } = useInfiniteScroll({
    filters: {
      page: state.page,
      pageSize: state.pageSize,
    },
    fetchData: getWmsSettings,
    name: ['settings', 'list'],
  });

  return {
    data,
    isLoading,
  };
};

export default useReadSettingList;
