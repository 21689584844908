/* eslint-disable @typescript-eslint/no-empty-function */
import { FormInstance } from 'antd';
import { IBanner, IBannerForm } from 'services/parameters/interface';
import { CreateBannerFormInitialValues } from '../constants';

export enum BannerModalTypeEnum {
  create = 'create',
  update = 'update',
  order = 'order',
}

export interface IBannerContext {
  banners: IBanner[];
  draftBanners: IBanner[];
  form: FormInstance<IBannerForm>;
  isLoading: boolean;
  updateLoading: boolean;
  modalVisible: boolean;
  modalType: BannerModalTypeEnum;
  selectedBanner?: IBanner;
  bannerFormInitialValues: IBannerForm;
  setDraftBanners: (banners: IBanner[]) => void;
  openCreateModal: () => void;
  openEditModal: (banner: IBanner) => void;
  openOrderModal: (banner: IBanner) => void;
  onModalOk: () => void;
  closeModal: () => void;
  onBannerDelete: (banner: IBanner) => Promise<void>;
  formAction: (banner: IBannerForm) => Promise<void>;
}

export const BANNER_CONTEXT_DEFAULT_VALUES: IBannerContext = {
  banners: [],
  isLoading: true,
  updateLoading: false,
  modalVisible: false,
  modalType: BannerModalTypeEnum.create,
  bannerFormInitialValues: CreateBannerFormInitialValues,
  draftBanners: [],
  form: {} as any,
  onBannerDelete: async () => {},
  formAction: async () => {},
  onModalOk: () => {},
  closeModal: () => {},
  openEditModal: () => {},
  openCreateModal: () => {},
  openOrderModal: () => {},
  setDraftBanners: () => {},
};

export interface useBannersApiProps {
  state: BannersState;
  dispatch: React.Dispatch<BannersAction>;
}

export enum BannersEventType {
  SET_BANNERS = 'SET_BANNERS',
  SET_DRAFT_BANNERS = 'SET_DRAFT_BANNERS',
  SET_SELECTED_BANNER = 'SET_SELECTED_BANNER',
  SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE',
  SET_MODAL_TYPE = 'SET_MODAL_TYPE',
}

export interface BannersState {
  banners: IBanner[];
  draftBanners: IBanner[];
  selectedBanner?: IBanner;
  modalVisible: boolean;
  modalType: BannerModalTypeEnum;
}

export const BannersStateInitialValues: BannersState = {
  banners: [],
  draftBanners: [],
  selectedBanner: undefined,
  modalType: BannerModalTypeEnum.create,
  modalVisible: false,
};

export interface BannersAction {
  type: BannersEventType;
  payload: any;
}
