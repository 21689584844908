/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, Divider, Input, Row, Table, TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'helpers/constants/routes';
import { useTranslation } from 'react-i18next';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { TableWrapper } from 'components/TableWrapper';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import { UserContent, UserListResponse } from './interface';
import useUserTableModule from './hooks/useUserTableModule';

const TABLE_HEADER_HEIGHT = 150;

export const UserTable = ({
  data,
  isLoading,
  onUpdateUser,
  onDeleteUser,
  onSetPagination,
  onSetRoleFilter,
  onChangeSearch,
}: {
  data: UserListResponse;
  isLoading: boolean;
  onDeleteUser: (user: UserContent) => void;
  onUpdateUser: (user: UserContent) => void;
  onSetPagination: (paginationData: PaginationParams) => void;
  onSetRoleFilter: (roleFilterString: string[]) => void;
  onChangeSearch: (search: string) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { columns, hiddenDescription } = useUserTableModule({
    onUpdateUser,
    onDeleteUser,
  });

  const goToCreate = () => {
    navigate(`${ROUTES.createUser}`);
  };

  const onChange: TableProps<UserContent>['onChange'] = (
    pagination,
    filters,
  ) => {
    const rolesColumnIndex =
      columns.findIndex(
        (row) => row.title === t('user.userListColumns.roles'),
      ) + 1;
    const roleFilter = filters[rolesColumnIndex];
    onSetRoleFilter((roleFilter as string[]) || []);
  };

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  };
  const tableSize = useTableSize(TABLE_HEADER_HEIGHT);
  return (
    <>
      <Row>
        <Col xs={{ span: 16 }} lg={{ span: 8 }}>
          <Input.Search onChange={onChangeSearchValue} />
        </Col>
        <Col xs={{ span: 6, offset: 1 }} lg={{ span: 4, offset: 1 }}>
          <Button type="primary" onClick={goToCreate}>
            {t('user.createUser')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <TableWrapper>
        <Table
          {...tableSize}
          rowKey="id"
          loading={isLoading}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => hiddenDescription(record),
            rowExpandable: () => true,
          }}
          onChange={onChange}
          pagination={{
            total: data.metadata?.length || 0,
            current: data.metadata?.page || 1,
            defaultPageSize: data.metadata?.pageSize || 50,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000'],
            onChange(page, pageSize) {
              onSetPagination({ page, pageSize });
            },
          }}
          dataSource={data.data || []}
        />
      </TableWrapper>
    </>
  );
};
