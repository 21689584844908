import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Layout, Typography } from 'antd';
import styled from 'styled-components';
import { BaseColors, LightTheme } from '../../helpers/constants/theme';

const { Sider } = Layout;

export const DrawerBodyStyle = {
  paddingRight: '0',
  paddingLeft: '0',
};

export const DrawerHeaderStyle = {
  backgroundColor: LightTheme.color.dark_alt,
  borderColor: LightTheme.color.dark_alt,
};

export const DrawerStyle = {
  backgroundColor: LightTheme.color.dark_alt,
};

export const CloseDrawer = styled(CloseOutlined)`
  color: ${({ theme }) => theme.color.white};
`;

export const MenuDrawer = styled(Drawer)`
  @media screen and (${({ theme }) => theme.queries.sm}) {
    display: none;
  }
`;

export const MenuSider = styled(Sider)`
  @media screen and (${({ theme }) => theme.queries.xs}) {
    display: none;
  }
`;

export const LetterLogoContainer = styled.figure`
  height: 32px;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LetterLogoImg = styled.img`
  max-width: 100%;
  height: auto;
`;
export const ContainerLayout = styled.div`
  height: 100%;
  position: relative;
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #fff !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #ffffff99 !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #ffffff99 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${LightTheme.color.primary};
  }
  .ant-tabs-ink-bar {
    background: ${LightTheme.color.primary} !important;
  }
`;
export const ContentLayout = styled.div`
  height: 100%;
  margin: 0;
  padding: 0 40px 0 40px;
  max-height: calc(100vh - 70px);
  position: relative;
`;
export const BackLayout = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 35%;
  background-color: ${({ theme }) => theme.color.link};
`;

export const Title = styled(Typography.Title)`
  font-size: 38px;
  color: ${LightTheme.color.white} !important;
`;

export const UpdateTitle = styled(Typography.Title)`
  font-size: 38px;
  color: ${BaseColors.deep_blue} !important;
`;

export const LayoutContainer = styled.div`
  padding: 40px 20px;
  background-color: #eff3f8;
  border-radius: 6px;
  height: calc(100% - 100px);
  width: 100%;
`;

export const LayoutContainerScroll = styled(LayoutContainer)`
  height: calc(100vh - 263px);
  overflow: auto;
`;

export const MenuSiderContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
