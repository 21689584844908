import { ColProps } from 'antd';
import { IBannerForm } from 'services/parameters/interface';

export const PARAMS_BANNERS_KEY = 'banners';
export const BANNER_ORDER_MODAL_WIDTH = '40%';
export const bannersQueryKey = 'banners';

export const bannerColSizes: ColProps = {
  xxl: 6,
  xl: 8,
  lg: 10,
  md: 12,
  xs: 24,
  sm: 24,
};

export const CreateBannerFormInitialValues: IBannerForm = {
  link: '',
  image: null,
  description: '',
  title: '',
};
