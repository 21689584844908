import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const UploadContent = ({ isLoading }:
{ isLoading:boolean}) => {
  const { t } = useTranslation();
  return (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('upload.upload')}</div>
    </div>
  );
};

export default UploadContent;
