/* eslint-disable no-param-reassign */
import { Input, Tag } from 'antd';
import { ProductFigure } from 'components/AutoCompleteVariantItem/AutoCompleteVariantItem.sty';
import { TableWrapper } from 'components/TableWrapper';
import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { cartAtom } from 'helpers/recoil/atoms/cart';
import { computeVolumeDiscount } from 'helpers/utils/computeVolumeDiscount';
import { numberToCurrency } from 'helpers/utils/currency';
import { t } from 'i18next';
import _ from 'lodash';
import { LayoutContainer, Title } from 'pages/App/Layout.sty';
import React, { RefObject, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CART_ITEM_COLORS } from 'services/cart/interface';
import { CartVariant, Variant, VariantImage } from 'services/product/interface';
import styled from 'styled-components';

export const CreateProductContainer = styled(LayoutContainer)`
  padding-top: 40px;
`;
export const InputContainer = styled.div`
  padding: 0 20px;
`;

export interface ProductsTableProps {
  list: Variant[];
  onChangeQuantity: (product: Variant, quantity: number) => void;
  fixHeight?: number;
  id: string;
  isLoading?: boolean;
  onScrollToEnd?: () => void;
  reachedEndOfData: boolean;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
}

export interface ProductsProps {
  list: Variant[];
  onChangeQuantity: (product: Variant, quantity: number) => void;
  onSearch: (value: string) => void;
  onScrollToEnd?: () => void;
  id: string;
  isLoading?: boolean;
  reachedEndOfData: boolean;
  showTitle: boolean;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
}

const TABLE_HEADER_HEIGHT = 200;

const columns = (
  onChangeQuantity: (index: CartVariant, cardQuantity: number) => void,
  variantsAdded: { [key: string]: CartVariant },
) => [
  {
    title: 'Imagen',
    dataIndex: 'images',
    render: (images: VariantImage[], record: Variant) => (
      <ProductFigure>
        <img
          src={
            images?.length
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${images[0].url}`
              : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
          }
          alt={record.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
          }}
        />
      </ProductFigure>
    ),
  },
  {
    title: 'Producto',
    dataIndex: 'name',
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    render: (price: number | undefined) => numberToCurrency(price || 0),
  },
  {
    title: 'Precio con descuento',
    dataIndex: 'discount',
    render: (discount: number, variant: CartVariant) => {
      const variantClone = _.cloneDeep(variant);
      variantClone.cartQuantity = variantsAdded[variant.id]?.cartQuantity ?? 0;
      return numberToCurrency(computeVolumeDiscount(variantClone));
    },
  },
  {
    title: 'Status',
    render: (cartVariant: CartVariant) => {
      const { status, stock } = cartVariant;
      const quantity = stock?.available ?? 0;
      if (status) {
        return (
          <Tag color={CART_ITEM_COLORS[cartVariant.status || 'IN_STOCK']}>
            {t(`stockStatus.${cartVariant.status || 'IN_STOCK'}`)}
          </Tag>
        );
      }
      if (quantity > 0) {
        return (
          <Tag color={CART_ITEM_COLORS.IN_STOCK}>
            {t('stockStatus.IN_STOCK')}
          </Tag>
        );
      }
      return (
        <Tag color={CART_ITEM_COLORS.OUT_OF_STOCK}>
          {t('stockStatus.OUT_OF_STOCK')}
        </Tag>
      );
    },
  },
  {
    title: 'Stock',
    render: (record: CartVariant) => record.stock?.available ?? 0,
  },
  {
    title: 'Cantidad',
    editable: true,
    render: (_cartQuantity: number, record: CartVariant) => (
      <Input
        disabled={(record.stock?.available ?? 0) <= 0}
        style={{ width: 70 }}
        value={variantsAdded[record.id]?.cartQuantity || undefined}
        onChange={(e) => {
          onChangeQuantity(record, Number(e.currentTarget.value));
        }}
      />
    ),
  },
];

export const ProductsTable = ({
  list,
  onChangeQuantity,
  fixHeight,
  reachedEndOfData,
  onScrollToEnd,
  isLoading,
  id,
  tableRef,
}: ProductsTableProps) => {
  const variantsAdded = useRecoilValue(cartAtom);
  const getColumns = useMemo(
    () => columns(onChangeQuantity, variantsAdded),
    [onChangeQuantity, variantsAdded],
  );

  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={fixHeight || TABLE_HEADER_HEIGHT}
      rowKey="id"
      id={id}
      isLoading={isLoading}
      columns={getColumns}
      dataSource={list}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
      pagination={false}
    />
  );
};

export const Products = ({
  list = [],
  onChangeQuantity,
  onSearch,
  isLoading,
  onScrollToEnd,
  reachedEndOfData,
  id,
  tableRef,
  showTitle,
}: ProductsProps) => {
  const [filter, setFilter] = useState('');

  const onChangeFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(e.currentTarget.value);
      setFilter(e.currentTarget.value);
    },
    [onSearch],
  );

  return (
    <>
      {showTitle ? (
        <Title level={1}>{t('createOrder.pickProducts')}</Title>
      ) : null}
      <CreateProductContainer>
        <InputContainer>
          <Input
            value={filter}
            onChange={onChangeFilter}
            placeholder="Búsqueda"
          />
        </InputContainer>
        <TableWrapper>
          <ProductsTable
            tableRef={tableRef}
            list={list}
            onChangeQuantity={onChangeQuantity}
            onScrollToEnd={onScrollToEnd}
            reachedEndOfData={reachedEndOfData}
            isLoading={isLoading}
            id={id}
          />
        </TableWrapper>
      </CreateProductContainer>
    </>
  );
};
