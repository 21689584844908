import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { skipShippingPayment } from 'services/order/order.service';

const useSkipShipping = ({
  id,
  notifySuccess,
  notifyError,
  refetchOrder,
}: {
  id: string;
  notifySuccess: (msg?: string) => void;
  notifyError: (msg?: string) => void;
  refetchOrder: () => void;
}) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => skipShippingPayment(id),
  });

  const skipShipping = useCallback(async () => {
    try {
      await mutateAsync();
      notifySuccess();
      refetchOrder();
    } catch {
      notifyError();
    }
  }, [mutateAsync, refetchOrder, notifyError, notifySuccess]);

  return {
    isLoading,
    skipShipping,
  };
};

export default useSkipShipping;
