import { DEFAULT_PAGE_SIZE_VALUE } from 'helpers/constants/http';
import useInfiniteRead from 'helpers/hooks/useModuleCRUD/useInfiniteRead';
import { isNotNil } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { WalletActionEnum, WalletTransaction } from 'services/wallet/interface';
import { getWalletTranstions } from 'services/wallet/wallet.service';
import { WalletProps } from '../interface';

const useWalletApi = ({ state, dispatch, tableRef }: WalletProps) => {
  const { walletTransactionList, user, search, status } = state;

  const orderListParams = useMemo(
    () => ({
      search,
      status,
      user,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE_VALUE,
    }),
    [search, status, user],
  );

  const {
    readInfiniteQuery: {
      fetchNextPage,
      refetch,
      data,
      hasNextPage,
      isFetching,
    },
  } = useInfiniteRead(
    'wallet',
    orderListParams,
    tableRef.current?.scrollToTop,
    true,
  );

  const fetchInitialData = useCallback(async () => {
    await getWalletTranstions({
      search: '',
      user: '',
      page: 1,
      status: '',
      pageSize: 20,
    });
  }, []);

  const walletList: WalletTransaction[] = useMemo(
    () =>
      data?.pages
        ?.flat()
        .map((item) => item?.data)
        ?.flat() ?? [],
    [data],
  );

  useEffect(() => {
    if (!isFetching) {
      dispatch({
        type: WalletActionEnum.SET_TRANSACTION_LIST,
        payload: walletList,
      });
    }
  }, [walletList, isFetching, dispatch]);

  useEffect(() => {
    if (isNotNil(walletTransactionList)) {
      dispatch({
        type: WalletActionEnum.SET_TRANSACTION_LIST,
        payload: walletTransactionList,
      });
    }
  }, [walletTransactionList, dispatch]);

  return {
    data: walletList,
    isFetching,
    hasNextPage,
    refetch,
    fetchNextPage,
    fetchInitialData,
  };
};

export default useWalletApi;
