/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Select, Spin, Tag } from 'antd';
import useComplexSelect from './hooks/useComplexSelect';
import { ComplexSelectProps } from './interface';

function ComplexMultiSelect<ValueType = any, FormType = any>(
  props: Readonly<ComplexSelectProps<ValueType, FormType>>,
) {
  const {
    selectProps,
    renderValue,
    form,
    indexDataProp,
    formValueName,
    loadingData = false,
    formDisabled,
    placeholder,
  } = props;

  const { handleSearch, handleSelect, handleDeselect, options } =
    useComplexSelect<ValueType, FormType>(props);

  const values = Form.useWatch([formValueName], form) as ValueType[];

  return (
    <div>
      <Select<string, any>
        {...selectProps}
        onSearch={handleSearch}
        loading={loadingData}
        onSelect={handleSelect}
        options={loadingData ? [] : options}
        notFoundContent={loadingData ? <Spin size="small" /> : null}
        showSearch
        placeholder={placeholder}
      />
      <div>
        {values?.map((v) => (
          <Tag
            key={String(v[indexDataProp])}
            onClose={() => handleDeselect(v)}
            closable={!formDisabled}
          >
            {renderValue?.(v)}
          </Tag>
        ))}
      </div>
    </div>
  );
}

export default ComplexMultiSelect;
