import { BrandEntity, BrandFormInterface } from '../interface';

export const parseToBrandEntity = (
  formValues: BrandFormInterface,
): BrandEntity => ({
  name: formValues.name,
  logo: {
    url: formValues.logo?.url ?? '',
    id: formValues.logo?.uid ?? '',
    name: formValues.logo?.name ?? '',
  },
  ranking: Number(formValues.ranking),
});
