import { format } from 'date-fns';

export const dateFormat = (dataString: string) => {
  try {
    return format(
      new Date(
        !dataString.includes('T') ? `${dataString}T00:00:00` : dataString,
      ),
      'dd/MM/yyyy',
    );
  } catch (e) {
    return dataString;
  }
};

export const stringDateFormat = (Currentdate: string) => {
  const dateString = Currentdate;
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const mounth = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  const hora = date.getUTCHours();
  const minutos = date.getUTCMinutes();

  const formatedDat = `${day < 10 ? '0' : ''}${day}/${
    mounth < 10 ? '0' : ''
  }${mounth}/${year}`;
  const formatedHour = `${hora < 10 ? '0' : ''}${hora}:${
    minutos < 10 ? '0' : ''
  }${minutos}`;

  return `${formatedDat}-${formatedHour}`;
};

export default dateFormat;
