/* eslint-disable react/jsx-no-useless-fragment */
import { Col, Row, Tag } from 'antd';
import {
  RoleEnum,
  RoleTagColorCode,
} from 'helpers/hooks/useRoleAccess/interface';
import { t } from 'i18next';
import { FormItem } from './CouponModal.sty';
import { CouponOtherInfoSubFormProps } from './interface';

const CouponOthersInfoSubForm = ({ coupon }: CouponOtherInfoSubFormProps) => (
  <>
    {coupon?.createdBy && (
      <Row gutter={[16, 8]}>
        <Col xs={8} md={12}>
          <FormItem label={t('couponModal.form.labels.createdBy.name')}>
            {coupon?.createdBy?.name}
          </FormItem>
          <FormItem label={t('couponModal.form.labels.createdBy.roles')}>
            {coupon?.createdBy?.role?.map((role) => (
              <Tag color={RoleTagColorCode[role]}>{RoleEnum[role]}</Tag>
            ))}
          </FormItem>
        </Col>
      </Row>
    )}
  </>
);

export default CouponOthersInfoSubForm;
