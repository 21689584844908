import { Form, Input, InputNumber } from 'antd';
import { numberToCurrency } from 'helpers/utils/currency';
import { numberValidator, stringValidator } from 'helpers/utils/formValidators';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentRaiseArticle,
  PaymentRaiseFormItem,
} from './OrderPaymentRaiseForm.sty';

interface OrderPaymentRaiseFormProps {
  disabled: boolean;
  totalRaised: number;
}

export interface OrderPaymentRaise {
  amount: number;
  paymentMethod: string;
  comments?: string;
}

const OrderPaymentRaiseForm = ({
  disabled,
  totalRaised,
}: OrderPaymentRaiseFormProps) => {
  const { t } = useTranslation();

  const paymentRaiseValidator = useCallback(
    async (_: unknown, value: Record<string, string | number>) => {
      if (!value) {
        return Promise.resolve();
      }
      const requiredFields: Record<string, boolean> = {
        amount: true,
        paymentMethod: true,
      };

      const valuesKeys = Object.keys(value);
      let requiredFieldsCount = 0;

      valuesKeys.forEach((k) => {
        if (requiredFields[k] && value[k]) {
          requiredFieldsCount += 1;
        }
      });

      if (
        requiredFieldsCount > 0 &&
        requiredFieldsCount < Object.keys(requiredFields).length
      ) {
        throw new Error(t('paymentRaiseForm.errors.wrongFields'));
      }

      await stringValidator(
        value.paymentMethod as string,
        { required: true },
        t,
      );
      await numberValidator(value.amount as number, { required: true }, t);

      return Promise.resolve();
    },
    [t],
  );

  return (
    <Form.Item
      name="raisedPayments"
      rules={[{ required: false, validator: paymentRaiseValidator }]}
    >
      <PaymentRaiseArticle>
        <p>{t('paymentRaiseForm.title')}</p>
        <b>{numberToCurrency(totalRaised ?? 0)}</b>
      </PaymentRaiseArticle>
      <PaymentRaiseFormItem
        name={['raisedPayments', 'paymentMethod']}
        required
        label={t('paymentRaiseForm.labels.paymentMethod')}
      >
        <Input
          disabled={disabled}
          placeholder={t('paymentRaiseForm.placeholders.paymentMethod')}
        />
      </PaymentRaiseFormItem>
      <PaymentRaiseFormItem
        name={['raisedPayments', 'amount']}
        required
        label={t('paymentRaiseForm.labels.amount')}
      >
        <InputNumber
          disabled={disabled}
          placeholder={t('paymentRaiseForm.placeholders.amount')}
          style={{
            width: '100%',
          }}
        />
      </PaymentRaiseFormItem>
      <PaymentRaiseFormItem
        name={['raisedPayments', 'comments']}
        label={t('paymentRaiseForm.labels.comments')}
      >
        <Input
          disabled={disabled}
          placeholder={t('paymentRaiseForm.placeholders.comments')}
        />
      </PaymentRaiseFormItem>
    </Form.Item>
  );
};

export default OrderPaymentRaiseForm;
