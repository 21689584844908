import { Button } from 'antd';
import { t } from 'i18next';
import { LocationValue } from './interface';
import {
  DisplayInputWrapper,
  AddressWrapper,
  AddressValueWrapper,
  DetailsValueWrapper,
} from './LocationInput.sty';

const LocationDisplay = ({
  value,
  onEdit,
}: {
  value: LocationValue;
  onEdit: (params?: any) => void;
}) => (
  <DisplayInputWrapper>
    {(value.address || value.details) && (
      <AddressWrapper>
        {value.address && (
          <AddressValueWrapper>{value.address || ''}</AddressValueWrapper>
        )}
        {value.details && (
          <DetailsValueWrapper>{value.details || ''}</DetailsValueWrapper>
        )}
      </AddressWrapper>
    )}
    <Button type="default" onClick={onEdit}>
      {t('addressInput.update')}
    </Button>
  </DisplayInputWrapper>
);

export default LocationDisplay;
