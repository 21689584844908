/* eslint-disable react/require-default-props */
import { Col, Image, Row } from 'antd';
import Error from 'assets/images/Error.svg';
import { buildOptionsDropdown } from 'helpers/utils/buildOptionsForDropdown';
import { t } from 'i18next';
import { useMemo } from 'react';
import { ActionModalEnum } from '../CancelOrderModal';
import {
  CustomLabel,
  CustomSelectLarge,
  DefaultBigCol,
  DefaultCol,
  ModalWrapper,
} from '../CancelOrderModal/ConfirmActionModal.sty';

interface RescheduleOrderModalProps {
  onCancelReschedule: () => void;
  setRescheduleReason: (reason: string) => void;
  onOkReschedule: () => void;
  open: boolean;
  rescheduleReason: string;
  orderNumber: string;
  reasonOptions: string[];
}

const RescheduleOrderModal = ({
  onCancelReschedule,
  setRescheduleReason,
  onOkReschedule,
  open,
  rescheduleReason,
  orderNumber,
  reasonOptions,
}: RescheduleOrderModalProps) => {
  const type = useMemo(() => ActionModalEnum.RESCHEDULE, []);
  return (
    <ModalWrapper
      open={open}
      color={type}
      disableButton={!rescheduleReason}
      onOk={onOkReschedule}
      onCancel={onCancelReschedule}
      okButtonProps={{
        disabled: !rescheduleReason,
        className: 'button-modal',
      }}
      destroyOnClose
      centered
    >
      <Row>
        <Col>
          <Image alt="modal-icon" src={Error} width={60} />
        </Col>
        <CustomLabel color={type}>
          {t(`ActionModal.${type}`, { orderNumber })}
        </CustomLabel>
      </Row>
      <Row>
        <DefaultCol />
        <DefaultBigCol>
          <CustomSelectLarge
            placeholder={t('orderForm.modal.reason.cancel')}
            onSelect={(value) => setRescheduleReason(value as string)}
            options={buildOptionsDropdown(reasonOptions)}
          />
        </DefaultBigCol>
      </Row>
    </ModalWrapper>
  );
};

export default RescheduleOrderModal;
