import { Col, Divider, Row, Select } from 'antd';
import { CustomTag } from 'components/CustomTag';
import MPSUserModal from 'components/MPSUserModal/MPSUserModal';
import { TableWrapper } from 'components/TableWrapper';
import { Title } from 'pages/App/Layout.sty';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  mpsRequestStatusColors,
  mpsRequestStatusEnum,
  MPSUserModalAction,
} from 'services/MPSUser/interface';
import { MPSUserTable } from './MPSUserTable/MPSUserTable';
import useMPSUserTable from './MPSUserTable/useMPSUserTable';

function MPSListContainer() {
  const { t } = useTranslation();
  const tableRef = useRef<{ scrollToTop: () => void }>(null);

  const {
    isModalOpen,
    closeModal,
    modalActionType,
    initialValues,
    requestList,
    columns,
    nextPage,
    refetch,
    isFetchingList,
    handleChangeRequestStatus,
    status,
  } = useMPSUserTable();

  return (
    <>
      <Title level={1}>{t('menu.mpsList')}</Title>
      <MPSUserModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        modalActionType={modalActionType ?? MPSUserModalAction.UPDATE}
        initialValues={initialValues}
        reLoadOnSuccess={refetch}
      />
      <Row>
        <Col xs={{ span: 16 }} lg={{ span: 4 }}>
          <Select
            style={{ width: '100%' }}
            onChange={handleChangeRequestStatus}
            placeholder={t('MPSUser.filters.status')}
            value={status ?? ''}
          >
            <Select.Option value="">
              <CustomTag color={mpsRequestStatusColors['']}>
                {t('MPSUser.statuses.ALL')}
              </CustomTag>
            </Select.Option>

            <Select.Option value={mpsRequestStatusEnum.RECEIVED}>
              <CustomTag
                color={mpsRequestStatusColors[mpsRequestStatusEnum.RECEIVED]}
              >
                {t('MPSUser.statuses.RECEIVED')}
              </CustomTag>
            </Select.Option>

            <Select.Option value={mpsRequestStatusEnum.ACCEPTED}>
              <CustomTag
                color={mpsRequestStatusColors[mpsRequestStatusEnum.ACCEPTED]}
              >
                {t('MPSUser.statuses.ACCEPTED')}
              </CustomTag>
            </Select.Option>

            <Select.Option value={mpsRequestStatusEnum.REJECTED}>
              <CustomTag
                color={mpsRequestStatusColors[mpsRequestStatusEnum.REJECTED]}
              >
                {t('MPSUser.statuses.REJECTED')}
              </CustomTag>
            </Select.Option>
          </Select>
        </Col>
      </Row>
      <Divider />
      <TableWrapper>
        <MPSUserTable
          tableRef={tableRef}
          columns={columns}
          data={requestList || []}
          isLoading={isFetchingList}
          onScrollToEnd={nextPage}
        />
      </TableWrapper>
    </>
  );
}

export default MPSListContainer;
