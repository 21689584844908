import { Method } from 'axios';

export const endpoints = {
  createUser: {
    method: 'POST' as Method,
    url: 'user-dom/user-with-role',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getUserList: {
    method: 'GET' as Method,
    url: 'user-dom/user',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getUserById: {
    method: 'GET' as Method,
    url: 'user-dom/user/params.userId',
    baseURL: process.env.REACT_APP_API_URL,
  },
  linkUserByToken: {
    method: 'PUT' as Method,
    url: 'user-dom/user-by-token',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateUserById: {
    method: 'PUT' as Method,
    url: 'user-dom/user/{params.userId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteUserById: {
    method: 'DELETE' as Method,
    url: 'user-dom/user/{params.userId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
