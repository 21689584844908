import { Tag } from 'antd';
import { UnitStatusColor } from 'containers/Products/VariantList/interface';
import { useTranslation } from 'react-i18next';
import { UnitStatus } from 'services/unit/interface';
import { LocationListTitleProps } from './interface';

const LocationListTitle = ({ item }: LocationListTitleProps) => {
  const { t } = useTranslation();

  return (
    <p>
      <Tag color={UnitStatusColor[item.status as UnitStatus]}>
        {t(`wms.unitStatus.${item?.status?.toLowerCase()}`)}
      </Tag>
      {`(${item?.warehouse?.name ?? ''}) ${item?.name ?? ''}`}
    </p>
  );
};

export default LocationListTitle;
