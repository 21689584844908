import { UseMutateAsyncFunction } from '@tanstack/react-query';
import AutoCompleteVariantItem from 'components/AutoCompleteVariantItem/AutoCompleteVariantItem';
import { VariantQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductLogistic } from 'services/MPS/interface';
import { Variant } from 'services/product/interface';
import { VariantAutocompleteOptions } from '../interface';

const useVariantSearchInput = (
  value: Variant,
  getVariantsAsync: UseMutateAsyncFunction<
    any,
    unknown,
    VariantQueryParams | undefined,
    unknown
  >,
  mpsId?: string,
  logisticType?: ProductLogistic,
) => {
  const searchKeyState = useState<string>(
    value.variantSku && value.name ? `${value.name}, ${value.variantSku}` : '',
  );
  const autocompleteResultsState = useState<Variant[]>([]);
  const variantOptionsState = useState<VariantAutocompleteOptions[]>([]);

  const setSearchKey = searchKeyState[1];
  const [autocompleteResults, setAutocompleteResults] =
    autocompleteResultsState;
  const [variantOptions, setVariantOptions] = variantOptionsState;

  const searchResult = useCallback(
    async (inputValue: string) => {
      const results = await getVariantsAsync({
        search: inputValue,
        pagination: {
          pageSize: 10,
          page: 1,
        },
        mpsId,
        logisticType,
      });
      setAutocompleteResults(results);
      const newOptions: any[] = [];
      results.forEach((variant: Variant) => {
        newOptions.push({
          value: variant.id,
          label: <AutoCompleteVariantItem variant={variant} />,
        });
      });
      setVariantOptions(newOptions);
    },
    [
      getVariantsAsync,
      mpsId,
      logisticType,
      setAutocompleteResults,
      setVariantOptions,
    ],
  );

  const debouncedSearch = useMemo(
    () => debounce(searchResult, 700),
    [searchResult],
  );

  const handleSearchVariants = useCallback(
    async (search: string) => {
      setSearchKey(search);
      await debouncedSearch(search);
    },
    [debouncedSearch, setSearchKey],
  );

  useEffect(() => {
    setVariantOptions([]);
    setSearchKey('');
  }, [mpsId, logisticType, setSearchKey, setVariantOptions]);

  return {
    searchKeyState,
    autocompleteResults,
    variantOptions,
    handleSearchVariants,
  };
};

export default useVariantSearchInput;
