import { Select } from 'antd';
import { t } from 'i18next';
import { PURCHASE_VIEWS_ENUM } from 'modules/Purchase/reducer/interface';
import { getPurchaseVariantCost } from 'modules/Purchase/reducer/utils/getPurchaseVariantCost';
import { PurchaseStatus, PurchaseVariant } from 'services/purchase/interface';
import { PurchaseVariantColumnsProps } from './interface';
import {
  CostRenderer,
  DeleteButtonRenderer,
  QuantityRenderer,
  VariantFigureRenderer,
} from './VariantRenders';

const currencyOptions = [
  { label: 'Pesos', value: 'COP' },
  { label: 'Dólar', value: 'USD' },
];
const taxOptions = [
  { label: '0 %', value: 0 },
  { label: '5 %', value: 5 },
  { label: '19 %', value: 19 },
];

export const columns = ({
  readOnly,
  purchaseStatus,
  isCreate,
  onChangeValues,
  onRemovePurchaseVariant,
}: PurchaseVariantColumnsProps) => [
  {
    id: 'image',
    title: t('purchaseForm.variantTable.columns.image'),
    dataIndex: 'variant',
    width: '90px',
    render: VariantFigureRenderer,
  },
  {
    id: 'name',
    title: t('purchaseForm.variantTable.columns.name'),
    dataIndex: ['variant', 'name'],
    key: 'name',
    width: '170px',
  },
  {
    id: 'ondemandPrice',
    title: t('purchaseForm.variantTable.columns.ondemandPrice'),
    dataIndex: ['values', 'ondemandPrice'],
    key: 'ondemandPrice',
    editable: true,
    width: '115px',
    render: (ondemandPrice: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={ondemandPrice}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'ondemandPrice', cost)
        }
        readOnly={
          purchaseStatus === PurchaseStatus.DELIVERED ||
          purchaseStatus === PurchaseStatus.CANCELLED
        }
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'purchasePriceCurrency',
    title: t('purchaseForm.variantTable.columns.purchasePriceCurrency'),
    dataIndex: ['values', 'purchasePriceCurrency'],
    editable: true,
    width: '115px',
    render: (
      purchasePriceCurrency: string,
      purchaseVariant: PurchaseVariant,
    ) => (
      <Select
        options={currencyOptions}
        value={currencyOptions.find((o) => o.value === purchasePriceCurrency)}
        onSelect={(data) => {
          onChangeValues(purchaseVariant, 'purchasePriceCurrency', data as any);
        }}
      />
    ),
  },
  {
    id: 'purchasePrice',
    title: t('purchaseForm.variantTable.columns.purchasePrice'),
    dataIndex: ['values', 'purchasePrice'],
    editable: true,
    width: '100px',
    render: (purchasePrice: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={purchasePrice}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'purchasePrice', cost)
        }
        readOnly={
          purchaseStatus === PurchaseStatus.DELIVERED ||
          purchaseStatus === PurchaseStatus.CANCELLED
        }
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'estimatedCogsVat',
    title: t('purchaseForm.variantTable.columns.estimatedCogsVat'),
    dataIndex: ['values', 'estimatedCogsVat'],
    editable: true,
    width: '100px',
    render: (estimatedCogsVat: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={estimatedCogsVat}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'estimatedCogsVat', cost)
        }
        readOnly={purchaseStatus !== PurchaseStatus.PENDING}
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'estimatedCogs',
    title: t('purchaseForm.variantTable.columns.estimatedCogs'),
    dataIndex: ['values', 'estimatedCogs'],
    editable: true,
    width: '100px',
    render: (estimatedCogs: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={estimatedCogs}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'estimatedCogs', cost)
        }
        readOnly={purchaseStatus !== PurchaseStatus.PENDING}
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'importCostVat',
    title: t('purchaseForm.variantTable.columns.importCostVat'),
    dataIndex: ['values', 'importCostVat'],
    editable: true,
    width: '100px',
    render: (importCostVat: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={importCostVat}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'importCostVat', cost)
        }
        readOnly={!!readOnly || purchaseStatus !== PurchaseStatus.PENDING}
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'importCost',
    title: t('purchaseForm.variantTable.columns.importCost'),
    dataIndex: ['values', 'importCost'],
    editable: true,
    width: '100px',
    render: (importCost: number, purchaseVariant: PurchaseVariant) => (
      <CostRenderer
        cost={importCost}
        onChangeCost={(cost: number) =>
          onChangeValues(purchaseVariant, 'importCost', cost)
        }
        readOnly={!!readOnly || purchaseStatus !== PurchaseStatus.PENDING}
        fallbackCost={purchaseVariant.variant.cost ?? 0}
      />
    ),
  },
  {
    id: 'tax',
    title: t('purchaseForm.variantTable.columns.tax'),
    dataIndex: ['values', 'tax'],
    editable: true,
    width: '110px',
    render: (tax: number, purchaseVariant: PurchaseVariant) => {
      if (purchaseVariant.values.taxType === 'PERCENTAGE') {
        return (
          <Select
            options={taxOptions}
            value={taxOptions.find((o) => o.value === tax)}
            onSelect={(data) => {
              onChangeValues(purchaseVariant, 'tax', data as any);
            }}
          />
        );
      }

      return (
        <CostRenderer
          cost={tax}
          onChangeCost={(cost: number) =>
            onChangeValues(purchaseVariant, 'tax', cost)
          }
          readOnly={!!readOnly || purchaseStatus !== PurchaseStatus.PENDING}
          fallbackCost={purchaseVariant.values.tax ?? 0}
        />
      );
    },
  },
  {
    id: 'cogsVat',
    title: t('purchaseForm.variantTable.columns.cogsVat'),
    dataIndex: ['values', 'cogsVat'],
    editable: false,
    width: '100px',
    render: (cogsVat: number) => (
      <CostRenderer
        cost={cogsVat}
        onChangeCost={() => ({})}
        readOnly
        fallbackCost={0}
      />
    ),
  },
  {
    id: 'cogs',
    title: t('purchaseForm.variantTable.columns.cogs'),
    dataIndex: ['values', 'cogs'],
    editable: false,
    width: '100px',
    render: (cogs: number) => (
      <CostRenderer
        cost={cogs}
        onChangeCost={() => ({})}
        readOnly
        fallbackCost={0}
      />
    ),
  },
  {
    id: 'orderedQuantity',
    title: t('purchaseForm.variantTable.columns.orderedQuantity'),
    dataIndex: ['values', 'orderedQuantity'],
    editable: true,
    width: '100px',
    render: (orderedQuantity: number, purchaseVariant: PurchaseVariant) => (
      <QuantityRenderer
        quantity={orderedQuantity}
        onChangeQuantity={(cost: number) =>
          onChangeValues(purchaseVariant, 'orderedQuantity', cost)
        }
        fallback={0}
        readOnly={
          readOnly || (!isCreate && purchaseStatus !== PurchaseStatus.PENDING)
        }
        minValue={0}
      />
    ),
  },
  {
    id: 'clearedQuantity',
    title: t('purchaseForm.variantTable.columns.clearedQuantity'),
    dataIndex: ['values', 'clearedQuantity'],
    editable: true,
    width: '100px',
    render: (clearedQuantity: number, purchaseVariant: PurchaseVariant) => (
      <QuantityRenderer
        quantity={clearedQuantity}
        onChangeQuantity={(cost: number) =>
          onChangeValues(purchaseVariant, 'clearedQuantity', cost)
        }
        fallback={0}
        readOnly={!!readOnly || purchaseStatus !== PurchaseStatus.PENDING}
        minValue={0}
      />
    ),
  },
  {
    id: 'receivedQuantity',
    title: t('purchaseForm.variantTable.columns.receivedQuantity'),
    dataIndex: ['values', 'receivedQuantity'],
    editable: true,
    width: '100px',
    render: (receivedQuantities: number, purchaseVariant: PurchaseVariant) => (
      <QuantityRenderer
        quantity={receivedQuantities}
        onChangeQuantity={(cost: number) =>
          onChangeValues(purchaseVariant, 'receivedQuantity', cost)
        }
        readOnly={!!readOnly || purchaseStatus !== PurchaseStatus.IN_TRANSIT}
        fallback={0}
        minValue={0}
      />
    ),
  },
  {
    id: 'orders',
    title: t('purchaseForm.variantTable.columns.orders'),
    dataIndex: 'orders',
    editable: false,
    width: '120px',
    render: (_: unknown, variant: PurchaseVariant) => (
      <div>
        {(variant.orders ?? []).map((order) => (
          <p key={order}>{order}</p>
        ))}
      </div>
    ),
  },
  {
    id: 'total',
    title: t('purchaseForm.variantTable.columns.total'),
    width: '120px',
    render: (variant: PurchaseVariant) => getPurchaseVariantCost(variant),
  },
  {
    id: 'actions',
    title: t('purchaseForm.variantTable.columns.actions'),
    dataIndex: 'actions',
    width: '80px',
    render: (_: unknown, purchaseVariant: PurchaseVariant) => (
      <DeleteButtonRenderer
        variant={purchaseVariant}
        onRemovePurchaseVariant={onRemovePurchaseVariant}
      />
    ),
  },
];

export const COLUMNS_TO_SHOW: Record<
  string, // Marketplace or Regular
  Record<
    PURCHASE_VIEWS_ENUM.CREATE | PURCHASE_VIEWS_ENUM.UPDATE,
    Record<PurchaseStatus, string[]>
  >
> = {
  MARKETPLACE: {
    [PURCHASE_VIEWS_ENUM.CREATE]: {
      [PurchaseStatus.PENDING]: [
        'image',
        'name',
        'ondemandPrice',
        'tax',
        'orderedQuantity',
        'total',
        'actions',
      ],
      [PurchaseStatus.IN_TRANSIT]: [] as string[],
      [PurchaseStatus.DELIVERED]: [] as string[],
      [PurchaseStatus.CANCELLED]: [] as string[],
    },
    [PURCHASE_VIEWS_ENUM.UPDATE]: {
      [PurchaseStatus.PENDING]: [
        'image',
        'name',
        'ondemandPrice',
        'tax',
        'orderedQuantity',
        'orders',
        'total',
        'actions',
      ],
      [PurchaseStatus.IN_TRANSIT]: [
        'image',
        'name',
        'ondemandPrice',
        'tax',
        'orderedQuantity',
        'receivedQuantity',
        'orders',
        'total',
        'actions',
      ],
      [PurchaseStatus.DELIVERED]: [
        'image',
        'name',
        'ondemandPrice',
        'tax',
        'orderedQuantity',
        'receivedQuantity',
        'orders',
        'total',
        'actions',
      ],
      [PurchaseStatus.CANCELLED]: [
        'image',
        'name',
        'ondemandPrice',
        'tax',
        'orderedQuantity',
        'receivedQuantity',
        'orders',
        'total',
        'actions',
      ],
    },
  },
  REGULAR: {
    [PURCHASE_VIEWS_ENUM.CREATE]: {
      [PurchaseStatus.PENDING]: [
        'image',
        'name',
        'purchasePriceCurrency',
        'purchasePrice',
        'estimatedCogsVat',
        'estimatedCogs',
        'orderedQuantity',
        'total',
        'actions',
      ],
      [PurchaseStatus.IN_TRANSIT]: [] as string[],
      [PurchaseStatus.DELIVERED]: [] as string[],
      [PurchaseStatus.CANCELLED]: [] as string[],
    },
    [PURCHASE_VIEWS_ENUM.UPDATE]: {
      [PurchaseStatus.PENDING]: [
        'image',
        'name',
        'purchasePriceCurrency',
        'purchasePrice',
        'estimatedCogsVat',
        'estimatedCogs',
        'importCostVat',
        'importCost',
        'tax',
        'cogsVat',
        'cogs',
        'orderedQuantity',
        'clearedQuantity',
        'total',
        'actions',
      ],
      [PurchaseStatus.IN_TRANSIT]: [
        'image',
        'name',
        'purchasePrice',
        'purchasePriceCurrency',
        'estimatedCogsVat',
        'estimatedCogs',
        'importCostVat',
        'importCost',
        'tax',
        'cogsVat',
        'cogs',
        'orderedQuantity',
        'clearedQuantity',
        'receivedQuantity',
        'total',
        'actions',
      ],
      [PurchaseStatus.DELIVERED]: [
        'image',
        'name',
        'purchasePrice',
        'purchasePriceCurrency',
        'estimatedCogsVat',
        'estimatedCogs',
        'importCostVat',
        'importCost',
        'tax',
        'cogsVat',
        'cogs',
        'orderedQuantity',
        'clearedQuantity',
        'receivedQuantity',
        'total',
        'actions',
      ],
      [PurchaseStatus.CANCELLED]: [
        'image',
        'name',
        'purchasePrice',
        'purchasePriceCurrency',
        'estimatedCogsVat',
        'estimatedCogs',
        'importCostVat',
        'importCost',
        'tax',
        'cogsVat',
        'cogs',
        'orderedQuantity',
        'clearedQuantity',
        'receivedQuantity',
        'total',
        'actions',
      ],
    },
  },
};
