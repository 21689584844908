/* eslint-disable react/require-default-props */
import { DollarOutlined } from '@ant-design/icons';
import { Col, Form, FormInstance, InputNumber, Row, Select } from 'antd';
import {
  getCurrencyInputRules,
  getNumberInputRules,
  InputNumberDefProps,
} from 'modules/ShippingZones/constants';
import { currencyParser, formatCurrency } from 'modules/ShippingZones/helpers';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogisticType } from 'services/MPS/interface';
import { Variant } from 'services/product/interface';
import {
  DefaultStockUpdateReasonOptions,
  VariantStockLayout,
} from '../constants';
import { IVariantStockForm } from '../interface';
import DifferenceAddon from './DifferenceAddon';

const VariantStockForm = ({
  form,
  onSubmit,
  initialValues,
  disabled = true,
  variant,
}: {
  form: FormInstance<IVariantStockForm>;
  onSubmit: (values: IVariantStockForm) => void;
  initialValues?: IVariantStockForm;
  disabled?: boolean;
  variant: Partial<Variant>;
}) => {
  const { t } = useTranslation();
  const currencyInputRules = getCurrencyInputRules(t);
  const numberInputRules = getNumberInputRules(t);
  const liveForm = Form.useWatch([], form);
  const updateStockReasons = DefaultStockUpdateReasonOptions.map(
    (reason): LabelValue<string> => ({
      label: t(`stock.form.reason.${reason}`),
      value: t(`stock.form.reason.${reason}`),
    }),
  );

  const capEnabled = useMemo(
    () => variant?.mps?.logisticType === LogisticType['ON-DEMAND'],
    [variant],
  );

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      disabled={disabled}
    >
      <Row gutter={20} justify={VariantStockLayout.row.justify}>
        <Col span={4}>
          <Form.Item
            label={t('stock.form.labels.inWarehouseUnits')}
            name="inWarehouse"
            tooltip={t('stock.form.tooltips.inWarehouseUnits')}
            rules={numberInputRules}
            required
          >
            <InputNumber
              style={InputNumberDefProps.style}
              prefix={
                <DifferenceAddon
                  initialValue={initialValues?.inWarehouse}
                  newValue={liveForm?.inWarehouse}
                  key="inWarehouse-addon"
                />
              }
            />
          </Form.Item>
        </Col>
        {capEnabled && (
          <Col span={4}>
            <Form.Item
              label={t('stock.form.labels.capUnits')}
              name="cap"
              tooltip={t('stock.form.tooltips.capUnits')}
              rules={numberInputRules}
              required
            >
              <InputNumber
                style={InputNumberDefProps.style}
                prefix={
                  <DifferenceAddon
                    initialValue={initialValues?.cap}
                    newValue={liveForm?.cap}
                    key="cap-addon"
                  />
                }
              />
            </Form.Item>
          </Col>
        )}
        <Col span={6}>
          <Form.Item
            label={t('stock.form.labels.unitCost')}
            name="cost"
            tooltip={t('stock.form.tooltips.unitCost')}
            rules={currencyInputRules}
            required
          >
            <InputNumber
              style={InputNumberDefProps.style}
              prefix={<DollarOutlined />}
              formatter={formatCurrency}
              parser={currencyParser}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('stock.form.labels.reason')}
            name="reason"
            rules={currencyInputRules}
            required
          >
            <Select
              placeholder={t('variantForm.placeholders.inventoryReason')}
              options={updateStockReasons}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default VariantStockForm;
