import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import { getAPIMethod } from 'helpers/libs/Http';

interface GetAttributeTypeListParams {
  withChildrenOnly: boolean;
}

const getAttributeTypeList = async (params: GetAttributeTypeListParams) => {
  const response = await getAPIMethod({
    name: 'getAttributeTypeList',
    params: {
      withChildrenOnly: params?.withChildrenOnly ?? undefined,
    },
  });
  return response.data;
};

const createAttribute = async (attribute: AttributeTypeInterface) => {
  const response = await getAPIMethod({
    name: 'createAttributeType',
    data: attribute,
  });
  return response.data;
};

export default {
  create: createAttribute,
  read: getAttributeTypeList,
  update: async (attribute: any) => ({}),
  delete: async (attribute: any) => ({}),
  readByParam: async (params: any) => ({}),
};
