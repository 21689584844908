import { MultiSearchComponetProps } from 'components/MultiSearchSelect/interface';
import { DefaultTable } from 'helpers/constants/userDocTypes';
import { Moment } from 'moment';
import {
  City,
  costOperator,
  holyDay,
  Regional,
  ShippingZones,
  Transport,
  ZoneStatusEnum,
} from 'services/cities/interface';

export interface CitiesListProps extends DefaultTable<ShippingZones> {
  id?: string;
}

export interface DatePickerTagsProps {
  id: string;
  selectedDates?: string[];
  onChange?: (value: Moment) => void;
  onClose?: (value: string) => void;
}

export interface ShippingZoneListProps extends MultiSearchComponetProps {
  selectedCity: ShippingZones;
  currentView: string;
  selectedRegional: string;
  regionList: Regional[];
  transportOptions: Transport[];
  handleDateChange: (value: Moment) => void;
  handleDateClose: (value: string) => void;
  handleRegionChange: (value: string) => void;
  selectedDates: string[];
  submitForm: (form: ShippingZones) => void;
}

export interface IZoneCity {
  value: string;
  label: string;
}

export enum RegionalShippingCostType {
  VARIABLE = 'VARIABLE',
  FIXED = 'FIXED',
}

export interface RegionalConfigForm {
  shippingCostType: RegionalShippingCostType;
  regional: Regional | string;
  holyDays: holyDay[];
  deliveryDaysLimit?: number;
}

export interface IShippingZoneForm {
  name: string;
  costTakings: number;
  zoneCity: City[];
  freeShippingLimit: number;
  maxWarrantyPrice: number;
  leadTime: number;
  minimumOrderAmount: number;
  shippingCost: number;
  mediumMille: boolean;
  isFreeCollectionCost: boolean;
  deliveryDays: number[];
  transport: string;
  regionalConfig: RegionalConfigForm;
  status: ZoneStatusEnum;
  freeShippingEnabled: boolean;
  earnCost: costOperator;
  shippingSubsidy: costOperator;
}

export const DefaultShippingZoneFormValues: IShippingZoneForm = {
  name: '',
  costTakings: 0,
  deliveryDays: [],
  freeShippingLimit: 0,
  isFreeCollectionCost: false,
  leadTime: 0,
  maxWarrantyPrice: 0,
  mediumMille: false,
  minimumOrderAmount: 0,
  regionalConfig: {
    holyDays: [],
    regional: '',
    shippingCostType: RegionalShippingCostType.VARIABLE,
    deliveryDaysLimit: 20,
  },
  transport: '',
  shippingCost: 0,
  zoneCity: [],
  status: ZoneStatusEnum.ACTIVE,
  freeShippingEnabled: false,
  earnCost: {
    value: 3,
    operator: 'PERCENTAGE',
  },
  shippingSubsidy: {
    value: 5,
    operator: 'PERCENTAGE',
  },
};

export interface ShippingZoneLoadingStates {
  loadingCityCodes: boolean;
}
