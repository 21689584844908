/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import { WarrantyDetailDTO } from 'services/warranty/interface';
import { VariantContent } from '../interface';
import { ThumbnailImageWrapper } from '../VariantTable.sty';

const ThumbnailRender = ({
  variant,
  mini = false,
}: {
  variant: VariantContent | WarrantyDetailDTO;
  mini?: boolean;
}) => {
  const baseUrl =
    variant?.images?.length > 0
      ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${variant?.images[0].url}`
      : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
  return (
    <ThumbnailImageWrapper mini={mini}>
      {variant && (
        <img
          src={baseUrl}
          alt={variant?.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
          }}
        />
      )}
    </ThumbnailImageWrapper>
  );
};

export default ThumbnailRender;
