import { UploadFile } from 'antd/lib/upload/interface';

export interface BaseEntityResponse {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface LogoInterface {
  id: string;
  name: string;
  url: string;
}
export interface BrandEntity {
  name: string;
  logo: LogoInterface;
  ranking: number;
}

export interface FormUpload extends UploadFile {
  id?: string;
}

export interface BrandFormInterface {
  logo?: FormUpload;
  name: string;
  ranking: string;
  id?: string;
}

export enum BrandsModalAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface BrandDBEntity extends BaseEntityResponse, BrandEntity {}
export interface BrandFunctions {
  setFormReference?: (arg0: any) => void;
  actionType?: BrandsModalAction;
}

export interface BrandFormProps extends BrandFunctions {
  initialValues?: BrandFormInterface;
  closeModal?: () => any;
  reLoadOnSuccess?: () => void;
  setLoadingConfirm?: (state: boolean) => void;
}

export const BrandFormInitialValues: BrandFormInterface = {
  name: '',
  ranking: '1',
};
