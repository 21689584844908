import { InputNumber, TableProps } from 'antd';
import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import { TFunction } from 'i18next';
import { PackageQuantities, QuantityVariant } from 'services/package/interface';
import { Variant } from 'services/product/interface';

function findQty(
  quantities: PackageQuantities,
  id: string,
  findIn: keyof PackageQuantities,
): number | null {
  const foundQuantities = quantities[findIn];
  if (!foundQuantities) return null;

  const qtyMatch = foundQuantities.find((qt) => qt.id === id);
  if (!qtyMatch) return null;

  return qtyMatch.quantity;
}

export function getPackagesProductsTableColumns(
  t: TFunction,
  editionEnabled: boolean,
  onChangeQuantity: (variant: QuantityVariant, quantity: number) => void,
  quantities: PackageQuantities,
): TableProps<QuantityVariant>['columns'] {
  return [
    {
      title: t('package.table.labels.image'),
      key: 'image',
      render(_, record) {
        return <ThumbnailRender variant={record as unknown as Variant} />;
      },
    },
    {
      title: t('package.table.labels.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('package.table.labels.price'),
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: t('package.table.labels.quantity'),
      key: 'price',
      render(_, record) {
        if (!editionEnabled) return record.quantity;
        const maxQuantity =
          findQty(quantities, record.id, 'delivered') ??
          findQty(quantities, record.id, 'packaged') ??
          findQty(quantities, record.id, 'requested');

        return (
          <InputNumber
            min={0}
            defaultValue={record.quantity}
            onChange={(value) => onChangeQuantity(record, value ?? 0)}
            max={maxQuantity ?? 0}
          />
        );
      },
    },
  ];
}
