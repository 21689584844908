import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

const maxWidth = '350px';

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  gap: ${spaceSizes.m};
  align-items: center;
  margin-top: ${spaceSizes.none};
  max-height: 250px;
  max-width: 250px;
  margin-bottom: ${spaceSizes.xl};
  max-width: ${maxWidth};
`;

export const Image = styled.img`
  object-fit: contain;
`;

export const Title = styled.h1`
  color: ${LightTheme.color.primary};
  text-align: center;
  line-height: ${spaceSizes.xxl};
  margin: unset;
  font-weight: bold;
  margin-bottom: ${spaceSizes.xl};
  max-width: ${maxWidth};
`;

export const Description = styled.p`
  color: ${LightTheme.color.gray_light};
  max-width: 70%;
  text-align: center;
  line-height: ${spaceSizes.l};
  margin-bottom: ${spaceSizes.xl};
  max-width: ${maxWidth};
  white-space: pre-line;
`;
