import { UploadFile } from 'antd/lib/upload/interface';
import { VariantImage } from 'services/product/interface';
import { fullImageURL } from './fullImageURL';

export const imageToUploadFile = (
  image: VariantImage,
  folder = 'products',
): UploadFile => ({
  uid: image.id || '',
  name: image.url,
  url: fullImageURL(folder, image.url),
});

export const imagesToUploadFiles = (
  images: VariantImage[],
  folder = 'products',
): UploadFile[] =>
  images.map((image) => ({
    uid: image.id || '',
    name: image.url,
    url: fullImageURL(folder, image.url),
  }));

export const uploadFileToImage = (file: UploadFile): VariantImage => ({
  name: file.name,
  url: file.name,
});

export const uploadFilesToImages = (files: UploadFile[]): VariantImage[] =>
  files.map(uploadFileToImage);
