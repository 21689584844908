import { BaseColors, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const ShippingZonesWrapper = styled.div`
  background: ${BaseColors.white};
  width: 100%;
  height: 90vh;
  border-radius: 20px;
  padding: 30px;
  overflow-y: scroll;
`;

export const UpdateShippingZoneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spaceSizes.m} 0;
`;

export const UpdateShippingZoneSection = styled.div`
  display: flex;
  gap: 5px;
`;
