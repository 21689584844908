import { ErrorMessageObject } from './development.errors';

export const productErrors: { [key: string]: ErrorMessageObject } = {
  PROC400010: {
    translation: 'PROC400010',
    isBlocking: false,
  },
  PROU400004: {
    translation: 'PROU400004',
    isBlocking: false,
  },
  PROC400023: {
    translation: 'PROC400023',
    isBlocking: false,
  },
};
