import { Col, Input, Row, Select } from 'antd';
import AddressSelect from 'containers/General/AddressSelect/AddressSelect';
import { LocationValue } from 'containers/General/LocationInput/interface';
import { emailRegex } from 'helpers/constants/regex';
import { docTypes, docTypesKey } from 'helpers/constants/userDocTypes';
import { phoneValidator, stringValidator } from 'helpers/utils/formValidators';
import { t } from 'i18next';
import { requiredRule } from 'modules/Order/constants/rules';
import { FormItem } from '../OrderForm.sty';

const { Option } = Select;

const OrderClientData = ({
  locationOptions,
  requireLastName,
  disabled,
}: {
  locationOptions: LocationValue[];
  requireLastName: boolean | undefined;
  disabled: boolean | undefined;
}) => (
  <Row gutter={[32, 16]}>
    <Col span={10} offset={2}>
      <FormItem
        label={t('userForm.labels.documentType')}
        name={['user', 'documentType']}
        rules={[requiredRule]}
      >
        <Select
          placeholder={t('userForm.placeholders.docType')}
          disabled={disabled}
        >
          {(Object.keys(docTypes) as docTypesKey[]).map((doc: docTypesKey) => (
            <Option key={doc} value={doc}>
              {t(docTypes[doc])}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label={t('userForm.labels.documentNumber')}
        name={['user', 'documentNumber']}
        rules={[requiredRule]}
      >
        <Input disabled={disabled} />
      </FormItem>
      <FormItem
        label={t('userForm.labels.phoneNumber')}
        name={['user', 'phone']}
        rules={[
          {
            required: true,
            validator: (_, value) =>
              phoneValidator(value, { minLength: 6, required: true }, t),
          },
        ]}
      >
        <Input type="tel" disabled={disabled} />
      </FormItem>
      <FormItem
        label={t('userForm.labels.email')}
        name={['user', 'email']}
        rules={[
          {
            required: true,
            validator: (_, value) =>
              stringValidator(value, { required: true, regexp: emailRegex }, t),
          },
        ]}
      >
        <Input type="email" disabled={disabled} />
      </FormItem>
    </Col>
    <Col span={10}>
      <FormItem
        label={t('userForm.labels.name')}
        name={['user', 'name']}
        rules={[requiredRule]}
      >
        <Input disabled={disabled} />
      </FormItem>
      {requireLastName && (
        <FormItem
          label={t('userForm.labels.lastName')}
          name={['user', 'firstLastName']}
        >
          <Input disabled={disabled} />
        </FormItem>
      )}
      <FormItem
        label={t('userForm.labels.address')}
        name={['user', 'localization']}
        rules={[requiredRule]}
        style={{ width: '100%' }}
      >
        <AddressSelect locationOptions={locationOptions} disabled={disabled} />
      </FormItem>
    </Col>
  </Row>
);

export default OrderClientData;
