import { Col, Row } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

export const TransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TransferInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputLabel = styled.p`
  margin: 0 10px;
`;

export const TransfersWrapper = styled(Row)`
  background-color: white;
  border-radius: 10px;
`;

export const TransferItemsContainer = styled(Col)`
  background-color: ${BaseColors.white};
  padding: 0px;
  max-width: 100%;
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid ${BaseColors.darky_gray};
  position: relative;
`;

export const VariantsTableWrapper = styled(Col)`
  padding: 10px 0;
`;
