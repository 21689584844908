import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PandasError } from 'config/Errors/error';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { Purchase } from 'services/purchase/interface';
import {
  createPurchase,
  getPurchaseById,
  getPurchasesByMps,
  getStockPendingMps,
  updatePurchase,
} from 'services/purchase/purchase.service';

interface Props {
  id?: string;
  isOndemand?: boolean;
}

const useWritePurchase = ({ id, isOndemand = false }: Props) => {
  const {
    data: purchase,
    isFetching: purchaseLoading,
    error: PurchaseError,
  } = useQuery({
    queryKey: ['purchases', 'list', id],
    queryFn: () => getPurchaseById(id ?? ''),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  const purchaseError = useMemo(() => {
    if (PurchaseError instanceof AxiosError && PurchaseError.response) {
      return new PandasError(PurchaseError.response.data);
    }

    if (PurchaseError) {
      return new PandasError({
        code: t('PURR-500100'),
        statusCode: 500,
        message: 'Unhandled error',
      });
    }

    return undefined;
  }, [PurchaseError]);

  // Mps stock pending functions
  const {
    mutateAsync: GetMpsOrders,
    isLoading: mpsOrdersLoading,
    error: MpsOrdersError,
  } = useMutation({
    mutationFn: getPurchasesByMps,
  });
  const getMpsOrders = useCallback(
    async (mpsId: string) => {
      return GetMpsOrders(mpsId);
    },
    [GetMpsOrders],
  );

  const mpsOrdersError = useMemo(() => {
    if (MpsOrdersError instanceof AxiosError && MpsOrdersError.response) {
      return new PandasError(MpsOrdersError.response.data);
    }

    if (MpsOrdersError) {
      return new PandasError({
        code: t('ORDR-500100'),
        statusCode: 500,
        message: 'Unhandled error',
      });
    }

    return undefined;
  }, [MpsOrdersError]);

  const {
    data: stockPendingMps,
    isFetching: stockPedingMpsLoading,
    error: StockPendingMpsError,
  } = useQuery({
    queryFn: getStockPendingMps,
    enabled: isOndemand,
    refetchOnWindowFocus: false,
  });

  const stockPendingMpsError = useMemo(() => {
    if (
      StockPendingMpsError instanceof AxiosError &&
      StockPendingMpsError.response
    ) {
      return new PandasError(StockPendingMpsError.response.data);
    }

    if (StockPendingMpsError) {
      return new PandasError({
        code: t('ORDR-500100'),
        statusCode: 500,
        message: 'Unhandled error',
      });
    }

    return undefined;
  }, [StockPendingMpsError]);

  // -------------------

  const {
    mutateAsync: Create,
    isLoading: isLoadingCreate,
    error: CreateError,
  } = useMutation({
    mutationFn: createPurchase,
  });

  const CreatePurchase = useCallback(
    async (purchaseData: Purchase) => {
      const created = await Create(purchaseData);
      return created;
    },
    [Create],
  );

  const createError = useMemo(() => {
    if (CreateError instanceof AxiosError && CreateError.response) {
      return new PandasError(CreateError.response.data);
    }

    if (CreateError) {
      return new PandasError({
        code: t('PURC-500100'),
        statusCode: 500,
        message: 'Unhandled Error',
      });
    }

    return undefined;
  }, [CreateError]);

  const {
    mutateAsync: Update,
    isLoading: isLoadingUpdate,
    error: UpdateError,
  } = useMutation({
    mutationFn: updatePurchase,
  });

  const UpdatePurchase = useCallback(
    async (purchaseData: Purchase) => {
      const updated = Update(purchaseData);
      return updated;
    },
    [Update],
  );

  const updateError = useMemo(() => {
    if (UpdateError instanceof AxiosError && UpdateError.response) {
      return new PandasError(UpdateError.response.data);
    }

    if (UpdateError) {
      return new PandasError({
        code: t('PUR-UPD001'),
        statusCode: 500,
        message: 'Unhandled Error',
      });
    }

    return undefined;
  }, [UpdateError]);

  return {
    purchase,
    purchaseLoading,
    purchaseError,

    getMpsOrders,
    mpsOrdersLoading,
    mpsOrdersError,

    stockPendingMps,
    stockPedingMpsLoading,
    stockPendingMpsError,

    createPurchase: CreatePurchase,
    isLoadingCreate,
    createError,

    updatePurchase: UpdatePurchase,
    isLoadingUpdate,
    updateError,
  };
};

export default useWritePurchase;
