import { ShippingZonesProvider } from 'context/ShippingZonesContext/ShippingZones.Context';
import ShippingZonesContainer from 'modules/ShippingZones/containers/ShippingZones';

export const ShippingZonesPage = () => {
  return (
    <ShippingZonesProvider>
      <ShippingZonesContainer />
    </ShippingZonesProvider>
  );
};
