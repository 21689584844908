import {
  Form,
  Divider,
  Input,
  Switch,
  Button,
  Modal,
  TreeSelect,
  Checkbox,
  Select,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import UploadItem from 'components/Upload/UploadItem';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutContainer, Title } from 'pages/App/Layout.sty';
import useCategoryList from 'helpers/hooks/useCategoryList/useCategoryList';
import { ButtonCol, ButtonRow } from './CategoryForm.sty';
import useCategoryForm from './hooks/useCategoryForm';
import { CategoryFormProps, CategoryFormValues } from './interface';

const defaultInitialValues: CategoryFormValues = {
  name: '',
  parentId: '',
  description: '',
  industry: '',
  isParent: false,
};

export const CategoryForm = ({
  onSubmit,
  initialValues = defaultInitialValues,
  title = 'createCategory',
  updateCategory = false,
  isLoading,
}: CategoryFormProps) => {
  const { t } = useTranslation();

  const {
    readQuery: { mutate: getParameters, data: industriesList },
  } = useModuleCRUD('parameters');

  const {
    form,
    displaySelect,
    visibleConfirmation,
    handleFormValuesChange,
    onCheckoutForm,
    onFinish,
    onConfirm,
    onCancel,
    fileListState: [fileList, setFileList],
  } = useCategoryForm(
    initialValues,
    onSubmit,
  );
  const { categoryTree } = useCategoryList();

  const [industries, setIndustries] = useState([]);
  const [industrySelected, setIndustrySelected] = useState('');

  useEffect(() => {
    getParameters({ keys: 'industries' });
  }, [getParameters]);

  useEffect(() => {
    if (industriesList) {
      setIndustries(industriesList?.industries?.data);
    }
  }, [industriesList, setIndustries]);

  const handleFileUpload = useCallback(
    (file) => {
      setFileList([...fileList, file]);
      form.setFieldsValue({ imageUrl: file.name });
    },
    [fileList, form, setFileList],
  );

  const handleIndustryChange = useCallback((value) => {
    setIndustrySelected(value);
    form.setFieldsValue({ industry: value });
  }, [form]);

  const handleFileRemove = useCallback(
    (file: UploadFile) => {
      setFileList(
        fileList.filter(
          (fileInList: UploadFile) => fileInList.uid !== file.uid,
        ),
      );
    },
    [fileList, setFileList],
  );

  return (
    <>
      <Title level={1}>{t(title)}</Title>
      <Modal
        visible={visibleConfirmation || isLoading}
        onOk={onConfirm}
        onCancel={onCancel}
        title={t('categoryMutate')}
        okText={t('categoryForm.modal.buttonTexts.ok')}
        cancelText={t('categoryForm.modal.buttonTexts.cancel')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          loading: isLoading,
        }}
      >
        {t('categoryForm.modal.content')}
      </Modal>
      <Divider />
      <LayoutContainer>
        <Form
          labelCol={{
            xs: { span: 7 },
            sm: { span: 6 },
          }}
          wrapperCol={{
            xs: { span: 16 },
            sm: { span: 12 },
            xl: { span: 10 },
          }}
          form={form}
          name="create-category"
          onFinish={onFinish}
          onValuesChange={handleFormValuesChange}
          initialValues={initialValues}
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label={t('categoryForm.labels.name')}
            rules={[
              {
                required: true,
                message: t('categoryForm.errorMessages.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('categoryForm.labels.description')}
            rules={[{ required: false }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="urlName"
            label={t('categoryForm.labels.urlName')}
            rules={[
              {
                required: true,
                message: t('categoryForm.errorMessages.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="isParent"
            label={t('categoryForm.labels.isParent')}
            rules={[
              {
                required: true,
                message: t('categoryForm.errorMessages.required'),
              },
            ]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          {displaySelect ? (
            <Form.Item
              name="parentId"
              label={t('categoryForm.labels.parentId')}
              rules={[
                {
                  required: displaySelect,
                  message: t('categoryForm.errorMessages.required'),
                },
              ]}
            >
              <TreeSelect
                placeholder={t('categoryForm.parentCategoryPlaceholders')}
                treeData={categoryTree || []}
                treeDefaultExpandAll
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="landingSubcategory"
                label={t('categoryForm.labels.landingSubcategory')}
                valuePropName="checked"
                initialValue={initialValues.landingSubcategory ?? false}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="industry"
                label={t('categoryForm.labels.industry')}
                initialValue={initialValues.industry ?? ''}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  style={{ minWidth: '100px' }}
                  placeholder={t('orderTableFilters.mps')}
                  defaultValue={initialValues.industry ?? ''}
                  value={industrySelected ?? ''}
                  onChange={handleIndustryChange}
                >
                  {
                  industries?.map((industry : any) => (
                    <Select.Option key={industry.code}>
                      {industry.name}
                    </Select.Option>
                  ))
                }
                </Select>
              </Form.Item>
            </>

          )}
          <Form.Item
            name="imageUrl"
            label={t('categoryForm.labels.imageUrl')}
            rules={[
              {
                required: true,
                message: t('categoryForm.errorMessages.required'),
              },
            ]}
          >
            <UploadItem
              folder="categories"
              fileList={fileList}
              onUpload={handleFileUpload}
              onRemove={handleFileRemove}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 7, offset: 0 },
              sm: { span: 6, offset: 0 },
            }}
          >
            <ButtonRow>
              <ButtonCol>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={onCheckoutForm}
                >
                  {!updateCategory ? t('createCategory') : t('updateCategory')}
                </Button>
              </ButtonCol>
            </ButtonRow>
          </Form.Item>
        </Form>
      </LayoutContainer>
    </>
  );
};

CategoryForm.defaultProps = {
  isLoading: false,
};
