import { ROUTES } from 'helpers/constants/routes';
import SupplierContainer from 'modules/Suppliers/containers/SupplierContainer';
import { SuppliersProvider } from 'modules/Suppliers/context/supplier.context';
import { SUPPLIER_PAGE_ENUM } from 'modules/Suppliers/reducer/supplier.reducer';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const SupplierPage = () => {
  const { pathname } = useLocation();

  const route = useMemo(() => {
    if (pathname === ROUTES.updateSupplier) return SUPPLIER_PAGE_ENUM.UPDATE;
    if (pathname === ROUTES.createSupplier) return SUPPLIER_PAGE_ENUM.CREATE;
    return SUPPLIER_PAGE_ENUM.LIST;
  }, [pathname]);
  return (
    <SuppliersProvider>
      <SupplierContainer route={route} />
    </SuppliersProvider>
  );
};

export default SupplierPage;
