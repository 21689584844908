import { FormListFieldData } from 'antd/lib/form/FormList';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import { LightTheme } from 'helpers/constants/theme';
import { VariantLocation } from 'modules/WMS/components/interface';
import { CartItemStatus } from 'services/cart/interface';
import { Category } from 'services/category/interface';
import { MPS } from 'services/MPS/interface';
import { Unit } from 'services/unit/interface';

export const VARIANT_RANKINGS = [0, 1, 2, 3];
export interface VariantImage {
  id?: string;
  name: string;
  url: string;
}

export interface Attribute {
  createdAt?: string;
  updatedAt?: string;
  id: string;
  name: string;
  type: string;
  typeId: string;
  description?: string;
}

export interface StockObject {
  available: number;
  cap?: number;
  block: number;
  sold?: number;
  availableInWarehouse: number;
  notAvailableInWarehouse: number;
  inWarehouse?: number;
}

export interface SelectProductObject {
  expected: number;
  selected: number;
}
export interface Variant {
  _id?: string;
  id: string;
  name: string;
  variantSku: string;
  stock: StockObject;
  stockCost?: number;
  category?: Category;
  price: number;
  partNumber?: string;
  discount?: number;
  cost?: number;
  designTags?: string;
  tags?: string[];
  keywords?: string[];
  ranking?: number;
  images: VariantImage[];
  urlName: string;
  mps?: MPS;
  productId: string;
  parent?: Product;
  attributeId?: string;
  attributes?: Attribute[];
  description?: string;
  rack?: string;
  position?: string;
  level?: string;
  hall?: string;
  brand?: BrandDBEntity | null;
  video?: string;
  cartQuantity?: number;
  volumePrice?: DiscountItem[];
  status?: VariantStatus | keyof typeof CartItemStatus;
  cap?: number;
  isTaxExcluded?: boolean;
  vatPercentage?: number;
  units?: Unit[];
  unitsLength?: number;
  unitStatus?: string;
  locations?: VariantLocation[];
  commission?: number;
  dimensions: {
    width: number;
    length: number;
    height: number;
    weight: number;
  };
}

export enum VariantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BillingType {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
}
export const BillingTypeColor: { [key: string]: string } = {
  [BillingType.PRODUCT]: LightTheme.color.prime_purple,
  [BillingType.SERVICE]: LightTheme.color.light_yellow,
};

export interface PickingProduct {
  images: VariantImage[];
  id: string;
  name: string;
  cartQuantity: number;
  price: number;
  expectedCartQuantity: number;
  field: FormListFieldData;
}
export interface VariantToCreate extends Partial<Variant> {
  productId: string;
}

export interface VariantCombo extends Variant {
  combo: Variant[];
}

export interface CartVariant extends Variant {
  cartQuantity?: number;
  discount?: number;
  status?: keyof typeof CartItemStatus | VariantStatus;
  initialCartQuantity?: number;
}

export type AttributeLevel = Record<string, AttributeTypeInterface>;

export interface Product {
  _id: string;
  id: string;
  name: string;
  urlName: string;
  categoryId: string;
  inventoryId: string;
  tags?: string;
  imageUrl?: string;
  variants?: Variant[];
  description?: string;
  brand?: BrandDBEntity;
  attributeLevels?: AttributeLevel;
  revisionStatus?: RevisionStatus;
}

export enum RevisionStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export interface ProductDetailsData<T> {
  data: T;
}

export interface DiscountItem {
  min: number;
  max: number;
  price: number;
}
