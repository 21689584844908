import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const UserSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px dashed solid ${LightTheme.color.gray_light};
  padding: ${LightTheme.spaceSizes.sm} ${LightTheme.spaceSizes.m};
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UserInfoContainer = styled.div`
  width: 90%;
  padding-right: ${LightTheme.spaceSizes.m};
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.xs};
`;

export const UserName = styled.b`
  font-size: ${LightTheme.spaceSizes.l};
`;

export const UserDataItem = styled.span`
  color: ${LightTheme.color.gray_light};
`;

export const DeleteButtonContainer = styled.div`
  width: 10%;
  position: relative;
`;

export const DeleteButton = styled.span`
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, 0);
`;

export const UserListWrapper = styled.div`
  height: fit-content;
  width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.m};
`;
