/* eslint-disable react/prop-types */
import { createContext, useContext } from 'react';
import { BANNER_CONTEXT_DEFAULT_VALUES, IBannerContext } from '../interface';
import useBannerContext from './useBannerContext';

const BannerContext = createContext<IBannerContext>(
  BANNER_CONTEXT_DEFAULT_VALUES,
);

export const BannerProvider: React.FC = ({ children }) => {
  const transfers = useBannerContext();

  return (
    <BannerContext.Provider value={transfers}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => useContext(BannerContext);
