/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPIMethod } from 'helpers/libs/Http';
import { RoutesFilters } from './interface';

const getRoutesCsv = async (filters: RoutesFilters) => {
  const response = await getAPIMethod({
    name: 'getRoutesCsv',
    params: {
      status: filters?.status?.join(',') || null,
      startDeliveryDate: filters.startDeliveryDate,
      endDeliveryDate: filters.endDeliveryDate,
    },
  });
  return response;
};

export const getRoutesStatusFilters = async (): Promise<string[]> => {
  const response = await getAPIMethod({
    name: 'getRoutesFilter',
  });
  return response.data;
};

export default {
  create: async (params: any) => ({}),
  read: getRoutesCsv,
  update: async (params: any) => ({}),
  delete: async (params: any) => ({}),
  readByParam: async (params: any) => ({}),
};
