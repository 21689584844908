/* eslint-disable react/jsx-wrap-multilines */
import { List, Spin } from 'antd';
import HistoryProduct from 'components/HistoryProduct/HistoryProduct';
import { HistoryItem } from 'components/VariantForm/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { NoHistory } from 'pages/Products/UpdateProduct/UpdateProduct.sty';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LogoImage from '../../assets/images/logo.svg';

const DEFAULT_PAGE_SIZE = 10;

const VariantHistory = ({ variantId }: { variantId: string }) => {
  const [page, setPage] = useState<number>(1);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const { t } = useTranslation();

  const {
    readByParamQuery: {
      mutateAsync: getHistoryOnPage,
      isLoading: loadingHistory,
    },
  } = useModuleCRUD('variant');

  const fetchHistory = useCallback(async () => {
    const { data, metadata } = await getHistoryOnPage({
      pageSize: DEFAULT_PAGE_SIZE,
      variantId,
      page,
      history: true,
    });
    setHistory((oldHistory) => [...oldHistory, ...data]);
    setPage((oldPage) => oldPage + 1);
    setHasNext(metadata.hasNext);
  }, [getHistoryOnPage, variantId, page]);

  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InfiniteScroll
      next={fetchHistory}
      dataLength={history.length}
      hasMore={hasNext}
      loader={<Spin tip={t('loading')} />}
      scrollableTarget="scrollable-container"
      endMessage={
        !loadingHistory && history.length ? (
          <NoHistory>
            <img alt="pandas logo" src={LogoImage} />
            <p>{t('variantHistory.noMoreToLoad')}</p>
          </NoHistory>
        ) : null
      }
    >
      <List
        dataSource={history}
        renderItem={(historyItem) => (
          <HistoryProduct key={historyItem.date.toString()} {...historyItem} />
        )}
        loading={loadingHistory && history.length === 0}
      />
    </InfiniteScroll>
  );
};

export default VariantHistory;
