import { PlusOutlined } from '@ant-design/icons';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AddButton } from 'components/AddButton';
import {
  BrandDBEntity,
  BrandFormInitialValues,
  BrandFormInterface,
  BrandsModalAction,
} from 'components/BrandForm/interface';
import useModal from 'helpers/hooks/useModal/useModal';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { FilterCol } from 'modules/Order/components/OrderContent/OrderContent.sty';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Brand } from 'services/brands/interface';

const DEFAULT_PAGE_SIZE = 30;

const useBrandsList = ({
  searchKey,
  tableRef,
  getBrandsListAsync,
}: {
  createBrandsAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  updateBrandsAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  getBrandsListAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  searchKey: string | undefined;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
}) => {
  const { t } = useTranslation();

  const paginationState = useState<PaginationParams>({
    page: 1,
    pageSize: 10,
  });
  const { hasAccess: canCreate } = useRoleAccess('brands.create');

  const [reachedEndOfData, setReachedEndOfData] = useState<boolean>(false);

  const [brandsList, setBrandsList] = useState<
    Brand[] | BrandDBEntity[] | undefined
  >();
  const [initialValues, setInitialValues] = useState<BrandFormInterface>();
  const [modalActionType, setModalActionType] = useState<BrandsModalAction>();
  const { openModal, closeModal, isOpen: isModalOpen } = useModal({});

  const {
    isOpen: isOpenDelete,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal({});

  const [pagination, setPagination] = paginationState;
  const fetchBrandsList = useCallback(
    async (search?: string) => {
      const fetchedBrandsList = await getBrandsListAsync?.({
        search,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      if (tableRef?.current) {
        tableRef.current.scrollToTop();
      }
      setBrandsList([...fetchedBrandsList.data]);
      setPagination({
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      setReachedEndOfData(fetchedBrandsList.data.length < DEFAULT_PAGE_SIZE);
    },
    [setPagination, getBrandsListAsync, setReachedEndOfData, tableRef],
  );

  const fetchNextBrandsPage = useCallback(async () => {
    if (!reachedEndOfData) {
      const fetchedBrandsList = await getBrandsListAsync?.({
        search: searchKey,
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      });
      setBrandsList((prev) => [...(prev || []), ...fetchedBrandsList.data]);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [
    pagination.page,
    pagination.pageSize,
    getBrandsListAsync,
    searchKey,
    setPagination,
    reachedEndOfData,
  ]);

  const handleOpenCreate = useCallback(() => {
    setModalActionType(BrandsModalAction.CREATE);
    setInitialValues(BrandFormInitialValues);
    openModal();
  }, [openModal, setModalActionType]);

  const handleUpdate = useCallback(
    (brand: any) => {
      setModalActionType(BrandsModalAction.UPDATE);
      setInitialValues(brand);
      openModal();
    },
    [openModal],
  );

  const handleDelete = useCallback(
    (brand: any) => {
      setModalActionType(BrandsModalAction.DELETE);
      setInitialValues(brand);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  useEffect(() => {
    fetchBrandsList(searchKey);
  }, [fetchBrandsList, searchKey]);

  const BrandsFilters = useCallback(
    () => (
      <FilterCol
        style={{
          color: 'white',
          padding: 0,
        }}
      >
        {canCreate && (
          <AddButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenCreate}
          >
            {t('brand.brandsFilters.create')}
          </AddButton>
        )}
      </FilterCol>
    ),
    [canCreate, t, handleOpenCreate],
  );

  return {
    paginationState,
    fetchBrandsList,
    isOpenDelete,
    openDeleteModal,
    closeDeleteModal,
    BrandsFilters,
    handleDelete,
    reachedEndOfData,
    setBrandsList,
    brandsList,
    fetchNextBrandsPage,
    t,
    closeModal,
    isModalOpen,
    modalActionType,
    handleUpdate,
    initialValues,
  };
};

export default useBrandsList;
