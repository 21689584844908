/* eslint-disable react/jsx-no-constructed-context-values */
import { ShippingZonesActionEnum } from 'config/Constants/actions';
import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import {
  initialShippingZoneState,
  ShippingZonesAction,
  ShippingZonesState,
} from 'services/cities/interface';

function ShippingZonesReducer(
  state: ShippingZonesState,
  action: ShippingZonesAction,
): ShippingZonesState {
  switch (action.type) {
    case ShippingZonesActionEnum.SET_SHIPPING_ZONE_LIST:
      return { ...state, ShippingZoneList: action.payload };
    case ShippingZonesActionEnum.SET_SEARCH:
      return { ...state, search: action.payload };
    case ShippingZonesActionEnum.SET_CURRENT_SHIPPING_ZONE:
      return { ...state, selectedShippingZone: action.payload };
    case ShippingZonesActionEnum.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case ShippingZonesActionEnum.SET_DEPARTMENTS_FILTER:
      return { ...state, departmentCode: action.payload };
    case ShippingZonesActionEnum.SET_CITIES_OPTIONS:
      return { ...state, citiesOptions: action.payload };
    case ShippingZonesActionEnum.SET_CITY_SEARCH:
      return { ...state, citySearch: action.payload };
    case ShippingZonesActionEnum.SET_TRANSPORT_LIST:
      return { ...state, transportList: action.payload };
    case ShippingZonesActionEnum.SET_REGIONAL_LIST:
      return { ...state, regionalList: action.payload };
    case ShippingZonesActionEnum.SET_SELECTED_REGION:
      return { ...state, selectedRegional: action.payload };
    case ShippingZonesActionEnum.SET_HOLY_DAYS:
      return { ...state, holyDays: action.payload };
    case ShippingZonesActionEnum.ADD_HOLY_DAYS:
      return { ...state, holyDays: [...state.holyDays, action.payload] };
    case ShippingZonesActionEnum.REMOVE_HOLY_DAYS: {
      const newArray = state.holyDays.filter(
        (element) => element !== action.payload,
      );
      return { ...state, holyDays: newArray };
    }
    case ShippingZonesActionEnum.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page + 1,
          pageSize: action.payload.pageSize,
          hasNext: action.payload.hasNext,
        },
      };
    default:
      return state;
  }
}

const ShippingZonesContext = createContext<{
  state: ShippingZonesState;
  dispatch: React.Dispatch<ShippingZonesAction>;
}>({
  state: initialShippingZoneState,
  dispatch: () => null,
});

interface CityProviderProps {
  children: ReactNode;
}

export const ShippingZonesProvider: React.FC<CityProviderProps> = ({
  children,
}: CityProviderProps) => {
  const [state, dispatch] = useReducer(
    ShippingZonesReducer,
    initialShippingZoneState,
  );
  return (
    <ShippingZonesContext.Provider value={{ state, dispatch }}>
      {children}
    </ShippingZonesContext.Provider>
  );
};

export const useShippingZonesContext = () => useContext(ShippingZonesContext);
