/* eslint-disable react/jsx-props-no-spreading */
import { Button, Input, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'helpers/constants/routes';
import { useTranslation } from 'react-i18next';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import { TableWrapper } from 'components/TableWrapper';
import { CategoryContent } from './interface';
import { SearchCol } from './CategoryTable.sty';
import useFilterCategoryData from './hooks/useFilterCategoryData';
import useGetCategoryColumns from './hooks/useGetCategoryColumns';

const { Search } = Input;
const TABLE_HEADER_HEIGHT = 140;

export const CategoryTable = ({
  data,
  onDeleteCategory,
  onGoToUpdateCategory,
}: {
  data: CategoryContent[];
  onDeleteCategory: (id: string) => void;
  onGoToUpdateCategory: (id: string) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayData, searchKey, handleChange, handleClickChange } =
    useFilterCategoryData(data);
  const { columns, hiddenDescription } = useGetCategoryColumns({
    onDeleteCategory,
    onGoToUpdateCategory,
  });

  const goToCreate = () => {
    navigate(`${ROUTES.createCategory}`);
  };
  const tableSize = useTableSize(TABLE_HEADER_HEIGHT);

  return (
    <>
      <Row gutter={16} style={{ paddingBottom: '20px' }}>
        <SearchCol md={12} xs={24}>
          <Search
            value={searchKey}
            placeholder={t('localSearchPlaceholder')}
            onChange={handleChange}
            onSearch={handleClickChange}
          />
        </SearchCol>
        <SearchCol md={12} xs={24}>
          <Row justify="end">
            <Button type="primary" onClick={goToCreate}>
              {t('createCategory')}
            </Button>
          </Row>
        </SearchCol>
      </Row>
      <TableWrapper>
        <Table
          {...tableSize}
          rowKey="id"
          columns={columns}
          expandable={{
            expandedRowRender: (record) => hiddenDescription(record),
            rowExpandable: () => true,
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
          dataSource={displayData}
        />
      </TableWrapper>
    </>
  );
};
