/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AtomEffect } from 'recoil';

export const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const ISSERVER = typeof window !== 'undefined';
    const savedValue = ISSERVER ? localStorage.getItem(key) : null;
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset || !newValue
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
