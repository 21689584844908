/* eslint-disable react/jsx-curly-newline */
import { Button, Col, Divider, Modal, Row } from 'antd';
import { TableWrapper } from 'components/TableWrapper';
import CreateAttribute from 'containers/CreateAttribute/CreateAttribute';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { debounce } from 'lodash';
import { Title } from 'pages/App/Layout.sty';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AttributeTypeForm } from '../AttributesType/AttributeTypeForm';
import { ButtonCol, SearchInput } from './AttributeContent.sty';
import AttributeTable from './AttributeTable';
import useAttributeContent from './hooks/useAttributeContent';
import { useAttributeType } from './hooks/useAttributeType';
import { Attribute } from './interface';

const AttributeContent = () => {
  const { t } = useTranslation();

  const {
    selectedRowKeysState: [selectedRowKeys, setSelectedRowKeys],
    loadingDeleteState: [loadingDelete, setLoadingDelete],
    showDeleteModalState: [showDeleteModal, setShowDeleteModal],
    deleteAttributeIdState: [deleteAttributeId, setDeleteAttributeId],
    showDeleteSingleModalState: [
      showDeleteSingleModal,
      setShowDeleteSingleModal,
    ],
    tablePagination: [tablePagination],
    attributeState: [attributes, setAttributes],
    onConfirmDeleteSelection,
    getAttributeList,
    attributeList,
    isLoadingList,
    deleteAttributeById,
    isLoadingDeleteAttribute,
    showCreateAttributeModalState: [
      showCreateAttributeModal,
      setShowCreateAttributeModal,
    ],
  } = useAttributeContent();

  const {
    attributeModalOpen,
    handleCloseAttributeTypeModal,
    handleOpenAttributeTypeModal,
    attributeTypeFormRef,
    handleAttributeTypeFormSubmit,
  } = useAttributeType();

  const { notifySuccess, notifyError, notifyWarning } = useNotifications({
    translationFunction: t,
  });

  const { AccessWrapper } = useRoleAccess('attributes.editActions');

  const onDeleteSelection = useCallback(() => {
    setShowDeleteModal(true);
  }, [setShowDeleteModal]);

  const onCancelDeleteSelection = useCallback(() => {
    setShowDeleteModal(false);
    notifyWarning('attributesForm.modal.message.cancel');
  }, [notifyWarning, setShowDeleteModal]);

  const onDeleteAttribute = useCallback(
    (id: string) => {
      setDeleteAttributeId(id);
      setShowDeleteSingleModal(true);
    },
    [setDeleteAttributeId, setShowDeleteSingleModal],
  );

  const onCloseCreateModal = useCallback(
    () => setShowCreateAttributeModal(false),
    [setShowCreateAttributeModal],
  );

  const onOpenCreateModal = useCallback(
    () => setShowCreateAttributeModal(true),
    [setShowCreateAttributeModal],
  );

  const onConfirmDeleteSingle = useCallback(async () => {
    setShowDeleteSingleModal(false);
    setLoadingDelete(true);
    try {
      await deleteAttributeById(deleteAttributeId);
      notifySuccess('attributesForm.modal.message.success');
    } catch (error) {
      notifyError('attributesForm.modal.message.error');
    }
    setLoadingDelete(false);
    setDeleteAttributeId('');
    getAttributeList(undefined);
  }, [
    deleteAttributeById,
    deleteAttributeId,
    getAttributeList,
    notifyError,
    notifySuccess,
    setDeleteAttributeId,
    setLoadingDelete,
    setShowDeleteSingleModal,
  ]);

  const onCancelDeleteSingle = useCallback(() => {
    setShowDeleteSingleModal(false);
    notifyWarning('attributesForm.modal.message.cancel');
  }, [notifyWarning, setShowDeleteSingleModal]);

  const handleSearchWithDebounce = useMemo(
    () =>
      debounce((_val: string) => {
        const filtered = attributeList.filter((attr: Attribute) =>
          attr.name.toLowerCase().includes(_val.toLowerCase()),
        );
        setAttributes(filtered);
      }, 700),
    [attributeList, setAttributes],
  );

  const onSearchInputPlaceholder = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchWithDebounce(value);
    },
    [handleSearchWithDebounce],
  );

  useEffect(() => {
    if (attributeList && attributeList.length > 0) {
      setAttributes(attributeList);
    }
  }, [attributeList, setAttributes]);

  return (
    <>
      <Modal
        visible={showDeleteModal}
        onOk={onConfirmDeleteSelection}
        onCancel={onCancelDeleteSelection}
        okText={t('attributesForm.modal.buttonTexts.ok')}
        cancelText={t('attributesForm.modal.buttonTexts.cancel')}
        title={t('attributesForm.modal.title')}
      >
        {t('attributeActionKeys.confirmDelete')}
      </Modal>
      <Modal
        visible={attributeModalOpen}
        onOk={handleAttributeTypeFormSubmit}
        okText={t('attributesTypeForm.buttons.ok')}
        cancelText={t('attributesTypeForm.buttons.cancel')}
        title={t('attributesTypeForm.title')}
        onCancel={handleCloseAttributeTypeModal}
        destroyOnClose
      >
        <AttributeTypeForm ref={attributeTypeFormRef ?? null} />
      </Modal>
      <Modal
        visible={showCreateAttributeModal}
        footer={null}
        onCancel={onCloseCreateModal}
      >
        <CreateAttribute />
      </Modal>
      <Modal
        visible={showDeleteSingleModal}
        onOk={onConfirmDeleteSingle}
        onCancel={onCancelDeleteSingle}
        okText={t('attributesForm.modal.buttonTexts.ok')}
        cancelText={t('attributesForm.modal.buttonTexts.cancel')}
        title={t('attributesForm.modal.title')}
      >
        {t('attributeActionKeys.confirmDelete')}
      </Modal>
      <Title level={1}>{t('attributesList')}</Title>
      <Divider />
      <Row gutter={12}>
        <Col xl={14} lg={16} md={14} xs={24}>
          <SearchInput
            placeholder={t('textSearchPlaceholder')}
            onChange={onSearchInputPlaceholder}
          />
        </Col>
        <AccessWrapper>
          <ButtonCol xl={10} lg={18} md={24} xs={26}>
            <Row gutter={3}>
              <Col span={10}>
                <Button type="primary" onClick={handleOpenAttributeTypeModal}>
                  {t('attributesTypeForm.title')}
                </Button>
              </Col>
              <Col span={7}>
                <Button type="primary" onClick={onOpenCreateModal}>
                  {t('attributesForm.createAttribute')}
                </Button>
              </Col>
              <Col span={7}>
                <Button
                  danger
                  type="primary"
                  onClick={onDeleteSelection}
                  disabled={selectedRowKeys.length <= 0}
                  loading={loadingDelete}
                >
                  {t('attributesForm.deleteSelection')}
                </Button>
              </Col>
            </Row>
          </ButtonCol>
        </AccessWrapper>
      </Row>
      <Divider />
      <TableWrapper>
        <AttributeTable
          loading={isLoadingList || isLoadingDeleteAttribute}
          data={attributes}
          onDeleteAttribute={onDeleteAttribute}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          pagination={tablePagination}
        />
      </TableWrapper>
    </>
  );
};

export default AttributeContent;
