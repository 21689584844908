import { CustomSearch } from 'containers/MPS/MpsAutocomplete/MpsAutocomplete.sty';
import { t } from 'i18next';
import useLocationsAutocomplete from './hooks/useLocationsAutocomplete';
import { LocationAutocompleteProps } from './interface';
import { LocationAutoCompleteBar } from './LocationsAutocomplete.sty';

export const LocationAutocomplete = ({
  searchKey = '',
  disabled = false,
  prefix = '',
  allowClear = true,
  placeholder,
  isLeaf = false,
  emptyResultsMsg = '',
  handleSelect,
  defaultValue,
  additionalOptions,
}: LocationAutocompleteProps) => {
  const {
    isLoadingLocations,
    selectOptions,
    search,
    handleSearchLocation,
    onClear,
    handleSelectAutoComplete,
  } = useLocationsAutocomplete(
    searchKey || defaultValue?.name || '',
    isLeaf,
    undefined,
    emptyResultsMsg,
    handleSelect,
    additionalOptions,
  );

  return (
    <LocationAutoCompleteBar<string>
      dropdownMatchSelectWidth={300}
      options={selectOptions}
      onSelect={handleSelectAutoComplete}
      onSearch={handleSearchLocation}
      value={search}
      disabled={disabled}
      allowClear={allowClear}
      onClear={onClear}
      notFoundContent={emptyResultsMsg}
    >
      <CustomSearch
        size="large"
        placeholder={placeholder ?? t('mpsAutocomplete.placeholder')}
        loading={isLoadingLocations}
        icon
        prefix={prefix}
      />
    </LocationAutoCompleteBar>
  );
};
