import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { AXIOS_HTTP_TIMEOUT } from 'helpers/constants/http';
import API from '../../config/API';
import {
  CreateAxiosMethodProps,
  EndpointListProps,
  GetURIProps,
} from './interface';

const axiosInstance = axios.create({
  timeout: AXIOS_HTTP_TIMEOUT,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const endpointList: EndpointListProps = API.endpoints;

const getURI = ({ url, params = {} }: GetURIProps) => {
  let transformedURL = url;
  const matches = transformedURL.match(/\{params.(\w+)}/g);
  if (matches) {
    matches.forEach((match) => {
      const name = match.replace('{params.', '').replace('}', '');
      transformedURL = transformedURL.replace(match, params[name]);
    });
  }
  return transformedURL;
};

export const getAPIMethod = async (requestParams: CreateAxiosMethodProps) => {
  const { name, pathParams } = requestParams;
  const endpointObject = endpointList[name];

  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  return axiosInstance({
    baseURL: endpointObject.baseURL || API.baseURL,
    method: endpointObject.method,
    url: getURI({ url: endpointObject.url, params: pathParams }),
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
    ...requestParams,
  });
};

export default axiosInstance;
