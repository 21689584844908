/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
import { Button, Row, Spin } from 'antd';
import {
  defaultVariantFormValues,
  VariantFormValues,
} from 'components/VariantForm/interface';
import { VariantForm } from 'components/VariantForm/VariantForm';
import { useGetAttributeOptions } from 'helpers/hooks/useGetAttributeOptions/useGetAttributeOptions';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { buildVariantComboParams } from 'helpers/utils/buildVariantComboParams';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateVariantForm,
  ProductFormFooter,
} from './CreateAndUpdateVariant.sty';
import { CreateAndUpdateVariantProps } from './interfaces';

const CreateAndUpdateVariant = ({
  product,
  onFinishStep,
  selectedBrand,
}: CreateAndUpdateVariantProps) => {
  const {
    createMutation: { mutateAsync: fetchVariant, isLoading: isLoadingVariant },
  } = useModuleCRUD('variant');
  const { t } = useTranslation();
  const {
    createMutation: { mutateAsync: createComboAsync },
  } = useModuleCRUD('combo');

  const attributeParams = useGetAttributeOptions(product?.attributeLevels);

  const customMessage = {
    success: {
      message: 'variantForm.modal.actions.success',
      description: 'variantForm.modal.message.createSuccess',
    },
  };
  const { notifySuccess } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const onSubmitCombo = useCallback(
    async (values: VariantFormValues) => {
      if (!product) return;
      try {
        await createComboAsync(
          buildVariantComboParams({
            values,
            product,
            isCombo: true,
          }),
        );
        notifySuccess('productForm.modal.message.variant.success');
        onFinishStep();
      } catch (error) {
        console.error(error);
      }
    },
    [createComboAsync, notifySuccess, onFinishStep, product],
  );

  const onSubmitVariant = useCallback(
    async (values: VariantFormValues) => {
      if (!product) return;
      try {
        await fetchVariant(
          buildVariantComboParams({
            values,
            product,
          }),
        );
        notifySuccess('productForm.modal.message.variant.success');
        onFinishStep();
      } catch (error) {
        console.error(error);
      }
    },
    [fetchVariant, notifySuccess, onFinishStep, product],
  );

  const defaultInitialValues = useMemo(
    () =>
      selectedBrand
        ? { ...defaultVariantFormValues, brand: selectedBrand }
        : defaultVariantFormValues,
    [selectedBrand],
  );

  if (isLoadingVariant) {
    return (
      <Row justify="center">
        <Spin tip="Cargando" />
      </Row>
    );
  }

  return (
    <CreateVariantForm>
      <VariantForm
        initialValues={{
          ...defaultInitialValues,
          attributes: attributeParams.defaultValues,
        }}
        formId="create-variant"
        onSubmitVariant={onSubmitVariant}
        onSubmitCombo={onSubmitCombo}
        isUpdate={false}
        title={t(`Crear variante para: ${product?.name}`)}
        submitButton={
          <ProductFormFooter>
            <Button type="primary" size="large" htmlType="submit">
              {t('Guardar Variante')}
            </Button>
          </ProductFormFooter>
        }
        attributeParams={attributeParams}
        productName={product?.name}
      />
    </CreateVariantForm>
  );
};

export default CreateAndUpdateVariant;
