import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { TransferLoaderProps } from '../../interface';
import { LoadingScreen } from './TransferItems.sty';

const TransferLoader = ({ transferLoading }: TransferLoaderProps) => {
  const { t } = useTranslation();

  if (!transferLoading) return null;

  return (
    <LoadingScreen>
      <Spin size="large" tip={t('transfers.cart.labels.loading')} />
    </LoadingScreen>
  );
};

export default TransferLoader;
