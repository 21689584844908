import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Tag,
} from 'antd';
import {
  getDefaultInputRules,
  getNumberInputRules,
  InputNumberDefProps,
} from 'modules/ShippingZones/constants';
import {
  dayParser,
  formatDay,
  getShippingCostTypeValues,
} from 'modules/ShippingZones/helpers';
import { IShippingZoneForm } from 'modules/ShippingZones/interface';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { Moment } from 'moment';
import { map, prop, uniq } from 'ramda';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetShippingRegionalConfigParams,
  holyDay,
  RegionalConfig,
} from 'services/cities/interface';

const RegionalConfigInputs = ({
  form,
  regionOptionList,
  disabled,
  fetchRegionalConfig,
}: {
  form: FormInstance<IShippingZoneForm>;
  regionOptionList: LabelValue<string>[];
  fetchRegionalConfig: (
    params: GetShippingRegionalConfigParams,
  ) => Promise<RegionalConfig>;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const defaultInputRules = getDefaultInputRules(t);
  const numberInputRules = getNumberInputRules(t);

  const shippingCostTypeOptions = getShippingCostTypeValues(t);
  const selectedDates = Form.useWatch(['regionalConfig', 'holyDays'], form);
  const onSelectNewRegional = useCallback(
    async (value: string) => {
      const selectedRegional = await fetchRegionalConfig({ id: value });
      form.setFieldValue(
        ['regionalConfig', 'holyDays'],
        selectedRegional.holyDays ?? [],
      );
      form.setFieldValue(
        ['regionalConfig', 'deliveryDaysLimit'],
        selectedRegional.deliveryDaysLimit ?? 0,
      );
    },
    [fetchRegionalConfig, form],
  );

  const onSelectNewHolyday = useCallback(
    (newHoliday: Moment) => {
      const rawActualHolyDays = (form.getFieldValue([
        'regionalConfig',
        'holyDays',
      ]) ?? []) as holyDay[];
      const actualHolyDays = map(prop('date'), rawActualHolyDays);
      const dateToAdd = newHoliday.format('YYYY-MM-DD');

      const dateSet = uniq([...actualHolyDays, dateToAdd]);
      const newHolyDays = dateSet.map(
        (date): holyDay => ({
          date,
          label: date,
        }),
      );

      form.setFieldValue(['regionalConfig', 'holyDays'], newHolyDays);
    },
    [form],
  );

  const onDeleteHolyday = useCallback(
    (deletedDate: string) => {
      const actualHolyDays = form.getFieldValue([
        'regionalConfig',
        'holyDays',
      ]) as holyDay[];

      const newHolyDays = actualHolyDays.filter(
        (hd) => hd.date !== deletedDate,
      );

      form.setFieldValue(['regionalConfig', 'holyDays'], newHolyDays);
    },
    [form],
  );

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          name={['regionalConfig', 'regional']}
          rules={defaultInputRules}
          label={t('regional.form.label.regional')}
          required
        >
          <Select
            onSelect={onSelectNewRegional}
            options={regionOptionList ?? []}
          />
        </Form.Item>
        <Form.Item
          name={['regionalConfig', 'shippingCostType']}
          rules={defaultInputRules}
          label={t('regional.form.label.shippingCostType')}
          required
        >
          <Select options={shippingCostTypeOptions} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['regionalConfig', 'holyDays']}
          label={t('regional.form.label.holydays')}
        >
          <DatePicker
            style={InputNumberDefProps.style}
            defaultValue={undefined}
            onSelect={onSelectNewHolyday}
          />
          <Card>
            {selectedDates?.map((d) => (
              <Tag
                closable={!disabled}
                key={d.date}
                onClose={() => onDeleteHolyday(d.date)}
              >
                {d.date}
              </Tag>
            ))}
          </Card>
        </Form.Item>
        <Form.Item
          name={['regionalConfig', 'deliveryDaysLimit']}
          label={t('shippingZone.form.label.deliveryDaysLimit')}
          tooltip={t('shippingZone.form.tooltip.deliveryDaysLimit')}
          rules={numberInputRules}
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<ClockCircleOutlined />}
            formatter={(n) => formatDay(t, n)}
            parser={dayParser}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default RegionalConfigInputs;
