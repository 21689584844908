import { CategoryNode } from 'containers/Categories/CategoryForm/interface';
import moment from 'moment';
import { FormInstance, NamePath } from 'rc-field-form/lib/interface';
import { Brand } from 'services/brands/interface';
import { Coupon } from 'services/coupons/interface';
import { MPS } from 'services/MPS/interface';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export interface FormCoupon
  extends Partial<Omit<Coupon, 'startDate' | 'endDate'>> {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  productSegmentation?: string;
}

export interface CouponModalProps {
  coupon?: Coupon;
  isUpdate?: boolean;
  isLoading?: boolean;
  onSubmit: (values: Partial<Coupon>) => void;
}

export interface CouponModalTitleProps {
  coupon?: Coupon;
  title: string;
  readOnly?: boolean;
  onSetEditable: () => void;
  canEdit?: boolean;
}

export interface CouponModalFooterProps {
  isUpdate?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  onClickFooter: () => void;
}

export interface CouponNameInfoSubFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
}

export interface CouponDurationFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
}

export interface CouponDurationSubFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
  getFieldValue?: (name: NamePath) => any;
}

export interface CouponDetailsSubFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
  requireNominalValue?: boolean;
  segmentation?: string[];
  getFieldValue?: (name: NamePath) => any;
}

export interface CouponOtherInfoSubFormProps {
  coupon?: Coupon;
  readOnly?: boolean;
}

export interface CouponDetailFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
}

export interface CouponOtherInfoFormProps {
  readOnly?: boolean;
  isUpdate?: boolean;
}

export interface CouponProductSegSubFormProps {
  categoryTree: CategoryNode[];
  isCategorySegmentation?: boolean;
  isBrandSegmentation?: boolean;
  brands?: Brand[];
  isSponsoredCoupon?: boolean;
  sellers?: MPS[];
  isUpdate?: boolean;
  readOnly?: boolean;
  getFieldValue?: (name: NamePath) => any;
}

export interface CouponUserSegSubFormProps {
  isUpdate?: boolean;
  isUserSegmented?: boolean;
  readOnly?: boolean;
  form: FormInstance<FormCoupon>;
  userIds?: string[];
}
