import { Method } from 'axios';

export const endpoints = {
  getPurchaseList: {
    method: 'GET' as Method,
    url: 'purchase-dom/purchase',
    baseURL: process.env.REACT_APP_PURCHASE_API,
  },
  createPurchase: {
    method: 'POST' as Method,
    url: 'purchase-dom/purchase',
    baseURL: process.env.REACT_APP_PURCHASE_API,
  },
  updatePurchase: {
    method: 'PUT' as Method,
    url: 'purchase-dom/purchase/{params.purchaseId}',
    baseURL: process.env.REACT_APP_PURCHASE_API,
  },
  getPurchaseById: {
    method: 'GET' as Method,
    url: 'purchase-dom/purchase/{params.purchaseId}',
    baseURL: process.env.REACT_APP_PURCHASE_API,
  },
  getPurchasesByMps: {
    method: 'GET' as Method,
    url: 'order-dom/orders/pending-stock-orders',
    baseURL: process.env.REACT_APP_ORDER_API,
  },
  getStockPendingMps: {
    method: 'GET' as Method,
    url: 'order-dom/orders/pending-stock-mps',
    baseURL: process.env.REACT_APP_ORDER_API,
  },
};
