/* eslint-disable react/jsx-no-useless-fragment */
import { Row, Spin } from 'antd';
import { t } from 'i18next';

const LoaderWrapper = ({
  loading,
  children,
}: {
  loading: boolean;
  children: React.ReactNode;
}) => {
  if (loading) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }
  return <>{ children }</>;
};

export default LoaderWrapper;
