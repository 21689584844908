import { Button } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const AddButton = styled(Button)`
  width: 160px;
  height: 35px;
  font-size: 13px;
  background: ${LightTheme.color.primary};
  border-radius: 8px;
  border: none;
  color: ${LightTheme.color.white};
`;
