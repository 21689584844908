import { TransportActionEnum } from 'config/Constants/actions';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'helpers/constants/http';
import { InfiniteScrollPagination, ShippingZonesViewEnum, Transport } from '.';

export interface TransportState {
  transportList: Transport[];
  selectedTransport: Transport;
  currentView: ShippingZonesViewEnum;
  pagination: InfiniteScrollPagination;
}

export const initialTransportState: TransportState = {
  transportList: [],
  pagination: {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    hasNext: true,
  },
  selectedTransport: {
    _id: '',
    additionalPackageCost: 0,
    convertionFactor: 0,
    name: '',
    minimumVolumeWeight: 0,
  },
  currentView: ShippingZonesViewEnum.LIST,
};

export type TransportAction = {
  type: TransportActionEnum;
  payload: any;
};
