import CreateShippingZoneContainer from 'modules/ShippingZones/containers/CreateShippingZone';
import { ShippingZoneCreateProvider } from 'modules/ShippingZones/context/SettingCreate.context';

export const CreateShippingZonePage = () => {
  return (
    <ShippingZoneCreateProvider>
      <CreateShippingZoneContainer />
    </ShippingZoneCreateProvider>
  );
};
