import { notification } from 'antd';
import { useNotificationParams } from './interface/useNotifications';

const useNotifications = ({
  customMessage,
  translationFunction: t,
}: useNotificationParams) => {
  const success = {
    message: t(
      customMessage?.success?.message ||
        'defaultActionMessages.success.message',
    ),
    description: t(
      customMessage?.success?.description ||
        'defaultActionMessages.success.description',
    ),
  };
  const error = {
    message: t(
      customMessage?.error?.message || 'defaultActionMessages.error.message',
    ),
    description: t(
      customMessage?.error?.description ||
        'defaultActionMessages.error.description',
    ),
  };
  const cancel = {
    message: t(
      customMessage?.cancel?.message || 'defaultActionMessages.cancel.message',
    ),
    description: t(
      customMessage?.cancel?.description ||
        'defaultActionMessages.cancel.description',
    ),
  };
  const warning = {
    message: t(
      customMessage?.warning?.message || 'defaultActionMessages.cancel.message',
    ),
    description: t(
      customMessage?.warning?.description ||
        'defaultActionMessages.cancel.description',
    ),
  };

  const notifySuccess = (message?: string) => {
    if (message) {
      notification.success({
        message: success.message,
        description: t(message),
      });
      return;
    }
    notification.success(success);
  };
  const notifyError = (message?: string) => {
    if (message) {
      notification.error({
        message: error.message,
        description: t(message),
      });
      return;
    }
    notification.error(error);
  };
  const notifyCancel = (message?: string) => {
    if (message) {
      notification.info({
        message: cancel.message,
        description: t(message),
      });
      return;
    }
    notification.info(cancel);
  };
  const notifyWarning = (message?: string, options: any = {}) => {
    if (message) {
      notification.warning({
        message: warning.message,
        description: t(message, options),
      });
      return;
    }
    notification.warning(warning);
  };

  return {
    notifySuccess,
    notifyError,
    notifyCancel,
    notifyWarning,
  };
};

export default useNotifications;
