import { UnitRegistry } from 'components/UnitMovements/interface';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';

const DEFAULT_RESPONSE = {
  data: [],
  metadata: {
    hasNext: false,
    length: 0,
    page: 1,
    pageSize: 10,
  },
};

export const getUnitVariantsByLocation = async (queryParams?: {
  search: string;
  page: number;
  pageSize: number;
  getAll: boolean;
  locationId: string;
}) => {
  if (!queryParams?.locationId) {
    return DEFAULT_RESPONSE;
  }
  const response = await getAPIMethod({
    name: 'getUnitVariantsByLocation',
    params: {
      search: queryParams?.search,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
      getAll: queryParams?.getAll,
    },
    pathParams: {
      locationId: queryParams?.locationId ?? '',
    },
  });
  return response.data;
};

export const getUnitMovementsByVariant = async (queryParams?: {
  page: number;
  pageSize: number;
  variantId: string;
}): Promise<PaginatedResponse<UnitRegistry>> => {
  if (!queryParams?.variantId) {
    return DEFAULT_RESPONSE;
  }
  const response = await getAPIMethod({
    name: 'getUnitMovementsByVariant',
    params: {
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
    pathParams: {
      variantId: queryParams?.variantId ?? '',
    },
  });

  return response.data;
};

export default {
  read: getUnitVariantsByLocation,
  create: getUnitVariantsByLocation,
  update: getUnitVariantsByLocation,
  delete: getUnitVariantsByLocation,
  readByParam: getUnitVariantsByLocation,
};
