/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable func-call-spacing */
/* eslint-disable no-spaced-func */
import { HaulierModalAction } from 'components/HaulierForm/interface';
import DeleteHaulierModal from 'components/HaulierModal/DeleteHaulierModal';
import HaulierModal from 'components/HaulierModal/HaulierModal';
import { TableWrapper } from 'components/TableWrapper';
import { ButtonRigthContainer } from 'containers/Stores/StoresContentList/StoresContentList.sty';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import React, { useRef } from 'react';
import useHaulierList from '../hooks/useHaulierList';
import { HaulierTable } from './HaulierTable';

export const HaulierContentList = ({
  searchKey,
}: {
  searchKey: string | undefined;
}) => {
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const {
    readQuery: { mutateAsync: getHaulierListAsync, isLoading: isLoadingList },
  } = useModuleCRUD('haulier');

  const {
    t,
    haulierList,
    HaulierHeader,
    reachedEndOfData,
    fetchNextHaulierPage,
    fetchHaulierList,
    closeDeleteModal,
    isOpenDelete,
    handleUpdate,
    handleDelete,
    isModalOpen,
    closeModal,
    modalActionType,
    initialValues,
  } = useHaulierList({
    searchKey,
    getHaulierListAsync,
    tableRef,
  });

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <ButtonRigthContainer>
        <HaulierHeader />
      </ButtonRigthContainer>

      <HaulierModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        modalActionType={modalActionType ?? HaulierModalAction.CREATE}
        initialValues={initialValues}
        reLoadOnSuccess={fetchHaulierList}
      />

      <DeleteHaulierModal
        modalActionType={HaulierModalAction.DELETE}
        closeModal={closeDeleteModal}
        isOpen={isOpenDelete}
        initialValues={initialValues}
        reLoadOnSuccess={fetchHaulierList}
      />

      <TableWrapper>
        <HaulierTable
          reachedEndOfData={reachedEndOfData}
          isLoading={isLoadingList}
          onDeleteHaulier={handleDelete}
          onUpdateHaulier={handleUpdate}
          data={haulierList || []}
          onScrollToEnd={fetchNextHaulierPage}
          tableRef={tableRef}
        />
      </TableWrapper>
    </React.Suspense>
  );
};
