/* eslint-disable no-underscore-dangle */
import { Spin, Table } from 'antd';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import useInfiniteRead from 'helpers/hooks/useModuleCRUD/useInfiniteRead';
import { numberToCurrency } from 'helpers/utils/currency';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CreditStatement,
  CreditStatementOrder,
} from 'services/credit/interface';
import { OrderReference } from './CreditListStatement.sty';

const PAGE_SIZE = 10;

export const CreditListStatementOrders = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();

  const statementParams = useMemo(
    () => ({
      search: userId,
      page: 1,
      pageSize: PAGE_SIZE,
    }),
    [userId],
  );

  const {
    readInfiniteQuery: { fetchNextPage, data, hasNextPage },
  } = useInfiniteRead<CreditStatementOrder>(
    'creditStatementOrder',
    statementParams,
  );

  const creditStatements: CreditStatementOrder[] = useMemo(
    () =>
      data?.pages
        ?.flat()
        .map((item) => item?.data)
        ?.flat() ?? [],
    [data],
  );

  const dataSource = creditStatements.map((statement) => ({
    key: statement.orderReference,
    orderNumbers: statement.orderNumbers,
    sumTotal: statement.sumTotal, // adquirida
    sumOwed: statement.sumOwed, // restante
    amortization: statement.sumTotal - statement.sumOwed,
  }));

  const columns = [
    {
      title: t('creditsForm.extractTable.columns.orderNumbers'),
      dataIndex: 'orderNumbers',
      key: 'orderNumbers',
      render: (value?: string[]) =>
        value?.map((order) => (
          <OrderReference key={order}>{order}</OrderReference>
        )),
    },
    {
      title: t('creditsForm.extractTable.columns.sumTotal'),
      dataIndex: 'sumTotal',
      key: 'sumTotal',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('creditsForm.extractTable.columns.amortization'),
      dataIndex: 'amortization',
      key: 'amortization',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('creditsForm.extractTable.columns.sumOwed'),
      dataIndex: 'sumOwed',
      key: 'sumOwed',
      render: (value: number) => numberToCurrency(Number(value)),
    },
  ];

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={dataSource.length}
        hasMore={hasNextPage ?? false}
        scrollableTarget="scrollableDiv"
        loader={<Spin tip={t('loading')} />}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          key={1}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};

export const CreditListStatement = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();

  const statementParams = useMemo(
    () => ({
      search: userId,
      page: 1,
      pageSize: PAGE_SIZE,
    }),
    [userId],
  );

  const {
    readInfiniteQuery: { fetchNextPage, data, hasNextPage },
  } = useInfiniteRead<CreditStatement>('creditStatement', statementParams);

  const creditStatements: CreditStatement[] = useMemo(
    () =>
      data?.pages
        ?.flat()
        .map((item) => item?.data)
        ?.flat() ?? [],
    [data],
  );

  const dataSource = creditStatements.map((statement) => ({
    key: statement.creditLineId,
    type: statement.type,
    amount: statement.amount,
    newQuota: statement.newQuota,
    newDebt: statement.newDebt,
    description: statement.description,
    provider: statement.provider,
  }));

  const columns = [
    {
      title: t('creditsForm.extractTable.columns.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('creditsForm.extractTable.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('creditsForm.extractTable.columns.newQuota'),
      dataIndex: 'newQuota',
      key: 'newQuota',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('creditsForm.extractTable.columns.newDebt'),
      dataIndex: 'newDebt',
      key: 'newDebt',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('creditsForm.extractTable.columns.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('creditsForm.extractTable.columns.provider'),
      dataIndex: 'provider',
      key: 'provider',
    },
  ];

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={dataSource.length}
        hasMore={hasNextPage ?? false}
        scrollableTarget="scrollableDiv"
        loader={<Spin tip={t('loading')} />}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          key={1}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};
