import attributeType from 'services/attribute-type/attribute-type.service';
import attributes from 'services/attribute/attribute.service';
import brands from 'services/brands/brands.service';
import categories from 'services/category/category.service';
import combo from 'services/combo/combo.service';
import coupons from 'services/coupons/coupons.service';
import creditStatementOrder from 'services/credit/credit-statement-order.service';
import creditStatement from 'services/credit/credit-statement.service';
import haulier from 'services/haulier/haulier.service';
import location from 'services/location/location.service';
import mps from 'services/MPS/mps.service';
import pandabonos from 'services/pandabonos/pandabonos.service';
import parameters from 'services/parameters/parameters.service';
import { RevisionStatus } from 'services/product/interface';
import products from 'services/product/product.service';
import { PurchaseStatus } from 'services/purchase/interface';
import routes from 'services/routes/routes.service';
import shipping from 'services/shipping/shipping.service';
import stores from 'services/store/store.service';
import unit from 'services/unit/unit.service';
import user from 'services/user/user.service';
import variant from 'services/variant/variant.service';
import wallet from 'services/wallet/wallet.service';

export const DEFAULT_PAGE_SIZE = 10;

export const modules = {
  attributes,
  attributeType,
  brands,
  categories,
  combo,
  coupons,
  creditStatement,
  creditStatementOrder,
  haulier,
  mps,
  pandabonos,
  parameters,
  products,
  routes,
  shipping,
  stores,
  user,
  variant,
  location,
  unit,
  wallet,
};

export interface PaginationParams {
  page: number;
  getAll?: boolean;
  pageSize: number;
}

export interface QueryParams {
  pagination?: PaginationParams;
  search?: string;
}

export interface LocationQueryParams extends QueryParams {
  isLeaf?: boolean;
  locationId?: string;
}
export interface VariantQueryParams extends QueryParams {
  logisticType?: string;
  mpsId?: string;
  inStock?: string;
  category?: string;
}
export interface UserQueryParams extends QueryParams {
  roleFilter?: string[];
}

export interface PurchaseQueryParams extends QueryParams {
  statusFilter?: PurchaseStatus;
  delivery?: string;
}

export interface CitiesQueryParams extends PaginationParams {
  departmentCode?: string;
  search?: string;
}

export interface WalletQueryParams {
  user?: string;
  search?: string;
  status?: string;
  page?: number;
  pageSize?: number;
}

export interface TransportQueryParams extends PaginationParams {
  name?: string;
  search?: string;
}

export interface CouponQueryParams extends PaginationParams {
  search?: string;
  status?: string;
  startDate?: string;
}

export interface HaulierQueryParams extends PaginationParams {
  search?: string;
}

export interface BrandsQueryParams extends PaginationParams {
  search?: string;
}
export interface StoresQueryParams extends PaginationParams {
  search?: string;
  type?: string;
}
export interface CreditQueryParams extends PaginationParams {
  search?: string;
  showLegacy?: boolean;
}
export interface ProductQueryParams extends PaginationParams {
  search?: string;
  revisionStatus?: RevisionStatus;
}

export interface CategoryParams {
  parent?: string;
}

export interface GenericParams extends PaginationParams, Record<string, any> {}

export type moduleNameType = keyof typeof modules;
