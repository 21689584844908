import { List } from 'antd';
import { MpsLogo } from 'components/AutoCompleteMpsItem/AutoCompleteMpsItem.sty';
import { useTranslation } from 'react-i18next';
import { UnitMovementDefault, UnitRegistry } from './interface';
import OldMovementContent from './OldMovementContent';
import UnitMovementHeader from './UnitMovementHeader';
import UnitMovementItems from './UnitMovementItems';

const UnitMovement = ({ historyItem }: { historyItem: UnitRegistry }) => {
  const { t } = useTranslation();
  const userName =
    historyItem?.user?.name ??
    t('variantUnitMovements.fallbacks.user.UNKNOWN') ??
    UnitMovementDefault.NO_STATUS;

  const userRole =
    historyItem?.user?.roles?.[0] ??
    t('variantUnitMovements.fallbacks.user.NO_ROLE') ??
    UnitMovementDefault.NO_STATUS;

  const avatarUserName = `${userName?.[0]?.toUpperCase()}${userName?.[1]?.toUpperCase()}`;

  return (
    <List.Item key={historyItem.id}>
      <List.Item.Meta
        avatar={<MpsLogo name={avatarUserName}>{avatarUserName}</MpsLogo>}
        title={t('variantUnitMovements.title')}
        description={
          <>
            <UnitMovementHeader
              historyItem={historyItem}
              userName={userName}
              userRole={userRole}
            />
            {historyItem.movements ? (
              <UnitMovementItems registry={historyItem} />
            ) : (
              <OldMovementContent historyItem={historyItem} />
            )}
          </>
        }
      />
    </List.Item>
  );
};

export default UnitMovement;
