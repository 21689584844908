import { UseMutateFunction } from '@tanstack/react-query';
import {
  PaginationParams,
  UserQueryParams,
} from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { useEffect, useState } from 'react';
import { User } from 'services/user/interface';

const useUserModule = ({
  getUserList,
}: {
  getUserList: UseMutateFunction<
    any,
    unknown,
    UserQueryParams | undefined,
    unknown
  >;
}) => {
  const deleteConfirmationModalVisibilityState = useState(false);
  const deleteUserIdState = useState('');
  const drawerVisibilityState = useState(false);
  const paginationState = useState<PaginationParams>({
    page: 1,
    pageSize: 50,
  });
  const searchState = useState<string | undefined>(undefined);
  const roleFilterState = useState<string[] | undefined>(undefined);
  const userToUpdateState = useState<User | undefined>(undefined);
  const [pagination] = paginationState;
  const [search] = searchState;
  const [roleFilter] = roleFilterState;
  useEffect(() => {
    getUserList({
      pagination,
      search,
      roleFilter,
    });
  }, [getUserList, pagination, roleFilter, search]);

  return {
    deleteConfirmationModalVisibilityState,
    deleteUserIdState,
    drawerVisibilityState,
    userToUpdateState,
    searchState,
    paginationState,
    roleFilterState,
  };
};

export default useUserModule;
