import { LocationValue } from 'containers/General/LocationInput/interface';
import useNotifications from 'helpers/hooks/useNotifications';
import { getCoordinates } from 'helpers/utils/getCoordinates';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyLocation } from '../interface';

export const useMultiAddressInput = ({
  value,
  onChange,
}: {
  value?: LocationValue[];
  onChange?: (val: LocationValue[]) => void;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useNotifications({ translationFunction: t });
  const locationMapRef = useRef<google.maps.Map>();
  const modalLocationState = useState<LocationValue>(emptyLocation);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(-1);
  const showModalState = useState<boolean>(false);
  const addressSelectedState = useState<boolean>(false);

  const [_showModal, setShowModal] = showModalState;
  const [modalLocation, setModalLocation] = modalLocationState;

  const recenterMap = (inputValue: LocationValue) => {
    if (locationMapRef.current) {
      locationMapRef.current.setZoom(16);
      locationMapRef.current.setCenter(
        getCoordinates({
          latitude: inputValue.latitude,
          longitude: inputValue.longitude,
        }),
      );
    }
  };

  const handleCancel = useCallback(() => {
    setModalLocation(emptyLocation);
    setSelectedAddressIndex(-1);
    recenterMap(emptyLocation);
    setShowModal(false);
  }, [setModalLocation, setShowModal]);

  const handleLocationModalInputChange = (inputValue: LocationValue) => {
    setModalLocation(inputValue);
  };

  const handleDeleteAddress = useCallback((idx: number) => {
    if (idx <= -1) {
      return;
    }
    const valueCopy = [...(value || [])];
    valueCopy.splice(idx, 1);
    onChange?.([...valueCopy]);
  }, [value, onChange]);

  const handleSelectLocationIdx = useCallback((idx: number) => {
    setSelectedAddressIndex(idx);
    if (idx < 0) {
      setModalLocation(emptyLocation);
      recenterMap(emptyLocation);
      setShowModal(true);
      return;
    }
    if (value) {
      setModalLocation(value[idx]);
      recenterMap(value[idx]);
      setShowModal(true);
    }
  }, [setModalLocation, setShowModal, value]);

  const handleAddressItemChange = useCallback(() => {
    const valuesCopy = [...(value || [])];
    if (modalLocation.address === '' || modalLocation.cityName === '') {
      notifyError('userForm.errorMessages.badLocation');
      return;
    }
    if (selectedAddressIndex < -1) {
      notifyError('userForm.errorMessages.badSelectedLocation');
      return;
    }
    if (selectedAddressIndex === -1) {
      onChange?.([
        { ...modalLocation },
        ...valuesCopy,
      ]);
      handleCancel();
      return;
    }
    valuesCopy[selectedAddressIndex] = { ...modalLocation };
    onChange?.([...valuesCopy]);
    handleCancel();
  }, [
    value,
    modalLocation,
    selectedAddressIndex,
    notifyError,
    onChange,
    handleCancel,
  ]);

  return {
    showModalState,
    modalLocation,
    locationMapRef,
    addressSelectedState,
    recenterMap,
    handleCancel,
    handleLocationModalInputChange,
    handleSelectLocationIdx,
    handleAddressItemChange,
    handleDeleteAddress,
    t,
  };
};
