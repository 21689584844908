/* eslint-disable react/jsx-wrap-multilines */
import { useQuery } from '@tanstack/react-query';
import { ROUTES } from 'helpers/constants/routes';
import { AuthUserProvider } from 'helpers/context/AuthUserContext';
import { parameters } from 'helpers/recoil/atoms/parameters';
import AttributesList from 'pages/Attributes/AttributesList/AttributesList';
import { BrandsList } from 'pages/Brands/BrandsList/BrandsList';
import { CategoryList } from 'pages/Categories/CategoryList/CategoryList';
import { CreateCategory } from 'pages/Categories/CreateCategory/CreateCategory';
import { UpdateCategory } from 'pages/Categories/UpdateCategory/UpdateCategory';
import { CouponsList } from 'pages/Coupons/CouponsList/CouponsList';
import CreditList from 'pages/Credit/CreditList/CreditList';
import { Home } from 'pages/Home/Home';
import UpdateAvailableMassive from 'pages/Inventory/UpdateAvailableMassive/UpdateAvailableMassive';
import UpdateStock from 'pages/Inventory/UpdateStock/UpdateStock';
import UpdateVariantMassive from 'pages/Inventory/UpdateVariantMassive/UpdateVariantMassive';
import { InvoiceReportsPage } from 'pages/InvoiceReportsPage';
import { Login } from 'pages/Login/Login';
import Maintenance from 'pages/Maintenance';
import { MPSUserList } from 'pages/MPSUser/MPSUserList/MPSUserlIst';
import { OrderCreate } from 'pages/Orders/OrderCreate/OrderCreate';
import { OrderList } from 'pages/Orders/OrderList/OrderList';
import { OrderUpdate } from 'pages/Orders/OrderUpdate/OrderUpdate';
import { Pandabonos } from 'pages/Pandabonos/Pandabonos';
import BannerList from 'pages/Parameters/BannerList/BannerList';
import CreateProduct from 'pages/Products/CreateProduct/CreateProduct';
import { ProductList } from 'pages/Products/ProductList/ProductList';
import { UpdateProduct } from 'pages/Products/UpdateProduct/UpdateProduct';
import { PurchaseCreatePage } from 'pages/Purchase/PurchaseCreate';
import { PurchaseListPage } from 'pages/Purchase/PurchaseList';
import { PurchaseUpdatePage } from 'pages/Purchase/PurchaseUpdate';
import CreateRoutesMassive from 'pages/Routes/CreateRoutesMassive/CreateRoutesMassive';
import { CreateShippingZonePage } from 'pages/ShippingZones/ShippingZoneCreate';
import { ShippingZonesPage } from 'pages/ShippingZones/ShippingZonesList';
import { UpdateShippingZonePage } from 'pages/ShippingZones/ShippingZoneUpdate';
import { StoresList } from 'pages/Stores/StoresList/StoresList';
import SupplierPage from 'pages/Supplier';
import { TransportPage } from 'pages/Transport';
import CreateUser from 'pages/Users/CreateUser/CreateUser';
import UserList from 'pages/Users/UserList/UserList';
import { WalletPage } from 'pages/Wallet';
import LocationTree from 'pages/WMS/LocationTree/LocationTree';
import ListSettings from 'pages/WMS/Settings/ListSettings/ListSettings';
import UpdateSetting from 'pages/WMS/Settings/UpdateSetting/UpdateSetting';
import Transfer from 'pages/WMS/Transfer/Transfer';
import { TransfersCsv } from 'pages/WMS/TransfersCsv/TransfersCsv';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getParameters } from 'services/parameters/parameters.service';
import { LayoutApp } from '../App/Layout';
import { HaulierList } from '../Haulier/HaulierList/HaulierList';
import PrivateRoute from './PrivateRoute';

function Router() {
  const [parametersState, setParameters] = useRecoilState(parameters);

  useQuery({
    queryFn: () => getParameters({ keys: 'maintenance' }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess(response) {
      setParameters(response);
    },
  });

  useEffect(() => {
    if (
      parametersState?.maintenance?.data?.value &&
      window.location.pathname !== ROUTES.maintenance
    ) {
      window.location.href = ROUTES.maintenance;
    }
  }, [parametersState]);

  return (
    <BrowserRouter>
      <AuthUserProvider>
        <Routes>
          <Route path={ROUTES.maintenance} element={<Maintenance />} />
          <Route path={ROUTES.login} element={<Login />} />
          <Route element={<LayoutApp />}>
            <Route
              path={ROUTES.home}
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.transfers}
              element={
                <PrivateRoute>
                  <Transfer />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.transfersCsv}
              element={
                <PrivateRoute>
                  <TransfersCsv />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.bannersList}
              element={
                <PrivateRoute>
                  <BannerList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.locationTree}
              element={
                <PrivateRoute>
                  <LocationTree />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.wmsSettings}
              element={
                <PrivateRoute>
                  <ListSettings />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.wmsSettingUpdate}
              element={
                <PrivateRoute>
                  <UpdateSetting />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createOrder}
              element={
                <PrivateRoute>
                  <OrderCreate />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.orderUpdate}
              element={
                <PrivateRoute>
                  <OrderUpdate />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.order}
              element={
                <PrivateRoute>
                  <OrderList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createCategory}
              element={
                <PrivateRoute>
                  <CreateCategory />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.categoryList}
              element={
                <PrivateRoute>
                  <CategoryList />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.updateCategory}/:id`}
              element={
                <PrivateRoute>
                  <UpdateCategory />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.coupons}
              element={
                <PrivateRoute>
                  <CouponsList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.couponsList}
              element={
                <PrivateRoute>
                  <CouponsList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.wallet}
              element={
                <PrivateRoute>
                  <WalletPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.invoiceReport}
              element={
                <PrivateRoute>
                  <InvoiceReportsPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.brands}
              element={
                <PrivateRoute>
                  <BrandsList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.brandsList}
              element={
                <PrivateRoute>
                  <BrandsList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.haulier}
              element={
                <PrivateRoute>
                  <HaulierList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.haulierList}
              element={
                <PrivateRoute>
                  <HaulierList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.stores}
              element={
                <PrivateRoute>
                  <StoresList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.storesList}
              element={
                <PrivateRoute>
                  <StoresList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.productList}
              element={
                <PrivateRoute>
                  <ProductList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createProduct}
              element={
                <PrivateRoute>
                  <CreateProduct />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.updateProduct}/:id`}
              element={
                <PrivateRoute>
                  <UpdateProduct />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.shippingZones}
              element={
                <PrivateRoute>
                  <ShippingZonesPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.shippingZoneUpdate}
              element={
                <PrivateRoute>
                  <UpdateShippingZonePage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.shippingZoneCreate}
              element={
                <PrivateRoute>
                  <CreateShippingZonePage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createUser}
              element={
                <PrivateRoute>
                  <CreateUser />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.userList}
              element={
                <PrivateRoute>
                  <UserList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.updateVariantsMassive}
              element={
                <PrivateRoute>
                  <UpdateVariantMassive />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.updateAvailableMassive}
              element={
                <PrivateRoute>
                  <UpdateAvailableMassive />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.updateStock}
              element={
                <PrivateRoute>
                  <UpdateStock />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.supplierList}
              element={
                <PrivateRoute>
                  <SupplierPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createSupplier}
              element={
                <PrivateRoute>
                  <SupplierPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.updateSupplier}
              element={
                <PrivateRoute>
                  <SupplierPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.purchase}
              element={
                <PrivateRoute>
                  <PurchaseListPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.purchaseCreate}
              element={
                <PrivateRoute>
                  <PurchaseCreatePage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.purchaseUpdate}
              element={
                <PrivateRoute>
                  <PurchaseUpdatePage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.attributes}
              element={
                <PrivateRoute>
                  <AttributesList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.creditList}
              element={
                <PrivateRoute>
                  <CreditList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.mpsUserList}
              element={
                <PrivateRoute>
                  <MPSUserList />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.pandabonos}
              element={
                <PrivateRoute>
                  <Pandabonos />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.transport}
              element={
                <PrivateRoute>
                  <TransportPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.routesCsv}
              element={
                <PrivateRoute>
                  <CreateRoutesMassive />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </AuthUserProvider>
    </BrowserRouter>
  );
}

export default Router;
