/* eslint-disable no-underscore-dangle */
import { Modal } from 'antd';
import { LocationAutocomplete } from 'components/LocationsAutocomplete/LocationsAutocomplete';
import UnitVariantTable from 'containers/WMS/UnitVariantTable/UnitVariantTable';
import { VariantLocation } from 'modules/WMS/components/interface';
import LocationList from 'modules/WMS/components/LocationList';
import TransferItems from 'modules/WMS/Transfers/components/TransferItems/TransferItems';
import { Title } from 'pages/App/Layout.sty';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'services/location/interface';
import { Variant } from 'services/product/interface';
import { TRANSFER_RELOAD_TIME_MS } from './constants';
import {
  InputContainer,
  InputLabel,
  TransferContainer,
  TransferInputContainer,
  TransfersWrapper,
  VariantsTableWrapper,
} from './Transfer.sty';

const Transfer = () => {
  const { t } = useTranslation();
  const [originLocation, setOriginLocation] = useState<Location | undefined>();
  const [locations, setLocations] = useState<VariantLocation[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [locationsModalVisible, setLocationsModalVisible] =
    useState<boolean>(false);

  const onSeeLocations = useCallback(
    (variant: Variant) => {
      setLocations(variant?.locations ?? []);
      setLocationsModalVisible(true);
    },
    [setLocationsModalVisible],
  );

  const onCloseLocationsModal = useCallback(() => {
    setLocationsModalVisible(false);
  }, [setLocationsModalVisible]);

  const onTransfer = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setReload(true);
    }, TRANSFER_RELOAD_TIME_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [setReload]);

  return (
    <>
      {/* Modals: */}
      <Modal
        visible={locationsModalVisible}
        mask
        destroyOnClose
        onCancel={onCloseLocationsModal}
        onOk={onCloseLocationsModal}
      >
        <LocationList data={locations} />
      </Modal>

      {/* Page: */}
      <Title level={1}>{t('menu.createTransfer')}</Title>
      <TransfersWrapper>
        <VariantsTableWrapper span={17}>
          <TransferContainer>
            <TransferInputContainer>
              <InputContainer>
                <InputLabel>{t('wms.transfers.originLabel')}</InputLabel>
                <LocationAutocomplete
                  placeholder={t('wms.transfers.originPlaceHolder')}
                  isLeaf
                  handleSelect={(value) => {
                    setOriginLocation(value);
                  }}
                />
              </InputContainer>
            </TransferInputContainer>
            <UnitVariantTable
              locationId={originLocation?._id ?? ''}
              location={originLocation}
              onSeeLocations={onSeeLocations}
              reload={reload}
              setReload={setReload}
            />
          </TransferContainer>
        </VariantsTableWrapper>
        <TransferItems onTransfer={onTransfer} />
      </TransfersWrapper>
    </>
  );
};

export default Transfer;
