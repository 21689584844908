import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { UnitRegistry } from './interface';
import UnitMovementDescription from './UnitMovementDescription';
import UnitMovementIcon from './UnitMovementIcon';
import { CardsContainer, MovementCardContainer } from './UnitMovementItem.sty';
import UnitMovementTitle from './UnitMovementTitle';

const UnitMovementItems = ({ registry }: { registry: UnitRegistry }) => {
  return (
    <CardsContainer>
      {registry?.movements?.map((movement, idx) => {
        return (
          <MovementCardContainer
            direction="vertical"
            size="middle"
            key={movement?.id ?? idx}
          >
            <Card>
              <Meta
                avatar={<UnitMovementIcon movement={movement} />}
                title={<UnitMovementTitle movement={movement} />}
                description={<UnitMovementDescription movement={movement} />}
              />
            </Card>
          </MovementCardContainer>
        );
      })}
    </CardsContainer>
  );
};

export default UnitMovementItems;
