import { Method } from 'axios';

export const endpoints = {
  getRoutesCsv: {
    method: 'GET' as Method,
    url: 'order-dom/order-package/csv',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getRoutesFilter: {
    method: 'GET' as Method,
    url: 'order-dom/order-package/status',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
