import { Method } from 'axios';

export const endpoints = {
  getCategoryList: {
    method: 'GET' as Method,
    url: '/product-dom/category',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getCategoryById: {
    method: 'GET' as Method,
    url: '/product-dom/category/{params.categoryId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getCategoryByUrlName: {
    method: 'GET' as Method,
    url: '/product-dom/category-by-name/{params.urlName}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createCategory: {
    method: 'POST' as Method,
    url: '/product-dom/category',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateCategory: {
    method: 'PUT' as Method,
    url: '/product-dom/category/{params.categoryId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteCategory: {
    method: 'DELETE' as Method,
    url: '/product-dom/category/{params.categoryId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
