import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC } from 'react';
import BannerPicItem from './BannerPictureItem';
import { ItemProps } from './interface';

const SortableItem: FC<ItemProps> = ({ id, preSelected, image, ...props }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <BannerPicItem
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      id={id}
      image={image}
      preSelected={preSelected}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableItem;
