import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { SettingListAction, SettingListState } from '../reducer/interface';
import {
  INITIAL_SETTING_LIST_STATE,
  settingsListReducer,
} from '../reducer/settings-list.reducer';

export interface SettingListContextTypes {
  state: SettingListState;
  dispatch: Dispatch<SettingListAction>;
}

const SettingListContext = createContext<SettingListContextTypes>(
  {} as SettingListContextTypes,
);

export const SettingListProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    settingsListReducer,
    INITIAL_SETTING_LIST_STATE,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <SettingListContext.Provider value={value}>
      {children}
    </SettingListContext.Provider>
  );
};

export const useSettingList = () => useContext(SettingListContext);
