/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import { GoogleMap } from '@react-google-maps/api';
import { Divider, Input, Select } from 'antd';
import pandaPin from 'assets/images/pandasMapPin.svg';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import useLocationInput from './hooks/useLocationInput';
import { LocationInputProps } from './interface';
import {
  AddressInputWrapper,
  AddressSuggestion,
  AddressSuggestionList,
  InputLabel,
  InputWrapper,
  MapContainer,
  PinContainer,
  RequiredLabel,
} from './LocationInput.sty';

const LocationInput = ({
  value = {},
  onChange,
  onLoadMap,
  addressSelectedState,
}: LocationInputProps) => {
  const {
    searchOptions,
    departmentName,
    departments,
    mapOptions,
    cityName,
    cities,
    handleSelectAddressOption,
    handleAddressValueChange,
    handleCityChange,
    handleMapLoad,
    triggerChange,
    mapRef,
    setDetails,
    setCoordinates,
    handleDepartmentSelect,
  } = useLocationInput(value, addressSelectedState, onLoadMap, onChange);
  const { t } = useTranslation();

  const handleDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value: inputValue },
    } = e;
    setDetails(inputValue);
    triggerChange({ details: inputValue });
  };

  const handleDragMap = () => {
    const lat = mapRef.current?.getCenter()?.lat() || 0;
    const lng = mapRef.current?.getCenter()?.lng() || 0;
    setCoordinates({ lat, lng });
    triggerChange({ latitude: String(lat), longitude: String(lng) });
  };

  return (
    <>
      <InputWrapper>
        <InputWrapper>
          <RequiredLabel>
            {t('addressInput.modal.labels.department')}
          </RequiredLabel>
          <Select
            showSearch
            placeholder={t('addressInput.placeholders.department')}
            optionFilterProp="children"
            onChange={handleDepartmentSelect}
            value={departmentName}
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {departments.length > 0 &&
              departments.map((department) => (
                <Select.Option key={department.id} value={department.name}>
                  {department.name}
                </Select.Option>
              ))}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <RequiredLabel>
            {t('addressInput.modal.labels.cityCode')}
          </RequiredLabel>
          <Select
            showSearch
            disabled={!departmentName}
            placeholder={t('addressInput.placeholders.cityCode')}
            optionFilterProp="children"
            onChange={handleCityChange}
            value={cityName}
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {cities.length > 0 &&
              cities.map((city) => (
                <Select.Option key={city.id} value={city.name}>
                  {city.name}
                </Select.Option>
              ))}
          </Select>
        </InputWrapper>
        <RequiredLabel>{t('addressInput.modal.labels.address')}</RequiredLabel>
        <PlacesAutocomplete
          value={value.address}
          onChange={handleAddressValueChange}
          debounce={700}
          onSelect={handleSelectAddressOption}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <AddressInputWrapper>
              <Input {...getInputProps()} />
              {suggestions?.length > 0 && (
                <AddressSuggestionList>
                  {suggestions.map((suggestion) => (
                    <AddressSuggestion
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.id}
                    >
                      {suggestion.description}
                    </AddressSuggestion>
                  ))}
                </AddressSuggestionList>
              )}
            </AddressInputWrapper>
          )}
        </PlacesAutocomplete>
      </InputWrapper>
      <InputWrapper>
        <InputLabel>{t('addressInput.modal.labels.details')}</InputLabel>
        <Input.TextArea
          placeholder={t('addressInput.placeholders.details')}
          value={value.details}
          onChange={handleDetailsChange}
        />
      </InputWrapper>
      <Divider />
      <MapContainer>
        <GoogleMap
          zoom={16}
          mapContainerStyle={{
            width: '100%',
            height: '300px',
          }}
          options={{ ...mapOptions, gestureHandling: 'greedy' }}
          onDrag={handleDragMap}
          onLoad={handleMapLoad}
        />
        <PinContainer>
          <img src={pandaPin} alt="mapPin" />
        </PinContainer>
      </MapContainer>
    </>
  );
};

export default LocationInput;
