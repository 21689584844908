/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import {
  HaulierFormValues,
  HaulierModalAction,
  HaulierStatusEnum,
} from 'components/HaulierForm/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Haulier } from 'services/haulier/interface';

const useHaulierForm = (
  initialValues: Haulier,
  actionType?: HaulierModalAction,
  haulierId?: string,
  closeModal?: () => void,
  reLoadOnSuccess?: () => void,
) => {
  const {
    createMutation: { mutateAsync: createHaulier },
    updateMutation: { mutateAsync: updateHaulier },
    deleteMutation: { mutateAsync: deleteHaulier },
  } = useModuleCRUD('haulier');

  const {
    readQuery: { mutateAsync: getParameters },
  } = useModuleCRUD('parameters');

  const { t } = useTranslation();

  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });

  const [carTypes, setCarTypes] = useState<string[]>();
  const [company, setCompany] = useState<string[]>();
  const [haulierStatus, setHauliertStatus] = useState<HaulierStatusEnum>(
    initialValues?.status ?? HaulierStatusEnum.ACTIVE,
  );

  const getSegmentation = useCallback(async () => {
    const res = await getParameters({ keys: 'carTypes,company' });
    setCarTypes(res.carTypes.data.value);
    setCompany(res.company.data.value);
  }, [getParameters, setCarTypes, setCompany]);

  useEffect(() => {
    getSegmentation();
  }, [getSegmentation]);

  const onDeleteModal = async () => {
    try {
      await deleteHaulier(haulierId);
      notifySuccess();
      reLoadOnSuccess?.();
      closeModal?.();
    } catch (error: any) {
      console.error(error);
      notifyError();
    }
  };

  const handleStatusChange = (val: HaulierStatusEnum) => setHauliertStatus(val);

  const submitForm = async (values: HaulierFormValues) => {
    const data = values;
    data.status = haulierStatus;
    if (actionType === HaulierModalAction.CREATE) {
      try {
        await createHaulier(data);
        notifySuccess(t('haulierForm.notifications.creationSuccess'));
        closeModal?.();
        reLoadOnSuccess?.();
      } catch (error) {
        console.error(error);
      }
    }
    if (actionType === HaulierModalAction.UPDATE) {
      try {
        data._id = initialValues._id;
        await updateHaulier(data);
        notifySuccess(t('haulierForm.notifications.updateSuccess'));
        closeModal?.();
        reLoadOnSuccess?.();
      } catch (error) {
        console.error(error);
      }
    }
  };
  return {
    submitForm,
    onDeleteModal,
    handleStatusChange,
    haulierStatus,
    carTypes,
    company,
  };
};

export default useHaulierForm;
