/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { Package } from 'services/package/interface';
import { IPackageForm } from '../interface';
import { ToColDate } from './date';

export function packageToForm(pkg: Package): IPackageForm {
  return {
    ...pkg,
    deliveryDate: format(ToColDate(String(pkg.delivery.date)), 'yyyy-MM-dd'),
    sellerId: pkg.sellers?.[0]?._id ?? pkg.sellers?.[0]?.id ?? '',
    id: pkg._id,
  };
}
