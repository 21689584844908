import styled from 'styled-components';

export const DeliveryDatesWrapper = styled.div`
  margin: 10px 0;
`;

export const DeliveryDatesImageWrapper = styled.div`
  margin: 2px 0;
  display: flex;
  gap: 5px;
`;
