import config from 'config';
import { numberToCurrency } from 'helpers/utils/currency';
import { calculatePurchaseTotal } from 'modules/Purchase/reducer/utils/getPurchaseVariantCost';
import moment from 'moment';
import { PurchaseStatus, PurchaseVariant } from 'services/purchase/interface';
import {
  PurchaseAction,
  PurchaseState,
  PURCHASE_ACTION_ENUM,
  PURCHASE_VIEWS_ENUM,
} from './interface';

const NOW = new Date().toISOString();

export const INITIAL_PURCHASE_REDUCER: PurchaseState = {
  currentView: PURCHASE_VIEWS_ENUM.BOARD,
  filters: {
    search: '',
  },
  readOnly: true,
  step: 1,
  purchaseTotal: numberToCurrency(0),
  showClonePurchaseModal: false,
  formInitialValues: {
    id: '',
    status: PurchaseStatus.PENDING,
    delivery: {
      date: moment().utc() as unknown as string,
      priority: 'HIGH',
      type: 'STANDARD',
      location: config.googleMapsConfig.pandasWarehouses[0],
    },
    detail: [],
    total: 0,
    consecutive: 0,
    purchaseNumber: '',
    metadata: {
      isMarketplace: false,
      isConsignment: false,
    },
    createdAt: NOW,
    updatedAt: NOW,
  },
  autocompletedSuggested: false,
};

export function purchaseReducer(
  state: PurchaseState,
  action: PurchaseAction,
): PurchaseState {
  switch (action.type) {
    case PURCHASE_ACTION_ENUM.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case PURCHASE_ACTION_ENUM.SET_SEARCH_PURCHASES:
      return {
        ...state,
        filters: { ...state.filters, search: action.payload },
      };
    case PURCHASE_ACTION_ENUM.SET_FILTER_STATUS:
      return {
        ...state,
        filters: { ...state.filters, status: action.payload },
      };
    case PURCHASE_ACTION_ENUM.SET_FORM_INITIAL_VALUES:
      return { ...state, formInitialValues: action.payload };
    case PURCHASE_ACTION_ENUM.SET_READONLY:
      return { ...state, readOnly: action.payload };
    case PURCHASE_ACTION_ENUM.SET_STEP:
      return { ...state, step: action.payload };
    case PURCHASE_ACTION_ENUM.SET_PURCHASE_TOTAL: {
      const newDetail: PurchaseVariant[] = action.payload;
      const total = numberToCurrency(calculatePurchaseTotal(newDetail));
      return { ...state, purchaseTotal: total };
    }
    case PURCHASE_ACTION_ENUM.SET_SHOW_CLONE_PURCHASE_MODAL:
      return { ...state, showClonePurchaseModal: action.payload };
    case PURCHASE_ACTION_ENUM.SET_PURCHASE_TO_CLONE:
      return { ...state, purchaseToClone: action.payload };
    case PURCHASE_ACTION_ENUM.SET_AUTOCOMPLETE_SUGGESTED:
      return { ...state, autocompletedSuggested: action.payload };
    default:
      return state;
  }
}
