/* eslint-disable react/jsx-props-no-spreading */
import { DeleteOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Spin } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { bannerColSizes } from 'containers/BannerList/constants';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerCard,
  BannerCardDescription,
  BannerCardImage,
  BannerCardImageLoader,
} from './BannerCardItem.sty';
import {
  BANNER_DELETE_BUTTON_KEY,
  BANNER_EDIT_BUTTON_KEY,
  BANNER_IMAGE_ALT,
  BANNER_IMAGE_LOADING_TYPE,
  BANNER_SWAP_ORDER_BUTTON_KEY,
  NO_BANNER_DESCRIPTION,
  NO_BANNER_TITLE,
} from './constants';
import { BannerCardItemProps } from './interface';

const BannerCardItem = ({
  banner,
  onBannerDelete,
  onBannerEdit,
  onBannerOrder,
}: BannerCardItemProps) => {
  const { t } = useTranslation();
  const [deleteVisible, setDeleteVisible] = useState(false);

  const turnDeleteVisible = useCallback(() => {
    setDeleteVisible(!deleteVisible);
  }, [deleteVisible]);

  const handleBannerEdit = useCallback(() => {
    onBannerEdit(banner);
  }, [onBannerEdit, banner]);

  const handleBannerOrder = useCallback(() => {
    onBannerOrder(banner);
  }, [onBannerOrder, banner]);

  const onBannerDeleteConfirm = useCallback(() => {
    onBannerDelete(banner);
  }, [onBannerDelete, banner]);

  return (
    <Col {...bannerColSizes}>
      <BannerCard
        hoverable
        cover={
          <BannerCardImage
            loading={BANNER_IMAGE_LOADING_TYPE}
            preview
            placeholder={
              <BannerCardImageLoader>
                <Spin />
              </BannerCardImageLoader>
            }
            alt={BANNER_IMAGE_ALT}
            src={banner.image}
          />
        }
        actions={[
          <EditOutlined
            key={BANNER_EDIT_BUTTON_KEY}
            onClick={handleBannerEdit}
          />,
          <SwapOutlined
            key={BANNER_SWAP_ORDER_BUTTON_KEY}
            onClick={handleBannerOrder}
          />,
          <Popconfirm
            title={t('banners.actions.delete.label')}
            onConfirm={onBannerDeleteConfirm}
            onCancel={turnDeleteVisible}
            key={BANNER_DELETE_BUTTON_KEY}
            visible={deleteVisible}
          >
            <DeleteOutlined onClick={turnDeleteVisible} />
          </Popconfirm>,
        ]}
      >
        <Meta
          title={t(banner.title || NO_BANNER_TITLE)}
          description={
            <>
              <BannerCardDescription lineClamp={2}>
                {t(banner.description || NO_BANNER_DESCRIPTION)}
              </BannerCardDescription>
              <BannerCardDescription lineClamp={1}>
                {t('banners.labels.link')}
                <a href={banner.link} target="_blank" rel="noreferrer">
                  {banner.link}
                </a>
              </BannerCardDescription>
            </>
          }
        />
      </BannerCard>
    </Col>
  );
};

export default BannerCardItem;
