import { endpoints as attributeType } from './attribute-type';
import { endpoints as attributes } from './attributes';
import { endpoints as brands } from './brands';
import { endpoints as cart } from './cart';
import { endpoints as categories } from './categories';
import { endpoints as coupons } from './coupons';
import { endpoints as credit } from './credit';
import { endpoints as files } from './files';
import { endpoints as haulier } from './haulier';
import { endpoints as location } from './location';
import { endpoints as mps } from './mps';
import { endpoints as MPSUser } from './MPSUser';
import { endpoints as orders } from './orders';
import { endpoints as pandabonos } from './pandabonos';
import { endpoints as parameters } from './parameters';
import { endpoints as products } from './products';
import { endpoints as purchase } from './purchase';
import { endpoints as routes } from './routes';
import { endpoints as shipping } from './shipping';
import { endpoints as shippingZones } from './shipping-zones';
import { endpoints as stock } from './stock';
import { endpoints as stores } from './stores';
import { endpoints as transfer } from './transfer';
import { endpoints as transport } from './transport';
import { endpoints as unit } from './unit';
import { endpoints as user } from './user';
import { endpoints as variantCsv } from './variant-csv';
import { endpoints as variants } from './variants';
import { endpoints as wallet } from './wallet';
import { endpoints as warranty } from './warranty';
import { endpoints as wmsSettings } from './wms-settings';

export default {
  baseURL: process.env.REACT_APP_API_URL,
  endpoints: {
    ...products,
    ...orders,
    ...categories,
    ...attributes,
    ...variants,
    ...user,
    ...cart,
    ...purchase,
    ...coupons,
    ...parameters,
    ...brands,
    ...stores,
    ...shipping,
    ...credit,
    ...pandabonos,
    ...mps,
    ...routes,
    ...haulier,
    ...attributeType,
    ...variantCsv,
    ...location,
    ...unit,
    ...stock,
    ...warranty,
    ...transfer,
    ...MPSUser,
    ...shippingZones,
    ...transport,
    ...wallet,
    ...files,
    ...wmsSettings,
  },
};
