import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { WalletListProps } from 'containers/Wallet/interface';
import { WalletTransaction } from 'services/wallet/interface';

const TABLE_HEADER_HEIGHT = 160;

export const WalletTable = ({
  list,
  tableRef,
  loading,
  columns,
  reachedEndOfData,
  onScrollToEnd,
  onViewDetail,
}: WalletListProps) => {
  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="wallet-table"
      isLoading={loading}
      columns={columns}
      rowKey="user.id"
      onRow={(record) => ({
        onClick: () => {
          onViewDetail(record as WalletTransaction);
        },
      })}
      pagination={false}
      dataSource={list || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};
