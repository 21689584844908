import styled from 'styled-components';
import { LightTheme } from 'helpers/constants/theme';
import { Col } from 'antd';

export const ItemParagraph = styled.div`
    margin: ${LightTheme.spaceSizes.s} 0;
`;

export const ItemLabel = styled.span`
    font-weight: bold;
    margin-right: ${LightTheme.spaceSizes.m};
`;

export const SearchCol = styled(Col)`
    margin: ${LightTheme.spaceSizes.m} 0;
`;
