import { useCallback, useEffect, useState } from 'react';
import { ModalProps } from './interface';

const useModal = ({
  onOpen,
  onClose,
  canClose = true,
  isOpen = false,
}: ModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const openModal = useCallback(() => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [setOpen, onOpen]);

  const closeModal = useCallback(() => {
    if (!canClose) {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [canClose, onClose]);

  return {
    isOpen: open,
    openModal,
    closeModal,
    onOpen,
    onClose,
  };
};

export default useModal;
