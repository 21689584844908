/* eslint-disable react/require-default-props */
import { Form, FormInstance } from 'antd';
import { uniqueId } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AllowedRuleProp,
  Behavior,
  IBehaviorForm,
  RuleOption,
  WmsSetting,
} from 'services/wms-settings/interface';
import { BehaviorFormNamePaths, BehaviorFormProps } from '../constants';
import ValueSelector from '../helpers/ValueSelector';
import RuleFormItem from './RuleFormItem';

const BehaviorForm = ({
  ruleDefinitions,
  setting,
  form,
  onSubmit,
  initialValues,
}: {
  ruleDefinitions: AllowedRuleProp[];
  setting: WmsSetting;
  form: FormInstance;
  onSubmit: (behavior: Behavior) => void;
  initialValues?: Behavior;
}) => {
  const tlPrefix = useMemo(
    () => `wms-settings.setting.${setting.setting}`,
    [setting],
  );
  const { t } = useTranslation();

  const onSetValue = useCallback(
    (value) => {
      form.setFieldValue(BehaviorFormNamePaths.value, value);
    },
    [form],
  );

  const initialValue: RuleOption | undefined = useMemo(() => {
    if (!initialValues?.label || !initialValues?.value) return undefined;
    return {
      label: initialValues.label,
      value: initialValues.value,
    };
  }, [initialValues]);

  const handleSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();

    const builtBehavior: Behavior = {
      ruleSet: Object.values(formValues.rules),
      label: formValues.value.label,
      value: formValues.value.value,
    };

    onSubmit(builtBehavior);
  }, [form, onSubmit]);

  useEffect(() => {
    if (!initialValues) return;

    const initial: IBehaviorForm = {
      rules: initialValues.ruleSet.reduce(
        (acc, rule) => ({
          ...acc,
          [rule.field]: rule,
        }),
        {},
      ),
      value: {
        label: initialValues.label,
        value: initialValues.value,
      },
    };

    form.setFieldsValue(initial);
  }, [form, initialValues]);

  return (
    <Form layout={BehaviorFormProps.layout} form={form} onFinish={handleSubmit}>
      {ruleDefinitions.map((definition) => (
        <RuleFormItem
          key={definition.field}
          definition={definition}
          form={form}
          tlPrefix={tlPrefix}
          initialValues={initialValues}
        />
      ))}
      <Form.Item
        label={t(`${tlPrefix}.rules.form.labels.value`)}
        name={BehaviorFormNamePaths.value}
        required
        rules={[
          {
            required: true,
            message: t('wms-settings.behavior.form.errors.value'),
          },
        ]}
      >
        <ValueSelector
          key={uniqueId()}
          onSelectValue={onSetValue}
          setting={setting}
          settingType={setting.setting}
          defaultValue={initialValue}
        />
      </Form.Item>
    </Form>
  );
};

export default BehaviorForm;
