import { ColumnsType } from 'antd/lib/table';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { t } from 'i18next';
import {
  OrderHiddenDescription,
  OrderTableColumns,
} from 'modules/Order/components/OrderTable/OrderColumns';
import { useMemo } from 'react';
import { Order } from 'services/order/interface';

const useOrderTableModule = () => {
  const defaultOrderColumns = OrderTableColumns();
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);

  const columns: ColumnsType<Order> = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('orderTableKeys.client'),
      t('orderTableKeys.actions'),
      t('orderTableKeys.total'),
      t('orderTableKeys.deliveryDate'),
      t('orderTableKeys.state'),
    ];

    const filteredOrderColumns: ColumnsType<Order> = isMobile
      ? defaultOrderColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultOrderColumns;

    return filteredOrderColumns;
  }, [defaultOrderColumns, isMobile]);

  return {
    columns,
    hiddenDescription: (order: Order) => OrderHiddenDescription({ order }),
  };
};

export default useOrderTableModule;
