import { Button, Modal, Select } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

interface ColoredTagProps {
  color: string;
}

export const TitleSpan = styled.h2`
  font-weight: bold;
  font-size: ${LightTheme.spaceSizes.xl4};
  color: ${LightTheme.color.dark};
  margin: 0;
  padding: 0;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${LightTheme.spaceSizes.xxl};
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.m};
`;

export const DeliveryDateWrapper = styled.div`
  font-size: ${LightTheme.spaceSizes.l};
  color: ${LightTheme.color.gray_light};
  display: flex;
  flex-direction: row;
  gap: ${LightTheme.spaceSizes.m};
`;

export const DeliveryDateLabel = styled.span`
  color: ${LightTheme.color.dark};
`;

export const OrderCountTag = styled.span`
  background-color: ${LightTheme.color.gray_light};
  border-radius: 8px;
  color: ${LightTheme.color.primary_dark};
  padding: ${LightTheme.spaceSizes.sm} ${LightTheme.spaceSizes.m};
`;

export const StatusFlag = styled.div<ColoredTagProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ color }) => `${color}`};
  margin-right: ${LightTheme.spaceSizes.sm};
`;

export const NewStatusSelect = styled(Select)`
  max-width: 150px;
  border: 1px solid ${LightTheme.color.gray_dark} !important;
  border-radius: 8px !important;
  padding: ${LightTheme.spaceSizes.sm} ${LightTheme.spaceSizes.m};
`;

export const StatusItemContainer = styled.div`
  display: flex;
  min-width: 150px;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.s};
  margin-bottom: ${LightTheme.spaceSizes.xl3};
`;

export const StatusContainer = styled.span`
  display: flex;
  flex-direction: row;
  max-width: 150px;
  border-radius: 8px;
  padding: ${LightTheme.spaceSizes.s} ${LightTheme.spaceSizes.m};
  padding-left: 0;
`;

export const SubmitButton = styled(Button)`
  width: 170px;
  height: 40px;
  background: ${LightTheme.color.primary};
  border-radius: 8px;
  border: none;
  color: ${LightTheme.color.white};
  &:hover,
  &:active,
  &:focus {
    color: ${LightTheme.color.gray_lighter};
    background: ${LightTheme.color.primary};
  }
`;

export const OrderNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.m};
`;

export const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.xs};
  span {
    margin: 0;
  }
`;

export const FooterRow = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const OrderFormModal = styled(Modal)`
  .ant-modal-content {
    height: 85vh !important;
    max-height: 85vh !important;
    position: relative;
    overflow-y: hidden;

    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          background: none !important;
          border: 1px ${LightTheme.color.primary_dark} !important;
        }
      }
    }

    .ant-modal-footer {
      padding: ${LightTheme.spaceSizes.xl} ${LightTheme.spaceSizes.xl};
      background: ${LightTheme.color.white};
      opacity: 1 !important;
      box-sizing: border-box !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: fit-content;
    }
  }

  .ant-modal-body {
    height: calc(100% - 185px) !important;
    overflow-y: scroll;
  }
`;
