/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { useMutation } from '@tanstack/react-query';
import { CsvInput } from 'components/CsvInput/CsvInput';
import config from 'config';
import { getAvailableCsvTemplate } from 'services/stock/stock.service';

export const VariantAvailable = () => {
  const { mutateAsync: fetchFormat, isLoading: isLoadingFormat } = useMutation({
    mutationFn: getAvailableCsvTemplate,
  });

  return (
    <CsvInput
      fetchFormat={fetchFormat}
      isLoadingFormat={isLoadingFormat}
      draggerName="file"
      actionUrl={config.availableSetCsvUpload.actionURL}
      fileName="variant_av.csv"
      title="availableSetCsv.modal.content"
      csvInputHint="availableSetCsv.hint"
      method="POST"
    />
  );
};
