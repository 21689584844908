import { Alert, Progress, Result } from 'antd';
import { useMemo } from 'react';
import { DEFAULT_RESULT_STATUS } from './constants';
import CsvPresentation from './CsvPresentation';
import { CsvModuleProps, ProgressBarStatusesEnum } from './interface';

export const CsvModule = ({
  uploadProps,
  hint,
  title,
  inputText,
  formatDownloadProps,
  statusCheckingProps,
  ErrorView,
  showAlertResult,
}: CsvModuleProps) => {
  const statusBar: ProgressBarStatusesEnum = useMemo(() => {
    // If status checking is disabled then show the progress bar as normal
    if (!statusCheckingProps) return ProgressBarStatusesEnum.normal;
    if (statusCheckingProps.loadInProgress)
      return ProgressBarStatusesEnum.active;

    const { errors } = statusCheckingProps;
    if (!errors) return ProgressBarStatusesEnum.success;

    const isErrorArray = Array.isArray(errors) && errors.length > 0;
    const isErrorString = typeof errors === 'string' && errors !== '';

    if (isErrorArray || isErrorString) return ProgressBarStatusesEnum.exception;
    if (Number(statusCheckingProps.progress) === 100)
      return ProgressBarStatusesEnum.success;

    return ProgressBarStatusesEnum.active;
  }, [statusCheckingProps]);

  const renderProgress = useMemo(
    () =>
      !!statusCheckingProps &&
      (statusCheckingProps.progress > 0 || statusCheckingProps.loadInProgress),
    [statusCheckingProps],
  );

  return (
    <>
      <Result
        status={DEFAULT_RESULT_STATUS}
        title={title}
        extra={
          <CsvPresentation
            hint={hint}
            inputText={inputText}
            uploadProps={uploadProps}
            formatDownloadProps={formatDownloadProps}
          />
        }
      />
      {!!statusCheckingProps && renderProgress && (
        <>
          <Progress
            percent={Number(statusCheckingProps.progress.toFixed(1))}
            success={{ percent: Number(statusCheckingProps.progress) }}
            status={statusBar}
          />
          {Number(statusCheckingProps.progress) >= 100 && !!showAlertResult && (
            <Alert
              message={statusCheckingProps.successMessage}
              type={ProgressBarStatusesEnum.success}
            />
          )}
          {statusBar === ProgressBarStatusesEnum.exception &&
            ErrorView !== undefined && (
              <ErrorView errors={statusCheckingProps.errors} />
            )}
        </>
      )}
    </>
  );
};
