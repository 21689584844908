import { Footer } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const ProductFormFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  z-index: 1;
`;
