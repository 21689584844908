import {
  SettingListAction,
  SettingListState,
  SETTING_LIST_ACTION_ENUM,
} from './interface';

export const INITIAL_SETTING_LIST_STATE: SettingListState = {
  page: 1,
  pageSize: 10,
  settingsList: [],
};

export function settingsListReducer(
  state: SettingListState,
  action: SettingListAction,
): SettingListState {
  switch (action.type) {
    case SETTING_LIST_ACTION_ENUM.SET_SETTINGS_LIST:
      return { ...state, settingsList: action.payload?.data ?? [] };
    default:
      return state;
  }
}
