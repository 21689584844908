import { InputNumberProps } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import { numberValidator, stringValidator } from 'helpers/utils/formValidators';
import { TFunction } from 'i18next';
import { IShippingZoneForm, RegionalShippingCostType } from '../interface';
import { LabelValue } from '../reducer/interface';

export const InputNumberDefProps: InputNumberProps = {
  style: {
    width: '100%',
  },
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EmptyFunction = () => {};
export const costOperatorOptions = ['PERCENTAGE', 'NOMINAL'];
export const shippingCostTypeOptions = [
  RegionalShippingCostType.FIXED,
  RegionalShippingCostType.VARIABLE,
];

export function getDeliveryDayOptions(t: TFunction): LabelValue<number>[] {
  return [
    { label: t('Monday'), value: 1 },
    { label: t('Tuesday'), value: 2 },
    { label: t('Wednesday'), value: 3 },
    { label: t('Thursday'), value: 4 },
    { label: t('Friday'), value: 5 },
    { label: t('Saturday'), value: 6 },
    { label: t('Sunday'), value: 7 },
  ];
}

export function getDefaultInputRules(t: TFunction): Rule[] {
  return [
    {
      required: true,
      message: t('BOGENE003'),
    },
  ];
}

export function getStringInputRules(t: TFunction): Rule[] {
  return [
    {
      validator: (_, val) =>
        stringValidator(
          val,
          {
            required: true,
          },
          t,
        ),
    },
  ];
}
export function getNumberInputRules(t: TFunction): Rule[] {
  return [
    {
      validator: (_, val) =>
        numberValidator(
          val,
          {
            required: true,
            min: 0,
          },
          t,
        ),
    },
  ];
}
export function getCurrencyInputRules(t: TFunction): Rule[] {
  return [
    {
      validator: (_, val) =>
        numberValidator(
          val,
          {
            required: true,
            min: 100,
          },
          t,
        ),
    },
  ];
}

export function getFreeShippingLimitValidators(
  t: TFunction,
  form: FormInstance<IShippingZoneForm>,
): Rule[] {
  return [
    ...getCurrencyInputRules(t),
    {
      validator: (_, val) => {
        const minimumOrderAmount =
          form.getFieldValue('minimumOrderAmount') ?? 0;
        if ((val ?? 0) <= minimumOrderAmount) {
          return Promise.reject(
            new Error(t('shippingZone.form.errors.freeShippingLimit')),
          );
        }
        return Promise.resolve();
      },
    },
  ];
}
