import { RcFile } from 'antd/lib/upload';

export function validateCsvFile(
  fileUpload: RcFile,
  loadInProgress?: boolean,
): string | null {
  if (loadInProgress) {
    return 'variantsCsvInput.modal.message.currentProcessError';
  }
  const { name } = fileUpload;
  const extension = name.split('.').pop();

  if (extension !== 'csv') {
    return 'variantsCsvInput.modal.message.extensionError';
  }

  return null;
}
