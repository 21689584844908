/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
import {
  Button,
  Collapse,
  Form,
  FormInstance,
  FormListFieldData,
  Select,
  Tabs,
} from 'antd';
import { CustomTag } from 'components/CustomTag';
import LoaderWrapper from 'components/LoadingWrapper';
import { t } from 'i18next';
import { requiredRule } from 'modules/Order/constants/rules';
import OrderPackageContainer from 'modules/Order/containers/OrderPackageContainer';
import { OrderPackageProvider } from 'modules/Order/context/OrderPackage.context';
import { GetPaymentUpdateOptions } from 'modules/Order/helpers/orderForm';
import { getOrderAccessMap } from 'modules/Order/helpers/orderStatus';
import { PaymentUpdateStatus } from 'modules/Order/interface';
import { detailValidator } from 'modules/Order/reducer/utils/detail.validator';
import { useMemo } from 'react';
import {
  Order,
  OrderStatusAction,
  OrderTotals,
  OrderType,
  PaymentConcepts,
  PaymentMethodStatus,
  StatusOrderEnum,
  StatusTagColor,
  WarrantyOrderDTO,
} from 'services/order/interface';
import { PickingProduct } from 'services/product/interface';
import {
  DeliveryDateOption,
  DeliveryOption,
} from 'services/shipping/interface';
import { User } from 'services/user/interface';
import { WarrantyStatus } from 'services/warranty/interface';
import DetailsTable from '../DetailsTable/DetailsTable';
import HistoryOrder from '../HistoryOrder';
import { WarrantyCard } from '../WarrantyCard';
import {
  PendingWarrantyCounter,
  WarrantyCardWrapper,
} from '../WarrantyCard/WarrantyList.sty';
import OrderClientData from './OrderClientData';
import {
  ButtonCol,
  CustomOrderTabs,
  FinanceButtonsWrapper,
  FooterRow,
  FormWrapper,
  StatusChangesWrapper,
  SubmitButton,
  TotalCol,
} from './OrderForm.sty';
import OrderFormHeader from './OrderFormHeader';
import OrderInvoicesForm from './OrderInvoicesForm';
import OrderProductTable from './OrderProductTable';
import OrderSummaryData from './OrderSummaryData';
import OrderTotalDisplay from './OrderTotalDisplay';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const NIT_TYPE_VALUE = 'NIT';

interface OrderFormProps {
  isUpdate: boolean;
  loadingOrder: boolean;
  currentStatus: StatusOrderEnum;
  form: FormInstance<Order>;
  initialValues?: Order;
  values: Order;
  disableForm: boolean;
  disableMpsVariantAutocomplete: boolean;
  handleFormFinish: () => void;
  hasAccess: (
    currentState: StatusOrderEnum,
    action: OrderStatusAction,
  ) => boolean;
  refetchOrder?: () => void;
  onChangeStatus?: (newStatus: StatusOrderEnum) => void;
  maxDiscount: number;
  isAdmin: boolean;
  handleDiscountValue: (value: number) => void;
  deliveryDateOptions: DeliveryDateOption[];
  segmentationValues: string[];

  loadingPendingStockUpdate?: boolean;
  onEnableForm?: () => void;
  onGoToOrder?: (order: Partial<Order>) => void;
  updatePendingStock?: () => void;
  updatePaymentStatus?: () => void;
  handlePaymentStatusSelect?: (status: PaymentUpdateStatus) => void;
  onViewDropshipperClientRemission?: (id: string) => void;
  onViewPickingSheet?: (id: string) => void;
  onViewRemission?: (id: string) => void;
  onSelectUser?: (user: User) => void;
  setSelectedWarrantyOptions?: (orders: WarrantyOrderDTO[]) => void;
  onChangePandabonosUse?: (checked: boolean) => void;
  goToList: () => void;
  skipShipping?: () => void;

  totals?: OrderTotals;
  loadingTotals: boolean;
  loadingSubmit: boolean;
  loadingPaymentUpdate?: boolean;
  pandabonos?: number;
  canUpdatePendingStock?: boolean;
  canUpdatePaymentStatus?: boolean;
  canUpdateInvoices?: boolean;
  userCanUpdateOrder?: boolean;
  userCanCreateOrder?: boolean;
  userHasSkipPaymentPermission?: boolean;
  isLoadingSkipShipping?: boolean;
  deliveryOptions?: DeliveryOption[];
}

const OrderForm = ({
  isUpdate,
  loadingOrder,
  currentStatus,
  form,
  initialValues,
  values,
  disableForm,
  disableMpsVariantAutocomplete,
  maxDiscount,
  isAdmin,
  deliveryDateOptions,
  segmentationValues,
  handleDiscountValue,
  handlePaymentStatusSelect,
  handleFormFinish,
  hasAccess,
  refetchOrder,
  onChangeStatus,
  loadingPendingStockUpdate,
  userHasSkipPaymentPermission,
  skipShipping,
  isLoadingSkipShipping,
  onEnableForm,
  onGoToOrder,
  updatePendingStock,
  updatePaymentStatus,
  onViewDropshipperClientRemission,
  onViewPickingSheet,
  onViewRemission,
  onSelectUser,
  setSelectedWarrantyOptions,
  onChangePandabonosUse,
  goToList,
  totals,
  loadingTotals,
  loadingSubmit,
  loadingPaymentUpdate = false,
  pandabonos,
  canUpdatePendingStock,
  canUpdatePaymentStatus,
  canUpdateInvoices,
  userCanUpdateOrder,
  userCanCreateOrder,
  deliveryOptions,
}: OrderFormProps) => {
  const is3POrder = values.orderType === OrderType['3P'];
  const statusEval = useMemo(
    () =>
      (values?.status ??
        initialValues?.status ??
        StatusOrderEnum.CANCELLED) as StatusOrderEnum,
    [initialValues?.status, values?.status],
  );
  const shippingPayment = useMemo(
    () =>
      initialValues?.paymentData?.find(
        (p) => p.concept === PaymentConcepts.SHIPPING,
      ),
    [initialValues],
  );

  const statusOptions = useMemo(() => {
    if (
      !!shippingPayment &&
      shippingPayment?.status !== PaymentMethodStatus.PAID
    ) {
      return [StatusOrderEnum.CANCELLED];
    }

    if (!initialValues) {
      return [];
    }
    return getOrderAccessMap(initialValues) ?? [];
  }, [initialValues, shippingPayment]);

  const pendingWarranties = useMemo(
    () =>
      values?.warranties?.warranties?.filter(
        (w) => w.status === WarrantyStatus.PENDING,
      ).length ?? 0,
    [values?.warranties?.warranties],
  );
  const paymentOptions = GetPaymentUpdateOptions(t);

  return (
    <LoaderWrapper loading={loadingOrder}>
      <FormWrapper>
        <OrderFormHeader
          initialValues={initialValues}
          orderData={values}
          title={
            isUpdate
              ? t('orderForm.titles.detail')
              : t('orderForm.titles.create')
          }
          disableEditButton={!hasAccess(statusEval, 'editForm')}
          canEditOrder={
            hasAccess(statusEval, 'editForm') && (userCanUpdateOrder ?? false)
          }
          loadingPendingStockUpdate={loadingPendingStockUpdate}
          onEnableForm={onEnableForm}
          onGoToOrder={onGoToOrder}
          updatePendingStock={updatePendingStock}
          onViewDropshipperClientRemission={onViewDropshipperClientRemission}
          onViewRemission={onViewRemission}
          onViewPickingSheet={onViewPickingSheet}
          goToList={goToList}
          canUpdatePendingStock={canUpdatePendingStock}
          shippingPayment={shippingPayment}
        />
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleFormFinish}
          // onValuesChange={handleFormValuesChange}
          name="update-order-modal"
          autoComplete="off"
          labelWrap
          layout="vertical"
        >
          <StatusChangesWrapper>
            <Form.Item
              name="status"
              rules={[requiredRule]}
              style={{ maxWidth: '245px', margin: '0px' }}
            >
              <Select
                placeholder={t('userForm.placeholders.docType')}
                disabled={disableForm || !isUpdate}
                onChange={onChangeStatus}
              >
                <>
                  <Select.Option
                    key={currentStatus}
                    value={StatusOrderEnum[currentStatus]}
                  >
                    <CustomTag color={StatusTagColor[currentStatus]}>
                      {t(`orderStatus.${currentStatus}`) ??
                        `${currentStatus}` ??
                        ''}
                    </CustomTag>
                  </Select.Option>
                  {statusOptions.map(
                    (statusKey: keyof typeof StatusOrderEnum) => (
                      <Select.Option
                        key={statusKey}
                        value={StatusOrderEnum[statusKey]}
                      >
                        <CustomTag color={StatusTagColor[statusKey]}>
                          {t(`orderStatus.${statusKey}`) ??
                            `${statusKey}` ??
                            ''}
                        </CustomTag>
                      </Select.Option>
                    ),
                  )}
                </>
              </Select>
            </Form.Item>
            {shippingPayment &&
              shippingPayment?.status !== PaymentMethodStatus.PAID &&
              userHasSkipPaymentPermission && (
                <Button
                  loading={isLoadingSkipShipping}
                  onClick={skipShipping}
                  type="primary"
                >
                  {t('orderForm.buttons.skipPayment')}
                </Button>
              )}
          </StatusChangesWrapper>
          <CustomOrderTabs defaultActiveKey="1">
            <TabPane tab={t('orderForm.modal.tabs.titles.summaryData')} key="1">
              <Collapse
                defaultActiveKey={
                  canUpdatePaymentStatus ? ['1', '3', 'finance'] : ['1', '3']
                }
              >
                {(canUpdatePaymentStatus || canUpdateInvoices) && (
                  <Panel
                    key="finance"
                    header={
                      canUpdateInvoices
                        ? t('orderForm.panel.title.invoicesInfo')
                        : t('orderForm.panel.title.paymentStatus')
                    }
                    forceRender
                  >
                    <>
                      {canUpdatePaymentStatus && (
                        <>
                          <p>{t('orderForm.titles.updatePaymentStatus')}</p>
                          <FinanceButtonsWrapper>
                            <Select
                              placeholder={t('orderForm.labels.paymentStatus')}
                              options={paymentOptions}
                              onSelect={handlePaymentStatusSelect}
                            />
                            <Button
                              type="primary"
                              onClick={updatePaymentStatus}
                              loading={loadingPaymentUpdate}
                            >
                              {t('orderForm.buttons.updatePayment')}
                            </Button>
                          </FinanceButtonsWrapper>
                        </>
                      )}
                      {canUpdateInvoices && (
                        <OrderInvoicesForm t={t} disableForm={disableForm} />
                      )}
                    </>
                  </Panel>
                )}
                <Panel
                  key="1"
                  header={t('orderForm.panel.title.rawInfo')}
                  collapsible="disabled"
                >
                  <OrderSummaryData
                    orderData={values}
                    initialValues={initialValues}
                    disabled={disableForm || is3POrder}
                    disabledEditSeller={hasAccess(statusEval, 'editSeller')}
                    segmentationsDisabled={
                      (form.getFieldValue('discount') ?? 0) === 0
                    }
                    maxDiscount={maxDiscount}
                    isAdmin={isAdmin}
                    isUpdate={isUpdate}
                    handleDiscountValue={handleDiscountValue}
                    onSelectUser={onSelectUser}
                    deliveryDateOptions={deliveryDateOptions}
                    segmentationValues={segmentationValues}
                    pandabonos={pandabonos}
                    setSelectedWarrantyOptions={setSelectedWarrantyOptions}
                    onChangePandabonosUse={onChangePandabonosUse}
                    deliveryOptions={deliveryOptions}
                    form={form}
                  />
                </Panel>
                <Panel key="2" header={t('orderForm.panel.title.clientInfo')}>
                  <OrderClientData
                    locationOptions={
                      values?.user?.localization
                        ? [values?.user?.localization]
                        : []
                    }
                    requireLastName={
                      values?.user?.documentType !== NIT_TYPE_VALUE
                    }
                    disabled={
                      disableForm || !hasAccess(statusEval, 'editClientData')
                    }
                  />
                </Panel>
                <Panel key="3" header={t('orderForm.panel.title.detail')}>
                  <Form.List
                    name="detail"
                    rules={[{ validator: detailValidator }]}
                  >
                    {(fields, { add, remove }) => (
                      <OrderProductTable
                        detail={fields.map(
                          (field: FormListFieldData, idx: number) => ({
                            ...(values?.detail?.[idx] ?? {}),
                            field,
                            subtotal:
                              Number(values?.detail?.[idx]?.cartQuantity ?? 0) *
                              Number(values?.detail?.[idx]?.price || 0),
                          }),
                        )}
                        add={add}
                        remove={remove}
                        disabled={
                          disableForm ||
                          !hasAccess(statusEval, 'updateProducts') ||
                          disableMpsVariantAutocomplete
                        }
                        pendingStock={
                          statusEval === StatusOrderEnum.STOCK_PENDING
                            ? values?.pendingStock
                            : undefined
                        }
                        isUpdate={isUpdate}
                      />
                    )}
                  </Form.List>
                </Panel>
              </Collapse>
            </TabPane>
            <TabPane tab={t('orderForm.modal.tabs.titles.history')} key="2">
              <HistoryOrder historyList={initialValues?.history ?? []} />
            </TabPane>
            {values.orderType === OrderType['3P'] && (
              <TabPane tab={t('orderForm.modal.tabs.titles.packages')} key="6">
                <OrderPackageProvider>
                  <OrderPackageContainer order={values} orderId={values.id} />
                </OrderPackageProvider>
              </TabPane>
            )}
            {hasAccess(statusEval, 'editPackedUnits') && (
              <TabPane tab={t('orderForm.titles.packing')} key="3">
                <Form.List name="picked">
                  {(fields) => (
                    <DetailsTable
                      pickProducts={form
                        .getFieldValue('picked')
                        .map((pickedVariant: PickingProduct, idx: number) => ({
                          ...pickedVariant,
                          field: fields[idx],
                        }))}
                      isLoading={loadingOrder}
                      disabled={
                        disableForm || !hasAccess(statusEval, 'editPackedUnits')
                      }
                    />
                  )}
                </Form.List>
              </TabPane>
            )}
            {hasAccess(statusEval, 'editReturnedUnits') && (
              <TabPane tab={t('orderForm.titles.delivered')} key="4">
                <Form.List name="delivered">
                  {(fields) => (
                    <DetailsTable
                      pickProducts={form
                        .getFieldValue('delivered')
                        .map((pickedVariant: PickingProduct, idx: number) => ({
                          ...pickedVariant,
                          field: fields[idx],
                        }))}
                      isLoading={loadingOrder}
                      disabled={
                        disableForm ||
                        !hasAccess(statusEval, 'editReturnedUnits')
                      }
                    />
                  )}
                </Form.List>
              </TabPane>
            )}
            {statusEval === StatusOrderEnum.DELIVERED && (
              <TabPane
                tab={
                  <>
                    {t('orderForm.titles.warranty')}{' '}
                    {pendingWarranties > 0 && (
                      <PendingWarrantyCounter>
                        {pendingWarranties}
                      </PendingWarrantyCounter>
                    )}
                  </>
                }
                key="5"
              >
                <WarrantyCardWrapper>
                  {(values?.warranties?.warranties ?? []).map((warranty) => (
                    <WarrantyCard
                      key={warranty.id}
                      warranty={warranty}
                      refetch={refetchOrder}
                    />
                  ))}
                </WarrantyCardWrapper>
              </TabPane>
            )}
          </CustomOrderTabs>

          {/* HIDDEN */}
          <Form.Item hidden name="user" />
          <Form.Item hidden name="id" />
          <Form.Item hidden name="detail" />
          <Form.Item hidden name="consecutive" />
          <Form.Item hidden name="totals" />
          <Form.Item hidden name="total" />
          <Form.Item hidden name="subtotal" />
          <Form.Item hidden name="payment" />
          <Form.Item hidden name="delivery" />
          <Form.Item hidden name="seller" />
          <Form.Item hidden name="sellerCoordinator" />
          <Form.Item hidden name="warrantyOrders" />
          <Form.Item hidden name="createdAt" />
          <Form.Item hidden name="updatedAt" />
          <Form.Item hidden name="isFirstBuy" />
          <Form.Item hidden name="isWarranty" />
          <Form.Item hidden name="warranties" />
          <Form.Item hidden name="isDropShipper" />
          <Form.Item hidden name="dropShipperInfo" />
          <Form.Item hidden name="freeShippingCost" />
          <Form.Item hidden name="delivered" />
          <Form.Item hidden name="picked" />
          <Form.Item hidden name="history" />
          <Form.Item hidden name="coupon" />
          <Form.Item hidden name="pendingStock" />
          <Form.Item hidden name="orderType" />
          <Form.Item hidden name="paymentMethod" />
          <Form.Item hidden name="bankTransferPaid" />
          <Form.Item hidden name="shippingQuote" />
        </Form>
        <FooterRow>
          <TotalCol span={16}>
            <OrderTotalDisplay totals={totals} loadingTotals={loadingTotals} />
          </TotalCol>

          <ButtonCol span={4}>
            <SubmitButton
              type="primary"
              onClick={handleFormFinish}
              loading={loadingSubmit}
              disabled={
                disableForm ||
                !hasAccess(statusEval, 'editForm') ||
                (!isUpdate && !userCanCreateOrder)
              }
            >
              {t(
                `orderForm.modal.buttonTexts.${isUpdate ? 'update' : 'create'}`,
              )}
            </SubmitButton>
          </ButtonCol>
        </FooterRow>
      </FormWrapper>
    </LoaderWrapper>
  );
};

export default OrderForm;
