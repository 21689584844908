/* eslint-disable no-param-reassign */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { t } from 'i18next';
import { useMemo } from 'react';
import { CART_ITEM_COLORS } from 'services/cart/interface';
import { InsufficientStockDetails } from 'services/order/interface';
import { VariantImage } from 'services/product/interface';
import { ProductFigure } from '../../OrderTable/OrderTable.sty';

export interface InsufficientStockModalProps {
  open: boolean;
  details: InsufficientStockDetails[];
  goToWMSTransfers: () => void;
  onEditOrderDetail: () => void;
  canEditOrderDetail: boolean;
  closeStockModal: () => void;
}

const columns = [
  {
    title: t('stockInfoModal.columns.image'),
    dataIndex: 'images',
    key: 'images',
    width: '120px',
    render: (images: Array<VariantImage>, info: InsufficientStockDetails) => (
      <ProductFigure>
        <img
          src={
            images?.length
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${images?.[0]?.url}`
              : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
          }
          alt={info.variantName ?? ''}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
          }}
        />
      </ProductFigure>
    ),
  },
  {
    title: t('stockInfoModal.columns.product'),
    dataIndex: 'variantName',
    width: '250px',
  },
  {
    title: t('stockInfoModal.columns.sku'),
    dataIndex: 'variantSku',
    width: '150px',
  },
  {
    title: t('stockInfoModal.columns.status'),
    width: '110px',
    render: (info: InsufficientStockDetails) =>
      !info.canFillWithEntry ? (
        <CustomTag color={CART_ITEM_COLORS.OUT_OF_STOCK}>
          {t(`stockStatus.OUT_OF_STOCK`)}
        </CustomTag>
      ) : (
        <CustomTag color={CART_ITEM_COLORS.LIMITED_STOCK}>
          {t(`stockStatus.LIMITED_STOCK`)}
        </CustomTag>
      ),
  },
  {
    title: t('stockInfoModal.columns.quantity'),
    dataIndex: 'quantity',
    width: '100px',
  },
  {
    title: t('stockInfoModal.columns.unitsInEntry'),
    dataIndex: 'unitsInEntry',
    width: '100px',
  },
  {
    title: t('stockInfoModal.columns.canFillWithEntry'),
    dataIndex: 'canFillWithEntry',
    width: '100px',
    render: (canFillWithEntry: boolean) =>
      canFillWithEntry ? (
        <CheckCircleOutlined style={{ fontSize: '1.4rem', color: 'green' }} />
      ) : (
        <CloseCircleOutlined style={{ fontSize: '1.4rem', color: 'red' }} />
      ),
  },
];

const InsufficientStockModal = ({
  open,
  details,
  goToWMSTransfers,
  onEditOrderDetail,
  canEditOrderDetail,
  closeStockModal,
}: InsufficientStockModalProps) => {
  const canTransferUnits = useMemo(() => {
    return details.some((d) => d.canFillWithEntry);
  }, [details]);
  return (
    <Modal
      destroyOnClose
      onCancel={closeStockModal}
      onOk={closeStockModal}
      title={t('stockInfoModal.title')}
      open={open}
      centered
      width={1000}
      footer={[
        <Button
          key={1}
          type="link"
          disabled={!canTransferUnits}
          onClick={goToWMSTransfers}
        >
          {t('stockInfoModal.transfer')}
        </Button>,
        <Button key={2} onClick={closeStockModal} type="dashed">
          {t('stockInfoModal.close')}
        </Button>,
        <Button
          key={3}
          disabled={!canEditOrderDetail}
          type="primary"
          onClick={onEditOrderDetail}
        >
          {t('stockInfoModal.edit')}
        </Button>,
      ]}
      // confirmLoading={isLoadingMultiLegalize}
    >
      <Space direction="vertical">
        <Typography.Title type="danger" level={5}>
          <WarningOutlined
            style={{ fontSize: '1.4rem', color: 'red', marginRight: 10 }}
          />
          {t('stockInfoModal.head')}
        </Typography.Title>
        <Typography.Text italic>{t('stockInfoModal.info')}</Typography.Text>
        <Table pagination={false} dataSource={details} columns={columns} />
        <Typography.Text>{t('stockInfoModal.steps')}</Typography.Text>
      </Space>
    </Modal>
  );
};

export default InsufficientStockModal;
