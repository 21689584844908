import { VariantFormValues } from 'components/VariantForm/interface';
import config from 'config';
import { VariantContent } from 'containers/Products/VariantList/interface';
import {
  BillingType,
  Product,
  Variant,
  VariantStatus,
} from 'services/product/interface';
import { uploadFilesToImages } from './ImagesToUploadFile';
import { getEffectiveDiscount } from './tagValidators';

const DEFAULT_PANDAS_MPS = config.defaultPandasMps;

export const buildDetailsTableFormValue = (
  value: Record<string, string>[] | { key: string; value: string }[],
) => {
  let returnvalue: { key: string; value: string }[] = [];
  value?.forEach((va) => {
    if (va) {
      if (!returnvalue) {
        returnvalue = [];
      }
      const [k, v] = Object.entries(va)[0];

      returnvalue?.push({
        key: k,
        value: v,
      });
    }
  });
  return returnvalue;
};

const buildSubmitValues = (values: VariantFormValues) => {
  const newVariantName = values.name;
  const effectiveDiscount = getEffectiveDiscount(
    values.tags,
    values.price,
    values.discount,
  );

  return {
    effectiveDiscount,
    newVariantName,
  };
};

export const buildVariantComboParams = ({
  values,
  product,
  isCombo = false,
  targetVariant,
}: {
  values: VariantFormValues;
  product: Product | undefined;
  isCombo?: boolean;
  targetVariant?: VariantContent | Record<string, unknown>;
}) => {
  const { newVariantName, effectiveDiscount } = buildSubmitValues(values);
  let stock = null;

  if (!isCombo) {
    stock = {
      availableInWarehouse: values.stock?.availableInWarehouse ?? 0,
      notAvailableInWarehouse: values.stock?.notAvailableInWarehouse ?? 0,
      inWarehouse: values.stock?.inWarehouse ?? 0,
      cap: values.cap ?? 0,
    };
  }
  let descriptionTableValue;
  let descriptiontableFilter;
  if (values.descriptionTable) {
    try {
      const finalObj = values.descriptionTable?.map((e) => {
        const key = e?.key ?? undefined;
        const newvalue = e?.value ?? undefined;
        return {
          [key]: newvalue,
        };
      });
      descriptionTableValue = finalObj;
      descriptiontableFilter = descriptionTableValue.filter((item) => {
        const val = Object.values(item)[0];
        const key = Object.keys(item)[0];

        return val !== undefined && key !== 'undefined';
      });
    } catch (e) {
      console.error(e);
    }
  }

  let comboVal = null;
  if (isCombo && values.combo) {
    comboVal = values?.combo?.map((cv: Variant) => ({
      ...cv,
      comboQuantity: cv.cartQuantity,
    }));
  }
  let isTaxExcluded = values.isTaxExcluded ?? false;
  if (values.vatPercentage === 0) {
    isTaxExcluded = true;
  }
  let vatPercentage = values.vatPercentage ?? '';
  if (values.isTaxExcluded) {
    vatPercentage = 0;
  }

  return {
    ...(targetVariant ?? {}),
    name: newVariantName,
    brand: values?.brand ?? product?.brand,
    variantSku: values.variantSku,
    level: values.level ?? '',
    combo: comboVal,
    isCombo: isCombo ?? false,
    volumePrice: values.volumePrice ?? null,
    rack: values.rack ?? '',
    position: values.position ?? '',
    hall: values.hall ?? '',
    tags: values.tags ?? [],
    designTags: values.designTags ?? '',
    descriptionTable: descriptiontableFilter ?? undefined,
    keywords: values.keywords ?? [],
    stock,
    stockCost: Number(values.stockCost ?? values.cost ?? 0),
    partNumber: values.partNumber ?? '',
    mps: values.mps ?? DEFAULT_PANDAS_MPS,
    price: Number(values.price ?? 0),
    discount: Number(effectiveDiscount),
    status: values.status ? VariantStatus.ACTIVE : VariantStatus.INACTIVE,
    cost: Number(values.cost ?? 0),
    ranking: Number(values.ranking ?? 0),
    parent: {
      _id: product?.id ?? '',
    },
    quantity: Number(values.cartQuantity ?? 0),
    attributes: values.attributes,
    images: uploadFilesToImages(values.images ?? []),
    description: values.description,
    video: values?.video?.trim() ?? '',
    cap: values.cap ? Number(values.cap) : undefined,
    isTaxExcluded,
    vatPercentage,
    inventoryChangeReason: values.inventoryChangeReason,
    dimensions: {
      width: Number(values.dimensions?.width) || undefined,
      height: Number(values.dimensions?.height) || undefined,
      length: Number(values.dimensions?.length) || undefined,
      weight: Number(values.dimensions?.weight) || undefined,
    },
    commission:
      values?.mps?.billingType === BillingType.PRODUCT ? 0 : values.commission,
  };
};
