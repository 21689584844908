import { Method } from 'axios';

export const endpoints = {
  getWalletTransactions: {
    method: 'GET' as Method,
    url: 'finance-dom/wallet/withdraws',
    baseURL: process.env.REACT_APP_API_WALLET_URL,
  },
  updateWalletTransactions: {
    method: 'PUT' as Method,
    url: 'finance-dom/wallet',
    baseURL: process.env.REACT_APP_API_WALLET_URL,
  },
  createWalletTransactions: {
    method: 'POST' as Method,
    url: 'finance-dom/wallet/transaction',
    baseURL: process.env.REACT_APP_API_WALLET_URL,
  },
  updateInvoiceReports: {
    method: 'POST' as Method,
    url: 'invoice-dom/reports',
    baseUrl: process.env.REACT_APP_API_WALLET_URL,
  },
};
