import { Col, DatePicker, Row } from 'antd';
import { t } from 'i18next';
import 'moment/locale/en-ca';
import { FormItem } from './CouponModal.sty';
import { validateEndAfterStart } from './CouponValidations.schema';
import { CouponDurationSubFormProps, DEFAULT_DATE_FORMAT } from './interface';

const CouponDurationSubForm = ({
  readOnly,
  getFieldValue,
}: CouponDurationSubFormProps) => (
  <Row gutter={[16, 8]}>
    <Col xs={24} md={12}>
      <FormItem
        label={t('couponModal.form.labels.startDate')}
        name="startDate"
        rules={[
          {
            required: true,
            message: t('couponModal.form.errorMessages.startDate.required'),
          },
        ]}
      >
        {readOnly ? (
          getFieldValue?.('startDate')?.format(DEFAULT_DATE_FORMAT)
        ) : (
          <DatePicker
            placeholder={t('couponModal.form.placeholders.startDate')}
            showTime
            format={DEFAULT_DATE_FORMAT}
          />
        )}
      </FormItem>
    </Col>
    <Col xs={24} md={12}>
      <FormItem
        label={t('couponModal.form.labels.endDate')}
        name="endDate"
        rules={[
          {
            required: true,
            message: t('couponModal.form.errorMessages.endDate.required'),
          },
          validateEndAfterStart,
        ]}
      >
        {readOnly ? (
          getFieldValue?.('endDate')?.format(DEFAULT_DATE_FORMAT)
        ) : (
          <DatePicker
            showTime
            format={DEFAULT_DATE_FORMAT}
            placeholder={t('couponModal.form.placeholders.endDate')}
          />
        )}
      </FormItem>
    </Col>
  </Row>
);

export default CouponDurationSubForm;
