/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
} from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { BrandImage } from 'components/AutoCompleteBrandItem/AutoCompleteBrandItem.sty';
import KeyValueInput from 'components/KeyValueInput/KeyValueInput';
import ObjectSelect from 'components/ObjectSelect/ObjectSelect';
import UploadItem from 'components/Upload/UploadItem';
import {
  ButtonBlock,
  RowFlexCenter,
} from 'components/VariantVolDiscount/VariantVolDicount.sty';
import VariantVolDiscount from 'components/VariantVolDiscount/VariantVolDiscount';
import MpsAutocomplete from 'containers/MPS/MpsAutocomplete/MpsAutocomplete';
import { ONDEMAND, SALE } from 'helpers/constants/Tags';
import {
  DEFAULT_MPS,
  DEFAULT_VAT_PERCENTAGE,
  youtubeVideoRegexp,
} from 'helpers/constants/variantForm';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { buildDetailsTableFormValue } from 'helpers/utils/buildVariantComboParams';
import { numberToCurrency } from 'helpers/utils/currency';
import { numberValidator, stringValidator } from 'helpers/utils/formValidators';
import { expectFromTagArray } from 'helpers/utils/tagValidators';
import { validateVolumePrice } from 'helpers/utils/validateDiscountPrice';
import isEmpty from 'lodash/isEmpty';
import { Title, UpdateTitle } from 'pages/App/Layout.sty';
import { WrappedFormItem } from 'pages/Products/UpdateProduct/UpdateProduct.sty';
import { FieldData } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MPS } from 'services/MPS/interface';
import {
  BillingType,
  CartVariant,
  DiscountItem,
  Variant,
  VARIANT_RANKINGS,
} from 'services/product/interface';
import { variantTypeParser } from 'services/product/product.service';
import DefaultLogo from '../../assets/images/logo.svg';
import { useAddProduct } from './hooks/useAddProduct';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import useVariantForm from './hooks/useVariantForm';
import {
  defaultVariantFormValues,
  TagParams,
  VariantFormProps,
  VariantFormValues,
} from './interface';
import { Products } from './Products';
import {
  BoldBrandParagraph,
  BrandItem,
  NumberInput,
  ProductsWrapper,
  VariantCostWrapper,
  WrappedLabel,
} from './VariantForm.sty';

const { Panel } = Collapse;

const DEFAULT_VOL_DISCOUNT = [
  {
    min: 1,
    max: NaN,
    price: NaN,
  },
  {
    min: NaN,
    max: -1,
    price: NaN,
  },
];

export const VariantForm = ({
  title = 'Agregar Variante',
  initialValues = defaultVariantFormValues,
  onSubmitVariant,
  isUpdate,
  formId,
  labelCol,
  wrapperCol,
  submitButton,
  reset,
  comboLoading,
  attributeParams,
  productName,
  showTitle,
  isVariantFormLoading = false,
  variant: actualVariant,
}: VariantFormProps) => {
  const {
    readQuery: { mutateAsync: getParameters },
  } = useModuleCRUD('parameters');
  const {
    form,
    setFieldsValue,
    fileListState: [fileList, setFileList],
    onAttributesChange,
  } = useVariantForm(initialValues, productName, attributeParams);

  const { t } = useTranslation();
  const [renderTags, setRenderTags] = useState<string[]>([]);
  const [_designTagsList, setDesignTagsList] = useState<string[]>();
  const [requiredTag, setRequiredTag] = useState<boolean>(
    initialValues.tags?.length ? initialValues.tags?.length > 0 : false,
  );
  const [disableMps] = useState<boolean>(false);
  const [selectedMps, setSelectedMps] = useState<MPS | undefined>(
    initialValues.mps,
  );
  const [isCombo, setIsCombo] = useState<boolean>(
    initialValues?.isCombo ?? false,
  );
  const [descriptionTable, setDescriptionTable] =
    useState<{ key: string; value: string }[]>();
  const [detailsTable, setDetailsTable] =
    useState<{ key: string; value: string }[]>();

  const [volDiscount, setvolDiscount] = useState<DiscountItem[]>(
    initialValues?.volumePrice?.length
      ? initialValues?.volumePrice
      : DEFAULT_VOL_DISCOUNT,
  );
  const [hasVolDiscount, setHasVolDiscount] = useState<boolean>(
    !!initialValues?.volumePrice?.length,
  );
  const [addMore, setAddMore] = useState<boolean>(
    initialValues.volumePrice?.length === 3,
  );
  const [disable, setDisable] = useState<boolean>(false);
  const [comboVariantList, setComboVariantList] = useState<Variant[]>([]);
  const [searchVariantList, setSearchVariantList] = useState<Variant[]>([]);
  const [displayPrice, setDisplayPrice] = useState<number>(
    initialValues.price ?? 0,
  );

  const [isTaxExcluded, setIsTaxExcluded] = useState<boolean>(
    initialValues?.isTaxExcluded ?? false,
  );
  const [vatPercentages, setVatPercentages] = useState<string[]>([]);
  const { notifyWarning } = useNotifications({
    translationFunction: t,
    customMessage: { warning: { message: t('variantForm.warnings.header') } },
  });
  const mpsSearchKey = useMemo(() => {
    if (disableMps) {
      return DEFAULT_MPS;
    }
    return selectedMps?._id ?? DEFAULT_MPS;
  }, [disableMps, selectedMps]);

  const { variantsAdded, onChangeQuantity, setVariantsAdded } = useAddProduct();
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const {
    isLoadingVariantList,
    variantList,
    handleChangeSearchWithDebounce,
    fetchNextPage,
    fetchFirstPage,
  } = useInfiniteScroll({
    tableRef,
    customParams: {
      inStock: true,
      mpsId: mpsSearchKey,
    },
    fetchOnInvoke: isCombo,
  });

  const submitWrapper = (values: VariantFormValues) => {
    const data: VariantFormValues = {
      ...values,
      descriptionTable: detailsTable,
    };

    data.brand = initialValues.brand;
    data.descriptionTable = detailsTable ?? initialValues.descriptionTable;
    data.volumePrice = values.hasVolDiscount ? volDiscount : [];
    if (data.volumePrice.length && !validateVolumePrice(data.volumePrice)) {
      return notification.error({
        message: 'Accion no ejecutada',
        description: t('variantForm.notification'),
      });
    }
    onSubmitVariant?.(variantTypeParser(data));
  };

  // Taxes - VAT
  const getTaxPercentages = useCallback(async () => {
    const { taxPercentages } = await getParameters({ keys: 'taxPercentages' });
    const nTaxPercentages: string[] = taxPercentages?.data?.map(
      (percentage: number) => percentage,
    );
    setVatPercentages(nTaxPercentages);
  }, [setVatPercentages, getParameters]);

  useEffect(() => {
    (async () => {
      await getTaxPercentages();
    })();
  }, [getTaxPercentages]);

  useEffect(() => {
    setFieldsValue({ isTaxExcluded });
  }, [isTaxExcluded]);

  useEffect(() => {
    if (initialValues.mps) {
      setSelectedMps(initialValues.mps);
      form.setFieldsValue({ mps: selectedMps });
    }
    if (initialValues.descriptionTable && !descriptionTable) {
      setDescriptionTable(
        buildDetailsTableFormValue(initialValues.descriptionTable),
      );
    }
    setIsCombo(initialValues?.isCombo ?? false);
  }, [initialValues]);

  useEffect(() => {
    if (!initialValues.combo || initialValues.combo.length < 1) {
      setVariantsAdded({});
    } else {
      const initialCartVariantsMap: { [x: string]: CartVariant } = {};
      initialValues.combo.forEach((v: Variant) => {
        initialCartVariantsMap[v.id] = {
          ...v,
          cartQuantity: 0,
        };
      });
      setVariantsAdded(initialCartVariantsMap);
    }
  }, [initialValues, setVariantsAdded]);

  useEffect(() => {
    const discounts = [...volDiscount];

    if (volDiscount?.length && volDiscount.length < 3 && addMore) {
      discounts[1].max = discounts[1].min + 1;
      const discountItem: DiscountItem = {
        price: 0,
        max: -1,
        min: discounts[1].max + 1,
      };
      setvolDiscount([...discounts, discountItem]);
    }

    if (volDiscount.length === 3 && !addMore) {
      discounts.splice(1, 2, { ...discounts[1], max: -1 });
      setvolDiscount(discounts);
    }
  }, [addMore]);

  useEffect(() => {
    setFieldsValue({ combo: Object.values(variantsAdded) });
  }, [variantsAdded]);

  useEffect(() => {
    if (
      initialValues?.descriptionTable &&
      !isEmpty(initialValues?.descriptionTable)
    ) {
      setDetailsTable(
        buildDetailsTableFormValue(initialValues.descriptionTable),
      );
    }
  }, [initialValues.descriptionTable, setDetailsTable]);

  useEffect(() => {
    if (comboLoading === false) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, comboLoading]);

  useEffect(() => {
    if ((form.getFieldValue('tags')?.length ?? 0) < 1) {
      form.setFieldsValue({ designTags: null });
    }
  }, [_designTagsList, form.getFieldValue]);

  const handleStockChange = useCallback(
    (newValues: FieldData[]) => {
      const nameArrays = newValues.map((changedVal: FieldData) =>
        Array<string>(String(changedVal?.name)),
      );
      if (nameArrays.find((x) => x.includes('price'))) {
        setDisplayPrice(form.getFieldValue('price'));
      }
    },
    [form],
  );

  const handleAddMore = useCallback((value: boolean) => {
    setAddMore(value);
  }, []);

  const handleCheckBox = useCallback(
    ({ target }: { target: CheckboxChangeEventTarget }) => {
      if (target.id === 'create-product_isCombo') {
        setIsCombo(target.checked);
      }
      if (target.id === 'create-product_hasVolDiscount') {
        setHasVolDiscount(target.checked);
      }

      if (target.id === 'create-product_isTaxExcluded') {
        if (target.checked) {
          form.setFieldsValue({ vatPercentage: 0 });
        } else {
          form.setFieldsValue({ vatPercentage: DEFAULT_VAT_PERCENTAGE });
        }
        setIsTaxExcluded(target.checked);
      }
    },
    [],
  );

  const handleDisable = useCallback(
    (tags: string[]) => {
      setDisable(expectFromTagArray(tags, SALE));
      setDesignTagsList(tags.filter((filter) => filter !== ONDEMAND));
      if (form.getFieldValue('tags')?.length < 1) {
        form.setFieldsValue({ designTags: null });
      }

      if (
        form.getFieldValue('designTags')?.length < 1 &&
        form.getFieldValue('tags')?.length > 0
      ) {
        form.setFieldsValue({
          designTags: tags[0] !== ONDEMAND ? tags[0] : null,
        });
      }

      if (
        !form.getFieldValue('tags').includes(form.getFieldValue('designTags'))
      ) {
        form.setFieldsValue({
          designTags: tags[0] !== ONDEMAND ? tags[0] : tags[1],
        });
      }

      if (
        form.getFieldValue('tags')?.length > 0 &&
        form.getFieldValue('tags').every((tag: string) => tag !== ONDEMAND)
      ) {
        setRequiredTag(true);
      } else {
        setRequiredTag(false);
      }
    },
    [form],
  );

  const handleDesignTags = useCallback(
    (tag: string) => {
      form.setFieldsValue({ designTags: tag });
    },
    [form],
  );

  const handleMpsChange = useCallback(
    (value: MPS) => {
      form.setFieldsValue({ mps: value });
      setSelectedMps(value);
    },
    [form],
  );

  const handleImageError = useCallback((e) => {
    const target = e.target as HTMLImageElement;
    target.src = DefaultLogo;
  }, []);

  const getTags = useCallback(async () => {
    const { tags } = await getParameters({ keys: 'tags' });
    const stringTags: string[] = tags?.data?.map((tag: TagParams) => tag.tag);
    setRenderTags(stringTags);
  }, [getParameters, setRenderTags]);

  const handleImageUpload = useCallback(
    (file) => {
      const fileListToSet = [...fileList, file];
      setFileList(fileListToSet);
      form.setFieldsValue({ images: fileListToSet });
    },
    [fileList, form, setFileList],
  );

  const handleImageRemove = useCallback(
    (file) => {
      const fileListToSet = fileList.filter(
        (fileInList) => fileInList.uid !== file.uid,
      );
      setFileList(fileListToSet);
      form.setFieldsValue({ images: fileListToSet });
    },
    [fileList, form, setFileList],
  );

  const handleButtonBlockClick = useCallback(
    () => handleAddMore(true),
    [handleAddMore],
  );

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    if ((isUpdate || initialValues.id) && isCombo) {
      setComboVariantList([]);
    }
    setSearchVariantList(variantList);
  }, [variantList, variantsAdded, disableMps, selectedMps]);

  useEffect(() => {
    setFieldsValue({ combo: Object.values(variantsAdded || []) });
  }, [setFieldsValue, variantsAdded]);

  useEffect(() => {
    if (isCombo) {
      setFieldsValue({ availableQuantity: null });
      setFieldsValue({ totalQuantity: null });
    }
    if (hasVolDiscount) setFieldsValue({ discount: null });
  }, [isCombo, setFieldsValue, hasVolDiscount]);

  useEffect(() => {
    if (initialValues?.tags) {
      const validator = initialValues?.tags?.filter((value) => value === SALE);
      if (validator.length > 0) {
        handleDisable(validator);
      }
    }
  }, [initialValues.tags]);

  useEffect(() => {
    if (
      initialValues?.volumePrice?.length &&
      initialValues?.volumePrice?.length > 1
    ) {
      setFieldsValue({ hasVolDiscount: true });
    }
  }, [initialValues.tags, initialValues?.volumePrice?.length, setFieldsValue]);

  useEffect(() => {
    if (!selectedMps || !isCombo || (isCombo && comboLoading)) return;
    let filtered: [string, CartVariant][] = [];
    if (!disableMps) {
      filtered = Object.entries(variantsAdded).filter(([vid, variant]) => {
        if ((variant?.mps?._id ?? '') !== selectedMps?._id) {
          return null;
        }
        return [vid, variant];
      });
    }
    if (filtered.length === 0) {
      notifyWarning(t('variantForm.warnings.mpsComboChange'));
    }
    setVariantsAdded(Object.fromEntries(filtered));
  }, [selectedMps, disableMps]);

  useEffect(() => {
    if (isCombo) {
      if (variantList.length < 1) {
        fetchFirstPage('');
      }
    }
  }, [isCombo]);

  // This doesn't uses ref because it's an internal ant module
  const scrollableTable = document.querySelector('.ant-modal-content');
  if (scrollableTable) scrollableTable.id = 'scrollable-container';

  if (attributeParams.loadingAttributesList || isVariantFormLoading) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }

  return (
    <>
      {reset && (
        <>
          {isUpdate || showTitle ? (
            <UpdateTitle level={1}>{title}</UpdateTitle>
          ) : (
            <Title level={1}>{title}</Title>
          )}
          <Divider />
        </>
      )}

      <Form
        onFieldsChange={handleStockChange}
        id={formId}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        form={form}
        name="create-product"
        onFinish={submitWrapper}
        autoComplete="off"
        onValuesChange={onAttributesChange}
        scrollToFirstError
      >
        <Collapse defaultActiveKey={['1', '2', '3', '4', '6']}>
          <Panel key="1" header={t('variantForm.panels.summary')}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t('variantForm.labels.name')}
                  rules={[
                    {
                      required: true,
                      message: t('variantForm.requiredField'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {!isCombo &&
                  !attributeParams.loadingAttributesList &&
                  attributeParams.attributeOptions && (
                    <Form.List name="attributes">
                      {(fields) => (
                        <>
                          {fields.map((field) => (
                            <Form.Item
                              label={
                                <WrappedLabel>
                                  {t('variantForm.labels.attribute', {
                                    attributeName:
                                      attributeParams.attributeTypeNames
                                        .length > 0
                                        ? attributeParams.attributeTypeNames[
                                            field.key
                                          ]
                                        : 'color',
                                    attributeLevel: field.key + 1,
                                  })}
                                </WrappedLabel>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: t('variantForm.requiredField'),
                                },
                              ]}
                              {...field}
                              key={field.key}
                            >
                              <ObjectSelect
                                options={
                                  (attributeParams.attributeOptions || {})[
                                    attributeParams.attributeTypeNames.length >
                                    0
                                      ? attributeParams.attributeTypeNames[
                                          field.key
                                        ]
                                      : 'noLevel'
                                  ]
                                }
                              />
                            </Form.Item>
                          ))}
                        </>
                      )}
                    </Form.List>
                  )}
                <Form.Item
                  name="description"
                  label={t('variantForm.labels.description')}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item
                  name="keywords"
                  label={t('variantForm.labels.keywords')}
                >
                  <Select
                    mode="tags"
                    placeholder={t('variantForm.placeholders.keywords')}
                  />
                </Form.Item>
                <WrappedFormItem
                  tooltip={t('variantForm.tooltips.video')}
                  name="video"
                  label={t('variantForm.labels.video')}
                  rules={[
                    {
                      validator: (_, value) =>
                        stringValidator(
                          value,
                          {
                            required: false,
                            regexp: youtubeVideoRegexp,
                          },
                          t,
                          { badRegex: 'BOVARS001' },
                        ),
                    },
                  ]}
                >
                  <Input placeholder={t('variantForm.placeholders.video')} />
                </WrappedFormItem>
                <Form.Item
                  name="ranking"
                  label={t('variantForm.labels.ranking')}
                  rules={[
                    {
                      required: true,
                      message: t('variantForm.requiredField'),
                    },
                  ]}
                >
                  <Select>
                    {VARIANT_RANKINGS.map((value: number) => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('variantForm.labels.brand')}>
                  <BrandItem>
                    <BrandImage
                      alt={`${
                        initialValues?.brand?.logo?.name ?? 'no-brand'
                      } logo`}
                      src={initialValues?.brand?.logo?.url ?? DefaultLogo}
                      onError={handleImageError}
                    />
                    <BoldBrandParagraph>
                      {initialValues?.brand?.name ?? t('brand.noBrand.title')}
                    </BoldBrandParagraph>
                  </BrandItem>
                </Form.Item>

                <Form.Item
                  name="isTaxExcluded"
                  label={t('variantForm.labels.taxExcluded')}
                  valuePropName="checked"
                >
                  <Checkbox
                    defaultChecked={isTaxExcluded}
                    onChange={handleCheckBox}
                  />
                </Form.Item>
                <Form.Item
                  name="vatPercentage"
                  label={t('variantForm.labels.vat')}
                  rules={[
                    {
                      required: true,
                      message: t('variantForm.requiredField'),
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      isTaxExcluded
                        ? '0%'
                        : `${
                            initialValues.vatPercentage ??
                            DEFAULT_VAT_PERCENTAGE
                          }%`
                    }
                    disabled={isTaxExcluded}
                  >
                    {vatPercentages.map((value: string) => (
                      <Select.Option key={value} value={value}>
                        {isTaxExcluded ? '0%' : `${value}%`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['dimensions', 'height']}
                  label={t('variantForm.labels.height')}
                  rules={[
                    {
                      type: 'number',
                      validator: (_, value) =>
                        numberValidator(value, { min: 0, required: false }, t),
                    },
                  ]}
                >
                  <NumberInput type="number" />
                </Form.Item>
                <Form.Item
                  name={['dimensions', 'length']}
                  label={t('variantForm.labels.length')}
                  rules={[
                    {
                      type: 'number',
                      validator: (_, value) =>
                        numberValidator(value, { min: 0, required: false }, t),
                    },
                  ]}
                >
                  <NumberInput type="number" />
                </Form.Item>
              </Col>
              {/* RIGHT */}
              <Col span={12}>
                <Form.Item required label={t('variantForm.labels.sku')}>
                  <Row gutter={16}>
                    <Col span={16}>
                      <Form.Item>{initialValues.variantSku || ''}</Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="status"
                        required
                        label={t('variantForm.labels.active')}
                        valuePropName="checked"
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="tags" label={t('variantForm.labels.tags')}>
                  <Select
                    mode="multiple"
                    onChange={handleDisable}
                    placeholder={t('variantForm.placeholders.tags')}
                  >
                    {renderTags?.map((tag) => (
                      <Select.Option key={tag} value={tag}>
                        {tag}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="designTags"
                  label={t('variantForm.labels.designTags')}
                  rules={[
                    {
                      required: requiredTag,
                      message: t('variantForm.requiredField'),
                    },
                  ]}
                >
                  <Select
                    onChange={handleDesignTags}
                    placeholder={t('variantForm.placeholders.designTags')}
                    defaultValue={form.getFieldValue('designTags') || null}
                  >
                    {form
                      .getFieldValue('tags')
                      ?.filter((tag: string) => tag !== ONDEMAND)
                      .map((tag: string) => (
                        <Select.Option key={`design-${tag}`} value={tag}>
                          {tag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="cost"
                  label={t('variantForm.labels.cost')}
                  tooltip={t('variantForm.tooltips.avgCost')}
                >
                  <VariantCostWrapper>
                    {numberToCurrency(form.getFieldValue('cost') ?? 0)}
                  </VariantCostWrapper>
                </Form.Item>
                <Form.Item
                  name="images"
                  label={t('variantForm.labels.images')}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <UploadItem
                    folder="products"
                    fileList={fileList}
                    maxLength={10}
                    onUpload={handleImageUpload}
                    onRemove={handleImageRemove}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Row gutter={16}>
                    <Col span={21}>
                      <Form.Item name="mps" label={t('variantForm.labels.mps')}>
                        <MpsAutocomplete
                          disabled={disableMps}
                          onChange={handleMpsChange}
                          value={selectedMps}
                          style={{ width: '100%', minWidth: '300px' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item noStyle>
                  <Row gutter={16}>
                    <Col span={21}>
                      <Form.Item
                        name="partNumber"
                        label={t('variantForm.labels.partNumber')}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name={['dimensions', 'width']}
                  label={t('variantForm.labels.width')}
                  rules={[
                    {
                      type: 'number',
                      validator: (_, value) =>
                        numberValidator(value, { min: 0, required: false }, t),
                    },
                  ]}
                >
                  <NumberInput type="number" />
                </Form.Item>
                <Form.Item
                  name={['dimensions', 'weight']}
                  label={t('variantForm.labels.weight')}
                  rules={[
                    {
                      type: 'number',
                      validator: (_, value) =>
                        numberValidator(value, { min: 0, required: false }, t),
                    },
                  ]}
                >
                  <NumberInput type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          {isCombo && !comboLoading && (
            <Panel key="4" header={t('variantForm.panels.combo')}>
              {isCombo && !comboLoading ? (
                <Form.Item name="combo" wrapperCol={{ span: 24 }}>
                  <ProductsWrapper>
                    <Products
                      showTitle={false}
                      tableRef={tableRef}
                      onSearch={handleChangeSearchWithDebounce}
                      list={
                        initialValues?.combo?.length
                          ? comboVariantList
                          : searchVariantList
                      }
                      id="pick-variant-combo"
                      reachedEndOfData={false}
                      onScrollToEnd={fetchNextPage}
                      onChangeQuantity={onChangeQuantity}
                      isLoading={isLoadingVariantList}
                    />
                  </ProductsWrapper>
                </Form.Item>
              ) : isCombo && comboLoading ? (
                <Skeleton active />
              ) : null}
            </Panel>
          )}
          <Panel key="2" header={t('variantForm.panels.price')}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="price"
                  label={t('variantForm.labels.price')}
                  rules={[
                    {
                      type: 'number',
                      validator: (_, value) =>
                        numberValidator(value, { min: 1, required: true }, t),
                    },
                  ]}
                >
                  <NumberInput type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="hasVolDiscount"
                  label={t('variantForm.labels.volumePrice')}
                  valuePropName="checked"
                  tooltip={t('variantForm.tooltip')}
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 15 }}
                >
                  <Checkbox disabled={!disable} onChange={handleCheckBox} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <WrappedFormItem
                  name="discount"
                  label={t('variantForm.labels.otherDiscount')}
                  tooltip={t('variantForm.labels.number')}
                  dependencies={['price']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || Number(getFieldValue('price')) >= value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t('variantForm.labels.numberError')),
                        );
                      },
                    }),
                  ]}
                >
                  <NumberInput
                    type="number"
                    min={0}
                    disabled={!disable || hasVolDiscount}
                    max={initialValues ? initialValues.price : ''}
                  />
                </WrappedFormItem>
                {actualVariant?.mps?.billingType === BillingType.SERVICE && (
                  <Form.Item
                    name="commission"
                    label={t('variantForm.labels.commission')}
                    tooltip={t('variantForm.commission')}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 8 }}
                  >
                    <InputNumber
                      min="0"
                      style={{ width: '100%' }}
                      size="large"
                      placeholder="Commission"
                    />
                  </Form.Item>
                )}
              </Col>
              {hasVolDiscount ? (
                <Col span={24}>
                  <Form.Item
                    name="volumePrice"
                    label={t('variantForm.labels.volumePriceVal')}
                  >
                    <VariantVolDiscount
                      volumePrice={volDiscount}
                      setvolDiscount={setvolDiscount}
                      addMore={addMore}
                      handleAddMore={handleAddMore}
                      variantPrice={displayPrice}
                    />
                    <RowFlexCenter gutter={[12, 7]}>
                      <ButtonBlock
                        block
                        onClick={handleButtonBlockClick}
                        disabled={volDiscount.length > 2}
                      >
                        {t('volumeDiscount.button')}
                      </ButtonBlock>
                    </RowFlexCenter>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Panel>
          <Panel key="6" header={t('variantForm.panels.detailsTable')}>
            <Row gutter={16}>
              <Col span={24}>
                {!comboLoading ? (
                  <Form.Item name="descriptionTable">
                    <KeyValueInput
                      value={descriptionTable}
                      detailsTable={detailsTable}
                      setDetailsTable={setDetailsTable}
                      setFieldsValue={setFieldsValue}
                    />
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
          </Panel>
        </Collapse>
        {submitButton}
      </Form>
    </>
  );
};

VariantForm.defaultProps = {
  title: 'Agregar Variante',
  isUpdate: true,
  labelCol: { span: 6 },
  wrapperCol: {
    xl: { span: 10 },
    lg: { span: 12 },
    md: { span: 15 },
  },
  reset: true,
};
