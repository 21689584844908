import { Method } from 'axios';

export const endpoints = {
  getAttributeList: {
    method: 'GET' as Method,
    url: '/product-dom/attribute',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createAttribute: {
    method: 'POST' as Method,
    url: '/product-dom/attribute',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteAttributeById: {
    method: 'DELETE' as Method,
    url: '/product-dom/attribute/{params.attributeId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
