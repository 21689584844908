import { Button, Col, Divider, Form, Row } from 'antd';
import { TransportFormProps } from 'containers/Transport/interface';
import { stringValidator } from 'helpers/utils/formValidators';
import {
  InputBig,
  StyledForm,
  SubmitButtonWrapper,
} from 'modules/ShippingZones/components/ShippingZonesUpdate/ShippingZonesUpdate.sty';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TransportForm = ({
  selectedTransport,
  submitForm,
}: TransportFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const LABEL_COL = {
    xl: { span: 12 },
    lg: { span: 10 },
    sm: { span: 6 },
  };
  const WRAPPER_COL = {
    xl: { span: 10, offset: 0 },
    lg: { span: 12, offset: 2 },
    sm: { span: 100, offset: 2 },
  };
  return (
    <StyledForm
      scrollToFirstError
      onFinish={(values: any) => submitForm(values)}
      initialValues={selectedTransport}
      form={form}
    >
      <>
        <Divider />
        <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
          <Col span={10}>
            <Form.Item
              required
              name="name"
              label={t('transport.name')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, values) =>
                    stringValidator(
                      values,
                      { minLength: 1, required: true },
                      t,
                    ),
                },
              ]}
            >
              <InputBig type="text" />
            </Form.Item>
            <Form.Item
              required
              name="convertionFactor"
              label={t('transport.convertionFactor')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
            >
              <InputBig type="number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required
              name="additionalPackageCost"
              label={t('transport.additionalPackageCost')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
            >
              <InputBig type="number" />
            </Form.Item>
            <Form.Item
              required
              name="minimumVolumeWeight"
              labelAlign="left"
              label={t('transport.minimumVolumeWeight')}
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
            >
              <InputBig type="number" />
            </Form.Item>
          </Col>
        </Row>
        <SubmitButtonWrapper>
          <Button htmlType="submit">{t('transport.submit')}</Button>
        </SubmitButtonWrapper>
      </>
    </StyledForm>
  );
};
