import { ArrowRightOutlined } from '@ant-design/icons';
import { Avatar, Badge, Row, Tag } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ITransferUnits, TransferUnitsDTO } from 'services/transfers/interface';
import { TransferThumbnailWrapper } from './TransferCard.sty';
import {
  ErrorIcon,
  ResultCardWrapper,
  ResultLabel,
  ResultWrapper,
  SuccessIcon,
  TransferCardInfoWrapper,
} from './TransferResultCard.sty';

const TransferResultCard = ({
  item,
}: {
  item: ITransferUnits & TransferUnitsDTO;
}) => {
  const { t } = useTranslation();

  const baseUrl =
    item?.variant?.images?.length > 0
      ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${item?.variant?.images[0].url}`
      : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;

  return (
    <ResultCardWrapper>
      <Row>
        <TransferThumbnailWrapper span={4}>
          <Badge count={item.quantity}>
            <Avatar src={baseUrl} />
          </Badge>
        </TransferThumbnailWrapper>
        <TransferCardInfoWrapper span={20}>
          <Tag color="blue"> {item.initialLocation?.name} </Tag>
          <ArrowRightOutlined />
          <Tag color="blue"> {item.finalLocation?.name} </Tag>
          <ResultWrapper>
            {isEmpty(item?.error ?? '') ? (
              <>
                <SuccessIcon />
                <ResultLabel>{t('transfers.results.success')}</ResultLabel>
              </>
            ) : (
              <>
                <ErrorIcon />
                <ResultLabel>{t('transfers.results.error')}</ResultLabel>
              </>
            )}
          </ResultWrapper>
        </TransferCardInfoWrapper>
      </Row>
    </ResultCardWrapper>
  );
};

export default TransferResultCard;
