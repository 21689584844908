import { Method } from 'axios';

export const endpoints = {
  getMpsList: {
    method: 'GET' as Method,
    url: 'mps-user-dom/mps-user',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getMpsByType: {
    method: 'GET' as Method,
    url: 'mps-user-dom/mps-user/type',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createMps: {
    method: 'POST' as Method,
    url: 'mps-user-dom/mps-user',
    baseURL: process.env.REACT_APP_API_MPS_URL,
  },
  updateMps: {
    method: 'PUT' as Method,
    url: 'mps-user-dom/mps-user',
    baseURL: process.env.REACT_APP_API_MPS_URL,
  },
};
