import { InboxOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { InputContainer, UploadDragger } from './CsvModule.sty';
import { CsvPresentationProps } from './interface';

const CsvPresentation = ({
  hint,
  inputText,
  uploadProps,
  formatDownloadProps,
}: CsvPresentationProps) => {
  return (
    <>
      {!!formatDownloadProps && (
        <Button
          type="primary"
          loading={formatDownloadProps.isLoading}
          disabled={formatDownloadProps.isLoading}
          onClick={formatDownloadProps.handleDownloadFormat}
        >
          {formatDownloadProps?.downloadFormatText}
        </Button>
      )}
      <br />
      <InputContainer>
        <UploadDragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{inputText}</p>
          <p className="ant-upload-hint">{hint}</p>
        </UploadDragger>
      </InputContainer>
    </>
  );
};

export default CsvPresentation;
