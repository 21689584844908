/* eslint-disable no-underscore-dangle */

import { Spin, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { parsePandasErr } from 'config/Errors/error';
import useNotifications from 'helpers/hooks/useNotifications';
import PackageCard from 'modules/Order/components/Packages/PackageCard';
import { useOrderPackage } from 'modules/Order/context/OrderPackage.context';
import useOrderPackageService from 'modules/Order/hooks/useOrderPackageService';
import { IPackageForm } from 'modules/Order/interface';
import { ORDER_PACKAGE_ACTION_ENUM } from 'modules/Order/reducer/interface/order-package';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from 'services/order/interface';

const OrderPackageContainer = ({
  orderId,
  order,
}: {
  orderId: string;
  order: Order;
}) => {
  const { state, dispatch } = useOrderPackage();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });
  const {
    packages,
    packagesFetchLoading,
    fetchPackageDeliveryDates,
    updatePackage,
    updateLoading,
  } = useOrderPackageService({
    orderId,
    order,
  });

  const onUpdatePackage = useCallback(
    async (pkg: IPackageForm) => {
      try {
        await updatePackage(pkg);
        notifySuccess();
        setTimeout(() => {
          window?.location?.reload();
        }, 2000);
      } catch (error) {
        const err = parsePandasErr(error, 'ORDU500000');
        notifyError(t(err.code));
      }
    },
    [updatePackage, notifySuccess, notifyError, t],
  );

  const onFetchOptions = useCallback(
    async (id: string) => {
      const options = await fetchPackageDeliveryDates(id);

      dispatch({
        payload: { packageId: id, options: options?.[0]?.dates ?? [] },
        type: ORDER_PACKAGE_ACTION_ENUM.SET_PACKAGE_DELIVERY_OPTIONS,
      });
    },
    [dispatch, fetchPackageDeliveryDates],
  );

  return (
    <Tabs defaultActiveKey={packages?.[0]?.packageNumber}>
      {packagesFetchLoading && <Spin />}
      {packages?.map((pkg) => (
        <TabPane
          tab={pkg.packageNumber}
          key={pkg.packageNumber}
          id={pkg.packageNumber}
        >
          <PackageCard
            updateLoading={updateLoading}
            options={{
              dates: state.deliveryOptions[pkg._id],
            }}
            fetchOptions={onFetchOptions}
            onUpdatePackage={onUpdatePackage}
            pkg={pkg}
            key={pkg._id}
          />
        </TabPane>
      ))}
    </Tabs>
  );
};

export default OrderPackageContainer;
