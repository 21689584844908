import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { Role } from 'helpers/hooks/useRoleAccess/interface';
import { Store } from 'services/store/interface';

export type DocType = 'CC' | 'CE' | 'TE' | 'NIT' | 'PS' | '';

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER',
  MERCHANT = 'MERCHANT',
  WAREHOUSE_ASSISTANT = 'WAREHOUSE_ASSISTANT',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  ORDER_CREATOR = 'ORDER_CREATOR',
  ADMINISTRATIVE_ASSISTANT = 'ADMINISTRATIVE_ASSISTANT',
  SELLER = 'SELLER',
  SELLER_COORDINATOR = 'SELLER_COORDINATOR',
  FINANCE = 'FINANCE',
  GROWTH = 'GROWTH',
}

export type RoleTypes = keyof typeof UserRoleEnum;

export const DocTypesObj = {
  CC: 'docTypes.CC',
  CE: 'docTypes.CE',
  TE: 'docTypes.TE',
  NIT: 'docTypes.NIT',
  PS: 'docTypes.PS',
  '': '',
};

export interface Location {
  address?: string;
  details?: string;
  latitude?: string;
  longitude?: string;
  cityCode?: string;
  cityName?: string;
  departmentCode?: string;
  departmentName?: string;
  department?: string;
}

export interface Person {
  name?: string;
  lastName?: string;
  secondLastName?: string;
  email?: string;
  phoneNumber?: string;
  docType?: DocType;
  documentNumber?: string;
  locations?: Location[];
  photoURL?: string;
}

export interface TermAndConditions {
  url?: string;
  content?: string;
  version?: string;
  acceptedAt: string;
  updatedAt?: string;
  deletedAt?: string;
}

export enum UserStatusEnum {
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ONBOARDING_USER_INFO = 'ONBOARDING_USER_INFO',
  ONBOARDING_ADDRESS = 'ONBOARDING_ADDRESS',
  'ONBOARDING_T&V' = 'ONBOARDING_T&V',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type UserStatus = keyof typeof UserStatusEnum;

export const StatusTagColorCode: {
  [key: string]: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
} = {
  [UserStatusEnum.PENDING_ACTIVATION]: 'processing',
  [UserStatusEnum.ONBOARDING_USER_INFO]: 'geekblue',
  [UserStatusEnum.ONBOARDING_ADDRESS]: 'geekblue',
  [UserStatusEnum['ONBOARDING_T&V']]: 'geekblue',
  [UserStatusEnum.ACTIVE]: 'green',
  [UserStatusEnum.INACTIVE]: 'error',
};

export interface User {
  isPrime?: boolean;
  id?: string;
  userNumber?: string;
  mainPhone?: string;
  mainEmail?: string;
  role?: Role[];
  firebaseId?: string;
  info: Person;
  status?: UserStatus;
  termAndConditionsRef?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  sellerCode?: string;
  seller?: RawStaffUser;
  sellerCoordinator?: RawStaffUser;
  store?: Store;
  billingEmail?: string;
}

export class CreatedByUser {
  id?: string;

  role?: string[];

  email?: string;

  name?: string;
}

export interface RawStaffUser {
  id?: string;
  sellerCode?: string;
  info: Person;
}

export interface UserQueryParams {
  search?: string;
  roleFilter?: string[];
  pagination?: PaginationParams;
}
