import { Method } from 'axios';

export const endpoints = {
  getUnitVariantsByLocation: {
    method: 'GET' as Method,
    url: 'transfer-dom/transfer/list/{params.locationId}',
    baseURL: process.env.REACT_APP_API_TRANSFER_URL,
  },
  getTransferCsvTemplate: {
    method: 'GET' as Method,
    url: 'transfer-dom/transfer/csv',
    baseURL: process.env.REACT_APP_API_TRANSFER_URL,
  },
  getVariantsCsvStatus: {
    method: 'GET' as Method,
    url: 'transfer-dom/transfer/csv-status',
    baseURL: process.env.REACT_APP_API_TRANSFER_URL,
  },
  unitBatchTransfer: {
    method: 'POST' as Method,
    url: 'transfer-dom/transfer',
    baseURL: process.env.REACT_APP_API_TRANSFER_URL,
  },
};
