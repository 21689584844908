import { useMutation, useQuery } from '@tanstack/react-query';
import { Dispatch, useEffect } from 'react';
import { updateShippingZone } from 'services/cities/cities.service';
import { getShippingZoneById } from 'services/shipping/shipping-zones.service';
import {
  ShippingZoneUpdateAction,
  SHIPPING_ZONE_UPDATE_ACTION_ENUM,
} from '../reducer/interface';
import useFetchShippingZoneUtils from './useFetchShippingZoneUtils';

const useUpdateShippingZoneService = ({
  id,
  dispatch,
}: {
  id: string;
  dispatch: Dispatch<ShippingZoneUpdateAction>;
}) => {
  const {
    data: shippingZone,
    isLoading: isLoadingFetch,
    refetch: refetchShippingZone,
  } = useQuery({
    queryFn: () => getShippingZoneById(id),
    refetchOnWindowFocus: false,
    queryKey: ['shippingZone', id],
  });

  const { mutateAsync: updateAsync, isLoading: updateLoading } = useMutation({
    mutationFn: updateShippingZone,
  });

  const {
    transportList,
    citiesList,
    regionList,
    isLoading: isUtilsLoading,
    ...serviceUtilities
  } = useFetchShippingZoneUtils({ id });

  useEffect(() => {
    if (shippingZone) {
      dispatch({
        type: SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_SHIPPING_ZONE,
        payload: shippingZone,
      });
    }
  }, [shippingZone, dispatch]);

  useEffect(() => {
    if (transportList) {
      dispatch({
        type: SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_TRANSPORT_LIST,
        payload: transportList,
      });
    }
  }, [transportList, dispatch]);

  useEffect(() => {
    if (regionList) {
      dispatch({
        type: SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_REGION_LIST,
        payload: regionList,
      });
    }
  }, [regionList, dispatch]);

  useEffect(() => {
    if (citiesList) {
      dispatch({
        type: SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_CITIES_LIST,
        payload: citiesList,
      });
    }
  }, [citiesList, dispatch]);

  return {
    shippingZone,
    isLoading: isLoadingFetch || isUtilsLoading,
    updateShippingZone: updateAsync,
    updateLoading,
    refetchShippingZone,
    ...serviceUtilities,
  };
};

export default useUpdateShippingZoneService;
