/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import { Spin, Table } from 'antd';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import { t } from 'i18next';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useProductTable from './hooks/useProductTable';
import { ProductContent, ProductTableProps } from './interface';
import { InfiniteTableLoader } from './ProductTable.sty';

export const ProductTable = ({
  data,
  fetchNextPage,
  onGoToUpdate,
  hasNextPage,
}: ProductTableProps) => {
  const { columns } = useProductTable();
  const dataSource: ProductContent[] = useMemo(
    () =>
      data.map((product) => ({
        ...product,
        key: product.id,
      })),
    [data],
  );

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={dataSource.length}
        hasMore={hasNextPage ?? false}
        scrollableTarget="scrollableDiv"
        loader={
          <InfiniteTableLoader>
            <Spin tip={t('loading')} />
          </InfiniteTableLoader>
        }
      >
        <Table
          onRow={(record: ProductContent) => ({
            onClick: () => onGoToUpdate(record.urlName),
          })}
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          key={1}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};
