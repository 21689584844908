import SettingUpdateContainer from 'modules/WMS/Settings/containers/SettingUpdateContainer';
import { SettingUpdateProvider } from 'modules/WMS/Settings/context/SettingUpdate.context';

const UpdateSetting = () => {
  return (
    <SettingUpdateProvider>
      <SettingUpdateContainer />
    </SettingUpdateProvider>
  );
};

export default UpdateSetting;
