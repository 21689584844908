import { Alert } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

export const GridSortableContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
`;

export const HelpAlert = styled(Alert)`
  margin-bottom: 10px;
`;

export const DraggableBannerPicItem = styled.div<{
  isDragging: boolean;
  preSelected?: boolean;
}>`
  opacity: ${({ isDragging }) => (!isDragging ? '1' : '0.5')};
  transform-origin: 50% 50%;
  height: auto;
  width: 200px;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
  background-color: ${BaseColors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ isDragging }) => (isDragging ? 'scale(1.05)' : 'scale(1)')};
  box-shadow: 0px 0px 10px 1px #23090930;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & .ant-image {
    width: 100%;
    height: 100%;
  }

  & .ant-image-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
`;
