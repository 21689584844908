/* eslint-disable react/jsx-wrap-multilines */
import { Result } from 'antd';
import { Logo, PandasText } from 'pages/Login/Login.sty';
import { LayoutContainer } from 'pages/App/Layout.sty';
import LogoText from '../../assets/images/pandas_text.svg';
import LogoImage from '../../assets/images/logo.svg';

export const Home = () => (
  <div>
    <LayoutContainer>
      <Result
        title="Bienvenido a Pandas BackOffice"
        subTitle="Abastece tu negocio"
        icon={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Logo src={LogoImage} alt="logo" />
            <PandasText src={LogoText} alt="pandas logo text" />
          </div>
        }
      />
    </LayoutContainer>
  </div>
);
