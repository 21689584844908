import { Col, Row } from 'antd';
import { BoldItemParagraph } from 'components/AutoCompleteUserItem/AutoCompleteUserItem.sty';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { BrandImage } from './AutoCompleteBrandItem.sty';
import DefaultLogo from '../../assets/images/logo.svg';

const AutoCompleteBrandItem = ({ name, logo }: Partial<BrandDBEntity>) => (
  <Row gutter={[16, 8]}>
    <Col
      span={5}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BrandImage
        alt={`${name} logo`}
        src={logo?.url ?? DefaultLogo}
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = DefaultLogo;
        }}
      />
    </Col>
    <Col span={16}>
      <BoldItemParagraph>{name}</BoldItemParagraph>
    </Col>
  </Row>
);

export default AutoCompleteBrandItem;
