import { Button, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const LoginContainer = styled.div`
  background-color: ${({ theme }) => theme.color.link};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginActionsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 450px;
  width: 90%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 40px 30px;
  border-radius: 8px;
`;

export const Logo = styled.img`
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
`;

export const PandasText = styled.img`
  height: 50px;
  width: 200px;
  margin-bottom: 5px;
`;

export const BackofficeText = styled.h2`
  height: 60px;
  width: 200px;
  margin-bottom: 40px;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};
`;

export const LoginButton = styled(Button)`
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.color.link};
  border-color: ${({ theme }) => theme.color.link};
  &:hover {
    background-color: ${({ theme }) => theme.color.primary_dark};
    border-color: ${({ theme }) => theme.color.primary_dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.color.primary_dark};
    border-color: ${({ theme }) => theme.color.primary_dark};
  }
`;

export const ErrorText = styled(Text)`
  text-align: center;
`;
