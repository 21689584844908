import { BaseColors } from 'helpers/constants/theme';
import { Variant } from 'services/product/interface';

export interface VariantContent extends Variant {
  createdAt?: string;
  updatedAt?: string;
}

export const VariantStatusColor = {
  ACTIVE: BaseColors.success_green,
  INACTIVE: BaseColors.error_red,
};

export const UnitStatusColor = {
  available: BaseColors.success_green,
  committed: BaseColors.wow_blue,
  sold: BaseColors.dark_gray,
  inactive: BaseColors.carnation,
  blocked: BaseColors.orange,
  active: BaseColors.soft_blue,
};
