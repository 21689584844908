import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { SettingUpdateAction, SettingUpdateState } from '../reducer/interface';
import {
  INITIAL_SETTING_UPDATE_STATE,
  settingsUpdateReducer,
} from '../reducer/settings-update.reducer';

export interface SettingUpdateContextTypes {
  state: SettingUpdateState;
  dispatch: Dispatch<SettingUpdateAction>;
}

const SettingUpdateContext = createContext<SettingUpdateContextTypes>(
  {} as SettingUpdateContextTypes,
);

export const SettingUpdateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    settingsUpdateReducer,
    INITIAL_SETTING_UPDATE_STATE,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <SettingUpdateContext.Provider value={value}>
      {children}
    </SettingUpdateContext.Provider>
  );
};

export const useSettingUpdate = () => useContext(SettingUpdateContext);
