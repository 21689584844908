import { WmsSettingEnum } from 'services/wms-settings/interface';
import {
  SettingUpdateAction,
  SettingUpdateState,
  SETTING_UPDATE_ACTION_ENUM,
} from './interface';

export const INITIAL_SETTING_UPDATE_STATE: SettingUpdateState = {
  setting: {
    _id: '',
    otherAllowedValues: [],
    allowedRuleProps: [],
    default: {
      value: '',
      label: '',
    },
    behaviors: [],
    setting: WmsSettingEnum.NO_SETTING,
  },
  createBehaviorOpen: false,
  editBehaviorOpen: false,
  editionMode: false,
  editedBehaviorIndex: -1,
};

export function settingsUpdateReducer(
  state: SettingUpdateState,
  action: SettingUpdateAction,
): SettingUpdateState {
  switch (action.type) {
    case SETTING_UPDATE_ACTION_ENUM.SET_SETTING:
      return { ...state, setting: action.payload };
    case SETTING_UPDATE_ACTION_ENUM.DELETE_BEHAVIOR:
      return {
        ...state,
        setting: {
          ...state.setting,
          behaviors: state.setting.behaviors.filter(
            (_, idx) => idx !== action.payload,
          ),
        },
      };
    case SETTING_UPDATE_ACTION_ENUM.CREATE_BEHAVIOR:
      return {
        ...state,
        setting: {
          ...state.setting,
          behaviors: [...state.setting.behaviors, action.payload],
        },
      };
    case SETTING_UPDATE_ACTION_ENUM.TURN_CREATE_MODAL:
      return {
        ...state,
        createBehaviorOpen: action.payload,
      };
    case SETTING_UPDATE_ACTION_ENUM.TURN_BEHAVIOR_EDITION_MODAL:
      return {
        ...state,
        editBehaviorOpen: action.payload,
      };
    case SETTING_UPDATE_ACTION_ENUM.TURN_EDITION_MODE:
      return {
        ...state,
        editionMode: action.payload,
      };
    case SETTING_UPDATE_ACTION_ENUM.SET_EDITED_BEHAVIOR:
      return {
        ...state,
        editedBehavior: action.payload.body,
        editedBehaviorIndex: action.payload.index,
      };
    default:
      return state;
  }
}
