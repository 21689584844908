import { Col, Row, Tag } from 'antd';
import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import { UnitStatusColor } from 'containers/Products/VariantList/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITransferUnits } from 'services/transfers/interface';
import { UnitStatus } from 'services/unit/interface';
import {
  TRANSFER_THUMBNAIL_TAG_SPAN,
  TRANSFER_THUMBNAIL_WRAPPER_SPAN,
} from '../../constants';
import { TransferThumbnailWrapper } from './TransferCard.sty';

const TransferCardInfo = ({
  item: { status, variant, initialLocation },
}: {
  item: ITransferUnits;
}) => {
  const { t } = useTranslation();

  const unitTagColor = useMemo(() => {
    return UnitStatusColor[status as UnitStatus];
  }, [status]);

  const unitStatus = useMemo(() => {
    return t(`wms.unitStatus.${status?.toLowerCase()}`);
  }, [status, t]);

  const nameIsValidation = Boolean(initialLocation?.name || false);

  return (
    <Row>
      <TransferThumbnailWrapper span={TRANSFER_THUMBNAIL_WRAPPER_SPAN}>
        <ThumbnailRender variant={variant} />
      </TransferThumbnailWrapper>
      <Col span={TRANSFER_THUMBNAIL_TAG_SPAN}>
        <Tag color={unitTagColor}>{unitStatus}</Tag>
        {nameIsValidation && <Tag color="blue">{initialLocation?.name}</Tag>}
        <p>{variant?.name}</p>
      </Col>
    </Row>
  );
};

export default TransferCardInfo;
