/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import { TablePaginationConfig } from 'antd';
import { CreditStatusEnum } from 'containers/CreditLine/interface';
import useModal from 'helpers/hooks/useModal/useModal';
import { useCallback, useState } from 'react';
import { getCreditList } from 'services/credit/credit.service';
import { PaginatedResponse } from 'services/global/interface';
import { CreditForm, creditFormInitial, CreditLine } from '../interface';

const useCreditContent = () => {
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
      total: 1000,
    },
  );

  const { openModal, closeModal, isOpen: isModalOpen } = useModal({});
  const [initialValues, setInitialValues] =
    useState<CreditForm>(creditFormInitial);
  const searchDataStatus = useState<string>('');
  const [showLegacy, setShowLegacy] = useState(false);
  const [searchData] = searchDataStatus;

  const { isLoading: loadingList, data: creditList } = useQuery<
    PaginatedResponse<CreditLine>
  >({
    queryFn: () =>
      getCreditList({
        search: searchData,
        page: tablePagination.current ?? 1,
        pageSize: tablePagination.pageSize ?? 10,
        showLegacy,
      }),
    queryKey: [
      'creditList',
      searchData,
      tablePagination.current,
      tablePagination.pageSize,
      showLegacy,
    ],
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setTablePagination({
        ...tablePagination,
        total: data.metadata.length,
      });
    },
  });

  const handleUpdateCredit = useCallback(
    (credit: CreditLine) => {
      setInitialValues({
        ...credit,
        user: {
          id: credit.userId,
          info: {
            name: credit.userName,
          },
        },
        status: credit.status.toUpperCase() as CreditStatusEnum,
      });
      openModal();
    },
    [openModal],
  );

  return {
    creditList,
    getCreditList,
    tablePagination,
    setTablePagination,
    openModal,
    closeModal,
    isModalOpen,
    initialValues,
    handleUpdateCredit,
    setShowLegacy,
    searchDataStatus,
    loadingList,
  };
};

export default useCreditContent;
