import { useMutation, useQuery } from '@tanstack/react-query';
import { ROUTES } from 'helpers/constants/routes';
import { t } from 'i18next';
import {
  accessFromCurrentStatus,
  orderActionsPermissions,
} from 'modules/Order/constants/orderStatusMachine';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Order,
  OrderStatus,
  OrderStatusAction,
  PandasPaymentMethod,
  StatusOrderEnum,
} from 'services/order/interface';
import { updateOrderStatus } from 'services/order/order.service';
import { getParameters } from 'services/parameters/parameters.service';
import useFirebaseAuth from '../../../helpers/hooks/useFirebaseAuth';
import useNotifications from '../../../helpers/hooks/useNotifications';

export const useOrderStatus = ({
  editForStock = false,
}: {
  editForStock?: boolean;
}) => {
  const navigate = useNavigate();
  const { logOut } = useFirebaseAuth();
  const { notifyWarning, notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });

  const handleAuthError = useCallback(
    (error: any) => {
      if (error?.response?.data?.statusCode === 401) {
        logOut();
        notifyWarning(t(`httpErrors.${error?.response?.data?.statusCode}`));
        navigate(ROUTES.login);
      } else {
        notifyError(t('order.changeStatus.error'));
      }
    },
    [navigate, logOut, notifyWarning, notifyError],
  );

  const { mutateAsync: changeOrderStatus, isLoading: loadingStatusUpdate } =
    useMutation({
      mutationFn: updateOrderStatus,
      onError: handleAuthError,
      onSuccess: () => notifySuccess(t('order.changeStatus.success')),
    });
  const { data } = useQuery({
    queryFn: () => getParameters({ keys: 'reasons' }),
    queryKey: ['cancel-reasons'],
    refetchOnWindowFocus: false,
  });

  const hasAccess = (
    currentState: OrderStatus,
    action: OrderStatusAction,
  ): boolean =>
    orderActionsPermissions[action].includes(currentState) ||
    (currentState === StatusOrderEnum.PENDING && editForStock);

  const canChangeStatus = (
    currentStatus: OrderStatus,
    newStatus: OrderStatus,
  ): boolean =>
    accessFromCurrentStatus[currentStatus].includes(
      newStatus as StatusOrderEnum,
    );

  const changeStatus = async (
    order: Pick<
      Order,
      'id' | 'status' | 'orderNumber' | 'paymentMethod' | 'bankTransferPaid'
    >,
    newStatus: StatusOrderEnum,
    observations?: string,
    cancelReason?: string,
    reportMissing?: boolean,
  ): Promise<void> => {
    if (
      newStatus === StatusOrderEnum.IN_TRANSIT &&
      order.paymentMethod === PandasPaymentMethod.BANK_TRANSFER &&
      !order.bankTransferPaid
    ) {
      notifyError('order.changeStatus.bankTransferError');
      return;
    }

    if (canChangeStatus(order.status, newStatus)) {
      await changeOrderStatus({
        orderId: order.id,
        newStatus,
        observations,
        cancelReason,
        reportMissing,
      });
      return;
    }

    notifyWarning(t('order.changeStatus.error'));
  };

  return {
    changeStatus,
    hasAccess,
    loadingStatusUpdate,
    reasonOptions: data?.reasons?.data ?? [],
  };
};
