import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HaulierModalAction } from 'components/HaulierForm/interface';
import HaulierForm from 'components/HaulierForm/HaulierForm';
import { HaulierModalProps } from './interface';

const HaulierModal = ({
  isOpen,
  closeModal,
  modalActionType,
  initialValues,
  reLoadOnSuccess,
}: HaulierModalProps) => {
  const [formReference, setFormReference] = useState<any>();

  const { t } = useTranslation();

  return (
    <Modal
      width="95%"
      visible={isOpen}
      onCancel={closeModal}
      destroyOnClose
      onOk={formReference ? formReference.submit : null}
      okText={
        modalActionType === HaulierModalAction.CREATE
          ? t('haulierForm.okButton.create')
          : t('haulierForm.okButton.update')
      }
    >
      <HaulierForm
        actionType={modalActionType}
        setFormReference={setFormReference}
        closeModal={closeModal}
        reLoadOnSuccess={reLoadOnSuccess}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default HaulierModal;
