import { PurchaseVariant } from 'services/purchase/interface';
import { numberToCurrency } from '../../../../helpers/utils/currency';

const getVariantCost = (variant: PurchaseVariant) =>
  Number(
    variant.values.importCost ??
      variant.values.estimatedCogs ??
      variant.values.importCostVat ??
      variant.values.estimatedCogsVat,
  );

const getVariantQuantity = (variant: PurchaseVariant) =>
  Number(
    variant.values.receivedQuantity ??
      variant.values.clearedQuantity ??
      variant.values.orderedQuantity,
  );

const getVariantSubtotal = (variant: PurchaseVariant) => {
  const cost = getVariantCost(variant);
  const qty = getVariantQuantity(variant);
  return cost * qty;
};

const getVariantTax = (variant: PurchaseVariant) => {
  let tax = 0;

  if (variant.values.taxType === 'NOMINAL') {
    const quantity = getVariantQuantity(variant);
    tax = variant.values.tax * quantity;
  } else {
    const subTotal = getVariantSubtotal(variant);
    tax = subTotal * (variant.values.tax / 100);
  }

  return Number(tax);
};

export const getPurchaseVariantCost = (variant: PurchaseVariant) =>
  numberToCurrency(getVariantSubtotal(variant) + getVariantTax(variant));

export const calculatePurchaseTotal = (detail: PurchaseVariant[]) =>
  detail.reduce(
    (acc, item) => acc + getVariantTax(item) + getVariantSubtotal(item),
    0,
  );
