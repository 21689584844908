import styled from 'styled-components';

const InfiniteScrollWrapper = styled.section`
  overflow-y: scroll;
  height: 70vh;
  & .infinite-scroll-component {
    overflow: hidden !important;
  }
`;

export default InfiniteScrollWrapper;
