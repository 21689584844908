import { AppstoreAddOutlined } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import { LayoutContainer } from 'pages/App/Layout.sty';
import styled from 'styled-components';

export const CreateBannerCard = styled(Card)`
  width: 100%;
  height: 100%;
  border: 2px dashed ${BaseColors.curious_blue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CreateBannerIcon = styled(AppstoreAddOutlined)`
  color: ${BaseColors.curious_blue};
  font-size: 3em;
`;

export const CreateBannerLabel = styled.p`
  color: ${BaseColors.curious_blue};
  font-size: 1.5em;
`;

export const BannerContainerLayout = styled(LayoutContainer)`
  overflow-y: scroll;
`;

export const BannerContainerModal = styled(Modal)`
  width: 40%;

  & .ant-modal-body {
    max-height: 70vh;
    overflow: scroll;
  }
`;
