import { transferUnitsAtom } from 'helpers/recoil/atoms/transfers';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

const useTransfersCart = () => {
  // ================================ states & hooks =================================
  const [transferItems, setTransferItems] = useRecoilState(transferUnitsAtom);

  // ===================================== memos =====================================
  const iterableItems = useMemo(() => {
    return Object.entries(transferItems).map(([path, i]) => ({ ...i, path }));
  }, [transferItems]);

  const canTransfer = useMemo(() => {
    let someHasWrongData = false;

    iterableItems.forEach((item): void => {
      if (!item.finalLocation || item.quantity <= 0) {
        someHasWrongData = true;
      }
    });

    return iterableItems.length > 0 && !someHasWrongData;
  }, [iterableItems]);

  const cartIsEmpty = useMemo(() => {
    return Object.keys(transferItems).length <= 0;
  }, [transferItems]);

  // ================================== callbacks ===================================
  const clearCart = useCallback(() => {
    setTransferItems({});
  }, [setTransferItems]);

  return {
    canTransfer,
    cartIsEmpty,
    clearCart,
    iterableItems,
  };
};

export default useTransfersCart;
