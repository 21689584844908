/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { Form, Table } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ProductFigure } from 'components/AutoCompleteVariantItem/AutoCompleteVariantItem.sty';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import { PickingProduct, VariantImage } from 'services/product/interface';
import { CustomNumberInput } from './DetailsTable.sty';

const tableColumns = (disabled: boolean) => [
  {
    title: '',
    width: '18%',
    dataIndex: 'images',
    render: (images: Array<VariantImage>) => (
      <ProductFigure>
        <img
          src={
            images?.length
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${images?.[0]?.url}`
              : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
          }
          alt={images?.[0]?.url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
          }}
        />
      </ProductFigure>
    ),
  },
  {
    title: '',
    dataIndex: 'name',
    render: (name: string) => {
      const nameSplit = name.split('-');
      return (
        <ul style={{ listStyle: 'none' }}>
          <li> {nameSplit[0] || '-'} </li>
          <li>
            <CustomTag color={LightTheme.color.dark}>
              {nameSplit[1] || '-'}
            </CustomTag>
          </li>
        </ul>
      );
    },
  },
  {
    title: '',
    width: '18%',
    dataIndex: 'field',
    render: (field: FormListFieldData, product: PickingProduct) => (
      <>
        <Form.Item {...field} name={[field.name, 'cartQuantity']} noStyle>
          <CustomNumberInput
            controls={false}
            size="small"
            min={0}
            max={product.expectedCartQuantity}
            disabled={disabled}
          />
        </Form.Item>
        &nbsp;{' /'}
        {product.expectedCartQuantity}
      </>
    ),
  },
];

const usePurchaseTableModule = (disabled: boolean) => {
  const defaultColumns = tableColumns(disabled);

  return {
    defaultColumns,
  };
};

const DetailsTable = ({
  pickProducts,
  isLoading,
  disabled,
}: {
  pickProducts: PickingProduct[];
  isLoading: boolean | undefined;
  disabled: boolean | false;
}) => {
  const { defaultColumns } = usePurchaseTableModule(disabled);

  return (
    <Table
      showHeader={false}
      columns={defaultColumns}
      loading={isLoading}
      rowKey="id"
      dataSource={pickProducts || []}
      pagination={false}
    />
  );
};

export default DetailsTable;
