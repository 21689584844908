/* eslint-disable no-underscore-dangle */
import { Modal, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import Paragraph from 'antd/lib/typography/Paragraph';
import { HaulierModalAction } from 'components/HaulierForm/interface';
import useHaulierForm from 'components/HaulierForm/hooks/useHaulierForm';
import { HaulierModalProps } from './interface';

const DeleteHaulierModal = ({
  isOpen,
  closeModal,
  initialValues,
  reLoadOnSuccess,
}: HaulierModalProps) => {
  const { t } = useTranslation();

  const { onDeleteModal } = useHaulierForm(
    initialValues,
    HaulierModalAction.DELETE,
    initialValues?._id,
    closeModal,
    reLoadOnSuccess,
  );

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      destroyOnClose
      onOk={onDeleteModal}
    >
      <PageHeader title={t('brandForm.titles.delete')} />
      <Paragraph>
        {t('brandForm.subtitle.deleteModal', {
          brandName: initialValues?.name,
        })}
      </Paragraph>
    </Modal>
  );
};

export default DeleteHaulierModal;
