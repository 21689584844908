/* eslint-disable react/jsx-props-no-spreading */
import { Col, Form, FormInstance, Modal } from 'antd';
import { CreateBannerCard } from 'containers/BannerList/BannerContainer.sty';
import { bannerColSizes } from 'containers/BannerList/constants';
import useNotifications from 'helpers/hooks/useNotifications';
import { uniqueId } from 'lodash';
import { Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Behavior,
  IBehaviorForm,
  IWmsSettingForm,
} from 'services/wms-settings/interface';
import { BehaviorFormKeys, BehaviorsKey } from '../constants';
import { checkIfBehaviorExists } from '../helpers/behavior';
import {
  SettingUpdateAction,
  SettingUpdateState,
  SETTING_UPDATE_ACTION_ENUM,
} from '../reducer/interface';
import BehaviorCard from './BehaviorCard';
import BehaviorForm from './BehaviorForm';
import { AddBehaviorIcon, AddBehaviorLabel } from './SettingUpdate.sty';

const BehaviorManager = ({
  state,
  dispatch,
  formBehaviors,
  form,
}: {
  state: SettingUpdateState;
  formBehaviors: Behavior[];
  dispatch: Dispatch<SettingUpdateAction>;
  form: FormInstance<IWmsSettingForm>;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });
  const [behaviorForm] = Form.useForm<IBehaviorForm>();
  const [editBehaviorForm] = Form.useForm<IBehaviorForm>();

  const onTurnCreateModal = useCallback(() => {
    behaviorForm.resetFields();

    dispatch({
      payload: !state.createBehaviorOpen,
      type: SETTING_UPDATE_ACTION_ENUM.TURN_CREATE_MODAL,
    });
  }, [dispatch, state, behaviorForm]);

  const onTurnEditionModal = useCallback(() => {
    behaviorForm.resetFields();

    dispatch({
      payload: !state.editBehaviorOpen,
      type: SETTING_UPDATE_ACTION_ENUM.TURN_BEHAVIOR_EDITION_MODAL,
    });
  }, [dispatch, state, behaviorForm]);

  const onEditBehavior = useCallback(
    (behaviorIndex: number) => {
      editBehaviorForm.resetFields();
      onTurnEditionModal();

      dispatch({
        payload: { body: formBehaviors[behaviorIndex], index: behaviorIndex },
        type: SETTING_UPDATE_ACTION_ENUM.SET_EDITED_BEHAVIOR,
      });
    },
    [dispatch, editBehaviorForm, formBehaviors, onTurnEditionModal],
  );

  const onDeleteBehavior = useCallback(
    (behaviorIndex: number) => {
      const actualBehaviors = form.getFieldValue(BehaviorsKey) as Behavior[];
      const behaviorsToSet = actualBehaviors.filter(
        (_, idx) => idx !== behaviorIndex,
      );

      form.setFieldValue(BehaviorsKey, behaviorsToSet);
    },
    [form],
  );

  const onSubmitCreateBehavior = useCallback(
    (newBehavior: Behavior) => {
      const behaviorAlreadyExists = checkIfBehaviorExists(
        formBehaviors,
        newBehavior,
      );

      if (behaviorAlreadyExists) {
        notifyError('wms-settings.behavior.form.errors.behaviorAlreadyExists');
        return;
      }
      const actualBehaviors = form.getFieldValue(BehaviorsKey);

      form.setFieldValue(BehaviorsKey, [...actualBehaviors, newBehavior]);
      onTurnCreateModal();
    },
    [onTurnCreateModal, formBehaviors, notifyError, form],
  );

  const onSubmitEditBehavior = useCallback(
    (editedBehavior: Behavior) => {
      const behaviorIdx = state.editedBehaviorIndex;
      const actualBehaviors = form.getFieldValue(BehaviorsKey);

      actualBehaviors[behaviorIdx] = editedBehavior;

      form.setFieldValue(BehaviorsKey, actualBehaviors);
      onTurnEditionModal();
    },
    [onTurnEditionModal, form, state],
  );

  return (
    <>
      {state.editionMode && (
        <Col {...bannerColSizes}>
          <CreateBannerCard bordered hoverable onClick={onTurnCreateModal}>
            <AddBehaviorIcon />
            <AddBehaviorLabel>
              {t('wms-settings.update.form.labels.addRule')}
            </AddBehaviorLabel>
          </CreateBannerCard>
        </Col>
      )}
      {formBehaviors?.map((behavior: Behavior, idx: number) => (
        <Col {...bannerColSizes} key={uniqueId()}>
          <BehaviorCard
            behavior={behavior}
            setting={state.setting}
            idx={idx}
            onDeleteBehavior={onDeleteBehavior}
            disabled={!state.editionMode}
            onEditBehavior={onEditBehavior}
          />
        </Col>
      ))}
      <Modal
        open={state.createBehaviorOpen}
        destroyOnClose
        onCancel={onTurnCreateModal}
        onOk={behaviorForm.submit}
        title={t('wms-settings.behavior.form.title')}
      >
        <BehaviorForm
          ruleDefinitions={state.setting.allowedRuleProps}
          setting={state.setting}
          form={behaviorForm}
          onSubmit={onSubmitCreateBehavior}
          key={BehaviorFormKeys.create}
        />
      </Modal>
      <Modal
        open={state.editBehaviorOpen}
        destroyOnClose
        onCancel={onTurnEditionModal}
        onOk={behaviorForm.submit}
        title={t('wms-settings.behavior.edition.title')}
      >
        <BehaviorForm
          ruleDefinitions={state.setting.allowedRuleProps}
          setting={state.setting}
          form={behaviorForm}
          onSubmit={onSubmitEditBehavior}
          initialValues={state.editedBehavior}
          key={BehaviorFormKeys.edit}
        />
      </Modal>
    </>
  );
};

export default BehaviorManager;
