import { AxiosResponse } from 'axios';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { WmsSetting } from './interface';

export const getWmsSettings = async (params: {
  page: number;
  pageSize: number;
}): Promise<PaginatedResponse<WmsSetting>> => {
  const response: AxiosResponse<PaginatedResponse<WmsSetting>> =
    await getAPIMethod({
      name: 'getSettingList',
      params,
    });
  return response.data;
};

export const getWmsSettingById = async (params: {
  id: string;
}): Promise<WmsSetting> => {
  const response: AxiosResponse<WmsSetting> = await getAPIMethod({
    name: 'getSetting',
    pathParams: params,
  });
  return response.data;
};

export const updateWmsSetting = async (params: {
  id: string;
  payload: WmsSetting;
}): Promise<WmsSetting> => {
  const response: AxiosResponse<WmsSetting> = await getAPIMethod({
    name: 'updateSetting',
    pathParams: {
      id: params.id,
    },
    data: params.payload,
  });
  return response.data;
};

export default {
  create: getWmsSettings,
  read: getWmsSettings,
  update: getWmsSettings,
  delete: getWmsSettings,
  readByParam: getWmsSettings,
};
