import { notification } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useAuth } from 'helpers/context/AuthUserContext';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { hasAccess } = useRoleAccess('general.backoffice');
  const { logOut, authUser } = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    if (!authUser && hasAccess) {
      notification.error({
        message: t('login.youDontHaveAccess'),
        description: t('login.youDontHaveAccessDescription'),
      });
      logOut();
    }
  }, [hasAccess, logOut, t, authUser]);
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<div>Cargando...</div>}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
};
export default PrivateRoute;
