/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import { Divider, Input, Table } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import VariantsAutocomplete from 'containers/Variants/VariantSearchInput/VariantSearchInput';
import { t } from 'i18next';
import useOrderProductTable from 'modules/Order/hooks/useOrderProductTable';
import { LogisticType } from 'services/MPS/interface';
import { StockInfo } from 'services/order/interface';
import { CartVariant } from 'services/product/interface';

interface UnitLocation {
  ID: string;
  Name: string;
  Warehouse: {
    ID: string;
    Name: string;
  };
}
export interface RelatedUnit {
  ID: string;
  cost: number;
  VariantId: string;
  VirtualLocation: string;
  Location: UnitLocation;
  PurchaseId: string;
}
export interface OrderProductTableData extends CartVariant {
  field: FormListFieldData;
  subtotal?: number;
  relatedUnits?: RelatedUnit[];
  editionLocked?: boolean;
}

interface OrderProductTableProps {
  detail: OrderProductTableData[];
  disabled?: boolean;
  add: (defaultValue?: CartVariant) => void;
  remove: (index: number) => void;
  pendingStock?: StockInfo[];
  isUpdate?: boolean;
}

const OrderProductTable = ({
  detail,
  add,
  remove,
  disabled,
  pendingStock,
  isUpdate,
}: OrderProductTableProps) => {
  const {
    columns,
    handleAddNewVariantToCart,
    handleFilterVariantList,
    searchKey,
    tableData,
    productsLogistic,
  } = useOrderProductTable({
    pendingStock,
    detail,
    add,
    remove,
    disabled,
    isUpdate,
  });

  const allSellersAre3P = detail.every(
    (d) => d.mps?.logisticType === LogisticType['3P'],
  );
  const sellerIds = detail.map((d) => d.mps?._id).join(',');

  return (
    <>
      <VariantsAutocomplete
        onChange={handleAddNewVariantToCart}
        disabled={disabled}
        mpsId={allSellersAre3P && isUpdate ? sellerIds : undefined}
        logisticType={productsLogistic}
      />
      <Divider />
      <Input.Search
        style={{ width: '100%', minWidth: '100%' }}
        value={searchKey}
        onChange={handleFilterVariantList}
        placeholder={t('variantSearch.placeholder')}
      />
      <Divider />
      <Table
        rowKey={(record: OrderProductTableData) =>
          `${record.id}${record.status}` || ''
        }
        columns={columns}
        dataSource={tableData}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 500, x: 1000 }}
      />
    </>
  );
};

export default OrderProductTable;
