import { RelatedUnit } from 'modules/Order/components/OrderForm/OrderProductTable';
import { CartItemStatus } from 'services/cart/interface';
import { CartVariant } from 'services/product/interface';

const VirutalLocations = {
  PENDING: ['marketplace-on-demand'],
  RESERVED: ['marketplace-on-demand'],
  IN_TRANSIT: ['marketplace-purchase'],
  IN_WAREHOUSE: ['marketplace-warehouse', 'marketplace-entry'],
  '': [],
};

export const getUnitsByStatus = (
  status: CartVariant['status'],
  relatedUnits: RelatedUnit[],
) => {
  let unitsToCheck = relatedUnits;

  switch (status) {
    case 'RESERVED' as CartItemStatus:
      unitsToCheck = unitsToCheck.filter((o) =>
        VirutalLocations.RESERVED.includes(o.VirtualLocation),
      );
      break;
    case 'IN_TRANSIT' as CartItemStatus:
      unitsToCheck = unitsToCheck.filter((o) =>
        VirutalLocations.IN_TRANSIT.includes(o.VirtualLocation),
      );
      break;
    case 'IN_WAREHOUSE' as CartItemStatus:
      unitsToCheck = unitsToCheck.filter((o) =>
        VirutalLocations.IN_WAREHOUSE.includes(o.VirtualLocation),
      );
      break;
    case 'PENDING' as CartItemStatus:
    default:
      unitsToCheck = [];
      break;
  }

  return unitsToCheck;
};
