import { validationConstants } from 'config/Constants/validations';
import { t } from 'i18next';
import moment from 'moment';
import { FormInstance } from 'rc-field-form/lib/interface';

const {
  specialCharsWithSpace: SPECIAL_CHARS_WITH_SPACE,
  specialChars: SPECIAL_CHARS,
} = validationConstants;

export const validateAlphanumericField =
  (errorMessage: string, excludeSpace?: boolean) =>
  ({ getFieldValue: _getFieldValue }: FormInstance) => ({
    validator(_: unknown, value: string) {
      const validateRegExp = excludeSpace
        ? SPECIAL_CHARS_WITH_SPACE
        : SPECIAL_CHARS;
      if (value?.trim()?.length > 0 && !validateRegExp.test(value || '@')) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t(errorMessage)));
    },
  });

export const validateCouponName = validateAlphanumericField(
  'couponModal.form.errorMessages.name.alphanumeric',
);

export const validateCouponCode = validateAlphanumericField(
  'couponModal.form.errorMessages.code.alphanumeric',
  true,
);

export const validateStartAfterNow = ({
  getFieldValue: _getFieldValue,
}: FormInstance) => ({
  validator(_: unknown, value: moment.Moment) {
    if (value?.isBefore(moment())) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        t('couponModal.form.errorMessages.startDate.createBeforeToday'),
      ),
    );
  },
});

export const validateEndAfterStart = ({ getFieldValue }: FormInstance) => ({
  validator(_: unknown, value: moment.Moment) {
    if (!getFieldValue('startDate')) {
      return Promise.reject(
        t('couponModal.form.errorMessages.endDate.beforeStartDate'),
      );
    }
    if (value?.isAfter(getFieldValue('startDate'))) {
      return Promise.resolve();
    }
    return Promise.reject(
      t('couponModal.form.errorMessages.endDate.beforeStartDate'),
    );
  },
});

export const validateUsesPerUser = ({ getFieldValue }: FormInstance) => ({
  validator(_: unknown, value: number) {
    if (Number(value || 0) <= Number(getFieldValue('uses') || 0)) {
      return Promise.resolve();
    }
    return Promise.reject(
      t('couponModal.form.errorMessages.usesPerUser.moreThanTotal'),
    );
  },
});

export const validateNominalValue = ({ getFieldValue }: FormInstance) => ({
  validator(_: unknown, value: number) {
    const lowerThanMaxDiscount =
      Number(value || 0) <= Number(getFieldValue('maxDiscount') || 0);
    const lowerThanMinPurchase =
      Number(value || 0) <= Number(getFieldValue('minimumPurchase') || 0);
    if (lowerThanMaxDiscount && lowerThanMinPurchase) {
      return Promise.resolve();
    }
    if (!lowerThanMaxDiscount) {
      return Promise.reject(
        new Error(
          t('couponModal.form.errorMessages.nominalValue.moreThanMaxDiscount'),
        ),
      );
    }
    if (!lowerThanMinPurchase) {
      return Promise.reject(
        new Error(
          t('couponModal.form.errorMessages.nominalValue.moreThanMinPurchase'),
        ),
      );
    }
    return Promise.reject(
      new Error(
        t('couponModal.form.errorMessages.nominalValue.moreThanAllowed'),
      ),
    );
  },
});

export const requiredRule = {
  required: true,
  message: t('couponModal.form.errorMessages.general.required'),
};

export const conditionalRequiredRule = (required: boolean) => ({
  required,
  message: t('couponModal.form.errorMessages.general.required'),
});
