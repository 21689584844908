import { AutoComplete, AutoCompleteProps } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

export const WarningMsg = styled.p`
  color: ${BaseColors.error_red};
`;

export const LocationAutoCompleteBar = styled(AutoComplete)`
  width: 100%;
` as <T>(props: AutoCompleteProps<T>) => React.ReactElement;
// for more info about the above change check: https://github.com/styled-components/styled-components/issues/1803;
