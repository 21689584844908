import { AutoComplete, Input } from 'antd';
import { useRef } from 'react';
import useBrandsAutocomplete from './hooks/useBrandsAutocomplete';
import { BrandsAutocompleteProps } from './interface';

const { Search } = Input;

const BrandsAutocomplete = ({
  setBrandValue,
  form,
  initialValue,
}: BrandsAutocompleteProps) => {
  const {
    selectBrand,
    isLoadingAutocomplete,
    handleSearch,
    options,
    searchKeyInput,
  } = useBrandsAutocomplete({ setBrandValue, form, initialValue });

  const autoCompleteRef = useRef<any>(null);

  return (
    <AutoComplete
      ref={autoCompleteRef}
      options={isLoadingAutocomplete ? [] : options}
      onSelect={selectBrand}
      onSearch={handleSearch}
      value={searchKeyInput}
    >
      <Search size="large" name="brand" loading={isLoadingAutocomplete} />
    </AutoComplete>
  );
};

export default BrandsAutocomplete;
