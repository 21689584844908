import { Card, Divider } from 'antd';
import styled from 'styled-components';

export const RelativeDivider = styled(Divider)`
  position: relative;
`;

export const RelativeDividerOptions = styled.div`
  position: absolute;
  top: 0;
  gap: 5px;
  display: flex;
  right: 20px;
  background: white;
  padding: 0 10px;
  z-index: 1;
`;

export const StockInfoCard = styled(Card)`
  height: 140px;
`;

export const StockInfoContainer = styled.div`
  display: flex;
`;
