import { notification } from 'antd';
import { ProductContent } from 'containers/Products/ProductList/ProductContent';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ProductList = () => {
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.productList}`);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  return <ProductContent />;
};
