import { MPS } from 'services/MPS/interface';
import { VariantImage } from 'services/product/interface';

export interface Warranty {
  status: WarrantyStatus; // Is pending if returnVariants.lenght > 0 otherwise is processed
  warranties: WarrantyList[];
  returnVariants: RequestVariants[]; // This is supposed to appear on remission
}

export interface RequestVariants {
  id: string;
  variantSku: string;
  name: string;
  quantity: number;
}

export interface WarrantyList {
  id: string;
  consecutive: number;
  warrantyNumber: string;
  userId: string;
  userName: string;
  orderId: string;
  orderNumber: string;
  packageNumber: string;
  detail: WarrantyDetailDTO[];
  warrantyTotal: number;
  status: WarrantyStatus;
  pandabonos: number;
  locations: {
    cityCode: string;
    departmentCode: string;
    address: string;
  };
  method: WarrantyMethod;
  requestDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface WarrantyDetailDTO {
  id: string;
  variantSku: string;
  name?: string;
  cartQuantity: number;
  warrantyQuantity: number;
  processedQuantity?: number;
  processed?: boolean;
  price: number;
  discount: number;
  warrantyReason: string;
  images: VariantImage[];
  mps: MPS;
}

export enum WarrantyMethod {
  FORMS = 'FORMS',
  PANDABONOS = 'PANDABONOS',
}

export enum WarrantyStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED', // Pandabonos
  PROCESSED = 'PROCESSED', // Forms
  REJECTED = 'REJECTED',
}

export const ValidWarrantyStatusChange = {
  [WarrantyStatus.PENDING]: [WarrantyStatus.PROCESSED, WarrantyStatus.REJECTED],
  [WarrantyStatus.ACCEPTED]: [],
  [WarrantyStatus.PROCESSED]: [],
  [WarrantyStatus.REJECTED]: [],
};

export interface UpdateStatusDTO {
  orderNumber: string;
  warrantyNumber: string;
  newStatus: string;
  detail: WarrantyDetailDTO[];
}
