/* eslint-disable react/jsx-props-no-spreading */
import { Row, Spin, Table } from 'antd';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import { t } from 'i18next';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { InfiniteScrollTableProps } from './interface';

const TABLE_HEADER_HEIGHT = 192;

const InfiniteScrollTableComponent = (
  {
    onScrollToEnd,
    id,
    isLoading,
    tableHeaderHeight,
    ...props
  }: InfiniteScrollTableProps,
  ref: ForwardedRef<{ scrollToTop: () => void }>,
) => {
  const tableSize = useTableSize(tableHeaderHeight || TABLE_HEADER_HEIGHT);
  const tableContainerRef = useRef<Element>();

  const LoadingFooter = useCallback(
    () =>
      isLoading ? (
        <Row justify="center" style={{ marginTop: '1em' }}>
          <Spin tip={t('loading')} />
        </Row>
      ) : null,
    [isLoading],
  );

  useEffect(() => {
    const handleScroll = (event: any) => {
      const reachedBottom =
        Math.abs(
          (event.target?.scrollHeight || 0) -
            (event.target?.scrollTop || 100) -
            (event.target?.clientHeight || 10),
        ) <= 1;
      if (reachedBottom) {
        onScrollToEnd?.();
      }
    };
    const tableRef = document.getElementById(id);
    const tableBodyContainer = tableRef?.querySelector('div.ant-table-body');
    if (tableBodyContainer) {
      tableContainerRef.current = tableBodyContainer;
    }
    tableBodyContainer?.addEventListener('scroll', handleScroll);
    return () =>
      tableBodyContainer?.removeEventListener('scroll', handleScroll);
  }, [onScrollToEnd, id]);

  const scrollToTop = useCallback(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  }, [tableContainerRef]);

  useImperativeHandle(ref, () => ({
    scrollToTop() {
      scrollToTop();
    },
  }));

  return (
    <>
      <Table {...props} {...tableSize} id={id} />
      <LoadingFooter />
    </>
  );
};

export const InfiniteScrollTable = forwardRef(InfiniteScrollTableComponent);
