/* eslint-disable react/require-default-props */
import { Col, Row } from 'antd';
import { Purchase, Supplier } from 'services/purchase/interface';
import {
  BoldItemParagraph,
  ItemParagraph,
} from '../PurchaseForm/PurchaseForm.sty';

export const SupplierInfo = ({ supplier }: { supplier?: Supplier }) => {
  return (
    <Row gutter={[16, 8]}>
      <Col span={16}>
        <BoldItemParagraph>{supplier?.name || ''}</BoldItemParagraph>
        <ItemParagraph>{`NIT. ${
          supplier?.documentNumber || ''
        }`}</ItemParagraph>
        <ItemParagraph>
          {`Contacto: ${supplier?.contactName || 'NA'}`}
        </ItemParagraph>
        <ItemParagraph>
          {`Tel. Contacto: ${supplier?.phoneNumber || ''}`}
        </ItemParagraph>
        <ItemParagraph>{`Correo: ${supplier?.email || 'NA'}`}</ItemParagraph>
      </Col>
    </Row>
  );
};

export const CreatedBy = ({ purchase }: { purchase: Purchase }) => {
  return (
    <Row gutter={[16, 8]}>
      <Col span={16}>
        <BoldItemParagraph>
          {purchase.metadata?.createdBy?.name || ''}
        </BoldItemParagraph>
      </Col>
    </Row>
  );
};
