import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const HistoryWrapper = styled.div`
  padding-right: ${LightTheme.spaceSizes.m};
  overflow-y: scroll;
`;
export const DateLabel = styled.label`
  color: ${LightTheme.color.primary};
  font-size: 16px;
  font-weight: bold;
`;
export const NormalLabel = styled.li`
  color: grey;
  font-size: 14px;
  list-style: none;
`;
export const ActionLabelItem = styled.li`
  color: grey;
  font-size: 14px;
`;
export const BoldLabel = styled.span`
  font-size: 14px;
  color: black;
  list-style: none;
`;
export const ActionLabel = styled.ul`
  font-size: 14px;
  margin-left: 5px;
  color: black;
`;
