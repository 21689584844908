import { Tag } from 'antd';
import styled from 'styled-components';

export interface StatusTagProps {
  color: string;
}

export const CustomTag = styled(Tag)<StatusTagProps>`
  background: ${({ color }) => `${color}`}26 !important;
  color: ${({ color }) => `${color}`};
  font-weight: bold;
`;
