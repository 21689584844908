/* eslint-disable no-underscore-dangle */
import { Form } from 'antd';
import { parsePandasErr } from 'config/Errors/error';
import { ROUTES } from 'helpers/constants/routes';
import { useAuth } from 'helpers/context/AuthUserContext';
import useNotifications from 'helpers/hooks/useNotifications';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { t } from 'i18next';
import CancelOrderModal from 'modules/Order/components/Modals/CancelOrderModal';
import InsufficientStockModal from 'modules/Order/components/Modals/InsufficientStockModal';
import RescheduleOrderModal from 'modules/Order/components/Modals/RescheduleOrderModal';
import OrderConfirmationModal from 'modules/Order/components/OrderConfirmationModal';
import OrderForm from 'modules/Order/components/OrderForm';
import { orderActionsPermissions } from 'modules/Order/constants/orderStatusMachine';
import { useOrder } from 'modules/Order/context/Order.context';
import { UserRoleHasAccessTo } from 'modules/Order/helpers/orderForm';
import { useOrderStatus } from 'modules/Order/hooks/useOrderStatus';
import useOrderStatusChange from 'modules/Order/hooks/useOrderStatusChange';
import useSkipShipping from 'modules/Order/hooks/useSkipShipping';
import useWriteOrder from 'modules/Order/hooks/useWriteOrder';
import { ORDER_ACTIONS_ENUM } from 'modules/Order/interface';
import { ORDER_ACTION_ENUM } from 'modules/Order/reducer/interface';
import { detailToPicking } from 'modules/Order/reducer/utils/detail.parser';
import {
  cartDifference,
  validateCartQuantities,
} from 'modules/Order/reducer/utils/detail.validator';
import { range2DateOption } from 'modules/Order/reducer/utils/range2options';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserCredit } from 'services/credit/credit.service';
import {
  Order,
  OrderType,
  PandasPaymentMethod,
  PaymentOptionsKeys,
  StatusOrderEnum,
} from 'services/order/interface';
import { UserRoleEnum } from 'services/user/interface';

const OrderUpdateContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state: previousFilter } = useLocation();
  const { authUser, firebaseRoles } = useAuth();
  const { state, dispatch } = useOrder();
  const { notifySuccess, notifyError, notifyWarning } = useNotifications({
    translationFunction: t,
  });

  const [form] = Form.useForm<Order>();
  const values = Form.useWatch([], form);

  const onGoToOrder = useCallback(
    (order: Partial<Order>) => {
      navigate(`${ROUTES.order}/${order.id}`);
    },
    [navigate],
  );

  const isAdmin = useMemo(
    () => authUser?.role?.includes(RoleEnum.ADMIN) ?? false,
    [authUser],
  );

  const {
    order,
    loadingOrder,
    orderError,
    refetchOrder,
    updateOrder,
    loadingUpdate,
    orderTotals,
    loadingTotals,
    // refetchTotals,
    rescheduleReasonOptions,
    deliveryRangeOptions,
    segmentationValues,
    updatePendingStock,
    loadingPendingStockUpdate,
    isLoadedGoogleScript,
    loadingPaymentUpdate,
    updatePayment,
  } = useWriteOrder({ id: id ?? '', orderValues: values, isUpdate: true });

  const { isLoading: isLoadingSkipShipping, skipShipping } = useSkipShipping({
    id: id ?? '',
    notifyError,
    notifySuccess,
    refetchOrder,
  });

  const {
    userCanUpdatePayment,
    userCanUpdateOrder,
    userCanUpdateInvoices,
    userHasSkipPaymentPermission,
  } = useMemo(() => {
    if (!order) {
      return {
        userCanUpdatePayment: false,
        userCanUpdateOrder: false,
        userCanUpdateInvoices: false,
      };
    }

    const isNotPaid = order.bankTransferPaid === false;
    const roles = firebaseRoles.split('|');

    const userHasPaymentPermission = UserRoleHasAccessTo(
      roles as UserRoleEnum[],
      ORDER_ACTIONS_ENUM.UPDATE_PAYMENT,
    );

    const SkipPaymentPermission = UserRoleHasAccessTo(
      roles as UserRoleEnum[],
      ORDER_ACTIONS_ENUM.SKIP_PAYMENT,
    );

    const userHasUpdateInvoicePermission = UserRoleHasAccessTo(
      roles as UserRoleEnum[],
      ORDER_ACTIONS_ENUM.UPDATE_INVOICES,
    );

    const userHasUpdatePermission = UserRoleHasAccessTo(
      roles as UserRoleEnum[],
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
    );

    const statusIsAllowed =
      orderActionsPermissions.canUpdateBankPayment.includes(order.status);

    const isBankDeposit =
      order.paymentMethod === PandasPaymentMethod.BANK_TRANSFER;

    const payment =
      statusIsAllowed && isNotPaid && userHasPaymentPermission && isBankDeposit;

    const updateInvoicePermission =
      userHasUpdateInvoicePermission &&
      (order.legalized || order.orderType === OrderType['3P']);
    return {
      userCanUpdatePayment: payment,
      userCanUpdateOrder: userHasUpdatePermission,
      userCanUpdateInvoices: updateInvoicePermission,
      userHasSkipPaymentPermission: SkipPaymentPermission,
    };
  }, [firebaseRoles, order]);

  const canEditOrderDetail = useMemo(() => {
    return (
      (order?.status === StatusOrderEnum.PENDING && isAdmin) ||
      order?.status === StatusOrderEnum.STOCK_PENDING ||
      order?.status === StatusOrderEnum.RECEIVED
    );
  }, [isAdmin, order?.status]);

  const { changeStatus, hasAccess, loadingStatusUpdate, reasonOptions } =
    useOrderStatus({
      editForStock: canEditOrderDetail && state.editableForStock,
    });

  const {
    onChangeStatus,
    handleCommentChange,
    setCancelReason,
    setReportMissing,
    closeStatusChangeModal,
    handleStatusChange,
    handlePaymentUpdate,
  } = useOrderStatusChange({
    state,
    dispatch,
    notifyWarning,
    notifyError,
    changeStatus,
    updatePayment,
    order,
    async refetchOrders() {
      refetchOrder();
    },
    restoreStatus() {
      form.setFieldValue('status', order?.status);
    },
  });

  const handlePaymentStatusSelect = useCallback(
    (selection: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_PAYMENT_STATUS,
        payload: selection,
      });
    },
    [dispatch],
  );
  const handleFormFinish = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL,
      payload: true,
    });
  }, [dispatch]);
  const cancelUpdate = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL,
      payload: false,
    });
  }, [dispatch]);

  const onCancelReschedule = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_RESCHEDULE_MODAL,
      payload: false,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_RESCHEDULE_REASON,
      payload: '',
    });
  }, [dispatch]);
  const onOkReschedule = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_RESCHEDULE_MODAL,
      payload: false,
    });
  }, [dispatch]);
  const setRescheduleReason = useCallback(
    (reason: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_RESCHEDULE_REASON,
        payload: reason,
      });
    },
    [dispatch],
  );
  const onEnableForm = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_READONLY,
      payload: false,
    });
  }, [dispatch]);

  const goToList = useCallback(() => {
    navigate(ROUTES.order, { state: previousFilter });
  }, [previousFilter, navigate]);

  const submit = useCallback(async () => {
    const cartDiff = cartDifference(values.detail, order?.detail ?? []);
    if (
      cartDiff.length &&
      order?.paymentMethod === PandasPaymentMethod.CREDIT
    ) {
      const ziroRes = await getUserCredit(order?.user?.documentNumber);
      const totalDiff =
        (values?.totals?.total ?? 0) - (order.totals?.total ?? 0);

      if (ziroRes.cupo < totalDiff) {
        notifyWarning(t('ORDS400013'));
        dispatch({
          type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL,
          payload: false,
        });
        return;
      }
    }

    const newDetail = await validateCartQuantities(
      values.detail,
      order?.detail ?? [],
      cartDiff,
    );

    form.setFieldValue('detail', newDetail);
    const invalidStock = newDetail.some(
      (variant) =>
        variant.status &&
        ['LIMITED_STOCK', 'OUT_OF_STOCK'].includes(variant.status),
    );
    if (invalidStock) {
      notifyWarning(t('BOORDS005'));
      dispatch({
        type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL,
        payload: false,
      });
      return;
    }

    try {
      await updateOrder(values);
      goToList();
      notifySuccess('orderForm.modal.message.success');
    } catch (error) {
      const err = parsePandasErr(error, 'ORDU500000');
      notifyError(t(err.code));
      if (err.code === 'ORDS400018') {
        // Stock error modal open
        dispatch({
          type: ORDER_ACTION_ENUM.SET_OPEN_INSUFFICIENT_STOCK_MODAL,
          payload: true,
        });
        dispatch({
          type: ORDER_ACTION_ENUM.SET_INSUFFICIENT_STOCK_DETAILS,
          payload: err.detail ?? [],
        });
      }
    }
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL,
      payload: false,
    });
  }, [
    form,
    values,
    order?.detail,
    goToList,
    dispatch,
    notifySuccess,
    notifyWarning,
    updateOrder,
    notifyError,
  ]);

  // ======== EFFECTS =========== //
  useEffect(() => {
    if (deliveryRangeOptions.length) {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_DELIVERY_DATE_OPTIONS,
        payload: { deliveryRangeOptions, isAdmin },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryRangeOptions]);

  useEffect(() => {
    if (orderError) {
      goToList();
      notifyError(t(orderError.code));
    }
  }, [orderError, notifyError, goToList]);

  useEffect(() => {
    if (order) {
      const payment = { ...order.payment };
      const paymentMethods = Object.keys(payment) as PaymentOptionsKeys[];

      paymentMethods.forEach((method) => {
        payment[method] = {
          ...payment[method],
          use: true,
        };
      });

      const currOrder: Order = { ...order, payment };
      form.setFieldsValue(currOrder as any);
    }
  }, [order, form]);

  useEffect(() => {
    if (orderTotals) {
      form.setFieldValue('totals', orderTotals);
      form.setFieldValue('total', orderTotals.total);
      form.setFieldValue('subtotal', orderTotals.subtotal);

      const payment = { ...orderTotals.payment };
      const paymentMethods = Object.keys(payment ?? {}) as PaymentOptionsKeys[];
      paymentMethods.forEach((method) => {
        payment[method] = {
          ...payment[method],
          use: true,
        };
      });
      form.setFieldValue('payment', payment);

      dispatch({
        type: ORDER_ACTION_ENUM.SET_MAX_DISCOUNT,
        payload: orderTotals.subtotal,
      });
    }
  }, [orderTotals, form, dispatch]);

  useEffect(() => {
    if (order?.status === StatusOrderEnum.IN_PICKING) {
      form.setFieldValue(
        'picked',
        detailToPicking(order.detail, order?.picked),
      );
    }
  }, [order?.status, order?.detail, order?.picked, form]);
  useEffect(() => {
    if (order?.status === StatusOrderEnum.IN_TRANSIT) {
      form.setFieldValue(
        'delivered',
        detailToPicking(order.detail, order?.delivered),
      );
    }
  }, [order?.status, order?.detail, order?.picked, order?.delivered, form]);

  const handleDiscountValue = useCallback(
    (value: number) => {
      form.setFieldValue(
        'discount',
        ((orderTotals?.subtotal ?? 0) * value) / 100,
      );
    },
    [form, orderTotals],
  );

  const closeStockModal = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_INSUFFICIENT_STOCK_MODAL,
      payload: false,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_INSUFFICIENT_STOCK_DETAILS,
      payload: [],
    });
  }, [dispatch]);
  const onEditOrderDetail = useCallback(() => {
    if (canEditOrderDetail) {
      onEnableForm();
      closeStockModal();
      dispatch({
        type: ORDER_ACTION_ENUM.SET_DETAIL_EDITABLE_FOR_STOCK,
        payload: true,
      });
    }
  }, [canEditOrderDetail, onEnableForm, closeStockModal, dispatch]);

  const onViewRemission = useCallback((ordId: string) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/order-dom/orders/${ordId}/remission`,
    );
  }, []);
  const onViewDropshipperClientRemission = useCallback((ordId: string) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/order-dom/orders/${ordId}/drop-shipping/remission`,
    );
  }, []);
  const onViewPickingSheet = useCallback((ordId) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/order-dom/orders/${ordId}/picking-sheet`,
    );
  }, []);
  const goToWMSTransfers = useCallback(() => {
    window.open(ROUTES.transfers);
  }, []);

  if (!isLoadedGoogleScript) {
    return <div />;
  }

  return (
    <>
      <InsufficientStockModal
        open={state.insufficientStockModalOpen}
        details={state.insufficientStockDetails}
        goToWMSTransfers={goToWMSTransfers}
        onEditOrderDetail={onEditOrderDetail}
        canEditOrderDetail={canEditOrderDetail}
        closeStockModal={closeStockModal}
      />
      <CancelOrderModal
        open={state.cancelModalVisible}
        loading={loadingStatusUpdate}
        cancelReason={state.cancelReason ?? ''}
        orderNumber={values?.orderNumber}
        onOkCancel={handleStatusChange}
        reasonOptions={reasonOptions}
        onCancelCancel={closeStatusChangeModal}
        setCancelReason={setCancelReason}
      />
      <OrderConfirmationModal
        statusUpdate
        open={state.openUpdateStatusModal}
        loadingUpdate={loadingStatusUpdate}
        loadingTotals={false}
        updateComment={state.observations ?? ''}
        status={state.newStatus ?? StatusOrderEnum.RECEIVED}
        submit={handleStatusChange}
        cancelUpdate={closeStatusChangeModal}
        handleCommentChange={handleCommentChange}
        setReportMissing={setReportMissing}
      />
      <RescheduleOrderModal
        onCancelReschedule={onCancelReschedule}
        setRescheduleReason={setRescheduleReason}
        onOkReschedule={onOkReschedule}
        open={state.openRescheduleModal}
        rescheduleReason={state.rescheduleReason ?? ''}
        orderNumber={values?.orderNumber}
        reasonOptions={rescheduleReasonOptions}
      />
      <OrderConfirmationModal
        statusUpdate={false}
        open={state.openUpdateConfirmModal}
        loadingUpdate={loadingUpdate}
        loadingTotals={loadingTotals}
        updateComment={state.observations ?? ''}
        status={(order?.status as StatusOrderEnum) ?? StatusOrderEnum.RECEIVED}
        submit={submit}
        cancelUpdate={cancelUpdate}
        handleCommentChange={handleCommentChange}
        setReportMissing={setReportMissing}
      />
      <OrderForm
        isUpdate
        form={form}
        values={values}
        loadingOrder={loadingOrder}
        currentStatus={
          (order?.status as StatusOrderEnum) ?? StatusOrderEnum.RECEIVED
        }
        initialValues={order}
        disableForm={state.readOnly}
        disableMpsVariantAutocomplete={state.disableMpsVariantAutocomplete}
        maxDiscount={state.maxDiscount}
        isAdmin={isAdmin}
        handleDiscountValue={handleDiscountValue}
        deliveryDateOptions={state.deliveryRangeOptions.map(range2DateOption)}
        segmentationValues={segmentationValues}
        onChangeStatus={onChangeStatus}
        handleFormFinish={handleFormFinish}
        hasAccess={hasAccess}
        refetchOrder={refetchOrder}
        onEnableForm={onEnableForm}
        onGoToOrder={onGoToOrder}
        loadingPendingStockUpdate={loadingPendingStockUpdate}
        updatePendingStock={updatePendingStock}
        onViewDropshipperClientRemission={onViewDropshipperClientRemission}
        onViewRemission={onViewRemission}
        onViewPickingSheet={onViewPickingSheet}
        goToList={goToList}
        totals={orderTotals}
        loadingTotals={loadingTotals}
        loadingSubmit={loadingUpdate}
        canUpdatePendingStock={order?.canUpdatePendingStock}
        canUpdatePaymentStatus={userCanUpdatePayment}
        canUpdateInvoices={userCanUpdateInvoices}
        userCanUpdateOrder={userCanUpdateOrder}
        updatePaymentStatus={handlePaymentUpdate}
        loadingPaymentUpdate={loadingPaymentUpdate}
        handlePaymentStatusSelect={handlePaymentStatusSelect}
        userHasSkipPaymentPermission={userHasSkipPaymentPermission}
        skipShipping={skipShipping}
        isLoadingSkipShipping={isLoadingSkipShipping}
      />
    </>
  );
};

export default OrderUpdateContainer;
