import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  INITIAL_REDUCER,
  SuppliersAction,
  SuppliersReducer,
  SuppliersState,
} from '../reducer/supplier.reducer';

interface SupplierContextTypes {
  state: SuppliersState;
  t: (...args0: any) => string;
  dispatch: Dispatch<SuppliersAction>;
}

const SupplierContext = createContext<SupplierContextTypes>(
  {} as SupplierContextTypes,
);

export const SuppliersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(SuppliersReducer, INITIAL_REDUCER);
  const { t } = useTranslation();
  const value = useMemo(() => ({ state, dispatch, t }), [state, dispatch, t]);
  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
};

export const useSuppliers = () => useContext(SupplierContext);
