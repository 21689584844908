import { Package, QuantityVariant } from 'services/package/interface';
import { DeliveryRangeOption } from 'services/shipping/interface';

export enum PaymentUpdateStatus {
  CONFIRM = 'CONFIRM',
  DECLINE = 'DECLINE',
  TO_ON_DELIVERY = 'TO_ON_DELIVERY',
}

export enum ORDER_ACTIONS_ENUM {
  UPDATE_PAYMENT = 'UPDATE_PAYMENT',
  UPDATE_INVOICES = 'UPDATE_INVOICES',
  UPDATE_ORDER = 'UPDATE_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  SKIP_PAYMENT = 'SKIP_PAYMENT',
}

export interface IPackageForm extends Pick<Package, 'status'> {
  deliveryDate: string;
  products: QuantityVariant[];
  id: string;
  sellerId: string;
}

export interface PackageEditOptions {
  dates: DeliveryRangeOption[];
}
