/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import {
  VariantStockUpdateAction,
  VariantStockUpdateState,
  VARIANT_STOCK_UPDATE_ACTION_ENUM,
} from './interface';

export const INITIAL_VARIANT_STOCK_UPDATE_STATE: VariantStockUpdateState = {
  editionMode: false,
  stockInfo: {
    available: 0,
    availableInWarehouse: 0,
    block: 0,
    notAvailableInWarehouse: 0,
    cap: 0,
    inWarehouse: 0,
    sold: 0,
  },
  stockInitialValues: {
    available: 0,
    availableInWarehouse: 0,
    block: 0,
    notAvailableInWarehouse: 0,
    cap: 0,
    inWarehouse: 0,
    sold: 0,
  },
};

export function shippingZoneCreateReducer(
  state: VariantStockUpdateState,
  action: VariantStockUpdateAction,
): VariantStockUpdateState {
  switch (action.type) {
    case VARIANT_STOCK_UPDATE_ACTION_ENUM.SET_STOCK_INFO:
      return {
        ...state,
        stockInfo: action.payload,
      };
    case VARIANT_STOCK_UPDATE_ACTION_ENUM.TURN_EDITION_MODE:
      return {
        ...state,
        editionMode: action.payload,
      };
    case VARIANT_STOCK_UPDATE_ACTION_ENUM.SET_FORM_INITIAL_VALUE:
      return {
        ...state,
        stockInitialValues: action.payload,
      };
    default:
      return state;
  }
}
