/* eslint-disable react/jsx-curly-newline */
import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import MoveItem from 'assets/images/MoveItem.svg';
import TrashIcon from 'assets/images/Trash.svg';
import { TrashButton } from 'components/VariantVolDiscount/VariantVolDicount.sty';
import { buildDetailsTableFormValue } from 'helpers/utils/buildVariantComboParams';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableHandle } from 'react-sortable-hoc';
import { ReactSortable } from 'react-sortablejs';
import { KeyValueInputProps } from './interface';
import {
  CustomHandle,
  DetailsContainer,
  DetailsKey,
  DetailsTableButton,
  DetailsValue,
  KeyValueInputWrapper,
} from './KeyValueInput.sty';

const KeyValueInput = ({
  value,
  setFieldsValue,
  setDetailsTable,
}: KeyValueInputProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (form.getFieldValue('descriptionTable')) {
      setDetailsTable(form.getFieldValue('descriptionTable'));
    }
  }, [form, setDetailsTable]);

  useEffect(() => {
    if (value) {
      form.resetFields();
      form.setFieldsValue({
        descriptionTable: buildDetailsTableFormValue(value),
      });
    }
  }, [form, setFieldsValue, value]);

  const onValueChange = () => {
    const values = form.getFieldValue('descriptionTable');
    setDetailsTable(values);
  };

  const Handle = SortableHandle(() => (
    <CustomHandle title={t('variantForm.labels.helpText')}>
      <img src={MoveItem} alt="icon" />
    </CustomHandle>
  ));

  return (
    <KeyValueInputWrapper>
      <DetailsContainer>
        <Form
          name="dynamic_form_nest_item"
          form={form}
          style={{ width: ' 100%' }}
          onValuesChange={onValueChange}
          autoComplete="off"
        >
          <Form.List name="descriptionTable">
            {(fields, { add, remove, move }) => (
              <DetailsContainer>
                <ReactSortable
                  list={form.getFieldValue('descriptionTable') ?? []}
                  setList={() => setDetailsTable}
                  onEnd={({ newIndex, oldIndex }) => {
                    move(oldIndex, newIndex);
                  }}
                >
                  {fields.map((field) => (
                    <div
                      key={field.key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                        width: '100%',
                        gap: 12,
                      }}
                    >
                      <Handle />
                      <Form.Item
                        label={t('variantForm.labels.key')}
                        name={[field.name, 'key']}
                        style={{ width: 340, maxWidth: '40%' }}
                      >
                        <DetailsKey
                          placeholder={t('variantForm.labels.insertValue')}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: 340, maxWidth: '40%' }}
                        label={t('variantForm.labels.value')}
                        name={[field.name, 'value']}
                      >
                        <DetailsValue
                          placeholder={t('variantForm.labels.insertValue')}
                        />
                      </Form.Item>
                      {field.name !== 0 ? (
                        <div>
                          <TrashButton
                            src={TrashIcon}
                            alt="Trash"
                            onClick={() => remove(field.name)}
                            onKeyUp={undefined}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </ReactSortable>
                <Form.Item>
                  <DetailsTableButton
                    type="default"
                    style={{ width: ' 80%', maxWidth: '840px' }}
                    onClick={() => add('')}
                    block
                    color="secundary"
                    icon={<PlusOutlined style={{ color: '#1ACEC3' }} />}
                  >
                    {t('variantForm.labels.addMore')}
                  </DetailsTableButton>
                </Form.Item>
              </DetailsContainer>
            )}
          </Form.List>
        </Form>
      </DetailsContainer>
    </KeyValueInputWrapper>
  );
};

export default KeyValueInput;
