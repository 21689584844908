/* eslint-disable no-underscore-dangle */
import { ITransferUnits, TransferUnitsDTO } from 'services/transfers/interface';

export function ITransferToDTO(item: ITransferUnits): TransferUnitsDTO {
  return {
    quantity: item.quantity,
    status: item.status,
    variantId: item.variantId,
    originLocation: item.initialLocation?._id ?? '',
    targetLocation: item.finalLocation?._id ?? '',
  };
}
