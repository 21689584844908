import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalContextProvider from 'context/global';
import Router from '../Route/Route';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <Router />
      </GlobalContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
