import { Col, Input } from 'antd';
import styled from 'styled-components';
import { LightTheme, spaceSizes } from 'helpers/constants/theme';

const { Search } = Input;

export const ButtonCol = styled(Col)`
  @media (${LightTheme.queries.xs}) {
    margin-top: ${spaceSizes.s};
  }
`;

export const SearchInput = styled(Search)`
  max-width: 400px;
  @media (${LightTheme.queries.xs}) {
    max-width: 100%;
  }
`;
