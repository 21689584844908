import { notification } from 'antd';
import RoutesCsvInput from 'containers/Routes/RoutesCsvInput/RoutesCsvInput';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CreateRoutesMassive = () => {
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.routesCsv}`);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);
  return <RoutesCsvInput />;
};

export default CreateRoutesMassive;
