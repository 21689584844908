/* eslint-disable react/jsx-props-no-spreading */
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Col, Row, Skeleton } from 'antd';
import BannerCardItem from 'components/BannerCardItem/BannerCardItem';
import BannerForm from 'components/BannerForm/BannerForm';
import BannerSort from 'components/BannerSort/BannerSort';
import { LayoutContainer, Title } from 'pages/App/Layout.sty';
import { useTranslation } from 'react-i18next';
import {
  BannerContainerLayout,
  BannerContainerModal,
  CreateBannerCard,
  CreateBannerIcon,
  CreateBannerLabel,
} from './BannerContainer.sty';
import { bannerColSizes } from './constants';
import useBannerContext from './context/useBannerContext';
import { BannerModalTypeEnum } from './interface';

const BannerContainer = () => {
  const {
    form,
    banners,
    isLoading,
    updateLoading,
    modalVisible,
    modalType,
    bannerFormInitialValues,
    draftBanners,
    selectedBanner,
    onBannerDelete,
    closeModal,
    openCreateModal,
    openEditModal,
    openOrderModal,
    formAction,
    setDraftBanners,
    onModalOk,
  } = useBannerContext();

  const [parent] = useAutoAnimate();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Skeleton loading active avatar>
        <LayoutContainer />
      </Skeleton>
    );
  }

  return (
    <>
      <Title level={1}>{t('banners.title')}</Title>
      {/* modals */}
      <BannerContainerModal
        open={modalVisible}
        onCancel={closeModal}
        onOk={onModalOk}
        title={t(`banners.form.titles.${modalType}`)}
        destroyOnClose
        maskClosable={!updateLoading}
        confirmLoading={updateLoading}
      >
        {modalType === BannerModalTypeEnum.order ? (
          <BannerSort
            defaultSelectedId={selectedBanner?.id ?? ''}
            items={draftBanners}
            setItems={setDraftBanners}
          />
        ) : (
          <BannerForm
            initialValues={bannerFormInitialValues}
            form={form}
            onSubmit={formAction}
          />
        )}
      </BannerContainerModal>
      {/* View */}
      <BannerContainerLayout>
        <Row gutter={[24, 24]} ref={parent}>
          <Col {...bannerColSizes}>
            <CreateBannerCard bordered hoverable onClick={openCreateModal}>
              <CreateBannerIcon />
              <CreateBannerLabel>
                {t('banners.labels.create')}
              </CreateBannerLabel>
            </CreateBannerCard>
          </Col>
          {banners.map((banner) => (
            <BannerCardItem
              banner={banner}
              key={`${banner.id}-${banner.image}`}
              onBannerDelete={onBannerDelete}
              onBannerEdit={openEditModal}
              onBannerOrder={openOrderModal}
            />
          ))}
        </Row>
      </BannerContainerLayout>
    </>
  );
};

export default BannerContainer;
