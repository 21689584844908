import { Col, Row, Tag } from 'antd';
import { numberToCurrency } from 'helpers/utils/currency';
import { t } from 'i18next';
import { CART_ITEM_COLORS } from 'services/cart/interface';
import { Variant, VariantStatus } from 'services/product/interface';
import {
  ItemLabel,
  ItemParagraph,
  ProductFigure,
} from './AutoCompleteVariantItem.sty';

const AutoCompleteVariantItem = ({ variant }: { variant: Variant }) => (
  <Row gutter={[16, 8]}>
    <Col span={3}>
      <ProductFigure>
        <img
          src={
            variant.images?.length
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${variant.images[0].url}`
              : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
          }
          alt={variant.name}
        />
      </ProductFigure>
    </Col>
    <Col span={3}>
      {(variant.stock?.available ?? 0) === 0 ? (
        <Tag color={CART_ITEM_COLORS.OUT_OF_STOCK}>
          {t(`stockStatus.OUT_OF_STOCK`)}
        </Tag>
      ) : (
        <Tag color={CART_ITEM_COLORS[variant.status as VariantStatus]}>
          {t(`stockStatus.${variant.status}`)}
        </Tag>
      )}
    </Col>
    <Col span={15}>
      <ItemParagraph>{variant.name}</ItemParagraph>
      <ItemParagraph>
        <ItemLabel>Precio:&nbsp;</ItemLabel>
        {numberToCurrency(Number(variant.price))}
      </ItemParagraph>
    </Col>
  </Row>
);
export default AutoCompleteVariantItem;
