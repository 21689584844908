/* eslint-disable @typescript-eslint/no-explicit-any */
export enum RuleComparatorsEnum {
  IN = 'in',
  EQ = 'eq',
  NOT_IN = 'not-in',
  NOT_EQ = 'not-eq',
}

export enum RuleTypeEnum {
  STR = 'string',
  STR_ARR = 'string-array',
}

export enum WmsSettingEnum {
  NO_SETTING = 'no-setting',
  SALE_ORDER_RETURN_LOCATIONS = 'SALE_ORDER_RETURN_LOCATIONS',
  MANUAL_STOCK_UPDATE_LOCATION = 'MANUAL_STOCK_UPDATE_LOCATION',
}

export interface WmsSetting<T = any> {
  _id: string;
  allowedRuleProps: AllowedRuleProp[];
  behaviors: Behavior<T>[];
  setting: WmsSettingEnum;
  default: RuleOption<any>;
  otherAllowedValues: RuleOption<any>[];
}

export interface Behavior<T = any> {
  ruleSet: RuleDefinition[];
  value: T;
  label: string;
}

export interface IBehaviorForm<T = any> {
  rules: Record<string, RuleDefinition>;
  value: {
    label: string;
    value: T;
  };
}

export type IWmsSettingForm = Pick<WmsSetting, 'behaviors' | 'default'>;

export interface RuleDefinition {
  name: string;
  field: string;
  evaluator: string | string[];
  evaluatorType: RuleTypeEnum;
  evaluation: RuleComparatorsEnum;
}

export interface AllowedRuleProp {
  name: string;
  field: string;
  comparators: RuleComparatorsEnum[];
  options: RuleOption[];
  type: RuleTypeEnum;
}

export interface RuleOption<T = string> {
  value: T;
  label: string;
}
