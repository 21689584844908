import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  OrderPackageAction,
  OrderPackageState,
} from '../reducer/interface/order-package';
import {
  INITIAL_ORDER_PACKAGE_REDUCER,
  orderPackageReducer,
} from '../reducer/order-package.reducer';

export interface OrderPackageContextTypes {
  state: OrderPackageState;
  dispatch: Dispatch<OrderPackageAction>;
}

const OrderPackageContext = createContext<OrderPackageContextTypes>(
  {} as OrderPackageContextTypes,
);

export const OrderPackageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    orderPackageReducer,
    INITIAL_ORDER_PACKAGE_REDUCER,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <OrderPackageContext.Provider value={value}>
      {children}
    </OrderPackageContext.Provider>
  );
};

export const useOrderPackage = () => useContext(OrderPackageContext);
