import { TFunction } from 'i18next';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import {
  PackageStatusEnum,
  PackageStatusOptions,
} from 'services/package/interface';

export function packageStatusToOptions(
  status: PackageStatusEnum,
  t: TFunction,
): LabelValue<PackageStatusEnum>[] {
  const options = PackageStatusOptions[status];

  return options.map(
    (opt): LabelValue<PackageStatusEnum> => ({
      label: t(`package.status.${opt}`),
      value: opt,
    }),
  );
}
