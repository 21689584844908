import { EyeFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { t } from 'i18next';
import { Variant } from 'services/product/interface';

export function LocationsColumn(onSeeLocations: (variant: Variant) => void) {
  return {
    title: t('productTableKeys.stockValues.locations'),
    render: (variant: Variant) => (
      <Button onClick={() => onSeeLocations(variant)}>
        <EyeFilled />
      </Button>
    ),
  };
}

export default LocationsColumn;
