import { Haulier } from 'services/haulier/interface';

export enum HaulierModalAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface HaulierFormValues {
  _id?: string;
  status: HaulierStatusEnum;
  haulierNumber: string;
  name: string;
  phone: string;
  carType: string;
  licensePlate: string;
  company: string;
}

export const HaulierFormInitialValues: HaulierFormValues = {
  _id: '',
  status: 'ACTIVE' as HaulierStatusEnum,
  haulierNumber: '',
  name: '',
  phone: '',
  carType: '',
  licensePlate: '',
  company: '',
};

export interface HaulierFunctions {
  setFormReference?: (arg0: any) => void;
  actionType?: HaulierModalAction;
}

export interface HaulierFormProps extends HaulierFunctions {
  initialValues?: Haulier;
  closeModal?: () => any;
  reLoadOnSuccess?: () => void;
}

export interface HaulierFormContainerProps {
  children: JSX.Element | JSX.Element[] | string;
  haulierNumber?: string;
}

export enum HaulierStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
