/* eslint-disable react/jsx-props-no-spreading */
import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { RefObject } from 'react';
import { Store } from 'services/store/interface';

import useStoresTable from './hooks/useStoresTable';

const TABLE_HEADER_HEIGHT = 160;

export const StoresTable = ({
  data,
  isLoading,
  onScrollToEnd,
  onUpdateStore,
  reachedEndOfData = false,
  tableRef,
}: {
  data: Store[];
  isLoading: boolean;
  onUpdateStore: (store: Store) => void;
  onScrollToEnd: (params?: any) => void;
  reachedEndOfData: boolean;
  tableRef: RefObject<{ scrollToTop: () => void }>;
}) => {
  const { columns } = useStoresTable({
    onUpdateStore,
  });

  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="store-table"
      isLoading={isLoading}
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={data || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};
