import { valueType } from 'components/MultiSearchSelect/interface';
import { ShippingZonesActionEnum } from 'config/Constants/actions';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'helpers/constants/http';

export interface GeoLocation {
  latitude?: string;
  longitude?: string;
  centerLocation: string;
}

export enum DefaultCitiesValues {
  countryCode = 'COL',
  countryName = 'COLOMBIA',
}

export enum RegionalShippingCostType {
  VARIABLE = 'VARIABLE',
  FIXED = 'FIXED',
}

export enum CityStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum RegionalEnum {
  valle = 'valle',
  Servientrega = 'Servientrega',
  Antioquia = 'Antioquia',
  Costa = 'Costa',
  Ibague = 'Ibague',
  Santander = 'Santander',
  Bogota = 'Bogota',
}
export interface zoneCity {
  code: string;
  name: string;
}

export enum ZoneStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Transport {
  _id: string;
  name: string;
  additionalPackageCost: number;
  minimumVolumeWeight: number;
  convertionFactor: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface Columns {
  title: string;
  dataIndex: string | string[];
  width: string;
  render?: (value: any, _: any) => any;
}

export interface costOperator {
  operator: 'PERCENTAGE' | 'NOMINAL';
  value: number;
}

export interface ShippingZones {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  status: ZoneStatusEnum;
  name: string;
  freeShippingLimit: number;
  leadTime: number;
  maxWarrantyPrice: number;
  minimumOrderAmount: number;
  regional?: RegionalEnum;
  regionalConfig: RegionalConfig;
  transport: Transport;
  zoneCity: City[];
  shippingCost: number;
  deliveryDays: number[];
  costTakings: number;
  isFreeCollectionCost: boolean;
  mediumMille: boolean;
  freeShippingEnabled: boolean;
  shippingSubsidy: costOperator;
  earnCost: costOperator;
}

export interface Regional {
  id: string;
  value: string;
}
export interface holyDay {
  label: string;
  date: string;
}
export interface RegionalConfig {
  regional: Regional;
  holyDays: holyDay[];
  deliveryDaysLimit?: number;
  shippingCostType: RegionalShippingCostType;
}

export interface City {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  name: string;
  status: string;
  department: string;
  departmentCode: string;
  code: string;
  countryCode?: string;
  countryName?: string;
  freeShippingLimit: number;
  leadTime: number;
  ranking?: number;
  maxWarrantyPrice: number;
  minimumOrderAmount: number;
  shippingCost: number;
  deliveryDays: number[];
  isPrincipal?: boolean;
  geoLocation: GeoLocation;
  regional: RegionalEnum;
  transport: Transport;
  siigoCode: string;
}

export enum ShippingZonesViewEnum {
  UPDATE = 'update',
  CREATE = 'create',
  LIST = 'list',
}

export interface ShippingZonesState {
  ShippingZoneList: ShippingZones[];
  selectedShippingZone: ShippingZones;
  currentView: ShippingZonesViewEnum;
  regionalList: Regional[];
  transportList: Transport[];
  search: string;
  citiesOptions: valueType[];
  citySearch: string;
  departmentCode: string;
  pagination: InfiniteScrollPagination;
  selectedRegional: string;
  holyDays: holyDay[];
}

export interface InfiniteScrollPagination {
  page: number;
  pageSize: number;
  hasNext: boolean;
}

export const initialShippingZoneState: ShippingZonesState = {
  ShippingZoneList: [],
  regionalList: [],
  holyDays: [{ date: '2023-12-12', label: '12 dic 2023' }],
  pagination: {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    hasNext: true,
  },
  selectedRegional: '',
  citiesOptions: [
    {
      label: '',
      value: '',
    },
  ],
  transportList: [
    {
      _id: 'ID',
      additionalPackageCost: 0,
      minimumVolumeWeight: 0,
      convertionFactor: 0,
      name: '1',
    },
  ],
  citySearch: '',
  selectedShippingZone: {
    _id: '',
    name: '',
    status: ZoneStatusEnum.ACTIVE,
    freeShippingLimit: 0,
    leadTime: 0,
    maxWarrantyPrice: 0,
    minimumOrderAmount: 0,
    shippingCost: 0,
    deliveryDays: [],
    isFreeCollectionCost: true,
    mediumMille: false,
    regionalConfig: {
      regional: { id: '', value: '' },
      holyDays: [{ date: '', label: '' }],
      shippingCostType: RegionalShippingCostType.VARIABLE,
    },
    regional: RegionalEnum.Bogota,
    transport: {
      _id: 'ID',
      additionalPackageCost: 0,
      minimumVolumeWeight: 0,
      convertionFactor: 0,
      name: '1',
    },
    zoneCity: [],
    costTakings: 0,
    freeShippingEnabled: false,
    earnCost: {
      operator: 'NOMINAL',
      value: 0,
    },
    shippingSubsidy: {
      operator: 'NOMINAL',
      value: 0,
    },
  },
  currentView: ShippingZonesViewEnum.LIST,
  search: '',
  departmentCode: '',
};

export type ShippingZonesAction = {
  type: ShippingZonesActionEnum;
  payload: any;
};

export interface GetShippingRegionalConfigParams {
  id: string;
}
