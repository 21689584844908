import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Col, Radio, Row, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadProps } from 'antd/lib/upload/interface';
import { BoldBrandParagraph } from 'components/VariantForm/VariantForm.sty';
import { ButtonCol } from 'containers/Categories/CategoryForm/CategoryForm.sty';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import { parse } from 'papaparse';
import { useCallback, useMemo } from 'react';
import { UserSegType } from 'services/coupons/interface';
import { DownloadButton, FormItem } from './CouponModal.sty';
import { requiredRule } from './CouponValidations.schema';
import { CouponUserSegSubFormProps } from './interface';

export const CouponUserSegSubForm = ({
  isUpdate,
  readOnly,
  isUserSegmented,
  form,
  userIds = [],
}: CouponUserSegSubFormProps) => {
  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  const { setFieldsValue, getFieldValue } = form;
  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        notifyError(t('variantsCsvInput.message.extensionError'));
      }
      return isCSV || Upload.LIST_IGNORE;
    },
    accept: '.csv',
    multiple: false,
    maxCount: 1,
  };

  const onChange = useCallback(
    async ({ file }: UploadChangeParam) => {
      const buffer = await file.originFileObj?.text();
      const parsed = parse(buffer?.trim() ?? '');
      const data = parsed.data ? parsed.data[0] : [];
      setFieldsValue({ targetUsers: data });
    },
    [setFieldsValue],
  );

  const showDragger = useMemo(
    () => isUserSegmented && !readOnly && !isUpdate,
    [isUserSegmented, readOnly, isUpdate],
  );

  const showCSVDownload = useMemo(
    () => isUpdate && isUserSegmented && (userIds ?? []).length > 0,
    [isUpdate, isUserSegmented, userIds],
  );

  const getUserSegType = useCallback(() => {
    const fieldValue = getFieldValue?.('userSegmentation');
    if (fieldValue === UserSegType.INCLUDED) {
      return t('couponUserSegType.INCLUDED');
    }
    if (fieldValue === UserSegType.EXCLUDED) {
      return t('couponUserSegType.EXCLUDED');
    }
    return t('couponUserSegType.NONE');
  }, [getFieldValue]);

  const onDownloadUsers = useCallback(() => {
    const csv = userIds?.reduce((prev, curr) => `${prev}${curr},`, '');

    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'userIds.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [userIds]);

  const customRequest = useCallback(({ onSuccess }) => {
    onSuccess?.('');
  }, []);

  return (
    <Row gutter={[16, 8]}>
      <Col xs={24} md={isUpdate ? 12 : 36}>
        <FormItem
          label={t('couponModal.form.labels.userSegmentationType')}
          name="userSegmentation"
          rules={[requiredRule]}
          initialValue={UserSegType.NONE}
          labelAlign="left"
        >
          {readOnly || isUpdate ? (
            getUserSegType()
          ) : (
            <Radio.Group>
              <Radio value={UserSegType.NONE} defaultChecked>
                {t('couponUserSegType.NONE')}
              </Radio>
              <Radio value={UserSegType.INCLUDED}>
                {t('couponUserSegType.INCLUDED')}
              </Radio>
              <Radio value={UserSegType.EXCLUDED}>
                {t('couponUserSegType.EXCLUDED')}
              </Radio>
            </Radio.Group>
          )}
        </FormItem>
        {showDragger ? (
          <FormItem name="usersFile" noStyle>
            <Upload.Dragger
              beforeUpload={uploadProps.beforeUpload}
              accept={uploadProps.accept}
              multiple={uploadProps.multiple}
              onChange={onChange}
              customRequest={customRequest}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t('upload.uploadSegmentedUsers')}
              </p>
              <p className="ant-upload-hint">
                {t('upload.uploadSegmentedUsersHint')}
              </p>
            </Upload.Dragger>
          </FormItem>
        ) : null}
        {showCSVDownload ? (
          <ButtonCol>
            <DownloadButton onClick={onDownloadUsers}>
              <DownloadOutlined />
              <BoldBrandParagraph>
                {t('couponModal.buttonTitles.downloadFile')}
              </BoldBrandParagraph>
            </DownloadButton>
          </ButtonCol>
        ) : null}
        <FormItem hidden name="targetUsers" />
      </Col>
    </Row>
  );
};
