/* eslint-disable no-param-reassign */
import { subMonths } from 'date-fns';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { MPS } from 'services/MPS/interface';
import { PendingStockOrderMps, Purchase, PurchaseStatus } from './interface';

export const createPurchase = async (purchase: Purchase): Promise<Purchase> => {
  purchase.detail.forEach((variant) => {
    delete variant.values.ondemandPrice;
  });

  const response = await getAPIMethod({
    name: 'createPurchase',
    data: purchase,
  });
  return response.data;
};

export const getPurchaseList = async (queryParams?: {
  search: string;
  status?: PurchaseStatus;
  page: number;
  pageSize: number;
}): Promise<PaginatedResponse<Purchase>> => {
  const response = await getAPIMethod({
    name: 'getPurchaseList',
    params: {
      search: queryParams?.search,
      status: queryParams?.status,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const getPurchaseById = async (
  purchaseId: string,
): Promise<Purchase> => {
  const response = await getAPIMethod({
    name: 'getPurchaseById',
    pathParams: {
      purchaseId,
    },
  });
  return response.data;
};

export const updatePurchase = async (
  purchase: Partial<Purchase>,
): Promise<Purchase> => {
  (purchase?.detail ?? []).forEach((variant) => {
    delete variant.values.ondemandPrice;
  });

  const response = await getAPIMethod({
    name: 'updatePurchase',
    data: purchase,
    pathParams: { purchaseId: purchase.id ?? '' },
  });

  return response.data;
};

export const getPurchasesByMps = async (
  mpsId: string,
): Promise<PendingStockOrderMps[]> => {
  const now = new Date();
  const oneMonthAgo = subMonths(now, 12);

  const response = await getAPIMethod({
    name: 'getPurchasesByMps',
    params: {
      startDate: oneMonthAgo.toISOString(),
      endDate: now.toISOString(),
      mpsIds: mpsId,
    },
  });
  return response.data;
};

export const getStockPendingMps = async (): Promise<MPS[]> => {
  const now = new Date();
  const oneMonthAgo = subMonths(now, 12);

  const response = await getAPIMethod({
    name: 'getStockPendingMps',
    params: {
      startDate: oneMonthAgo.toISOString(),
      endDate: now.toISOString(),
    },
  });
  return response.data;
};
