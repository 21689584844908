import { Tag } from 'antd';
import { MovementCardTitle } from 'components/UnitMovements/UnitMovementItem.sty';
import { useTranslation } from 'react-i18next';
import { LocationListDescriptionProps } from './interface';

const LocationListDescription = ({ item }: LocationListDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MovementCardTitle>
        {t('location.modal.id', {
          id: item?.locationId,
        })}
      </MovementCardTitle>
      <MovementCardTitle>
        {t('wms.locations.labels.properties')}
        {item?.properties?.map((prop) => (
          <Tag key={`${prop}-${item.locationId}`} color="blue">
            {prop}
          </Tag>
        ))}
      </MovementCardTitle>
      <MovementCardTitle>
        {t('location.modal.quantity', {
          quantity: item.quantity,
        })}
      </MovementCardTitle>
    </>
  );
};

export default LocationListDescription;
