/* eslint-disable @typescript-eslint/no-unused-vars */
import config from 'config';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'helpers/constants/http';
import { DEFAULT_COLOMBIAN_DEPARTMENTS } from 'helpers/constants/Tags';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { getCoordinates } from 'helpers/utils/getCoordinates';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getCitiesOptions } from 'services/cities/cities.service';
import { City, Department, LocationValue, SearchOptions } from '../interface';

const useLocationInput = (
  value: LocationValue,
  addressSelectedState: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ],
  onLoadMap?: (map: google.maps.Map) => void,
  onChange?: ((value: LocationValue) => void) | undefined,
) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string>(value.address ?? '');
  const [details, setDetails] = useState<string>(value.details ?? '');
  const [cityCode, setCityCode] = useState<string | undefined>(value.cityCode);
  const [departmentCode, setDepartmentCode] = useState<string | undefined>(
    value.departmentCode,
  );
  const [departmentName, setDepartmentName] = useState<string | undefined>(
    value.departmentName,
  );
  const [cityName, setCityName] = useState<string | undefined>(
    value.cityName ?? '',
  );
  const [searchOptions, setSearchOptions] = useState<SearchOptions>();
  const [coordinates, setCoordinates] = useState<google.maps.LatLngLiteral>(
    getCoordinates({
      latitude: value.latitude,
      longitude: value.longitude,
    }),
  );
  const [departments, setDepartments] = useState<Department[]>(
    [] as Department[],
  );
  const [cities, setCities] = useState<City[]>([]);
  const [addressSelected, setAddressSelected] = addressSelectedState;
  const mapRef = useRef<google.maps.Map>();
  const {
    readQuery: { mutateAsync: getParameters },
  } = useModuleCRUD('parameters');

  const customMessage = {
    error: {
      message: 'userForm.modal.actions.error',
      description: 'userForm.errorMessages.badLocation',
    },
  };
  const { notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const handleMapLoad = useCallback((map: google.maps.Map) => {
    onLoadMap?.(map);
    mapRef.current = map;
    if (mapRef.current) {
      mapRef.current.setZoom(16);
      mapRef.current.setCenter(
        getCoordinates({
          latitude: value.latitude,
          longitude: value.longitude,
        }),
      );
    }
  }, []);

  const mapOptions = useMemo(
    () => ({
      mapId: config.googleMapsConfig.mapId.addressMap,
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    [],
  );

  const triggerChange = (changedValue: LocationValue) => {
    onChange?.({
      address,
      details,
      cityCode,
      cityName,
      departmentCode,
      departmentName,
      latitude: String(coordinates?.lat) || '',
      longitude: String(coordinates?.lng) || '',
      ...value,
      ...changedValue,
    });
  };

  const fetchDepartments = useCallback(async () => {
    const departmentResponse = await getParameters({
      keys: DEFAULT_COLOMBIAN_DEPARTMENTS,
    });

    setDepartments(departmentResponse[DEFAULT_COLOMBIAN_DEPARTMENTS].data);
  }, [setDepartments, getParameters]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  const handleSetCity = useCallback(async (departCode: string) => {
    const response = await getCitiesOptions({
      departmentCode: departCode,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    setCities(response.data);
  }, []);

  useEffect(() => {
    if (cityName && cities) {
      const matchCity = cities.find((city: City) => city.name === cityName);
      if (matchCity?.centerLocation) {
        setSearchOptions({
          location: new google.maps.LatLng(matchCity.centerLocation),
          componentRestrictions: {
            country: matchCity.countryCode.toLowerCase(),
          },
          radius: matchCity.searchRadius || config.googleMapsConfig.placesRange,
        });
      }
    }
  }, [cityName, cities, setSearchOptions, value]);

  const setEmptyAddress = () => {
    setAddress('');
    triggerChange({ address: '' });
  };

  const handleSelectAddressOption = async (addressValue: string) => {
    if (!cityName || addressValue === '') {
      notifyError('BOORDS015');
      setEmptyAddress();
      return;
    }

    try {
      const geolocationResults = await geocodeByAddress(
        addressValue + cityName,
      );
      const addressCoords = await getLatLng(geolocationResults[0]);

      setCoordinates(addressCoords);
      setAddress(geolocationResults[0].formatted_address);
      triggerChange({
        address: geolocationResults[0].formatted_address,
        latitude: String(addressCoords?.lat) || '',
        longitude: String(addressCoords?.lng) || '',
      });
      setAddressSelected(true);
      if (mapRef?.current) {
        mapRef.current.setZoom(16);
        mapRef.current.setCenter(addressCoords);
      }
    } catch (error) {
      setEmptyAddress();
      notifyError();
    }
  };

  const handleAddressValueChange = (inputValue: string) => {
    triggerChange({ address: inputValue });
  };

  const handleCityChange = (cityValue: string) => {
    const city = cities.find((fil) => fil.name === cityValue);
    setCityCode(city?.code);
    setCityName(cityValue);
    triggerChange({
      cityCode: city?.code,
      cityName: cityValue,
      departmentCode,
      departmentName,
    });
  };

  const handleDepartmentSelect = (val: string) => {
    const department = departments.find((dep) => dep.name === val);
    setDepartmentName(val);
    setDepartmentCode(department?.code);
    handleSetCity(department?.code ?? '');
  };

  return {
    addressSelected,
    departmentName,
    searchOptions,
    departments,
    mapOptions,
    cityName,
    cities,
    mapRef,
    handleSelectAddressOption,
    handleAddressValueChange,
    handleDepartmentSelect,
    handleCityChange,
    setCoordinates,
    handleMapLoad,
    triggerChange,
    setDetails,
  };
};

export default useLocationInput;
