/* eslint-disable no-underscore-dangle */
import { QueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { BillingType } from 'services/product/interface';
import { MPS } from './interface';

export const getMpsList = async (
  queryParams?: QueryParams & {
    isSupplier?: boolean;
    billingType?: BillingType;
  },
): Promise<PaginatedResponse<MPS>> => {
  const body = {
    search: queryParams?.search,
    page: queryParams?.pagination?.page,
    pageSize: queryParams?.pagination?.pageSize,
    isSupplier: queryParams?.isSupplier,
    billingType: queryParams?.billingType,
  };

  if (queryParams?.isSupplier !== undefined) {
    body.isSupplier = queryParams?.isSupplier;
  }

  const response = await getAPIMethod({
    name: 'getMpsList',
    params: body,
  });
  return response.data;
};

const getMpsByType = async (mpsType: string) => {
  const response = await getAPIMethod({
    name: 'getMpsByType',
    params: { type: mpsType },
  });
  return response.data;
};

export const createMps = async (data: MPS) => {
  const response = await getAPIMethod({
    name: 'createMps',
    data,
  });
  return response.data;
};

export const updateMps = async (data: MPS) => {
  const response = await getAPIMethod({
    name: 'updateMps',
    pathParams: { mpsId: data._id },
    data,
  });
  return response.data;
};

export default {
  read: getMpsList,
  create: createMps,
  update: async () => ({}),
  delete: async () => ({}),
  readByParam: getMpsByType,
};
