import { Divider } from 'antd';
import { TableWrapper } from 'components/TableWrapper';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { TFunction } from 'i18next';
import { SupplierTable } from 'modules/Suppliers/components/SupplierTable';
import { Title } from 'pages/App/Layout.sty';
import { MPS } from 'services/MPS/interface';

interface SupplierListProps {
  supplierList: MPS[];
  isLoadingList: boolean;
  totalData: number;
  pagination: PaginationParams;
  onUpdateSupplier: (supplier: MPS) => void;
  onSetPagination: (paginationData: PaginationParams) => void;
  onChangeSearch: (search: string) => void;
  handleChangeSupplierFilter: (value: any) => void;
  handleChangeBillingTypeFilter: (value: any) => void;
  goToCreate: () => void;
  t: TFunction;
}

const SupplierList = ({
  supplierList,
  isLoadingList,
  totalData,
  pagination,
  onUpdateSupplier,
  onSetPagination,
  onChangeSearch,
  goToCreate,
  handleChangeSupplierFilter,
  handleChangeBillingTypeFilter,
  t,
}: SupplierListProps) => {
  return (
    <>
      <Title level={1}>{t('menu.supplierList')}</Title>
      <Divider />
      <TableWrapper>
        <SupplierTable
          data={supplierList || []}
          totalData={totalData}
          isLoading={isLoadingList}
          pagination={pagination}
          onUpdateSupplier={onUpdateSupplier}
          onSetPagination={onSetPagination}
          onChangeSearch={onChangeSearch}
          handleChangeSupplierFilter={handleChangeSupplierFilter}
          handleChangeBillingTypeFilter={handleChangeBillingTypeFilter}
          goToCreate={goToCreate}
          t={t}
        />
      </TableWrapper>
    </>
  );
};

export default SupplierList;
