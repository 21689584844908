import { Button, Col, InputNumber } from 'antd';
import styled from 'styled-components';

export const TransferCardWrapper = styled.div`
  display: block;
`;

export const TransferThumbnailWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const TransferQuantityInput = styled(InputNumber)`
  height: 40px;
  width: 100%;
`;

export const TransferInputLabel = styled.p`
  margin: 0;
`;

export const TransferDataWrapper = styled(Col)`
  display: flex;
  align-items: end;
`;

export const TransferDeleteButton = styled(Button)`
  height: 40px;
`;
