import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { CitiesListProps } from 'modules/ShippingZones/interface';
import { ShippingZones } from 'services/cities/interface';

const TABLE_HEADER_HEIGHT = 160;

export const ShippingZonesList = ({
  list,
  tableRef,
  loading,
  columns,
  reachedEndOfData,
  onScrollToEnd,
  onViewDetail,
}: CitiesListProps) => {
  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="cities-table"
      isLoading={loading}
      columns={columns}
      rowKey="name"
      onRow={(record) => ({
        onClick: () => {
          onViewDetail(record as ShippingZones);
        },
      })}
      pagination={false}
      dataSource={list || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};
