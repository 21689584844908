import OrderListContainer from 'modules/Order/containers/OrderListContainer';
import { OrderProvider } from 'modules/Order/context/Order.context';

export const OrderList = () => {
  return (
    <OrderProvider>
      <OrderListContainer />
    </OrderProvider>
  );
};
