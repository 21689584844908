import { useEffect, useRef, useState } from 'react';

export interface TableSizeProps {
  ref: React.RefObject<HTMLDivElement>;
  scroll: { y: number };
}

const useTableSize = (padding: number): TableSizeProps => {
  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      const { top } = node.getBoundingClientRect();
      const tableHeightValue = window.innerHeight - top - padding;
      setTableHeight(tableHeightValue);
    }
  }, [padding, ref]);
  return {
    ref,
    scroll: { y: tableHeight },
  };
};

export default useTableSize;
