/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { TFunction, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tag } from 'antd';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import TableActions from 'components/TableActions/TableActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Store } from 'services/store/interface';
import { useRecoilValue } from 'recoil';
import { storeTypesAtom } from 'helpers/recoil/atoms/stores/storeTypes';
import { StoreType } from 'containers/Stores/StoreFormModal/interface';
import { StoreTypeText } from '../StoresContentList.sty';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  StoreActions: (store: Store) => JSX.Element,
  storeTypes: StoreType[],
) => [
  {
    key: 'id',
    title: t('store.labels.id'),
    dataIndex: 'id',
    width: '140px',
  },
  {
    key: 'name',
    title: t('store.labels.name'),
    dataIndex: 'name',
    width: '180px',
  },
  {
    key: 'type',
    title: t('store.labels.type'),
    dataIndex: 'type',
    width: '180px',
    render: (type: string) => (
      <StoreTypeText>
        {storeTypes.find((tp) => tp.value === type)?.title ?? ''}
      </StoreTypeText>
    ),
  },
  {
    key: 'phone',
    title: t('store.labels.phone'),
    dataIndex: 'phone',
    width: '180px',
  },
  {
    key: 'email',
    title: t('store.labels.mail'),
    dataIndex: 'email',
    width: '180px',
  },
  {
    key: 'docNumber',
    title: t('store.labels.documentNumber'),
    dataIndex: 'docNumber',
    width: '180px',
  },
  {
    key: 'actions',
    title: t('store.labels.actions'),
    dataIndex: '',
    width: '120px',
    render: (store: Store) => StoreActions(store),
  },
];

const useStoresTable = ({
  onUpdateStore,
}: {
  onUpdateStore: (store: Store) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('user.editActions');
  const storeTypes = useRecoilValue(storeTypesAtom);

  const StoreActions = (store: Store) => (
    <AccessWrapper
      fallback={
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </Tag>
      }
    >
      <TableActions entity={store} onUpdate={onUpdateStore} />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, StoreActions, storeTypes);
  const columns = useMemo(() => defaultColumns, [defaultColumns]);

  return {
    columns,
    t,
  };
};

export default useStoresTable;
