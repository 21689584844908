import { SisternodeOutlined } from '@ant-design/icons';
import { BannerCard } from 'components/BannerCardItem/BannerCardItem.sty';
import { BaseColors, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const Subtitle = styled.h1`
  font-size: ${spaceSizes.xl};
`;

export const AddBehaviorIcon = styled(SisternodeOutlined)`
  color: ${BaseColors.curious_blue};
  font-size: 3em;
`;

export const AddBehaviorLabel = styled.p`
  color: ${BaseColors.curious_blue};
  font-size: 1.5em;
`;

export const BehaviorStyledCard = styled(BannerCard)``;

export const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spaceSizes.m} 0;
`;

export const SettingHeaderSection = styled.div`
  display: flex;
  gap: 5px;
`;
