import { Form, Input, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { UploadFile } from 'antd/lib/upload/interface';
import UploadItem from 'components/Upload/UploadItem';
import {
  arrayValidator,
  numberValidator,
  stringValidator,
} from 'helpers/utils/formValidators';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBrandForm from './hooks/useBrandForm';
import {
  BrandFormInitialValues,
  BrandFormInterface,
  BrandFormProps,
} from './interface';

const BrandForm = ({
  setFormReference,
  initialValues = BrandFormInitialValues,
  actionType,
  closeModal,
  reLoadOnSuccess,
  setLoadingConfirm,
}: BrandFormProps) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const rankingOptions = [1, 2, 3, 4, 5];
  const parsedInitialValues: BrandFormInterface = useMemo(() => {
    const initialValuesCopy = { ...initialValues };
    if (initialValuesCopy?.logo?.id) {
      initialValuesCopy.logo.uid = initialValuesCopy?.logo?.id ?? '';
    }
    return initialValuesCopy;
  }, [initialValues]);

  const { submitForm, isLoadingAction } = useBrandForm(
    actionType,
    parsedInitialValues.id,
    closeModal,
    reLoadOnSuccess,
  );

  useEffect(() => {
    if (parsedInitialValues.logo) {
      setFileList([parsedInitialValues.logo]);
    }
    if (setFormReference) {
      setFormReference(form);
    }
  }, []);

  useEffect(() => {
    if (setLoadingConfirm) setLoadingConfirm(isLoadingAction);
  }, [setLoadingConfirm, isLoadingAction]);

  return (
    <Form
      scrollToFirstError
      onFinish={submitForm}
      initialValues={parsedInitialValues}
      form={form}
    >
      <Form.Item
        required
        rules={[
          {
            required: true,
            validator: (_, value) =>
              stringValidator(value, { required: true }, t),
          },
        ]}
        name="name"
        label={t('brandForm.labels.name')}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        required
        name="ranking"
        label={t('brandForm.labels.ranking')}
        rules={[
          {
            validator: (_, value) =>
              numberValidator(value, { required: true, min: 1, max: 5 }, t),
          },
        ]}
      >
        <Select defaultValue={parsedInitialValues.ranking}>
          {rankingOptions.map((val) => (
            <Option value={val.toString()}>{val}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        required
        name="logo"
        label={t('brandForm.labels.logo')}
        rules={[
          {
            required: true,
            validator: (_, value) =>
              arrayValidator(value, { maxLength: 1, required: true }, t),
          },
        ]}
      >
        <UploadItem
          folder="brand_logos"
          fileList={fileList}
          maxLength={1}
          onUpload={(file) => {
            setFileList([file]);
            form.setFieldsValue({ logo: file });
          }}
          format={['png', 'webp', 'jpeg', 'jpg', 'gif']}
          onRemove={() => {
            setFileList([]);
            form.setFieldsValue({ logo: null });
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default BrandForm;
