import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

export const UnitTableNameWrapper = styled.div`
  padding: 8px;
`;

export const UnitTableSearch = styled(Input)`
  width: 188px;
  margin-bottom: 8px;
  display: block;
`;

export const UnitTableSearchFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UnitTableSearchFooterButton = styled(Button)`
  width: 90px;
`;

export const UnitTableSearchIcon = styled(SearchOutlined)<{
  filtered: boolean;
}>`
  color: ${({ filtered }) => (filtered ? BaseColors.wow_blue : undefined)};
`;
