import { AxiosResponse } from 'axios';
import { getAPIMethod } from 'helpers/libs/Http';
import { ShippingZones } from 'services/cities/interface';

export const getShippingZoneById = async (
  id: string,
): Promise<ShippingZones> => {
  const response: AxiosResponse<ShippingZones> = await getAPIMethod({
    name: 'getShippingZoneById',
    pathParams: {
      id,
    },
  });
  return response.data;
};

export default {
  create: getShippingZoneById,
  read: getShippingZoneById,
  update: getShippingZoneById,
  delete: getShippingZoneById,
  readByParam: getShippingZoneById,
};
