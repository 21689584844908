import { useMutation, useQuery } from '@tanstack/react-query';
import { UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
  HttpRequestHeader,
  UploadChangeParam,
} from 'antd/lib/upload/interface';
import {
  FormatDownloadProps,
  StatusCheckingProps,
} from 'components/CsvModule/interface';
import CsvTransferErrorView from 'components/CsvTransferErrorView/CsvTransferErrorView';
import useGetToken from 'helpers/hooks/useGetToken/useGetToken';
import useNotifications from 'helpers/hooks/useNotifications';
import { buildBearerToken } from 'helpers/utils/buildBearerToken';
import { validateCsvFile } from 'helpers/utils/validateCsvFile';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getTransferCsvTemplate,
  getVariantsCsvStatus,
} from 'services/transfers/transfer.service';
import {
  TRANSFER_CSV_FORMAT_FILE,
  TRANSFER_CSV_UPLOAD_BASE_PROPS,
} from '../constants';
import {
  ITransferCsvStatus,
  ITransfersCsvContext,
  TRANSFER_CSV_STATUS_DEFAULT_VALUES,
} from '../interface';
import { getRefetchInterval } from '../utils';

export type PurchaseActionModal = 'CREATE' | 'UPDATE';

function useTransfersCsvContext(): ITransfersCsvContext {
  const { t } = useTranslation();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  // ====================== REQUEST HANDLING ======================
  const {
    data: transfersCsvStatus,
    isLoading: isLoadingStatusCheck,
    refetch,
  } = useQuery(['transfersCsvStatus'], {
    queryFn: getVariantsCsvStatus,
    refetchInterval: getRefetchInterval,
  });
  const { mutateAsync: fetchFormat, isLoading: isLoadingFormat } = useMutation({
    mutationFn: getTransferCsvTemplate,
  });

  const { token } = useGetToken();
  const [loadInProgress, setLoadInProgress] = useState(true);

  const beforeUpload = useCallback(
    (fileUpload: RcFile) => {
      const validationErr = validateCsvFile(fileUpload, loadInProgress);
      if (validationErr !== null) {
        notifyError(validationErr);
        return false;
      }

      return true;
    },
    [loadInProgress, notifyError],
  );

  const onChangeUpload = useCallback(
    (info: UploadChangeParam) => {
      if (info?.file?.status === 'done') {
        refetch();
      }
    },
    [refetch],
  );

  // ========================== HANDLERS ==========================
  const handleDownloadFormat = useCallback(async () => {
    const { data: content } = await fetchFormat();
    const file = new Blob([content], { type: 'text/csv' });
    const csvUrl = window.URL.createObjectURL(file);
    const tempLink = document.createElement('a');

    tempLink.href = csvUrl;
    tempLink.setAttribute('download', TRANSFER_CSV_FORMAT_FILE);
    tempLink.click();
  }, [fetchFormat]);

  // ======================= PROPS BUILDING =======================
  const uploadProps: UploadProps = useMemo(() => {
    const uploadHeaders: HttpRequestHeader = {
      Authorization: buildBearerToken(token),
    };

    return {
      ...TRANSFER_CSV_UPLOAD_BASE_PROPS,
      headers: uploadHeaders,
      beforeUpload,
      onChange: onChangeUpload,
    };
  }, [beforeUpload, token, onChangeUpload]);

  const formatDownloadProps: FormatDownloadProps = useMemo(() => {
    const props: FormatDownloadProps = {
      downloadFormatText: t('transfersCsv.buttons.downloadFormat'),
      isLoading: isLoadingFormat,
      handleDownloadFormat,
    };

    return props;
  }, [handleDownloadFormat, isLoadingFormat, t]);

  const statusCheckingProps: StatusCheckingProps = useMemo(() => {
    const data = (transfersCsvStatus ??
      TRANSFER_CSV_STATUS_DEFAULT_VALUES) as ITransferCsvStatus;

    setLoadInProgress(data.transferIsRunning);
    return {
      isLoading: isLoadingStatusCheck,
      loadInProgress: data.transferIsRunning,
      progress: data.percentage,
      errors: data.errors,
      successMessage: t('transfersCsv.messages.success'),
    };
  }, [t, transfersCsvStatus, isLoadingStatusCheck]);

  return {
    hint: t('transfersCsv.hint'),
    inputText: t('transfersCsv.inputText'),
    title: t('transfersCsv.title'),
    uploadProps,
    formatDownloadProps,
    statusCheckingProps,
    ErrorView: CsvTransferErrorView,
  };
}

export default useTransfersCsvContext;
