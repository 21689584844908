import { ErrorMessageObject } from './development.errors';

export const ordersErrors: { [key: string]: ErrorMessageObject } = {
  ORDS401001: {
    translation: 'ORDS401001',
    isBlocking: false,
  },
  ORDS403002: {
    translation: 'ORDS403002',
    isBlocking: false,
  },
  ORDS403003: {
    translation: 'ORDS403003',
    isBlocking: false,
  },
  ORDS400004: {
    translation: 'ORDS400004',
    isBlocking: false,
  },
  ORDS400005: {
    translation: 'ORDS400005',
    isBlocking: false,
  },
  ORDS400006: {
    translation: 'ORDS400006',
    isBlocking: false,
  },
  ORDS400007: {
    translation: 'ORDS400007',
    isBlocking: false,
  },
  ORDS400008: {
    translation: 'ORDS400008',
    isBlocking: false,
  },
  ORDS400009: {
    translation: 'ORDS400009',
    isBlocking: false,
  },
  ORDS400010: {
    translation: 'ORDS400010',
    isBlocking: false,
  },
  ORDS400011: {
    translation: 'ORDS400011',
    isBlocking: false,
  },
  ORDS400012: {
    translation: 'ORDS400012',
    isBlocking: false,
  },
  ORDS400013: {
    translation: 'ORDS400013',
    isBlocking: false,
  },
  ORDS400014: {
    translation: 'ORDS400014',
    isBlocking: false,
  },
  ORDS400015: {
    translation: 'ORDS400015',
    isBlocking: false,
  },

  ORDC417001: {
    translation: 'ORDC417001',
    isBlocking: false,
  },
  ORDC500001: {
    translation: 'ORDC500001',
    isBlocking: false,
  },

  ORDR404001: {
    translation: 'ORDR404001',
    isBlocking: false,
  },
  ORDR500001: {
    translation: 'ORDR500001',
    isBlocking: false,
  },

  ORDU404001: {
    translation: 'ORDU404001',
    isBlocking: false,
  },
  ORDU404002: {
    translation: 'ORDU404002',
    isBlocking: false,
  },
  ORDU404003: {
    translation: 'ORDU404003',
    isBlocking: false,
  },
  ORDU404004: {
    translation: 'ORDU404004',
    isBlocking: false,
  },
};
