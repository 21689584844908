/* eslint-disable indent */
import { Table, Tag } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { numberToCurrency } from 'helpers/utils/currency';
import dateFormat from 'helpers/utils/dateFormat';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Order, StatusTagColor, UserOrders } from 'services/order/interface';
import { ClientInfoParagraph } from '../../OrderTable/OrderTable.sty';

const tableColumns = (t: TFunction<'translation', undefined>) => [
  {
    title: t('orderTableKeys.orderNumber'),
    dataIndex: 'orderNumber',
    render: (item: string) => (
      <span>
        <Tag color="geekblue">{(item || '')?.split('-')[0]}</Tag>
        <Tag color="gold">{(item || '')?.split('-')[1]}</Tag>
      </span>
    ),
  },
  {
    title: t('orderTableKeys.client'),
    dataIndex: 'user',
    render: (orderUser: UserOrders) => (
      <ClientInfoParagraph>
        {orderUser?.name || 'NN'}
        &nbsp;
        {orderUser?.firstLastName || ''}
      </ClientInfoParagraph>
    ),
  },
  {
    title: t('orderTableKeys.total'),
    dataIndex: 'total',
    render: (total: string) => numberToCurrency(Number(total)),
  },
  {
    title: t('orderTableKeys.deliveryDate'),
    dataIndex: ['delivery', 'date'],
    render: (date: string) => dateFormat(date || ''),
  },
  {
    title: t('orderTableKeys.state'),
    render: (order: Order) => (
      <CustomTag color={StatusTagColor[order?.status]}>
        {t(`orderStatus.${order?.status}`) || `${order?.status}` || ''}
      </CustomTag>
    ),
  },
];

const ModalOrderTable = ({ orders }: { orders: Order[] }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const defaultColumns = tableColumns(t);
    const mobileHidableColumns: string[] = [
      t('orderTableKeys.client'),
      t('orderTableKeys.total'),
      t('orderTableKeys.deliveryDate'),
    ];
    const filteredColumns = isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
    return filteredColumns;
  }, [t, isMobile]);
  return (
    <Table
      scroll={{ y: 410 }}
      columns={columns}
      pagination={false}
      dataSource={orders}
      rowKey="id"
    />
  );
};

export default ModalOrderTable;
