/* eslint-disable no-underscore-dangle */
import {
  OrderPackageAction,
  OrderPackageState,
  ORDER_PACKAGE_ACTION_ENUM,
} from './interface/order-package';

export const INITIAL_ORDER_PACKAGE_REDUCER: OrderPackageState = {
  packages: [],
  deliveryOptions: {},
};

export function orderPackageReducer(
  state: OrderPackageState,
  action: OrderPackageAction,
): OrderPackageState {
  switch (action.type) {
    case ORDER_PACKAGE_ACTION_ENUM.SET_PACKAGES:
      return { ...state, packages: action.payload };
    case ORDER_PACKAGE_ACTION_ENUM.SET_PACKAGE_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptions: {
          ...state.deliveryOptions,
          [action.payload.packageId]: action.payload.options,
        },
      };
    default:
      return state;
  }
}
