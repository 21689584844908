import PurchaseUpdateContainer from 'modules/Purchase/containers/PurchaseUpdateContainer';
import { PurchaseProvider } from 'modules/Purchase/context/Purchase.context';

export const PurchaseUpdatePage = () => {
  return (
    <PurchaseProvider>
      <PurchaseUpdateContainer />
    </PurchaseProvider>
  );
};
