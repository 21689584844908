/* eslint-disable import/no-unresolved */
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { MPS } from 'services/MPS/interface';
import {
  BoldItemParagraph,
  ItemParagraph,
  MpsLogo,
} from './AutoCompleteMpsItem.sty';

const AutoCompleteMpsItem = ({ mps }: { mps: MPS }) => {
  const fullName = mps.name || '';
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 8]}>
      <Col
        span={5}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MpsLogo name={fullName}>
          {`${(mps.name || 'N')[0].toUpperCase()}${(mps.name ||
            'NN')[1].toUpperCase()}`}
        </MpsLogo>
      </Col>
      <Col span={16}>
        <BoldItemParagraph>{fullName}</BoldItemParagraph>
        <ItemParagraph>{`NIT. ${mps.document || ''}`}</ItemParagraph>
        <ItemParagraph>
          {t('contactLabel', {
            contact: mps.contactName || 'NA',
          })}
        </ItemParagraph>
        <ItemParagraph>
          {t('contactPhoneLabel', {
            contactPhone: mps.phoneNumber || '',
          })}
        </ItemParagraph>
        <ItemParagraph>
          {t('mailLabel', {
            email: mps.email || 'NA',
          })}
        </ItemParagraph>
        <ItemParagraph>
          {t('logisticsLabel', {
            logistics: mps.logisticType || 'NA',
          })}
        </ItemParagraph>
      </Col>
    </Row>
  );
};

export default AutoCompleteMpsItem;
