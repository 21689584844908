import { BaseColors, spaceSizes } from 'helpers/constants/theme';
import { BoldLabel } from 'modules/Order/components/HistoryOrder/HistoryOrder.sty';
import styled from 'styled-components';

export {};

export const OrderHistoryContainer = styled.div`
  margin: 0 0 ${spaceSizes.l} 0;
  padding: 0 0 ${spaceSizes.l} 0;
  width: 100%;
  border-bottom: 1px solid ${BaseColors.porcelain};
`;

export const ProductBoldLabel = styled(BoldLabel)`
  margin: 0 ${spaceSizes.s} 0 0;
`;
export const RenderLocationsContainer = styled.div`
  overflow: scroll;
  max-height: 120px;
`;

export const RenderLocationsLabel = styled.div`
  margin: 0 ${spaceSizes.l};
`;
