import { useMutation } from '@tanstack/react-query';
import { CsvInput } from 'components/CsvInput/CsvInput';
import config from 'config';
import { getVariantListAsCsv } from 'services/product-massive/product-massive.service';

export const VariantCsvInput = () => {
  const { mutateAsync: fetchFormat, isLoading: isLoadingFormat } = useMutation({
    mutationFn: getVariantListAsCsv,
  });

  return (
    <CsvInput
      fetchFormat={fetchFormat}
      isLoadingFormat={isLoadingFormat}
      draggerName="variantCsv"
      actionUrl={config.variantCsvUpload.actionURL}
      fileName="variant_catalog.csv"
      title="variantsCsvInput.modal.content"
      csvInputHint="variantsCsvInput.hint"
    />
  );
};
