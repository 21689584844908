import { Method } from 'axios';

export const endpoints = {
  updateWarehouseStock: {
    method: 'PUT' as Method,
    url: 'stock-dom/stock/{params.warehouseId}/update/{params.variantId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getStockCsvTemplate: {
    method: 'GET' as Method,
    url: 'stock-dom/stock/template',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getAvailableCsvTemplate: {
    method: 'GET' as Method,
    url: 'unit-dom/unit/massive-available-set',
    baseURL: process.env.REACT_APP_API_PRODUCT_VARIANT_URL,
  },
};
