import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { LocationValue } from '../interface';

const useAddressSelect = (
  value: LocationValue | undefined,
  locationOptions: LocationValue[],
) => {
  const addressState = useState<LocationValue | undefined>(value);
  const addressIndexState = useState<number | undefined>(undefined);
  const extendedLocationOptionsState = useState<LocationValue[]>(
    locationOptions,
  );
  const addressModalState = useState<boolean>(false);

  const [_address, setAddress] = addressState;
  const [_addressIndex, setAddressIndex] = addressIndexState;
  const [extendedLocationOptions, _setExtendedLocationOptions] = extendedLocationOptionsState;

  useEffect(() => {
    if (value) {
      const matchIdx = extendedLocationOptions.findIndex((location: LocationValue) =>
        isEqual(location, value));
      if (matchIdx > -1) {
        setAddressIndex(matchIdx);
        setAddress({
          ...extendedLocationOptions[matchIdx],
        });
      }
    }
  }, [value, setAddress, setAddressIndex, extendedLocationOptions]);

  return {
    extendedLocationOptionsState,
    addressState,
    addressIndexState,
    addressModalState,
  };
};

export default useAddressSelect;
