import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { LocationValue } from '../LocationInput/interface';
import LocationDisplay from '../LocationInput/LocationDisplay';
import LocationInput from '../LocationInput/LocationInput';
import { useMultiAddressInput } from './hooks/useMultiAddressInput';
import { MultiAddressInputProps } from './interface';
import {
  AddButton,
  AddressItem,
  AddressListWrapper,
  DeleteButton,
  LocationContainer,
  MultiAddressContainer,
} from './MultiAddressInput.sty';

export const MultiAddressInput = ({
  value,
  onChange,
}: MultiAddressInputProps) => {
  const {
    showModalState: [showModal, _setShowModal],
    addressSelectedState,
    modalLocation,
    locationMapRef,
    handleCancel,
    handleLocationModalInputChange,
    handleSelectLocationIdx,
    handleAddressItemChange,
    handleDeleteAddress,
    t,
  } = useMultiAddressInput({ value, onChange });

  return (
    <>
      <Modal
        title={t('addressInput.modal.title')}
        visible={showModal}
        onCancel={handleCancel}
        onOk={handleAddressItemChange}
      >
        <LocationInput
          onLoadMap={(map: google.maps.Map) => {
            locationMapRef.current = map;
          }}
          value={modalLocation}
          onChange={handleLocationModalInputChange}
          addressSelectedState={addressSelectedState}
        />
      </Modal>
      <MultiAddressContainer>
        <AddressListWrapper>
          {!!value?.length && (
            <>
              {value.map((location: LocationValue, idx: number) => (
                <AddressItem key={location.address}>
                  <LocationContainer>
                    <LocationDisplay
                      value={location}
                      onEdit={() => handleSelectLocationIdx(idx)}
                    />
                  </LocationContainer>
                  <DeleteButton>
                    <MinusCircleOutlined
                      onClick={() => handleDeleteAddress(idx)}
                    />
                  </DeleteButton>
                </AddressItem>
              ))}
            </>
          )}
        </AddressListWrapper>
        <AddButton>
          <Button
            type="dashed"
            onClick={() => handleSelectLocationIdx(-1)}
            block
            icon={<PlusOutlined />}
            id="adress_selector_to_scroll"
          >
            {t('userForm.actions.addLocation')}
          </Button>
        </AddButton>
      </MultiAddressContainer>
    </>
  );
};
