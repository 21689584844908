import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const ItemParagraph = styled.div`
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  margin-right: ${LightTheme.spaceSizes.m};
`;

export const ThumbnailImageWrapper = styled.figure<{ mini?: boolean }>`
  width: ${({ mini }) => (mini ? '25px' : '40px')};
  height: ${({ mini }) => (mini ? '25px' : '40px')};
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const StatusFlag = styled.div<{ color: string }>`
  width: ${spaceSizes.ml};
  height: ${spaceSizes.ml};
  border-radius: 50%;
  background: ${({ color }) => `${color}`};
  margin-right: ${LightTheme.spaceSizes.sm};
`;
