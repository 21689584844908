/* eslint-disable no-underscore-dangle */
import { useQuery } from '@tanstack/react-query';
import AutoCompleteMpsItem from 'components/AutoCompleteMpsItem/AutoCompleteMpsItem';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MPS } from 'services/MPS/interface';
import { getMpsList } from 'services/MPS/mps.service';
import { MpsAutocompleteOption } from '../interface';

const useMpsAutocomplete = (
  value: MPS & { documentNumber?: string },
  triggerChange: (changedValue: MPS) => void,
  disabled = true,
) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [mpsOptions, setMpsOptions] = useState<MpsAutocompleteOption[]>([]);

  useEffect(() => {
    if (disabled) setSearchKey('');

    if (Object.keys(value).length) {
      setSearchKey(
        `${value.name ?? ''}, ${
          value.document ?? value.documentNumber ?? value.phoneNumber ?? ''
        }`,
      );
    }
  }, [value, disabled]);

  const { data, isFetching } = useQuery({
    queryFn: () =>
      getMpsList({
        pagination: {
          page: 1,
          pageSize: 10,
        },
        search: searchFilter,
        isSupplier: false,
      }),
    queryKey: ['mps', searchFilter],
    onSuccess(res) {
      const newOptions = res.data.map((mps: MPS) => ({
        value: mps._id,
        label: <AutoCompleteMpsItem mps={mps} />,
      }));
      setMpsOptions(newOptions);
    },
    refetchOnWindowFocus: false,
  });

  const autocompleteResults = useMemo(() => data?.data ?? [], [data]);

  const handleSelectAutocomplete = (inputValue: string) => {
    const mpsToAdd = autocompleteResults.find(
      (result) => result._id === inputValue,
    );
    triggerChange(mpsToAdd as MPS);
    setSearchKey(
      mpsToAdd
        ? `${mpsToAdd?.name || ''}, NIT. ${mpsToAdd?.document || ''}`
        : '',
    );
  };

  useEffect(() => {
    const debounced = debounce(() => {
      setSearchFilter(searchKey);
    }, 700);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [searchKey]);

  const handleSearchMps = useCallback(
    async (search: string) => {
      setSearchKey(search);
    },
    [setSearchKey],
  );

  return {
    searchKey,
    mpsOptions,
    isLoadingAutocomplete: isFetching,
    handleSearchMps,
    handleSelectAutocomplete,
  };
};

export default useMpsAutocomplete;
