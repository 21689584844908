/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import { Space, Tag } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import dateFormat from 'helpers/utils/dateFormat';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import TableActions from 'components/TableActions/TableActions';
import { StatusTagColorCode, UserRoleEnum } from 'services/user/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { UserContent } from '../interface';
import { ItemLabel, ItemParagraph } from '../UserTable.sty';
import { RoleTagColorCode } from '../../../../helpers/hooks/useRoleAccess/interface';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  UserActions: (user: UserContent) => JSX.Element,
) => [
  {
    title: t('user.userListColumns.userNumber'),
    render: (user: UserContent) => {
      if (user.userNumber) {
        return (
          <span>
            <Tag color="geekblue">{user.userNumber.split('-')[0]}</Tag>
            <Tag color="gold">{user.userNumber.split('-')[1]}</Tag>
          </span>
        );
      }
      return <b>-</b>;
    },
  },
  {
    title: t('user.userListColumns.fullName'),
    render: (user: UserContent) => (
      <p>{`${user.info?.name || 'N/A'} ${user.info?.lastName || ''}`}</p>
    ),
  },
  {
    title: t('user.userListColumns.phoneNumber'),
    render: (user: UserContent) => <p>{user.info?.phoneNumber}</p>,
  },
  {
    title: t('user.userListColumns.email'),
    render: (user: UserContent) => <p>{user.info?.email}</p>,
  },
  {
    title: t('user.userListColumns.roles'),
    filters: (
      Object.keys(UserRoleEnum) as Array<keyof typeof UserRoleEnum>
    ).map((key) => ({
      text: key,
      value: key,
    })),
    render: (user: UserContent) => (
      <Space>
        {user.role
          ? user.role.map((role) => (
              <Tag color={RoleTagColorCode[role]}>{role}</Tag>
            ))
          : null}
      </Space>
    ),
  },
  {
    title: t('user.userListColumns.actions'),
    render: (user: UserContent) => UserActions(user),
  },
];

const hiddenDescription = ({
  user,
  UserActions,
  t,
  isMobile,
}: {
  user: UserContent;
  t: TFunction<'translation', undefined>;
  isMobile: boolean;
  UserActions: (user: UserContent) => JSX.Element;
}) => (
  <Space direction="vertical">
    <ItemParagraph>
      <ItemLabel>
        {' '}
        {t('user.userListColumns.id')}
        :&nbsp;
      </ItemLabel>
      {user.id}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.status')}
        :&nbsp;
      </ItemLabel>
      <Space>
        <Tag color={user.status ? StatusTagColorCode[user.status] : 'default'}>
          {user.status}
        </Tag>
      </Space>
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.roles')}
        :&nbsp;
      </ItemLabel>
      <Space>
        {user.role?.map((role) => (
          <Tag color={RoleTagColorCode[role] || 'default'}>{role}</Tag>
        ))}
      </Space>
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.document')}
        :&nbsp;
      </ItemLabel>
      {`${user.info?.docType || 'N/A'}. ${user.info?.documentNumber || ''}`}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.fullName')}
        :&nbsp;
      </ItemLabel>
      {`${user.info?.name || 'N/A'} ${user.info?.lastName || ''}`}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.phoneNumber')}
        :&nbsp;
      </ItemLabel>
      {user.info?.phoneNumber}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.email')}
        :&nbsp;
      </ItemLabel>
      {user.info?.email}
    </ItemParagraph>
    {user.info?.locations?.length && (
      <ItemParagraph>
        <ItemLabel>
          {t('user.userListColumns.address')}
          :&nbsp;
        </ItemLabel>
        {`${user.info?.locations[0].address} ${
          user.info?.locations[0].details || ''
        }`}
      </ItemParagraph>
    )}
    <ItemParagraph>
      <ItemLabel>
        {t('user.userListColumns.creationDate')}
        :&nbsp;
      </ItemLabel>
      {user.createdAt ? dateFormat(user.createdAt) : ''}
    </ItemParagraph>
    {isMobile && UserActions(user)}
  </Space>
);

const useUserTableModule = ({
  onDeleteUser,
  onUpdateUser,
}: {
  onDeleteUser: (user: UserContent) => void;
  onUpdateUser: (user: UserContent) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('user.editActions');

  const UserActions = (user: UserContent) => (
    <AccessWrapper
      fallback={
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </Tag>
      }
    >
      <TableActions
        entity={user}
        onDelete={onDeleteUser}
        onUpdate={onUpdateUser}
      />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, UserActions);
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('user.userListColumns.actions'),
      t('user.userListColumns.phoneNumber'),
      t('user.userListColumns.email'),
      t('user.userListColumns.status'),
    ];
    return isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
  }, [defaultColumns, isMobile, t]);
  return {
    columns,
    hiddenDescription: (user: UserContent) =>
      hiddenDescription({
        t,
        user,
        isMobile,
        UserActions,
      }),
  };
};

export default useUserTableModule;
