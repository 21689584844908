/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import Tag from 'antd/es/tag';
import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { numberToCurrency } from 'helpers/utils/currency';
import { spanishDateFormat } from 'helpers/utils/dateFormatCol';
import { t } from 'i18next';
import { RefObject, useMemo } from 'react';
import { MPS } from 'services/MPS/interface';
import {
  Delivery,
  Purchase,
  purchaseStatusColors,
} from 'services/purchase/interface';

interface Props {
  isLoading: boolean;
  data: Purchase[];
  reachedEndOfData?: boolean;
  onViewDetail: (purchase: Purchase) => void;
  onScrollToEnd: (params?: any) => void;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
}

const TABLE_HEADER_HEIGHT = 120;
const tableColumns = [
  {
    title: t('purchaseTableKeys.purchaseNumber'),
    dataIndex: 'purchaseNumber',
    render: (item: string) => {
      if (!item) {
        return null;
      }
      return (
        <span>
          <Tag color="purple">{item?.split('-')[0]}</Tag>
          <Tag color="default">{item?.split('-')[1]}</Tag>
        </span>
      );
    },
  },
  {
    title: t('purchaseTableKeys.supplier'),
    dataIndex: 'supplier',
    render: (supplier: MPS) => supplier?.name || '-',
  },
  {
    title: t('purchaseTableKeys.total'),
    dataIndex: 'total',
    render: (total: string) =>
      total ? numberToCurrency(Number(total)) : t('purchaseTableKeys.empty'),
  },
  {
    title: t('purchaseTableKeys.deliveryDate'),
    dataIndex: 'delivery',
    render: (delivery: Delivery) =>
      spanishDateFormat(delivery.date ?? '', true),
  },
  {
    title: t('purchaseTableKeys.state'),
    render: (purchase: Purchase) => (
      <Tag color={purchaseStatusColors[purchase?.status]}>
        {(
          t(`purchaseStatus.${purchase?.status}`) ||
          `${purchase?.status}` ||
          ''
        ).toUpperCase()}
      </Tag>
    ),
  },
];

const PurchaseTable = ({
  tableRef,
  isLoading,
  data,
  reachedEndOfData = false,
  onViewDetail,
  onScrollToEnd,
}: Props) => {
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('purchaseTableKeys.actions'),
      t('purchaseTableKeys.total'),
      t('purchaseTableKeys.creationDate'),
    ];
    const filteredColumns = isMobile
      ? tableColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : tableColumns;
    return filteredColumns;
  }, [isMobile]);

  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="order-table"
      isLoading={isLoading}
      columns={columns}
      rowKey="id"
      onRow={(record) => ({
        onClick: () => onViewDetail(record as Purchase),
      })}
      expandable={{
        rowExpandable: () => false,
      }}
      pagination={false}
      dataSource={data}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};

export default PurchaseTable;
