/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useContext, useReducer } from 'react';
import {
  initialWalletState,
  WalletAction,
  WalletActionEnum,
  WalletState,
} from 'services/wallet/interface';

function WalletReducer(state: WalletState, action: WalletAction) {
  switch (action.type) {
    case WalletActionEnum.SET_TRANSACTION_LIST:
      return { ...state, walletTransactionList: action.payload };
    case WalletActionEnum.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case WalletActionEnum.SET_CURRENT_TRANSACTION:
      return { ...state, currentTransaction: action.payload };
    case WalletActionEnum.SET_SEARCH:
      return { ...state, search: action.payload };
    case WalletActionEnum.SET_USER_FILTER:
      return { ...state, user: action.payload };
    case WalletActionEnum.SET_STATUS_FILTER:
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

const WalletContext = createContext<{
  state: WalletState;
  dispatch: React.Dispatch<WalletAction>;
}>({
  state: initialWalletState,
  dispatch: () => null,
});

interface WalletProviderProps {
  children: ReactNode;
}

export const WalletProvider: React.FC<WalletProviderProps> = ({
  children,
}: WalletProviderProps) => {
  const [state, dispatch] = useReducer(WalletReducer, initialWalletState);

  return (
    <WalletContext.Provider value={{ state, dispatch }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => useContext(WalletContext);
