import OrderCreateContainer from 'modules/Order/containers/OrderCreateContainer';
import { OrderProvider } from 'modules/Order/context/Order.context';

export const OrderCreate = () => {
  return (
    <OrderProvider>
      <OrderCreateContainer />
    </OrderProvider>
  );
};
