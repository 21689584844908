/* eslint-disable no-underscore-dangle */
import { Variant } from 'services/product/interface';
import { PurchaseStatus, PurchaseVariant } from 'services/purchase/interface';

export const createNewPurchaseVariant = (
  variant: Variant,
  isMarketplace: boolean,
  isCreate: boolean,
  status?: PurchaseStatus,
): PurchaseVariant => {
  return {
    id: variant.id,
    variant: {
      variantSku: variant.variantSku,
      name: variant.name,
      urlName: variant.urlName,
      cost: variant.cost,
      productId: variant.parent?._id ?? variant.parent?.id ?? '',
      images: variant.images,
    },
    values: {
      purchasePrice: isMarketplace ? undefined : 0,
      purchasePriceCurrency: isMarketplace ? undefined : 'COP',
      estimatedCogsVat: variant.cost ?? 0,
      estimatedCogs: variant.cost ?? 0,
      importCostVat: !isCreate ? variant.cost ?? 0 : undefined,
      importCost: !isCreate ? variant.cost ?? 0 : undefined,
      taxType: isMarketplace ? 'PERCENTAGE' : 'NOMINAL',
      tax: 0,
      cogs: !isCreate && !isMarketplace ? 0 : undefined,
      cogsVat: !isCreate && !isMarketplace ? 0 : undefined,
      orderedQuantity: 0,
      clearedQuantity: isMarketplace || isCreate ? undefined : 0,
      receivedQuantity:
        !isCreate && status === PurchaseStatus.IN_TRANSIT ? 0 : undefined,
    },
  };
};
