/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable func-call-spacing */
/* eslint-disable no-spaced-func */
import { TableWrapper } from 'components/TableWrapper';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import React, { useRef } from 'react';
import { FiltersAndButtonContainer } from '../../../modules/Order/components/OrderContent/OrderContent.sty';
import CouponModal from '../CouponModal/CouponModal';
import { CouponsTable } from './CouponsTable';
import useCouponsList from './hooks/useCouponsList';

export const CouponsContentList = ({
  searchKey,
}: {
  searchKey: string | undefined;
}) => {
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const modalRef = useRef<{
    openModal: () => void;
    closeModal: () => void;
    triggerEditable: () => void;
  }>(null);
  const {
    readQuery: { mutateAsync: getCouponListAsync, isLoading: isLoadingList },
    updateMutation: {
      mutateAsync: updateCouponAsync,
      isLoading: isLoadingUpdate,
    },
    createMutation: {
      mutateAsync: createCouponAsync,
      isLoading: isLoadingCreate,
    },
  } = useModuleCRUD('coupons');

  const {
    t,
    couponsList,
    CouponsFilters,
    reachedEndOfData,
    fetchNextCouponPage,
    modalCoupon,
    handleSubmitForm,
    handleViewDetail,
  } = useCouponsList({
    searchKey,
    getCouponListAsync,
    tableRef,
    modalRef,
    updateCouponAsync,
    createCouponAsync,
  });

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <FiltersAndButtonContainer>
        <CouponsFilters />
      </FiltersAndButtonContainer>
      <CouponModal
        ref={modalRef}
        coupon={modalCoupon}
        onSubmit={handleSubmitForm}
        isLoading={isLoadingCreate || isLoadingUpdate}
        isUpdate={!!modalCoupon}
      />
      <TableWrapper>
        <CouponsTable
          onViewDetail={handleViewDetail}
          reachedEndOfData={reachedEndOfData}
          isLoading={isLoadingList}
          data={couponsList || []}
          onScrollToEnd={fetchNextCouponPage}
          tableRef={tableRef}
        />
      </TableWrapper>
    </React.Suspense>
  );
};
