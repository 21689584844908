/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { CustomTag } from 'components/CustomTag';
import TableActions from 'components/TableActions/TableActions';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import useModal from 'helpers/hooks/useModal/useModal';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  mpsRequestStatusColors,
  mpsRequestStatusEnum,
  MPSUserFormInitialValues,
  MPSUserModalAction,
  MPSUSERRequest,
} from 'services/MPSUser/interface';
import { getMpsUserRequest } from 'services/MPSUser/mps-user-request.service';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  MPSUserActions: (MPSUserAction: any) => JSX.Element,
) => [
  {
    title: t('MPSUser.name'),
    dataIndex: 'name',
    width: '180px',
  },
  {
    title: t('MPSUser.phoneNumber'),
    dataIndex: 'phoneNumber',
    width: '180px',
  },
  {
    title: t('MPSUser.document'),
    dataIndex: 'document',
    width: '180px',
  },
  {
    title: t('MPSUser.contactName'),
    dataIndex: 'contactName',
    width: '180px',
  },
  {
    title: t('MPSUser.email'),
    dataIndex: 'email',
    width: '180px',
  },
  {
    title: t('MPSUser.status'),
    dataIndex: 'status',
    width: '120px',
    render: (MPSUsers: MPSUSERRequest, _: any) => (
      <CustomTag color={mpsRequestStatusColors[_.status]}>
        {t(`mpsStatus.${MPSUsers}`) || `${MPSUsers}` || ''}
      </CustomTag>
    ),
  },
  {
    title: t('MPSUser.actions'),
    dataIndex: '',
    width: '120px',
    render: (MPSUserInterface: any) => MPSUserActions(MPSUserInterface),
  },
];

const useMPSUserTable = () => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('user.editActions');
  const [status, setStatus] = useState<mpsRequestStatusEnum>(
    mpsRequestStatusEnum.RECEIVED,
  );

  const { isFetching, list, nextPage, refetch } = useInfiniteScroll({
    name: ['mps', 'request', 'list'],
    fetchData: getMpsUserRequest,
    filters: { status },
  });

  const [initialValues, setInitialValues] = useState(MPSUserFormInitialValues);
  const [modalActionType, setModalActionType] = useState<MPSUserModalAction>();
  const { openModal, closeModal, isOpen: isModalOpen } = useModal({});

  const handleUpdate = useCallback(
    (MPSUser: MPSUSERRequest) => {
      setModalActionType(MPSUserModalAction.UPDATE);
      setInitialValues(MPSUser);
      openModal();
    },
    [openModal],
  );

  const handleChangeRequestStatus = useCallback(
    (value: mpsRequestStatusEnum) => {
      setStatus(value || undefined);
    },
    [setStatus],
  );

  const MPSUserActions = (MPSUserValue: any) => (
    <AccessWrapper
      fallback={
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </Tag>
      }
    >
      <TableActions entity={MPSUserValue} onUpdate={handleUpdate} />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, MPSUserActions);
  const columns = useMemo(() => defaultColumns, [defaultColumns]);

  return {
    columns,
    t,
    handleUpdate,
    isModalOpen,
    closeModal,
    modalActionType,
    requestList: list,
    isFetchingList: isFetching,
    initialValues,
    nextPage,
    refetch,
    handleChangeRequestStatus,
    status,
  };
};

export default useMPSUserTable;
