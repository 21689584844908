import { SearchOutlined } from '@ant-design/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnitVariantFilterDropdown } from './interface';
import {
  UnitTableNameWrapper,
  UnitTableSearch,
  UnitTableSearchFooter,
  UnitTableSearchFooterButton,
} from './UnitVariantTable.sty';

const VariantFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset,
}: IUnitVariantFilterDropdown) => {
  // =================================================== hooks ===================================================
  const { t } = useTranslation();

  // =================================================== memos ===================================================
  const handleSearchValue = useMemo(
    () => selectedKeys?.[0] ?? '',
    [selectedKeys],
  );

  // ================================================= callbacks =================================================
  const handleEnterSearch = useCallback(() => {
    handleSearch(selectedKeys, confirm, 'name');
  }, [handleSearch, confirm, selectedKeys]);

  const handleClickSearch = useCallback(() => {
    handleSearch(selectedKeys, confirm, 'name');
  }, [selectedKeys, confirm, handleSearch]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value !== undefined && value !== null) {
        setSelectedKeys([value]);
      }
    },
    [setSelectedKeys],
  );

  return (
    <UnitTableNameWrapper>
      <UnitTableSearch
        placeholder={t('wms.searchName')}
        value={handleSearchValue}
        onChange={handleSearchChange}
        onPressEnter={handleEnterSearch}
      />
      <UnitTableSearchFooter>
        <UnitTableSearchFooterButton
          type="primary"
          onClick={handleClickSearch}
          icon={<SearchOutlined />}
          size="small"
        >
          {t('wms.search.buttons.search')}
        </UnitTableSearchFooterButton>
        <UnitTableSearchFooterButton
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
        >
          {t('wms.search.buttons.clear')}
        </UnitTableSearchFooterButton>
      </UnitTableSearchFooter>
    </UnitTableNameWrapper>
  );
};

export default VariantFilterDropdown;
