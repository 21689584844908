import { UploadProps } from 'antd';
import { FC } from 'react';

export interface FormatDownloadProps {
  isLoading: boolean;
  downloadFormatText: string;
  handleDownloadFormat: VoidFunction;
}

export interface ErrorViewProps {
  errors?: string[] | string;
}

export interface StatusCheckingProps {
  isLoading: boolean;
  progress: number;
  loadInProgress: boolean;
  successMessage: string;
  errors?: string[] | string;
}

export interface CsvModuleProps {
  uploadProps: UploadProps;
  hint: string;
  title: string;
  inputText: string;
  formatDownloadProps?: FormatDownloadProps;
  statusCheckingProps?: StatusCheckingProps;
  showAlertResult?: boolean;
  ErrorView?: FC<any>;
}

export type CsvPresentationProps = Pick<
  CsvModuleProps,
  'formatDownloadProps' | 'hint' | 'inputText' | 'uploadProps'
>;

export enum ProgressBarStatusesEnum {
  exception = 'exception',
  success = 'success',
  active = 'active',
  normal = 'normal',
}
