import axios from 'axios';
import { IBoardColumn } from 'components/Board/interface';
import { parsePandasErr } from 'config/Errors/error';
import { t } from 'i18next';
import { useCallback } from 'react';
import {
  Order,
  PandasPaymentMethod,
  StatusOrderEnum,
} from 'services/order/interface';
import { accessFromCurrentStatus } from '../constants/orderStatusMachine';
import { OrderContextTypes } from '../context/Order.context';
import { getOrderAccessMap } from '../helpers/orderStatus';
import { PaymentUpdateStatus } from '../interface';
import { ORDER_ACTION_ENUM } from '../reducer/interface';

interface UseOrderStatusChange extends OrderContextTypes {
  notifyWarning: (message: string) => void;
  notifyError: (message: string) => void;
  changeStatus: (
    order: Pick<
      Order,
      'id' | 'status' | 'orderNumber' | 'bankTransferPaid' | 'paymentMethod'
    >,
    newStatus: StatusOrderEnum,
    observations?: string,
    cancelReason?: string,
    reportMissing?: boolean,
  ) => Promise<void>;
  refetchOrders?: () => Promise<void>;
  updatePayment?: (params: {
    order: Partial<Order>;
    paymentStatus: PaymentUpdateStatus;
  }) => Promise<Order>;
  redirect?: () => void;
  order?: Order;
  restoreStatus?: () => void;
}

const useOrderStatusChange = ({
  state,
  dispatch,
  notifyWarning,
  changeStatus,
  updatePayment,
  refetchOrders,
  redirect,
  order,
  restoreStatus,
  notifyError,
}: UseOrderStatusChange) => {
  const onChangeStatus = useCallback(
    (newStatus: StatusOrderEnum) => {
      if (newStatus === order?.status) return;

      dispatch({
        type: ORDER_ACTION_ENUM.SET_NEW_STATUS,
        payload: newStatus,
      });
      if (order) {
        dispatch({
          type: ORDER_ACTION_ENUM.SET_ORDER_DRAGGED,
          payload: {
            id: order.id,
            status: order.status,
            orderNumber: order.orderNumber,
            paymentMethod: order.paymentMethod,
            bankTransferPaid: order.bankTransferPaid,
          },
        });
      }

      if (newStatus === StatusOrderEnum.CANCELLED) {
        dispatch({
          type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
          payload: true,
        });
      } else {
        dispatch({
          type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_STATUS_MODAL,
          payload: true,
        });
      }
    },
    [dispatch, order],
  );

  const handleDragCard = useCallback(
    (
      source: IBoardColumn<Order>,
      destination: IBoardColumn<Order>,
      movedItem: Order,
    ): void => {
      const statusDestination = destination.id as StatusOrderEnum;
      const possibleStatus = getOrderAccessMap({
        ...movedItem,
        status: source.id as StatusOrderEnum,
      });

      const canUpdateStatus = possibleStatus.includes(statusDestination);

      if (!canUpdateStatus) {
        notifyWarning(t('order.changeStatus.error'));
        return;
      }

      if (
        statusDestination === StatusOrderEnum.IN_TRANSIT &&
        movedItem.paymentMethod === PandasPaymentMethod.BANK_TRANSFER &&
        !movedItem.bankTransferPaid
      ) {
        notifyError('order.changeStatus.bankTransferError');
        return;
      }

      // Set confirm or cancel modal
      dispatch({
        type: ORDER_ACTION_ENUM.SET_NEW_STATUS,
        payload: statusDestination,
      });
      dispatch({
        type: ORDER_ACTION_ENUM.SET_ORDER_DRAGGED,
        payload: {
          id: movedItem.id,
          status: movedItem.status,
          orderNumber: movedItem.orderNumber,
          paymentMethod: movedItem.paymentMethod,
          bankTransferPaid: movedItem.bankTransferPaid,
        },
      });

      if (statusDestination === StatusOrderEnum.CANCELLED) {
        dispatch({
          type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
          payload: true,
        });
      } else {
        dispatch({
          type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_STATUS_MODAL,
          payload: true,
        });
      }
    },
    [dispatch, notifyWarning],
  );

  const handleCommentChange = useCallback(
    (comment: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_OBSERVATIONS,
        payload: comment,
      });
    },
    [dispatch],
  );
  const setCancelReason = useCallback(
    (reason: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_CANCEL_REASON,
        payload: reason,
      });
    },
    [dispatch],
  );
  const setReportMissing = useCallback(
    (missing: boolean) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_REPORT_MISSING,
        payload: missing,
      });
    },
    [dispatch],
  );
  const closeStatusChangeModal = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
      payload: false,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_UPDATE_STATUS_MODAL,
      payload: false,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OBSERVATIONS,
      payload: '',
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_NEW_STATUS,
      payload: undefined,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_ORDER_DRAGGED,
      payload: undefined,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_REPORT_MISSING,
      payload: false,
    });

    restoreStatus?.();
  }, [dispatch, restoreStatus]);

  const handleStatusChange = useCallback(async () => {
    if (state.orderDragged && state.newStatus) {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_UPDATE_STATUS_MODAL_LOADING,
        payload: true,
      });
      try {
        await changeStatus(
          state.orderDragged,
          state.newStatus,
          state.observations,
          state.cancelReason,
          state.reportMissing,
        );

        if (refetchOrders) {
          setTimeout(async () => {
            await refetchOrders();
          }, 1500);
        }
        if (redirect) {
          redirect();
        }
      } catch (error) {
        const err = parsePandasErr(error, 'ORDU500000');
        notifyError?.(t(err.code));
        if (err.code === 'ORDS400018') {
          // Stock error modal open
          dispatch({
            type: ORDER_ACTION_ENUM.SET_OPEN_INSUFFICIENT_STOCK_MODAL,
            payload: true,
          });
          dispatch({
            type: ORDER_ACTION_ENUM.SET_INSUFFICIENT_STOCK_DETAILS,
            payload: err.detail ?? [],
          });
        }
      } finally {
        closeStatusChangeModal();
        dispatch({
          type: ORDER_ACTION_ENUM.SET_UPDATE_STATUS_MODAL_LOADING,
          payload: false,
        });
      }
    }
  }, [
    state,
    changeStatus,
    refetchOrders,
    closeStatusChangeModal,
    dispatch,
    redirect,
    notifyError,
  ]);

  // eslint-disable-next-line consistent-return
  const handlePaymentUpdate = useCallback(async () => {
    if (!state.selectedPaymentStatus)
      return notifyWarning(t('order.paymentStatus.emptyStatus'));

    try {
      await updatePayment?.({
        order: order ?? {},
        paymentStatus: state.selectedPaymentStatus,
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notifyError?.(e.response?.data?.code ?? '');
      }
    } finally {
      if (refetchOrders) {
        setTimeout(async () => {
          await refetchOrders();
        }, 1500);
      }
    }
  }, [
    notifyError,
    notifyWarning,
    order,
    refetchOrders,
    state.selectedPaymentStatus,
    updatePayment,
  ]);

  return {
    onChangeStatus,
    handleDragCard,
    handleCommentChange,
    setCancelReason,
    setReportMissing,
    closeStatusChangeModal,
    handlePaymentUpdate,
    handleStatusChange,
  };
};

export default useOrderStatusChange;
