import { Columns } from 'services/cities/interface';

export const docTypes = {
  CC: 'docTypes.CC',
  CE: 'docTypes.CE',
  TE: 'docTypes.TE',
  NIT: 'docTypes.NIT',
  PS: 'docTypes.PS',
};

export type docTypesKey = keyof typeof docTypes;

export interface DefaultTable<T> {
  list: T[];
  tableRef: React.Ref<{ scrollToTop: () => void }>;
  loading: boolean;
  columns: Columns[];
  reachedEndOfData: boolean;
  onScrollToEnd: () => void;
  onViewDetail: (shippingZones: T) => void;
}
