import { Input, InputNumber } from 'antd';
import styled from 'styled-components';

export const NumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  min-width: 100%;
  -moz-appearance: textfield;
`;

export const DecimalNumberInput = styled(InputNumber)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  min-width: 100%;
  -moz-appearance: textfield;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
