import { useMutation } from '@tanstack/react-query';
import { Dispatch, useEffect } from 'react';
import { createShippingZone } from 'services/cities/cities.service';
import {
  ShippingZoneCreateAction,
  SHIPPING_ZONE_CREATE_ACTION_ENUM,
} from '../reducer/interface';
import useFetchShippingZoneUtils from './useFetchShippingZoneUtils';

const useCreateShippingZone = ({
  dispatch,
}: {
  dispatch: Dispatch<ShippingZoneCreateAction>;
}) => {
  const { mutateAsync: createAsync, isLoading: createLoading } = useMutation({
    mutationFn: createShippingZone,
  });

  const {
    transportList,
    citiesList,
    regionList,
    isLoading: isUtilsLoading,
    ...serviceUtilities
  } = useFetchShippingZoneUtils();

  useEffect(() => {
    if (transportList) {
      dispatch({
        type: SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_TRANSPORT_LIST,
        payload: transportList,
      });
    }
  }, [transportList, dispatch]);

  useEffect(() => {
    if (regionList) {
      dispatch({
        type: SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_REGION_LIST,
        payload: regionList,
      });
    }
  }, [regionList, dispatch]);

  useEffect(() => {
    if (citiesList) {
      dispatch({
        type: SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_CITIES_LIST,
        payload: citiesList,
      });
    }
  }, [citiesList, dispatch]);

  return {
    isLoading: isUtilsLoading,
    createShippingZone: createAsync,
    createLoading,
    ...serviceUtilities,
  };
};

export default useCreateShippingZone;
