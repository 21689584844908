import { Tag } from 'antd';
import { UnitStatusColor } from 'containers/Products/VariantList/interface';
import { t } from 'i18next';
import { useCallback } from 'react';
import { Variant } from 'services/product/interface';
import { UnitStatus } from 'services/unit/interface';

export function StatusColumn() {
  const render = useCallback((variant: Variant) => {
    const status = (variant?.unitStatus ?? UnitStatus.available) as UnitStatus;

    return (
      <Tag color={UnitStatusColor[status]}>
        {t(`wms.unitStatus.${status?.toLowerCase()}`)}
      </Tag>
    );
  }, []);

  return {
    title: t('productTableKeys.status'),
    render,
  };
}

export default StatusColumn;
