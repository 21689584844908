/* eslint-disable react/jsx-props-no-spreading */
import { Table } from 'antd';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import useAttributeTable from './hooks/useAttributeTable';
import { Attribute, AttributeTableProps } from './interface';

const TABLE_HEADER_HEIGHT = 150;

const AttributeTable = ({
  data,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
  onDeleteAttribute,
}: AttributeTableProps) => {
  const tableSize = useTableSize(TABLE_HEADER_HEIGHT);
  const { hiddenDescription, selectOptions, columns } = useAttributeTable({
    selectedRowKeys,
    onDeleteAttribute,
    setSelectedRowKeys,
  });

  return (
    <Table
      {...tableSize}
      loading={loading}
      columns={columns}
      rowKey="id"
      rowSelection={selectOptions}
      expandable={{
        expandedRowRender: (record: Attribute) =>
          hiddenDescription(record),
        rowExpandable: () => true,
      }}
      dataSource={data || []}
      pagination={false}
    />
  );
};

export default AttributeTable;
