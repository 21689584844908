import { MinusCircleOutlined } from '@ant-design/icons';
import { User } from 'services/user/interface';
import { t } from 'i18next';
import { MultiUserAutoCompleteProps } from './interface';
import useMultiUserAutoComplete from './hooks/useMultiUserAutoComplete';
import {
  DeleteButton,
  DeleteButtonContainer,
  InputWrapper,
  UserDataItem,
  UserInfoContainer,
  UserListWrapper,
  UserName,
  UserSummaryContainer,
} from './MultiUserAutoComplete.sty';
import UserAutocomplete from '../UserAutocomplete/UserAutocomplete';

const UserSummary = ({
  user,
  onDeleteUser,
  disabled,
}: {
  user: User;
  onDeleteUser: (deletedUser: User) => void;
  disabled: boolean;
}) => (
  <UserSummaryContainer>
    <UserInfoContainer>
      <UserName>{`${user?.info?.name} ${user?.info?.lastName || ''}`}</UserName>
      <UserDataItem>{`${user?.info?.docType}. ${user?.info?.documentNumber}`}</UserDataItem>
      <UserDataItem>{user?.mainEmail}</UserDataItem>
      <UserDataItem>
        {`${t('multiUserAutoComplete.label.phone')}. ${user?.info?.phoneNumber}`}
      </UserDataItem>
    </UserInfoContainer>
    {!disabled && (
      <DeleteButtonContainer>
        <DeleteButton>
          <MinusCircleOutlined onClick={() => onDeleteUser(user)} />
        </DeleteButton>
      </DeleteButtonContainer>
    )}
  </UserSummaryContainer>
);

export const MultiUserAutoComplete = ({
  value = [] as User[],
  onChange,
  onSearchExhaust,
  queryParams,
  disabled,
  cleanOnSelect,
}: MultiUserAutoCompleteProps) => {
  const { handleAddUser, handleDeleteUser } = useMultiUserAutoComplete(
    value,
    onChange,
  );
  return (
    <InputWrapper>
      <UserAutocomplete
        onSearchExhaust={onSearchExhaust}
        queryParams={queryParams}
        disabled={disabled}
        onChange={handleAddUser}
        cleanOnSelect={cleanOnSelect}
      />
      {!!value.length && (
        <UserListWrapper>
          {value.map((user: User) => (
            <UserSummary
              key={user.id}
              disabled={!!disabled}
              user={user}
              onDeleteUser={handleDeleteUser}
            />
          ))}
        </UserListWrapper>
      )}
    </InputWrapper>
  );
};
