import { PaymentUpdateStatus } from 'modules/Order/interface';
import {
  InsufficientStockDetails,
  Order,
  OrderFilters,
  StatusOrderEnum,
} from 'services/order/interface';
import {
  DeliveryOption,
  DeliveryRangeOption,
} from 'services/shipping/interface';

export enum ORDER_VIEWS_ENUM {
  LIST = 'LIST',
  BOARD = 'BOARD',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum ORDER_ACTION_ENUM {
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_FILTERS = 'SET_FILTERS',
  SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES',
  SET_READONLY = 'SET_READONLY',
  SET_STEP = 'SET_STEP',
  SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS',
  SET_SELECTED_ROWKEYS = 'SET_SELECTED_ROWKEYS',
  SET_OPEN_MULTI_LEGALIZE = 'SET_OPEN_MULTI_LEGALIZE',
  SET_OPEN_MULTI_STATUS_CHANGE = 'SET_OPEN_MULTI_STATUS_CHANGE',
  SET_CANCEL_MODAL_VISIBLE = 'SET_CANCEL_MODAL_VISIBLE',
  SET_CANCEL_REASON = 'SET_CANCEL_REASON',
  SET_OPEN_UPDATE_CONFIRM_MODAL = 'SET_OPEN_UPDATE_CONFIRM_MODAL',
  SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS',
  SET_DISABLE_VARIANT_AUTOCOMPLETE = 'SET_DISABLE_VARIANT_AUTOCOMPLETE',
  SET_OPEN_RESCHEDULE_MODAL = 'SET_OPEN_RESCHEDULE_MODAL',
  SET_RESCHEDULE_REASON = 'SET_RESCHEDULE_REASON',
  SET_MAX_DISCOUNT = 'SET_MAX_DISCOUNT',
  SET_OPEN_UPDATE_STATUS_MODAL = 'SET_OPEN_UPDATE_STATUS_MODAL',
  SET_NEW_STATUS = 'SET_NEW_STATUS',
  SET_ORDER_DRAGGED = 'SET_ORDER_DRAGGED',
  SET_OBSERVATIONS = 'SET_OBSERVATIONS',
  SET_REPORT_MISSING = 'SET_REPORT_MISSING',
  SET_UPDATE_STATUS_MODAL_LOADING = 'SET_UPDATE_STATUS_MODAL_LOADING',
  SET_DELIVERY_DATE_OPTIONS = 'SET_DELIVERY_DATE_OPTIONS',
  SET_SEARCH_FILTER = 'SET_SEARCH_FILTER',
  SET_OPEN_INSUFFICIENT_STOCK_MODAL = 'SET_OPEN_INSUFFICIENT_STOCK_MODAL',
  SET_INSUFFICIENT_STOCK_DETAILS = 'SET_INSUFFICIENT_STOCK_DETAILS',
  SET_DETAIL_EDITABLE_FOR_STOCK = 'SET_DETAIL_EDITABLE_FOR_STOCK',
}

export type OrderAction = {
  type: ORDER_ACTION_ENUM;
  payload: any;
};

export interface OrderState {
  currentView: ORDER_VIEWS_ENUM;
  filters: OrderFilters;
  searchFilter?: string;
  selectedOrders: Order[];
  selectedRowKeys: React.Key[];
  openMultiLegalize: boolean;
  openMultiStatusChange: boolean;
  cancelModalVisible: boolean;
  cancelReason?: string;
  rescheduleReason?: string;
  newStatus?: StatusOrderEnum;
  orderDragged?: Pick<
    Order,
    'id' | 'status' | 'orderNumber' | 'bankTransferPaid' | 'paymentMethod'
  >;
  observations?: string;
  reportMissing?: boolean;
  updateStatusModalLoading: boolean;
  openUpdateStatusModal: boolean;

  formInitialValues: Order;
  readOnly: boolean;
  step: number;
  openUpdateConfirmModal: boolean;
  openRescheduleModal: boolean;
  disableMpsVariantAutocomplete: boolean; // idk what is this
  maxDiscount: number;
  deliveryRangeOptions: DeliveryRangeOption[];
  deliveryOptions: DeliveryOption[];
  selectedPaymentStatus?: PaymentUpdateStatus;

  insufficientStockModalOpen: boolean;
  insufficientStockDetails: InsufficientStockDetails[];
  editableForStock: boolean;
}
