import { Method } from 'axios';

export const endpoints = {
  getBrandList: {
    method: 'GET' as Method,
    url: '/brand-dom/brand',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createBrand: {
    method: 'POST' as Method,
    url: '/brand-dom/brand',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateBrand: {
    method: 'PUT' as Method,
    url: '/brand-dom/brand/{params.brandId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getBrandById: {
    method: 'GET' as Method,
    url: '/brand-dom/brand/{params.brandId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteBrandById: {
    method: 'DELETE' as Method,
    url: '/brand-dom/brand/{params.brandId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
