import { ROUTES } from 'helpers/constants/routes';
import { ActionsTypes, RoleEnum } from 'helpers/hooks/useRoleAccess/interface';

export const RolesActions: ActionsTypes = {
  general: {
    backoffice: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.SELLER_COORDINATOR,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  supplier: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  user: {
    adminCreate: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    setRoles: {
      roles: [RoleEnum.ADMIN, RoleEnum.SELLER_COORDINATOR],
      scope: ['BACKOFFICE'],
    },
    editRoles: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    setStatus: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    preFillCoordinator: {
      roles: [RoleEnum.SELLER_COORDINATOR],
      scope: ['BACKOFFICE'],
    },
  },
  order: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.SELLER_COORDINATOR,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  purchase: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  category: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  product: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  attributes: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  wallet: {
    all: {
      roles: [RoleEnum.FINANCE],
      scope: ['BACKOFFICE'],
    },
  },
  coupon: {
    create: {
      roles: [RoleEnum.GROWTH],
      scope: ['BACKOFFICE'],
    },
    seeCode: {
      roles: [RoleEnum.GROWTH],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.GROWTH],
      scope: ['BACKOFFICE'],
    },
  },
  brands: {
    create: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    seeCode: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
  },
  stores: {
    create: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
  },
  cities: {
    create: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
  },
  credit: {
    create: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
  },
  pandabono: {
    create: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
  },
  haulier: {
    create: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    editActions: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    deleteActions: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
  },
  routes: {
    [ROUTES.home]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.haulier]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.wallet]: {
      roles: [RoleEnum.FINANCE],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.invoiceReport]: {
      roles: [RoleEnum.FINANCE],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.login]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.product]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.attributes]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createAttribute]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createProduct]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.updateProduct]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.productList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.productList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.updateVariantsMassive]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.transfers]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.transfersCsv]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.parameters]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.bannersList]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.locationTree]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.wmsSettings]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.wmsSettingUpdate]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.purchase]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.order]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createOrder]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.orderUpdate]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.order]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.orderPackages]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.couponsList]: {
      roles: [RoleEnum.GROWTH],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.brandsList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.brands]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.stores]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.storesList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },

    [ROUTES.category]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createCategory]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.categoryList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.updateCategory]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createUser]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.user]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.createSupplier]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.WAREHOUSE_ASSISTANT,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.userList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.supplierList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.variantsMassive]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.updateAvailableMassive]: {
      roles: [RoleEnum.ADMIN, RoleEnum.WAREHOUSE_MANAGER],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.updateStock]: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.creditList]: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.pandabonos]: {
      roles: [RoleEnum.ADMIN],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.routesCsv]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.mpsUser]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.mpsUserList]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.shippingZones]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.WAREHOUSE_ASSISTANT,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.shippingZoneUpdate]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.WAREHOUSE_ASSISTANT,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.shippingZoneCreate]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.WAREHOUSE_ASSISTANT,
      ],
      scope: ['BACKOFFICE'],
    },
    [ROUTES.transport]: {
      roles: [
        RoleEnum.ADMIN,
        RoleEnum.CUSTOMER_SERVICE,
        RoleEnum.WAREHOUSE_ASSISTANT,
        RoleEnum.WAREHOUSE_MANAGER,
        RoleEnum.SELLER_COORDINATOR,
      ],
      scope: ['BACKOFFICE'],
    },
  },
};
