import {
  CloseOutlined,
  ContainerOutlined,
  EditOutlined,
  PicRightOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Col, Row, Space, Tag, Tooltip, Typography } from 'antd';
import Crown from 'assets/images/crown.svg';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { t } from 'i18next';
import {
  Order,
  PandasPaymentMethod,
  PaymentData,
  PaymentMethodStatus,
  StatusOrderEnum,
  StatusTagColor,
  ValidOrderStatuses,
} from 'services/order/interface';
import MultiOrderSelector from '../../MultiOrderSelector';
import { StatusTagImage, TagImage } from '../../OrderCard/OrderCard.sty';
import {
  ButtonCol,
  CloseUpdate,
  MainActionsCol,
  MpsName,
  OrderNumberTitle,
  OrderNumberWrapper,
  PrimaryButton,
  SelectorsContainer,
  SubmitButton,
  TitleDivider,
} from '../OrderForm.sty';

export interface OrderFormHeaderProps {
  orderData: Order;
  title: string;
  disableEditButton: boolean;
  canEditOrder: boolean;
  loadingPendingStockUpdate?: boolean;
  canUpdatePendingStock?: boolean;
  onEnableForm?: () => void;
  onGoToOrder?: (order: Partial<Order>) => void;
  updatePendingStock?: () => void;
  updateBankDeposit?: () => void;
  onViewDropshipperClientRemission?: (id: string) => void;
  onViewRemission?: (id: string) => void;
  onViewPickingSheet?: (id: string) => void;
  goToList: () => void;
  initialValues?: Order;
  shippingPayment?: PaymentData;
}

const OrderFormHeader = ({
  orderData,
  title,
  disableEditButton,
  canEditOrder,
  loadingPendingStockUpdate,
  canUpdatePendingStock,
  onEnableForm,
  onGoToOrder,
  updatePendingStock,
  onViewDropshipperClientRemission,
  onViewRemission,
  onViewPickingSheet,
  goToList,
  initialValues,
  shippingPayment,
}: OrderFormHeaderProps) => {
  const { AccessWrapper } = useRoleAccess('order.editActions');
  return (
    <Row>
      <Col
        span={24}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography.Title level={2} style={{ marginBottom: 0, height: '100%' }}>
          {t(title)}
        </Typography.Title>
        <SelectorsContainer>
          {onGoToOrder && orderData?.warrantyOrders?.length ? (
            <MultiOrderSelector
              key="warrantyOrders"
              field="warrantyOrders"
              orderData={orderData}
              changeDrawerOrder={onGoToOrder}
            />
          ) : (
            <div />
          )}
        </SelectorsContainer>
        <CloseUpdate onClick={goToList}>
          <CloseOutlined />
        </CloseUpdate>
      </Col>
      <TitleDivider />
      <Col span={24}>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <OrderNumberWrapper>
              <Space direction="horizontal">
                {orderData?.orderNumber && (
                  <OrderNumberTitle>
                    {t('orderForm.orderNumber', {
                      orderNumber: orderData?.orderNumber,
                    })}
                  </OrderNumberTitle>
                )}
                {shippingPayment?.status === PaymentMethodStatus.PENDING && (
                  <CustomTag color={StatusTagColor.PENDING_SHIPPING_PAYMENT}>
                    {t('orderForm.tags.pendingPaymentShipping')}
                  </CustomTag>
                )}
                {shippingPayment?.status === PaymentMethodStatus.REJECTED && (
                  <CustomTag color={StatusTagColor.REJECTED_SHIPPING_PAYMENT}>
                    {t('orderForm.tags.rejectedPaymentShipping')}
                  </CustomTag>
                )}
                {orderData?.paymentMethod ===
                  PandasPaymentMethod.BANK_TRANSFER && (
                  <>
                    <CustomTag color={StatusTagColor.BANK_DEPOSIT}>
                      {t(`orderStatus.bankDeposit`)}
                    </CustomTag>
                    {orderData?.bankTransferPaid ? (
                      <CustomTag color={LightTheme.color.info}>
                        {t('orderForm.tags.paid')}
                      </CustomTag>
                    ) : (
                      <CustomTag color={LightTheme.color.danger}>
                        {t('orderForm.tags.notPaid')}
                      </CustomTag>
                    )}
                  </>
                )}
                {orderData?.isDropShipper && (
                  <Tag color="gold">{t('orderForm.tags.isDropShipper')}</Tag>
                )}
                {orderData?.isWarranty && (
                  <Tag color="gold">{t('orderForm.tags.isWarranty')}</Tag>
                )}
                {orderData?.isFirstBuy && (
                  <Tag color={StatusTagColor.FIRST_ORDER}>
                    {t('orderStatus.newClient')}
                  </Tag>
                )}
                {orderData?.user?.isPrime ? (
                  <StatusTagImage color={StatusTagColor.PRIME}>
                    <TagImage src={Crown} />
                    {t('orderStatus.PRIME')}
                  </StatusTagImage>
                ) : null}
                {orderData?.reportMissing ? (
                  <StatusTagImage color={StatusTagColor.MISSING}>
                    {t('orderStatus.MISSING')}
                  </StatusTagImage>
                ) : null}
                {orderData?.payment?.credit?.amount &&
                orderData?.payment?.credit?.amount > 0 ? (
                  <Tag color={StatusTagColor.CREDIT}>
                    {t('orderStatus.credit')}
                  </Tag>
                ) : null}
              </Space>
              {orderData?.mps ? (
                <MpsName>
                  {orderData?.mps?.map((m) => m.name)?.join(' | ') ||
                    orderData?.mps?.map((m) => m.contactName)?.join(' | ')}
                </MpsName>
              ) : null}
            </OrderNumberWrapper>
          </Col>
          <MainActionsCol span={12}>
            <AccessWrapper>
              <Space>
                {canUpdatePendingStock && (
                  <ButtonCol>
                    <SubmitButton
                      type="primary"
                      onClick={updatePendingStock}
                      loading={loadingPendingStockUpdate}
                    >
                      {t('orderForm.modal.buttonTexts.updateStock')}
                    </SubmitButton>
                  </ButtonCol>
                )}
                {onViewPickingSheet &&
                  initialValues?.status === StatusOrderEnum.IN_PICKING && (
                    <Tooltip
                      title={t('orderForm.modal.buttonTexts.pickingSheet')}
                    >
                      <PrimaryButton
                        type="primary"
                        style={{
                          backgroundColor: LightTheme.color.dark_orange,
                        }}
                        icon={<PicRightOutlined />}
                        shape="circle"
                        onClick={() =>
                          onViewPickingSheet?.(initialValues?.id ?? '')
                        }
                      />
                    </Tooltip>
                  )}
                {orderData?.isDropShipper &&
                  ValidOrderStatuses.includes(
                    orderData?.status as StatusOrderEnum,
                  ) &&
                  onViewDropshipperClientRemission && (
                    <Tooltip
                      title={t(
                        'orderForm.modal.buttonTexts.dropshipperRemission',
                      )}
                    >
                      <PrimaryButton
                        type="primary"
                        style={{
                          backgroundColor: LightTheme.color.light_yellow,
                        }}
                        icon={<ProfileOutlined style={{ color: '#555' }} />}
                        shape="circle"
                        onClick={() =>
                          onViewDropshipperClientRemission?.(
                            initialValues?.saleOrderId ||
                              initialValues?.id ||
                              '',
                          )
                        }
                      />
                    </Tooltip>
                  )}
                {onViewRemission && (
                  <Tooltip
                    title={t('orderForm.modal.buttonTexts.orderRemission')}
                  >
                    <PrimaryButton
                      type="primary"
                      icon={<ContainerOutlined />}
                      shape="circle"
                      onClick={() => {
                        onViewRemission?.(orderData?.id || '');
                      }}
                    />
                  </Tooltip>
                )}
                {canEditOrder && (
                  <Tooltip title={t('orderForm.modal.buttonTexts.update')}>
                    <PrimaryButton
                      type="primary"
                      icon={<EditOutlined />}
                      shape="circle"
                      disabled={disableEditButton}
                      onClick={onEnableForm}
                    />
                  </Tooltip>
                )}
              </Space>
            </AccessWrapper>
          </MainActionsCol>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderFormHeader;
