import {
  ApartmentOutlined,
  BookOutlined,
  CarOutlined,
  ControlOutlined,
  DropboxOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  FileSyncOutlined,
  FolderOpenOutlined,
  GoldOutlined,
  HomeOutlined,
  OrderedListOutlined,
  PictureOutlined,
  SettingFilled,
  SwapOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ROUTES } from 'helpers/constants/routes';

export const LAYOUT_ROUTES = [
  {
    path: ROUTES.home,
    name: 'menu.home',
    icon: HomeOutlined,
  },
  {
    path: ROUTES.order,
    name: 'menu.order',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.order,
        name: 'menu.orderList',
        icon: UnorderedListOutlined,
      },
      {
        path: ROUTES.createOrder,
        name: 'menu.createOrder',
        icon: FileAddOutlined,
      },
    ],
  },
  {
    path: ROUTES.purchase,
    name: 'menu.purchase',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.purchase,
        name: 'menu.purchaseList',
        icon: UnorderedListOutlined,
      },
      {
        path: ROUTES.createSupplier,
        name: 'menu.createSupplier',
        icon: UserAddOutlined,
      },
      {
        path: ROUTES.supplierList,
        name: 'menu.supplierList',
        icon: OrderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.wms,
    name: 'menu.wms',
    icon: GoldOutlined,
    subMenus: [
      {
        path: ROUTES.transfers,
        name: 'menu.transfers',
        icon: SwapOutlined,
      },
      {
        path: ROUTES.transfersCsv,
        name: 'menu.transfersCsv',
        icon: FileSyncOutlined,
      },
      {
        path: ROUTES.locationTree,
        name: 'menu.locationTree',
        icon: ApartmentOutlined,
      },
      {
        path: ROUTES.wmsSettings,
        name: 'menu.wmsSettings',
        icon: SettingFilled,
      },
    ],
  },
  {
    path: ROUTES.mpsUser,
    name: 'menu.mps',
    icon: GoldOutlined,
    subMenus: [
      {
        path: ROUTES.mpsUserList,
        name: 'menu.mpsList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.shippingZones,
    name: 'menu.cities',
    icon: CarOutlined,
    subMenus: [
      {
        path: ROUTES.shippingZones,
        name: 'menu.citiesList',
        icon: UnorderedListOutlined,
      },
      {
        path: ROUTES.transport,
        name: 'menu.transport',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.coupons,
    name: 'menu.coupons',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.couponsList,
        name: 'menu.counponsList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.wallet,
    name: 'menu.finance',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.invoiceReport,
        name: 'menu.invoiceReport',
        icon: UnorderedListOutlined,
      },
      {
        path: ROUTES.wallet,
        name: 'menu.walletList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.brands,
    name: 'menu.brands',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.brandsList,
        name: 'menu.brandsList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.parameters,
    name: 'menu.parameters',
    icon: ControlOutlined,
    subMenus: [
      {
        path: ROUTES.bannersList,
        name: 'menu.bannersList',
        icon: PictureOutlined,
      },
    ],
  },
  {
    path: ROUTES.haulier,
    name: 'menu.haulier',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.haulier,
        name: 'menu.haulierList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.stores,
    name: 'menu.stores',
    icon: UnorderedListOutlined,
    subMenus: [
      {
        path: ROUTES.storesList,
        name: 'menu.storesList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.user,
    name: 'menu.user',
    icon: UserOutlined,
    subMenus: [
      {
        path: ROUTES.createUser,
        name: 'menu.createUser',
        icon: UserAddOutlined,
      },
      {
        path: ROUTES.userList,
        name: 'menu.userList',
        icon: OrderedListOutlined,
      },
      {
        path: ROUTES.creditList,
        name: 'menu.creditList',
        icon: OrderedListOutlined,
      },
      {
        path: ROUTES.pandabonos,
        name: 'menu.pandabonos',
        icon: OrderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.product,
    name: 'menu.product',
    icon: DropboxOutlined,
    subMenus: [
      {
        path: ROUTES.createProduct,
        name: 'menu.createProduct',
        icon: FileAddOutlined,
      },
      {
        path: ROUTES.productList,
        name: 'menu.productList',
        icon: UnorderedListOutlined,
      },
      {
        path: ROUTES.attributes,
        name: 'menu.attributesList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.category,
    name: 'menu.category',
    icon: FolderOpenOutlined,
    subMenus: [
      {
        path: ROUTES.createCategory,
        name: 'menu.createCategory',
        icon: FileAddOutlined,
      },
      {
        path: ROUTES.categoryList,
        name: 'menu.categoryList',
        icon: UnorderedListOutlined,
      },
    ],
  },
  {
    path: ROUTES.routes,
    name: 'menu.routes',
    icon: CarOutlined,
    subMenus: [
      {
        path: ROUTES.routesCsv,
        name: 'menu.routesCsv',
        icon: FileExcelOutlined,
      },
    ],
  },
  {
    path: ROUTES.variantsMassive,
    name: 'menu.inventory',
    icon: BookOutlined,
    subMenus: [
      {
        path: ROUTES.updateVariantsMassive,
        name: 'menu.uploadMassive',
        icon: FileExcelOutlined,
      },
      {
        path: ROUTES.updateStock,
        name: 'menu.updateStock',
        icon: FileExcelOutlined,
      },
      {
        path: ROUTES.updateAvailableMassive,
        name: 'menu.updateAvailableMassive',
        icon: FileExcelOutlined,
      },
    ],
  },
];
