import { parameters } from 'helpers/recoil/atoms/parameters';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import maintenanceIcon from '../../assets/images/maintenance.svg';
import {
  ContentWrapper,
  Description,
  LogoWrapper,
  Title,
} from './Maintenance.sty';

const Maintenance = () => {
  const { t } = useTranslation();
  const { maintenance } = useRecoilValue(parameters);

  return (
    <ContentWrapper>
      <LogoWrapper>
        <img src={maintenanceIcon} alt="Maintenance icon" />
      </LogoWrapper>
      <Title>{t('maintenance.title')}</Title>
      <Description>
        {t('maintenance.description', {
          hour: maintenance?.data?.hour,
          date: maintenance?.data?.date,
        })}
      </Description>
    </ContentWrapper>
  );
};

export default Maintenance;
