import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import { AxiosResponse } from 'axios';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../interface';

const useAttributeContent = () => {
  const { t } = useTranslation();
  const selectedRowKeysState = useState<Key[]>([]);
  const [selectedRowKeys] = selectedRowKeysState;
  const loadingDeleteState = useState<boolean>(false);
  const [_loadingDelete, setLoadingDelete] = loadingDeleteState;
  const showDeleteModalState = useState<boolean>(false);
  const [_, setShowDeleteModal] = showDeleteModalState;
  const deleteAttributeIdState = useState<string>('');
  const searchKeyState = useState<string>('');
  const showDeleteSingleModalState = useState<boolean>(false);
  const showCreateAttributeModalState = useState<boolean>(false);
  const attributeState = useState<Attribute[]>([]);
  const tablePagination = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 1000,
  });

  const {
    readQuery: {
      mutateAsync: getAttributeList,
      data: attributeList,
      isLoading: isLoadingList,
    },
    deleteMutation: {
      mutateAsync: deleteAttributeById,
      isLoading: isLoadingDeleteAttribute,
    },
  } = useModuleCRUD('attributes');

  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  useEffect(() => {
    getAttributeList(undefined);
  }, [getAttributeList]);

  const onConfirmDeleteSelection = async () => {
    setShowDeleteModal(false);
    setLoadingDelete(true);
    try {
      const res = await Promise.all(
        selectedRowKeys.map((attributeId: Key) =>
          deleteAttributeById(attributeId as string),
        ),
      );
      res.forEach((response: AxiosResponse) => {
        const { status } = response;
        if (status < 300) {
          notifySuccess('attributesForm.modal.message.deleteSuccess');
        }
        if (status >= 400) {
          notifyError('attributesForm.modal.message.deleteError');
        }
      });
      setLoadingDelete(false);
    } catch (error) {
      console.error(error);
      setLoadingDelete(false);
      notifyError('attributesForm.modal.message.deleteError');
    }
    getAttributeList(undefined);
  };

  return {
    selectedRowKeysState,
    loadingDeleteState,
    showDeleteModalState,
    deleteAttributeIdState,
    showDeleteSingleModalState,
    searchKeyState,
    attributeState,
    tablePagination,
    onConfirmDeleteSelection,
    getAttributeList,
    attributeList,
    isLoadingList,
    deleteAttributeById,
    isLoadingDeleteAttribute,
    showCreateAttributeModalState,
  };
};

export default useAttributeContent;
