import { InputNumber } from 'antd';
import styled from 'styled-components';

export const NumberInput = styled(InputNumber)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  min-width: 100%;
  -moz-appearance: textfield;
`;
