/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, Divider, Input, Row, Select, Table } from 'antd';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import { TFunction } from 'react-i18next';
import { MPS } from 'services/MPS/interface';
import { BillingType } from 'services/product/interface';
import useSuppliersTableModule from '../../hooks/useSuppliersTableModule';

const TABLE_HEADER_HEIGHT = 150;

export const SupplierTable = ({
  data,
  isLoading,
  totalData,
  pagination,
  onUpdateSupplier,
  onSetPagination,
  onChangeSearch,
  handleChangeSupplierFilter,
  handleChangeBillingTypeFilter,
  goToCreate,
  t,
}: {
  data: MPS[];
  totalData: number;
  pagination: PaginationParams;
  isLoading: boolean;
  onUpdateSupplier: (supplier: MPS) => void;
  onSetPagination: (paginationData: PaginationParams) => void;
  onChangeSearch: (search: string) => void;
  handleChangeSupplierFilter: (value: any) => void;
  handleChangeBillingTypeFilter: (value: any) => void;
  goToCreate: () => void;
  t: TFunction;
}) => {
  const { columns, hiddenDescription } = useSuppliersTableModule({
    onUpdateSupplier,
  });
  const tableSize = useTableSize(TABLE_HEADER_HEIGHT);

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 16 }} lg={{ span: 8 }}>
          <Input.Search onChange={handleChangeSearchValue} />
        </Col>
        <Col xs={{ span: 16 }} lg={{ span: 4 }}>
          <Select
            style={{ width: '100%' }}
            onChange={handleChangeSupplierFilter}
            placeholder={t('supplier.filters.isSupplier')}
            options={[
              { label: 'Todos', value: '' },
              { label: 'Proveedores', value: true },
              { label: 'Sellers', value: false },
            ]}
          />
        </Col>
        <Col xs={{ span: 16 }} lg={{ span: 4 }}>
          <Select
            style={{ width: '100%' }}
            onChange={handleChangeBillingTypeFilter}
            placeholder={t('supplier.filters.billingType')}
            options={[
              { label: 'Todos', value: '' },
              { label: t('billingType.PRODUCT'), value: BillingType.PRODUCT },
              { label: t('billingType.SERVICE'), value: BillingType.SERVICE },
            ]}
          />
        </Col>
        <Col xs={{ span: 6, offset: 1 }} lg={{ span: 4, offset: 1 }}>
          <Button type="primary" onClick={goToCreate}>
            {t('supplier.createSupplier')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        {...tableSize}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => hiddenDescription(record),
          rowExpandable: () => true,
        }}
        pagination={{
          total: totalData,
          current: pagination.page,
          defaultPageSize: pagination.pageSize || 50,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000'],
          onChange(page, pageSize) {
            onSetPagination({ page, pageSize });
          },
        }}
        dataSource={data || []}
      />
    </>
  );
};
