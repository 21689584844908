import { Input } from 'antd';
import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import { LayoutContainer, Title } from 'pages/App/Layout.sty';
import styled from 'styled-components';

export const NumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const RichTextEditor = styled.div`
  & div.jodit-ui-group_group_script {
    display: none;
  }
  span,
  li,
  p,
  div {
    line-height: 135%;
  }
`;

export const InnerTitle = styled(Title)`
  color: ${LightTheme.color.dark} !important;
`;

export const CreateProductContainer = styled(LayoutContainer)`
  overflow-y: auto;
  max-height: 69vh;
  height: fit-content;
`;

export const AttributeLevelContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${spaceSizes.m} 0;
  gap: ${spaceSizes.m};
`;

export const AttributeLevelName = styled.div`
  padding: ${spaceSizes.m};
  background-color: white;
  border: 1px dashed #bbb;
  width: 100%;
`;

export const AttributeLevelTitle = styled.p`
  margin: 0 ${spaceSizes.m} 0 0;
  white-space: nowrap;
`;
