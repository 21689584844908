import { getAPIMethod } from 'helpers/libs/Http';
import { IPackageForm } from 'modules/Order/interface';
import { Package } from './interface';

export const getOrderPackages = async (id: string): Promise<Package[]> => {
  const response = await getAPIMethod({
    name: 'getPackageByOrderId',
    pathParams: {
      orderId: id,
    },
  });
  return response.data;
};

export const updatePackage = async (body: IPackageForm): Promise<Package[]> => {
  const response = await getAPIMethod({
    name: 'updatePackage',
    pathParams: {
      packageId: body.id,
    },
    data: body,
  });
  return response.data;
};

export const getCollectionRecords = async (
  ordersIds: string[],
  sellersIds: string[],
): Promise<string> => {
  const response = await getAPIMethod({
    name: 'getCollectionRecords',
    data: { ordersIds, sellersIds },
  });

  return response.data;
};

export const getShippingGuides = async (
  ordersIds: string[],
  sellersIds: string[],
): Promise<string> => {
  const response = await getAPIMethod({
    name: 'getShippingGuides',
    data: { ordersIds, sellersIds },
  });

  return response.data;
};
