import { Collapse, Form, Input } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { UploadFile } from 'antd/lib/upload/interface';
import UploadItem from 'components/Upload/UploadItem';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBannerForm } from 'services/parameters/interface';
import { v4 as uuid } from 'uuid';
import { StyledBannerForm } from './BannerForm.sty';
import {
  BANNERS_ALLOWED_FORMATS,
  BANNERS_FILE_FOLDER,
  BANNER_DEFAULT_IMAGE_NAME,
  BANNER_FORM_COLLAPSE_KEY,
  BANNER_FORM_ITEM_IMAGE_NAME,
  BANNER_FORM_ITEM_LINK_DESCRIPTION,
  BANNER_FORM_ITEM_LINK_NAME,
  BANNER_FORM_ITEM_LINK_TITLE,
  BANNER_FORM_LAYOUT,
  BANNER_FORM_NAME,
  MAX_UPLOAD_ITEMS,
} from './constants';
import { BannerFormProps } from './interface';
import { BannerImageRules, BannerLinkRules } from './utils';

const BannerForm = ({ initialValues, form, onSubmit }: BannerFormProps) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onUploadBanner = useCallback(
    (file: UploadFile<unknown>) => {
      setFileList([file]);
      form.setFieldsValue({ image: file.url });
    },
    [form],
  );

  const onRemoveBanner = useCallback(() => {
    setFileList([]);
    form.setFieldsValue({ image: null });
  }, [form]);

  useEffect(() => {
    if (initialValues.image) {
      setFileList([
        {
          name: BANNER_DEFAULT_IMAGE_NAME,
          uid: uuid(),
          url: initialValues.image,
        },
      ]);
    }
  }, [initialValues]);

  return (
    <StyledBannerForm<IBannerForm>
      name={BANNER_FORM_NAME}
      layout={BANNER_FORM_LAYOUT}
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
    >
      <Form.Item
        required
        name={BANNER_FORM_ITEM_IMAGE_NAME}
        label={t('banners.form.labels.image')}
        rules={BannerImageRules}
      >
        <UploadItem
          folder={BANNERS_FILE_FOLDER}
          maxLength={MAX_UPLOAD_ITEMS}
          format={BANNERS_ALLOWED_FORMATS}
          fileList={fileList}
          onUpload={onUploadBanner}
          onRemove={onRemoveBanner}
        />
      </Form.Item>
      <Form.Item
        required
        rules={BannerLinkRules}
        label={t('banners.form.labels.link')}
        name={BANNER_FORM_ITEM_LINK_NAME}
      >
        <Input />
      </Form.Item>
      <Collapse>
        <CollapsePanel
          key={BANNER_FORM_COLLAPSE_KEY}
          header={t('banners.form.labels.additionalInfo')}
        >
          <Form.Item
            label={t('banners.form.labels.title')}
            name={BANNER_FORM_ITEM_LINK_TITLE}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('banners.form.labels.description')}
            name={BANNER_FORM_ITEM_LINK_DESCRIPTION}
          >
            <Input />
          </Form.Item>
        </CollapsePanel>
      </Collapse>
    </StyledBannerForm>
  );
};

export default BannerForm;
