import { FormInstance } from 'antd';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { Dispatch, SetStateAction } from 'react';

export interface BrandsAutocompleteProps {
  setBrandValue: Dispatch<SetStateAction<BrandDBEntity>>;
  initialValue: BrandDBEntity;
  form: FormInstance;
}

export const nullishProductBrand: BrandDBEntity = {
  id: '',
  logo: { id: '', url: '', name: '' },
  name: 'Sin Marca',
  ranking: 1,
  createdAt: '',
  deletedAt: '',
  updatedAt: '',
};
