import { Behavior, WmsSetting } from 'services/wms-settings/interface';

export enum SETTING_LIST_ACTION_ENUM {
  SET_SETTINGS_LIST = 'SET_SETTINGS_LIST',
}

export type SettingListAction = {
  type: SETTING_LIST_ACTION_ENUM;
  payload: any;
};

export interface SettingListState {
  settingsList: WmsSetting[];
  page: number;
  pageSize: number;
}

export enum SETTING_UPDATE_ACTION_ENUM {
  SET_SETTING = 'SET_SETTING',
  DELETE_BEHAVIOR = 'DELETE_BEHAVIOR',
  CREATE_BEHAVIOR = 'CREATE_BEHAVIOR',
  TURN_CREATE_MODAL = 'TURN_CREATE_MODAL',
  TURN_BEHAVIOR_EDITION_MODAL = 'TURN_BEHAVIOR_EDITION_MODAL',
  TURN_EDITION_MODE = 'TURN_EDITION_MODE',
  SET_EDITED_BEHAVIOR = 'EDITED_BEHAVIOR',
}

export type SettingUpdateAction = {
  type: SETTING_UPDATE_ACTION_ENUM;
  payload: any;
};

export interface SettingUpdateState {
  setting: WmsSetting;
  createBehaviorOpen: boolean;
  editBehaviorOpen: boolean;
  editionMode: boolean;
  editedBehavior?: Behavior;
  editedBehaviorIndex: number;
}
