import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from 'antd';
import moment from 'moment';

interface OrderInvoicesFormProps {
  disableForm: boolean;
  t: (...args0: any) => string;
}

const OrderInvoicesForm = ({ disableForm, t }: OrderInvoicesFormProps) => {
  return (
    <Col span={24}>
      <Form.List name="billing">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.name} gutter={[16, 16]}>
                <Form.Item
                  hidden
                  name={[field.name, 'invoiceId']}
                  initialValue="MANUALLY_SET_INVOICE"
                />
                <Col span={6}>
                  <Form.Item
                    name={[field.name, 'date']}
                    label={t('orderForm.billing.date')}
                    rules={[
                      {
                        required: true,
                        message: t('orderForm.errors.billingDate'),
                      },
                    ]}
                    getValueFromEvent={(onChange) =>
                      onChange && moment(onChange).format('YYYY-MM-DD')
                    }
                    getValueProps={(i) => ({
                      value: !i ? undefined : moment(i),
                    })}
                  >
                    <DatePicker
                      placeholder={t('orderForm.labels.billingDate')}
                      format="YYYY-MM-DD"
                      disabled={disableForm}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    key={field.name}
                    label={t('orderForm.billing.invoiceNumber')}
                    name={[field.name, 'invoiceNumber']}
                    rules={[
                      {
                        required: true,
                        message: t('orderForm.errors.invoiceNumber'),
                      },
                    ]}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    key={field.name}
                    label={t('orderForm.billing.type')}
                    initialValue="PRODUCT"
                    name={[field.name, 'type']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input type!',
                      },
                    ]}
                  >
                    <Select
                      defaultValue="PRODUCT"
                      disabled={disableForm}
                      options={[
                        { value: 'PRODUCT', label: 'Pandas' },
                        {
                          value: 'SERVICE',
                          label: 'Servicios',
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    key={field.name}
                    valuePropName="checked"
                    initialValue={false}
                    label={t('orderForm.billing.isMandatedBilling')}
                    name={[field.name, 'isMandatedBilling']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input isMandatedBilling!',
                      },
                    ]}
                  >
                    <Switch defaultChecked={false} disabled={disableForm} />
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Button
                    type="dashed"
                    danger
                    icon={<DeleteOutlined />}
                    disabled={disableForm}
                    onClick={() => remove(field.name)}
                  />
                </Col>
              </Row>
            ))}
            <Button disabled={disableForm} type="dashed" onClick={() => add()}>
              {t('orderForm.billing.addInvoice')}
            </Button>
          </>
        )}
      </Form.List>
    </Col>
  );
};

export default OrderInvoicesForm;
