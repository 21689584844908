import { PlusOutlined } from '@ant-design/icons';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AddButton } from 'components/AddButton';
import {
  HaulierFormInitialValues,
  HaulierModalAction,
} from 'components/HaulierForm/interface';
import useModal from 'helpers/hooks/useModal/useModal';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { FilterCol } from 'modules/Order/components/OrderContent/OrderContent.sty';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Haulier } from '../../../services/haulier/interface/index';

const DEFAULT_PAGE_SIZE = 30;

const useHaulierList = ({
  searchKey,
  tableRef,
  getHaulierListAsync,
}: {
  createHaulierAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  updateHaulierAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  getHaulierListAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  searchKey: string | undefined;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
}) => {
  const { t } = useTranslation();

  const paginationState = useState<PaginationParams>({
    page: 1,
    pageSize: 10,
  });
  const { hasAccess: canCreate } = useRoleAccess('haulier.create');

  const [reachedEndOfData, setReachedEndOfData] = useState<boolean>(false);

  const [haulierList, setHaulierList] = useState<Haulier[] | undefined>();
  const [initialValues, setInitialValues] = useState<Haulier>(
    HaulierFormInitialValues,
  );
  const [modalActionType, setModalActionType] = useState<HaulierModalAction>();
  const { openModal, closeModal, isOpen: isModalOpen } = useModal({});

  const {
    isOpen: isOpenDelete,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal({});

  const [pagination, setPagination] = paginationState;

  const fetchHaulierList = useCallback(
    async (search?: string) => {
      const fetchedHaulierList = await getHaulierListAsync?.({
        search,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      if (tableRef?.current) {
        tableRef.current.scrollToTop();
      }
      setHaulierList([...fetchedHaulierList.data]);
      setPagination({
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
      setReachedEndOfData(fetchedHaulierList.data.length < DEFAULT_PAGE_SIZE);
    },
    [setPagination, getHaulierListAsync, setReachedEndOfData, tableRef],
  );

  const fetchNextHaulierPage = useCallback(async () => {
    if (!reachedEndOfData) {
      const fetchedHaulierList = await getHaulierListAsync?.({
        search: searchKey,
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      });
      setHaulierList((prev) => [...(prev || []), ...fetchedHaulierList.data]);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [
    pagination.page,
    pagination.pageSize,
    getHaulierListAsync,
    searchKey,
    setPagination,
    reachedEndOfData,
  ]);

  const handleOpenCreate = useCallback(() => {
    setModalActionType(HaulierModalAction.CREATE);
    setInitialValues(HaulierFormInitialValues);
    openModal();
  }, [openModal, setModalActionType]);

  const handleUpdate = useCallback(
    (haulier: Haulier) => {
      setModalActionType(HaulierModalAction.UPDATE);
      setInitialValues(haulier);
      openModal();
    },
    [openModal],
  );

  const handleDelete = useCallback(
    (halier: any) => {
      setModalActionType(HaulierModalAction.DELETE);
      setInitialValues(halier);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  useEffect(() => {
    fetchHaulierList(searchKey);
  }, [fetchHaulierList, searchKey]);

  const HaulierHeader = useCallback(
    () => (
      <FilterCol
        style={{
          color: 'white',
          padding: 0,
        }}
      >
        {canCreate && (
          <AddButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenCreate}
          >
            {t('haulier.haulierHeader.create')}
          </AddButton>
        )}
      </FilterCol>
    ),
    [canCreate, t, handleOpenCreate],
  );

  return {
    paginationState,
    fetchHaulierList,
    isOpenDelete,
    openDeleteModal,
    closeDeleteModal,
    HaulierHeader,
    handleDelete,
    reachedEndOfData,
    setHaulierList,
    haulierList,
    fetchNextHaulierPage,
    t,
    closeModal,
    isModalOpen,
    modalActionType,
    handleUpdate,
    initialValues,
  };
};

export default useHaulierList;
