/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import { ContainerOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import config from 'config';
import { changePurchaseStatusAccess } from 'config/purchaseStateMachine/purchaseStateMachine';
import VariantsAutocomplete from 'containers/Variants/VariantSearchInput/VariantSearchInput';
import { LightTheme } from 'helpers/constants/theme';
import { t } from 'i18next';
import { CreatedBy, SupplierInfo } from 'modules/Purchase/components/Info';
import SupplierAutocomplete from 'modules/Purchase/containers/SupplierAutocomplete/SupplierAutocomplete';
import { PURCHASE_VIEWS_ENUM } from 'modules/Purchase/reducer/interface';
import { MpsToSupplier } from 'modules/Purchase/reducer/utils/mps2supplier';
import { MPS } from 'services/MPS/interface';
import { Variant } from 'services/product/interface';
import {
  Purchase,
  PurchaseStatus,
  purchaseStatusColors,
  PurchaseVariant,
  Supplier,
  VariantValues,
} from 'services/purchase/interface';
import RadioGroup from '../LocationRadioGroup';
import { FiltersAndButtonContainer } from '../PurchasesContent/PurchasesContent.sty';
import PurchaseVariantList from '../PurchaseVariantList';
import {
  FormHeader,
  FormWrapper,
  ItemLabel,
  ItemParagraph,
  PurchaseCloneModalTitle,
  PurchasesDropdownWrapper,
  RadioMpsWrapper,
  SubmitWrapper,
} from './PurchaseForm.sty';

const { Panel } = Collapse;
const dayFormat = 'YYYY-MM-DD HH:mm';

interface Props {
  showClonePurchaseModal: boolean;
  isLoadingClonePurchase: boolean;
  form: FormInstance<Purchase>;
  formInitialValues: Purchase;
  values: Purchase;
  readOnly: boolean;
  currentView: PURCHASE_VIEWS_ENUM;
  step: number;
  purchaseTotal: string;
  isLoading: boolean;
  mpsOrdersLoading?: boolean;
  autocompletedSuggested?: boolean;
  stockPendingMps?: MPS[];
  setAutocompleteMpsOrders?: () => void;
  onSelectPurchase?: (id: string) => void;
  onConfirmCreateClone?: () => void;
  onCancelCreateClone?: () => void;
  onSubmit: () => void;
  handlePickVariant: (value: Variant) => void;
  onRemovePurchaseVariant: (variant: PurchaseVariant) => void;
  onChangeValues: (
    variant: PurchaseVariant,
    field: VariantValues,
    value: number | string,
  ) => void;
  openRemission?: () => void;
  handleEdit?: () => void;
  selectSupplier?: (value?: Supplier) => void;
}

const requiredRule = {
  required: true,
  message: t('purchaseForm.fields.errors.required'),
};

const PurchaseForm = ({
  showClonePurchaseModal,
  isLoadingClonePurchase,
  form,
  formInitialValues,
  values,
  readOnly,
  currentView,
  step,
  purchaseTotal,
  isLoading,
  mpsOrdersLoading,
  autocompletedSuggested,
  stockPendingMps,
  setAutocompleteMpsOrders,
  onSelectPurchase,
  onConfirmCreateClone,
  onCancelCreateClone,
  onSubmit,
  handlePickVariant,
  onRemovePurchaseVariant,
  onChangeValues,
  openRemission,
  handleEdit,
  selectSupplier,
}: Props) => {
  return (
    <>
      <Modal
        open={showClonePurchaseModal}
        title={
          <PurchaseCloneModalTitle>
            {t('purchaseForm.clonePurchase.modal.title')}
          </PurchaseCloneModalTitle>
        }
        onOk={onConfirmCreateClone}
        onCancel={onCancelCreateClone}
        okText={t('purchaseForm.clonePurchase.modal.okText')}
        cancelText={t('purchaseForm.clonePurchase.modal.cancelText')}
        okButtonProps={{
          loading: isLoadingClonePurchase,
          style: {
            backgroundColor: LightTheme.color.warning,
            border: 'none',
            width: '90px',
          },
        }}
        cancelButtonProps={{
          style: {
            width: '90px',
          },
        }}
      >
        {t('purchaseForm.clonePurchase.modal.detail')}
      </Modal>

      <Form
        initialValues={formInitialValues}
        form={form}
        onFinish={onSubmit}
        name="purchase-form"
        autoComplete="off"
      >
        <FormWrapper>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Space direction="vertical">
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  {t(
                    currentView === PURCHASE_VIEWS_ENUM.CREATE
                      ? 'purchaseForm.titles.create'
                      : 'purchaseForm.titles.update',
                  )}
                </Typography.Title>
                {formInitialValues?.purchaseNumber && (
                  <span style={{ fontWeight: 'bold' }}>
                    {t('purchaseForm.titles.orderNumber', {
                      id: formInitialValues?.purchaseNumber ?? '',
                    })}
                  </span>
                )}
              </Space>
            </Col>
            {currentView === PURCHASE_VIEWS_ENUM.UPDATE && (
              <Col
                span={12}
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: '5ch',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    icon={<ContainerOutlined />}
                    shape="circle"
                    onClick={openRemission}
                  />
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    shape="circle"
                    disabled={
                      formInitialValues.status === PurchaseStatus.DELIVERED ||
                      formInitialValues.status === PurchaseStatus.CANCELLED
                    }
                    onClick={handleEdit}
                  />
                </Space>
              </Col>
            )}
          </Row>
          <Divider />
          <FormHeader>
            <Col span={12}>
              <Form.Item
                name="status"
                rules={[requiredRule]}
                style={{ maxWidth: '130px' }}
              >
                <Select
                  placeholder={t('userForm.placeholders.docType')}
                  disabled={readOnly}
                >
                  {changePurchaseStatusAccess[
                    currentView === PURCHASE_VIEWS_ENUM.UPDATE
                      ? formInitialValues?.status ?? 'CREATE'
                      : 'CREATE'
                  ].map((status) => (
                    <Select.Option key={status} value={status}>
                      <Tag color={purchaseStatusColors[status]}>
                        {t(`purchaseStatus.${status}`)}
                      </Tag>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <PurchasesDropdownWrapper span={12}>
              {formInitialValues?.metadata?.linkedPurchase?.id &&
              currentView === PURCHASE_VIEWS_ENUM.UPDATE ? (
                <Select
                  onSelect={onSelectPurchase}
                  style={{ width: '150px', height: '32px' }}
                  placeholder={t(
                    'purchaseForm.fields.placeholders.linkedPurchase',
                  )}
                >
                  <Select.Option
                    key={formInitialValues.metadata.linkedPurchase.id}
                    value={formInitialValues.metadata.linkedPurchase.id}
                  >
                    {formInitialValues.metadata.linkedPurchase.purchaseNumber}
                  </Select.Option>
                </Select>
              ) : null}
              {formInitialValues?.metadata?.childPurchase?.id &&
              currentView === PURCHASE_VIEWS_ENUM.UPDATE ? (
                <Select
                  onSelect={onSelectPurchase}
                  style={{ width: '150px', height: '32px' }}
                  placeholder={t(
                    'purchaseForm.fields.placeholders.childPurchase',
                  )}
                >
                  <Select.Option
                    key={formInitialValues.metadata.childPurchase.id}
                    value={formInitialValues.metadata.childPurchase.id}
                  >
                    {formInitialValues.metadata.childPurchase.purchaseNumber}
                  </Select.Option>
                </Select>
              ) : null}
            </PurchasesDropdownWrapper>
          </FormHeader>

          {currentView === PURCHASE_VIEWS_ENUM.CREATE && step === 1 ? (
            <>
              <Form.Item
                label={t('purchaseForm.panel.title.orderType')}
                name={['metadata', 'isMarketplace']}
              >
                <Radio.Group
                  onChange={(e) =>
                    e.target.value === false && selectSupplier?.(undefined)
                  }
                >
                  <Radio value={false}>{t('regular')}</Radio>
                  <Space direction="horizontal" size="large" />
                  <Radio value>{t('ondemand')}</Radio>
                </Radio.Group>
              </Form.Item>
              {values?.metadata?.isMarketplace && stockPendingMps?.length && (
                <>
                  <ItemParagraph>
                    <ItemLabel>
                      {t('purchaseForm.fields.labels.radioSupplier')}
                    </ItemLabel>
                  </ItemParagraph>
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => selectSupplier?.(e.target.value)}
                    value={values?.supplier?.id}
                  >
                    <RadioMpsWrapper>
                      {stockPendingMps.map((mps) => (
                        <Radio
                          key={mps._id}
                          value={MpsToSupplier(mps)}
                          checked={values?.supplier?.id === mps._id}
                          style={{
                            backgroundColor:
                              values?.supplier?.id === mps._id
                                ? LightTheme.color.info
                                : LightTheme.color.white,
                            color:
                              values?.supplier?.id === mps._id
                                ? LightTheme.color.white
                                : LightTheme.color.dark,
                          }}
                        >
                          {mps.name ?? mps.contactName}
                        </Radio>
                      ))}
                    </RadioMpsWrapper>
                  </Radio.Group>
                </>
              )}
            </>
          ) : null}

          {step === 2 && (
            <Collapse defaultActiveKey={['1', '2']}>
              <Panel key="1" header={t('purchaseForm.panel.title.rawInfo')}>
                <Row gutter={[24, 16]}>
                  <Col span={12}>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      name="supplier"
                      label={t('purchaseForm.fields.labels.supplier')}
                      rules={[requiredRule]}
                    >
                      <SupplierAutocomplete disabled={readOnly} />
                    </Form.Item>
                    {formInitialValues.metadata.createdBy?.id && (
                      <Form.Item
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        name={['metadata', 'createdBy']}
                        label={t('purchaseForm.fields.labels.createdBy')}
                      >
                        <CreatedBy purchase={formInitialValues} />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      label={t('purchaseForm.fields.labels.supplierData')}
                    >
                      <SupplierInfo supplier={values?.supplier} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      name={['delivery', 'location']}
                      label={t('purchaseForm.fields.labels.location')}
                      rules={[requiredRule]}
                    >
                      <RadioGroup
                        value={config.googleMapsConfig.pandasWarehouses[0]}
                        options={config.googleMapsConfig.pandasWarehouses}
                        disabled={readOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {!values?.metadata?.isMarketplace && (
                      <>
                        <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          name={['metadata', 'providerPONumber']}
                          label={t(
                            'purchaseForm.fields.labels.providerPONumber',
                          )}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                        <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          name={['metadata', 'shipmentNumber']}
                          label={t('purchaseForm.fields.labels.shipmentNumber')}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </>
                    )}
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      rules={[requiredRule]}
                      label={t('purchaseForm.fields.labels.deliveryDate')}
                    >
                      <Form.Item
                        name={['delivery', 'date']}
                        rules={[requiredRule]}
                        noStyle
                      >
                        <DatePicker
                          placeholder={t(
                            'purchaseForm.fields.placeholders.deliveryDay',
                          )}
                          showTime
                          format={dayFormat}
                          disabled={
                            readOnly ||
                            formInitialValues?.status ===
                              PurchaseStatus.DELIVERED ||
                            formInitialValues?.status ===
                              PurchaseStatus.CANCELLED
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      label={t('purchaseForm.fields.labels.paymentDate')}
                    >
                      <Form.Item name={['payment', 'date']} noStyle>
                        <DatePicker
                          placeholder={t(
                            'purchaseForm.fields.placeholders.paymentDay',
                          )}
                          format={dayFormat}
                          showTime
                          disabled={
                            readOnly ||
                            formInitialValues?.status ===
                              PurchaseStatus.DELIVERED ||
                            formInitialValues?.status ===
                              PurchaseStatus.CANCELLED
                          }
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      label={t('purchaseForm.fields.labels.isConsignment')}
                      name={['metadata', 'isConsignment']}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={readOnly} />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>

              <Divider />
              <Panel key="2" header={t('purchaseForm.panel.title.detail')}>
                {currentView === PURCHASE_VIEWS_ENUM.CREATE &&
                  values?.supplier?.id &&
                  values.metadata?.isMarketplace && (
                    <FiltersAndButtonContainer>
                      <Button
                        type="primary"
                        loading={mpsOrdersLoading}
                        disabled={mpsOrdersLoading || autocompletedSuggested}
                        onClick={setAutocompleteMpsOrders}
                      >
                        {t(
                          'purchaseForm.fields.labels.autocompleteSuggestedButton',
                        )}
                      </Button>
                    </FiltersAndButtonContainer>
                  )}
                <Col span={24}>
                  <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 24 }}>
                    <VariantsAutocomplete
                      onChange={handlePickVariant}
                      disabled={readOnly}
                      mpsId={
                        values?.metadata?.isMarketplace
                          ? values?.supplier?.id
                          : undefined
                      }
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    name="detail"
                  >
                    <PurchaseVariantList
                      list={values.detail}
                      onRemovePurchaseVariant={onRemovePurchaseVariant}
                      onChangeValues={onChangeValues}
                      readOnly={readOnly}
                      isMarketplace={values?.metadata?.isMarketplace ?? false}
                      isCreate={currentView === PURCHASE_VIEWS_ENUM.CREATE}
                      purchaseStatus={
                        formInitialValues?.status ?? PurchaseStatus.PENDING
                      }
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Row gutter={[12, 12]}>
                  <Col
                    span={12}
                    style={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('purchaseForm.fields.labels.total')}
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    {purchaseTotal}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )}

          {/* These are hidden fields. This is useful to persist its values without showing a form field */}
          <Form.Item hidden name={['id']} />
          <Form.Item hidden name={['total']} />
          <Form.Item hidden name={['supplier']} />
          <Form.Item hidden name={['consecutive']} />
          <Form.Item hidden name={['purchaseNumber']} />
          <Form.Item hidden name={['metadata', 'linkedPurchase']} />
          <Form.Item hidden name={['metadata', 'childPurchase']} />
          <Form.Item hidden name={['createdAt']} />
          <Form.Item hidden name={['updatedAt']} />
          <Form.Item hidden name={['metadata', 'isMarketplace']} />
        </FormWrapper>
        {!readOnly && (
          <SubmitWrapper>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              {step === 1
                ? t('purchaseForm.buttonActions.continue')
                : t('purchaseForm.buttonActions.submit')}
            </Button>
          </SubmitWrapper>
        )}
      </Form>
    </>
  );
};

export default PurchaseForm;
