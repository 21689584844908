import { SearchOutlined } from '@ant-design/icons';
import { BrandsContentList } from 'containers/Brands/BrandsContentList/BrandsContentList';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleColumn } from '../../../modules/Order/components/OrderContent/OrderContent.sty';
import {
  BrandsContainer,
  BrandsTitle,
  SearchInput,
  TitleRow,
} from './BrandsList.sty';

export const BrandsList = () => {
  const { t } = useTranslation();
  const prefix = <SearchOutlined />;
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const onSearchWithDebounce = debounce((value: string) => {
    if (!value.length) {
      setSearchKey(undefined);
      return;
    }
    setSearchKey(value);
  }, 700);

  return (
    <BrandsContainer>
      <TitleRow>
        <TitleColumn
          span={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <BrandsTitle level={1}>{t('menu.brandsList')}</BrandsTitle>
        </TitleColumn>
      </TitleRow>
      <TitleRow style={{}}>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchInput
            placeholder={t('brandsSearchPlaceholder')}
            bordered={false}
            prefix={prefix}
            onChange={(e) => onSearchWithDebounce(e.target.value)}
          />
        </TitleColumn>
      </TitleRow>
      <TitleRow>
        <BrandsContentList searchKey={searchKey} />
      </TitleRow>
    </BrandsContainer>
  );
};
