import { Form, Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LightTheme } from 'helpers/constants/theme';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'services/store/interface';
import { buildStoreFromParams } from 'services/store/store.service';
import { User } from 'services/user/interface';

const { confirm } = Modal;

const EMPTY_STORE: Partial<Store> = {
  name: '',
  phone: '',
  docType: '',
  email: '',
  docNumber: '',
  pictures: [],
  addresses: [],
  users: [],
  seller: undefined,
};

export const useStoreForm = ({
  update,
  initialValues,
  onClose,
  onSubmit,
}: {
  initialValues?: Partial<Store>;
  onClose?: () => void;
  onSubmit?: (values: Partial<Store>) => void;
  update?: boolean;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Store>();
  const [displaySeller, setDisplaySeller] = useState<User>();
  const [tagStore, setTagStore] = useState(!!initialValues?.type);
  const fileListState = useState<UploadFile[]>(initialValues?.pictures || []);
  const setFileList = fileListState[1];
  const { setFieldsValue } = form;
  const {
    readByParamQuery: {
      mutate: getStoresById,
      isLoading: isLoadingStore,
      data: storeById,
    },
  } = useModuleCRUD('stores');

  useEffect(() => {
    if (initialValues?.id) {
      getStoresById({ id: initialValues.id });
    }
    if (initialValues?.seller) {
      setDisplaySeller(initialValues?.seller);
    }
    if (!update) {
      setFieldsValue(EMPTY_STORE);
      setFileList([]);
      setDisplaySeller(undefined);
    }
  }, [initialValues, getStoresById, update, setFieldsValue, setFileList]);

  useEffect(() => {
    if (!!storeById && update) {
      setFieldsValue({
        ...storeById,
        phone: storeById?.phone.replace('+57', ''),
      });
      setDisplaySeller({
        ...storeById.seller,
      });
      setFileList([...(storeById.pictures || [])]);
    }
  }, [setFieldsValue, storeById, update, setFileList]);

  const handleFormValuesChange = (changedValues: Partial<Store>) => {
    if (Object.keys(changedValues).includes('seller')) {
      const { seller } = changedValues;
      setDisplaySeller(seller);
    }
  };

  const onSubmitWrapper = useCallback(
    (values: Partial<Store>) => {
      onSubmit?.(
        buildStoreFromParams(
          {
            ...values,
            id: initialValues?.id,
            seller: displaySeller,
            sellerCoordinator: displaySeller?.sellerCoordinator,
            type: values.type ?? null,
          },
          update ? storeById : undefined,
        ),
      );
    },
    [displaySeller, onSubmit, initialValues, update, storeById],
  );

  const handleClose = useCallback(() => {
    confirm({
      icon: null,
      title: (
        <span
          style={{
            color: LightTheme.color.danger,
            fontWeight: 'bold',
          }}
        >
          {t('storeModal.confirmation.title')}
        </span>
      ),
      content: t('storeModal.confirmation.message'),
      onOk: () => {
        setFieldsValue(EMPTY_STORE);
        onClose?.();
      },
      okButtonProps: {
        style: {
          backgroundColor: LightTheme.color.danger,
          border: 'none',
        },
      },
      cancelText: t('remain'),
      okText: t('exit'),
    });
  }, [onClose, t, setFieldsValue]);

  const handleTagStore = (e: CheckboxChangeEvent) => {
    setTagStore(e.target.checked);
  };

  return {
    form,
    isLoadingStore,
    displaySeller,
    fileListState,
    handleFormValuesChange,
    onSubmitWrapper,
    handleClose,
    handleTagStore,
    tagStore,
    t,
  };
};
