import { StockObject } from 'services/product/interface';

export enum VARIANT_STOCK_UPDATE_ACTION_ENUM {
  SET_FORM_INITIAL_VALUE = 'SET_FORM_INITIAL_VALUE',
  TURN_EDITION_MODE = 'TURN_EDITION_MODE',
  SET_STOCK_INFO = 'SET_STOCK_INFO',
}

export type VariantStockUpdateAction = {
  type: VARIANT_STOCK_UPDATE_ACTION_ENUM;
  payload: any;
};

export interface VariantStockUpdateState {
  editionMode: boolean;
  stockInfo: StockObject;
  stockInitialValues: StockObject;
}
