import { UserOrders } from 'services/order/interface';
import { User } from 'services/user/interface';

export const user2orderUser = (user: User): UserOrders => ({
  id: user.id ?? '',
  name: user.info?.name ?? '',
  documentType: user.info?.docType ?? '',
  documentNumber: user.info?.documentNumber ?? '',
  localization: {
    latitude: user.info?.locations?.[0]?.latitude ?? '',
    longitude: user.info?.locations?.[0]?.longitude ?? '',
    address: user.info?.locations?.[0]?.address ?? '',
    details: user.info?.locations?.[0]?.details ?? '',
    cityCode: user.info?.locations?.[0]?.cityCode ?? '',
    cityName: user.info?.locations?.[0]?.cityName ?? '',
    departmentCode: user.info?.locations?.[0]?.departmentCode ?? '',
    departmentName: user.info?.locations?.[0]?.departmentName ?? '',
  },
  firstLastName: user.info?.lastName ?? '',
  email: user.info?.email ?? '',
  phone: user.info?.phoneNumber ?? '',
  isPrime: user.isPrime ?? false,
});
