/* eslint-disable import/no-duplicates */
import { addHours, format } from 'date-fns';
import es from 'date-fns/locale/es';

const dateFormatCol = (dataString: string) => {
  try {
    return dataString.split('/').reverse().toString().replaceAll(',', '-');
  } catch (e) {
    return dataString;
  }
};

export const dateFormatTable = (dataString: string) => {
  try {
    return dataString
      .split('T')[0]
      .split('-')
      .reverse()
      .toString()
      .replaceAll(',', '-');
  } catch (e) {
    return dataString;
  }
};

export const spanishDateFormat = (date: string, withHours: boolean): string => {
  const formatting = withHours ? 'PP p' : 'PP';
  return format(addHours(new Date(date), 5), formatting, { locale: es });
};

export default dateFormatCol;
