import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const OrderNumberTags = styled.div`
  display: flex;
  gap: 1px;
`;

export const OrderLocationP = styled.p`
  margin: 2px 0 0 7px;
`;

export const OrderTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  .ant-tag {
    margin: 2px;
  }
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  padding-right: ${LightTheme.spaceSizes.m};
`;

export const ClientInfoParagraph = styled.p`
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const ItemParagraph = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const ProductFigure = styled.figure`
  width: 80px;
  height: 80px;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const ProductDescriptionWrap = styled.div`
  margin-left: ${LightTheme.spaceSizes.s};
`;
