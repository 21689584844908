/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Col, Form } from 'antd';
import TrashIcon from 'assets/images/Trash.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VariantVolDiscountTable } from './interface';
import {
  DividerNoMargin,
  FormItemNoMargin,
  InputField,
  RowFlexCenter,
  TrashButton,
} from './VariantVolDicount.sty';

const VariantVolDiscount = ({
  volumePrice,
  addMore,
  variantPrice,
  handleAddMore,
  setvolDiscount,
}: VariantVolDiscountTable) => {
  const [, forceUpdate] = useState({});
  useEffect(() => {
    forceUpdate({});
  }, []);
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  if (!volumePrice) {
    return null;
  }
  const handleMax = (values: any, i: number) => {
    setFieldsValue({ [`max${i}`]: values });
    const volumes = [...volumePrice];
    volumes[i].max = Number(values);
    volumes[i + 1].min = Number(values) + 1;
    setvolDiscount(volumes);
  };

  const handlePrice = (values: any, i: number) => {
    setFieldsValue({ [`price${i}`]: Number(values) });
    const volumes = [...volumePrice];
    volumes[i].price = Number(values);
    setvolDiscount(volumes);
  };
  return (
    <Form form={form}>
      <RowFlexCenter gutter={[12, 7]}>
        <Col span={8}>{t('volumeDiscount.minimun')}</Col>
        <Col span={8}>{t('volumeDiscount.maximun')}</Col>
        <Col span={8}>{t('volumeDiscount.discount')}</Col>
      </RowFlexCenter>
      <DividerNoMargin plain />

      {volumePrice?.map((volumePriceValues, i, v) => (
        <>
          <RowFlexCenter gutter={[12, 7]}>
            <Col span={8}>
              <FormItemNoMargin style={{ marginBottom: '0' }}>
                <InputField
                  name={`min${i}`}
                  value={volumePriceValues.min}
                  placeholder={t('volumeDiscount.label.minimum')}
                  disabled
                  required
                />
              </FormItemNoMargin>
            </Col>
            <Col span={7}>
              <FormItemNoMargin style={{ marginBottom: '0' }} required>
                <InputField
                  required
                  name={`max${i}`}
                  placeholder={t('volumeDiscount.label.maximun')}
                  onChange={(e) => handleMax(e, i)}
                  min={volumePriceValues.min + i}
                  disabled={volumePriceValues.max === -1}
                  defaultValue={
                    volumePriceValues.max !== -1 && i !== 2
                      ? volumePriceValues.max
                      : 'Infinito'
                  }
                  value={
                    volumePriceValues.max !== -1 && i !== 2
                      ? volumePriceValues.max
                      : 'Infinito'
                  }
                />
              </FormItemNoMargin>
            </Col>
            <Col span={8}>
              <FormItemNoMargin style={{ marginBottom: '0' }}>
                <InputField
                  required
                  name={`price${i}`}
                  placeholder={t('volumeDiscount.label.discount')}
                  min={v?.[i + 1]?.price + 1 || 0}
                  max={v?.[i - 1]?.price - 1 || variantPrice}
                  onChange={(e) => handlePrice(e, i)}
                  defaultValue={volumePriceValues.price}
                />
              </FormItemNoMargin>
            </Col>
            {addMore && (
              <Col span={1}>
                {i === 2 && (
                  <FormItemNoMargin>
                    <TrashButton
                      src={TrashIcon}
                      alt="Trash"
                      onClick={() => handleAddMore(false)}
                      onKeyUp={undefined}
                    />
                  </FormItemNoMargin>
                )}
              </Col>
            )}
          </RowFlexCenter>
          <DividerNoMargin plain />
        </>
      ))}
    </Form>
  );
};

export default VariantVolDiscount;
