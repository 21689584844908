import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import config from 'config';
import { PandasError } from 'config/Errors/error';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { useCallback, useMemo } from 'react';
import { ResponseMetadata } from 'services/global/interface';
import { MPS } from 'services/MPS/interface';
import { getMpsList } from 'services/MPS/mps.service';
import { Order, OrderFilters, StatusOrderEnum } from 'services/order/interface';
import { getOrderList } from 'services/order/order.service';
import { getParameters } from 'services/parameters/parameters.service';

const DEFAULT_PANDAS_MPS = config.defaultPandasMps as MPS;
interface Props {
  filters: OrderFilters;
}

const useReadOrder = ({ filters }: Props) => {
  // ------------- TABLE --------------- //
  const {
    list: ordersList,
    isFetching: ordersListLoading,
    nextPage: nextListOrdersPage,
    listError: ordersListError,
    refetch: RefetchList,
  } = useInfiniteScroll({
    filters,
    fetchData: getOrderList,
    name: ['orders', 'list'],
  });

  // ------------- BOARD --------------- //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queries = [
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.RECEIVED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.IN_PICKING },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.PACKED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.IN_TRANSIT },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.DELIVERED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.CANCELLED_NOT_LEGALIZED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.STOCK_PENDING },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.CANCELLED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.PENDING },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.REJECTED },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
    useInfiniteScroll({
      filters: { ...filters, status: StatusOrderEnum.TEST },
      fetchData: getOrderList,
      name: ['orders', 'board'],
    }),
  ];

  const statusOrderData = useMemo(
    () =>
      queries.reduce(
        (acc, curr, idx) => ({
          data: {
            ...acc.data,
            [Object.keys(StatusOrderEnum)[idx]]: curr.list,
          },
          metadata: {
            ...acc.metadata,
            [Object.keys(StatusOrderEnum)[idx]]: curr.meta,
          },
          error: {
            ...acc.error,
            [Object.keys(StatusOrderEnum)[idx]]: curr.listError,
          },
          next: {
            ...acc.next,
            [Object.keys(StatusOrderEnum)[idx]]: curr.nextPage,
          },
        }),
        {} as {
          data: Record<StatusOrderEnum, Order[]>;
          metadata: Record<StatusOrderEnum, ResponseMetadata>;
          error: Record<StatusOrderEnum, AxiosError<PandasError>>;
          next: Record<StatusOrderEnum, () => Promise<void>>;
        },
      ),
    [queries],
  );

  // ------------- UTILS --------------- //
  const { data: locationsList } = useQuery({
    queryKey: ['locations'],
    queryFn: () => getParameters({ keys: 'locations' }),
    refetchOnWindowFocus: false,
  });
  const { data: mpsList } = useQuery({
    queryKey: ['mps'],
    queryFn: () =>
      getMpsList({
        pagination: { page: 1, pageSize: 1000 },
        isSupplier: false,
      }),
    refetchOnWindowFocus: false,
  });

  const refetchOrders = useCallback(async () => {
    await RefetchList();
    await Promise.all(queries.map((query) => query.refetch()));
  }, [RefetchList, queries]);

  const ordersBoardLoading = useMemo(
    () => queries.some((query) => query.isFetching),
    [queries],
  );

  return {
    ordersList,
    nextListOrdersPage,
    ordersListLoading,
    ordersListError,

    ordersBoard: statusOrderData.data,
    ordersBoardMeta: statusOrderData.metadata,
    ordersBoardNext: statusOrderData.next,
    ordersBoardLoading,
    ordersBoardError: statusOrderData.error,

    departments: locationsList?.locations?.data?.departments ?? [],
    mpsUsers: mpsList?.data ?? [DEFAULT_PANDAS_MPS],
    refetchOrders,
  };
};

export default useReadOrder;
