import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Tabs,
} from 'antd';
import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const StatusChangesWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  & .ant-select {
    width: 190px;
  }
`;

const { Panel } = Collapse;
const { Item } = Form;

export const OrderFormModal = styled(Modal)`
  .ant-modal-content {
    height: 85vh !important;
    max-height: 85vh !important;
    position: relative;
    overflow-y: hidden;

    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          background: none !important;
          border: 1px ${LightTheme.color.primary_dark} !important;
        }
      }
    }

    .ant-modal-footer {
      padding: ${LightTheme.spaceSizes.xl} ${LightTheme.spaceSizes.xl};
      background: ${LightTheme.color.white};
      opacity: 1 !important;
      box-sizing: border-box !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: fit-content;
    }
  }
  .ant-modal-body {
    height: calc(100% - 185px) !important;
    overflow-y: scroll;
  }
`;

export const OrderNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderNumberTitle = styled.span`
  font-weight: bold;
  width: 100%;
  color: ${LightTheme.color.primary};
`;

export const MpsName = styled.span`
  font-size: ${LightTheme.spaceSizes.l};
  color: ${LightTheme.color.gray_dark};
`;

export const PanelGrid = styled(Panel)`
  .ant-collapse-content-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;

    @media screen and (${LightTheme.queries.xs}) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const TitleDivider = styled(Divider)`
  margin: ${LightTheme.spaceSizes.l} 0;
`;

export const FormItem = styled(Item)`
  margin: ${LightTheme.spaceSizes.m} 0;
  .ant-form-item-label {
    label {
      font-weight: bold;
      font-size: ${LightTheme.spaceSizes.n};
    }
    padding: 0;
  }
`;
export const FormItemMargin = styled(FormItem)`
  margin-top: ${LightTheme.spaceSizes.m};
`;

export const FormNoMarginItem = styled(FormItem)`
  margin: 0px;
  flex-direction: row !important;
  .ant-form-item-control-input {
    display: block;
  }
  .ant-form-item-label {
    label {
      font-weight: bold;
      margin-right: 10px;
      font-size: ${LightTheme.spaceSizes.n};
    }
    padding: 0;
  }
`;

export const MainActionsCol = styled(Col)`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${LightTheme.spaceSizes.xl4};
`;

export const FooterRow = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  padding: 40px;
  background-color: white;
`;

export const TotalCol = styled(Col)`
  text-align: left;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  padding-left: ${LightTheme.spaceSizes.xxl};
`;

export const TotalSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.xl};
  font-weight: bold;
`;

export const DiscountSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.l};
  span {
    color: ${LightTheme.color.danger};
  }
`;

export const SubtotalSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.l};
`;

export const ButtonCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding-right: ${LightTheme.spaceSizes.l};
`;

export const CustomOrderTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${LightTheme.color.primary} !important;
    pointer-events: none;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        &:hover {
          color: inherit;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${LightTheme.color.primary} !important;
        }
      }
    }
  }
`;

export const SubmitButton = styled(Button)<{ color?: string }>`
  width: 170px;
  height: 40px;
  background: ${({ color }) => color ?? LightTheme.color.primary};
  border-radius: 8px;
  border: none;
  color: ${LightTheme.color.white};
`;

export const PrimaryButton = styled(Button)`
  background: ${LightTheme.color.primary};
  color: ${LightTheme.color.white};
  border: 1px ${LightTheme.color.primary};
`;

export const SuperOrderRemissionButton = styled(Button)`
  width: ${LightTheme.spaceSizes.xl5};
  height: ${LightTheme.spaceSizes.xl5};
  background: ${LightTheme.color.gray_light};
  color: ${LightTheme.color.dark};
  border: 1px ${LightTheme.color.gray_light};
`;

export const SelectorsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: ${spaceSizes.xl};
  margin-right: ${spaceSizes.xl4};

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const NumberInput = styled(InputNumber)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const ItemParagraph = styled.div`
  display: flex;
  margin: ${LightTheme.spaceSizes.s} 0;
  width: 100%;
  max-width: 450px;
  flex-wrap: wrap;
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  margin-right: ${LightTheme.spaceSizes.m};
`;

export const TwoGridContent = styled(Row)`
  & .ant-form-item {
    & .ant-form-item-control {
    }
  }
  margin-top: 8px;
`;
export const TwoGridContainer = styled(Row)`
  display: inline-flex;
`;

export const DiscountInput = styled.div`
  display: flex;
`;

export const DiscountSegInput = styled.div`
  min-width: ${spaceSizes.xxl1};
`;

export const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item {
  }
`;

export const FormColumns = styled(Col)``;

export const FinanceButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  margin: 10px 0;

  & .ant-select {
    width: 200px;
  }
`;

export const DeliveryCheckbox = styled(Checkbox)`
  .ant-checkbox-inner,
  input {
    width: 25px;
    height: 25px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    top: 60%;
    left: 30%;
    transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
  }
`;

export const DiscountContainer = styled.div`
  display: inline-flex;
  gap: ${spaceSizes.xxl1};
`;

export const FormWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
`;

export const CloseUpdate = styled.div`
  cursor: pointer;
`;
