import { CreditQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { ZiroCreditResponse } from './interface';

export const getUserCredit = async (
  documentNumber: string,
): Promise<ZiroCreditResponse> => {
  const response = await getAPIMethod({
    name: 'getUserCredit',
    pathParams: { documentNumber },
  });
  return response.data;
};

export const getCreditList = async (queryParams?: CreditQueryParams) => {
  const response = await getAPIMethod({
    name: 'getCreditList',
    params: {
      search: queryParams?.search,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
      showLegacy: queryParams?.showLegacy || undefined,
    },
  });
  return response.data;
};
