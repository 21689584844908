/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { DefaultTitle } from 'components/DefaultTitle/DefaultTitle';
import { TransportActionEnum } from 'config/Constants/actions';
import { useTransportsContext } from 'context/TransportContext/TransportContext';
import useNotifications from 'helpers/hooks/useNotifications';
import { validateTransportForm } from 'helpers/utils/validateCitiesForm';
import { t } from 'i18next';
import { DefaultContainer } from 'pages/Brands/BrandsList/BrandsList.sty';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  Columns,
  ShippingZonesViewEnum,
  Transport,
} from 'services/cities/interface';
import { initialTransportState } from 'services/cities/interface/transport';
import {
  createTransport,
  getTransportList,
  updateTransport,
} from 'services/cities/transport.service';
import { TransportTable } from './components/TransportList';
import { TransportForm } from './components/TransportUpdate';

export const TransportContainer = () => {
  const { state, dispatch } = useTransportsContext();
  const { transportList, selectedTransport, currentView, pagination } = state;
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  const GoToList = () => {
    dispatch({
      type: TransportActionEnum.SET_CURRENT_VIEW,
      payload: ShippingZonesViewEnum.LIST,
    });

    dispatch({
      type: TransportActionEnum.SET_TRANSPORT_LIST,
      payload: null,
    });
  };

  const GoToUpdate = (transport: Transport) => {
    dispatch({
      type: TransportActionEnum.SET_CURRENT_TRANSPORT,
      payload: transport,
    });

    dispatch({
      type: TransportActionEnum.SET_CURRENT_VIEW,
      payload: ShippingZonesViewEnum.UPDATE,
    });
  };

  const GoToCreate = () => {
    dispatch({
      type: TransportActionEnum.SET_CURRENT_VIEW,
      payload: ShippingZonesViewEnum.CREATE,
    });
  };

  const handlePagination = () => {
    dispatch({
      type: TransportActionEnum.SET_PAGINATION,
      payload: {},
    });
  };

  const fetchTransport = useCallback(async () => {
    try {
      const response = await getTransportList({
        page: pagination.page,
        pageSize: pagination.pageSize,
      });

      dispatch({
        type: TransportActionEnum.SET_TRANSPORT_LIST,
        payload: response.data,
      });
    } catch (error) {
      notifyError('cityTableKeys.notifications.list.error');
    }
  }, [currentView]);

  const handleSubmitFrom = useCallback(
    async (form: Transport) => {
      try {
        const validatedFrom = validateTransportForm(form);

        if (validatedFrom.error) {
          notifyError('transport.notifications.create.error');
          return;
        }
        const response =
          currentView === ShippingZonesViewEnum.UPDATE
            ? await updateTransport(selectedTransport._id, validatedFrom.form)
            : await createTransport(validatedFrom.form);

        dispatch({
          type: TransportActionEnum.SET_CURRENT_TRANSPORT,
          payload: response,
        });

        const successMessageKey =
          currentView === ShippingZonesViewEnum.UPDATE
            ? 'transport.notifications.update.success'
            : 'transport.notifications.create.success';

        notifySuccess(successMessageKey);

        if (currentView !== ShippingZonesViewEnum.UPDATE) {
          GoToUpdate(response);
        }
      } catch (error) {
        notifyError('transport.notifications.create.error');
      }
    },
    [currentView, dispatch, notifyError, notifySuccess, selectedTransport._id],
  );

  useEffect(() => {
    fetchTransport();
  }, [fetchTransport, dispatch]);

  const columns: Columns[] = [
    {
      title: t('transport.name'),
      dataIndex: 'name',
      width: '100%',
    },
    {
      title: t('transport.additionalPackageCost'),
      dataIndex: 'additionalPackageCost',
      width: '100%',
    },
    {
      title: t('transport.minimumVolumeWeight'),
      dataIndex: 'minimumVolumeWeight',
      width: '100%',
    },
    {
      title: t('transport.convertionFactor'),
      dataIndex: 'convertionFactor',
      width: '100%',
    },
  ];

  return (
    <DefaultContainer>
      <DefaultTitle
        createButton={GoToCreate}
        returnButton={GoToList}
        currentView={currentView}
        initialTitle="transport"
        createButtonText={t('transport.createButton')}
        returnButtonText={t('transport.returnButton')}
      />
      {(() => {
        switch (currentView) {
          case ShippingZonesViewEnum.LIST:
            return (
              <TransportTable
                list={transportList}
                tableRef={tableRef}
                loading={false}
                onViewDetail={GoToUpdate}
                columns={columns}
                reachedEndOfData={pagination.hasNext}
                onScrollToEnd={handlePagination}
              />
            );
          case ShippingZonesViewEnum.CREATE:
            return (
              <TransportForm
                selectedTransport={initialTransportState.selectedTransport}
                currentView={currentView}
                submitForm={handleSubmitFrom}
              />
            );
          case ShippingZonesViewEnum.UPDATE:
            return (
              <TransportForm
                selectedTransport={selectedTransport}
                currentView={currentView}
                submitForm={handleSubmitFrom}
              />
            );
          default:
            return (
              <TransportTable
                list={[]}
                tableRef={tableRef}
                loading={false}
                onViewDetail={GoToUpdate}
                columns={columns}
                reachedEndOfData={pagination.hasNext}
                onScrollToEnd={handlePagination}
              />
            );
        }
      })()}
    </DefaultContainer>
  );
};
