export enum WalletStatusEnum {
  CX = 'CS',
  PRODUCT = 'PRODUCT',
  SALES = 'SALE',
  GROWTH = 'GROWTH',
}

export interface User {
  userId: string;
  name: string;
  uid: string;
  role?: string[];
  user_id?: string;
  email?: string;
  email_verified?: boolean;
  phone_number?: string;
}

export interface PandabonosUploadHistory {
  user: Partial<User>;
  wallet: WalletStatusEnum;
  uploadId: string;
  pandabonosTotal: number;
  userQuantity: number;
  createdAt: string;
}

export const customMessage = {
  success: {
    message: '',
    description: 'pandabonos.message.success',
  },
  error: {
    message: '',
    description: 'pandabonos.message.error',
  },
  cancel: {
    message: '',
    description: 'pandabonos.messages.cancel',
  },
  warning: {
    message: '',
    description: 'pandabonos.message.addProductError',
  },
};
