import { LightTheme } from 'helpers/constants/theme';
import styled, { css } from 'styled-components';
import { COLUMNS_DEFAULT_WIDTH } from './constants';

interface ColumnItemsListProps {
  animationEnabled: boolean;
  isDraggingOver: boolean;
  animationColor?: string;
}

export const StyledBoard = styled.div<{ columnsQuantity: number }>`
  display: grid;
  height: 100%;
  gap: 15px;
  grid-template-columns: ${({ columnsQuantity }) =>
    css`repeat(${columnsQuantity}, ${COLUMNS_DEFAULT_WIDTH})`};
  overflow-x: auto;

  & .ant-alert-error {
    width: ${COLUMNS_DEFAULT_WIDTH}!important;
    height: 100% !important;
    overflow: scroll !important;
  }
`;

export const ColumnContainer = styled.div<{ color: string }>`
  margin: 0px;
  width: ${COLUMNS_DEFAULT_WIDTH};
  height: calc(100vh - 300px);
  overflow-y: scroll;
  background: #f0f0f0;
  padding: 5px;

  & .infinite-scroll-component {
    // I know this is a shit hack, but it only works in this way :(.
    overflow: hidden !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ color }) => `${color}20`};
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ color }) => `${color}B3`};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ color }) => color};
  }
`;

export const ColumnItemsList = styled.div<ColumnItemsListProps>`
  padding: 5px 10px;
  transition: 0.2s;
  overflow: hidden;
  background-color: ${({ isDraggingOver, animationEnabled, animationColor }) =>
    isDraggingOver && animationEnabled ? animationColor : '#F0F0F0'};
  border-radius: 5px;
`;

export const ColumnTitleWrapper = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px 8px 0 0;
`;

export const ColumnTitle = styled.h1<{ color: string }>`
  padding: 10px;
  font-weight: bold;
  margin: 0px;
  width: fit-content;
  color: ${({ color }) => color};
  font-size: ${LightTheme.spaceSizes.xl};
`;

export const ColumnCard = styled.div`
  width: 100%;
  height: fit-content;
`;

export const DroppableWrapper = styled.div`
  border-radius: 8px;
`;
