import UpdateShippingZoneContainer from 'modules/ShippingZones/containers/UpdateShippingZone';
import { ShippingZoneUpdateProvider } from 'modules/ShippingZones/context/SettingUpdate.context';

export const UpdateShippingZonePage = () => {
  return (
    <ShippingZoneUpdateProvider>
      <UpdateShippingZoneContainer />
    </ShippingZoneUpdateProvider>
  );
};
