import { Button, Result } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

const TransferItemsBaseFloating = styled.div`
  font-size: 2em;
  justify-content: space-between;
  align-items: center;
  display: flex;
  z-index: 2;
  position: sticky;
  background: ${BaseColors.white};
`;

export const TransferResult = styled(Result)`
  max-height: 85%;
  width: 100%;
  padding: 0;

  & .ant-result-content {
    padding: 0;
    background: ${BaseColors.white};
    max-height: 50%;
    overflow: scroll;
    border: 1px solid ${BaseColors.darky_gray};
  }
`;

export const LoadingScreen = styled.div`
  background-color: ${BaseColors.white};
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
`;

export const AcceptButton = styled(Button)`
  margin: 10px 0;
`;

export const TransferCartHeader = styled(TransferItemsBaseFloating)`
  border-bottom: 1px solid ${BaseColors.darky_gray};
  margin-bottom: 10px;
  padding: 0 10px;
  top: 0;
`;

export const TransfersTitle = styled.h1`
  margin: 0;
`;

export const TransferCartFooter = styled(TransferItemsBaseFloating)`
  padding: 10px;
  top: calc(100% - 50px);
  height: 50px;
  border-top: 1px solid ${BaseColors.darky_gray};
`;

export const TransferCartWrapper = styled.div`
  padding: 0 10px;
  padding-bottom: 45px;
  margin-top: -45px;
`;
