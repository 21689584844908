import { Col, Collapse, Form, Modal, Row, Tabs } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

const { Panel } = Collapse;
export const UpdateProductPanel = styled(Panel)`
  background-color: #eff3f8;
`;

export const NoHistory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const UpdateProductContainer = styled.div`
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const UpdateProductModal = styled(Modal)`
  .ant-modal-content {
    height: 85vh !important;
    max-height: 85vh !important;
    position: relative;
    overflow-y: scroll;

    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          background: none !important;
          border: 1px ${LightTheme.color.primary_dark} !important;
        }
      }
    }

    .ant-modal-footer {
      padding: ${LightTheme.spaceSizes.xl} ${LightTheme.spaceSizes.xl};
      background: ${LightTheme.color.white};
      opacity: 1 !important;
      box-sizing: border-box !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 0;
    }
  }
  .ant-modal-body {
    height: fit-content;
    padding-bottom: 40px;
    overflow-y: scroll;
  }
`;

export const SubmitButtonRow = styled(Row)`
  margin-top: ${LightTheme.spaceSizes.xl};
`;

export const SubmitButtonCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrappedFormItem = styled(Form.Item)`
  .ant-form-item-label {
    white-space: normal;
  }
`;

export const CustomItemTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${LightTheme.color.primary} !important;
    pointer-events: none;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        &:hover {
          color: inherit;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${LightTheme.color.primary} !important;
        }
      }
    }
  }
`;

export const TabsContainer = styled(Tabs)`
  overflow: scroll;
  height:100%;
`;
