import { Button, Divider, Form, InputNumber, Row } from 'antd';
import { spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const InputField = styled(InputNumber)`
  width: 100%;
  border-radius: 6px;
`;

export const FormItemNoMargin = styled(Form.Item)`
  margin-bottom: 0;
`;
export const TrashButton = styled.img`
  cursor: pointer;
`;

export const ButtonBlock = styled(Button)`
  width: 97%;
  margin: 0 auto;
`;

export const RowFlexCenter = styled(Row)`
  justify-content: center;
`;

export const DividerNoMargin = styled(Divider)`
  margin: ${spaceSizes.mls} auto;
`;
