import { Row, Col } from 'antd';
import styled from 'styled-components';
import { LightTheme } from 'helpers/constants/theme';

export const ButtonCol = styled(Col)`
  display: flex;

  @media screen and (${LightTheme.queries.sm}) {
    justify-content: flex-end;
    margin-right: 1ch;
  }
`;

export const ButtonRow = styled(Row)`
  display: flex;
  justify-content: flex-start;

  @media screen and (${LightTheme.queries.sm}) {
    justify-content: flex-end;
  }
`;
