export const BaseColors = {
  java: '#1ACEC3',
  jelly_bean: '#287C8E',
  regent_grey: '#818E9F',
  regent_dark_grey: '#C5D0DA',
  ghost: '#C7D0D9',
  porcelain: '#ECEDEE',
  wild_sand: '#F5F5F5',
  carnation: '#F04E6B',
  deep_cove: '#070648',
  curious_blue: '#2495E8',
  powder_blue: '#B1E8E1',
  iceberg: '#E0F6F3',
  white_ice: '#F1FDFB',
  shark: '#1F2123',
  white: '#FFFFFF',
  deep_blue: '#001529',
  wow_blue: '#4361EE',
  strong_pink: '#FF006E',
  strong_fuscia: '#E500C1',
  soft_blue: '#4361EE',
  deep_light_blue: '#3962F7',
  light_yellow: '#FFB703',
  soft_yellow: '#ffe9b4',
  light_blackberry: '#9D4EDD',
  dark_gray: '#1D3B53',
  darky_gray: '#d9d9d9',
  success_green: '#47D215',
  kiwi: '#6fcb8d',
  mint_cream: '#f4fbf6',
  error_red: '#CE1925',
  orange: '#ff9800',
  orange_nice: '#ff6c00',
  prime_purple: '#9D4EDD',
  happy_emerald: '#4EDD98',
};

export const UserLogoColors = {
  A: '#aa2e25',
  B: '#f44336',
  C: '#f6685e',
  D: '#1769aa',
  E: '#2196f3',
  F: '#4dabf5',
  G: '#00695f',
  H: '#009688',
  I: '#33ab9f',
  J: '#b26a00',
  K: '#ff9800',
  L: '#ffac33',
  M: '#482880',
  N: '#673ab7',
  O: '#008394',
  P: '#00bcd4',
  Q: '#33c9dc',
  R: '#1c54b2',
  S: '#2979ff',
  T: '#5393ff',
  U: '#8ab200',
  V: '#c6ff00',
  W: '#d1ff33',
  X: '#b2a429',
  Y: '#ffeb3b',
  Z: '#ffef62',
};

export const spaceSizes = {
  none: '0',
  xxs: '1px',
  xs: '2px',
  s: '5px',
  sm: '7px',
  m: '10px',
  n: '12px',
  ml: '12px',
  mls: '14px',
  l: '15px',
  xl: '20px',
  xl1: '22px',
  xxl: '25px',
  xl3: '30px',
  xl4: '35px',
  xl5: '40px',
  xl7: '50px',
  xx: '90px',
  xxl1: '100px',
  xl12: '130px',
  xxl2: '200px',
  xxl10: '250px',
};

export const MediaQueries = {
  xs: 'max-width: 767px',
  sm: 'min-width: 786px',
};

export const LightTheme = {
  color: {
    dark: BaseColors.shark,
    dark_alt: BaseColors.deep_blue,
    gray_dark: BaseColors.regent_grey,
    gray_light: BaseColors.ghost,
    gray_lighter: BaseColors.porcelain,
    gray_lightest: BaseColors.wild_sand,
    // Primary Colors
    primary: BaseColors.java,
    primary_dark: BaseColors.jelly_bean,
    primary_light: BaseColors.powder_blue,
    primary_lighter: BaseColors.iceberg,
    primary_lightest: BaseColors.white_ice,
    light_yellow: BaseColors.light_yellow,
    soft_yellow: BaseColors.soft_yellow,
    // Link, info and danger Colors
    link: BaseColors.deep_cove,
    info: BaseColors.curious_blue,
    danger: BaseColors.carnation,
    error: BaseColors.error_red,
    success: BaseColors.success_green,
    // General
    white: BaseColors.white,
    // Tag colors
    soft_danger: BaseColors.strong_pink,
    error_red: BaseColors.error_red,
    hard_danger: BaseColors.strong_fuscia,
    soft_link: BaseColors.soft_blue,
    warning: BaseColors.light_yellow,
    dark_highlight: BaseColors.light_blackberry,
    soft_highlight: BaseColors.dark_gray,
    soft_blue: BaseColors.wow_blue,
    dark_orange: BaseColors.orange,
    orange_nice: BaseColors.orange_nice,
    prime_purple: BaseColors.prime_purple,
    happy_emerald: BaseColors.happy_emerald,
    // Others
    kiwi: BaseColors.kiwi,
    mint_cream: BaseColors.mint_cream,
  },
  queries: {
    xs: MediaQueries.xs,
    sm: MediaQueries.sm,
  },
  spaceSizes: {
    none: spaceSizes.none,
    xs: spaceSizes.xs,
    s: spaceSizes.s,
    sm: spaceSizes.sm,
    m: spaceSizes.m,
    n: spaceSizes.n,
    ml: spaceSizes.ml,
    l: spaceSizes.l,
    xl: spaceSizes.xl,
    xl1: spaceSizes.xl1,
    xxl: spaceSizes.xxl,
    xl3: spaceSizes.xl3,
    xl4: spaceSizes.xl4,
    xl7: spaceSizes.xl7,
    xl5: spaceSizes.xl5,
    xx: spaceSizes.xx,
    xxl10: spaceSizes.xxl10,
  },
} as const;
