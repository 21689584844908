import { useLoadScript } from '@react-google-maps/api';
import { Row, Spin } from 'antd';
import config from 'config';
import { UserFormValues } from 'containers/Users/UserForm/interface';
import { UserForm } from 'containers/Users/UserForm/UserForm';
import { ROUTES } from 'helpers/constants/routes';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import buildUserFromParams from 'helpers/utils/buildUserFormParams';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DocType } from 'services/user/interface';

const initialValues = {
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  docType: 'CC' as DocType,
  documentNumber: '',
  phoneNumber: '',
  locations: [],
};

type Libraries = 'places';

const libraries = ['places'] as Libraries[];

const CreateUser = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapsConfig.apiKey,
    libraries,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    createMutation: { mutateAsync, isLoading },
  } = useModuleCRUD('user');
  const customMessage = {
    success: {
      message: 'userForm.modal.actions.success',
      description: 'userForm.modal.message.createSuccess',
    },
    error: {
      message: 'userForm.modal.actions.error',
      description: 'userForm.modal.message.createError',
    },
  };
  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const handleSubmit = async (values: UserFormValues) => {
    try {
      await mutateAsync(buildUserFromParams(values));
      notifySuccess();
      navigate(ROUTES.userList);
    } catch (error) {
      notifyError();
      throw new Error('Failed creation request');
    }
  };

  if (!isLoaded) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }

  return (
    <UserForm
      title="userForm.title.create"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      create
    />
  );
};

export default CreateUser;
