import { DatePicker, Select } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { FiltersAndButtonContainer } from 'modules/Order/components/OrderContent/OrderContent.sty';
import styled from 'styled-components';

export const CustomDatePicker = styled(DatePicker)`
  min-width: 220px;
  height: 35px;
  border-radius: 8px;

  .ant-picker-input {
    .ant-picker-suffix {
      color: ${LightTheme.color.link} !important;
    }
    input {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
    input::placeholder {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
  }
`;

export const ButtonRigthContainer = styled(FiltersAndButtonContainer)`
  width: 100%;
  display: 'flex';
  justify-content: flex-end;
`;

export const CustomSelect = styled(Select)`
  height: 35px;
  .ant-picker-input {
    .ant-picker-suffix {
      color: ${LightTheme.color.link} !important;
    }
    input {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
    input::placeholder {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
  }
`;

export const StoreTypeText = styled.p`
  margin: 0;
`;
