import { ErrorMessageObject } from './development.errors';

export const couponErrors: { [key: string]: ErrorMessageObject } = {
  COUS401001: {
    translation: 'httpErrors.coupon.noAuth',
    isBlocking: true,
  },
  COUS403002: {
    translation: 'httpErrors.coupon.noAccess',
    isBlocking: true,
  },
  COUS403003: {
    translation: 'httpErrors.coupon.undefinedPermissions',
    isBlocking: true,
  },
  COUS400004: {
    translation: 'httpErrors.coupon.couponNoFound',
    isBlocking: true,
  },
  COUC400001: {
    translation: 'httpErrors.coupon.codeAlreadyExists',
    isBlocking: true,
  },
  COUC400002: {
    translation: 'httpErrors.coupon.undefinedCouponValue',
    isBlocking: true,
  },
  COUC400003: {
    translation: 'httpErrors.coupon.bothNominalAndPercentage',
    isBlocking: true,
  },
  COUC400004: {
    translation: 'httpErrors.coupon.undefinedNominalDiscount',
    isBlocking: true,
  },
  COUC400005: {
    translation: 'httpErrors.coupon.invalidNominalDiscount',
    isBlocking: true,
  },
  COUC400006: {
    translation: 'httpErrors.coupon.undefinedPercentageDiscount',
    isBlocking: true,
  },
  COUC400007: {
    translation: 'httpErrors.coupon.invalidPercentageDiscount',
    isBlocking: true,
  },
  COUC400008: {
    translation: 'httpErrors.coupon.couponCodeNotFound',
    isBlocking: true,
  },
  COUC400009: {
    translation: 'httpErrors.coupon.invalidCouponCode',
    isBlocking: true,
  },
  COUC500010: {
    translation: 'httpErrors.coupon.createError',
    isBlocking: true,
  },
  COUR400001: {
    translation: 'httpErrors.coupon.userIdNotFound',
    isBlocking: true,
  },
  COUR400002: {
    translation: 'httpErrors.coupon.inactiveCoupon',
    isBlocking: true,
  },
  COUR400003: {
    translation: 'httpErrors.coupon.totalBelowMinPurchase',
    isBlocking: true,
  },
  COUR400004: {
    translation: 'httpErrors.coupon.notAvailable',
    isBlocking: true,
  },
  COUR400005: {
    translation: 'httpErrors.coupon.noUsesLeft',
    isBlocking: true,
  },
  COUR400006: {
    translation: 'httpErrors.coupon.noUsesLeftForUser',
    isBlocking: true,
  },
  COUR400007: {
    translation: 'httpErrors.coupon.notAvailableForForUser',
    isBlocking: true,
  },
};
