export const CUSTOM_MESSAGE = {
  success: {
    message: 'purchaseForm.actions.success',
    description: 'purchaseForm.modal.message.success',
  },
  error: {
    message: 'purchaseForm.actions.error',
    description: 'purchaseForm.modal.message.error',
  },
  cancel: {
    message: 'purchaseForm.actions.cancel',
    description: 'purchaseForm.messages.cancel',
  },
  warning: {
    message: 'purchaseForm.actions.warning',
    description: 'purchaseForm.messages.warning',
  },
};

export const CREATE_PURCHASE_SUCCESS = 'purchaseForm.messages.create.success';
export const CREATE_CLONE_PURCHASE_SUCCESS =
  'purchaseForm.messages.create.clonedSuccess';
export const UPDATE_PURCHASE_SUCCESS = 'purchaseForm.messages.update.success';

export const CREATE_PURCHASE_ERROR = 'purchaseForm.messages.create.error';
export const CREATE_CLONE_PURCHASE_ERROR = 'purchaseForm.messages.create.error';
export const UPDATE_PURCHASE_ERROR = 'purchaseForm.messages.update';
export const LIST_PURCHASE_ERROR = 'purchaseForm.messages.read.error';
export const READ_PURCHASE_ERROR =
  'purchaseForm.messages.update.failedFetchPurchase';
export const INVALID_PURCHASE_DETAIL = 'purchaseForm.actions.invalidQuantities';
export const EMPTY_PURCHASE_DETAIL = 'purchaseForm.actions.emptyDetail';
export const MPS_ORDERS_PURCHASE_ERROR =
  'purchaseForm.messages.create.mpsOrdersError';
export const NO_MPS_ORDERS_ERROR =
  'purchaseForm.messages.create.mpsOrdersErrorNoCode';
