import { CsvModuleProps } from 'components/CsvModule/interface';

export type ITransfersCsvContext = CsvModuleProps;

export const TRANSFERS_CSV_CONTEXT_DEFAULT_VALUES: ITransfersCsvContext = {
  hint: '',
  inputText: '',
  title: '',
  uploadProps: {},
};

export interface ITransferCsvStatus {
  errors: string[];
  percentage: number;
  transferIsRunning: boolean;
}

export const TRANSFER_CSV_STATUS_DEFAULT_VALUES: ITransferCsvStatus = {
  errors: [],
  percentage: 0,
  transferIsRunning: false,
};
