import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { TransferItemsFooterProps } from '../../interface';
import { TransferCartFooter } from './TransferItems.sty';

const TransferItemsFooter = ({
  canTransfer,
  transferLoading,
  transferItems,
}: TransferItemsFooterProps) => {
  const { t } = useTranslation();
  return (
    <TransferCartFooter>
      <Button
        disabled={!canTransfer}
        type="primary"
        block
        loading={transferLoading}
        onClick={transferItems}
      >
        {t('transfers.buttons.transfer')}
      </Button>
    </TransferCartFooter>
  );
};

export default TransferItemsFooter;
