import { LocationValue } from 'containers/General/LocationInput/interface';

const DEFAULT_CITY_CODE = '';

export const emptyLocation: LocationValue = {
  address: '',
  details: '',
  latitude: '',
  longitude: '',
  cityCode: DEFAULT_CITY_CODE,
};

export interface MultiAddressInputProps {
  value?: LocationValue[];
  onChange?: (val: LocationValue[]) => void;
  isLoadingScript?: boolean;
}
