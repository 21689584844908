import { Tag } from 'antd';
import {
  OrderHistoryContainer,
  ProductBoldLabel,
} from 'components/HistoryProduct/HistoryProduct.sty';
import format from 'date-fns/format';
import { RoleTagColorCode } from 'helpers/hooks/useRoleAccess/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import { stringDateFormat } from 'helpers/utils/dateFormat';
import {
  DateLabel,
  NormalLabel,
} from 'modules/Order/components/HistoryOrder/HistoryOrder.sty';
import { PandabonosUploadHistory } from 'pages/Pandabonos/interface';
import { useTranslation } from 'react-i18next';

const HistoryPandabonos = ({
  user,
  wallet,
  uploadId,
  pandabonosTotal,
  userQuantity,
  createdAt,
}: PandabonosUploadHistory) => {
  const { t } = useTranslation();
  const renderRole = (roles: string[] | string) => {
    if (!roles) {
      return <Tag color="red">{t('variantHistory.errorHandling.noRole')}</Tag>;
    }

    if (typeof roles === 'string') {
      return <Tag color={RoleTagColorCode[roles] || 'default'}>{roles}</Tag>;
    }

    return user?.role?.map((role: any) => (
      <Tag color={RoleTagColorCode[role] || 'default'} key={role}>
        {role}
      </Tag>
    ));
  };

  return (
    <OrderHistoryContainer>
      <DateLabel>{stringDateFormat(createdAt)}</DateLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.user')}</ProductBoldLabel>
        <span>
          {user.name ??
            t('variantHistory.errorHandling.noUser', { id: user.userId })}
        </span>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.role')}</ProductBoldLabel>
        {renderRole(user.role ?? [])}
      </NormalLabel>

      <NormalLabel>
        <ProductBoldLabel>{t('pandabonos.total')}</ProductBoldLabel>
        <span>{numberToCurrency(pandabonosTotal)}</span>
      </NormalLabel>

      <NormalLabel>
        <ProductBoldLabel>{t('pandabonos.users')}</ProductBoldLabel>
        <span>{userQuantity}</span>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('pandabonos.wallet')}</ProductBoldLabel>
        <span>{wallet}</span>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('pandabonos.id')}</ProductBoldLabel>
        <span>{uploadId}</span>
      </NormalLabel>
    </OrderHistoryContainer>
  );
};

export default HistoryPandabonos;
