import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const MultiLegalizeOrdersWrapper = styled.ul`
  height: fit-content;
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
  list-style: none;
  padding: 0;
`;

export const MultiLegalizeItem = styled.li`
  border-bottom: 1px solid ${LightTheme.color.gray_light};
  padding: 10px 0;
  width: 100%;
`;
