export const BANNERS_FILE_FOLDER = 'Banners Fijos';
export const MAX_UPLOAD_ITEMS = 1;
export const BANNERS_ALLOWED_FORMATS = ['png', 'webp', 'jpeg', 'jpg'];
export const BAD_LINK_REGEX_ERROR = 'BOGENE010';
export const BANNER_FORM_NAME = 'banner-form';
export const BANNER_FORM_LAYOUT = 'vertical';
export const BANNER_FORM_ITEM_IMAGE_NAME = 'image';
export const BANNER_FORM_ITEM_LINK_NAME = 'link';
export const BANNER_FORM_ITEM_LINK_TITLE = 'title';
export const BANNER_FORM_ITEM_LINK_DESCRIPTION = 'description';
export const BANNER_FORM_COLLAPSE_KEY = 'additionalInfo';
export const BANNER_DEFAULT_IMAGE_NAME = 'image';
