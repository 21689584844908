import { PickedVariant } from 'services/order/interface';
import { CartVariant } from 'services/product/interface';

export const detailToPicking = (
  detail: CartVariant[],
  picked?: PickedVariant[],
) =>
  detail.map((variant) => {
    const prodPick = picked?.find((p) => p.id === variant.id);
    return {
      images: variant.images,
      id: variant.id,
      name: variant.name,
      cartQuantity: prodPick?.cartQuantity ?? variant.cartQuantity,
      price: prodPick?.price ?? variant.price,
      expectedCartQuantity: variant.cartQuantity,
    };
  });
