import OrderUpdateContainer from 'modules/Order/containers/OrderUpdateContainer';
import { OrderProvider } from 'modules/Order/context/Order.context';

export const OrderUpdate = () => {
  return (
    <OrderProvider>
      <OrderUpdateContainer />
    </OrderProvider>
  );
};
