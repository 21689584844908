import { FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AttributeLevel } from 'services/product/interface';

const useAttributeLevelsInput = (
  form: FormInstance<unknown>,
  initialLevels?: AttributeLevel,
) => {
  const [levels, setLevels] = useState<string[]>([]);
  const {
    readQuery: {
      mutate: getAttributeTypeList,
      data: attributeTypeList,
      isLoading: loadingAttributeTypeList,
    },
  } = useModuleCRUD('attributeType');

  useEffect(() => {
    if (initialLevels) {
      setLevels(Object.values(initialLevels).map((attr) => attr.id || ''));
    }
  }, [initialLevels]);

  const parsedLevels = useMemo<Record<number, AttributeTypeInterface>>(
    () =>
      levels.reduce(
        (
          prev: Record<number, AttributeTypeInterface>,
          curr: string,
          idx: number,
        ) => {
          const foundAttributeType = attributeTypeList?.find(
            (attr: AttributeTypeInterface) => attr.id === curr,
          );
          return {
            ...prev,
            [idx]: foundAttributeType,
          };
        },
        {},
      ),
    [levels, attributeTypeList],
  );

  const addLevel = useCallback((level: string) => {
    setLevels((prev) => [...prev, level]);
  }, []);

  const removeLevel = useCallback((levelToRemove: string) => {
    setLevels((prev) => prev.filter((level) => level !== levelToRemove));
  }, []);

  const canAdd = useMemo(() => levels.length < 5, [levels]);

  const attributeTypeOptions = useMemo(() => {
    const options: DefaultOptionType[] = [];

    attributeTypeList?.forEach((attributeType: AttributeTypeInterface) => {
      if (!levels.includes(attributeType.id ?? '')) {
        options.push({
          value: attributeType.id,
          label: attributeType.name,
        });
      }
    });

    return options;
  }, [levels, attributeTypeList]);

  useEffect(() => {
    getAttributeTypeList({ withChildrenOnly: true });
  }, [getAttributeTypeList]);

  useEffect(() => {
    form.setFieldsValue({ attributeLevels: null });
    form.setFieldsValue({ attributeLevels: parsedLevels });
  }, [parsedLevels, form]);

  return {
    parsedLevels,
    canAdd,
    addLevel,
    removeLevel,
    attributeTypeOptions,
    loadingAttributeTypeList,
  };
};

export default useAttributeLevelsInput;
