/* eslint-disable no-bitwise */
import { Col, Row } from 'antd';
import { User } from 'services/user/interface';
import {
  BoldItemParagraph,
  ItemLabel,
  ItemParagraph,
  UserLogo,
} from './AutoCompleteUserItem.sty';

const AutoCompleteUserItem = ({ user }: { user: User }) => {
  const { info } = user;
  const fullName = `${info.name || ''} ${info.lastName || ''}`;
  return (
    <Row gutter={[16, 8]}>
      <Col
        span={5}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UserLogo name={fullName}>
          {`${(info.name || 'N')[0].toUpperCase()}${(info.lastName ||
            'N')[0].toUpperCase()}`}
        </UserLogo>
      </Col>
      <Col span={16}>
        <BoldItemParagraph>{fullName}</BoldItemParagraph>
        <ItemParagraph>{`${info.docType || 'NA'}. ${info.documentNumber || ''}`}</ItemParagraph>
        <ItemParagraph>{info.email || 'NA'}</ItemParagraph>
      </Col>
    </Row>
  );
};

export default AutoCompleteUserItem;
