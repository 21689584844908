/* eslint-disable no-underscore-dangle */
import { TFunction } from 'i18next';
import {
  City,
  Regional,
  RegionalConfig,
  ShippingZones,
  Transport,
} from 'services/cities/interface';
import { costOperatorOptions, shippingCostTypeOptions } from '../constants';
import { DefaultShippingZoneFormValues, IShippingZoneForm } from '../interface';
import { LabelValue } from '../reducer/interface';

export function cityToLabelValue(city: City): LabelValue<string, City> {
  return {
    label: `[${city.departmentCode}] - ${city.name}`,
    value: `[${city.departmentCode}] - ${city.name}`,
    addon: city,
  };
}

export function shippingZoneToFormInitial(
  zone?: ShippingZones,
): IShippingZoneForm {
  if (!zone) {
    return DefaultShippingZoneFormValues;
  }
  return {
    ...zone,
    regionalConfig: {
      ...DefaultShippingZoneFormValues.regionalConfig,
      ...zone.regionalConfig,
    },
    transport: zone?.transport?._id ?? '',
  };
}

export function formToRegionalConfig(
  form: IShippingZoneForm,
  regionals: Regional[],
): RegionalConfig {
  let regional: Regional;

  if (typeof form.regionalConfig.regional === 'string') {
    regional = regionals.find(
      (r) => r.id === form.regionalConfig.regional,
    ) as Regional;
  } else {
    regional = form.regionalConfig.regional;
  }

  const regionalObject: RegionalConfig = {
    holyDays: form.regionalConfig.holyDays,
    shippingCostType: form.regionalConfig.shippingCostType,
    regional,
  };

  if ((form.regionalConfig?.deliveryDaysLimit ?? 0) > 0) {
    regionalObject.deliveryDaysLimit = form.regionalConfig?.deliveryDaysLimit;
  }

  return regionalObject;
}

export function getShippingZones(zones: LabelValue<string, City>[]): City[] {
  return zones.map((z) => z.addon ?? ({} as any));
}

export function shippingZoneFormToCreationEntity(
  zone: IShippingZoneForm,
  transports: Transport[],
  regionals: Regional[],
): Omit<ShippingZones, '_id'> {
  const transport = transports.find(
    (t) => t._id === zone.transport,
  ) as Transport;

  return {
    ...zone,
    transport,
    regionalConfig: formToRegionalConfig(zone, regionals),
  };
}

export function shippingZoneFormToEntity(
  zone: IShippingZoneForm,
  ogZone: ShippingZones,
  transports: Transport[],
  regionals: Regional[],
): ShippingZones {
  const zoneCity = zone.zoneCity.filter((c) => !!c._id);

  return {
    ...ogZone,
    ...shippingZoneFormToCreationEntity(zone, transports, regionals),
    zoneCity,
  };
}

export function formatCurrency(num?: number): string {
  return (num ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatDay(t: TFunction, num?: number): string {
  return `${num ?? 0} ${t(`shippingZone.form.values.days`)}`;
}

export function currencyParser(num?: string): number {
  if (!num) {
    return 0;
  }
  return Number(num?.replace(/\$\s?|(,*)/g, ''));
}

export function dayParser(num?: string): number {
  if (!num) {
    return 0;
  }
  return Number(num?.split(' ')?.[0] ?? 0);
}

export function getCostOperatorSelectValues(
  t: TFunction,
): LabelValue<string>[] {
  return costOperatorOptions.map((op) => ({
    label: t(`operators.${op}`),
    value: op,
  }));
}

export function getShippingCostTypeValues(t: TFunction): LabelValue<string>[] {
  return shippingCostTypeOptions.map((op) => ({
    label: t(`operators.${op}`),
    value: op,
  }));
}
