/* eslint-disable no-promise-executor-return */
import { useMutation } from '@tanstack/react-query';
import {
  browserLocalPersistence,
  ConfirmationResult,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  RecaptchaVerifier,
  setPersistence,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { ROUTES } from 'helpers/constants/routes';
import { getAPIMethod } from 'helpers/libs/Http';
import { authUserAtom } from 'helpers/recoil/atoms/authUser';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { FirebaseUser } from '../useRoleAccess/interface';

const googleProvider = new GoogleAuthProvider();

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
    recaptchaWidgetId: number;
  }
}

export default function useFirebaseAuth() {
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState('');
  const [firebaseRoles, setFirebaseRoles] = useState('');
  const [authUser, setAuthUser] = useRecoilState(authUserAtom);
  const navigate = useNavigate();
  const location = useLocation();
  googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

  const auth = getAuth();
  auth.languageCode = 'es';

  const clear = () => {
    setLoading(true);
    setAuthUser(undefined);
  };

  const loginWithGoogle = async () => {
    await setPersistence(auth, browserLocalPersistence);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      GoogleAuthProvider.credentialFromResult(result);
    } catch (error: any) {
      setAuthError(error.message);
      GoogleAuthProvider.credentialFromError(error);
    }
  };
  const { mutateAsync: getUserByToken } = useMutation(async () => {
    // Get twice for refreshing claims token puposes
    await getAPIMethod({ name: 'linkUserByToken' });
    const response = await getAPIMethod({ name: 'linkUserByToken' });
    return response.data;
  });

  const logOut = () => signOut(auth).then(clear);

  // On Login Callback
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        setLoading(false);
        setAuthUser(undefined);
        if (location.pathname !== ROUTES.login) {
          navigate(ROUTES.login);
        }
        return;
      }
      const userWithClaim: FirebaseUser = firebaseUser as FirebaseUser;
      const roles = JSON.parse(
        userWithClaim.reloadUserInfo?.customAttributes || '{}',
      ).role;
      setFirebaseRoles(roles);
      if (!roles) {
        await auth.currentUser?.getIdToken(true);
      }
      if (!authUser) {
        const user = await getUserByToken();
        setLoading(true);
        setAuthUser(user);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [
    auth,
    authUser,
    getUserByToken,
    location.pathname,
    navigate,
    setAuthUser,
  ]);

  return {
    authUser,
    loading,
    logOut,
    loginWithGoogle,
    authError,
    firebaseRoles,
  };
}
