import { Method } from 'axios';

export const endpoints = {
  getProductList: {
    method: 'GET' as Method,
    url: 'product-dom/product',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getProductListByCategory: {
    method: 'GET' as Method,
    url: 'product-dom/product-by-category/{params.category}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getByProductByName: {
    method: 'GET' as Method,
    url: 'product-dom/product-by-name/{params.name}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  productSearch: {
    method: 'GET' as Method,
    url: 'product-dom/product-search/{params.search}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createProduct: {
    method: 'POST' as Method,
    url: 'product-dom/product',
    baseURL: process.env.REACT_APP_PRODUCT_API_URL,
  },
  updateProductById: {
    method: 'PUT' as Method,
    url: 'product-dom/product/{params.productId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteProductById: {
    method: 'DELETE' as Method,
    url: 'product-dom/product/{params.productId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
