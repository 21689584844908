/* eslint-disable no-underscore-dangle */
import { useLoadScript } from '@react-google-maps/api';
import config from 'config';
import { ROUTES } from 'helpers/constants/routes';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { debounce } from 'lodash';
import { SupplierForm } from 'modules/Suppliers/components/SupplierForm/SupplierForm';
import SupplierList from 'modules/Suppliers/components/SupplierList';
import { useSuppliers } from 'modules/Suppliers/context/supplier.context';
import useLocationSelector from 'modules/Suppliers/hooks/useLocationSelector';
import useSupplierForm from 'modules/Suppliers/hooks/useSupplierForm';
import useSupplierService from 'modules/Suppliers/hooks/useSupplierService';
import {
  SUPPLIER_ACTION_ENUM,
  SUPPLIER_PAGE_ENUM,
} from 'modules/Suppliers/reducer/supplier.reducer';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MPS, SupplierForm as SupplierDTO } from 'services/MPS/interface';

type Libraries = 'places';
const libraries = ['places'] as Libraries[];

const SupplierContainer = ({ route }: { route: SUPPLIER_PAGE_ENUM }) => {
  const navigate = useNavigate();
  const { state, dispatch, t } = useSuppliers();
  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });
  useLoadScript({
    googleMapsApiKey: config.googleMapsConfig.apiKey,
    libraries,
  });
  useMemo(() => {
    if (route !== SUPPLIER_PAGE_ENUM.UPDATE) {
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_CURRENT_SUPPLIER,
        payload: undefined,
      });
    }
    dispatch({
      type: SUPPLIER_ACTION_ENUM.SET_CURRENT_VIEW,
      payload: route,
    });
  }, [route, dispatch]);

  useEffect(() => {
    if (route === SUPPLIER_PAGE_ENUM.UPDATE && !state.currentSupplier) {
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_CURRENT_VIEW,
        payload: SUPPLIER_PAGE_ENUM.CREATE,
      });
      navigate(ROUTES.createSupplier);
    }
  }, [dispatch, navigate, route, state.currentSupplier]);

  const {
    suppliersData,
    isLoadingSuppliers,
    totalData,
    createLoading,
    updateLoading,
    refetch,
    createSupplier,
    updateSupplier,
  } = useSupplierService({
    state,
  });
  const { form, fileList, handleRutUpload, handleRutDelete } = useSupplierForm(
    state.currentSupplier,
  );
  const locationSelectorProps = useLocationSelector({ form, notifyError });

  const onChangeSearch = debounce((value: string) => {
    dispatch({
      type: SUPPLIER_ACTION_ENUM.SET_SEARCH,
      payload: value,
    });
  }, 700);

  const onSetPagination = useCallback(
    (paginationData: PaginationParams) => {
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_PAGINATION,
        payload: paginationData,
      });
    },
    [dispatch],
  );

  const handleChangeSupplierFilter = useCallback(
    (value: any) => {
      let payload;
      if (value !== '') {
        payload = value;
      }
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_SUPPLIER_FILTER,
        payload,
      });
    },
    [dispatch],
  );
  const handleChangeBillingTypeFilter = useCallback(
    (value: any) => {
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_BILLING_TYPE_FILTER,
        payload: value || undefined,
      });
    },
    [dispatch],
  );

  const goToCreate = useCallback(() => {
    navigate(ROUTES.createSupplier);
  }, [navigate]);

  const onUpdateSupplier = useCallback(
    (supplier: MPS) => {
      dispatch({
        type: SUPPLIER_ACTION_ENUM.SET_CURRENT_SUPPLIER,
        payload: supplier,
      });
      navigate(ROUTES.updateSupplier);
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (state.currentSupplier) {
      form.setFieldsValue({
        ...state.currentSupplier,
      });
    }
  }, [form, state.currentSupplier]);

  const handleSubmit = useCallback(
    async (values: SupplierDTO) => {
      try {
        if (state.currentView === SUPPLIER_PAGE_ENUM.CREATE) {
          await createSupplier(values);
          notifySuccess('supplierForm.modal.message.createSuccess');
        } else {
          await updateSupplier(values);
          notifySuccess('supplierForm.modal.message.updateSuccess');
        }
        form.resetFields();
        navigate(ROUTES.supplierList);
        dispatch({
          type: SUPPLIER_ACTION_ENUM.SET_CURRENT_VIEW,
          payload: SUPPLIER_PAGE_ENUM.LIST,
        });
        refetch();
      } catch (error) {
        if (state.currentView === SUPPLIER_PAGE_ENUM.CREATE) {
          notifyError('supplierForm.modal.message.createError');
        } else {
          notifyError('supplierForm.modal.message.updateError');
        }
      }
    },
    [
      form,
      state.currentView,
      createSupplier,
      notifyError,
      notifySuccess,
      navigate,
      dispatch,
      updateSupplier,
      refetch,
    ],
  );

  if (state.currentView === SUPPLIER_PAGE_ENUM.LIST) {
    return (
      <SupplierList
        onChangeSearch={onChangeSearch}
        onSetPagination={onSetPagination}
        onUpdateSupplier={onUpdateSupplier}
        goToCreate={goToCreate}
        handleChangeSupplierFilter={handleChangeSupplierFilter}
        handleChangeBillingTypeFilter={handleChangeBillingTypeFilter}
        supplierList={suppliersData}
        isLoadingList={isLoadingSuppliers}
        pagination={state.pagination}
        totalData={totalData}
        t={t}
      />
    );
  }

  if (state.currentView === SUPPLIER_PAGE_ENUM.CREATE) {
    return (
      <SupplierForm
        isUpdate={false}
        t={t}
        handleSubmit={handleSubmit}
        handleRutUpload={handleRutUpload}
        handleRutDelete={handleRutDelete}
        isLoading={createLoading || updateLoading}
        fileList={fileList}
        form={form}
        locationSelectorProps={locationSelectorProps}
      />
    );
  }

  if (state.currentView === SUPPLIER_PAGE_ENUM.UPDATE) {
    return (
      <SupplierForm
        isUpdate
        t={t}
        handleSubmit={handleSubmit}
        handleRutUpload={handleRutUpload}
        handleRutDelete={handleRutDelete}
        initialValues={state.currentSupplier}
        isLoading={createLoading || updateLoading}
        fileList={fileList}
        form={form}
        locationSelectorProps={locationSelectorProps}
      />
    );
  }

  return <div />;
};

export default SupplierContainer;
