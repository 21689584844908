import { UploadFile } from 'antd/lib/upload/interface';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { nullishProductBrand } from 'components/BrandsAutocomplete/interface';
import { DEFAULT_VAT_PERCENTAGE } from 'helpers/constants/variantForm';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { CartItemStatus } from 'services/cart/interface';
import { MPS } from 'services/MPS/interface';
import {
  Attribute,
  AttributeLevel,
  StockObject,
  Variant,
  VariantCombo,
  VariantStatus,
} from 'services/product/interface';
import { User } from 'services/user/interface';

export type StockVariant = {
  id: string;
  quantityToModify: number;
  cost: number;
  price: number;
};

// Este enum/interface es la misma que se usa para gusrdar el historial de las ordenes
export enum StockActionEnum {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  COMMIT = 'COMMIT',
  FREE = 'FREE',
  SELL = 'SELL',
  MISSING = 'MISSING',
  RELEASE = 'RELEASE',
  ADD_CAP = 'ADD_CAP',
  PRICE_UPDATE = 'PRICE_UPDATE',
}

export type StockAction = keyof typeof StockActionEnum;

export interface HistoryUser {
  id: string;
  fullName: string;
  role: RoleEnum[];
}
export interface HistoryItem {
  action: StockAction;
  actionQuantity: number;
  cost: number;
  endLocation: any;
  originLocation: any;
  price: number;
  discount?: number;
  resultingStock: StockObject;
  resultingPrice?: number;
  resultingDiscount?: number;
  date: Date;
  user: HistoryUser;
  inventoryChangeReason?: string;
  metadata: { [key: string]: string | User };
}

export interface TagParams {
  type: string;
  tag: string;
  icon: string;
  title: string;
  description: string;
}

interface DiscountItem {
  min: number;
  max: number;
  price: number;
}
export interface VariantFormValues {
  name: string;
  variantSku: string;
  description?: string;
  hasVolDiscount?: boolean;
  price?: number;
  volumePrice?: DiscountItem[];
  cost?: number;
  tags?: string[];
  partNumber?: string;
  designTags?: string;
  descriptionTable?: Record<string, string>[];
  keywords?: string[];
  discount?: number;
  ranking?: number;
  attributes?: Attribute[];
  images?: UploadFile[];
  mps?: MPS;
  rack?: string;
  position?: string;
  level?: string;
  isCombo?: boolean;
  combo?: VariantCombo[];
  comboQuantity?: number;
  cartQuantity?: number;
  hall?: string;
  history?: HistoryItem[];
  id?: string;
  brand?: BrandDBEntity | null;
  video?: string;
  status?: VariantStatus | keyof typeof CartItemStatus;
  cap?: number;
  isTaxExcluded?: boolean;
  vatPercentage?: number;
  commission?: number;
  inventoryChangeReason?: string;
  dimensions?: {
    height?: number;
    length?: number;
    width?: number;
    weight?: number;
  };
  stock?: {
    availableInWarehouse?: number;
    notAvailableInWarehouse?: number;
    inWarehouse?: number;
    available?: number;
    cap?: number;
  };
  stockCost?: number; // To update stock endpoint
}

export interface VariantFormProps {
  title?: string;
  isUpdate?: boolean;
  initialValues: VariantFormValues;
  onSubmitVariant?: (values: VariantFormValues) => void;
  onSubmitCombo?: (values: VariantFormValues) => void;
  isVariantFormLoading?: boolean;
  showTitle?: boolean;
  formId: string;
  comboLoading?: boolean;
  variant?: Variant;
  labelCol?: { span: number };
  wrapperCol?: {
    xl: { span: number };
    lg: { span: number };
    md: { span: number };
  };
  reset?: boolean;
  submitButton?: JSX.Element;
  stockButton?: JSX.Element;
  setExternalFormReference?: React.Dispatch<React.SetStateAction<any>>;
  productName?: string;
  attributeLevels?: AttributeLevel;
  attributeParams: {
    defaultValues: Attribute[];
    loadingAttributesList: boolean;
    attributeOptions: Record<string, Attribute[]> | undefined;
    attributeTypeNames: string[];
  };
}

interface DiscountItem {
  min: number;
  max: number;
  price: number;
}

export const defaultVariantFormValues: VariantFormValues = {
  name: '',
  variantSku: '',
  description: '',
  attributes: [],
  price: undefined,
  discount: undefined,
  tags: [],
  partNumber: '',
  designTags: '',
  descriptionTable: undefined,
  cost: undefined,
  isCombo: false,
  combo: [],
  ranking: 0,
  status: VariantStatus.ACTIVE,
  volumePrice: [],
  rack: '',
  position: '',
  level: '',
  hall: '',
  id: '',
  commission: 0,
  brand: nullishProductBrand,
  video: '',
  vatPercentage: DEFAULT_VAT_PERCENTAGE,
  isTaxExcluded: false,
};
