/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import TableActions from 'components/TableActions/TableActions';
import { LightTheme } from 'helpers/constants/theme';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import dateFormat from 'helpers/utils/dateFormat';
import { useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ItemLabel, ItemParagraph } from '../CategoryTable.sty';
import { CategoryContent } from '../interface';
import { useMediaQuery } from './useMediaQuery';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  CategoryActions: (category: CategoryContent) => JSX.Element,
) => [
  {
    title: t('categoryListCols.urlName'),
    dataIndex: 'urlName',
  },
  {
    title: t('categoryListCols.name'),
    dataIndex: 'name',
  },
  {
    title: t('categoryListCols.description'),
    dataIndex: 'description',
  },
  {
    title: t('categoryListCols.actions'),
    render: CategoryActions,
  },
];

const hiddenDescription = ({
  category,
  t,
  isMobile,
  CategoryActions,
}: {
  category: CategoryContent;
  t: TFunction<'translation', undefined>;
  isMobile: boolean;
  CategoryActions: (category: CategoryContent) => JSX.Element;
}) => (
  <>
    <ItemParagraph>
      <ItemLabel>Id :&nbsp;</ItemLabel>
      {category.id}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('categoryTableKeys.creationDate')}
        :&nbsp;
      </ItemLabel>
      {dateFormat(category.createdAt ? category.createdAt : '')}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('categoryTableKeys.description')}
        :&nbsp;
      </ItemLabel>
      {category.description}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('categoryTableKeys.urlName')}
        :&nbsp;
      </ItemLabel>
      {category.urlName}
    </ItemParagraph>
    {isMobile && CategoryActions(category)}
  </>
);

const useGetCategoryColumns = ({
  onDeleteCategory,
  onGoToUpdateCategory,
}: {
  onDeleteCategory: (id: string) => void;
  onGoToUpdateCategory: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('category.editActions');

  const CategoryActions = (category: CategoryContent) => {
    const handleDeleteCategory = (categ: CategoryContent) => {
      const { id } = categ;
      onDeleteCategory(id);
    };
    const handleUpdateCategory = (categ: CategoryContent) => {
      const { urlName } = categ;
      onGoToUpdateCategory(urlName || '');
    };
    return (
      <AccessWrapper
        fallback={
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {t('noAccess')}
          </Tag>
        }
      >
        <TableActions
          entity={category}
          onDelete={handleDeleteCategory}
          onUpdate={handleUpdateCategory}
        />
      </AccessWrapper>
    );
  };

  const defaultColumns = tableColumns(t, CategoryActions);
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('categoryListCols.urlName'),
      t('categoryListCols.actions'),
    ];
    return isMobile
      ? defaultColumns.filter(
        (column) => !mobileHidableColumns.includes(column.title),
      )
      : defaultColumns;
  }, [t, isMobile, defaultColumns]);

  return {
    columns,
    hiddenDescription: (category: CategoryContent) =>
      hiddenDescription({
        t,
        category,
        isMobile,
        CategoryActions,
      }),
  };
};

export default useGetCategoryColumns;
