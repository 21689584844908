/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { LogoInterface } from 'components/BrandForm/interface';
import TableActions from 'components/TableActions/TableActions';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { BrandImage } from '../BrandsList.sty';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  BrandActions: (brand: any) => JSX.Element,
) => [
  {
    title: t('brand.id'),
    dataIndex: 'id',
    width: '140px',
  },
  {
    title: t('brand.name'),
    dataIndex: 'name',
    width: '180px',
  },
  {
    title: t('brand.logo'),
    dataIndex: 'logo',
    width: '120px',
    render: (logo: LogoInterface) => <BrandImage src={logo.url} />,
  },
  {
    title: t('brand.actions'),
    dataIndex: '',
    width: '120px',
    render: (brand: any) => BrandActions(brand),
  },
];

const useBrandsTable = ({
  onDeleteBrand,
  onUpdateBrand,
}: {
  onDeleteBrand: (brand: any) => void;
  onUpdateBrand: (brand: any) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('user.editActions');

  const BrandActions = (brand: any) => (
    <AccessWrapper
      fallback={
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </Tag>
      }
    >
      <TableActions
        entity={brand}
        onDelete={onDeleteBrand}
        onUpdate={onUpdateBrand}
      />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, BrandActions);
  const columns = useMemo(() => defaultColumns, [defaultColumns]);

  return {
    columns,
    t,
  };
};

export default useBrandsTable;
