import {
  AllowedRuleProp,
  RuleDefinition,
} from 'services/wms-settings/interface';

export function findRuleInRuleSet(
  ruleSet: RuleDefinition[],
  search: RuleDefinition | AllowedRuleProp,
): RuleDefinition | undefined {
  return ruleSet.find(
    (rule) => rule.field === search.field && rule.name === search.name,
  );
}
