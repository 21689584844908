/* eslint-disable no-underscore-dangle */
import { Form } from 'antd';
import { parsePandasErr } from 'config/Errors/error';
import { format } from 'date-fns';
import { ROUTES } from 'helpers/constants/routes';
import { useAuth } from 'helpers/context/AuthUserContext';
import useNotifications from 'helpers/hooks/useNotifications';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { t } from 'i18next';
import OrderForm from 'modules/Order/components/OrderForm';
import { useOrder } from 'modules/Order/context/Order.context';
import { UserRoleHasAccessTo } from 'modules/Order/helpers/orderForm';
import { useOrderStatus } from 'modules/Order/hooks/useOrderStatus';
import useWriteOrder from 'modules/Order/hooks/useWriteOrder';
import { ORDER_ACTIONS_ENUM } from 'modules/Order/interface';
import { ORDER_ACTION_ENUM } from 'modules/Order/reducer/interface';
import { validateStockInCreateOrder } from 'modules/Order/reducer/utils/detail.validator';
import { range2DateOption } from 'modules/Order/reducer/utils/range2options';
import { user2orderUser } from 'modules/Order/reducer/utils/user2orderUser';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Order,
  PaymentOptionsKeys,
  StatusOrderEnum,
  WarrantyOrderDTO,
} from 'services/order/interface';
import { User, UserRoleEnum } from 'services/user/interface';

const OrdercreateContainer = () => {
  const navigate = useNavigate();
  const { state: previousFilter } = useLocation();
  const { authUser, firebaseRoles } = useAuth();
  const { state, dispatch } = useOrder();

  const [form] = Form.useForm<Order>();
  const values = Form.useWatch([], form);

  const {
    createOrder,
    loadingCreate,
    orderTotals,
    loadingTotals,
    deliveryRangeOptions,
    segmentationValues,
    pandabonos,
    isLoadedGoogleScript,
  } = useWriteOrder({ orderValues: values });
  const { hasAccess } = useOrderStatus({});

  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  const isAdmin = useMemo(
    () => authUser?.role?.includes(RoleEnum.ADMIN) ?? false,
    [authUser],
  );

  const { userCanCreateOrder } = useMemo(() => {
    const roles = firebaseRoles.split('|');

    const userHasUpdatePermission = UserRoleHasAccessTo(
      roles as UserRoleEnum[],
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
    );

    return {
      userCanCreateOrder: userHasUpdatePermission,
    };
  }, [firebaseRoles]);

  const handleFormFinish = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (error: any) {
      notifyError(
        error?.errorFields?.[(error?.errorFields?.length ?? 1) - 1]
          ?.errors?.[0],
      );
      return;
    }

    // validate cart
    const { validCart, isBadCart } = await validateStockInCreateOrder(
      values.detail,
    );
    if (isBadCart || !validCart.length) {
      form.setFieldValue('detail', validCart);
      notifyError('ORDS400018');
      return;
    }

    try {
      await createOrder(values);
      navigate(ROUTES.order);
      notifySuccess('orderForm.modal.message.success');
    } catch (error) {
      const err = parsePandasErr(error, 'orderForm.modal.message.error');
      notifyError(t(err.code));
    }
  }, [createOrder, navigate, notifyError, notifySuccess, values, form]);

  const goToList = useCallback(() => {
    navigate(ROUTES.order, { state: previousFilter });
  }, [previousFilter, navigate]);

  // ======== EFFECTS =========== //
  useEffect(() => {
    form.setFieldValue('status', StatusOrderEnum.RECEIVED);
    form.setFieldValue('saleDate', format(new Date(), 'yyyy-MM-dd'));
    form.setFieldValue('payment', {
      cash: { use: true },
      pandabonos: { use: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (orderTotals) {
      form.setFieldValue('totals', orderTotals);
      form.setFieldValue('total', orderTotals.total);
      form.setFieldValue('subtotal', orderTotals.subtotal);

      const payment = { ...orderTotals.payment };
      const paymentMethods = Object.keys(payment ?? {}) as PaymentOptionsKeys[];
      paymentMethods.forEach((method) => {
        payment[method] = {
          ...payment[method],
          use: true,
        };
      });
      form.setFieldValue('payment', payment);

      dispatch({
        type: ORDER_ACTION_ENUM.SET_MAX_DISCOUNT,
        payload: orderTotals.subtotal,
      });
    }
  }, [orderTotals, form, dispatch]);
  useEffect(() => {
    if (deliveryRangeOptions.length) {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_DELIVERY_DATE_OPTIONS,
        payload: { deliveryRangeOptions, isAdmin },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryRangeOptions]);
  useEffect(() => {
    if (!values?.delivery && state.deliveryRangeOptions.length) {
      const first = state.deliveryRangeOptions.find(
        (date) => date.start.isFirst,
      );
      const delivery = {
        ...(values?.delivery ?? {}),
        date:
          first?.start?.value ?? state.deliveryRangeOptions[0]?.start?.value,
      };
      form.setFieldValue('delivery', delivery);
    }
  }, [form, state.deliveryRangeOptions, values?.delivery]);

  const handleDiscountValue = useCallback(
    (value: number) => {
      form.setFieldValue(
        'discount',
        ((orderTotals?.subtotal ?? 0) * value) / 100,
      );
    },
    [form, orderTotals],
  );

  const onSelectUser = useCallback(
    (user: User) => {
      const orderUser = user2orderUser(user);
      form.setFieldValue('user', orderUser);
    },
    [form],
  );
  const setSelectedWarrantyOptions = useCallback(
    (orders: WarrantyOrderDTO[]) => {
      form.setFieldValue('warrantyOrders', orders);
    },
    [form],
  );
  const onChangePandabonosUse = useCallback(
    (checked: boolean) => {
      const payment = { ...(values?.payment ?? {}) };
      if (checked) {
        payment.pandabonos = {
          amount: payment.pandabonos?.amount,
          use: true,
        };
      } else {
        payment.pandabonos = {
          use: false,
        };
      }

      form.setFieldValue(['payment'], payment);
    },
    [form, values?.payment],
  );

  if (!isLoadedGoogleScript) {
    return <div />;
  }

  return (
    <OrderForm
      isUpdate={false}
      form={form}
      values={values}
      loadingOrder={false}
      currentStatus={StatusOrderEnum.RECEIVED}
      disableForm={false}
      disableMpsVariantAutocomplete={state.disableMpsVariantAutocomplete}
      maxDiscount={state.maxDiscount}
      isAdmin={isAdmin}
      handleDiscountValue={handleDiscountValue}
      deliveryDateOptions={state.deliveryRangeOptions.map(range2DateOption)}
      segmentationValues={segmentationValues}
      handleFormFinish={handleFormFinish}
      hasAccess={hasAccess}
      onSelectUser={onSelectUser}
      totals={orderTotals}
      loadingTotals={loadingTotals}
      loadingSubmit={loadingCreate}
      pandabonos={pandabonos}
      userCanCreateOrder={userCanCreateOrder}
      setSelectedWarrantyOptions={setSelectedWarrantyOptions}
      onChangePandabonosUse={onChangePandabonosUse}
      goToList={goToList}
      deliveryOptions={state.deliveryOptions}
    />
  );
};

export default OrdercreateContainer;
