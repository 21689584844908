/* eslint-disable no-underscore-dangle */
/* eslint-disable spaced-comment */
import { AxiosResponse } from 'axios';
import {
  HaulierQueryParams,
  PaginationParams,
} from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { Haulier, HaulierHistory } from './interface';

export const updateHaulier = async (haulier: Partial<Haulier>) => {
  const response = await getAPIMethod({
    name: 'updateHaulier',
    data: haulier,
    pathParams: { id: haulier?._id || '' },
  });
  return response.data;
};

export const createHaulier = async (haulier: Partial<Haulier>) => {
  const response = await getAPIMethod({
    name: 'createHaulier',
    data: haulier,
  });
  return response.data;
};

export const getHaulierList = async (queryParams?: HaulierQueryParams) => {
  const response = await getAPIMethod({
    name: 'getHaulierList',
    params: {
      search: queryParams?.search,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const deleteHaulier = async (haulierId: string) => {
  const response = await getAPIMethod({
    name: 'deleteHaulier',
    pathParams: { id: haulierId },
  });
  return response;
};

export const getHaulierHistory = async (
  haulierId: string,
  queryParams?: PaginationParams,
): Promise<PaginatedResponse<HaulierHistory>> => {
  const response: AxiosResponse<
    PaginatedResponse<HaulierHistory>,
    any
  > = await getAPIMethod({
    name: 'getHaulierHistory',
    params: {
      haulierId,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export default {
  create: createHaulier,
  read: getHaulierList,
  update: updateHaulier,
  delete: deleteHaulier,
  readByParam: getHaulierList,
};
