export const SALE = 'SALE';
export const ONDEMAND = 'ONDEMAND';
export const MAXRESCEDULE = 2;

export enum DefaultStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const DEFAULT_COLOMBIAN_DEPARTMENTS = 'col-departments';

export const DEFAULT_DELIVERY_DAYS = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
];
