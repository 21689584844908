import { ClearOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { TransferItemsHeaderProps } from '../../interface';
import { TransferCartHeader, TransfersTitle } from './TransferItems.sty';

const TransferItemsHeader = ({
  clearCart,
  cartIsEmpty,
}: TransferItemsHeaderProps) => {
  const { t } = useTranslation();
  return (
    <TransferCartHeader>
      <TransfersTitle>{t('transfers.cart.title')}</TransfersTitle>
      <Popconfirm
        title={t('transfers.modals.clearCart.description')}
        onConfirm={clearCart}
        disabled={cartIsEmpty}
      >
        <Button disabled={cartIsEmpty}>
          <ClearOutlined />
        </Button>
      </Popconfirm>
    </TransferCartHeader>
  );
};

export default TransferItemsHeader;
