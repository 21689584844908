/* eslint-disable no-bitwise */
import { isBefore, isEqual } from 'date-fns';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { validateCartMethod } from 'services/cart/cart.service';
import { CartItemStatus } from 'services/cart/interface';
import { CartVariant } from 'services/product/interface';

export const detailValidator = (_: any, val: any) => {
  return new Promise((res, rej) => {
    if (!val?.length) rej(new Error(t('BOORDS004')));

    res('');
  });
};

export const orderUserValidator = (_: any, val: any) => {
  return new Promise((res, rej) => {
    if (!val) rej(new Error(t('ORDC400001')));

    res('');
  });
};

export const validateSaleDate = (_: any, value: any, delivery: string) => {
  const deliveryDate = new Date(delivery);
  const saleDate = new Date(value);
  const saleBeforeDelivery = isBefore(saleDate, deliveryDate);
  const isSameDelivery = isEqual(saleDate, deliveryDate);
  if (saleBeforeDelivery || isSameDelivery) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(t('BOORDS001')));
};

export const cartDifference = (cartA: CartVariant[], cartB: CartVariant[]) =>
  cartA
    ?.map((cartItem) => {
      const existingVariant = cartB?.find(
        (originalCartItem) => originalCartItem.id === cartItem.id,
      );
      if (existingVariant) {
        return {
          ...cartItem,
          cartQuantity:
            Number(cartItem.cartQuantity ?? 0) -
            Number(existingVariant.cartQuantity ?? 0),
        };
      }
      return cartItem;
    })
    .filter((cartItem) => Number(cartItem.cartQuantity ?? 0) > 0);

export const validateCartQuantities = async (
  updatedDetail: CartVariant[],
  oldDetail: CartVariant[],
  diff: CartVariant[],
): Promise<CartVariant[]> => {
  let newDetail = cloneDeep(updatedDetail);

  if (!diff?.length) {
    const cartInStock = newDetail.map((cartVariant: CartVariant) => ({
      ...cartVariant,
      status: CartItemStatus.IN_STOCK,
    }));
    newDetail = cartInStock;
  }

  if (diff.length) {
    const validatedCart = await validateCartMethod(diff);

    validatedCart.forEach((cartItem) => {
      const cartItemIndex = newDetail?.findIndex(
        (variant) => variant.id === cartItem.id,
      );
      newDetail[cartItemIndex].status = cartItem.status;
      const initialCartItem = oldDetail.find(
        (initialItem) => initialItem.id === cartItem.id,
      );
      if (cartItem.status === 'LIMITED_STOCK') {
        newDetail[cartItemIndex].cartQuantity =
          Number(initialCartItem?.cartQuantity ?? 0) +
          Number(cartItem.cartQuantity ?? 0);
      } else if (cartItem.status === 'OUT_OF_STOCK') {
        newDetail[cartItemIndex].cartQuantity = Number(
          initialCartItem?.cartQuantity ?? 0,
        );
      }
    });
  }

  return newDetail;
};

export const validateStockInCreateOrder = async (
  cart: CartVariant[],
): Promise<{ validCart: CartVariant[]; isBadCart: boolean }> => {
  const validCart = await validateCartMethod(
    cart.filter((product) => product?.status !== 'OUT_OF_STOCK'),
  );

  const isBadCart = validCart.some(
    (item: CartVariant) =>
      item.status === CartItemStatus.OUT_OF_STOCK ||
      item.status === CartItemStatus.LIMITED_STOCK,
  );

  return { validCart, isBadCart };
};
