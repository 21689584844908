import { useEffect, useState } from 'react';
import { CategoryContent } from '../interface';

const useFilterCategoryData = (data: CategoryContent[]) => {
  const [displayData, setDisplayData] = useState(data);
  const [searchKey, setSearchKey] = useState<string>('');

  const handleSearch = (value: string) => {
    if (value) {
      setDisplayData(
        data.filter(
          (record) =>
            record.name.includes(value) || record.urlName?.includes(value),
        ),
      );
    }
    if (!value) {
      setDisplayData(data);
    }
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(value);
  };

  const handleClickChange = (value: string) => {
    setSearchKey(value);
  };

  useEffect(() => {
    handleSearch(searchKey);
  }, [searchKey]);

  return {
    displayData,
    searchKey,
    setDisplayData,
    handleChange,
    handleClickChange,
  };
};

export default useFilterCategoryData;
