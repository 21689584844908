import SettingListContainer from 'modules/WMS/Settings/containers/SettingListContainer';
import { SettingListProvider } from 'modules/WMS/Settings/context/SettingList.context';

const ListSettings = () => {
  return (
    <SettingListProvider>
      <SettingListContainer />
    </SettingListProvider>
  );
};

export default ListSettings;
