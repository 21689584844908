import { LightTheme } from 'helpers/constants/theme';
import { MPS } from 'services/MPS/interface';
import { VariantImage } from 'services/product/interface';
import { CreatedByUser, Location } from 'services/user/interface';

export enum PurchaseStatus {
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export interface Delivery {
  date?: string;
  method?: string;
  location?: Location;
  priority?: string;
  type?: string;
}

export interface Payment {
  date: string;
}

export interface Purchase {
  id: string;
  supplier?: Supplier;
  status: PurchaseStatus;
  delivery?: Delivery;
  payment?: Payment;
  detail: PurchaseVariant[];
  total: number;
  consecutive: number;
  purchaseNumber: string;
  metadata: Metadata;
  createdAt: string;
  updatedAt: string;
}

export interface Supplier {
  id: string;
  name: string;
  documentNumber: string;
  location: Location;
  contactName: string;
  email: string;
  phoneNumber: string;
  leadTime: number;
}

export interface Metadata {
  createdBy?: CreatedByUser;
  providerPONumber?: string;
  shipmentNumber?: string;
  linkedPurchase?: {
    id: string;
    purchaseNumber: string;
  };
  childPurchase?: {
    id: string;
    purchaseNumber: string;
  };
  isMarketplace: boolean;
  isConsignment: boolean;
}

export interface PurchaseVariant {
  id: string;
  variant: PurchaseVariantInfo;
  orders?: string[];
  values: Values;
  createdAt?: string;
  updatedAt?: string;
}

export interface Values {
  purchasePrice?: number;
  purchasePriceCurrency?: string;
  estimatedCogsVat: number;
  estimatedCogs: number;
  importCostVat?: number;
  importCost?: number;
  taxType: 'NOMINAL' | 'PERCENTAGE';
  tax: number;
  cogs?: number;
  cogsVat?: number;
  orderedQuantity: number;
  clearedQuantity?: number;
  receivedQuantity?: number;

  // Only applies for Backoffice
  ondemandPrice?: number;
}

type EditableValues = Omit<Values, 'taxType'>;
export type VariantValues = keyof EditableValues;

export interface PurchaseVariantInfo {
  variantSku: string;
  name: string;
  urlName: string;
  cost?: number;
  productId?: string;
  images?: VariantImage[];
}

export const StatusPurchaseName: { [key: number | string]: string } = {
  [PurchaseStatus.PENDING]: 'Pendiente',
  [PurchaseStatus.IN_TRANSIT]: 'En camino',
  [PurchaseStatus.CANCELLED]: 'Cancelada',
  [PurchaseStatus.DELIVERED]: 'Entregada',
};

export const purchaseStatusColors: { [key: string]: string } = {
  [PurchaseStatus.PENDING]: LightTheme.color.warning,
  [PurchaseStatus.IN_TRANSIT]: LightTheme.color.primary_dark,
  [PurchaseStatus.CANCELLED]: LightTheme.color.hard_danger,
  [PurchaseStatus.DELIVERED]: LightTheme.color.soft_link,
};

// Pending stock orders - purchase
export type PendingStockOrder = {
  orderNumber: string;
  id: string;
  quantity: number;
};

export interface PendingStockVariant {
  id: string;
  quantity: number;
  orders: PendingStockOrder[];
  name: string;
  variantSku: string;
  urlName: string;
  productId: string;
  images: VariantImage[];
  cost: number;
}

export interface PendingStockOrderMps extends MPS {
  variants: PendingStockVariant[];
}
