import { Input, Row } from 'antd';
import { BoldItemParagraph } from 'components/AutoCompleteUserItem/AutoCompleteUserItem.sty';
import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import { LayoutContainer } from 'pages/App/Layout.sty';
import styled from 'styled-components';

export const NumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const VariantFormContainer = styled(LayoutContainer)`
  overflow: visible;
  min-height: 60vh;
  height: fit-content;
`;

export const WrappedLabel = styled.span`
  white-space: pre-wrap;
  hyphens: auto;
`;

export const BrandItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BoldBrandParagraph = styled(BoldItemParagraph)`
  margin-left: ${spaceSizes.xl};
`;

export const ProductsWrapper = styled.div`
  & .ant-table-body {
    max-height: 300px !important;
    min-height: 300px;
  }
`;

export const VariantCostWrapper = styled.p`
  margin: auto 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const InventoryReasonTitle = styled.h2`
  color: ${LightTheme.color.primary};
  text-align: left;
  padding: 0px 20px;
  font-weight: bolder;
  width: 86%;
`;

export const StockButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
