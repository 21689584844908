/* eslint-disable no-underscore-dangle */
import { Button, Col, Form, Row, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import UserAutocomplete from 'containers/Users/UserAutocomplete/UserAutocomplete';
import { statusArray, WalletFormProps } from 'containers/Wallet/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import {
  InputBig,
  StyledForm,
  SubmitButtonWrapper,
} from 'modules/ShippingZones/components/ShippingZonesUpdate/ShippingZonesUpdate.sty';
import { useTranslation } from 'react-i18next';
import { StatusOrderEnum } from 'services/order/interface';
import {
  TransactionStatusEnum,
  transactionTypes,
  WalletViewEnum,
} from 'services/wallet/interface';

export const WalletForm = ({
  selectedTransaction,
  currentView,
  submitForm,
}: WalletFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const LABEL_COL = {
    xl: { span: 12 },
    lg: { span: 10 },
    sm: { span: 6 },
  };
  const WRAPPER_COL = {
    xl: { span: 10, offset: 0 },
    lg: { span: 12, offset: 2 },
    sm: { span: 100, offset: 2 },
  };

  return (
    <StyledForm
      scrollToFirstError
      onFinish={(values: any) => submitForm(values)}
      initialValues={selectedTransaction}
      form={form}
      style={{ paddingTop: '40px' }}
    >
      <Row gutter={[24, 0]} style={{ paddingLeft: '24px' }}>
        {selectedTransaction.status !== StatusOrderEnum.PENDING &&
          currentView === WalletViewEnum.UPDATE && (
            <Text>{t('wallet.labels.remember')}</Text>
          )}
        <Col span={18}>
          {currentView === WalletViewEnum.CREATE && (
            <Form.Item
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              name="user"
              label={t('wallet.labels.userName')}
              rules={[{ required: true, message: t('BOGENE003') }]}
            >
              <UserAutocomplete
                queryParams={{
                  pagination: {
                    page: 1,
                    pageSize: 10,
                  },
                }}
              />
            </Form.Item>
          )}
          {currentView === WalletViewEnum.UPDATE && (
            <>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="documentType"
                label={t('wallet.labels.documentType')}
              >
                <Text>{selectedTransaction?.user?.documentType}</Text>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="document"
                label={t('wallet.labels.document')}
              >
                <Text>{selectedTransaction?.user?.documentNumber}</Text>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="user"
                label={t('wallet.labels.userName')}
              >
                <Text>{selectedTransaction?.user?.name}</Text>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="account"
                label={t('wallet.labels.bank')}
              >
                <Text>{selectedTransaction?.user?.bank}</Text>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="accountType"
                label={t('wallet.labels.accountType')}
              >
                <Text>{selectedTransaction?.user?.accountType}</Text>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                labelCol={LABEL_COL}
                wrapperCol={WRAPPER_COL}
                name="accountNumber"
                label={t('wallet.labels.accountNumber')}
              >
                <Text>{selectedTransaction?.user?.bankAccount}</Text>
              </Form.Item>
            </>
          )}
          <Form.Item
            required
            name="total"
            label={t('wallet.labels.value')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
          >
            {currentView === WalletViewEnum.UPDATE ? (
              <Text>
                {numberToCurrency(Number(selectedTransaction?.total))}
              </Text>
            ) : (
              <InputBig type="number" />
            )}
          </Form.Item>
          <Form.Item
            required
            name="status"
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            label={t('wallet.labels.status')}
          >
            <Select
              disabled={
                !!selectedTransaction.status &&
                selectedTransaction.status !== TransactionStatusEnum.PENDING
              }
            >
              {statusArray.map((item) => (
                <Select.Option key={item} value={item}>
                  {t(item)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {currentView === WalletViewEnum.CREATE && (
            <Form.Item
              required
              name="reason"
              label={t('wallet.labels.reason')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
            >
              <InputBig />
            </Form.Item>
          )}
          <Form.Item
            required
            name="transactionType"
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            label={t('wallet.labels.transactionType')}
          >
            <Select disabled={currentView === WalletViewEnum.UPDATE}>
              {transactionTypes.map((item) => (
                <Select.Option key={item} value={item}>
                  {t(item)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <SubmitButtonWrapper>
        <Button htmlType="submit">{t('cityTableKeys.form.submit')}</Button>
      </SubmitButtonWrapper>
    </StyledForm>
  );
};
