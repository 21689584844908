import { FormInstance } from 'antd';
import { RolesActions } from 'config/roles/rolesActions';
import { LocationValue } from 'containers/General/LocationInput/interface';
import { UserAutoCompleteQueryParams } from 'containers/Users/UserAutocomplete/interface';
import { Role, RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { authUserAtom } from 'helpers/recoil/atoms/authUser';
import { difference, intersection } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserFormValues } from '../interface';

const NIT_TYPE_VALUE = 'NIT';

const emptyLocation = {
  address: '',
  details: '',
  latitude: '',
  longitude: '',
  cityCode: '',
};

const useUserForm = (form: FormInstance, initialValues: UserFormValues) => {
  const [showLastName, setShowLastName] = useState<boolean>(true);
  const [showParentStaffUser, setShowParentStaffUser] = useState<boolean>(
    !initialValues.role,
  );
  const authUser = useRecoilValue(authUserAtom);
  const [nameLabel, setNameLabel] = useState<string>('userForm.labels.name');
  const [staffMemberLabel, setStaffMemberLabel] = useState<string>(
    'userForm.labels.parentStaffUser.seller',
  );
  const showModal = useState<boolean>(false);
  const [showRoleInput, setShowRoleInput] = useState<boolean>(true);
  const modalLocation = useState<LocationValue>(emptyLocation);
  const locationValueIndex = useState<number>(-1);
  const addressSelectedState = useState<boolean>(false);
  const locationMapRef = useRef<google.maps.Map>();
  const [userQueryParams, setUserQueryParams] =
    useState<UserAutoCompleteQueryParams>({
      roleFilter: [RoleEnum.SELLER],
      pagination: {
        page: 1,
        pageSize: 10,
      },
    });
  const [disableUserAutocomplete, setDisableUserAutocomplete] =
    useState<boolean>(false);
  const { hasAccess: canEditRoles } = useRoleAccess('user.editRoles');
  const { setFieldsValue, setFields } = form;

  const setUserInputParams = useCallback(
    (role?: Role[]) => {
      setShowParentStaffUser(true);
      setStaffMemberLabel(
        role?.includes(RoleEnum.SELLER)
          ? 'userForm.labels.parentStaffUser.sellerCoordinator'
          : 'userForm.labels.parentStaffUser.seller',
      );
      setUserQueryParams({
        roleFilter: role?.includes(RoleEnum.SELLER)
          ? [RoleEnum.SELLER_COORDINATOR]
          : [RoleEnum.SELLER],
        pagination: {
          page: 1,
          pageSize: 10,
        },
      });
    },
    [setStaffMemberLabel, setUserQueryParams, setShowParentStaffUser],
  );

  const preFillParentUser = useCallback(
    (roles?: Role[]) => {
      const onlySellerCoordinator =
        difference(authUser?.role, RolesActions.user.preFillCoordinator.roles)
          .length === 0;
      if (onlySellerCoordinator) {
        setDisableUserAutocomplete(!!roles?.includes(RoleEnum.SELLER));
        if (roles?.includes(RoleEnum.SELLER)) {
          setFields([
            {
              name: 'parentStaffUser',
              value: authUser,
            },
          ]);
        }
      }
    },
    [authUser, setFields],
  );

  useEffect(() => {
    setFieldsValue(initialValues);
    preFillParentUser(initialValues.role);
  }, [initialValues, setFieldsValue, preFillParentUser]);

  useEffect(() => {
    if (
      Object.keys(initialValues.parentStaffUser || {}).length > 0 ||
      intersection(initialValues.role, [RoleEnum.SELLER, RoleEnum.MERCHANT])
        .length > 0
    ) {
      setUserInputParams(initialValues.role);
    }
  }, [initialValues, setUserInputParams, setShowParentStaffUser]);

  useEffect(() => {
    if ((initialValues.docType as string) === NIT_TYPE_VALUE) {
      setShowLastName(false);
      setNameLabel('userForm.labels.enterprise');
    }
  }, [initialValues]);

  useEffect(() => {
    if ((initialValues.role?.length || 0) > 0) {
      setShowRoleInput(canEditRoles);
    }
  }, [initialValues, canEditRoles]);

  const handleFormValuesChange = (changedValues: UserFormValues) => {
    if (Object.keys(changedValues).includes('docType')) {
      const { docType } = changedValues;
      if ((docType as string) === NIT_TYPE_VALUE) {
        setShowLastName(false);
        setNameLabel('userForm.labels.enterprise');
        return;
      }
      setShowLastName(true);
      setNameLabel('userForm.labels.name');
    }
    if (Object.keys(changedValues).includes('docType')) {
      form.validateFields(['documentNumber']);
    }
    if (Object.keys(changedValues).includes('role')) {
      const { role } = changedValues;
      const rolesWithStaffUser = [RoleEnum.MERCHANT, RoleEnum.SELLER];
      if (intersection(rolesWithStaffUser, role).length > 0) {
        setUserInputParams(role);
        preFillParentUser(role);
        return;
      }
      setShowParentStaffUser(false);
    }
  };

  return {
    showConfirmModal: useState<boolean>(false),
    showLastName,
    nameLabel,
    showModal,
    modalLocation,
    locationValueIndex,
    handleFormValuesChange,
    emptyLocation,
    locationMapRef,
    showParentStaffUser,
    staffMemberLabel,
    userQueryParams,
    showRoleInput,
    disableUserAutocomplete,
    addressSelectedState,
  };
};

export default useUserForm;
