import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleOutlined } from '@ant-design/icons';
import { useAuth } from 'helpers/context/AuthUserContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'helpers/constants/routes';
import LogoImage from '../../assets/images/logo.svg';
import LogoText from '../../assets/images/pandas_text.svg';
import {
  LoginActionsContainer,
  LoginContainer,
  Logo,
  LoginButton,
  ErrorText,
  PandasText,
  BackofficeText,
} from './Login.sty';

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginWithGoogle, authError, authUser } = useAuth();

  const openLoginPopUp = async () => {
    loginWithGoogle();
  };

  useEffect(() => {
    if (authUser) {
      navigate(ROUTES.home);
    }
  }, [authUser, navigate]);

  return (
    <LoginContainer>
      <LoginActionsContainer>
        <Logo src={LogoImage} alt="logo" />
        <PandasText src={LogoText} alt="pandas logo text" />
        <BackofficeText>{t('login.backofficeTitle')}</BackofficeText>
        <LoginButton
          type="primary"
          icon={<GoogleOutlined />}
          onClick={openLoginPopUp}
          shape="round"
          size="large"
        >
          {t('login.button')}
        </LoginButton>
        <ErrorText type="danger">{authError}</ErrorText>
      </LoginActionsContainer>
    </LoginContainer>
  );
};
