/* eslint-disable no-param-reassign */
import { Form } from 'antd';
import config from 'config';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import PurchaseForm from 'modules/Purchase/components/PurchaseForm';
import {
  CREATE_CLONE_PURCHASE_ERROR,
  CREATE_CLONE_PURCHASE_SUCCESS,
  CUSTOM_MESSAGE,
  EMPTY_PURCHASE_DETAIL,
  INVALID_PURCHASE_DETAIL,
  READ_PURCHASE_ERROR,
  UPDATE_PURCHASE_ERROR,
  UPDATE_PURCHASE_SUCCESS,
} from 'modules/Purchase/constants';
import { usePurchase } from 'modules/Purchase/context/Purchase.context';
import useWritePurchase from 'modules/Purchase/hooks/useWritePurchase';
import {
  PURCHASE_ACTION_ENUM,
  PURCHASE_VIEWS_ENUM,
} from 'modules/Purchase/reducer/interface';
import { clonePurchase } from 'modules/Purchase/reducer/utils/clonePurchase';
import { createNewPurchaseVariant } from 'modules/Purchase/reducer/utils/newPurchaseVariant';
import { validatePurchaseVariantDetail } from 'modules/Purchase/reducer/utils/validatePurchaseVariantDetail';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Variant } from 'services/product/interface';
import {
  Purchase,
  PurchaseVariant,
  VariantValues,
} from 'services/purchase/interface';

const PurchaseUpdateContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = usePurchase();
  const {
    purchase,
    purchaseLoading,
    purchaseError,
    isLoadingUpdate,
    updateError,
    updatePurchase,

    createPurchase: createClonePurchase,
    isLoadingCreate: isLoadingCreateClone,
    createError: createCloneError,
  } = useWritePurchase({ id: id ?? '' });

  const [form] = Form.useForm<Purchase>();
  const values = Form.useWatch([], form);

  const { notifySuccess, notifyError } = useNotifications({
    customMessage: CUSTOM_MESSAGE,
    translationFunction: t,
  });

  useEffect(() => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_CURRENT_VIEW,
      payload: PURCHASE_VIEWS_ENUM.UPDATE,
    });
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_STEP,
      payload: 2,
    });
  }, [dispatch]);
  useEffect(() => {
    if (purchase) {
      const currentPurchase: Purchase = {
        ...purchase,
        delivery: {
          ...purchase.delivery,
          date: moment(purchase.delivery?.date).utc() as unknown as string,
        },
      };
      if (currentPurchase.payment?.date) {
        currentPurchase.payment.date = moment(
          currentPurchase.payment.date,
        ).utc() as unknown as string;
      }

      if (currentPurchase.metadata.isMarketplace) {
        currentPurchase.detail.forEach((variant) => {
          variant.values.ondemandPrice = variant.values.estimatedCogsVat;
          variant.values.estimatedCogs = variant.values.estimatedCogsVat;
          variant.values.importCost = variant.values.estimatedCogsVat;
          variant.values.importCostVat = variant.values.estimatedCogsVat;
        });
      }

      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_FORM_INITIAL_VALUES,
        payload: currentPurchase,
      });

      form.setFieldsValue(currentPurchase);
    }
  }, [purchase, dispatch, form]);

  useEffect(() => {
    if (purchaseError) {
      navigate(ROUTES.purchase);
      notifyError(t(READ_PURCHASE_ERROR, { code: purchaseError.code }));
    }
  }, [purchaseError, navigate, notifyError]);
  useEffect(() => {
    if (updateError) {
      notifyError(
        t(`${UPDATE_PURCHASE_ERROR}.${updateError.code}`, {
          code: updateError.code,
        }),
      );
    }
  }, [updateError, navigate, notifyError]);
  useEffect(() => {
    if (createCloneError) {
      notifyError(
        t(CREATE_CLONE_PURCHASE_ERROR, { code: createCloneError.code }),
      );
    }
  }, [createCloneError, navigate, notifyError]);

  const onSelectPurchase = useCallback(
    (purchaseId: string) => {
      navigate(`${ROUTES.purchase}/${purchaseId}`);
    },
    [navigate],
  );

  const handlePickVariant = useCallback(
    (variant: Variant) => {
      const isMarketplace = state.formInitialValues?.metadata.isMarketplace;
      const newDetail = [...(values?.detail ?? [])];
      const newPurchaseVariant = createNewPurchaseVariant(
        variant,
        isMarketplace,
        false,
        purchase?.status,
      );
      newDetail.push(newPurchaseVariant);

      form.setFieldValue('detail', newDetail);
    },
    [
      form,
      state.formInitialValues?.metadata.isMarketplace,
      values?.detail,
      purchase?.status,
    ],
  );
  const handleRemovePurchaseVariant = useCallback(
    (variant: PurchaseVariant) => {
      let newDetail = [...(values?.detail ?? [])];
      newDetail = newDetail.filter((newDet) => newDet.id !== variant.id);

      form.setFieldValue('detail', newDetail);
    },
    [form, values?.detail],
  );
  const handleChangeValues = useCallback(
    (
      variant: PurchaseVariant,
      field: VariantValues,
      value: number | string,
    ) => {
      const newDetail = [...(values?.detail ?? [])];
      const variantIdx = newDetail.findIndex((v) => v.id === variant.id);

      if (variantIdx >= 0) {
        (newDetail[variantIdx].values[field] as string | number) = value;
        if (field === 'ondemandPrice') {
          newDetail[variantIdx].values.estimatedCogs = value as number;
          newDetail[variantIdx].values.estimatedCogsVat = value as number;
          newDetail[variantIdx].values.importCost = value as number;
          newDetail[variantIdx].values.importCostVat = value as number;
        }

        const cogsVat = Math.max(
          0,
          (newDetail[variantIdx].values.importCostVat ?? 0) -
            newDetail[variantIdx].values.tax,
        );
        const cogs = Math.max(
          0,
          (newDetail[variantIdx].values.importCost ?? 0) -
            newDetail[variantIdx].values.tax,
        );

        newDetail[variantIdx].values.cogsVat = cogsVat;
        newDetail[variantIdx].values.cogs = cogs;

        form.setFieldValue('detail', newDetail);
      }
    },
    [values, form],
  );

  useEffect(() => {
    if (!values?.detail?.length) return;

    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_PURCHASE_TOTAL,
      payload: values?.detail,
    });
  }, [values?.detail, dispatch]);

  const onConfirmCreateClone = useCallback(async () => {
    if (state.purchaseToClone) {
      const updated = await updatePurchase(values);
      const created = await createClonePurchase({ ...state.purchaseToClone });
      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_SHOW_CLONE_PURCHASE_MODAL,
        payload: false,
      });

      navigate(ROUTES.purchase);
      notifySuccess(
        t(UPDATE_PURCHASE_SUCCESS, { purchaseNumber: updated.purchaseNumber }),
      );
      notifySuccess(
        t(CREATE_CLONE_PURCHASE_SUCCESS, {
          purchaseNumber: created.purchaseNumber,
          linkedPurchase: created.metadata.linkedPurchase?.purchaseNumber,
        }),
      );
    }
  }, [
    state.purchaseToClone,
    updatePurchase,
    values,
    createClonePurchase,
    dispatch,
    notifySuccess,
    navigate,
  ]);

  const onCancelCreateClone = useCallback(async () => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_SHOW_CLONE_PURCHASE_MODAL,
      payload: false,
    });
    const updated = await updatePurchase(values);
    navigate(ROUTES.purchase);
    notifySuccess(
      t(UPDATE_PURCHASE_SUCCESS, { purchaseNumber: updated.purchaseNumber }),
    );
  }, [values, dispatch, updatePurchase, notifySuccess, navigate]);

  const handleSubmit = useCallback(async () => {
    if (!values.detail.length) {
      notifyError(t(EMPTY_PURCHASE_DETAIL));
      return;
    }

    const isValid = validatePurchaseVariantDetail(
      values.detail,
      false,
      values?.metadata?.isMarketplace ?? false,
      state.formInitialValues.status,
    );
    if (!isValid) {
      notifyError(t(INVALID_PURCHASE_DETAIL));
      return;
    }

    if (state.formInitialValues) {
      const { applyNewPurchase, purchaseToCreate } = clonePurchase(
        state.formInitialValues,
        values,
      );

      if (applyNewPurchase) {
        dispatch({
          type: PURCHASE_ACTION_ENUM.SET_SHOW_CLONE_PURCHASE_MODAL,
          payload: true,
        });

        dispatch({
          type: PURCHASE_ACTION_ENUM.SET_PURCHASE_TO_CLONE,
          payload: purchaseToCreate,
        });
        return;
      }

      const updated = await updatePurchase(values);
      navigate(ROUTES.purchase);
      notifySuccess(
        t(UPDATE_PURCHASE_SUCCESS, { purchaseNumber: updated.purchaseNumber }),
      );
    }
  }, [
    dispatch,
    state.formInitialValues,
    updatePurchase,
    values,
    notifySuccess,
    notifyError,
    navigate,
  ]);

  const openRemission = useCallback(() => {
    window.open(
      `${config.purchaseRemission.baseURL}/${state.formInitialValues.id}/remission`,
    );
  }, [state.formInitialValues]);

  const handleEdit = useCallback(() => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_READONLY,
      payload: false,
    });
  }, [dispatch]);

  if (purchaseLoading && state.formInitialValues) {
    return <>loading...</>;
  }

  return (
    <PurchaseForm
      showClonePurchaseModal={state.showClonePurchaseModal}
      isLoadingClonePurchase={isLoadingCreateClone}
      form={form}
      formInitialValues={state.formInitialValues}
      values={values}
      readOnly={state.readOnly}
      currentView={state.currentView}
      step={state.step}
      purchaseTotal={state.purchaseTotal}
      isLoading={purchaseLoading || isLoadingUpdate}
      onSelectPurchase={onSelectPurchase}
      onConfirmCreateClone={onConfirmCreateClone}
      onCancelCreateClone={onCancelCreateClone}
      onSubmit={handleSubmit}
      handlePickVariant={handlePickVariant}
      onRemovePurchaseVariant={handleRemovePurchaseVariant}
      onChangeValues={handleChangeValues}
      openRemission={openRemission}
      handleEdit={handleEdit}
    />
  );
};

export default PurchaseUpdateContainer;
