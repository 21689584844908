import { AxiosResponse } from 'axios';
import { PlatformEnum } from 'helpers/constants/platform';
import { getAPIMethod } from 'helpers/libs/Http';
import { Order } from 'services/order/interface';
import { Location } from 'services/user/interface';
import {
  DeliveryOption,
  DeliveryRangeOption,
  ShippingTotals,
  ShippingToValidate,
  ShippingValidated,
} from './interface';

export const validateShipping = async (
  shippingToValidate: ShippingToValidate,
) => {
  const response: AxiosResponse<ShippingValidated> = await getAPIMethod({
    name: 'validateShipping',
    data: shippingToValidate,
  });
  return response.data;
};

export const buildValidationData = (
  address: Location,
  totals: ShippingTotals,
): ShippingToValidate => ({
  cityName: address.cityName || '',
  cityCode: address.cityCode || '',
  department: address.department || '',
  totals,
  platform: PlatformEnum.BACKOFFICE,
});

export const getDeliveryDates = async ({
  order,
  isUpdate,
}: {
  order: Order;
  isUpdate?: boolean;
}): Promise<DeliveryOption[]> => {
  const response: AxiosResponse<DeliveryOption[]> = await getAPIMethod({
    name: 'getDeliveryDates',
    data: order,
    params: {
      isUpdate,
    },
  });
  return response.data;
};

export default {
  create: validateShipping,
  read: validateShipping,
  update: validateShipping,
  delete: validateShipping,
  readByParam: validateShipping,
};
