import { Col, Row } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { Title } from 'pages/App/Layout.sty';
import styled from 'styled-components';
import { TableWrapper } from 'components/TableWrapper';

export const OrderContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const TitleRow = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl};
`;

export const TableWrapperPandabonos = styled(TableWrapper)`
  padding: ${LightTheme.spaceSizes.xl3};
`;

export const TitleColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

export const OrderTitle = styled(Title)`
  margin: 0 !important;
`;
