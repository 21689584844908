import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { RefObject } from 'react';
import { Order } from 'services/order/interface';
import useOrderTableModule from '../../hooks/useOrderTable';

const TABLE_HEADER_HEIGHT = 230;

export interface OrderTableProps {
  data: Order[];
  isLoading: boolean;
  onViewDetail: (order: Order) => void;
  onScrollToEnd: (params?: any) => void;
  reachedEndOfData?: boolean;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
  rowSelection: {
    selectedRowKeys: React.Key[];
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
    getCheckboxProps: (record: any) => {
      disabled: boolean;
      name?: string;
    };
  };
}

export const OrderTable = ({
  data,
  isLoading,
  onViewDetail,
  onScrollToEnd,
  reachedEndOfData = false,
  tableRef,
  rowSelection,
}: OrderTableProps) => {
  const { columns, hiddenDescription } = useOrderTableModule();

  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="order-table"
      isLoading={isLoading}
      columns={columns}
      rowKey="id"
      onRow={(record) => ({
        onClick: () => onViewDetail(record as Order),
      })}
      expandable={{
        expandedRowRender: (record: Order) => hiddenDescription(record),
        rowExpandable: () => true,
      }}
      pagination={false}
      dataSource={data || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
      rowSelection={rowSelection}
    />
  );
};
