import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { debounce } from 'lodash';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { CartVariant } from 'services/product/interface';

const DEFAULT_PAGE_SIZE = 10;

const useInfiniteScroll = ({
  tableRef,
  customParams,
  fetchOnInvoke = true,
}: {
  tableRef: RefObject<{ scrollToTop: () => void }>;
  customParams?: Record<string, unknown>;
  fetchOnInvoke?: boolean;
}) => {
  const {
    readQuery: {
      mutateAsync: getVariantsAsync,
      isLoading: isLoadingVariantList,
    },
  } = useModuleCRUD('variant');

  const [variantList, setVariantList] = useState<CartVariant[]>([]);
  const [pagination, setPagination] = useState<PaginationParams>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [searchKey, setSearchKey] = useState<string>();

  const fetchFirstPage = useCallback(
    async (key: string) => {
      if (tableRef?.current) {
        tableRef.current.scrollToTop();
      }
      const fetchedData = await getVariantsAsync({
        search: key,
        pagination: {
          page: 1,
          pageSize: DEFAULT_PAGE_SIZE,
        },
        ...customParams,
      });
      setVariantList(fetchedData || []);
      setPagination({
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    },
    [getVariantsAsync, tableRef, customParams?.mpsId],
  );

  const fetchNextPage = useCallback(async () => {
    const fetchedData = await getVariantsAsync({
      search: searchKey,
      pagination: {
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      },
      ...customParams,
    });
    setVariantList((prev) => [...(prev || []), ...fetchedData]);
    if (fetchedData.length > 0) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  }, [searchKey, getVariantsAsync, pagination, customParams?.mpsId]);

  const fetchWithSearch = useCallback((search: string) => {
    setSearchKey(search);
  }, []);

  const debouncedSearch = useMemo(
    () => debounce(fetchWithSearch, 700),
    [fetchWithSearch],
  );

  const handleChangeSearchWithDebounce = useCallback(
    (val: string) => {
      debouncedSearch(val);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    if (fetchOnInvoke) {
      fetchFirstPage(searchKey || '');
    }
  }, [searchKey, fetchFirstPage, fetchOnInvoke]);

  return {
    getVariantsAsync,
    isLoadingVariantList,
    variantList,
    handleChangeSearchWithDebounce,
    searchKey,
    fetchNextPage,
    fetchFirstPage,
  };
};

export default useInfiniteScroll;
