/* eslint-disable react-hooks/exhaustive-deps */
import { BrandDBEntity } from 'components/BrandForm/interface';
import { brandsToOptions } from 'components/ProductForm/functions';
import { BrandOption } from 'components/ProductForm/interface';
import useBrandsList from 'containers/Brands/BrandsContentList/hooks/useBrandsList';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandsAutocompleteProps, nullishProductBrand } from '../interface';

const useBrandsAutocomplete = ({
  setBrandValue,
  form,
  initialValue,
}: BrandsAutocompleteProps) => {
  const [searchKeyInput, setSearchKeyInput] = useState<string>('');
  const [options, setOptions] = useState<BrandOption[]>([]);
  const [searchResult, setSearchResult] = useState<BrandDBEntity[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const { t } = useTranslation();
  const [isLoadingAutocomplete, setIsLoadingAutocomplete] =
    useState<boolean>(true);
  const {
    readQuery: { mutateAsync: getBrandsListAsync },
  } = useModuleCRUD('brands');

  const { fetchBrandsList, brandsList } = useBrandsList({
    searchKey: '',
    getBrandsListAsync,
  });

  const searchBrand = useCallback(
    async (search: string) => {
      setIsLoadingAutocomplete(true);
      await fetchBrandsList(search);

      setSearchResult(brandsList as BrandDBEntity[]);
      setOptions(brandsToOptions(brandsList as BrandDBEntity[]));
      form.setFieldsValue({ brand: null });
      setIsLoadingAutocomplete(false);
    },
    [brandsList, fetchBrandsList, form],
  );

  const debouncedSearch = useMemo(
    () => debounce(searchBrand, 700),
    [searchBrand],
  );

  const handleSearch = useCallback(
    (search: string) => {
      setSearchKeyInput(search);
      debouncedSearch(search);
    },
    [debouncedSearch],
  );

  const selectBrand = (selected: string) => {
    const brandSelection = searchResult?.find((brand) => brand.id === selected);
    setSearchKeyInput(brandSelection?.name ?? '');
    setBrandValue(brandSelection ?? nullishProductBrand);
    form.setFieldsValue({ brand: brandSelection?.id });
  };

  useEffect(() => {
    setSearchKeyInput(initialValue.name || t('brand.noBrand.title'));
    fetchBrandsList();
    if (initialValue.name) setBrandValue(initialValue);
  }, []);

  useEffect(() => {
    if (brandsList && firstLoad) {
      setSearchResult(brandsList as BrandDBEntity[]);
      setOptions(brandsToOptions(brandsList as BrandDBEntity[]));
      form.setFieldsValue({ brand: initialValue.id });
      setFirstLoad(false);
      setIsLoadingAutocomplete(false);
    }
  }, [brandsList]);

  return {
    searchResult,
    searchBrand,
    selectBrand,
    options,
    isLoadingAutocomplete,
    handleSearch,
    firstLoad,
    searchKeyInput,
  };
};

export default useBrandsAutocomplete;
