import { PlusOutlined } from '@ant-design/icons';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { DatePickerProps, Select } from 'antd';
import { AddButton } from 'components/AddButton';
import { CustomTag } from 'components/CustomTag';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import dateFormatCol from 'helpers/utils/dateFormatCol';
import moment from 'moment';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Coupon,
  CouponStatus,
  CouponStatusTagColor,
} from 'services/coupons/interface';
import {
  ButtonContainer,
  FilterCol,
} from '../../../../modules/Order/components/OrderContent/OrderContent.sty';
import { CustomDatePicker } from '../CouponsList.sty';

const DEFAULT_PAGE_SIZE = 30;
type CouponModalAction = 'CREATE' | 'UPDATE';

const useCouponsList = ({
  searchKey,
  tableRef,
  modalRef,
  getCouponListAsync,
  createCouponAsync,
  updateCouponAsync,
}: {
  createCouponAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  updateCouponAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  getCouponListAsync?: UseMutateAsyncFunction<any, any, any, unknown>;
  searchKey: string | undefined;
  tableRef?: RefObject<{ scrollToTop: () => void }>;
  modalRef?: RefObject<{
    openModal: () => void;
    closeModal: () => void;
    triggerEditable: () => void;
  }>;
}) => {
  const { t } = useTranslation();
  const creationDateFilterState = useState<string | undefined>();
  const statusFilterState = useState<CouponStatus | undefined>(undefined);
  const paginationState = useState<PaginationParams>({
    page: 1,
    pageSize: 10,
  });
  const [modalAction, setModalAction] = useState<CouponModalAction | undefined>(
    undefined,
  );
  const { hasAccess: canCreate } = useRoleAccess('coupon.create');

  const [creationDateFilter, setCreationDateFilter] = creationDateFilterState;
  const [statusFilter, setStatusFilter] = statusFilterState;
  const [reachedEndOfData, setReachedEndOfData] = useState<boolean>(false);
  const [modalCoupon, setModalCoupon] = useState<Coupon | undefined>(undefined);

  const [couponsList, setcouponsList] = useState<Coupon[] | undefined>();

  const dateFormatNow = 'DD/MM/YYYY';
  const customFormat: DatePickerProps['format'] = (value) =>
    `${value.format(dateFormatNow)}`;

  const customMessage = {
    success: {
      message: 'orderForm.modal.actions.success',
      description: 'orderForm.modal.message.success',
    },
    error: {
      message: 'orderForm.modal.actions.error',
      description: 'orderForm.modal.message.error',
    },
    cancel: {
      message: 'orderForm.modal.actions.cancel',
      description: 'orderForm.modal.message.cancel',
    },
    warning: {
      message: 'orderForm.modal.actions.cancel',
      description: 'orderForm.modal.message.addProductError',
    },
  };
  const notifications = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const [pagination, setPagination] = paginationState;
  const fetchCouponsList = useCallback(async () => {
    const fetchedCouponsList = await getCouponListAsync?.({
      search: searchKey,
      startDate: creationDateFilter,
      status: statusFilter,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    if (tableRef?.current) {
      tableRef.current.scrollToTop();
    }
    setcouponsList([...fetchedCouponsList.data]);
    setPagination({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    setReachedEndOfData(fetchedCouponsList.data.length < DEFAULT_PAGE_SIZE);
  }, [
    creationDateFilter,
    setPagination,
    getCouponListAsync,
    searchKey,
    statusFilter,
    setReachedEndOfData,
    tableRef,
  ]);

  const fetchNextCouponPage = useCallback(async () => {
    if (!reachedEndOfData) {
      const fetchedCouponsList = await getCouponListAsync?.({
        search: searchKey,
        startDate: creationDateFilter,
        status: statusFilter,
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      });
      setcouponsList((prev) => [...(prev || []), ...fetchedCouponsList.data]);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [
    creationDateFilter,
    pagination.page,
    pagination.pageSize,
    getCouponListAsync,
    searchKey,
    statusFilter,
    setPagination,
    reachedEndOfData,
  ]);

  useEffect(() => {
    fetchCouponsList();
  }, [fetchCouponsList, creationDateFilter, searchKey, statusFilter]);

  const handleChangeCreationDateFilter = useCallback(
    (_date: any, dateString: string) => {
      setCreationDateFilter(dateFormatCol(dateString));
    },
    [setCreationDateFilter],
  );

  const handleStatusFilterChange = useCallback(
    (value: CouponStatus) => {
      setStatusFilter(value.length > 0 ? value : undefined);
    },
    [setStatusFilter],
  );

  const handleSubmitForm = useCallback(
    async (values: Partial<Coupon>) => {
      try {
        if (modalAction === 'CREATE') {
          const createdCoupon = await createCouponAsync?.(values);
          notifications.notifySuccess(
            t('couponModal.form.actions.create.createSuccess', {
              couponName: createdCoupon.name,
            }),
          );
        }
        if (modalAction === 'UPDATE') {
          const updatedCoupon = await updateCouponAsync?.(values);
          notifications.notifySuccess(
            t('couponModal.form.actions.update.updateSuccess', {
              couponName: updatedCoupon.name,
            }),
          );
        }
        await fetchCouponsList();
      } catch (error) {
        console.error(error);
      }
    },
    [
      modalAction,
      createCouponAsync,
      updateCouponAsync,
      fetchCouponsList,
      notifications,
      t,
    ],
  );

  const handleTriggerCreateModal = useCallback(() => {
    if (modalRef?.current) {
      setModalCoupon(undefined);
      setModalAction('CREATE');
      modalRef?.current?.triggerEditable();
      modalRef?.current?.openModal();
    }
  }, [modalRef]);

  const handleViewDetail = useCallback(
    (value: Coupon) => {
      if (modalRef?.current) {
        setModalCoupon(value);
        setModalAction('UPDATE');
        modalRef?.current?.openModal();
      }
    },
    [modalRef],
  );

  const CouponsFilters = useCallback(
    () => (
      <>
        <FilterCol style={{ color: 'white', padding: 0 }}>
          <CustomDatePicker
            value={creationDateFilter ? moment(creationDateFilter) : undefined}
            placeholder={t('couponsFilters.creationDate')}
            onChange={handleChangeCreationDateFilter}
            format={customFormat}
          />
          <Select
            placeholder={t('couponsFilters.status')}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <Select.Option value="">{t('couponsFilters.status')}</Select.Option>
            {Object.keys(CouponStatus).map((statusOption) => (
              <Select.Option
                style={{ width: 160 }}
                value={statusOption}
                key={statusOption}
              >
                <CustomTag color={CouponStatusTagColor[statusOption]}>
                  {t(`couponStatus.${statusOption}`) || `${statusOption}` || ''}
                </CustomTag>
              </Select.Option>
            ))}
          </Select>
        </FilterCol>
        <ButtonContainer>
          {canCreate && (
            <AddButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleTriggerCreateModal}
            >
              {t('couponsFilters.create')}
            </AddButton>
          )}
        </ButtonContainer>
      </>
    ),
    [
      creationDateFilter,
      statusFilter,
      canCreate,
      handleChangeCreationDateFilter,
      handleStatusFilterChange,
      handleTriggerCreateModal,
      t,
    ],
  );

  return {
    creationDateFilterState,
    statusFilterState,
    notifications,
    paginationState,
    modalCoupon,
    fetchCouponsList,
    CouponsFilters,
    reachedEndOfData,
    setcouponsList,
    couponsList,
    fetchNextCouponPage,
    handleSubmitForm,
    t,
    handleViewDetail,
  };
};

export default useCouponsList;
