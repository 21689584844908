/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPIMethod } from 'helpers/libs/Http';
import {
  PandabonosUploadHistory,
  WalletStatusEnum,
} from 'pages/Pandabonos/interface';
import { PaginatedResponse } from 'services/global/interface';

export const getPandabonosById = async (userId: string): Promise<number> => {
  const response = await getAPIMethod({
    name: 'getPandabonosById',
    pathParams: { userId },
  });

  return response.data?.coins ?? 0;
};

const updatePandabonosCsv = async (filter: WalletStatusEnum) => {
  const response = await getAPIMethod({
    name: 'uploadPandabonosCsv',
    params: {
      status: WalletStatusEnum[filter as keyof typeof WalletStatusEnum],
    },
  });
  return response;
};

export const getPandabonosHistory = async (params: {
  page: number;
  pageSize: number;
}): Promise<PaginatedResponse<PandabonosUploadHistory>> => {
  const response = await getAPIMethod({
    name: 'getPandabonosHistory',
    params: {
      page: params.page,
      pageSize: params.pageSize,
    },
  });
  return response.data;
};

export default {
  create: updatePandabonosCsv,
  read: async () => ({}),
  update: async () => ({}),
  delete: async () => ({}),
  readByParam: getPandabonosHistory,
};
