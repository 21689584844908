import { Col, Divider, Row, Tag } from 'antd';
import { UnitStatusColor } from 'containers/Products/VariantList/interface';
import { useTranslation } from 'react-i18next';
import { UnitStatus } from 'services/unit/interface';
import { UnitMovementDefault, UnitRegistryMovement } from './interface';
import { TitleContainer } from './UnitMovementItem.sty';

const UnitMovementDescription = ({
  movement,
}: {
  movement: UnitRegistryMovement;
}) => {
  const { t } = useTranslation();

  const oldStatus = (movement?.oldStatus ||
    UnitMovementDefault.NO_STATUS) as UnitStatus;
  const newStatus = (movement?.newStatus ||
    UnitMovementDefault.NO_STATUS) as UnitStatus;

  const oldStatusColor = UnitStatusColor[oldStatus] || UnitStatusColor.sold;
  const newStatusColor = UnitStatusColor[newStatus] || UnitStatusColor.sold;

  const originLocation = (movement?.origin ?? '').trim();
  let origin = movement?.fullOrigin?.name ?? originLocation;

  if (origin === '' || origin === UnitMovementDefault.NO_ORIGIN) {
    origin = t('variantUnitMovements.fallbacks.location.NO_ORIGIN');
  }

  return (
    <Row gutter={[16, 40]}>
      <Col span={12}>
        <TitleContainer>{t('variantUnitMovements.origin')}</TitleContainer>
        <p>{origin}</p>
        <Divider />
        <TitleContainer>{t('variantUnitMovements.oldStatus')}</TitleContainer>
        <Tag color={oldStatusColor}>{oldStatus}</Tag>
      </Col>
      <Col span={12}>
        <TitleContainer>{t('variantUnitMovements.destination')}</TitleContainer>
        <p>{movement?.fullDestination?.name ?? movement.destination ?? 'NA'}</p>
        <Divider />
        <TitleContainer>{t('variantUnitMovements.newStatus')}</TitleContainer>
        <Tag color={newStatusColor}>{newStatus}</Tag>
      </Col>
    </Row>
  );
};

export default UnitMovementDescription;
