import { getAPIMethod } from 'helpers/libs/Http';
import { Variant } from 'services/product/interface';

const createComboVariant = async (variant: Variant) => {
  const response = await getAPIMethod({
    name: 'createCombo',
    data: variant,
  });
  return response.data;
};

const updateComboVariant = async (variant: Variant) => {
  const response = await getAPIMethod({
    name: 'updateCombo',
    pathParams: { variantId: variant.id },
    data: variant,
  });
  return response.data;
};

export default {
  create: createComboVariant,
  read: updateComboVariant,
  update: updateComboVariant,
  delete: updateComboVariant,
  readByParam: createComboVariant,
};
