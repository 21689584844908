import { Button, Form, Input, Select, Space, Spin, Typography } from 'antd';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { filterSelectByLabelText } from 'helpers/utils/filterSelect';
import { useTranslation } from 'react-i18next';
import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import { AttributeFormProps, AttributeFormValues } from './interface';

const AttributeForm = ({
  title,
  initialValues,
  onSubmit,
  isLoading,
  labelCol,
  wrapperCol,
}: AttributeFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState<AttributeTypeInterface>();
  const { AccessWrapper } = useRoleAccess('attributes.editActions');

  const {
    readQuery: {
      mutate: fetchTypeList,
      isLoading: loadingTypeList,
      data: typeList,
    },
  } = useModuleCRUD('attributeType');

  const handleSubmitAndReset = useCallback(
    (values: AttributeFormValues) => {
      const attributeToCreate = {
        ...values,
        typeId: selectedType?.id ?? '',
        type: selectedType?.name ?? '',
      };
      onSubmit(attributeToCreate);
      form.resetFields();
    },
    [form, onSubmit, selectedType?.id, selectedType?.name],
  );

  useEffect(() => {
    fetchTypeList({});
  }, [fetchTypeList]);

  const rules = [
    {
      required: true,
      message: t('attributesForm.errorMessages.required'),
    },
  ];

  const typeOptions = useMemo(
    () =>
      (typeList ?? []).map((attributeType: AttributeTypeInterface) => ({
        value: attributeType.id,
        label: attributeType.name,
      })),
    [typeList],
  );

  const onSelectType = useCallback(
    (typeId: string) => {
      const foundType = typeList.find(
        (attrType: AttributeTypeInterface) => attrType.id === typeId,
      );
      setSelectedType(foundType);
    },
    [typeList, setSelectedType],
  );

  if (loadingTypeList) {
    return (
      <Space direction="horizontal">
        <Spin />
        {t('loading')}
      </Space>
    );
  }

  return (
    <>
      <Typography.Title level={3}>{t(title)}</Typography.Title>
      {loadingTypeList ? (
        <Space direction="horizontal">
          <Spin />
          {t('loading')}
        </Space>
      ) : (
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          form={form}
          id="create-attribute-form"
          name="create-attribute"
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmitAndReset}
          scrollToFirstError
        >
          <AccessWrapper>
            <Form.Item
              name="name"
              label={t('attributesForm.labels.name')}
              rules={rules}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="type"
              label={t('attributesForm.labels.type')}
              rules={rules}
            >
              <Select
                filterOption={filterSelectByLabelText}
                showSearch
                options={typeOptions ?? []}
                onSelect={onSelectType}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={t('attributesForm.labels.description')}
              rules={rules}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('attributesForm.title.create')}
              </Button>
            </Form.Item>
          </AccessWrapper>
        </Form>
      )}
    </>
  );
};

export default AttributeForm;

AttributeForm.defaultProps = {
  title: 'attributesForm.title.create',
  labelCol: {
    xs: { span: 7 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 12 },
    xl: { span: 10 },
  },
};
