import { Attribute } from 'containers/Products/Attributes/interface';
import { getAPIMethod } from 'helpers/libs/Http';

const getAttributeList = async (params: any) => {
  const response = await getAPIMethod({
    name: 'getAttributeList',
    params: {
      attributeType: params?.attributeType || '',
    },
  });
  return response.data;
};

const createAttribute = async (attribute: Attribute) => {
  const response = await getAPIMethod({
    name: 'createAttribute',
    data: attribute,
  });
  return response.data;
};

const deleteAttributeById = async (attributeId: string) => {
  const response = await getAPIMethod({
    name: 'deleteAttributeById',
    pathParams: { attributeId },
  });
  return response;
};

const getAttributeMapByType = async (type: string) => {
  const response = await getAttributeList({ attributeType: type });
  return { type, attributes: response };
};

export const getAttributesBytTypeList = async (
  types: string[],
): Promise<Record<string, Attribute[]>> => {
  if (types.length <= 0) {
    const response = await getAttributeList({});
    return { noLevel: response };
  }
  const response = await Promise.all(
    types.map((type) => getAttributeMapByType(type)),
  );
  return response.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr.attributes,
    }),
    {},
  );
};

export default {
  create: createAttribute,
  read: getAttributeList,
  update: async (attribute: any) => ({}),
  delete: deleteAttributeById,
  readByParam: async (params: any) => ({}),
};
