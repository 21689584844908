/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
import React, { useRef } from 'react';
import { TableWrapper } from 'components/TableWrapper';
import useStoresList from './hooks/useStoresList';
import { StoresTable } from './StoresTable';
import { ButtonRigthContainer } from './StoresContentList.sty';
import { StoreForm } from '../StoreFormModal/StoreFormModal';

export const StoresContentList = () => {
  const tableRef = useRef<{ scrollToTop: () => void }>(null);

  const {
    t,
    StoresFilters,
    handleViewStoreDetail,
    handleStoreFormSubmit,
    fetchNextPage,
    handleCloseStoreModal,
    initialValues,
    showStoreModal,
    isLoadedGoogleMaps,
    isLoading,
    hasNextPage,
    isFetching,
    storesList,
  } = useStoresList({
    tableRef,
  });

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <ButtonRigthContainer>
        <StoresFilters />
      </ButtonRigthContainer>

      {showStoreModal && (
        <StoreForm
          initialValues={initialValues}
          showModal={showStoreModal}
          onSubmit={handleStoreFormSubmit}
          onClose={handleCloseStoreModal}
          update={!!initialValues?.id}
          isLoading={isLoading}
          loadingGoogleMaps={!isLoadedGoogleMaps}
        />
      )}

      <TableWrapper>
        <StoresTable
          reachedEndOfData={!hasNextPage}
          isLoading={isFetching}
          onUpdateStore={handleViewStoreDetail}
          data={storesList ?? []}
          onScrollToEnd={fetchNextPage}
          tableRef={tableRef}
        />
      </TableWrapper>
    </React.Suspense>
  );
};
