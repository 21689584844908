import { CsvModule } from 'components/CsvModule/CsvModule';
import { LayoutContainer } from 'pages/App/Layout.sty';
import useTransfersCsvContext from './context/useTransfersCsvContext';

const TransfersCsvContainer = () => {
  const {
    hint,
    inputText,
    title,
    uploadProps,
    formatDownloadProps,
    statusCheckingProps,
    ErrorView,
  } = useTransfersCsvContext();

  return (
    <LayoutContainer>
      <CsvModule
        hint={hint}
        inputText={inputText}
        title={title}
        uploadProps={uploadProps}
        formatDownloadProps={formatDownloadProps}
        statusCheckingProps={statusCheckingProps}
        ErrorView={ErrorView}
        showAlertResult={false}
      />
    </LayoutContainer>
  );
};

export default TransfersCsvContainer;
