/* eslint-disable no-useless-return */
import { PurchaseStatus, PurchaseVariant } from 'services/purchase/interface';

const isValidNumericQuantity = (val?: number, min = 0) =>
  !Number.isNaN(Number(val)) && Number(val) > min;

const isValidNullableNumericQuantity = (val?: number) =>
  !Number.isNaN(Number(val)) && Number(val) >= 0;

const hasValidCosts = (
  {
    estimatedCogsVat,
    estimatedCogs,
    importCostVat,
    importCost,
    tax,
    purchasePrice,
  }: PurchaseVariant['values'],
  isCreation: boolean,
  isMarketplace: boolean,
): boolean => {
  const validTax = isValidNullableNumericQuantity(tax);
  const validEstimatedCogsVat = isValidNumericQuantity(estimatedCogsVat);
  const validEstimatedCogs = isValidNumericQuantity(estimatedCogs);
  let validImportVat = true;
  let validImport = true;
  let validPurchasePrice = true;

  if (!isCreation) {
    validImport = isValidNumericQuantity(importCost);
    validImportVat = isValidNumericQuantity(importCostVat);
  }

  if (!isMarketplace) {
    validPurchasePrice = isValidNullableNumericQuantity(purchasePrice);
  }

  return (
    validEstimatedCogsVat &&
    validEstimatedCogs &&
    validImportVat &&
    validImport &&
    validTax &&
    validPurchasePrice
  );
};

const hasValidQuantities = (
  {
    orderedQuantity,
    clearedQuantity,
    receivedQuantity,
    minQuantitiesCol,
  }: PurchaseVariant['values'] & { minQuantitiesCol?: number },
  isCreation: boolean,
  status: PurchaseStatus,
  isMarketplace: boolean,
): boolean => {
  let validReceived = true;
  let validCleared = true;
  const validOrdered = isValidNumericQuantity(
    orderedQuantity,
    minQuantitiesCol,
  );

  if (!isMarketplace && !isCreation) {
    validCleared = isValidNumericQuantity(clearedQuantity, minQuantitiesCol);
  }

  if (!isCreation && status !== PurchaseStatus.PENDING) {
    validReceived = isValidNumericQuantity(receivedQuantity, minQuantitiesCol);
  }

  return validCleared && validOrdered && validReceived;
};

export const validatePurchaseVariantDetail = (
  purchaseVariantsList: PurchaseVariant[],
  isCreation: boolean,
  isMarketplace: boolean,
  status: PurchaseStatus,
): boolean => {
  let isValid = true;
  purchaseVariantsList.forEach((variant) => {
    const validQties = hasValidQuantities(
      variant.values,
      isCreation,
      status,
      isMarketplace,
    );

    const validCosts = hasValidCosts(variant.values, isCreation, isMarketplace);

    if (!validQties || !validCosts) {
      isValid = false;
      return;
    }
  });

  return isValid;
};
