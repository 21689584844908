import { Form, Input, Row, Typography } from 'antd';
import {
  Header,
  StatusItemContainer,
} from 'components/HaulierForm/HaulierForm.sty';
import { BaseColors, LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  overflow: scroll;
`;

export const BackTitle = styled(Typography.Title)`
  color: ${LightTheme.color.dark};
`;

export const RegionalWrapper = styled.div`
  padding-bottom: 20px;
`;

export const DataTagviewComp = styled.div``;

export const LocationWrapper = styled(Row)``;

export const InputBig = styled(Input)``;

export const SubmitButtonWrapper = styled(Row)`
  float: right;
  padding-right: 60px;
`;

export const SquareRegions = styled.div`
  border: 0.1px solid ${BaseColors.regent_grey};
  padding: 14px;
  text-align: center;
`;

export const SideHeader = styled(Header)``;

export const UpdateImg = styled.img`
  width: ${spaceSizes.xl5};
  height: ${spaceSizes.xl5};
  border-radius: 50%;
  object-fit: cover;
`;

export const StatusItemWrapper = styled(StatusItemContainer)`
  margin-bottom: 0px;
  min-width: 300px;
`;
