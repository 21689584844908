import { PlusOutlined } from '@ant-design/icons';
import { useLoadScript } from '@react-google-maps/api';
import { Select } from 'antd';
import { AddButton } from 'components/AddButton';
import config from 'config';
import useInfiniteRead from 'helpers/hooks/useModuleCRUD/useInfiniteRead';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { StoreFilterAtom } from 'helpers/recoil/atoms/stores/storeFilter';
import { storeTypesAtom } from 'helpers/recoil/atoms/stores/storeTypes';
import { FilterCol } from 'modules/Order/components/OrderContent/OrderContent.sty';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Store } from 'services/store/interface';

type Libraries = 'places';
const libraries = ['places'] as Libraries[];
const DEFAULT_PAGE_SIZE = 30;

const useStoresList = ({
  tableRef,
}: {
  tableRef: RefObject<{ scrollToTop: () => void }>;
}) => {
  const { t } = useTranslation();
  const { hasAccess: canCreate } = useRoleAccess('stores.create');
  const { notifySuccess } = useNotifications({ translationFunction: t });
  const storeTypes = useRecoilValue(storeTypesAtom);
  const [filter, setFilter] = useRecoilState(StoreFilterAtom);

  const { isLoaded: isLoadedGoogleMaps } = useLoadScript({
    googleMapsApiKey: config.googleMapsConfig.apiKey,
    libraries,
  });

  const [initialValues, setInitialValues] = useState<
    Partial<Store> | undefined
  >({});
  const [showStoreModal, setShowStoreModal] = useState<boolean>(false);

  const listParams = useMemo(
    () => ({
      ...filter,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    }),
    [filter],
  );

  const {
    readInfiniteQuery: { fetchNextPage, data, hasNextPage, isFetching },
    updateMutation: {
      mutateAsync: updateStoreAsync,
      isLoading: isLoadingUpdate,
    },
    createMutation: {
      mutateAsync: createStoreAsync,
      isLoading: isLoadingCreate,
    },
  } = useInfiniteRead(
    'stores',
    listParams,
    tableRef.current?.scrollToTop,
    true,
  );

  const storesList: Store[] = useMemo(
    () =>
      data?.pages
        ?.flat()
        .map((item) => item?.data)
        ?.flat() ?? [],
    [data],
  );

  const handleViewStoreDetail = useCallback((store: Store) => {
    setInitialValues(store);
    setShowStoreModal(true);
  }, []);

  const handleCloseStoreModal = useCallback(() => {
    setInitialValues({});
    setShowStoreModal(false);
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    setInitialValues(undefined);
    setShowStoreModal(true);
  }, []);

  const handleStoreFormSubmit = useCallback(
    async (storeData: Partial<Store>) => {
      if (initialValues?.id) {
        await updateStoreAsync(storeData);
        notifySuccess('storeModal.feedback.update.success');
        handleCloseStoreModal();
        return;
      }
      await createStoreAsync(storeData);
      notifySuccess('storeModal.feedback.create.success');
      handleCloseStoreModal();
    },
    [
      initialValues,
      createStoreAsync,
      updateStoreAsync,
      handleCloseStoreModal,
      notifySuccess,
    ],
  );

  const handleSelectFilter = useCallback(
    (type: string) => {
      setFilter({ type, search: null });
    },
    [setFilter],
  );

  const StoresFilters = useCallback(
    () => (
      <FilterCol
        style={{
          color: 'white',
          padding: 0,
        }}
      >
        <Select
          placeholder={t('store.StoresFilters.type')}
          onChange={handleSelectFilter}
          value={filter.type}
        >
          {storeTypes.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type.title}
            </Select.Option>
          ))}
          <Select.Option key="all" value="">
            {t('store.StoresFilters.all')}
          </Select.Option>
        </Select>
        {canCreate && (
          <AddButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenCreateModal}
          >
            {t('store.StoresFilters.create')}
          </AddButton>
        )}
      </FilterCol>
    ),
    [
      canCreate,
      t,
      handleOpenCreateModal,
      storeTypes,
      handleSelectFilter,
      filter,
    ],
  );

  return {
    handleCloseStoreModal,
    handleViewStoreDetail,
    StoresFilters,
    handleStoreFormSubmit,
    fetchNextPage,
    t,
    initialValues,
    showStoreModal,
    isLoadedGoogleMaps,
    isLoading: isLoadingUpdate || isLoadingCreate,
    hasNextPage,
    isFetching,
    storesList,
  };
};

export default useStoresList;
