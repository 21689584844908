import { attributeTypeErrors } from './attribute-type';
import { brandErrors } from './brand.errors';
import { categoryErrors } from './categories.errors';
import { couponErrors } from './coupon.errors';
import { creditErrors } from './credit.errors';
import { haulierErrors } from './haulier.errors';
import { ordersErrors } from './order.errors';
import { productErrors } from './product.errors';
import { routesErrors } from './routes.errors';
import { userErrors } from './user.errors';
import { wmsErrors } from './wms.errors';

export interface ErrorMessageObject {
  translation: string;
  isBlocking?: boolean;
  isWarning?: boolean;
  invisible?: boolean;
}

export const errorConfig: { [key: string]: ErrorMessageObject } = {
  ...couponErrors,
  ...ordersErrors,
  ...attributeTypeErrors,
  ...userErrors,
  ...brandErrors,
  ...creditErrors,
  ...productErrors,
  ...routesErrors,
  ...haulierErrors,
  ...categoryErrors,
  ...wmsErrors,
};
