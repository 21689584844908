/* eslint-disable no-useless-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

export const buildOptionsDropdown = (options: any) => {
  if (options) {
    const newOptions: any[] = [];
    options?.map((opt: any) => {
      newOptions.push({
        value: opt,
        label: opt,
      });
    });
    return newOptions;
  }
};

export const buildOptionsDropdownObject = (
  options: any,
): { value: string; label: string }[] => {
  if (!options) {
    return [];
  }

  const newOptions = options.map((opt: any) => {
    const label = opt.code ?? opt.value;
    const labelPrefix = label.substring(0, 3);
    let displayLabel = opt.name || opt.label;

    if (!displayLabel.startsWith('[')) {
      displayLabel = `[${labelPrefix}] - ${displayLabel}`;
    }

    return {
      value: opt.code ?? opt.value,
      label: displayLabel,
    };
  });

  return newOptions;
};
