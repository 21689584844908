import { LocationQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';

const getLocationList = async (queryParams?: LocationQueryParams) => {
  const response = await getAPIMethod({
    name: 'getLocationList',
    params: {
      search: queryParams?.search,
      isLeaf: queryParams?.isLeaf,
      page: queryParams?.pagination?.page,
      pageSize: queryParams?.pagination?.pageSize,
    },
  });
  return response.data;
};

const getLocationTree = async (queryParams?: LocationQueryParams) => {
  const response = await getAPIMethod({
    name: 'getLocationTree',
    params: {
      page: 1,
      pageSize: 9999,
    },
    pathParams: {
      locationId: queryParams?.locationId || '',
    },
  });
  return response.data;
};

export const getLocationsByVariantId = async (variantId: string) => {
  const response = await getAPIMethod({
    name: 'getLocationsWithUnitsByVariantID',
    pathParams: {
      variantId,
    },
  });
  return response.data ?? [];
};

export default {
  read: getLocationList,
  create: getLocationList,
  update: getLocationList,
  delete: getLocationList,
  readByParam: getLocationTree,
};
