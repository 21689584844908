/* eslint-disable react/require-default-props */
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AllowedRuleProp, Behavior } from 'services/wms-settings/interface';
import { BehaviorFormNamePaths } from '../constants';
import { getSelectorMode } from '../helpers/getSelectorMode';
import { findRuleInRuleSet } from '../helpers/rule';
import { InitialRuleFormItemValues, noRuleInitialValue } from './interface';

const RuleFormItem = ({
  definition,
  form,
  tlPrefix,
  initialValues,
}: {
  definition: AllowedRuleProp;
  form: FormInstance;
  tlPrefix: string;
  initialValues?: Behavior;
}) => {
  const { t } = useTranslation();
  const defaultValues: InitialRuleFormItemValues = useMemo(() => {
    const initial = findRuleInRuleSet(initialValues?.ruleSet ?? [], definition);
    if (!initial) {
      return noRuleInitialValue;
    }

    return {
      evaluation: initial.evaluation,
      evaluator: initial.evaluator,
    };
  }, [initialValues, definition]);

  const onSelectOption = useCallback(
    (data) => {
      form.setFieldValue(
        [
          BehaviorFormNamePaths.rulesPath,
          definition.field,
          BehaviorFormNamePaths.evaluator,
        ],
        data,
      );
    },
    [form, definition],
  );

  const onSelectComparator = useCallback(
    (data) => {
      form.setFieldValue(
        [
          BehaviorFormNamePaths.rulesPath,
          definition.field,
          BehaviorFormNamePaths.evaluation,
        ],
        data,
      );
    },
    [form, definition],
  );

  useEffect(() => {
    form.setFieldValue([BehaviorFormNamePaths.rulesPath, definition.field], {
      [BehaviorFormNamePaths.name]: definition.name,
      [BehaviorFormNamePaths.field]: definition.field,
      [BehaviorFormNamePaths.evaluatorType]: definition.type,
    });
  }, [form, definition]);

  const comparators = useMemo(
    () =>
      definition.comparators.map((comparator) => ({
        label: t(`wms-settings.rules.comparators.${comparator}`),
        value: comparator,
      })),
    [definition, t],
  );

  const selectorMode = useMemo(
    () => getSelectorMode(definition.type),
    [definition],
  );

  return (
    <Form.Item
      name={[BehaviorFormNamePaths.rulesPath, definition.field]}
      noStyle
    >
      <Form.Item
        key={`${definition.field}evaluation`}
        label={t(`${tlPrefix}.rules.form.labels.${definition.name}`)}
        required
        rules={[
          {
            required: true,
            message: t('wms-settings.behavior.form.errors.general'),
          },
        ]}
        name={[
          BehaviorFormNamePaths.rulesPath,
          definition.field,
          BehaviorFormNamePaths.evaluation,
        ]}
      >
        <Select
          defaultValue={defaultValues.evaluation}
          onChange={onSelectComparator}
          options={comparators}
        />
      </Form.Item>
      <Form.Item
        key={`${definition.field}evaluator`}
        required
        rules={[
          {
            required: true,
            message: t('wms-settings.behavior.form.errors.general'),
          },
        ]}
        name={[
          BehaviorFormNamePaths.rulesPath,
          definition.field,
          BehaviorFormNamePaths.evaluator,
        ]}
      >
        <Select
          mode={selectorMode}
          onChange={onSelectOption}
          options={definition.options}
          defaultValue={defaultValues.evaluator}
        />
      </Form.Item>
    </Form.Item>
  );
};

export default RuleFormItem;
