import { SearchOutlined } from '@ant-design/icons';
import { StoresContentList } from 'containers/Stores/StoresContentList/StoresContentList';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { StoreFilterAtom } from 'helpers/recoil/atoms/stores/storeFilter';
import { storeTypesAtom } from 'helpers/recoil/atoms/stores/storeTypes';
import { debounce } from 'lodash';
import { TitleColumn } from 'modules/Order/components/OrderContent/OrderContent.sty';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  SearchInput,
  StoresContainer,
  StoresTitle,
  TitleRow,
} from './StoresList.sty';

export const StoresList = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useRecoilState(StoreFilterAtom);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [storeTypes, setStoreTypes] = useRecoilState(storeTypesAtom);

  const {
    readQuery: { mutateAsync: getParameters },
  } = useModuleCRUD('parameters');

  const getStoreTypes = useCallback(async () => {
    const params = await getParameters({ keys: 'storeTypes' });
    setStoreTypes(params.storeTypes.data);
  }, [getParameters, setStoreTypes]);

  useEffect(() => {
    if (!storeTypes.length) getStoreTypes();
  }, [getStoreTypes, storeTypes]);

  const onSearchWithDebounce = debounce((value: string) => {
    if (!value) {
      setFilter({});
      return;
    }
    setFilter({ search: value, type: null });
  }, 700);

  useEffect(() => {
    if (searchKey != null) onSearchWithDebounce(searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    if (filter.type != null) setSearchKey(null);
  }, [filter]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
    onSearchWithDebounce.cancel();
  };

  return (
    <StoresContainer>
      <TitleRow>
        <TitleColumn
          span={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <StoresTitle level={1}>{t('menu.storesList')}</StoresTitle>
        </TitleColumn>
      </TitleRow>
      <TitleRow style={{}}>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchInput
            placeholder={t('store.SearchPlaceholder')}
            bordered={false}
            prefix={<SearchOutlined />}
            onChange={handleInput}
            value={searchKey ?? ''}
          />
        </TitleColumn>
      </TitleRow>
      <TitleRow>
        <StoresContentList />
      </TitleRow>
    </StoresContainer>
  );
};
