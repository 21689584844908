import { notification } from 'antd';
import { CategoryForm } from 'containers/Categories/CategoryForm/CategoryForm';
import { CategoryFormValues } from 'containers/Categories/CategoryForm/interface';
import { ROUTES } from 'helpers/constants/routes';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CategoryCreateValues } from 'services/category/category.service';

const initialValues = {
  name: '',
  parentId: '',
  description: '',
  industry: '',
  isParent: false,
};

export const CreateCategory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.createCategory}`);

  const {
    createMutation: { mutateAsync: createCategory, isLoading },
  } = useModuleCRUD('categories');

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  const onSubmit = useCallback(
    async (values: CategoryFormValues) => {
      const reqBody: CategoryCreateValues = {
        name: values.name,
        description: values.description,
        imageUrl: values.imageUrl,
        industry: values.industry,
        urlName: values.urlName,
        parentId: values.isParent ? undefined : values.parentId,
        landingSubcategory: values.landingSubcategory,
      };
      await createCategory(reqBody);
      navigate(`${ROUTES.categoryList}`);
    },
    [createCategory, navigate],
  );

  return (
    <CategoryForm
      updateCategory={false}
      title="createCategory"
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
