/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import {
  ContainerOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Tag } from 'antd';
import TableActions from 'components/TableActions/TableActions';
import { LightTheme } from 'helpers/constants/theme';
import { useMediaQuery } from 'helpers/hooks/useMediaQuery/useMediaQuery';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Variant, VariantStatus } from 'services/product/interface';
import HiddenDescription from '../components/HiddenDescription';
import StatusSelectRender from '../components/StatusSelectRender';
import ThumbnailRender from '../components/ThumbnailRender';
import { VariantContent } from '../interface';

const tableColumns = (
  VariantActions: (variant: VariantContent) => JSX.Element | null,
  t: TFunction<'translation', undefined>,
) => [
  {
    title: t('productTableKeys.image'),
    render: (variant: VariantContent) => <ThumbnailRender variant={variant} />,
  },
  {
    title: t('productTableKeys.name'),
    dataIndex: 'name',
  },
  {
    title: t('productTableKeys.stockValues.totalWarehouse'),
    render: (variant: VariantContent) =>
      `${
        (variant.stock?.availableInWarehouse ?? 0) +
        (variant.stock?.notAvailableInWarehouse ?? 0)
      } | ${variant.stock?.availableInWarehouse ?? 0} | ${
        variant.stock?.notAvailableInWarehouse ?? 0
      }
      `,
  },
  {
    title: t('productTableKeys.stockValues.total'),
    render: (variant: VariantContent) =>
      `${variant.stock?.available ?? 0} | ${variant.stock?.cap ?? 0}
      `,
  },
  {
    title: t('productTableKeys.status'),
    render: (variant: VariantContent) => (
      <StatusSelectRender variant={variant} />
    ),
    filters: [
      {
        text: t('stockStatus.ACTIVE'),
        value: VariantStatus.ACTIVE,
      },
      {
        text: t('stockStatus.INACTIVE'),
        value: VariantStatus.INACTIVE,
      },
    ],
    onFilter: (value: unknown, record: Variant) => {
      return record.status === value;
    },
  },
  {
    title: t('productTableKeys.actions'),
    render: VariantActions,
  },
];

const useVariantTable = ({
  onUpdateVariant,
  onGoToPWA,
}: {
  onDeleteVariant: (productId: string, variantId: string) => void;
  onUpdateVariant: (variant: VariantContent) => void;
  onGoToPWA: (urlName: string) => void;
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const { AccessWrapper } = useRoleAccess('product.editActions');

  const VariantActions = useCallback(
    (variant: VariantContent) => {
      const handleUpdate = (variantVal: VariantContent) => {
        onUpdateVariant(variantVal);
      };
      const handleGoToPwa = (variantVal: VariantContent) => {
        const { urlName } = variantVal;
        onGoToPWA(urlName);
      };
      return (
        <AccessWrapper
          fallback={
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              {t('noAccess')}
            </Tag>
          }
        >
          <>
            <TableActions entity={variant} onUpdate={handleUpdate} />
            <Button>
              <ContainerOutlined onClick={() => handleGoToPwa(variant)} />
            </Button>
          </>
        </AccessWrapper>
      );
    },
    [AccessWrapper, onGoToPWA, onUpdateVariant, t],
  );
  const defaultColumns = tableColumns(VariantActions, t);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('productTableKeys.description'),
      t('productTableKeys.stock'),
      t('productTableKeys.actions'),
    ];
    const filteredColumns = isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
    return filteredColumns;
  }, [isMobile, defaultColumns, t]);
  return {
    columns,
    hiddenDescription: (variant: VariantContent) => (
      <HiddenDescription variant={variant} VariantActions={VariantActions} />
    ),
  };
};

export default useVariantTable;
