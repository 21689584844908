import { Image, Spin } from 'antd';
import { BannerCardImageLoader } from 'components/BannerCardItem/BannerCardItem.sty';
import { BANNER_IMAGE_LOADING_TYPE } from 'components/BannerCardItem/constants';
import { ForwardedRef, forwardRef } from 'react';
import { DraggableBannerPicItem } from './BannerSort.sty';
import { ItemProps } from './interface';

const BannerPicture = (
  { id, image, isDragging, ...props }: ItemProps,
  ref: ForwardedRef<any>,
) => {
  return (
    <DraggableBannerPicItem
      isDragging={isDragging ?? false}
      id={id}
      ref={ref}
      {...props}
    >
      <Image
        src={image}
        preview={false}
        loading={BANNER_IMAGE_LOADING_TYPE}
        placeholder={
          <BannerCardImageLoader>
            <Spin />
          </BannerCardImageLoader>
        }
      />
    </DraggableBannerPicItem>
  );
};

const BannerPictureItem = forwardRef(BannerPicture);
export default BannerPictureItem;
