import { Button, Col, Input, Row } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { Title } from 'pages/App/Layout.sty';
import styled from 'styled-components';

const { Search } = Input;

export const PurchaseContainer = styled.div`
  height: calc(100vh - 112px);

  .ant-tabs-tab-btn {
    color: white;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${LightTheme.color.primary};
  }
`;

export const TitleRow = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl};
`;

export const TitleColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

export const PurchaseTitle = styled(Title)`
  margin: 0 !important;
`;

export const SearchInput = styled(Search)`
  max-width: 400px;
`;

export const FiltersAndButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 20px 0;
`;
