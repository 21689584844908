/* eslint-disable react/require-default-props */
import { Radio, RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';
import { Location } from 'services/user/interface';
import {
  AddressValueWrapper,
  AddressWrapper,
  DetailsValueWrapper,
  DisplayInputWrapper,
} from '../PurchaseForm/PurchaseForm.sty';

export interface RadioProps {
  value?: Location;
  onChange?: (value: Location) => void;
  options: Location[];
  disabled?: boolean;
}

const getLocationValue = (location?: Location) =>
  location
    ? `${location.address ?? ''}${location.details ?? ''}${
        location.cityCode ?? ''
      }${String(location.latitude ?? '')}${String(location.longitude ?? '')}`
    : '';

const LocationDisplay = ({ value }: { value: Location }) => (
  <DisplayInputWrapper>
    {(value.address || value.details || value.cityCode) && (
      <AddressWrapper>
        {value.address && (
          <AddressValueWrapper>{value.address || ''}</AddressValueWrapper>
        )}
        {value.details && (
          <DetailsValueWrapper>{value.details || ''}</DetailsValueWrapper>
        )}
        {value.cityCode && (
          <DetailsValueWrapper>
            {`Ciudad: ${value.cityCode || ''}`}
          </DetailsValueWrapper>
        )}
      </AddressWrapper>
    )}
  </DisplayInputWrapper>
);

const RadioGroup = ({ value, onChange, disabled, options }: RadioProps) => {
  const [localValue, setLocalValue] = useState<string>(getLocationValue(value));
  useEffect(() => {
    setLocalValue(getLocationValue(value));
  }, [value, setLocalValue]);

  const triggerChange = (changedValue: Location) => {
    onChange?.({
      ...value,
      ...changedValue,
    });
  };

  const handleChangeObject = (stringValue: string) => {
    const stringOption = options.find(
      (option) => getLocationValue(option) === stringValue,
    );
    if (stringOption) {
      setLocalValue(stringValue);
      triggerChange({ ...stringOption });
    }
  };

  const handleChange = (e: RadioChangeEvent) => {
    const {
      target: { value: eventValue },
    } = e;
    handleChangeObject(eventValue);
  };

  return (
    <Radio.Group value={localValue} disabled={disabled} onChange={handleChange}>
      {options.map((location: Location) => (
        <Radio
          value={getLocationValue(location)}
          key={`${location.latitude}-${location.longitude}`}
        >
          <LocationDisplay value={location} />
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioGroup;
