export interface UnitRegistryUser {
  email: string;
  id: string;
  name: string;
  roles: string[] | null;
}

export interface UnitRegistryLocation {
  _id: string;
  name: string;
  isParent: boolean;
  parentId: string;
  properties: string[];
  warehouse: {
    _id: string;
    name: string;
  };
}

export interface UnitRegistry {
  id: string;
  time: string;
  variantId: string;
  movements: UnitRegistryMovement[];
  user?: UnitRegistryUser;

  // old registries fields
  to?: string;
  quantity?: number;
}

export interface UnitRegistryMovement {
  id: string;
  origin: string;
  originProps: string[];
  destination: string;
  destinationProps: string[];
  quantity: number;
  oldStatus: string;
  newStatus: string;
  relatedId?: string;
  fullOrigin?: UnitRegistryLocation;
  fullDestination?: UnitRegistryLocation;
  category?: UnitMovementCategoryEnum;
}

export enum UnitMovementCategoryEnum {
  PATCH = 'patch',
  SYS_PATCH = 'system-patch',
  MERGE = 'merge',
  TRANSFER = 'transfer',
  PURCHASE = 'purchase',
  SALE = 'sale',
  WARRANTY = 'warranty',
}

export enum UnitMovementDefault {
  NO_ORIGIN = 'NO_ORIGIN',
  DEFAULT_CATEGORY = 'transfer',
  NO_STATUS = 'NA',
  ZERO_QUANTITY = '0',
}
