import { FormCoupon } from 'containers/Coupons/CouponModal/interface';
import { Coupon, CouponType } from 'services/coupons/interface';

const buildCouponFromParams = (
  couponData: Partial<FormCoupon>,
  prevCouponData?: Coupon,
): Partial<Coupon> => ({
  ...prevCouponData,
  ...couponData,
  uses: couponData.uses
    ? Number(couponData.uses || prevCouponData?.uses || 0)
    : undefined,
  usesPerUser: couponData.usesPerUser
    ? Number(couponData.usesPerUser || prevCouponData?.usesPerUser || 0)
    : undefined,
  minimumPurchase: couponData.minimumPurchase
    ? Number(couponData.minimumPurchase || prevCouponData?.minimumPurchase || 0)
    : undefined,
  maxDiscount: couponData.maxDiscount
    ? Number(couponData.maxDiscount || prevCouponData?.maxDiscount || 0)
    : undefined,
  nominalValue: couponData.nominalValue
    ? Number(couponData.nominalValue || prevCouponData?.nominalValue || 0)
    : undefined,
  percentageValue: couponData.percentageValue
    ? Number(couponData.percentageValue || prevCouponData?.percentageValue || 0)
    : undefined,
  startDate: couponData.startDate?.toISOString(),
  endDate: couponData.endDate?.toISOString(),
  segmentation: couponData?.segmentation || undefined,
  maxCouponSpent:
    couponData.type === CouponType.NOMINAL ? -1 : couponData?.maxCouponSpent,
  categorySegmentation:
    couponData?.productSegmentation === 'CATEGORY' ||
    prevCouponData?.categorySegmentation ||
    undefined,
  targetCategories:
    couponData?.targetCategories || prevCouponData?.targetCategories || [],
  targetSellers:
    couponData?.targetSellers || prevCouponData?.targetSellers || [],
  brandSegmentation:
    couponData?.productSegmentation === 'BRAND' ||
    prevCouponData?.brandSegmentation ||
    undefined,
  targetBrands: couponData?.targetBrands || prevCouponData?.targetBrands || [],
  isSponsoredCoupon:
    couponData?.productSegmentation === 'SELLER' ||
    prevCouponData?.isSponsoredCoupon ||
    false,
  userSegmentation:
    couponData?.userSegmentation ||
    prevCouponData?.userSegmentation ||
    undefined,
  targetUsers: couponData?.targetUsers || prevCouponData?.targetUsers || [],
});

export default buildCouponFromParams;
