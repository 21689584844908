import { TablePaginationConfig } from 'antd';
import { CreditStatusEnum } from 'containers/CreditLine/interface';
import { PaginatedResponse } from 'services/global/interface';
import { User } from 'services/user/interface';

export enum CreditLineStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface CreditLine {
  id?: string;
  userId: string;
  userName: string;
  authorizedCredit: number;
  debt: number;
  status: CreditLineStatus;
  fee: number;
  balanceInFavor?: number;
}

export interface CreditTableProps {
  data: PaginatedResponse<CreditLine> | undefined;
  pagination: TablePaginationConfig;
  loading: boolean;
  onUpdateCredit: (credit: CreditLine) => void;
  onChange: (pagination: TablePaginationConfig) => Promise<void>;
  setTablePagination: React.Dispatch<
    React.SetStateAction<TablePaginationConfig>
  >;
}

export interface CreditForm {
  _id?: string;
  user: User | null;
  authorizedCredit: number;
  debt?: number;
  fee: number;
  amortization?: number;
  availableCredit?: number;
  status?: CreditStatusEnum;
  voucher?: string;
  balanceInFavor?: number;
}

export const defaultUser: User = {
  id: '',
  info: {
    name: '',
    lastName: '',
  },
};

export const creditFormInitial: CreditForm = {
  user: null,
  authorizedCredit: 0,
  fee: 2.4,
  status: CreditStatusEnum.ACTIVE,
};
