import { Select } from 'antd';
import useObjectSelect from './hooks/useObjectSelect';
import { GenericOption, SelectObjectProps } from './interface';

const SelectObject = ({ value, onChange, options }: SelectObjectProps) => {
  const {
    localId: [localId, setLocalId],
  } = useObjectSelect(value);
  const triggerChange = (changedValue: GenericOption) => {
    onChange?.({
      ...value,
      ...changedValue,
    });
  };
  const handleChangeObject = (idValue: string) => {
    const idOption = options?.find((option) => option?.id === idValue);
    if (idOption) {
      setLocalId(idValue);
      triggerChange({ ...idOption });
    }
  };

  const defaultExitsInList = options?.find((option) => option.id === value?.id);

  return (
    <Select
      value={defaultExitsInList ? localId : null}
      onChange={handleChangeObject}
      optionFilterProp="children"
      filterOption
      showSearch
    >
      {options?.map((option) => (
        <Select.Option key={option.id} value={option.id}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectObject;
