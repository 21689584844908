/* eslint-disable no-underscore-dangle */
import { DropboxCircleFilled } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import { VariantLocation } from './interface';
import LocationListDescription from './LocationListDescription';
import LocationListTitle from './LocationListTitle';

const LocationList = ({ data }: { data: VariantLocation[] }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item: VariantLocation) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar size="large">
                <DropboxCircleFilled />
              </Avatar>
            }
            title={<LocationListTitle item={item} />}
            description={<LocationListDescription item={item} />}
          />
        </List.Item>
      )}
    />
  );
};

export default LocationList;
