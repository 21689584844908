import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';
import { HaulierStatusEnum } from './interface';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  padding: ${spaceSizes.l} 0 0 ${spaceSizes.xxl};
  margin-bottom: 0;
  color: ${LightTheme.color.dark_alt};
  font-weight: bold;
`;

export const IconImg = styled.img`
  width: ${spaceSizes.xl5};
  height: ${spaceSizes.xl5};
  border-radius: 50%;
  object-fit: cover;
  margin: ${spaceSizes.l} ${spaceSizes.xxl} 0 0;
`;

export const StatusItemContainer = styled.div`
  display: flex;
  max-width: 200px;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.s};
  margin-bottom: ${LightTheme.spaceSizes.l};
  padding: 0 ${spaceSizes.xxl};
`;

export const HaulierStatusColor: { [key: string]: string } = {
  [HaulierStatusEnum.ACTIVE]: LightTheme.color.success,
  [HaulierStatusEnum.INACTIVE]: LightTheme.color.danger,
};

export const StatusFlag = styled.div<{ color: string }>`
  width: ${spaceSizes.ml};
  height: ${spaceSizes.ml};
  border-radius: 50%;
  background: ${({ color }) => `${color}`};
  margin-right: ${LightTheme.spaceSizes.sm};
`;

export const DetailTitle = styled.div`
  padding: 8px;
  background-color: ${LightTheme.color.primary_lightest};
  margin-bottom: 20px;

  & span {
    color: ${LightTheme.color.dark_alt};
  }
`;

export const ItemLabel = styled.span`
  font-weight: bold;
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: ${LightTheme.spaceSizes.m};
`;

export const NoHistory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DateSpan = styled.span`
  font-weight: bold;
  color: ${LightTheme.color.primary};
`;

export const HaulierHistoryLog = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HistoryContainer = styled.section`
  max-height: 60vh;
  overflow-y: scroll;
`;
