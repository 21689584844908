import useNotifications from 'helpers/hooks/useNotifications';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INITIAL_PAGE } from '../constants';
import { GenericInfiniteScrollProps } from '../interface';

function useGenericInfiniteScroll<T>({
  pureFetchFn,
  fetchOnDemand,
}: GenericInfiniteScrollProps<T>) {
  const [page, setPage] = useState<number>(1);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(!fetchOnDemand);
  const [data, setData] = useState<T[]>([]);
  const { t } = useTranslation();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  const fetchPage = useCallback(
    async (forceReload?: boolean) => {
      if ((hasNext || forceReload) && page > 0) {
        setIsLoading(true);
        try {
          const { data: fetchData, metadata } = await pureFetchFn(
            forceReload ? INITIAL_PAGE : page,
          );
          setHasNext(metadata.hasNext);
          setData((oldData) => [...oldData, ...fetchData]);
          setPage(metadata.page + 1);
        } catch (e: unknown) {
          console.error('[INFINITE-SCROLL-HOOK]', e);
          notifyError();
        } finally {
          setIsLoading(false);
        }
      }
    },
    [hasNext, setIsLoading, pureFetchFn, page, notifyError],
  );

  const fetchNext = useCallback(() => {
    fetchPage();
  }, [fetchPage]);

  const reloadFetch = useCallback(() => {
    setData([]);
    fetchPage(true);
  }, [fetchPage]);

  useEffect(() => {
    if (!fetchOnDemand) {
      fetchPage();
    }
  }, [fetchPage, fetchOnDemand]);

  return {
    isLoading,
    hasNext,
    data,
    fetchNext,
    reloadFetch,
  };
}

export default useGenericInfiniteScroll;
