import { Modal } from 'antd';
import MPSUserForm from 'components/MPSUserForm/MPSUserForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MPSUserModalProps } from './interface';

const MPSUserModal = ({
  isOpen,
  closeModal,
  modalActionType,
  initialValues,
  reLoadOnSuccess,
}: MPSUserModalProps) => {
  const [formReference, setFormReference] = useState<any>();

  const { t } = useTranslation();
  return (
    <Modal
      width="95%"
      open={isOpen}
      onCancel={closeModal}
      destroyOnClose
      onOk={formReference ? formReference.submit : null}
      okText={t('MPSUser.okButton.update')}
    >
      <MPSUserForm
        actionType={modalActionType}
        setFormReference={setFormReference}
        closeModal={closeModal}
        reLoadOnSuccess={reLoadOnSuccess}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default MPSUserModal;
