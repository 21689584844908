import { cloneDeep } from 'lodash';

export function changeSubObjectProperty<T>(
  object: Record<string, T>,
  path: string,
  prop: keyof T,
  value: unknown,
  validationRule?: (oldVal: unknown, newVal: unknown) => boolean,
): Record<string, T> {
  const objClone = cloneDeep(object);

  const oldProp = objClone?.[path]?.[prop];
  const validationIsOk = validationRule?.(oldProp, value) ?? true;

  if (validationIsOk) {
    objClone[path][prop] = value as T[keyof T];
  }

  return objClone;
}
