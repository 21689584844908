import { RuleObject } from 'antd/lib/form';
import { t } from 'i18next';
import { FormInstance } from 'rc-field-form/lib/interface';

export const validateNonEmptyString = (_form: FormInstance): RuleObject => ({
  validator(_: RuleObject, value: string) {
    const documentNumber = value.trim();
    if (!documentNumber.length) {
      return Promise.reject(new Error(t('BOORDS016')));
    }
    return Promise.resolve();
  },
});
