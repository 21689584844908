/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import { Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import LoaderWrapper from 'components/LoadingWrapper';
import UploadItem from 'components/Upload/UploadItem';
import { UserSummary } from 'components/UserSummary';
import { MultiAddressInput } from 'containers/General/MultiAddressInput/MultiAddressInput';
import { MultiUserAutoComplete } from 'containers/Users/MultiUserAutoComplete/MultiUserAutoComplete';
import UserAutocomplete from 'containers/Users/UserAutocomplete/UserAutocomplete';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { storeTypesAtom } from 'helpers/recoil/atoms/stores/storeTypes';
import { phoneValidator } from 'helpers/utils/formValidators';
import { validateNonEmptyString } from 'helpers/utils/validateNonEmptyString';
import validateUserDocTypeAntd from 'helpers/utils/validateUserDocTypeAntd';
import { useRecoilValue } from 'recoil';
import { DocTypesObj, DocType } from 'services/user/interface';
import { useStoreForm } from './hooks/useStoreForm';
import { StoreFormProps } from './interface';
import { PersonalizedCheckbox, StoreFormModal } from './StoreFormModal.sty';

const { Item } = Form;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const StoreForm = ({
  initialValues,
  showModal,
  update,
  isLoading,
  loadingGoogleMaps,
  onSubmit,
  onClose,
}: StoreFormProps) => {
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const storeTypes = useRecoilValue(storeTypesAtom);
  const {
    form,
    isLoadingStore,
    displaySeller,
    tagStore,
    handleClose,
    handleFormValuesChange,
    onSubmitWrapper,
    handleTagStore,
    t,
    fileListState: [fileList, setFileList],
  } = useStoreForm({
    update,
    initialValues,
    onClose,
    onSubmit,
  });

  return (
    <StoreFormModal
      width="95%"
      visible={showModal}
      title={
        <Typography.Title
          level={3}
          style={{
            margin: '0',
          }}
        >
          {t(update ? 'storeModal.titles.update' : 'storeModal.titles.create')}
        </Typography.Title>
      }
      onOk={() => form.submit()}
      onCancel={handleClose}
      okButtonProps={{
        loading: isLoading,
        style: {
          border: 'none',
          borderRadius: LightTheme.spaceSizes.xs,
          backgroundColor: LightTheme.color.primary,
          fontWeight: 'bold',
          color: LightTheme.color.white,
        },
      }}
    >
      <LoaderWrapper loading={!!loadingGoogleMaps || isLoadingStore}>
        <Form
          {...layout}
          form={form}
          initialValues={initialValues}
          autoComplete="off"
          labelWrap
          name="store-form-modal"
          onFinish={onSubmitWrapper}
          onValuesChange={handleFormValuesChange}
        >
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Item
                label={t('userForm.labels.name')}
                name="name"
                rules={[
                  { required: true, message: t('BOGENE003') },
                  validateNonEmptyString,
                ]}
              >
                <Input />
              </Item>
              <Item
                required
                name="phone"
                label={t('userForm.labels.phoneNumber')}
                tooltip={t('store.tooltips.phoneNumber')}
                rules={[
                  {
                    validator: (_, value) =>
                      phoneValidator(
                        value,
                        { colombianPhone: true, required: true, maxLength: 10 },
                        t,
                      ),
                  },
                ]}
              >
                <Input type="tel" />
              </Item>
              <Item
                name="email"
                label={t('userForm.labels.email')}
                rules={[{ required: true, message: t('BOGENE003') }]}
              >
                <Input type="email" />
              </Item>
              <Item
                name="users"
                label={t('storeModal.labels.users')}
                rules={[{ required: true, message: t('BOGENE003') }]}
              >
                <MultiUserAutoComplete
                  queryParams={{
                    roleFilter: [RoleEnum.MERCHANT],
                    pagination: {
                      page: 1,
                      pageSize: 10,
                    },
                  }}
                  cleanOnSelect
                />
              </Item>
              <Item label={t('storeModal.labels.tagStore')}>
                <Row gutter={[16, 8]} style={{ alignItems: 'center' }}>
                  <Col span={3}>
                    <PersonalizedCheckbox
                      size={20}
                      onChange={handleTagStore}
                      checked={tagStore}
                    />
                  </Col>
                  <Col span={21}>
                    {tagStore && (
                      <Item
                        name="type"
                        style={{ marginBottom: 0 }}
                        rules={[
                          { required: tagStore, message: t('BOGENE003') },
                        ]}
                      >
                        <Select placeholder={t('storeModal.placeholder.type')}>
                          {storeTypes.map((type) => (
                            <Select.Option key={type.value} value={type.value}>
                              {type.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>
                    )}
                  </Col>
                </Row>
              </Item>
              <Item
                name="pictures"
                label={t('variantForm.labels.images')}
                style={{ width: isMobile ? '100%' : 400 }}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <UploadItem
                  folder="products"
                  fileList={fileList}
                  maxLength={10}
                  onUpload={(file) => {
                    const fileListToSet = [...fileList, file];
                    setFileList(fileListToSet);
                    form.setFieldsValue({ pictures: fileListToSet });
                  }}
                  onRemove={(file) => {
                    const fileListToSet = fileList.filter(
                      (fileInList) => fileInList.uid !== file.uid,
                    );
                    setFileList(fileListToSet);
                    form.setFieldsValue({ pictures: fileListToSet });
                  }}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={t('userForm.labels.documentType')}
                name="docType"
                rules={[{ required: true, message: t('BOGENE003') }]}
              >
                <Select placeholder={t('userForm.placeholders.docType')}>
                  {(Object.keys(DocTypesObj) as DocType[]).map(
                    (doc: DocType) => (
                      <Option key={doc} value={doc}>
                        {t(DocTypesObj[doc])}
                      </Option>
                    ),
                  )}
                </Select>
              </Item>
              <Item
                name="docNumber"
                label={t('userForm.labels.documentNumber')}
                rules={[
                  { required: true, message: t('BOGENE003') },
                  validateUserDocTypeAntd,
                ]}
              >
                <Input />
              </Item>
              <Item
                name="addresses"
                label={t('storeModal.labels.addresses')}
                rules={[{ required: true, message: t('BOGENE003') }]}
              >
                <MultiAddressInput />
              </Item>
              <Item
                name="seller"
                label={t('storeModal.labels.seller')}
                rules={[{ required: true, message: t('BOGENE003') }]}
              >
                <UserAutocomplete
                  queryParams={{
                    roleFilter: [RoleEnum.SELLER],
                    pagination: {
                      page: 1,
                      pageSize: 10,
                    },
                  }}
                />
              </Item>
              <Item>
                <Col span={23} offset={8}>
                  <Divider />
                  <Item name="sellerCoordinator">
                    <Typography.Title level={5}>
                      {t('userForm.orderSellerTitle.seller')}
                    </Typography.Title>
                    {displaySeller && <UserSummary user={displaySeller} />}
                  </Item>
                  <Divider />
                  <Item>
                    <Typography.Title level={5}>
                      {t('userForm.orderSellerTitle.sellerCoordinator')}
                    </Typography.Title>
                    {displaySeller && (
                      <UserSummary
                        user={displaySeller?.sellerCoordinator || displaySeller}
                      />
                    )}
                  </Item>
                </Col>
              </Item>
            </Col>
          </Row>
        </Form>
      </LoaderWrapper>
    </StoreFormModal>
  );
};
