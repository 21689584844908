import { Alert } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import { StyledBoard } from './Board.sty';
import Column from './Column';
import useBoard from './hooks/useBoard';
import { BoardProps, ColumnItem, IBoardColumn } from './interface';

const { ErrorBoundary } = Alert;

function Board<T extends ColumnItem>({
  columns,
  columnChangeEventConfig,
  handleColumnChange,
  renderCard,
  setColumns,
  animation,
  infiniteScrollConfig,
}: Readonly<BoardProps<T>>) {
  const { onDragEnd } = useBoard({
    columnChangeEventConfig,
    columns,
    handleColumnChange,
    setColumns,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledBoard columnsQuantity={columns.length}>
        {columns.map((column: IBoardColumn<T>) => {
          return (
            <ErrorBoundary key={column.id}>
              <Column<T>
                animation={animation}
                key={column.id}
                column={column}
                renderCard={renderCard}
                infiniteScrollConfig={infiniteScrollConfig}
              />
            </ErrorBoundary>
          );
        })}
      </StyledBoard>
    </DragDropContext>
  );
}

export default Board;
