import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'services/product/interface';

const useProductForm = (
  getAttributeList: (a: undefined) => void,
  initialValues: Partial<Product>,
) => {
  const [editorState, setEditorState] = useState<string>(
    initialValues.description ?? '',
  );

  const [form] = Form.useForm();
  const translation = useTranslation();

  useEffect(() => {
    getAttributeList(undefined);
  }, [getAttributeList]);

  const handleEditorChange = useCallback(
    (data: string) => {
      setEditorState(data);
      form.setFieldsValue({
        ...form.getFieldsValue,
        description: editorState,
      });
    },
    [editorState, form, setEditorState],
  );

  return {
    translation,
    form,
    editorState,
    handleEditorChange,
  };
};

export default useProductForm;
