import { Location } from 'services/location/interface';
import { Variant } from 'services/product/interface';
import { ITransferUnits } from 'services/transfers/interface';

export function buildNewTransferItem(
  variant: Variant,
  variantId: string,
  quantity: number,
  maxQuantity: number,
  originLocation: string,
  initialLocation?: Location,
): ITransferUnits {
  return {
    originLocation,
    status: variant.unitStatus ?? '',
    quantity,
    variantId,
    variant,
    initialLocation,
    maxQuantityAllowed: maxQuantity,
  };
}
