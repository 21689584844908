import { LightTheme } from 'helpers/constants/theme';
import { TaxRegime } from 'services/MPS/interface';
import { BillingType } from 'services/product/interface';

export enum MPSUserModalAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum mpsRequestStatusEnum {
  'RECEIVED' = 'RECEIVED',
  'REJECTED' = 'REJECTED',
  'ACCEPTED' = 'ACCEPTED',
}
export const mpsRequestStatusColors: { [key: string]: string } = {
  [mpsRequestStatusEnum.RECEIVED]: LightTheme.color.soft_blue,
  [mpsRequestStatusEnum.REJECTED]: LightTheme.color.soft_danger,
  [mpsRequestStatusEnum.ACCEPTED]: LightTheme.color.success,
  '': LightTheme.color.primary,
};

export interface MPSUSERRequest {
  _id: string;
  address: string;
  name: string;
  document: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  status?: mpsRequestStatusEnum;
  takeRate: number;
  delay: number;
  catalogProducts: string;
  documentType?: string;
  taxRegime?: TaxRegime;
  isTaxWithholding?: boolean;
  department?: string;
  departmentCode?: string;
  billingType?: BillingType;
  commission?: number;
  city?: string;
  cityCode?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface MPSUserFormValues {
  _id: string;
  address: string;
  name: string;
  document: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  status?: mpsRequestStatusEnum;
  delay: number;
  takeRate: number;
  catalogProducts: string;
  documentType?: string;
  taxRegime?: TaxRegime;
  isTaxWithholding?: boolean;
  billingType?: BillingType;
  mandatedBilling?: boolean;
  department?: string;
  departmentCode?: string;
  city?: string;
  cityCode?: string;
}

export const MPSUserFormInitialValues: MPSUserFormValues = {
  _id: '',
  address: '',
  name: '',
  document: '',
  contactName: '',
  email: '',
  phoneNumber: '',
  delay: 0,
  takeRate: 0,
  catalogProducts: '',
  documentType: '',
  taxRegime: TaxRegime.COMUN,
  isTaxWithholding: false,
  department: '',
  departmentCode: '',
  city: '',
  cityCode: '',
  billingType: BillingType.SERVICE,
  mandatedBilling: false,
};
