import { AutoComplete, Input } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Supplier } from 'services/purchase/interface';
import useSupplierAutocomplete from './hooks/useSupplierAutocomplete';
import { SupplierAutocompleteProps } from './interface';

const { Search } = Input;

const SupplierAutocomplete = ({
  value,
  onChange,
  disabled,
}: SupplierAutocompleteProps) => {
  const triggerChange = useCallback(
    (changedValue: Supplier) => {
      onChange?.({
        ...value,
        ...changedValue,
      });
    },
    [onChange, value],
  );

  const {
    searchKey,
    supplierOptions,
    isLoadingAutocomplete,
    handleSelectAutocomplete,
    handleSearchSuppliers,
  } = useSupplierAutocomplete(triggerChange, value);

  const { t } = useTranslation();

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      options={supplierOptions}
      onSelect={handleSelectAutocomplete}
      onSearch={handleSearchSuppliers}
      value={searchKey}
      disabled={disabled}
    >
      <Search
        size="large"
        placeholder={t('supplierAutocomplete.placeholder')}
        loading={isLoadingAutocomplete}
      />
    </AutoComplete>
  );
};

export default SupplierAutocomplete;
