/* eslint-disable no-underscore-dangle */
import { ResponsiveTreeMapHtml } from '@nivo/treemap';
import { InputNumber, Slider, Space, Switch } from 'antd';
import LoaderWrapper from 'components/LoadingWrapper';
import { LocationAutocomplete } from 'components/LocationsAutocomplete/LocationsAutocomplete';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { LayoutContainer, Title } from 'pages/App/Layout.sty';
import {
  InputContainer,
  InputLabel,
  TransferContainer,
  TransferInputContainer,
} from 'pages/WMS/Transfer/Transfer.sty';
import { useCallback, useState } from 'react';
import Tree from 'react-d3-tree';
import 'react-d3-treemap/dist/react.d3.treemap.css';
import { useTranslation } from 'react-i18next';
import { Location } from 'services/location/interface';
import { Unit } from 'services/unit/interface';

interface TreeNode {
  name: string;
  value?: number;
  attributes: {
    properties?: string;
    unitsCount?: number;
    isWarehouse?: string;
  };
  children?: TreeNode[];
  unitsCount?: number;
}

interface LocationTreeResponse {
  location: Location;
  levels: Location[][];
  units: {
    metadata: {
      page: number;
      pageSize: number;
      length: number;
      hasNext: boolean;
    };
    data: Unit[];
  };
}

function transformDataToTree(
  data: LocationTreeResponse,
  withUnits: boolean,
): TreeNode {
  const { location, levels, units } = data;
  const rootNode: TreeNode = {
    name: location.name,
    attributes: {},
    children: [],
  };

  const rootAttributes = {
    properties: location.properties?.join(' | ') || undefined,
    isWarehouse: location.isWarehouse ? 'Yes' : undefined,
  };

  if (rootAttributes.properties) {
    rootNode.attributes.properties = rootAttributes.properties;
  }

  if (rootAttributes.isWarehouse) {
    rootNode.attributes.isWarehouse = rootAttributes.isWarehouse;
  }

  const levelMap = new Map<string, TreeNode>();

  // Create nodes for each level
  levels.forEach((level: Location[]) => {
    const levelNodes: TreeNode[] = [];

    level.forEach((item) => {
      const node: TreeNode = {
        name: item.name,
        attributes: {},
        value: 10,
        children: [],
      };

      const selfAttributes = {
        properties: item.properties?.join(' | ') || undefined,
        isWarehouse: item.isWarehouse ? 'Si' : undefined,
      };

      if (selfAttributes.properties) {
        node.attributes.properties = selfAttributes.properties;
      }

      if (selfAttributes.isWarehouse) {
        node.attributes.isWarehouse = selfAttributes.isWarehouse;
      }

      const leafUnits = units.data.filter(
        (unit: Unit) => unit.locationId === item._id,
      );

      const unitsCount = leafUnits.length;

      if (unitsCount > 0) {
        node.attributes.unitsCount = unitsCount;
        if (withUnits) {
          node.children = leafUnits.map((u) => ({
            name: u._id,
            value: 1,
            attributes: {},
          }));
          node.value = unitsCount;
        }
        node.value = 100;
      }

      levelMap.set(item._id, node);

      const parentNode = levelMap.get(item.parentId);
      if (parentNode) {
        parentNode.value = parentNode?.children?.length ?? 1;
        parentNode.children?.push(node);
      } else {
        rootNode.value = rootNode?.children?.length ?? 1;
        rootNode.children?.push(node);
      }
    });

    rootNode.children?.push(...levelNodes);
  });
  rootNode.value = rootNode?.children?.length ?? 0;

  return rootNode;
}

const LocationTreeContainer = () => {
  const { t } = useTranslation();
  const [isGraphChart, setIsGraphChart] = useState(false);
  const [firstTreeResponse, setFirstTreeResponse] = useState<
    LocationTreeResponse | undefined
  >(undefined);
  const [treeResponse, setTreeResponse] = useState<
    LocationTreeResponse | undefined
  >(undefined);

  const {
    readByParamQuery: { mutateAsync: getLocationTree, isLoading },
  } = useModuleCRUD('location');
  const onChartChange = (checked: boolean) => {
    setIsGraphChart(checked);
  };

  const handleAutoCompleteSelect = useCallback(
    async (value?: Location) => {
      const response = await getLocationTree({ locationId: value?._id });
      setTreeResponse({
        ...response,
        levels: response?.levels.slice(0, 1),
      });
      setFirstTreeResponse(response);
    },
    [getLocationTree],
  );

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <Title level={1}>{t('menu.locationTree')}</Title>
      <LayoutContainer>
        <TransferContainer>
          <TransferInputContainer>
            <InputContainer>
              <InputLabel>{t('wms.locationTree.originLabel')}</InputLabel>
              <LocationAutocomplete
                placeholder={t('wms.locationTree.originPlaceHolder')}
                handleSelect={handleAutoCompleteSelect}
              />
            </InputContainer>

            {treeResponse && (
              <>
                <Switch
                  style={{ marginLeft: '20px' }}
                  checkedChildren="Tree"
                  unCheckedChildren="MapTree"
                  onChange={onChartChange}
                />
                <InputLabel>{t('wms.locationTree.depth')}</InputLabel>
                <InputNumber
                  min={1}
                  max={firstTreeResponse?.levels.length}
                  defaultValue={1}
                  onChange={(value) => {
                    if (firstTreeResponse) {
                      setTreeResponse({
                        ...firstTreeResponse,
                        levels: firstTreeResponse?.levels.slice(0, value ?? 0),
                      });
                    }
                  }}
                />
              </>
            )}
          </TransferInputContainer>

          <LoaderWrapper loading={isLoading}>
            {treeResponse && (
              <div id="treeWrapper" style={{ width: '100%', height: '100%' }}>
                {isGraphChart ? (
                  <Tree
                    data={transformDataToTree(treeResponse, false)}
                    orientation="vertical"
                    nodeSize={{
                      x: 350,
                      y: 700,
                    }}
                  />
                ) : (
                  <ResponsiveTreeMapHtml
                    data={transformDataToTree(treeResponse, false)}
                    identity="name"
                    value="value"
                    valueFormat=".02s"
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    label={(node) => node.data.name}
                    labelSkipSize={12}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    parentLabelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 3]],
                    }}
                    colors={{ scheme: 'yellow_orange_red' }}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.1]],
                    }}
                  />
                )}
              </div>
            )}
          </LoaderWrapper>
        </TransferContainer>
      </LayoutContainer>
    </div>
  );
};

export default LocationTreeContainer;
