import { TransportQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { Transport } from './interface';

export const getTransportList = async (queryParams?: TransportQueryParams) => {
  const response = await getAPIMethod({
    name: 'getTransportList',
    params: {
      name: queryParams?.name,
    },
  });
  return response.data;
};

export const updateTransport = async (
  id: string,
  transport: Partial<Transport>,
) => {
  const response = await getAPIMethod({
    name: 'updateTransport',
    data: transport,
    pathParams: { id },
  });
  return response.data;
};

export const createTransport = async (transport: Partial<Transport>) => {
  const response = await getAPIMethod({
    name: 'createTransport',
    data: transport,
  });
  return response.data;
};
