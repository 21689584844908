import { AxiosError } from 'axios';
import { PandasError } from 'config/Errors/error';
import { WalletQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { WalletTransaction } from './interface';

export const getWalletTranstions = async (
  queryParams?: WalletQueryParams,
): Promise<PaginatedResponse<WalletTransaction>> => {
  const response = await getAPIMethod({
    name: 'getWalletTransactions',
    params: {
      search: queryParams?.search,
      user: queryParams?.user,
      page: queryParams?.page,
      status: queryParams?.status,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const updateWalletTransactions = async (
  params?: WalletTransaction,
): Promise<WalletTransaction> => {
  const response = await getAPIMethod({
    name: 'updateWalletTransactions',
    data: params,
  });
  return response.data;
};

export const createWalletTransactions = async (params?: WalletTransaction) => {
  const response = await getAPIMethod({
    name: 'createWalletTransactions',
    data: params,
  });
  return response.data;
};

export const updateInvoiceReports = async (params: FormData) => {
  try {
    const response = await getAPIMethod({
      name: 'updateInvoiceReports',
      data: params,
      responseType: 'blob',
    });
    return response.data;
  } catch (error: any) {
    const contentType = error?.response?.headers?.['content-type'];
    if (
      error instanceof AxiosError &&
      contentType?.includes('application/json')
    ) {
      const errorText = await new Response(error.response?.data).text();
      const errorData = JSON.parse(errorText);
      throw new PandasError(errorData);
    }

    throw new PandasError({
      code: 'INV-FSP100',
      statusCode: 500,
      message: 'Unhandled Error',
    });
  }
};

export default {
  create: createWalletTransactions,
  read: getWalletTranstions,
  update: updateWalletTransactions,
  delete: getWalletTranstions,
  readByParam: getWalletTranstions,
};
