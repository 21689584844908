import { Col, Divider, Row, TablePaginationConfig } from 'antd';
import CreditModal from 'components/Credit/CreditModal/CreditModal';
import { TableWrapper } from 'components/TableWrapper';
import { debounce } from 'lodash';
import { SearchInput } from 'modules/Order/components/OrderContent/OrderContent.sty';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, ToggleLegacyData } from './CreditContent.sty';
import CreditTable from './CreditTable';
import useCreditContent from './hooks/useCreditContent';

const CreditContent = () => {
  const { t } = useTranslation();

  const {
    creditList,
    getCreditList,
    tablePagination,
    setTablePagination,
    closeModal,
    isModalOpen,
    initialValues,
    handleUpdateCredit,
    searchDataStatus: [searchData, setSearchData],
    loadingList,
    setShowLegacy,
  } = useCreditContent();

  const handleSearchWithDebounce = useMemo(
    () =>
      debounce((_val: string) => {
        setSearchData(_val);
      }, 700),
    [setSearchData],
  );

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    await getCreditList({
      search: searchData,
      page: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  };

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchWithDebounce(e.target.value);
    },
    [handleSearchWithDebounce],
  );

  return (
    <>
      <CreditModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        initialValues={initialValues}
      />
      <Title level={1}>{t('creditsList')}</Title>
      <Divider />
      <Row gutter={12}>
        <Col xl={17} lg={16} md={14} xs={24}>
          <SearchInput
            placeholder={t('textSearchPlaceholder')}
            onChange={handleSearch}
          />
        </Col>
        <Col xl={6} lg={7} md={9} xs={24}>
          <ToggleLegacyData onChange={(e) => setShowLegacy(e.target.checked)}>
            {t('creditsForm.showLegacy')}
          </ToggleLegacyData>
        </Col>
      </Row>
      <Divider />
      <TableWrapper>
        <CreditTable
          loading={loadingList}
          data={creditList}
          pagination={tablePagination}
          onUpdateCredit={handleUpdateCredit}
          onChange={handleTableChange}
          setTablePagination={setTablePagination}
        />
      </TableWrapper>
    </>
  );
};

export default CreditContent;
