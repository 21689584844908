/* eslint-disable spaced-comment */
import { StoresQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { difference } from 'lodash';
import { User } from 'services/user/interface';
import { Store } from './interface';

export interface UpdateStore extends Omit<Store, 'users'> {
  addUsers: User[];
  removeUsers: User[];
}

export const updateStore = async (store: Partial<UpdateStore>) => {
  const response = await getAPIMethod({
    name: 'updateStore',
    pathParams: { storeId: store?.id || '' },
    data: store,
  });
  const add = store.addUsers?.map((user: User) => ({ id: user.id }));
  const remove = store.removeUsers?.map((user: User) => ({ id: user.id }));
  let updatedUsers = response;
  if (add?.length || remove?.length) {
    updatedUsers = await getAPIMethod({
      name: 'updateStoreUsers',
      pathParams: { storeId: store?.id || '' },
      data: {
        add: store.addUsers?.map((user: User) => ({ id: user.id })),
        remove: store.removeUsers?.map((user: User) => ({ id: user.id })),
      },
    });
  }
  return updatedUsers.data;
};

export const createStore = async (store: Partial<Store>) => {
  const response = await getAPIMethod({
    name: 'createStore',
    data: store,
  });
  return response.data;
};

export const getStoresList = async (queryParams?: StoresQueryParams) => {
  const response = await getAPIMethod({
    name: 'getStoresList',
    params: {
      search: queryParams?.search,
      type: queryParams?.type,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const getStoreById = async (params?: any) => {
  const response = await getAPIMethod({
    name: 'getStoreById',
    pathParams: {
      storeId: params.id,
    },
  });
  return response.data;
};

export const buildStoreFromParams = (
  rawData: Partial<Store>,
  prevData?: Partial<Store>,
): Partial<Store> | Partial<UpdateStore> => {
  let rawReqBody: any = {
    ...rawData,
    phone: `+57${rawData?.phone?.replace('+57', '')}`,
  };
  if (prevData?.users) {
    const { users } = rawReqBody;
    delete rawReqBody.users;
    const addUsers = difference(users, prevData.users);
    const removeUsers = difference(prevData.users, users || []);
    rawReqBody = {
      ...rawReqBody,
      addUsers,
      removeUsers,
    };
  }
  return rawReqBody;
};

export default {
  create: createStore,
  read: getStoresList,
  update: updateStore,
  delete: getStoresList,
  readByParam: getStoreById,
};
