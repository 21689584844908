import { useTranslation } from 'react-i18next';
import { TransferResultProps } from '../../interface';
import TransferResultCard from '../TransferCard/TransferResultCard';
import {
  AcceptButton,
  LoadingScreen,
  TransferResult,
} from './TransferItems.sty';

const TransferResume = ({
  transfersResult,
  clearTransferResult,
}: TransferResultProps) => {
  const { t } = useTranslation();

  if (!transfersResult || transfersResult.length <= 0) return null;

  return (
    <LoadingScreen>
      <TransferResult
        status="success"
        title={t('transfers.results.successTitle')}
      >
        {transfersResult.map((item) => (
          <TransferResultCard key={item.path} item={item} />
        ))}
      </TransferResult>
      <AcceptButton block type="primary" onClick={clearTransferResult}>
        {t('transfers.buttons.accept')}
      </AcceptButton>
    </LoadingScreen>
  );
};

export default TransferResume;
