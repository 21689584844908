import { Input, Row } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { Title } from 'pages/App/Layout.sty';
import styled from 'styled-components';

const { Search } = Input;

export const BrandsContainer = styled.div`
  height: calc(100vh - 112px);
`;
export const DefaultContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const BrandsTitle = styled(Title)`
  margin: 0 !important;
`;
export const TitleRow = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl};
`;
export const SearchInput = styled(Search)`
  width: 360px;
  border-radius: 8px;
  height: 33px;
  background: #fff;
  .ant-input-wrapper {
    .ant-input-group-addon {
      display: none;
    }
  }
`;
