import { Form } from 'antd';
import styled from 'styled-components';

export const OptionStatusWrapper = styled.div<{ color: string }>`
  &::before {
    display: inline-flex;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    margin: 0 10px 0 0;
  }
`;

export const TabVisibilityWrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ visible }) => (visible ? 'auto' : 'none')};
  animation: 0.2s;
`;

export const FormItemLSize = styled(Form.Item)`
  width: 70%;
`;

export const FormItemSSize = styled(Form.Item)`
  width: 30%;
`;
