import { FormLayout } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import {
  IWmsSettingForm,
  WmsSettingEnum,
} from 'services/wms-settings/interface';

export const BehaviorsKey: keyof IWmsSettingForm = 'behaviors';
export const DefaultKey: keyof IWmsSettingForm = 'default';
export const RequiredInputRules: Rule[] = [
  {
    required: true,
  },
];

export const BehaviorFormNamePaths = {
  rulesPath: 'rules',
  evaluator: 'evaluator',
  evaluation: 'evaluation',
  name: 'name',
  field: 'field',
  evaluatorType: 'evaluatorType',
  value: 'value',
};

export const BehaviorFormProps: { layout: FormLayout } = {
  layout: 'vertical',
};

export const BehaviorFormKeys = {
  edit: 'edit-behavior-form',
  create: 'create-behavior-form',
};

export const LOCATION_VALUE_CONFIGS = [
  WmsSettingEnum.SALE_ORDER_RETURN_LOCATIONS,
  WmsSettingEnum.MANUAL_STOCK_UPDATE_LOCATION,
];
