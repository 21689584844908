import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

const useGetToken = () => {
  const enableUpload = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const auth = getAuth();
      const firebaseToken = await auth.currentUser?.getIdToken();
      setToken(firebaseToken);
    })();
  }, [setToken]);

  return {
    token,
    enableUpload,
  };
};

export default useGetToken;
