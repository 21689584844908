import { ErrorMessageObject } from './development.errors';

export const categoryErrors: { [key: string]: ErrorMessageObject } = {
  PROU400005: {
    translation: 'PROU400005',
    isBlocking: false,
  },
  PROS400004: {
    translation: 'PROS400004',
    isBlocking: false,
  },
  PROC400002: {
    translation: 'PROC400002',
    isBlocking: false,
  },
};
