import { useEffect, useState } from 'react';

const useCategory = (getCategoryList: (a: undefined) => void) => {
  const deleteIdState = useState<string>('');
  const visibleConfirmationState = useState<boolean>(false);

  useEffect(() => {
    getCategoryList(undefined);
  }, [getCategoryList]);
  return {
    deleteIdState,
    visibleConfirmationState,
  };
};

export default useCategory;
