/* eslint-disable no-underscore-dangle */
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { format } from 'date-fns';
import { ToColDate, ToColDateString } from 'modules/Order/helpers/date';
import { packageToForm } from 'modules/Order/helpers/packageForm';
import { IPackageForm, PackageEditOptions } from 'modules/Order/interface';
import { range2DateOption } from 'modules/Order/reducer/utils/range2options';
import { packageStatusToOptions } from 'modules/Order/reducer/utils/status2options';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { prop, uniqBy } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Package, QuantityVariant } from 'services/package/interface';
import { PackageHeader, PackageHeaderTitle } from './PackageCard.sty';
import PackageProductTable from './PackageProductTable';

const PackageCard = ({
  pkg,
  options,
  fetchOptions,
  onUpdatePackage,
  updateLoading,
}: {
  pkg: Package;
  options: PackageEditOptions;
  fetchOptions: (pkgId: string) => void;
  onUpdatePackage: (pkg: IPackageForm) => void;
  updateLoading: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = useForm<IPackageForm>();
  const { t } = useTranslation();

  const turnIsEditing = () => {
    setIsEditing(!isEditing);
    form.setFieldsValue(packageToForm(pkg));
  };

  const initialValues = useMemo((): IPackageForm => {
    return packageToForm(pkg);
  }, [pkg]);

  useEffect(() => {
    if (!options.dates && isEditing) {
      fetchOptions(pkg._id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, options.dates, pkg._id]);

  const dateOptions = useMemo(() => {
    const baseOptions = options?.dates?.map(range2DateOption) ?? [];
    const actualDate = ToColDate(String(pkg.delivery.date));

    const actualOption: LabelValue<string> = {
      label: ToColDateString(pkg.delivery.date),
      value: format(actualDate, 'yyyy-MM-dd'),
    };

    return uniqBy(prop('value'), [actualOption, ...baseOptions]);
  }, [options?.dates, pkg?.delivery?.date]);

  const onChangeQuantity = useCallback(
    (qtyVar: QuantityVariant, newQuantity: number) => {
      const actual = form.getFieldValue('products') as QuantityVariant[];
      const newArr = actual.map((v) => {
        if (v.id === qtyVar.id) return { ...v, quantity: newQuantity };
        return v;
      });
      form.setFieldValue('products', newArr);
    },
    [form],
  );

  const packageSellers = useMemo(
    () => pkg.sellers.map((s) => s.name).join(', '),
    [pkg.sellers],
  );

  const onSubmit = useCallback(
    (values: IPackageForm) => {
      onUpdatePackage({
        ...values,
        id: pkg._id,
        sellerId: pkg.sellers?.[0]?.id ?? pkg.sellers?.[0]?._id ?? '',
      });
    },
    [onUpdatePackage, pkg._id, pkg.sellers],
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      disabled={updateLoading || !isEditing}
      onFinish={onSubmit}
    >
      <PackageHeader>
        <PackageHeaderTitle>
          {t('package.titles.header', { packageNumber: pkg.packageNumber })}
        </PackageHeaderTitle>
        {isEditing && (
          <Button
            onClick={form.submit}
            type="primary"
            disabled={false}
            loading={updateLoading}
          >
            {t('package.actions.save')}
          </Button>
        )}
        <Button
          onClick={turnIsEditing}
          type={isEditing ? 'ghost' : 'primary'}
          disabled={false} // force disabled
          loading={updateLoading}
        >
          {t(`package.actions.edit.${isEditing}`)}
        </Button>
      </PackageHeader>
      <div>{t('package.labels.sellers', { sellers: packageSellers })}</div>
      <Divider>{t('package.subtitles.dataSection')}</Divider>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('package.form.labels.status')} name="status">
            <Select options={packageStatusToOptions(pkg.status, t)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('package.form.labels.delivery')}
            name="deliveryDate"
          >
            <Select options={dateOptions} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('package.form.labels.carrier')}
            name={['delivery', 'carrier']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('package.form.labels.shippingNumber')}
            name={['delivery', 'shippingNumber']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Divider>{t('package.subtitles.productSection')}</Divider>
      <PackageProductTable
        onChangeQuantity={onChangeQuantity}
        enabled={isEditing}
        products={pkg.products}
        quantities={pkg.quantities}
      />
      <Form.Item name="products" noStyle />
    </Form>
  );
};

export default PackageCard;
