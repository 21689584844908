import { BaseColors } from 'helpers/constants/theme';
import { t } from 'i18next';
import { useMemo } from 'react';
import { UnitMovementDefault, UnitRegistryMovement } from './interface';
import {
  MovementCardLabel,
  MovementCardTitle,
  QuantityTag,
  TitleContainer,
} from './UnitMovementItem.sty';

const getCategory = (movement: UnitRegistryMovement) => {
  const defaultCategoryKey = `variantUnitMovements.types.${
    movement?.category ?? UnitMovementDefault.DEFAULT_CATEGORY
  }`;
  if (movement?.category !== 'sale') {
    return t(defaultCategoryKey);
  }

  if (movement.oldStatus === 'blocked' && movement.newStatus === 'available') {
    return t('variantUnitMovements.types.saleRelease');
  }

  // First check if has relatedId, so it is a commit movement
  if (movement.relatedId) {
    return t('variantUnitMovements.types.saleCommit');
  }

  // Otherwise, if statuses are the same (blocked), it is an unassign movement
  if (movement.oldStatus === 'blocked' && movement.newStatus === 'blocked') {
    return t('variantUnitMovements.types.saleUnassign');
  }

  return t('variantUnitMovements.types.saleBlock');
};

const UnitMovementTitle = ({
  movement,
}: {
  movement: UnitRegistryMovement;
}) => {
  const movementTitleInfo = useMemo(() => {
    const category = getCategory(movement);

    return {
      category,
      quantity: movement.quantity,
      relatedId: movement.relatedId,
    };
  }, [movement]);

  return (
    <TitleContainer>
      <div>
        <MovementCardTitle>{movementTitleInfo.category}</MovementCardTitle>
        <MovementCardLabel>{movementTitleInfo.relatedId}</MovementCardLabel>
      </div>
      <QuantityTag color={BaseColors.dark_gray}>
        {t('variantUnitMovements.quantity', {
          quantity: movementTitleInfo.quantity,
        })}
      </QuantityTag>
    </TitleContainer>
  );
};

export default UnitMovementTitle;
