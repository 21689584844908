import useInfiniteRead from 'helpers/hooks/useModuleCRUD/useInfiniteRead';
import { useMemo, useState } from 'react';
import { Product, RevisionStatus } from 'services/product/interface';

const DEFAULT_PAGE_SIZE = 20;

const useProductContent = () => {
  const [productSearchKey, setProductSearchKey] = useState<string>('');
  const [revisionStatusFilter, setRevisionStatusFilter] = useState<
    RevisionStatus | undefined
  >(undefined);

  const listParams = useMemo(
    () => ({
      search: productSearchKey,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      revisionStatus: revisionStatusFilter,
    }),
    [productSearchKey, revisionStatusFilter],
  );

  const {
    readInfiniteQuery: { fetchNextPage, data, hasNextPage },
  } = useInfiniteRead('products', listParams, () => ({}), true);

  const productList: Product[] = useMemo(
    () =>
      data?.pages
        ?.flat()
        .map((item) => item?.data)
        ?.flat() ?? [],
    [data],
  );

  return {
    fetchNextPage,
    productList,
    hasNextPage,
    setProductSearchKey,
    setRevisionStatusFilter,
  };
};

export default useProductContent;
