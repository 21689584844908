import { Col, Input, Row, Select } from 'antd';
import { numberToCurrency } from 'helpers/utils/currency';
import { t } from 'i18next';
import { CouponType, SaleType } from 'services/coupons/interface';
import { CustomInputNumber, FormItem } from './CouponModal.sty';
import {
  requiredRule,
  validateNominalValue,
  validateUsesPerUser,
} from './CouponValidations.schema';
import { CouponDetailsSubFormProps } from './interface';

const CouponDetailsSubForm = ({
  readOnly,
  isUpdate,
  getFieldValue,
  requireNominalValue,
  segmentation,
}: CouponDetailsSubFormProps) => (
  <Row gutter={[16, 8]}>
    <Col xs={24} md={12}>
      <FormItem
        label={t('couponModal.form.labels.saleType')}
        name="saleType"
        rules={[requiredRule]}
      >
        {readOnly || isUpdate ? (
          t(`couponSaleType.${getFieldValue?.('saleType')}`)
        ) : (
          <Select disabled={readOnly}>
            {(Object.keys(SaleType) as SaleType[]).map((saleOption) => (
              <Select.Option value={saleOption} key={saleOption}>
                {t(`couponSaleType.${saleOption}`)}
              </Select.Option>
            ))}
          </Select>
        )}
      </FormItem>
      <FormItem
        label={t('couponModal.form.labels.uses')}
        rules={[requiredRule]}
        name="uses"
      >
        {readOnly ? (
          getFieldValue?.('uses') || 0
        ) : (
          <Input type="number" min={0} />
        )}
      </FormItem>
      <FormItem
        label={t('couponModal.form.labels.usesPerUser')}
        name="usesPerUser"
        rules={[requiredRule, validateUsesPerUser]}
      >
        {readOnly || isUpdate ? (
          getFieldValue?.('usesPerUser') || 0
        ) : (
          <Input type="number" min={0} />
        )}
      </FormItem>
      <FormItem
        label={t('couponModal.form.labels.minimumPurchase')}
        name="minimumPurchase"
        rules={[requiredRule]}
      >
        {readOnly || isUpdate ? (
          numberToCurrency(getFieldValue?.('minimumPurchase'))
        ) : (
          <CustomInputNumber style={{ width: '100%' }} min={0} />
        )}
      </FormItem>
    </Col>
    <Col xs={24} md={12}>
      <FormItem
        label={t('couponModal.form.labels.maxDiscount')}
        name="maxDiscount"
        rules={[requiredRule]}
      >
        {readOnly || isUpdate ? (
          numberToCurrency(getFieldValue?.('maxDiscount'))
        ) : (
          <CustomInputNumber style={{ width: '100%' }} min={0} />
        )}
      </FormItem>
      <FormItem
        label={t('couponModal.form.labels.couponType')}
        name="type"
        rules={[requiredRule]}
      >
        {readOnly || isUpdate ? (
          t(`couponType.${getFieldValue?.('type')}`)
        ) : (
          <Select disabled={readOnly}>
            {(Object.keys(CouponType) as CouponType[]).map((couponOption) => (
              <Select.Option value={couponOption} key={couponOption}>
                {t(`couponType.${couponOption}`)}
              </Select.Option>
            ))}
          </Select>
        )}
      </FormItem>
      <FormItem
        rules={[requiredRule]}
        label={t('createOrder.segmentation')}
        name="segmentation"
      >
        {readOnly ? (
          t(`couponSeg.${getFieldValue?.('segmentation')}`)
        ) : (
          <Select>
            {segmentation?.map((value: string) => (
              <Select.Option value={value} key={value}>
                {t(`couponSeg.${value}`)}
              </Select.Option>
            ))}
          </Select>
        )}
      </FormItem>
      {requireNominalValue ? (
        <>
          <FormItem
            label={t('couponModal.form.labels.nominalValue')}
            name="nominalValue"
            rules={[requiredRule, validateNominalValue]}
          >
            {readOnly || isUpdate ? (
              numberToCurrency(getFieldValue?.('nominalValue') || 0)
            ) : (
              <CustomInputNumber style={{ width: '100%' }} min={0} />
            )}
          </FormItem>
          <FormItem name="maxCouponSpent" hidden initialValue={-1}>
            <CustomInputNumber value={-1} />
          </FormItem>
        </>
      ) : (
        <>
          <FormItem
            label={t('couponModal.form.labels.percentageValue')}
            name="percentageValue"
            rules={[requiredRule]}
          >
            {readOnly || isUpdate ? (
              `${getFieldValue?.('percentageValue')}%`
            ) : (
              <CustomInputNumber style={{ width: '100%' }} min={0} max={100} />
            )}
          </FormItem>
          <FormItem
            label={t('couponModal.form.labels.maxCouponSpent')}
            name="maxCouponSpent"
            rules={[requiredRule]}
            extra="Para descuento total ilimitado digite -1, de lo contrario digite un valor real mayor a 0"
          >
            {readOnly ? (
              <span>
                {(getFieldValue?.('maxCouponSpent') ?? -1) <= 0
                  ? 'Ilimitado'
                  : numberToCurrency(getFieldValue?.('maxCouponSpent'))}
              </span>
            ) : (
              <CustomInputNumber
                style={{ width: '100%' }}
                defaultValue={-1}
                value={getFieldValue?.('maxCouponSpent') ?? -1}
                min={-1}
              />
            )}
          </FormItem>
        </>
      )}
    </Col>
  </Row>
);

export default CouponDetailsSubForm;
