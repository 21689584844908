import { List, Spin } from 'antd';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { t } from 'i18next';
import { PandabonosUploadHistory } from 'pages/Pandabonos/interface';
import { NoHistory } from 'pages/Products/UpdateProduct/UpdateProduct.sty';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getPandabonosHistory } from 'services/pandabonos/pandabonos.service';
import LogoImage from '../../assets/images/logo.svg';
import HistoryPandabonos from './PandabonosHistoryItem';

const PandabonosHistory = () => {
  const { list, meta, isFetching, nextPage } = useInfiniteScroll<
    null,
    PandabonosUploadHistory
  >({
    fetchData: getPandabonosHistory,
    filters: null,
    name: ['pandabonos', 'history'],
    pageSize: 10,
  });

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={nextPage}
        dataLength={(meta?.page ?? 1) * (meta?.pageSize ?? 10)}
        hasMore={meta?.hasNext ?? true}
        loader={<Spin tip={t('loading')} />}
        scrollableTarget="scrollableDiv"
        endMessage={
          !isFetching && list.length ? (
            <NoHistory>
              <img alt="pandas logo" src={LogoImage} />
              <p>{t('variantHistory.noMoreToLoad')}</p>
            </NoHistory>
          ) : null
        }
      >
        <List
          dataSource={list}
          renderItem={(historyItem) => (
            <HistoryPandabonos key={historyItem.uploadId} {...historyItem} />
          )}
          loading={isFetching}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};

export default PandabonosHistory;
