import { Table } from 'antd';
import { getPackagesProductsTableColumns } from 'modules/Order/constants/packagesTable';
import { useTranslation } from 'react-i18next';
import { PackageQuantities, QuantityVariant } from 'services/package/interface';

const PackageProductTable = ({
  products,
  enabled,
  onChangeQuantity,
  quantities,
}: {
  quantities: PackageQuantities;
  products: QuantityVariant[];
  enabled: boolean;
  onChangeQuantity: (variant: QuantityVariant, quantity: number) => void;
}) => {
  const { t } = useTranslation();
  const columns = getPackagesProductsTableColumns(
    t,
    enabled,
    onChangeQuantity,
    quantities,
  );

  return <Table<QuantityVariant> columns={columns} dataSource={products} />;
};

export default PackageProductTable;
