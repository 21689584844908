import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Alert } from 'antd';
import { getErrorMessage } from 'helpers/utils/csvFileError';
import { useTranslation } from 'react-i18next';
import { ErrorViewContainer } from './CsvTransferErrorView.sty';
import { CsvTransferErrorViewProps } from './interface';

const CsvTransferErrorView = ({ errors }: CsvTransferErrorViewProps) => {
  const [parent] = useAutoAnimate();
  const { t } = useTranslation();

  return (
    <ErrorViewContainer ref={parent}>
      {errors.map((err) => {
        const [msg, errCode] = getErrorMessage(err, t);
        const desc = t(errCode);

        return (
          <Alert
            type="error"
            showIcon
            key={err}
            message={msg}
            description={desc}
          />
        );
      })}
    </ErrorViewContainer>
  );
};

export default CsvTransferErrorView;
