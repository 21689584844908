/* eslint-disable no-param-reassign */
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Form, Tag } from 'antd';
import { ProductFigure } from 'components/AutoCompleteVariantItem/AutoCompleteVariantItem.sty';
import { NumberInput } from 'components/NumberInput';
import { ROUTES } from 'helpers/constants/routes';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useNotifications from 'helpers/hooks/useNotifications';
import { computeVolumeDiscount } from 'helpers/utils/computeVolumeDiscount';
import { numberToCurrency } from 'helpers/utils/currency';
import { t } from 'i18next';
import { groupBy } from 'lodash';
import { indexBy, prop } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CartItemStatus, CART_ITEM_COLORS } from 'services/cart/interface';
import { LogisticType, ProductLogistic } from 'services/MPS/interface';
import { StockInfo } from 'services/order/interface';
import { CartVariant, Variant, VariantImage } from 'services/product/interface';
import { OrderProductTableData } from '../components/OrderForm/OrderProductTable';
import { getUnitsByStatus } from '../reducer/utils/stockPendingUnits';

const tableColumns = (
  remove: (idx: number) => void,
  disabled?: boolean,
  isUpdate?: boolean,
) => [
  {
    title: t('orderForm.variantTable.columns.image'),
    dataIndex: 'images',
    hideId: 'images',
    width: '120px',
    render: (images: VariantImage[], variant: OrderProductTableData) => (
      <ProductFigure>
        <img
          src={
            images?.length
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${images[0].url}`
              : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
          }
          alt={variant.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
          }}
        />
      </ProductFigure>
    ),
  },
  {
    title: t('orderForm.variantTable.columns.name'),
    dataIndex: 'name',
    width: '250px',
  },
  {
    title: t('orderForm.variantTable.columns.seller'),
    dataIndex: ['mps', 'name'],
    width: '150px',
  },
  {
    title: t('orderForm.variantTable.columns.stock'),
    dataIndex: 'status',
    width: '110px',
    render: (status: CartItemStatus) =>
      status && status !== CartItemStatus.IN_STOCK ? (
        <Tag color={CART_ITEM_COLORS[status]}>{t(`stockStatus.${status}`)}</Tag>
      ) : (
        <Tag color="lime">{t('stockStatus.BLOCKED')}</Tag>
      ),
  },
  {
    title: t('orderForm.variantTable.columns.purchase'),
    hideId: 'purchase',
    width: '110px',
    render: (_: unknown, variant: OrderProductTableData) => {
      const { status, relatedUnits } = variant;

      const unitsToCheck = getUnitsByStatus(status, relatedUnits ?? []);
      const purchases = groupBy(unitsToCheck, (unit) => unit.PurchaseId ?? '');
      return (
        <>
          {Object.keys(purchases)
            .filter((p) => p)
            .map((purchase) => (
              <Button
                key={purchase}
                shape="circle"
                size="small"
                icon={<LinkOutlined />}
                onClick={() =>
                  window.open(ROUTES.purchaseUpdate.replace(':id', purchase))
                }
                style={{ color: '#1ACEC3', borderColor: '#1ACEC3' }}
              />
            ))}
        </>
      );
    },
  },
  {
    title: t('orderForm.variantTable.columns.price'),
    dataIndex: 'price',
    width: '130px',
    render: (price: number, variant: OrderProductTableData) =>
      !disabled ? (
        <Form.Item
          {...variant.field}
          name={[variant.field.name, 'price']}
          noStyle
        >
          {numberToCurrency(price)}
        </Form.Item>
      ) : (
        numberToCurrency(price)
      ),
  },
  {
    title: t('orderForm.variantTable.columns.discount'),
    dataIndex: 'discount',
    width: '130px',
    render: (_: number, variant: OrderProductTableData) =>
      !disabled ? (
        <Form.Item
          {...variant.field}
          name={[variant.field.name, 'discount']}
          noStyle
        >
          {numberToCurrency(computeVolumeDiscount(variant))}
        </Form.Item>
      ) : (
        numberToCurrency(computeVolumeDiscount(variant))
      ),
  },
  {
    title: t('orderForm.variantTable.columns.available'),
    dataIndex: ['stock', 'available'],
    hideId: 'available',
    width: '110px',
    render: (stock: number) => stock,
  },
  {
    title: t('orderForm.variantTable.columns.cartQuantity'),
    dataIndex: 'cartQuantity',
    width: '130px',
    render: (cartQuantity: number, variant: OrderProductTableData) => {
      return !disabled ? (
        <Form.Item
          {...variant.field}
          name={[variant.field.name, 'cartQuantity']}
          noStyle
        >
          <NumberInput
            min={0}
            max={!isUpdate ? variant.stock?.available ?? 0 : undefined}
            disabled={
              variant.editionLocked ||
              disabled ||
              (variant.stock?.available ?? 0) === 0
            }
            type="number"
          />
        </Form.Item>
      ) : (
        cartQuantity
      );
    },
  },
  {
    title: t('orderForm.variantTable.columns.subtotal'),
    dataIndex: 'subtotal',
    width: '130px',
    render: (subtotal: number) => numberToCurrency(subtotal || 0),
  },
  {
    title: t('orderForm.variantTable.columns.actions'),
    hideId: 'actions',
    width: '80px',
    render: (variant: OrderProductTableData) =>
      !disabled && !variant.editionLocked ? (
        <Button
          key={`${variant.id}-update-button`}
          onClick={() => remove(variant.field.name)}
          shape="circle"
          icon={<DeleteOutlined />}
          disabled={disabled}
          danger
        />
      ) : null,
  },
];

const useOrderProductTable = ({
  add,
  remove,
  disabled,
  detail,
  pendingStock,
  isUpdate,
}: {
  add: (defaultValue?: CartVariant, insertIndex?: number) => void;
  remove: (index: number) => void;
  disabled?: boolean;
  detail: OrderProductTableData[];
  pendingStock?: StockInfo[];
  isUpdate?: boolean;
}) => {
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const { notifyWarning } = useNotifications({ translationFunction: t });
  const [searchKey, setSearchKey] = useState<string>('');
  const [productsLogistic, setProductsLogistic] = useState<
    ProductLogistic | undefined
  >();
  const [tableData, setTableData] = useState<OrderProductTableData[]>([
    ...detail,
  ]);
  const indexDetail = useMemo(() => indexBy(prop('id'), detail), [detail]);

  useEffect(() => {
    let newDetail = detail;
    if (pendingStock?.length && disabled) {
      const newVariants = pendingStock.map((stockVariant) => {
        const asociatedVariant = indexDetail[stockVariant.id];

        return {
          ...asociatedVariant,
          status: stockVariant.status,
          cartQuantity: stockVariant.quantity,
        };
      });
      newDetail = newVariants as unknown as OrderProductTableData[];
    }
    setTableData(newDetail.filter((d) => d.id));
  }, [detail, pendingStock, indexDetail, disabled]);

  const handleFilterVariantList = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      setTableData(
        val.length > 0
          ? detail.filter((item: OrderProductTableData) => {
              const compareString =
                `${item.name}${item.variantSku}${item.urlName}`.toLowerCase();
              return compareString.includes(val.toLowerCase());
            })
          : detail,
      );
      setSearchKey(val);
    },
    [detail],
  );

  const defaultColumns = tableColumns(remove, disabled, isUpdate);
  const columns = useMemo(() => {
    const toHide: string[] = [];

    if (isMobile) toHide.push('images');
    if (isUpdate) toHide.push('available');
    if (disabled) toHide.push('actions');
    if (!pendingStock?.length) toHide.push('purchase');

    return defaultColumns.filter((column) => {
      const a = !toHide.includes(column.hideId as string);
      return a;
    });
  }, [defaultColumns, isMobile, isUpdate, disabled, pendingStock]);

  const handleAddNewVariantToCart = useCallback(
    (addedVariant: Variant) => {
      const matchIndex = detail.findIndex(
        (item: CartVariant) => item.id === addedVariant.id,
      );
      if (matchIndex > -1) {
        notifyWarning('orderForm.modal.message.addProductError');
        return;
      }
      const addedCartVariant: CartVariant = {
        ...addedVariant,
        cartQuantity: 1,
        initialCartQuantity: 1,
        dimensions: {
          width: addedVariant.dimensions.width || 0,
          length: addedVariant.dimensions.length || 0,
          height: addedVariant.dimensions.height || 0,
          weight: addedVariant.dimensions.weight || 0,
        },
      };
      add(addedCartVariant, 0);
    },
    [detail, add, notifyWarning],
  );

  useEffect(() => {
    if (detail.length > 0) {
      setProductsLogistic(
        detail?.[0]?.mps?.logisticType === LogisticType['3P']
          ? ProductLogistic['3P']
          : ProductLogistic.CROSS_DOCKING,
      );
    } else {
      setProductsLogistic(undefined);
    }
  }, [detail]);

  return {
    columns,
    handleAddNewVariantToCart,
    searchKey,
    tableData,
    handleFilterVariantList,
    productsLogistic,
    t,
  };
};

export default useOrderProductTable;
