import { Button } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const InfoContainer = styled.div<{ toggled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
  width: fit-content;
  padding: 0;
  ${({ toggled }) =>
    (toggled ? `margin-bottom: ${LightTheme.spaceSizes.s};` : '')}
`;

export const ViewMoreButton = styled(Button)`
  width: fit-content;
  padding: 0;
  height: fit-content;
  margin-top: -${LightTheme.spaceSizes.s};
  color: ${LightTheme.color.primary} !important;
  font-weight: bold !important;
`;
