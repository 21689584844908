import { getAPIMethod } from 'helpers/libs/Http';
import { UpdateVariantStock } from './interface';

export const getStockCsvTemplate = async () => {
  const response = await getAPIMethod({ name: 'getStockCsvTemplate' });
  return response;
};

export const getAvailableCsvTemplate = async () => {
  const response = await getAPIMethod({ name: 'getAvailableCsvTemplate' });
  return response;
};

const DEFAULT_WAREHOUSE = String(
  process.env.REACT_APP_PANDAS_DEFAULT_WAREHOUSE,
);

export const updateVariantStock = async ({
  variantId,
  cap,
  inWarehouse,
  cost,
  inventoryChangeReason,
  warehouseId,
  logisticType,
}: UpdateVariantStock) => {
  const warehouseArr = [warehouseId || DEFAULT_WAREHOUSE, DEFAULT_WAREHOUSE];

  const response = await getAPIMethod({
    name: 'updateWarehouseStock',
    pathParams: {
      warehouseId: warehouseArr.join(','),
      variantId: String(variantId),
    },
    data: {
      cap: Number(cap),
      inWarehouse: Number(inWarehouse),
      cost: Number(cost),
      inventoryChangeReason,
      logisticType,
    },
  });
  return response.data;
};
