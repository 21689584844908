import { InputNumberProps, RowProps } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

export const InputNumberDefProps: InputNumberProps = {
  style: {
    width: '40%',
  },
};

export const DefaultStockUpdateReasonOptions = [
  'count',
  'shrinkage',
  'damage',
  'steal',
  'other',
];

type verticalOrientations = 'center' | 'left' | 'right';

export const VariantStockLayout = {
  row: {
    gutter: 16,
    justify: 'center' as RowProps['justify'],
  },
  col: {
    span: 4,
  },
  divider: {
    relativeOrientation: 'center' as verticalOrientations,
  },
  card: {
    tooltip: {
      placement: 'bottom' as TooltipPlacement,
    },
  },
};
