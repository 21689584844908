/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Select } from 'antd';
import { AddButton } from 'components/AddButton';
import { CustomTag } from 'components/CustomTag';
import { Department } from 'containers/General/LocationInput/interface';
import { ROUTES } from 'helpers/constants/routes';
import { t } from 'i18next';
import { customFormat } from 'modules/Order/constants/format';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MPS } from 'services/MPS/interface';
import {
  OrderType,
  OrderTypeColors,
  StatusOrderEnum,
  StatusTagColor,
} from 'services/order/interface';
import {
  ButtonContainer,
  CustomRangePicker,
  FilterCol,
  SelectOverflowH,
} from '../OrderContent/OrderContent.sty';

export interface OrderFiltersProps {
  handleChangeDeliveryDateFilter: (_date: any, dateString: string[]) => void;
  handleChangeCreationDateFilter: (_date: any, dateString: string[]) => void;
  handleStatusFilterChange: (value: StatusOrderEnum) => void;
  handleDepartmentFilterChange: (department: string[]) => void;
  handleMpsFilterChange: (value: MPS[]) => void;
  onUnselectAllClick: () => void;
  handle3PFilter: (value: string) => void;
  orderTypeFilter?: OrderType;
  deliveryDateFilter: string[] | undefined;
  creationDateFilter: string[] | undefined;
  renderFilterState?: boolean;
  statusFilter: StatusOrderEnum | undefined;
  departmentFilter: any;
  mpsFilter: any;
  departments: Department[];
  mpsUsers: MPS[] | undefined;
}

const DropdownRender = ({
  onUnselectAllClick,
  mpsList,
}: {
  onUnselectAllClick: () => void;
  mpsList: React.ReactElement<
    unknown,
    string | React.JSXElementConstructor<unknown>
  >;
}) => (
  <>
    <Button style={{ border: 'none' }} onClick={onUnselectAllClick}>
      {t('purchaseForm.buttonActions.unselectAll')}
    </Button>
    <Divider style={{ margin: '2px' }} />
    {mpsList}
  </>
);

const dropdownRender = (
  menu: React.ReactElement<
    unknown,
    string | React.JSXElementConstructor<unknown>
  >,
  onUnselectAllClick: () => void,
) => <DropdownRender mpsList={menu} onUnselectAllClick={onUnselectAllClick} />;

const OrderFilters = ({
  handleChangeDeliveryDateFilter,
  handleChangeCreationDateFilter,
  handleStatusFilterChange,
  handleDepartmentFilterChange,
  handleMpsFilterChange,
  onUnselectAllClick,
  handle3PFilter,
  deliveryDateFilter,
  creationDateFilter,
  renderFilterState,
  statusFilter,
  departmentFilter,
  mpsFilter,
  departments,
  mpsUsers,
  orderTypeFilter,
}: OrderFiltersProps) => {
  const navigate = useNavigate();
  return (
    <>
      <FilterCol style={{ color: 'white', padding: 0 }}>
        <CustomRangePicker
          key={1}
          format={customFormat}
          allowClear
          onChange={handleChangeDeliveryDateFilter}
          value={
            deliveryDateFilter?.[0]
              ? [
                  moment(deliveryDateFilter?.[0], false),
                  moment(deliveryDateFilter?.[1], false),
                ]
              : undefined
          }
          placeholder={[
            t('orderTableFilters.deliveryDateOne'),
            t('orderTableFilters.deliveryDateTwo'),
          ]}
        />
        <CustomRangePicker
          key={2}
          format={customFormat}
          allowClear
          onChange={handleChangeCreationDateFilter}
          value={
            creationDateFilter?.[0]
              ? [
                  moment(creationDateFilter?.[0]),
                  moment(creationDateFilter?.[1]),
                ]
              : undefined
          }
          placeholder={[
            t('orderTableFilters.createdAtOne'),
            t('orderTableFilters.createdAtTwo'),
          ]}
        />
        {renderFilterState ? (
          <Select
            style={{ minWidth: 'fit-content' }}
            placeholder={t('orderTableFilters.status')}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <Select.Option value="">
              {t('orderTableFilters.status')}
            </Select.Option>
            {Object.keys(StatusOrderEnum).map((statusOption) => (
              <Select.Option value={statusOption} key={statusOption}>
                <CustomTag color={StatusTagColor[statusOption]}>
                  {t(`orderStatus.${statusOption}`) || `${statusOption}` || ''}
                </CustomTag>
              </Select.Option>
            ))}
          </Select>
        ) : (
          false
        )}
        <SelectOverflowH
          style={{ minWidth: 'fit-content' }}
          placeholder={t('orderTableFilters.department')}
          value={
            departmentFilter?.length > 0
              ? departmentFilter?.split(',')
              : undefined
          }
          mode="multiple"
          onChange={(e) => handleDepartmentFilterChange(e as string[])}
        >
          {departments.map((cityOption: Department) => (
            <Select.Option value={cityOption.id} key={cityOption.id}>
              {cityOption.name}
            </Select.Option>
          ))}
        </SelectOverflowH>
        <SelectOverflowH
          style={{ minWidth: 'fit-content' }}
          placeholder={t('orderTableFilters.mps')}
          value={mpsFilter?.length > 0 ? mpsFilter?.split(',') : undefined}
          mode="multiple"
          onChange={(e) => handleMpsFilterChange(e as MPS[])}
          dropdownRender={(menu) => dropdownRender(menu, onUnselectAllClick)}
        >
          {mpsUsers?.map((mpsUser: MPS) => (
            <Select.Option value={mpsUser._id} key={mpsUser._id}>
              {mpsUser.name}
            </Select.Option>
          ))}
        </SelectOverflowH>
        <SelectOverflowH
          style={{ minWidth: 'fit-content' }}
          defaultValue="REGULAR"
          value={orderTypeFilter}
          onChange={(e) => handle3PFilter(e as string)}
        >
          <Select.Option value="3P" key="3P">
            <CustomTag color={OrderTypeColors['3P']}>
              {t('orderTableFilters.orderType.3P')}
            </CustomTag>
          </Select.Option>
          <Select.Option value="REGULAR" key="REGULAR">
            <CustomTag color={OrderTypeColors.REGULAR}>
              {t('orderTableFilters.orderType.REGULAR')}
            </CustomTag>
          </Select.Option>
          <Select.Option value="" key="ALL">
            <CustomTag color={OrderTypeColors.ALL}>
              {t('orderTableFilters.orderType.ALL')}
            </CustomTag>
          </Select.Option>
        </SelectOverflowH>
      </FilterCol>
      <ButtonContainer>
        <AddButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(ROUTES.createOrder)}
        >
          {t('menu.createOrder')}
        </AddButton>
      </ButtonContainer>
    </>
  );
};

export default OrderFilters;
