import { StatusOrderEnum } from 'services/order/interface';
import { UserRoleEnum } from 'services/user/interface';
import { ORDER_ACTIONS_ENUM } from '../interface';

export const orderActionsPermissions: { [key: string]: string[] } = {
  viewRemission: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.IN_TRANSIT,
    StatusOrderEnum.CANCELLED,
    StatusOrderEnum.DELIVERED,
    StatusOrderEnum.REJECTED,
    StatusOrderEnum.PENDING,
    StatusOrderEnum.TEST,
    StatusOrderEnum.CANCELLED_NOT_LEGALIZED,
    StatusOrderEnum.STOCK_PENDING,
  ],
  editForm: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.IN_TRANSIT,
    StatusOrderEnum.DELIVERED,
    StatusOrderEnum.CANCELLED,
    StatusOrderEnum.CANCELLED_NOT_LEGALIZED,
    StatusOrderEnum.STOCK_PENDING,
  ],
  editSummary: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.IN_TRANSIT,
    StatusOrderEnum.STOCK_PENDING,
  ],
  editClientData: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.IN_TRANSIT,
    StatusOrderEnum.STOCK_PENDING,
  ],
  editSeller: [StatusOrderEnum.DELIVERED],
  updateProducts: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.STOCK_PENDING,
    StatusOrderEnum.DELIVERED,
  ],
  editPackedUnits: [StatusOrderEnum.IN_PICKING, StatusOrderEnum.DELIVERED],
  editReturnedUnits: [StatusOrderEnum.IN_TRANSIT],
  canUpdateBankPayment: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.STOCK_PENDING,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.PACKED,
  ],
};

export const allowedUpdatesForRole: Record<UserRoleEnum, ORDER_ACTIONS_ENUM[]> =
  {
    [UserRoleEnum.FINANCE]: [
      ORDER_ACTIONS_ENUM.UPDATE_PAYMENT,
      ORDER_ACTIONS_ENUM.UPDATE_INVOICES,
    ],
    [UserRoleEnum.GROWTH]: [],
    [UserRoleEnum.ADMIN]: [
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
      ORDER_ACTIONS_ENUM.SKIP_PAYMENT,
    ],
    [UserRoleEnum.ADMINISTRATIVE_ASSISTANT]: [
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
    ],
    [UserRoleEnum.CUSTOMER_SERVICE]: [
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
      ORDER_ACTIONS_ENUM.SKIP_PAYMENT,
    ],
    [UserRoleEnum.MERCHANT]: [],
    [UserRoleEnum.ORDER_CREATOR]: [],
    [UserRoleEnum.SELLER]: [],
    [UserRoleEnum.SELLER_COORDINATOR]: [],
    [UserRoleEnum.WAREHOUSE_ASSISTANT]: [
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
    ],
    [UserRoleEnum.WAREHOUSE_MANAGER]: [
      ORDER_ACTIONS_ENUM.CREATE_ORDER,
      ORDER_ACTIONS_ENUM.UPDATE_ORDER,
    ],
  };

export const accessFromCurrentStatus3P: Record<
  StatusOrderEnum,
  StatusOrderEnum[]
> = {
  RECEIVED: [StatusOrderEnum.CANCELLED],
  IN_TRANSIT: [StatusOrderEnum.CANCELLED],
  DELIVERED: [],
  CANCELLED: [],
  CANCELLED_NOT_LEGALIZED: [],
  IN_PICKING: [],
  PACKED: [],
  PENDING: [],
  REJECTED: [],
  STOCK_PENDING: [],
  TEST: [],
};

export const accessFromCurrentStatusPandas3P: Record<
  StatusOrderEnum,
  StatusOrderEnum[]
> = {
  RECEIVED: [StatusOrderEnum.CANCELLED],
  CANCELLED: [],
  CANCELLED_NOT_LEGALIZED: [],
  IN_PICKING: [],
  PACKED: [StatusOrderEnum.IN_TRANSIT, StatusOrderEnum.CANCELLED],
  IN_TRANSIT: [StatusOrderEnum.DELIVERED, StatusOrderEnum.CANCELLED],
  DELIVERED: [],
  PENDING: [],
  REJECTED: [],
  STOCK_PENDING: [],
  TEST: [],
};

export const accessFromCurrentStatus: {
  [key: string]: StatusOrderEnum[];
} = {
  [StatusOrderEnum.PENDING]: [],
  [StatusOrderEnum.STOCK_PENDING]: [
    StatusOrderEnum.TEST,
    StatusOrderEnum.CANCELLED,
  ],
  [StatusOrderEnum.REJECTED]: [],
  [StatusOrderEnum.CANCELLED_NOT_LEGALIZED]: [StatusOrderEnum.CANCELLED],
  [StatusOrderEnum.RECEIVED]: [
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.CANCELLED,
    StatusOrderEnum.TEST,
  ],
  [StatusOrderEnum.IN_PICKING]: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.CANCELLED,
    StatusOrderEnum.TEST,
  ],
  [StatusOrderEnum.PACKED]: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.IN_PICKING,
    StatusOrderEnum.IN_TRANSIT,
    StatusOrderEnum.CANCELLED,
  ],
  [StatusOrderEnum.IN_TRANSIT]: [
    StatusOrderEnum.RECEIVED,
    StatusOrderEnum.DELIVERED,
    StatusOrderEnum.PACKED,
    StatusOrderEnum.CANCELLED_NOT_LEGALIZED,
  ],
  [StatusOrderEnum.DELIVERED]: [],
  [StatusOrderEnum.CANCELLED]: [],
  [StatusOrderEnum.TEST]: [],
};
