import { Form } from 'antd';
import styled from 'styled-components';

export const PaymentRaiseFormItem = styled(Form.Item)`
  flex-direction: row !important;
  width: 100%;

  & .ant-col {
    width: 30%;
  }
`;

export const PaymentRaiseArticle = styled.article`
  display: flex;

  & p {
    width: 30%;
  }
`;
