import { Method } from 'axios';

export const endpoints = {
  getParameters: {
    method: 'GET' as Method,
    url: 'parameters-dom/parameters',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateParameter: {
    method: 'POST' as Method,
    url: 'parameters-dom/parameters',
    baseURL: process.env.REACT_APP_API_PARAMETERS_URL,
  },
};
