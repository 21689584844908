import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PandasError } from 'config/Errors/error';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { PaginatedResponse } from 'services/global/interface';

const DEFAULT_PAGE_SIZE = 20;

type Args<T> = T & {
  page: number;
  pageSize: number;
};

interface UseInfiniteScrollProps<T, Q> {
  filters: T;
  fetchData: (args0: Args<T>) => Promise<PaginatedResponse<Q>>;
  name: string | string[];
  pageSize?: number;
}

const useInfiniteScroll = <T, Q>({
  filters,
  fetchData,
  name,
  pageSize,
}: UseInfiniteScrollProps<T, Q>) => {
  const keys = Array.isArray(name) ? name : [name];
  const { data, isFetching, fetchNextPage, error, refetch } = useInfiniteQuery({
    queryKey: [...keys, filters],
    queryFn: ({ pageParam = 1 }) =>
      fetchData({
        ...filters,
        page: pageParam,
        pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    getNextPageParam: (lastPage: PaginatedResponse<Q>) => {
      const hasNext = lastPage?.metadata?.hasNext;
      const isNaNPage = Number.isNaN(lastPage?.metadata?.page);
      if (hasNext && !isNaNPage) {
        return Number(lastPage?.metadata?.page) + 1;
      }

      return 1;
    },
  });

  const list = useMemo(() => {
    return data?.pages?.flatMap((page) => page?.data ?? []) ?? [];
  }, [data]);
  const meta = useMemo(() => {
    return data?.pages?.[(data?.pages?.length ?? 1) - 1]?.metadata;
  }, [data]);
  const nextPage = useCallback(async () => {
    if (meta?.hasNext) await fetchNextPage();
  }, [fetchNextPage, meta?.hasNext]);

  const listError = useMemo(() => {
    if (error instanceof AxiosError && error.response) {
      return new PandasError(error.response.data);
    }

    if (error) {
      return new PandasError({
        code: t('ORDR-500100'),
        statusCode: 500,
        message: 'Unhandled error',
      });
    }

    return undefined;
  }, [error]);

  return {
    list,
    isFetching,
    nextPage,
    listError,
    meta,
    refetch,
  };
};

export default useInfiniteScroll;
