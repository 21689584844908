import { Divider, Tag } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { format } from 'date-fns';
import { Role, RoleTagColorCode } from 'helpers/hooks/useRoleAccess/interface';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderHistory,
  ProductAction,
  StatusOrderEnum,
  StatusTagColor,
} from 'services/order/interface';
import {
  ActionLabel,
  ActionLabelItem,
  BoldLabel,
  DateLabel,
  HistoryWrapper,
  NormalLabel,
} from './HistoryOrder.sty';

interface HistoryOrderProps {
  historyList: OrderHistory[];
}

const HistoryOrder = ({ historyList }: HistoryOrderProps) => {
  const { t } = useTranslation();
  const getActionLabelItem = (
    historyItem: OrderHistory,
    action: ProductAction,
  ) => {
    if (
      historyItem.status === StatusOrderEnum.CANCELLED ||
      historyItem.status === StatusOrderEnum.TEST
    ) {
      return t('historyCanceled', {
        oldQuantity: action.oldQuantity,
        name: action.variantName,
        sku: action.variantSKU,
      });
    }
    // If we are releasing stock units we first log it then we log the quantity changes.
    // The real old quantity is: the changed or removed old quantity + quantity to release
    if (action.type === 'RELEASED') {
      let oldQuantity =
        historyItem.action.find(
          (a) =>
            ['REMOVED', 'QUANTITY_CHANGED'].includes(a.type) &&
            a.variantSKU === action.variantSKU,
        )?.oldQuantity ?? 0;
      oldQuantity += action.oldQuantity ?? 0;
      const newQuantity = oldQuantity - action.oldQuantity;
      return t(action.type, {
        newQuantity,
        oldQuantity,
        resta: Number(oldQuantity) - Number(action.newQuantity),
        name: action.variantName,
        sku: action.variantSKU,
        releasedQuantity: action.oldQuantity,
      });
    }
    // Return other kind of action types
    return t(action.type, {
      newQuantity: action.newQuantity,
      oldQuantity: action.oldQuantity,
      resta: Number(action.oldQuantity) - Number(action.newQuantity),
      name: action.variantName,
      sku: action.variantSKU,
    });
  };

  // Filter out those QUANTITY_CHANGED or REMOVED
  // actions where qties are the same
  const isActionDisplayed = (action: ProductAction): boolean =>
    !(
      // Hide action if QUANTITY_CHANGED but diff is 0
      (
        (action.newQuantity === action.oldQuantity &&
          action.type === 'QUANTITY_CHANGED') ||
        // Hide action if variant is REMOVED both new and old qty is 0
        (action.newQuantity === 0 &&
          action.oldQuantity === 0 &&
          action.type === 'REMOVED')
      )
    );

  return (
    <HistoryWrapper>
      {historyList.map(
        (historyItem: OrderHistory, idx: number): ReactNode => (
          <div key={historyItem.date}>
            <DateLabel>
              {`${format(new Date(historyItem.date), 'dd/MM/yyyy')} - ${format(
                new Date(historyItem.date),
                'HH:mm',
              )}`}
            </DateLabel>
            <NormalLabel>
              <BoldLabel>{t('status')}&nbsp;</BoldLabel>
              <CustomTag color={StatusTagColor[historyItem.status]}>
                {t(`orderStatus.${historyItem.status}`) ||
                  `${historyItem.status}` ||
                  ''}
              </CustomTag>
            </NormalLabel>
            <NormalLabel>
              <BoldLabel>{t('userName')}</BoldLabel>
              {` ${historyItem.user.name}`}
            </NormalLabel>
            <NormalLabel>
              <BoldLabel>{t('rol')}&nbsp;</BoldLabel>
              {historyItem?.user?.role?.map((role: Role) => (
                <Tag key={role} color={RoleTagColorCode[role] || 'default'}>
                  {role}
                </Tag>
              ))}
            </NormalLabel>
            <NormalLabel>
              <BoldLabel>{t('comentsOrder')}</BoldLabel>
              {` ${historyItem.observations || ''}`}
            </NormalLabel>

            {historyItem.reportMissing && (
              <NormalLabel>
                <BoldLabel>{t('reportMissing')}</BoldLabel>
              </NormalLabel>
            )}

            <BoldLabel>{t('action')}</BoldLabel>
            <ActionLabel>
              {historyItem?.action
                .filter((a) => isActionDisplayed(a))
                .map((action: ProductAction) => (
                  <ActionLabelItem key={action.variantSKU}>
                    {getActionLabelItem(historyItem, action)}
                  </ActionLabelItem>
                ))}
            </ActionLabel>
            {idx < historyList.length - 1 && <Divider />}
          </div>
        ),
      )}
    </HistoryWrapper>
  );
};
export default HistoryOrder;
