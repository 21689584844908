/* eslint-disable no-underscore-dangle */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { CustomTag } from 'components/CustomTag';
import TableActions from 'components/TableActions/TableActions';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import dateFormat from 'helpers/utils/dateFormat';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { LogisticTypeColor, MPS } from 'services/MPS/interface';
import { BillingType, BillingTypeColor } from 'services/product/interface';
import {
  ItemLabel,
  ItemParagraph,
} from '../../../containers/Suppliers/SupplierList/SupplierTable.sty';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  SupplierActions: (supplier: MPS) => JSX.Element,
) => [
  {
    title: t('supplier.supplierListColumns.name'),
    render: (supplier: MPS) => <p>{`${supplier.name || 'N/A'}`}</p>,
  },
  {
    title: t('supplier.supplierListColumns.contactName'),
    render: (supplier: MPS) => <p>{`${supplier.contactName || 'N/A'}`}</p>,
  },
  {
    title: t('supplier.supplierListColumns.phoneNumber'),
    render: (supplier: MPS) => <p>{supplier.phoneNumber}</p>,
  },
  {
    title: t('supplier.supplierListColumns.email'),
    render: (supplier: MPS) => <p>{supplier.email}</p>,
  },
  {
    title: t('supplier.supplierListColumns.type'),
    render: (supplier: MPS) => {
      if (!supplier.isSupplier) {
        return (
          <CustomTag color="red">
            {t('supplier.supplierListColumns.mps')}
          </CustomTag>
        );
      }

      return (
        <CustomTag color="green">
          {t('supplier.supplierListColumns.supplier')}
        </CustomTag>
      );
    },
  },
  {
    title: t('supplier.supplierListColumns.billingType'),
    render: (supplier: MPS) => {
      if (supplier.isSupplier) return <div />;

      if (supplier.billingType !== BillingType.SERVICE) {
        return (
          <CustomTag color={BillingTypeColor[BillingType.PRODUCT]}>
            {t('supplier.supplierListColumns.product')}
          </CustomTag>
        );
      }

      return (
        <CustomTag color={BillingTypeColor[BillingType.SERVICE]}>
          {t('supplier.supplierListColumns.service')}
        </CustomTag>
      );
    },
  },
  {
    title: t('supplier.supplierListColumns.logisticType'),
    render: (supplier: MPS) => (
      <CustomTag color={LogisticTypeColor[supplier.logisticType]}>
        {t(`logisticType.${supplier.logisticType}`)}
      </CustomTag>
    ),
  },
  {
    title: t('supplier.supplierListColumns.actions'),
    render: (supplier: MPS) => SupplierActions(supplier),
  },
];

const hiddenDescription = ({
  supplier,
  SupplierActions,
  t,
  isMobile,
}: {
  supplier: MPS;
  t: TFunction;
  isMobile: boolean;
  SupplierActions: (supplier: MPS) => JSX.Element;
}) => (
  <Space direction="vertical">
    <ItemParagraph>
      <ItemLabel>
        {' '}
        {t('supplier.supplierListColumns.id')}
        :&nbsp;
      </ItemLabel>
      {supplier._id ?? supplier.id}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.document')}
        :&nbsp;
      </ItemLabel>
      {`NIT. ${supplier.document || 'N/A'}`}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.contactName')}
        :&nbsp;
      </ItemLabel>
      {`${supplier.contactName || 'N/A'}`}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.type')}
        :&nbsp;
      </ItemLabel>
      {!supplier.isSupplier ? (
        <CustomTag color="red">
          {t('supplier.supplierListColumns.mps')}
        </CustomTag>
      ) : (
        <CustomTag color="green">
          {t('supplier.supplierListColumns.supplier')}
        </CustomTag>
      )}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.phoneNumber')}
        :&nbsp;
      </ItemLabel>
      {supplier.phoneNumber}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.email')}
        :&nbsp;
      </ItemLabel>
      {supplier.email}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.address')}
        :&nbsp;
      </ItemLabel>
      {supplier.address}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('supplier.supplierListColumns.creationDate')}
        :&nbsp;
      </ItemLabel>
      {supplier.createdAt ? dateFormat(supplier.createdAt) : ''}
    </ItemParagraph>
    {isMobile && SupplierActions(supplier)}
  </Space>
);

export const useSupplierTable = ({
  onUpdateSupplier,
}: {
  onUpdateSupplier: (supplier: MPS) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('supplier.editActions');

  const SupplierActions = (supplier: MPS) => (
    <AccessWrapper
      fallback={
        <CustomTag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </CustomTag>
      }
    >
      <TableActions entity={supplier} onUpdate={onUpdateSupplier} />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, SupplierActions);
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('supplier.supplierListColumns.actions'),
      t('supplier.supplierListColumns.phoneNumber'),
      t('supplier.supplierListColumns.contactName'),
      t('supplier.supplierListColumns.email'),
      t('supplier.supplierListColumns.status'),
    ];
    return isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
  }, [t, defaultColumns, isMobile]);
  return {
    columns,
    hiddenDescription: (supplier: MPS) =>
      hiddenDescription({
        t,
        supplier,
        isMobile,
        SupplierActions,
      }),
  };
};

export default useSupplierTable;
