import { Checkbox, Typography } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const Title = styled(Typography.Title)`
  font-size: 38px;
  color: ${LightTheme.color.white} !important;
`;

export const ToggleLegacyData = styled(Checkbox)`
  color: ${LightTheme.color.white};
`;
