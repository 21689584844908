import { PlusOutlined } from '@ant-design/icons';
import Tabs from 'antd/es/tabs';
import { AddButton } from 'components/AddButton';
import { TableWrapper } from 'components/TableWrapper';
import { t } from 'i18next';
import { DebouncedFunc } from 'lodash';
import { ReactNode } from 'react';
import {
  FiltersAndButtonContainer,
  PurchaseContainer,
  PurchaseTitle,
  SearchInput,
  TitleColumn,
  TitleRow,
} from './PurchasesContent.sty';

const { TabPane } = Tabs;

const PurchaseContent = ({
  Board,
  List,
  onSearch,
  onCreatePurchase,
}: {
  Board: ReactNode;
  List: ReactNode;
  onSearch: DebouncedFunc<(value: string) => void>;
  onCreatePurchase: () => void;
}) => {
  return (
    <PurchaseContainer>
      <TitleRow>
        <TitleColumn
          span={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PurchaseTitle level={1}>{t('purchase.listTitle')}</PurchaseTitle>
        </TitleColumn>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <SearchInput
            placeholder={t('purchase.seachPlaceHolder')}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </TitleColumn>
      </TitleRow>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('purchase.board')} key="1">
          <FiltersAndButtonContainer>
            <AddButton
              icon={<PlusOutlined color="white" />}
              onClick={onCreatePurchase}
            >
              {t('purchase.createPurchaseOrder')}
            </AddButton>
          </FiltersAndButtonContainer>
          {Board}
        </TabPane>
        <TabPane tab={t('purchase.list')} key="2">
          <FiltersAndButtonContainer>
            <AddButton
              icon={<PlusOutlined color="white" />}
              onClick={onCreatePurchase}
            >
              {t('purchase.createPurchaseOrder')}
            </AddButton>
          </FiltersAndButtonContainer>
          <TableWrapper>{List}</TableWrapper>
        </TabPane>
      </Tabs>
    </PurchaseContainer>
  );
};

export default PurchaseContent;
