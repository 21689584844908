import { Card, Image } from 'antd';
import styled from 'styled-components';

export const BannerCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BannerCardImage = styled(Image)`
  height: 120px;
  object-fit: cover;
`;

export const BannerCardImageLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerCardDescription = styled.p<{ lineClamp: number }>`
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
