import { Purchase, PurchaseStatus } from 'services/purchase/interface';

export enum PURCHASE_VIEWS_ENUM {
  LIST = 'LIST',
  BOARD = 'BOARD',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum PURCHASE_ACTION_ENUM {
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_SEARCH_PURCHASES = 'SET_SEARCH_PURCHASES',
  SET_FILTER_STATUS = 'SET_FILTER_STATUS',
  SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES',
  SET_READONLY = 'SET_READONLY',
  SET_STEP = 'SET_STEP',
  SET_PURCHASE_TOTAL = 'SET_PURCHASE_TOTAL',
  SET_SHOW_CLONE_PURCHASE_MODAL = 'SET_SHOW_CLONE_PURCHASE_MODAL',
  SET_PURCHASE_TO_CLONE = 'SET_PURCHASE_TO_CLONE',
  SET_AUTOCOMPLETE_SUGGESTED = 'SET_AUTOCOMPLETE_SUGGESTED',
}

export type PurchaseAction = {
  type: PURCHASE_ACTION_ENUM;
  payload: any;
};

export interface PurchaseState {
  currentView: PURCHASE_VIEWS_ENUM;
  filters: {
    search: string;
    status?: PurchaseStatus;
  };
  formInitialValues: Purchase;
  readOnly: boolean;
  step: number;
  purchaseTotal: string;
  showClonePurchaseModal: boolean;
  purchaseToClone?: Purchase;
  autocompletedSuggested: boolean;
}
