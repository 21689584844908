import { Tag } from 'antd';
import {
  HistoryItem,
  StockAction,
  StockActionEnum,
} from 'components/VariantForm/interface';
import format from 'date-fns/format';
import {
  ActionTagColorCode,
  Role,
  RoleTagColorCode,
} from 'helpers/hooks/useRoleAccess/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import {
  DateLabel,
  NormalLabel,
} from 'modules/Order/components/HistoryOrder/HistoryOrder.sty';
import { useTranslation } from 'react-i18next';
import {
  OrderHistoryContainer,
  ProductBoldLabel,
  RenderLocationsContainer,
  RenderLocationsLabel,
} from './HistoryProduct.sty';

const RenderLocations = (props: any) => {
  const { list } = props;
  return (
    <RenderLocationsContainer>
      {list?.map((item: any, _: any) => {
        return (
          <div style={{ display: 'flex' }} key={list[_]}>
            <RenderLocationsLabel>{item.id}</RenderLocationsLabel>
            <RenderLocationsLabel>{item.locationid}</RenderLocationsLabel>
          </div>
        );
      })}
    </RenderLocationsContainer>
  );
};

const HistoryProduct = ({
  action,
  actionQuantity,
  date,
  metadata,
  endLocation,
  originLocation,
  price,
  discount,
  resultingStock,
  resultingPrice,
  resultingDiscount,
  inventoryChangeReason = '',
  user,
}: HistoryItem) => {
  const { t } = useTranslation();
  const renderRole = (roles: string[] | string) => {
    if (!roles) {
      return <Tag color="red">{t('variantHistory.errorHandling.noRole')}</Tag>;
    }

    if (typeof roles === 'string') {
      return <Tag color={RoleTagColorCode[roles] || 'default'}>{roles}</Tag>;
    }

    return user?.role?.map((role: Role) => (
      <Tag color={RoleTagColorCode[role] || 'default'} key={role}>
        {role}
      </Tag>
    ));
  };

  const renderStockUpdateDetails = () => (
    <div>
      <NormalLabel>
        <ProductBoldLabel>
          {t('variantHistory.availableAction')}
        </ProductBoldLabel>
        <span>{actionQuantity}</span>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.stock')}</ProductBoldLabel>
        <span>{resultingStock?.available ?? 0}</span>
      </NormalLabel>
      {inventoryChangeReason && (
        <NormalLabel>
          <ProductBoldLabel>
            {t('variantHistory.inventoryChangeReason')}
          </ProductBoldLabel>
          <span>{inventoryChangeReason}</span>
        </NormalLabel>
      )}
      {price > 0 && (
        <NormalLabel>
          <ProductBoldLabel>
            {t('variantHistory.unitaryPrice')}
          </ProductBoldLabel>
          <span>{numberToCurrency(price)}</span>
        </NormalLabel>
      )}
      {metadata?.orderNumber && (
        <NormalLabel>
          <ProductBoldLabel>
            {t('variantHistory.actionNumber')}
          </ProductBoldLabel>
          <span>{metadata?.orderNumber}</span>
        </NormalLabel>
      )}
      {endLocation && (
        <NormalLabel>
          <ProductBoldLabel>{t('variantHistory.endLocation')}</ProductBoldLabel>
          <span>
            <RenderLocations list={endLocation} />
          </span>
        </NormalLabel>
      )}
      {originLocation && (
        <NormalLabel>
          <ProductBoldLabel>
            {t('variantHistory.originLocation')}
          </ProductBoldLabel>
          <span>
            <RenderLocations list={originLocation} />
          </span>
        </NormalLabel>
      )}
    </div>
  );

  const renderPriceUpdateDetails = (
    priceUpdate: boolean,
    discountUpdate: boolean,
  ) => (
    <div>
      {priceUpdate && (
        <div>
          <NormalLabel>
            <ProductBoldLabel>
              {t('variantHistory.previousPrice')}
            </ProductBoldLabel>
            <span>{numberToCurrency(price)}</span>
          </NormalLabel>
          <NormalLabel>
            <ProductBoldLabel>
              {t('variantHistory.updatedPrice')}
            </ProductBoldLabel>
            <span>{numberToCurrency(resultingPrice || 0)}</span>
          </NormalLabel>
        </div>
      )}
      {discountUpdate && (
        <div>
          <NormalLabel>
            <ProductBoldLabel>
              {t('variantHistory.previousDiscount')}
            </ProductBoldLabel>
            <span>{numberToCurrency(discount || 0)}</span>
          </NormalLabel>
          <NormalLabel>
            <ProductBoldLabel>
              {t('variantHistory.updatedDiscount')}
            </ProductBoldLabel>
            <span>{numberToCurrency(resultingDiscount || 0)}</span>
          </NormalLabel>
        </div>
      )}
    </div>
  );

  const renderHistoryDetails = (act: StockAction) => {
    if (act !== StockActionEnum.PRICE_UPDATE) {
      return renderStockUpdateDetails();
    }

    const priceUpdate = price !== resultingPrice;
    const discountUpdate = discount !== resultingDiscount;

    return renderPriceUpdateDetails(priceUpdate, discountUpdate);
  };

  return (
    <OrderHistoryContainer>
      <DateLabel>
        {`${format(new Date(date ?? ''), 'dd/MM/yyyy')} - ${format(
          new Date(date ?? ''),
          'HH:mm',
        )}`}
      </DateLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.action')}</ProductBoldLabel>
        <Tag color={ActionTagColorCode[action] || 'default'}>
          {t(`variantHistory.actions.${action}`)}
        </Tag>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.user')}</ProductBoldLabel>
        <span>
          {user.fullName ??
            t('variantHistory.errorHandling.noUser', { id: user.id })}
        </span>
      </NormalLabel>
      <NormalLabel>
        <ProductBoldLabel>{t('variantHistory.role')}</ProductBoldLabel>
        {renderRole(user?.role)}
      </NormalLabel>
      {renderHistoryDetails(action)}
    </OrderHistoryContainer>
  );
};

export default HistoryProduct;
