import { LightTheme } from 'helpers/constants/theme';
import { Role } from 'helpers/hooks/useRoleAccess/interface';
import { orderActionsPermissions } from 'modules/Order/constants/orderStatusMachine';
import { MPS } from 'services/MPS/interface';
import {
  CartVariant,
  DiscountItem,
  VariantImage,
} from 'services/product/interface';
import { User } from 'services/user/interface';
import {
  Warranty,
  WarrantyMethod,
  WarrantyStatus,
} from 'services/warranty/interface';

type OrderPlatform = 'PWA' | 'BACKOFFICE';

export enum PaymentMethodStatus {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  PAID = 'PAID',
}

export enum PaymentConcepts {
  ORDER = 'ORDER',
  SHIPPING = 'SHIPPING',
}

export enum EPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  ONLINE = 'ONLINE',
  CREDIT = 'CREDIT',
  CASH = 'CASH',
}

export interface PaymentData {
  status: PaymentMethodStatus;
  method: EPaymentMethod;
  amount: number;
  concept: PaymentConcepts;

  metadata?: any;
}

export interface PaymentMethod {
  use?: boolean;
  amount?: number;
}
export interface Pandabono extends PaymentMethod {
  segmentation?: string;
  campaignName?: string;
}

export interface CreditLine extends PaymentMethod {
  fee?: number;
}

export interface Online extends PaymentMethod {
  _id?: string;
  checkoutSessionId?: string;
  transactionId?: string;
  discount?: boolean;
  type?: PaymentMethodsEnum;
}

export interface PaymentOptions {
  pandabonos?: Pandabono;
  credit?: CreditLine;
  cash?: PaymentMethod;
  bankDeposit?: PaymentMethod;
  online?: Online;
}
export type PaymentOptionsKeys = keyof PaymentOptions;

export interface VariantItemDTO {
  id: string;
  cartQuantity: number;
  price?: number;
  volumeDiscount?: DiscountItem[];
  discount?: number;
}

export interface OrderDTO {
  saleDate?: string;
  comments?: string;
  user?: UserOrders;
  seller?: User;
  sellerCoordinator?: User;
  discount?: number;
  status?: OrderStatus;
  detail: VariantItemDTO[];
  platform?: OrderPlatform;
  payment?: PaymentOptions;
  freeShippingCost?: boolean;
  coupon?: OrderCoupon;
  relatedOrders?: RelatedOrder[];
}

export interface OrderTotals {
  coupon?: number;
  creditFee?: number;
  otherDiscount?: number;
  productDiscount?: number;
  pandaCoins?: number;
  shipping?: number;
  methodDiscount?: number;
  subtotal?: number;
  total?: number;
  saleOrderTotals?: OrderTotals;
  payment?: PaymentOptions;
  earnCost?: number;
  serviceFee?: number;
}

export interface DeliveryInfo {
  date: string;
  updatedDate: string;
  method?: string;
  paidMethod?: string;
  price?: number;
  cost?: number;
  localization?: localization;
  priority?: string;
  reschedule?: number;
  rescheduleReason?: string;
  type?: string;
  startHour?: string;
  endHour?: string;
  endDate?: string;
  mpsDates?: MpsDateValue[];
}

export interface MpsDateValue {
  _id: string;
  date: string;
  endDate?: string;
}

export interface PickedVariant {
  id: string;
  cartQuantity: number;
  expectedCartQuantity: number;
  price?: number;
  discount?: number;
}

export interface OrderBatch {
  id: string;
  observations?: string;
}

export enum StockStatus {
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_WAREHOUSE = 'IN_WAREHOUSE',
}

export interface StockInfo {
  id: string;
  status: StockStatus;
  quantity: number;
}

export interface RelatedOrder {
  id?: string;
  orderNumber: string;
  status: keyof typeof StatusOrderEnum;
  total: number;
}

export interface PackageInfo {
  sellerIds: string[];
  packageId: string;
  packageNumber: string;
}

export interface Order {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  saleDate?: string;
  deletedAt?: string | null;
  user: UserOrders;
  detail: CartVariant[];
  reportMissing?: boolean;
  delivered: PickedVariant[];
  picked: PickedVariant[];
  total: number;
  subtotal?: number;
  discount?: number;
  mps?: MPS[];
  lastOrders?: {
    id: string;
    orderNumber: string;
    status: string;
    billing: {
      date: string;
      invoiceNumber: string;
      invoiceId: string;
      type: string;
    };
  }[];
  status: keyof typeof StatusOrderEnum;
  history?: OrderHistory[];
  comments?: string;
  orderNumber: string;
  segmentation?: string;
  prepaid?: number;
  delivery?: DeliveryInfo;
  deliveryDate?: string;
  seller?: User;
  sellerCoordinator?: User;
  saleOrderId?: string;
  isWarranty?: boolean;
  totals?: OrderTotals;
  coupon?: OrderCoupon;
  isFirstBuy?: boolean;
  freeShippingCost?: boolean;
  payment?: PaymentOptions;
  legalized?: boolean;
  validatePayment?: boolean;
  pendingStock?: StockInfo[];
  relatedOrders?: RelatedOrder[];
  packageInfo?: PackageInfo;
  package?: any;
  totalRaised?: number;
  raisedPayments?: unknown;
  warrantyOrders?: WarrantyOrderDTO[];
  canUpdatePendingStock?: boolean;
  warranties?: Warranty;
  isMultiOrder?: boolean;
  isDropShipper?: boolean;
  dropShipperInfo?: DropshipperInfo;
  orderType?: OrderType;
  paymentMethod: PandasPaymentMethod;
  bankTransferPaid?: boolean;
  shippingQuote?: ShippingQuote;
  paymentData?: PaymentData[];
  shippingLogistic?: ShippingLogistic;
}

export enum ShippingLogistic {
  PANDAS = 'PANDAS',
  EXTERNAL = 'EXTERNAL',
  CUSTOM = 'CUSTOM',
}

export enum Courier {
  MI_PAQUETE = 'MI_PAQUETE',
}

export interface ShippingQuote {
  courier: Courier;
  providerId: string; // Quote id for mipaquete.com
  companyName: string;
  companyImg?: string;
  shippingCost: number;
  commissionWithShipping: number;
  commissionWithoutShipping: number;
  shippingTime?: number;
  weightVolume: number;
  pickupService: boolean;
  pickupTime?: number;
  deliveryCompanyId: string;
}

export enum PandasPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  ONLINE = 'ONLINE',
  CREDIT = 'CREDIT',
  CASH = 'CASH',
}

export enum OrderType {
  '3P' = '3P',
  'REGULAR' = 'REGULAR',
}
export const OrderTypeColors = {
  [OrderType['3P']]: LightTheme.color.light_yellow,
  [OrderType.REGULAR]: LightTheme.color.primary,
  ALL: LightTheme.color.orange_nice,
};
export interface WarrantyOrderDTO {
  value?: string;
  id: string;
  orderNumber?: string;
  isWarranty?: boolean;
}

export interface DropshipperInfo {
  subTotal: number;
  total: number;
  platformCommission: number;
  shippingCost: number;
  user?: UserOrders;
  collectionCost: number;
  gain: number;
}

export interface OrderCoupon {
  couponId: string;
  couponName: string;
  couponSaleType: string;
  couponType: string;
  discount: number;
}

export interface localization {
  latitude?: string;
  longitude?: string;
  address: string;
  details?: string;
  cityCode?: string;
  cityName?: string;
  departmentCode?: string;
  departmentName?: string;
}

export interface UserOrders extends Partial<User> {
  id: string;
  name: string;
  documentType: string;
  documentNumber: string;
  localization?: localization;
  firstLastName?: string;
  email?: string;
  phone?: string;
  isPrime?: boolean;
  // legacy prop
  userNumber?: string;
}

export interface StatusOrder {
  id: string;
  name: string;
}

export type Platformtype = 'BACKOFFICE' | 'PWA';
export type ActionType =
  | 'PICKED | RETURNED'
  | 'ADDED'
  | 'REMOVED'
  | 'QUANTITY_CHANGED'
  | 'RELEASED';

export interface OrderHistory {
  date: string;
  action: ProductAction[];
  status: StatusOrderEnum;
  user: HistoryUser;
  observations: string;
  reportMissing: boolean;
}

export interface HistoryUser {
  userId: string;
  name: string;
  email: string;
  role?: Role[];
}
export interface ProductAction {
  type: ActionType;
  variantSKU: string;
  variantName: string;
  oldQuantity: number;
  newQuantity: number;
}

export interface OrderFilters {
  search: string;
  startDeliveryDate?: string;
  endDeliveryDate?: string;
  startCreationDate?: string;
  endCreationDate?: string;
  department?: string;
  mpsId?: string;
  status?: StatusOrderEnum;
  page?: number;
  pageSize?: number;
  orderType?: OrderType;
}

export type InsufficientStockDetails = {
  canFillWithEntry: boolean;
  quantity: number;
  unitsInEntry: number;
  variantId: string;

  variantName?: string;
  variantSku?: string;
  images?: VariantImage[];
};

export enum StatusOrderEnum {
  RECEIVED = 'RECEIVED',
  IN_PICKING = 'IN_PICKING',
  PACKED = 'PACKED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED_NOT_LEGALIZED = 'CANCELLED_NOT_LEGALIZED',
  STOCK_PENDING = 'STOCK_PENDING',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TEST = 'TEST',
}

export const CancelledOrderStatuses = [
  StatusOrderEnum.CANCELLED_NOT_LEGALIZED,
  StatusOrderEnum.CANCELLED,
  StatusOrderEnum.TEST,
  StatusOrderEnum.REJECTED,
];

export const ValidOrderStatuses = [
  StatusOrderEnum.PENDING,
  StatusOrderEnum.STOCK_PENDING,
  StatusOrderEnum.RECEIVED,
  StatusOrderEnum.IN_PICKING,
  StatusOrderEnum.PACKED,
  StatusOrderEnum.IN_TRANSIT,
  StatusOrderEnum.DELIVERED,
];

export declare enum PaymentMethodsEnum {
  ADDI = 'ADDI',
  ACUOTAZ = 'ACUOTAZ',
  BANCOLOMBIA_TOKEN_BOX = 'BANCOLOMBIA_TOKEN_BOX',
  BANCOLOMBIA_TRANSFER = 'BANCOLOMBIA_TRANSFER',
  MERCADO_PAGO_CHECKOUT_PRO = 'MERCADO_PAGO_CHECKOUT_PRO',
  MERCADO_PAGO_WALLET = 'MERCADO_PAGO_WALLET',
  NEQUI = 'NEQUI',
  NU_PAY = 'NU_PAY',
  PAYVALIDA = 'PAYVALIDA',
  PIX = 'PIX',
  PSE = 'PSE',
  SAFETYPAY = 'SAFETYPAY',
  WEBPAY = 'WEBPAY',
  WIBOND = 'WIBOND',
}

export type OrderStatus = keyof typeof StatusOrderEnum;

export type OrderStatusAction = keyof typeof orderActionsPermissions;

export const StatusOrderName: { [key: number | string]: string } = {
  [StatusOrderEnum.RECEIVED]: 'Recibida',
  [StatusOrderEnum.PENDING]: 'Pendiente de pago',
  [StatusOrderEnum.IN_PICKING]: 'En preparación',
  [StatusOrderEnum.PACKED]: 'Empacada',
  [StatusOrderEnum.IN_TRANSIT]: 'En camino',
  [StatusOrderEnum.CANCELLED_NOT_LEGALIZED]: 'En devolución',
  [StatusOrderEnum.CANCELLED]: 'Cancelada',
  [StatusOrderEnum.REJECTED]: 'Pago rechazado',
  [StatusOrderEnum.DELIVERED]: 'Entregada',
  [StatusOrderEnum.TEST]: 'Prueba',
  [StatusOrderEnum.STOCK_PENDING]: 'Pendiente de stock',
};

export const StatusTagColor: { [key: string]: string } = {
  [StatusOrderEnum.RECEIVED]: LightTheme.color.warning,
  [StatusOrderEnum.PENDING]: LightTheme.color.warning,
  [StatusOrderEnum.IN_PICKING]: LightTheme.color.dark_highlight,
  [StatusOrderEnum.PACKED]: LightTheme.color.soft_highlight,
  [StatusOrderEnum.IN_TRANSIT]: LightTheme.color.primary_dark,
  [StatusOrderEnum.CANCELLED_NOT_LEGALIZED]: LightTheme.color.dark_orange,
  [StatusOrderEnum.CANCELLED]: LightTheme.color.hard_danger,
  [StatusOrderEnum.REJECTED]: LightTheme.color.soft_danger,
  [StatusOrderEnum.DELIVERED]: LightTheme.color.soft_link,
  [StatusOrderEnum.TEST]: LightTheme.color.gray_light,
  [StatusOrderEnum.STOCK_PENDING]: LightTheme.color.happy_emerald,
  FIRST_ORDER: LightTheme.color.primary,
  ROUTE: LightTheme.color.info,
  CANCELED: LightTheme.color.soft_danger,
  CREDIT: LightTheme.color.happy_emerald,
  ONLINE: LightTheme.color.soft_highlight,
  LEGALIZED: LightTheme.color.danger,
  P2P: LightTheme.color.soft_blue,
  BANK_DEPOSIT: LightTheme.color.soft_blue,
  PRIME: LightTheme.color.prime_purple,
  MISSING: LightTheme.color.soft_link,
  MULTI: LightTheme.color.danger,
  DROPSHIPPING: LightTheme.color.soft_danger,
  '3P': LightTheme.color.orange_nice,
  PENDING_SHIPPING_PAYMENT: LightTheme.color.warning,
  REJECTED_SHIPPING_PAYMENT: LightTheme.color.danger,
};

export const WarrantyStatusTagColor: { [key: string]: string } = {
  [WarrantyStatus.PENDING]: LightTheme.color.warning,
  [WarrantyStatus.ACCEPTED]: LightTheme.color.primary_dark,
  [WarrantyStatus.PROCESSED]: LightTheme.color.soft_link,
  [WarrantyStatus.REJECTED]: LightTheme.color.soft_danger,
};

export const WarrantyMathodColor = {
  [WarrantyMethod.FORMS]: LightTheme.color.gray_light,
  [WarrantyMethod.PANDABONOS]: LightTheme.color.primary_light,
};
