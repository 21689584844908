import styled from 'styled-components';

export const DifferenceAddonStyled = styled.div<{
  color: string;
}>`
  color: white;
  background-color: ${({ color }) => color};
  padding: 0px 5px;
  border-radius: 5px;
  width: fit-content;
  white-space: nowrap;
`;
