import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { StatusOrderEnum } from 'services/order/interface';
import { legalizeOrderBatch } from 'services/order/order.service';
import { OrderContextTypes } from '../context/Order.context';
import { ORDER_ACTION_ENUM } from '../reducer/interface';

interface UseMultiLegalizeProps {
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
}

const useMultiLegalize = ({
  state,
  dispatch,
  notifySuccess,
  notifyError,
}: OrderContextTypes & UseMultiLegalizeProps) => {
  const { mutateAsync: batchLegalize, isLoading } = useMutation({
    mutationFn: legalizeOrderBatch,
  });

  const openLegalizeModal = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_MULTI_LEGALIZE,
      payload: true,
    });
  }, [dispatch]);

  const closeLegalizeModal = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_MULTI_LEGALIZE,
      payload: false,
    });
  }, [dispatch]);

  const multiLegalizeDisabled = useMemo(() => {
    if (!state.selectedOrders?.length) return false;

    return state.selectedOrders.every(
      (o) => o.status === StatusOrderEnum.DELIVERED && !o.legalized,
    );
  }, [state.selectedOrders]);

  const onMultiLegalize = useCallback(async () => {
    const ordersToSend: { id: string }[] = state.selectedOrders.map(
      (order) => ({
        id: order.id ?? '',
      }),
    );
    try {
      await batchLegalize(ordersToSend);
      notifySuccess(t('multiLegalize.notifications.success'));
      closeLegalizeModal();
    } catch (e) {
      notifyError(t('multiLegalize.notifications.error'));
    }
  }, [
    state.selectedOrders,
    batchLegalize,
    closeLegalizeModal,
    notifyError,
    notifySuccess,
  ]);

  return {
    closeLegalizeModal,
    onMultiLegalize,
    multiLegalizeDisabled: !multiLegalizeDisabled,
    isLoadingMultiLegalize: isLoading,
    openLegalizeModal,
  };
};

export default useMultiLegalize;
