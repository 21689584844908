import { TargetCategory } from 'containers/Categories/CategoryForm/interface';
import { LightTheme } from 'helpers/constants/theme';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { WalletStatusEnum } from 'pages/Pandabonos/interface';
import { Category } from 'services/category/interface';
import {
  TransactionStatusEnum,
  WalletActionEnum,
} from 'services/wallet/interface';

export enum SaleType {
  FIRST = 'FIRST',
  ANY = 'ANY',
}
export enum CouponType {
  NOMINAL = 'NOMINAL',
  PERCENTAGE = 'PERCENTAGE',
}
export enum CouponStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserSegType {
  NONE = 'NONE',
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
}

export interface CouponUse {
  userId: string;
  orderId: string;
  date: string;
}

export interface CreatedByUser {
  id: string;
  firebaseId: string;
  name: string;
  role: RoleEnum[];
}

export const CouponStatusTagColor: { [key: string]: string } = {
  [CouponStatus.ACTIVE]: LightTheme.color.soft_link,
  [CouponStatus.INACTIVE]: LightTheme.color.soft_danger,
  [TransactionStatusEnum.PENDING]: LightTheme.color.primary,
  [TransactionStatusEnum.REJECTED]: LightTheme.color.soft_danger,
  [TransactionStatusEnum.ACCEPTED]: LightTheme.color.soft_link,
};

export interface Coupon {
  id: string;
  name: string;
  status: CouponStatus;
  startDate: string;
  endDate: string;
  saleType: SaleType;
  uses: number;
  usesPerUser: number;
  minimumPurchase: number;
  segmentation?: string;
  maxDiscount: number;
  type: CouponType;
  nominalValue: number;
  percentageValue: number;
  couponUses: CouponUse[];
  maxCouponSpent: number;
  couponSpentUsesCount: number;
  createdBy: CreatedByUser;
  usesCount: number;
  categorySegmentation: boolean;
  brandSegmentation: boolean;
  selectedCategories: Category[];
  targetCategories: TargetCategory[];
  targetBrands: TargetBrand[];
  userSegmentation: UserSegType;
  usersFile: string;
  isSponsoredCoupon: boolean;
  targetSellers: TargetSeller[];
  targetUsers: string[] | any;
}

export interface TargetBrand {
  id: string;
  name: string;
  urlName: string;
}

export interface TargetSeller {
  id: string;
  name: string;
}
