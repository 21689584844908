/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable func-call-spacing */
/* eslint-disable no-spaced-func */
import React, { useRef } from 'react';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { TableWrapper } from 'components/TableWrapper';
import BrandModal from 'components/BrandModal/BrandModal';
import DeleteModal from 'components/BrandModal/DeleteModal';
import { BrandsModalAction } from 'components/BrandForm/interface';
import useBrandsList from './hooks/useBrandsList';
import { BrandsTable } from './BrandsTable';
import { ButtonRigthContainer } from './BrandsList.sty';

export const BrandsContentList = ({
  searchKey,
}: {
  searchKey: string | undefined;
}) => {
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const {
    readQuery: { mutateAsync: getBrandsListAsync, isLoading: isLoadingList },
  } = useModuleCRUD('brands');

  const {
    t,
    brandsList,
    BrandsFilters,
    reachedEndOfData,
    fetchNextBrandsPage,
    closeDeleteModal,
    isOpenDelete,
    fetchBrandsList,
    handleUpdate,
    handleDelete,
    isModalOpen,
    closeModal,
    modalActionType,
    initialValues,
  } = useBrandsList({
    searchKey,
    getBrandsListAsync,
    tableRef,
  });

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <ButtonRigthContainer>
        <BrandsFilters />
      </ButtonRigthContainer>
      <BrandModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        modalActionType={modalActionType ?? BrandsModalAction.CREATE}
        initialValues={initialValues}
        reLoadOnSuccess={fetchBrandsList}
      />

      <DeleteModal
        modalActionType={BrandsModalAction.DELETE}
        closeModal={closeDeleteModal}
        isOpen={isOpenDelete}
        initialValues={initialValues}
        reLoadOnSuccess={fetchBrandsList}
      />

      <TableWrapper>
        <BrandsTable
          reachedEndOfData={reachedEndOfData}
          isLoading={isLoadingList}
          onDeleteBrand={handleDelete}
          onUpdateBrand={handleUpdate}
          data={brandsList || []}
          onScrollToEnd={fetchNextBrandsPage}
          tableRef={tableRef}
        />
      </TableWrapper>
    </React.Suspense>
  );
};
