export interface Unit {
  _id: string;
  status: string;
  variantId: string;
  locationId: string;
  cost: number;
  user: UnitUser;
  createdAt: Date;
  updatedAt: Date;
}

export interface UnitUser {
  id: string;
  name: string;
  email: string;
  roles: string[];
}

export enum UnitStatus {
  available = 'available',
  blocked = 'blocked',
}
