import { Method } from 'axios';

export const endpoints = {
  getHaulierList: {
    method: 'GET' as Method,
    url: '/haulier-dom/haulier',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createHaulier: {
    method: 'POST' as Method,
    url: '/haulier-dom/haulier',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateHaulier: {
    method: 'PUT' as Method,
    url: '/haulier-dom/haulier/{params.id}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteHaulier: {
    method: 'DELETE' as Method,
    url: '/haulier-dom/haulier/{params.id}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getHaulierHistory: {
    method: 'GET' as Method,
    url: '/route-dom/routes/by-haulier',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
