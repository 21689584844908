import * as R from 'ramda';
import { IBanner, IBannerForm } from 'services/parameters/interface';
import { v4 as uuid } from 'uuid';

export function FilterBannerByPropsPredicate(
  target: IBanner,
  invertValidation = false,
) {
  const pred = R.whereEq<IBanner>({
    id: target.id,
    image: target.image,
    link: target.link,
  });

  if (invertValidation) {
    return (b: IBanner) => !pred(b);
  }

  return pred;
}

export function FormBannerToBanner(banner: IBannerForm): IBanner {
  return {
    ...banner,
    id: uuid(),
    image: String(banner.image),
    desktopImage: String(banner.image),
  };
}
export function BannerToFormBanner(banner: IBanner): IBannerForm {
  return {
    image: banner.image,
    link: banner.link,
    description: banner.description,
    title: banner.title,
  };
}
