import { CategoryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { Category, CategoryCreateValues } from './interface';

const createCategory = async (category: CategoryCreateValues) => {
  const response = await getAPIMethod({
    name: 'createCategory',
    data: category,
  });
  return response.data;
};

const getCategoryList = async (queryParams?: CategoryParams) => {
  const response = await getAPIMethod({
    name: 'getCategoryList',
    params: { parent: queryParams?.parent, fetchAll: true },
  });
  return response.data.categories;
};

const getCategoryByUrlName = async (categoryUrlName: string) => {
  const response = await getAPIMethod({
    name: 'getCategoryByUrlName',
    pathParams: { urlName: categoryUrlName },
  });
  return response.data as Category;
};

const deleteCategory = async (categoryId: string) =>
  getAPIMethod({ name: 'deleteCategory', pathParams: { categoryId } });

const updateCategory = async (category: Category) =>
  getAPIMethod({
    name: 'updateCategory',
    data: category,
    pathParams: { categoryId: category.id || '' },
  });

export * from './interface';
export default {
  create: createCategory,
  read: getCategoryList,
  update: updateCategory,
  delete: deleteCategory,
  readByParam: getCategoryByUrlName,
};
