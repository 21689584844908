/* eslint-disable import/no-import-module-exports */
/* eslint-disable import/no-duplicates */
import * as module from 'colombia-holidays';
import { format, formatISO, getDay, getMonth } from 'date-fns';
import es from 'date-fns/locale/es';

const DATE_FORMAT = 'yyyy-MM-dd';
const LABEL_FORMAT = "eeee d 'de' LLLL";
export const SUNDAY = 0;

const getHolidays = () =>
  module
    .getColombiaHolidaysByYear(Number(new Date().getFullYear()))
    .map((holiday: any) => holiday?.holiday);

export const capitalizeString = (sentence: string) =>
  sentence
    .split(' ')
    .map((word: string) => {
      if (word.length <= 2) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

export const dateToDeliveryOption = (date: Date, disabled: boolean) => ({
  weekDay: getDay(date),
  month: getMonth(date),
  dayNumber: date.getDate(),
  year: date.getFullYear(),
  isoDate: formatISO(date),
  date,
  value: format(date, DATE_FORMAT),
  label: capitalizeString(format(date, LABEL_FORMAT, { locale: es })),
  disabled,
});

const getExcludedDays = () => [
  `${new Date().getFullYear()}-12-24`,
  `${new Date().getFullYear()}-12-31`,
  `2023-04-08`,
];

const colombianHolidays = getHolidays();
export const holidays = [...colombianHolidays, ...getExcludedDays()];
