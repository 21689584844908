/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd';
import { t } from 'i18next';
import { cloneDeep, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { Order } from 'services/order/interface';

interface MultiOrderSelectorProps {
  orderData: Order;
  field: keyof Order;
  changeDrawerOrder: (order: Partial<Order>) => void;
}

const MultiOrderSelector = ({
  field,
  orderData,
  changeDrawerOrder,
}: MultiOrderSelectorProps) => {
  const warrantyOrders = useMemo(() => {
    const warOrds = cloneDeep(orderData.warrantyOrders ?? []);

    warOrds.push({
      id: orderData.id,
      orderNumber: orderData.orderNumber,
      isWarranty: orderData.isWarranty,
    });
    const toReturn = uniqBy(warOrds, 'orderNumber');
    toReturn.sort((a, b) => {
      if (a.isWarranty && !b.isWarranty) return -1;
      if (b.isWarranty && !a.isWarranty) return 1;
      return 0;
    });
    return toReturn;
  }, [orderData]);

  return (
    <Select
      style={{ width: 200, marginTop: 5 }}
      showSearch
      placeholder={t('orderForm.warrantyOrders')}
      optionFilterProp="children"
      onChange={(value) => {
        const changeOrder = (orderData?.[field] as Array<any>)?.find(
          (o) => o.id === value,
        ) as Order;
        changeDrawerOrder({
          ...changeOrder,
          saleOrderId: orderData.saleOrderId,
          warrantyOrders,
        });
      }}
      filterOption={(input: string, option: any) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    >
      {((orderData?.[field] as Array<any>) ?? [])
        .filter((w) => w.id !== orderData.id)
        .map((item) => (
          <Select.Option key={item.id} value={item.id} label={item.orderNumber}>
            {item.isWarranty && <span>⭐️ &nbsp;</span>}
            {item.orderNumber}
          </Select.Option>
        ))}
    </Select>
  );
};

export default MultiOrderSelector;
