/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import Board from 'components/Board/Board';
import { IBoardColumn } from 'components/Board/interface';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import { numberToCurrency } from 'helpers/utils/currency';
import { spanishDateFormat } from 'helpers/utils/dateFormatCol';
import { t } from 'i18next';
import { useMemo } from 'react';
import {
  Order,
  OrderType,
  StatusOrderEnum,
  StatusTagColor,
} from 'services/order/interface';
import OrderTags from '../OrderTags';
import {
  OrderCardClientName,
  OrderCardContainer,
  OrderCardFooterContainer,
  OrderCardGrayParagraph,
  OrderCardHeaderContainer,
  OrderCardInfoContainer,
  OrderCardTotal,
  OrderNumberWrapper,
  OrdersInfoWrapper,
  PackageInfoWrapper,
  TagContainer,
} from './OrderCard.sty';

interface OrderBoardProps {
  columns: IBoardColumn<Order>[];
  ordersBoardNext: Record<StatusOrderEnum, () => Promise<void>>;
  onClick: (order: Order) => void;
  handleDragCard: (
    source: IBoardColumn<Order>,
    destination: IBoardColumn<Order>,
    movedItem: Order,
  ) => void;
}

const OrderCard = ({
  order,
  onClick,
  color,
}: {
  order: Order;
  onClick: () => void;
  color?: string;
}) => {
  const background = useMemo(() => {
    if (order.orderType === OrderType['3P']) return 'rgb(255, 253, 247)';
    if (order.canUpdatePendingStock) return 'rgb(223, 255, 233)';
    if (order.isDropShipper) return 'rgb(242, 243, 255)';
    return undefined;
  }, [order]);

  return (
    <OrderCardContainer
      border={StatusTagColor[order.status]}
      background={background}
      onClick={onClick}
    >
      <OrderCardHeaderContainer>
        <OrderCardInfoContainer>
          <OrderNumberWrapper>
            <CustomTag color={LightTheme.color.gray_dark}>
              {order.orderNumber}
            </CustomTag>
            <CustomTag color={LightTheme.color.kiwi}>
              {order.user.localization?.departmentCode}
            </CustomTag>
          </OrderNumberWrapper>
          <OrderCardClientName>
            {`${order.user?.name ?? ''} ${order.user.firstLastName ?? ''}`}
          </OrderCardClientName>
          <TagContainer>
            <OrderTags order={order} type="header" />
          </TagContainer>
          <OrderCardTotal>
            {`Total: ${numberToCurrency(order?.total ?? 0)}`}
          </OrderCardTotal>
        </OrderCardInfoContainer>
        {order?.package?.id && (
          <PackageInfoWrapper
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Tooltip
              title={t('orderForm.tooltip.package', {
                package: order.package.id,
              })}
              color={`${color}E6`}
            >
              <InfoCircleOutlined
                style={{ fontSize: '20px', color: LightTheme.color.info }}
              />
            </Tooltip>
          </PackageInfoWrapper>
        )}
        {order?.lastOrders?.length && (
          <OrdersInfoWrapper
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Tooltip
              title={t('orderForm.tooltip.lastOrders', {
                orders: order.lastOrders.map((o) => o.orderNumber).join(', '),
              })}
              color={`${color}E6`}
            >
              <InfoCircleOutlined
                style={{ fontSize: '20px', color: LightTheme.color.info }}
              />
            </Tooltip>
          </OrdersInfoWrapper>
        )}
        <CustomTag
          className="status-tag"
          color={StatusTagColor[order?.status || StatusOrderEnum.RECEIVED]}
        >
          {t(`orderStatus.${order?.status}`) ?? `${order?.status}` ?? ''}
        </CustomTag>
      </OrderCardHeaderContainer>
      <OrderCardFooterContainer>
        <p>{t('purchase.deliveryDate')}</p>
        <OrderCardGrayParagraph>
          {spanishDateFormat(order?.delivery?.date ?? '', false)}
        </OrderCardGrayParagraph>
      </OrderCardFooterContainer>
      <OrderCardFooterContainer>
        <TagContainer>
          <OrderTags order={order} type="footer" />
        </TagContainer>
      </OrderCardFooterContainer>
    </OrderCardContainer>
  );
};

const OrderBoard = ({
  columns,
  ordersBoardNext,
  onClick,
  handleDragCard,
}: OrderBoardProps) => {
  return (
    <Board<Order>
      columns={columns}
      setColumns={console.log}
      handleColumnChange={handleDragCard}
      renderCard={(props) => (
        <OrderCard
          order={props.data}
          onClick={() => onClick(props.data)}
          color={props.color}
        />
      )}
      animation={{
        enabled: true,
      }}
      infiniteScrollConfig={{
        loader: <Spin />,
        enabled: true,
        onFetchNextPage: async (column) => {
          const next = ordersBoardNext[column.id as StatusOrderEnum];
          if (next) next();
        },
      }}
    />
  );
};

export default OrderBoard;
