import { ErrorMessageObject } from './development.errors';

export const routesErrors: { [key: string]: ErrorMessageObject } = {
  ROUC400001: {
    translation: 'routesCsvInput.errors.ROUC400001',
    isBlocking: false,
  },
  ROUC400002: {
    translation: 'routesCsvInput.errors.ROUC400002',
    isBlocking: false,
  },
  ROUC400003: {
    translation: 'routesCsvInput.errors.ROUC400003',
    isBlocking: false,
  },
  ROUC500004: {
    translation: 'routesCsvInput.errors.ROUC500004',
    isBlocking: false,
  },
  ROUC400005: {
    translation: 'routesCsvInput.errors.ROUC400005',
    isBlocking: false,
  },
  ROUC400006: {
    translation: 'routesCsvInput.errors.ROUC400006',
    isBlocking: false,
  },
  ROUC400007: {
    translation: 'routesCsvInput.errors.ROUC400007',
    isBlocking: false,
  },
  ROUC400008: {
    translation: 'routesCsvInput.errors.ROUC400008',
    isBlocking: false,
  },
  ROUC400009: {
    translation: 'routesCsvInput.errors.ROUC400009',
    isBlocking: false,
  },
  ROUC400010: {
    translation: 'routesCsvInput.errors.ROUC400010',
    isBlocking: false,
  },
  ROUR500001: {
    translation: 'routesCsvInput.errors.ROUR500001',
    isBlocking: false,
  },
  ROUR400002: {
    translation: 'routesCsvInput.errors.ROUR500002',
    isBlocking: false,
  },
  ROUU400001: {
    translation: 'routesCsvInput.errors.ROUU400001',
    isBlocking: false,
  },
};
