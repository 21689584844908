/* eslint-disable react/prop-types */
import { Spin, Tag } from 'antd';
import Board from 'components/Board/Board';
import { IBoardColumn } from 'components/Board/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import { spanishDateFormat } from 'helpers/utils/dateFormatCol';
import { t } from 'i18next';
import {
  Purchase,
  PurchaseStatus,
  purchaseStatusColors,
} from 'services/purchase/interface';
import {
  PurchaseCardClientName,
  PurchaseCardContainer,
  PurchaseCardFooterContainer,
  PurchaseCardGrayParagraph,
  PurchaseCardHeaderContainer,
  PurchaseCardInfoContainer,
  PurchaseCardTotal,
  PurchaseStatusTag,
  TagContainer,
} from './PurchaseCard.sty';

interface PurchaseBoardProps {
  columns: IBoardColumn<Purchase>[];
  purchasesBoardNext: Record<PurchaseStatus, () => Promise<void>>;
  onClick: (purchase: Purchase) => void;
  handleDragCard: (
    source: IBoardColumn<Purchase>,
    destination: IBoardColumn<Purchase>,
    movedItem: Purchase,
  ) => void;
}

const PurchaseCard = ({
  purchase,
  onClick,
}: {
  purchase: Purchase;
  onClick: () => void;
}) => {
  return (
    <PurchaseCardContainer
      border={purchaseStatusColors[purchase.status]}
      onClick={onClick}
    >
      <PurchaseCardHeaderContainer>
        <PurchaseCardInfoContainer>
          <PurchaseCardGrayParagraph>
            {purchase.purchaseNumber}
          </PurchaseCardGrayParagraph>
          <PurchaseCardClientName>
            {`${purchase.supplier?.name ?? ''}`}
          </PurchaseCardClientName>
          <PurchaseCardTotal>
            {`Total: ${numberToCurrency(purchase?.total ?? 0)}`}
          </PurchaseCardTotal>
        </PurchaseCardInfoContainer>
        <PurchaseStatusTag
          className="status-tag"
          color={
            purchaseStatusColors[purchase?.status || PurchaseStatus.PENDING]
          }
        >
          {t(`purchaseStatus.${purchase?.status}`) ??
            `${purchase?.status}` ??
            ''}
        </PurchaseStatusTag>
      </PurchaseCardHeaderContainer>
      <PurchaseCardFooterContainer>
        <p>{t('purchase.deliveryDate')}</p>
        <PurchaseCardGrayParagraph>
          {spanishDateFormat(purchase?.delivery?.date ?? '', true)}
        </PurchaseCardGrayParagraph>
      </PurchaseCardFooterContainer>
      <PurchaseCardFooterContainer>
        <TagContainer>
          {purchase.metadata.isMarketplace ? (
            <Tag color="green" style={{ marginRight: '0' }}>
              {t('purchase.marketplace')}
            </Tag>
          ) : (
            <Tag color="gold" style={{ marginRight: '0' }}>
              {t('purchase.regular')}
            </Tag>
          )}
        </TagContainer>
      </PurchaseCardFooterContainer>
    </PurchaseCardContainer>
  );
};

const PurchaseBoard = ({
  columns,
  purchasesBoardNext,
  onClick,
  handleDragCard,
}: PurchaseBoardProps) => {
  return (
    <Board<Purchase>
      columns={columns}
      setColumns={console.log}
      handleColumnChange={handleDragCard}
      renderCard={(props) => (
        <PurchaseCard
          purchase={props.data}
          onClick={() => onClick(props.data)}
        />
      )}
      animation={{
        enabled: true,
      }}
      infiniteScrollConfig={{
        loader: <Spin />,
        enabled: true,
        onFetchNextPage: async (column) => {
          const next = purchasesBoardNext[column.id as PurchaseStatus];
          if (next) next();
        },
      }}
    />
  );
};

export default PurchaseBoard;
