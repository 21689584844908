/* eslint-disable no-unsafe-optional-chaining */
import _ from 'lodash';
import { DiscountItem } from 'services/product/interface';

export const validateVolumePrice = (volumePrice: DiscountItem[]) => {
  volumePrice?.sort((x, y) => x.min - y.min);

  if (volumePrice?.length < 2 || volumePrice?.length > 3) {
    return false;
  }

  if (
    Math.min(...volumePrice?.map((x) => x.min)) !== 1 ||
    !volumePrice.map((x) => x.max).some((y) => y === -1)
  ) {
    return false;
  }

  const volPriceCopy = _.cloneDeep(volumePrice);
  volPriceCopy[volPriceCopy.length - 1].max = Infinity;

  for (let idx = 0; idx < volPriceCopy.length; idx += 1) {
    if (
      volPriceCopy[idx].min == null ||
      volPriceCopy[idx].max == null ||
      volPriceCopy[idx].price == null ||
      Number.isNaN(volPriceCopy[idx].price)
    ) {
      return false;
    }

    if (
      volPriceCopy[idx].min > volPriceCopy[idx].max ||
      volPriceCopy?.[idx]?.max >= volPriceCopy?.[idx + 1]?.min ||
      (volPriceCopy[idx]?.max !== Infinity &&
        volPriceCopy?.[idx]?.max + 1 !== volPriceCopy?.[idx + 1]?.min)
    ) {
      return false;
    }

    if (volumePrice[idx]?.price <= volumePrice[idx + 1]?.price) {
      return false;
    }
  }
  return true;
};
