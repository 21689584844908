export enum CartItemStatus {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  LIMITED_STOCK = 'LIMITED_STOCK',
}

export enum CART_ITEM_COLORS {
  IN_STOCK = 'green',
  OUT_OF_STOCK = 'red',
  LIMITED_STOCK = 'orange',
  ACTIVE = 'green',
  INACTIVE = 'red',
  PENDING = '#ff1494',
  IN_TRANSIT = '#527186',
  IN_WAREHOUSE = '#3edc38',
  RESERVED = '#ff9800',
}
