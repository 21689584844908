import { getAPIMethod } from 'helpers/libs/Http';
import { PaymentUpdateStatus } from 'modules/Order/interface';
import { PaginatedResponse } from 'services/global/interface';
import {
  Order,
  OrderBatch,
  OrderFilters,
  OrderStatus,
  OrderTotals,
} from './interface';

export const createOrder = async (order: Order): Promise<Order> => {
  const response = await getAPIMethod({ name: 'createOrder', data: order });
  return response.data;
};

export const getOrderList = async (
  queryParams: OrderFilters,
): Promise<PaginatedResponse<Order>> => {
  const response = await getAPIMethod({
    name: 'getOrderList',
    params: queryParams,
  });
  return response.data;
};

export const getOrderById = async (id: string): Promise<Order> => {
  const response = await getAPIMethod({
    name: 'getOrderById',
    pathParams: {
      orderId: id,
    },
    params: { 'with-detail': true },
  });
  return response.data;
};

export const updateOrder = async ({
  order,
}: {
  order: Partial<Order>;
}): Promise<Order> => {
  const res = await getAPIMethod({
    name: 'updateOrder',
    data: order,
    pathParams: { orderId: order.id ?? '' },
  });
  return res.data;
};

export const updateOrderPayment = async ({
  order,
  paymentStatus,
}: {
  order: Partial<Order>;
  paymentStatus: PaymentUpdateStatus;
}): Promise<Order> => {
  const res = await getAPIMethod({
    name: 'updateOrderPayment',
    data: {
      paymentStatus,
    },
    pathParams: { orderId: order.id ?? '' },
  });
  return res.data;
};

export const updateOrderStatus = async ({
  orderId,
  newStatus,
  observations,
  cancelReason,
  reportMissing,
}: {
  orderId: string;
  newStatus: OrderStatus;
  observations?: string;
  cancelReason?: string;
  reportMissing?: boolean;
}) => {
  return getAPIMethod({
    name: 'updateOrderStatus',
    data: {
      status: newStatus,
      observations,
      cancelReason,
      reportMissing: reportMissing ?? false,
    },
    pathParams: { orderId },
  });
};

export const updateOrderStock = async (id: string) =>
  getAPIMethod({
    name: 'updateOrderStock',
    pathParams: { orderId: id },
  });

export const legalizeOrderBatch = async (orders: Record<string, string>[]) =>
  getAPIMethod({
    name: 'legalizeOrderBatch',
    data: {
      orders,
    },
  });

export const updateOrderStatusBatch = async ({
  orders,
  newStatus,
  cancelReason,
  saleOrderId,
}: {
  orders: OrderBatch[];
  newStatus: OrderStatus;
  cancelReason?: string;
  saleOrderId: string;
}) => {
  const res = await getAPIMethod({
    name: 'updateOrderStatusBatch',
    data: {
      status: newStatus,
      orders,
      cancelReason,
      saleOrderId,
    },
  });

  return res.data;
};

export const computeOrderTotals = async (
  order: Order,
): Promise<OrderTotals> => {
  const res = await getAPIMethod({
    name: 'computeTotals',
    data: order,
  });
  return res.data;
};

export const skipShippingPayment = async (
  orderId: string,
): Promise<{ label: string; value: string }[]> => {
  const { data } = await getAPIMethod({
    name: 'skipShippingPayment',
    data: { id: orderId },
  });

  return data;
};
