import { Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import useNotifications from 'helpers/hooks/useNotifications';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryFormValues } from '../interface';

const useCategoryForm = (
  initialValues: CategoryFormValues,
  onSubmit: (values: CategoryFormValues) => void,
) => {
  const imageUrl = initialValues.imageUrl
    ? `${process.env.REACT_APP_IMAGE_BASE_URL}categories/${initialValues.imageUrl}`
    : '';
  const { t } = useTranslation();
  const customMessage = {
    success: {
      message: 'categoryForm.modal.actions.success',
      description: 'categoryForm.modal.message.success',
    },
    error: {
      message: 'categoryForm.modal.actions.error',
      description: 'categoryForm.modal.message.cancel',
    },
    cancel: {
      message: 'categoryForm.modal.actions.cancel',
      description: 'categoryForm.modal.message.cancel',
    },
  };
  const { notifyCancel } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const [form] = Form.useForm();
  const [displaySelect, setDisplaySelect] = useState(!initialValues.isParent);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const fileListState = useState<UploadFile[]>(
    imageUrl
      ? [{ uid: imageUrl, name: initialValues.imageUrl || '', url: imageUrl }]
      : [],
  );

  const handleFormValuesChange = (changedValues: CategoryFormValues) => {
    if (Object.keys(changedValues).includes('isParent')) {
      setDisplaySelect(!changedValues.isParent);
    }
  };

  const onCheckoutForm = (e: React.MouseEvent) => {
    form
      .validateFields()
      .then((values) => {
        setVisibleConfirmation(true);
      })
      .catch((info) => {
        setVisibleConfirmation(false);
      });
  };

  const onFinish = (values: CategoryFormValues) => {
    onSubmit(values);
    setVisibleConfirmation(false);
  };

  const onConfirm = async () => {
    try {
      form.submit();
    } catch (error) {
      setVisibleConfirmation(false);
      return;
    }
    setVisibleConfirmation(false);
  };

  const onCancel = () => {
    setVisibleConfirmation(false);
    notifyCancel();
  };

  return {
    form,
    displaySelect,
    visibleConfirmation,
    fileListState,
    handleFormValuesChange,
    onCheckoutForm,
    onFinish,
    onConfirm,
    onCancel,
  };
};

export default useCategoryForm;
