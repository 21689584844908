import { Space } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import { numberToCurrency } from 'helpers/utils/currency';
import { spanishDateFormat } from 'helpers/utils/dateFormatCol';
import { t } from 'i18next';
import { Order, StatusTagColor, UserOrders } from 'services/order/interface';
import OrderTags from '../OrderTags';
import {
  ClientInfoParagraph,
  ItemLabel,
  ItemParagraph,
  OrderLocationP,
  OrderNumberTags,
  OrderTagsWrapper,
} from './OrderTable.sty';

export const OrderHiddenDescription = ({ order }: { order: Order }) => (
  <Space direction="vertical">
    <ItemParagraph>
      <ItemLabel>ID:&nbsp;</ItemLabel>
      {order.id}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('orderTableKeys.state')}
        :&nbsp;
      </ItemLabel>
      <CustomTag color={StatusTagColor[order?.status]}>
        {t(`orderStatus.${order?.status}`) || `${order?.status}` || ''}
      </CustomTag>
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('orderTableKeys.deliveryDate')}
        :&nbsp;
      </ItemLabel>
      {spanishDateFormat(order?.delivery?.date ?? '', false)}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('orderTableKeys.client')}
        :&nbsp;
      </ItemLabel>
      {`${order.user.name} ${order.user.firstLastName}`}
      <br />
      {`${order.user.documentType}. ${order.user.documentNumber}`}
      {order.user.userNumber && (
        <>
          <br />
          {`No. ${order.user.userNumber}`}
        </>
      )}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('orderTableKeys.total')}
        :&nbsp;
      </ItemLabel>
      {numberToCurrency(Number(order.total))}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>{t('orderTableKeys.tags')}:&nbsp;</ItemLabel>
      <OrderTagsWrapper>
        <OrderTags order={order} type="all" />
      </OrderTagsWrapper>
    </ItemParagraph>
  </Space>
);

export const OrderTableColumns = () => [
  {
    title: t('orderTableKeys.orderNumber'),
    dataIndex: 'orderNumber',
    width: 200,
    render: (item: string, order: Order) => (
      <>
        <OrderNumberTags>
          <CustomTag color={LightTheme.color.gray_dark}>{item}</CustomTag>
          <CustomTag color="green">
            {order.user?.localization?.departmentCode ?? ''}
          </CustomTag>
        </OrderNumberTags>
        <OrderLocationP>
          {`${order.user?.localization?.cityName ?? ''}, ${
            order.user?.localization?.departmentName ?? ''
          }`}
        </OrderLocationP>
      </>
    ),
  },
  {
    title: t('orderTableKeys.client'),
    dataIndex: 'user',
    width: 150,
    render: (orderUser: UserOrders) => (
      <ClientInfoParagraph>
        {orderUser?.name || 'NN'}
        &nbsp;
        {orderUser?.firstLastName ?? ''}
      </ClientInfoParagraph>
    ),
  },
  {
    title: t('orderTableKeys.total'),
    dataIndex: 'total',
    width: 130,
    render: (total: string) => numberToCurrency(Number(total)),
  },
  {
    title: t('orderTableKeys.deliveryDate'),
    dataIndex: ['delivery', 'date'],
    width: 150,
    render: (date: string) => spanishDateFormat(date ?? '', false),
  },
  {
    title: t('orderTableKeys.state'),
    width: 140,
    render: (order: Order) => (
      <CustomTag color={StatusTagColor[order?.status]}>
        {t(`orderStatus.${order?.status}`) || `${order?.status}` || ''}
      </CustomTag>
    ),
  },
  {
    title: t('orderTableKeys.tags'),
    width: '200px',
    render: (order: Order) => (
      <OrderTagsWrapper>
        <OrderTags order={order} type="all" />
      </OrderTagsWrapper>
    ),
  },
];
