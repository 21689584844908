import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const Title = styled.h1`
  padding: ${spaceSizes.l} 0 0 ${spaceSizes.xxl};
  margin-bottom: 0;
  color: ${LightTheme.color.dark_alt};
  font-weight: bold;
`;

export const StatusFlag = styled.div<{ color: string }>`
  width: ${spaceSizes.xl};
  height: ${spaceSizes.xl};
  border-radius: 50%;
  background: ${({ color }) => `${color}`};
  margin-right: ${LightTheme.spaceSizes.sm};
`;

export const StatusItemContainer = styled.div`
  display: flex;
  max-width: 200px;
  flex-direction: column;
  gap: ${LightTheme.spaceSizes.s};
  margin-bottom: ${LightTheme.spaceSizes.xl3};
  padding: 0 ${spaceSizes.xxl};
`;

export const IconImg = styled.img`
  width: ${spaceSizes.xl5};
  height: ${spaceSizes.xl5};
  border-radius: 50%;
  object-fit: cover;
  margin: ${spaceSizes.l} ${spaceSizes.xxl} 0 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BalanceInFavor = styled.p`
  font-weight: bold;
  margin: ${spaceSizes.xxl} ${spaceSizes.xxl} 0 ${spaceSizes.xxl};
`;
