export const LABEL_COL = {
  xl: { span: 6 },
  lg: { span: 8 },
  sm: { span: 11 },
};

export const LABEL_COL_LEFT = {
  xl: { span: 8 },
  lg: { span: 10 },
  sm: { span: 13 },
};

export const WRAPPER_COL = {
  xl: { span: 18, offset: 2 },
  lg: { span: 16, offset: 1 },
  sm: { span: 13, offset: 0 },
};

export const WRAPPER_COL_LEFT = {
  xl: { span: 16, offset: 2 },
  lg: { span: 14, offset: 1 },
  sm: { span: 11, offset: 0 },
};
