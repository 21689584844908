import { TFunction } from 'i18next';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { UserRoleEnum } from 'services/user/interface';
import { DEFAULT_PAYMENT_UPDATE_OPTIONS } from '../constants/orderForm';
import { allowedUpdatesForRole } from '../constants/orderStatusMachine';
import { ORDER_ACTIONS_ENUM } from '../interface';

export function GetPaymentUpdateOptions(t: TFunction): LabelValue<string>[] {
  return DEFAULT_PAYMENT_UPDATE_OPTIONS.map((opt) => ({
    label: t(`orderForm.labels.bankDeposit.${opt}`),
    value: opt,
  }));
}

export function UserRoleHasAccessTo(
  roles: UserRoleEnum[],
  access: ORDER_ACTIONS_ENUM,
) {
  const rolesAllows = roles.map((role) => allowedUpdatesForRole[role]);
  const validAllows = rolesAllows.filter((r) => !!r);
  const allPermissions = validAllows.flat();

  return allPermissions.includes(access);
}
