/* eslint-disable spaced-comment */
import { BrandsQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { Brand } from './interface/index';

export const updateBrands = async (brand: Partial<Brand>) => {
  const response = await getAPIMethod({
    name: 'updateBrand',
    data: brand,
    pathParams: { brandId: brand?.id || '' },
  });
  return response.data;
};

export const createBrands = async (brand: Partial<Brand>) => {
  const response = await getAPIMethod({
    name: 'createBrand',
    data: brand,
  });
  return response.data;
};

export const deleteBrands = async (brand: string) => {
  const response = await getAPIMethod({
    name: 'deleteBrandById',
    pathParams: { brandId: brand || '' },
  });
  return response.data;
};

export const getBrandsList = async (
  queryParams?: BrandsQueryParams,
): Promise<PaginatedResponse<Brand>> => {
  const response = await getAPIMethod({
    name: 'getBrandList',
    params: {
      search: queryParams?.search,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export default {
  create: createBrands,
  read: getBrandsList,
  update: updateBrands,
  delete: deleteBrands,
  readByParam: getBrandsList,
};
