import {
  PendingStockVariant,
  PurchaseVariant,
} from 'services/purchase/interface';

export const pendingStockVariant2purchaseVariant = (
  variant: PendingStockVariant,
): PurchaseVariant => ({
  id: variant.id,
  variant: {
    variantSku: variant.variantSku,
    name: variant.name,
    urlName: variant.urlName,
    cost: variant.cost,
    productId: variant.productId,
    images: variant.images,
  },
  orders: [],
  values: {
    estimatedCogsVat: variant.cost ?? 0,
    estimatedCogs: variant.cost ?? 0,
    taxType: 'PERCENTAGE',
    tax: 0,
    orderedQuantity: variant.quantity,
  },
});
