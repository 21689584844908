import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';

export const InputContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadDragger = styled(Dragger)`
  padding: 0 15px;
`;
