/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryFormValues } from 'containers/Categories/CategoryForm/interface';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Category } from 'services/category/interface';

const useUpdateCategory = (
  initialValues: CategoryFormValues,
  getCategoryByUrlName: (urlName: string) => void,
  defaultCategory: Category,
) => {
  const { id } = useParams();
  const categoryState = useState<CategoryFormValues>(initialValues);
  const loadingState = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryByUrlName(id || '');
  }, [getCategoryByUrlName, id]);

  useEffect(() => {
    if (defaultCategory) {
      if (defaultCategory.id) {
        categoryState[1]({
          name: defaultCategory.name,
          parentId: defaultCategory.parentId,
          description: defaultCategory.description,
          isParent: !defaultCategory.parentId,
          urlName: defaultCategory.urlName,
          industry: defaultCategory.industry,
          imageUrl: defaultCategory.imageUrl,
          landingSubcategory: defaultCategory.landingSubcategory,
        });
        loadingState[1](false);
      }
    }
  }, [defaultCategory]);

  return {
    categoryState,
    loadingState,
    navigate,
  };
};

export default useUpdateCategory;
