/* eslint-disable react/require-default-props */
import { Spin } from 'antd';
import { CollapsibleContainer } from 'components/CollapsibleContainer';
import { numberToCurrency } from 'helpers/utils/currency';
import { useTranslation } from 'react-i18next';
import { OrderTotals } from 'services/order/interface';
import { DiscountSpan, SubtotalSpan, TotalSpan } from './OrderTotalDisplay.sty';

const OrderTotalDisplay = ({
  totals,
  loadingTotals,
}: {
  totals?: OrderTotals;
  loadingTotals: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <CollapsibleContainer>
      <TotalSpan>
        {`${t('orderTableKeys.total')}: `}
        {loadingTotals ? (
          <Spin size="small" />
        ) : (
          `${numberToCurrency(totals?.total ?? 0)}`
        )}
      </TotalSpan>
      <SubtotalSpan>
        {`${t('orderTableKeys.subtotal')}: `}
        {loadingTotals ? (
          <Spin size="small" />
        ) : (
          `${numberToCurrency(totals?.subtotal ?? 0)}`
        )}
      </SubtotalSpan>
      <SubtotalSpan>
        {`${t('orderTableKeys.shipping')}: `}
        {loadingTotals ? (
          <Spin size="small" />
        ) : (
          `${numberToCurrency(totals?.shipping ?? 0)}`
        )}
      </SubtotalSpan>
      <SubtotalSpan>
        {`${t('orderTableKeys.serviceFee')}: `}
        {loadingTotals ? (
          <Spin size="small" />
        ) : (
          `${numberToCurrency(totals?.serviceFee ?? 0)}`
        )}
      </SubtotalSpan>
      <SubtotalSpan>
        {`${t('orderTableKeys.earnCost')}: `}
        {loadingTotals ? (
          <Spin size="small" />
        ) : (
          `${numberToCurrency(totals?.earnCost ?? 0)}`
        )}
      </SubtotalSpan>
      {(totals?.creditFee ?? 0) > 0 && (
        <SubtotalSpan>
          {`${t('orderTableKeys.creditLine')}
                    : ${numberToCurrency(totals?.creditFee ?? 0)}
                    `}
        </SubtotalSpan>
      )}
      <DiscountSpan>
        {`${t('orderTableKeys.discount')}
                    : `}
        <span>-{numberToCurrency(totals?.productDiscount ?? 0)}</span>
      </DiscountSpan>
      <DiscountSpan>
        {`${t('orderTableKeys.otherDiscount')}
                    : `}
        <span>-{numberToCurrency(totals?.otherDiscount ?? 0)}</span>
      </DiscountSpan>
      {totals?.coupon ? (
        <DiscountSpan>
          {`${t('orderTableKeys.couponDiscount')}: `}
          {loadingTotals ? (
            <Spin size="small" />
          ) : (
            <span>-{numberToCurrency(totals?.coupon ?? 0)}</span>
          )}
        </DiscountSpan>
      ) : null}
      {(totals?.payment?.pandabonos?.amount ?? 0) > 0 && (
        <DiscountSpan>
          {`${t('orderTableKeys.pandabonos')}: `}
          {loadingTotals ? (
            <Spin size="small" />
          ) : (
            <span>
              -{numberToCurrency(totals?.payment?.pandabonos?.amount ?? 0)}
            </span>
          )}
        </DiscountSpan>
      )}
    </CollapsibleContainer>
  );
};

export default OrderTotalDisplay;
