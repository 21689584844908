import { AutoComplete, Input } from 'antd';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { useTranslation } from 'react-i18next';
import { Variant } from 'services/product/interface';
import useVariantSearchInput from './hooks/useVariantSearchInput';
import { VariantSearchInputProps } from './interface';

const { Search } = Input;

const VariantsAutocomplete = ({
  value = {} as Variant,
  onChange,
  disabled,
  mpsId,
  logisticType,
}: VariantSearchInputProps) => {
  const {
    readQuery: {
      mutateAsync: getVariantsAsync,
      isLoading: isLoadingAutocomplete,
    },
  } = useModuleCRUD('variant');

  const {
    searchKeyState: [searchKey, setSearchKey],
    autocompleteResults,
    variantOptions,
    handleSearchVariants,
  } = useVariantSearchInput(value, getVariantsAsync, mpsId, logisticType);
  const { t } = useTranslation();

  const triggerChange = (changedValue: Variant) => {
    onChange?.({
      ...value,
      ...changedValue,
    });
  };

  const handleSelectAutocomplete = (inputValue: string) => {
    const variantToAdd = autocompleteResults.find(
      (result) => result.id === inputValue,
    );
    triggerChange(variantToAdd as Variant);
    setSearchKey(
      variantToAdd?.variantSku && variantToAdd?.name
        ? `${variantToAdd.name}, ${variantToAdd.variantSku}`
        : '',
    );
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      options={variantOptions}
      onSelect={handleSelectAutocomplete}
      onSearch={handleSearchVariants}
      value={searchKey}
      disabled={disabled}
    >
      <Search
        size="large"
        placeholder={t('purchaseAutocomplete.placeholder')}
        loading={isLoadingAutocomplete}
      />
    </AutoComplete>
  );
};

export default VariantsAutocomplete;
