import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { mpsRequestStatusEnum, MPSUSERRequest } from './interface';

export const getMpsUserRequest = async (
  queryParams: PaginationParams & { status?: mpsRequestStatusEnum },
): Promise<PaginatedResponse<MPSUSERRequest>> => {
  const response = await getAPIMethod({
    name: 'getMpsUserRequest',
    params: {
      page: queryParams.page,
      pageSize: queryParams.pageSize,
      status: queryParams.status,
    },
  });

  return response.data;
};

export const updateMpsUserRequest = async (mpsUserRequest: MPSUSERRequest) => {
  const response = await getAPIMethod({
    name: 'updateMpsUserRequest',
    data: mpsUserRequest,
  });
  return response.data;
};
