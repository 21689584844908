/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { Col, Form, Input, Row, Select, Space } from 'antd';
import editIcon from 'assets/images/editIcon.svg';
import { emailRegex, licensePlateRegex } from 'helpers/constants/regex';
import { phoneValidator, stringValidator } from 'helpers/utils/formValidators';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocType, DocTypesObj } from 'services/user/interface';
import {
  DetailTitle,
  HaulierStatusColor,
  Header,
  IconImg,
  StatusFlag,
  StatusItemContainer,
  Title,
} from './HaulierForm.sty';
import { HaulierFormContainer } from './HaulierFormContainer';
import useHaulierForm from './hooks/useHaulierForm';
import {
  HaulierFormInitialValues,
  HaulierFormProps,
  HaulierModalAction,
  HaulierStatusEnum,
} from './interface';

const HaulierFrom = ({
  setFormReference,
  initialValues = HaulierFormInitialValues,
  actionType,
  closeModal,
  reLoadOnSuccess,
}: HaulierFormProps) => {
  const { Option } = Select;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { submitForm, handleStatusChange, haulierStatus, carTypes, company } =
    useHaulierForm(
      initialValues,
      actionType,
      initialValues._id,
      closeModal,
      reLoadOnSuccess,
    );

  useEffect(() => {
    if (setFormReference) {
      setFormReference(form);
    }
  }, []);

  const LABEL_COL = {
    xl: { span: 6 },
    lg: { span: 8 },
    sm: { span: 11 },
  };
  const WRAPPER_COL = {
    xl: { span: 18, offset: 2 },
    lg: { span: 16, offset: 1 },
    sm: { span: 13, offset: 0 },
  };

  return (
    <Form
      scrollToFirstError
      onFinish={submitForm}
      initialValues={initialValues}
      form={form}
    >
      <Header>
        <Title>{t(`haulierForm.titles.${actionType?.toLowerCase()}`)}</Title>
        {actionType === HaulierModalAction.UPDATE && (
          <IconImg alt="Update logo" src={editIcon} />
        )}
      </Header>

      <StatusItemContainer>
        <Select value={haulierStatus} onChange={handleStatusChange}>
          <Option
            value={HaulierStatusEnum.ACTIVE}
            key={HaulierStatusEnum.ACTIVE}
          >
            <Space direction="horizontal">
              <StatusFlag
                color={HaulierStatusColor[HaulierStatusEnum.ACTIVE]}
              />
              {t('haulierForm.labels.status.active')}
            </Space>
          </Option>
          <Option
            value={HaulierStatusEnum.INACTIVE}
            key={HaulierStatusEnum.INACTIVE}
          >
            <Space direction="horizontal">
              <StatusFlag
                color={HaulierStatusColor[HaulierStatusEnum.INACTIVE]}
              />
              {t('haulierForm.labels.status.inactive')}
            </Space>
          </Option>
        </Select>
      </StatusItemContainer>

      <HaulierFormContainer haulierNumber={initialValues?.haulierNumber}>
        <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
          <Col span={24}>
            <DetailTitle>
              <span>{t('haulierForm.titles.detail')}</span>
            </DetailTitle>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name="name"
              label={t('haulierForm.labels.name')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(value, { minLength: 1, required: true }, t),
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              required
              name="phone"
              label={t('haulierForm.labels.phone')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    phoneValidator(
                      value,
                      { minLength: 7, maxLength: 10, required: true },
                      t,
                    ),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              required
              name="email"
              label={t('haulierForm.labels.email')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(
                      value,
                      { minLength: 1, required: true, regexp: emailRegex },
                      t,
                    ),
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required
              name="docType"
              label={t('haulierForm.labels.docType')}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(value, { minLength: 1, required: true }, t),
                },
              ]}
            >
              <Select>
                {(Object.keys(DocTypesObj) as DocType[]).map((doc: DocType) => (
                  <Option key={doc} value={doc}>
                    {t(DocTypesObj[doc])}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="docNumber"
              label={t('haulierForm.labels.docNumber')}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(
                      value,
                      { minLength: 8, maxLength: 11, required: true },
                      t,
                    ),
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
          <Col span={24}>
            <DetailTitle>
              <span>{t('haulierForm.titles.carDetail')}</span>
            </DetailTitle>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name="carType"
              label={t('haulierForm.labels.carType')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(value, { minLength: 1, required: true }, t),
                },
              ]}
            >
              <Select>
                {(carTypes ?? []).map((carType) => (
                  <Option key={carType} value={carType}>
                    {carType}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="licensePlate"
              label={t('haulierForm.labels.licensePlate')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(
                      value,
                      { regexp: licensePlateRegex, required: true },
                      t,
                      {
                        badRegex: t('haulierForm.validation.licensePlate'),
                      },
                    ),
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
          <Col span={24}>
            <DetailTitle>
              <span>{t('haulierForm.titles.company')}</span>
            </DetailTitle>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              name="company"
              label={t('haulierForm.labels.companyName')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    stringValidator(value, { minLength: 1, required: true }, t),
                },
              ]}
            >
              <Select>
                {(company ?? []).map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </HaulierFormContainer>
    </Form>
  );
};
export default HaulierFrom;
