import styled from 'styled-components';

export const ErrorViewContainer = styled.div`
  overflow: scroll;
  height: 180px;
  gap: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
`;
