import { TransportContainer } from 'containers/Transport/Transport';
import { TransportProvider } from 'context/TransportContext/TransportContext';

export const TransportPage = () => {
  return (
    <TransportProvider>
      <TransportContainer />
    </TransportProvider>
  );
};
