/* eslint-disable react/require-default-props */
import { InboxOutlined } from '@ant-design/icons';
import { Progress, Result } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { InputContainer } from 'components/CsvInput/CsvInput.sty';
import { t } from 'i18next';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useMemo } from 'react';
import { LayoutContainer } from './InvoiceReports.sty';

interface InvoiceReportsUploadProps {
  status: 'active' | 'success' | 'exception' | 'normal';
  beforeUpload: (fileUpload: RcFile) => boolean;
  handleRequest: (opts: UploadRequestOption) => Promise<void>;
}
const InvoiceReportsUpload = ({
  status,
  beforeUpload,
  handleRequest,
}: InvoiceReportsUploadProps) => {
  const percent = useMemo(() => {
    if (status === 'success') return 100;
    if (status === 'exception') return 70;
    if (status === 'active') return 20;
    return 0;
  }, [status]);
  const resultStatus = useMemo(() => {
    if (status === 'success') return status;
    if (status === 'exception') return 'error';
    return 'info';
  }, [status]);
  return (
    <LayoutContainer>
      <InputContainer>
        <Result
          status={resultStatus}
          title={t('Reporte de facturación')}
          extra={
            <>
              <Dragger
                name="file"
                accept=".xlsx"
                style={{ padding: '0 15px' }}
                beforeUpload={beforeUpload}
                customRequest={handleRequest}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('invoiceReportsUpload.inputText')}
                </p>
                <p className="ant-upload-hint">
                  {t('invoiceReportsUpload.csvInputHint')}
                </p>
              </Dragger>
              <Progress status={status} percent={percent} />
            </>
          }
        />
      </InputContainer>
    </LayoutContainer>
  );
};

export default InvoiceReportsUpload;
