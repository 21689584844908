/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
import { RolesActions } from 'config/roles/rolesActions';
import { getAuth } from 'firebase/auth';
import _ from 'lodash';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { ReactNode, useMemo } from 'react';
import {
  ActionType,
  FirebaseUser,
  Role,
  RoleEditOptions,
  Scope,
} from './interface';

const LOCAL_SCOPE: Scope = 'BACKOFFICE';

export const hasAccess = (moduleName: string, roles?: Role[]) => {
  const action: ActionType = get(RolesActions, moduleName.split('.'));
  if (!action) {
    console.error("Role action doesn't exist:", moduleName);
    return false;
  }
  try {
    const auth = getAuth();
    const firebaseUser: FirebaseUser = auth.currentUser as FirebaseUser;
    if (firebaseUser) {
      const rolesString = JSON.parse(
        firebaseUser?.reloadUserInfo?.customAttributes || '{}',
      ).role;
      const rolesFromToken =
        roles || (rolesString ? (rolesString.split('|') as Role[]) : []);
      const hasRoleAccess =
        intersection(action.roles, rolesFromToken).length > 0;
      const hasScopeAccess = action?.scope?.includes(LOCAL_SCOPE);
      return hasRoleAccess && hasScopeAccess;
    }
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const roleEditOptions = () => {
  try {
    const auth = getAuth();
    const firebaseUser: FirebaseUser = auth.currentUser as FirebaseUser;
    if (firebaseUser) {
      const rolesString = JSON.parse(
        firebaseUser?.reloadUserInfo?.customAttributes || '{}',
      ).role;
      const rolesFromToken = rolesString
        ? (rolesString.split('|') as Role[])
        : [];
      const rawOptions = rolesFromToken.map(
        (role: Role) => RoleEditOptions[role],
      );
      return _.union.apply(null, rawOptions);
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const AccessWrapper: React.FC<{
  module: string;
  fallback?: ReactNode;
}> = ({ children, module, fallback }) => {
  const hasRoleAccess = useMemo(() => hasAccess(module), [module]);

  if (hasRoleAccess) {
    return <>{children}</>;
  }
  return <>{fallback || ''}</>;
};

const useRoleAccess = (moduleName: string) => ({
  AccessWrapper: ({
    children,
    fallback,
  }: {
    children: React.ReactNode;
    fallback?: ReactNode;
  }) => (
    <AccessWrapper module={moduleName} fallback={fallback}>
      {children}
    </AccessWrapper>
  ),
  hasAccess: hasAccess(moduleName),
  roleEditOptions: roleEditOptions(),
});

export default useRoleAccess;
