import { UserFormValues } from 'containers/Users/UserForm/interface';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import { Location, RawStaffUser, User } from 'services/user/interface';

const buildUserFromParams = (values: UserFormValues, prevUser?: User): User => {
  const userData = {
    ...prevUser,
    role: values.role,
    mainPhone: `+57${values.phoneNumber}`,
    mainEmail: values.email,
    status: values.status,
    info: {
      name: values.name,
      lastName: values.lastName,
      secondLastName: values.secondLastName ?? prevUser?.info.secondLastName,
      email: values.email,
      phoneNumber: `+57${values.phoneNumber}`,
      docType: values.docType,
      documentNumber: String(values.documentNumber),
      locations: values.locations?.map((location: Location) => ({
        ...location,
        latitude: String(location?.latitude ?? 0),
        longitude: String(location?.longitude ?? 0),
      })),
    },
  };

  if (
    (values.role?.includes(RoleEnum.MERCHANT) ||
      (!values.role && prevUser?.role?.includes(RoleEnum.MERCHANT))) &&
    values.parentStaffUser?.id
  ) {
    const seller: RawStaffUser = {
      id: values.parentStaffUser?.id || '',
      sellerCode: values.parentStaffUser?.sellerCode ?? '',
      info: {
        docType: values.parentStaffUser?.info?.docType,
        documentNumber: String(values.parentStaffUser?.info?.documentNumber),
        email: values.parentStaffUser?.info?.email,
        lastName: values.parentStaffUser?.info?.lastName,
        name: values.parentStaffUser?.info?.name,
        phoneNumber: String(values.parentStaffUser?.info?.phoneNumber),
      },
    };
    const sellerCoordinator: RawStaffUser = {
      id:
        values.parentStaffUser?.sellerCoordinator?.id ??
        prevUser?.sellerCoordinator?.id ??
        '',
      sellerCode:
        values.parentStaffUser?.sellerCoordinator?.sellerCode ??
        prevUser?.sellerCoordinator?.sellerCode ??
        '',
      info: {
        docType:
          values.parentStaffUser?.sellerCoordinator?.info.docType ??
          prevUser?.sellerCoordinator?.info.docType ??
          '',
        documentNumber: String(
          values.parentStaffUser?.sellerCoordinator?.info.documentNumber ??
            prevUser?.sellerCoordinator?.info.documentNumber,
        ),
        lastName:
          values.parentStaffUser?.sellerCoordinator?.info.lastName ??
          prevUser?.sellerCoordinator?.info.lastName,
        name:
          values.parentStaffUser?.sellerCoordinator?.info.name ??
          prevUser?.sellerCoordinator?.info.name,
        phoneNumber: String(
          values.parentStaffUser?.sellerCoordinator?.info.phoneNumber ??
            prevUser?.sellerCoordinator?.info.phoneNumber,
        ),
      },
    };
    userData.seller = seller;
    userData.sellerCoordinator = sellerCoordinator;
  } else if (
    (values.role?.includes(RoleEnum.SELLER) ||
      (!values.role && prevUser?.role?.includes(RoleEnum.SELLER))) &&
    values.parentStaffUser?.id
  ) {
    const sellerCoordinator: RawStaffUser = {
      id: values.parentStaffUser?.id ?? prevUser?.sellerCoordinator?.id ?? '',
      sellerCode:
        values.parentStaffUser?.sellerCode ??
        prevUser?.sellerCoordinator?.sellerCode ??
        '',
      info: {
        docType:
          values.parentStaffUser?.info?.docType ??
          prevUser?.sellerCoordinator?.info?.docType ??
          '',
        documentNumber: String(
          values.parentStaffUser?.info?.documentNumber ??
            prevUser?.sellerCoordinator?.info?.documentNumber ??
            '',
        ),
        lastName:
          values.parentStaffUser?.info?.lastName ??
          prevUser?.sellerCoordinator?.info?.lastName ??
          '',
        name:
          values.parentStaffUser?.info?.name ??
          prevUser?.sellerCoordinator?.info?.name ??
          '',
        phoneNumber: String(
          values.parentStaffUser?.info?.phoneNumber ??
            prevUser?.sellerCoordinator?.info?.phoneNumber ??
            '',
        ),
      },
    };
    userData.sellerCoordinator = sellerCoordinator;
  }
  return userData;
};

export default buildUserFromParams;
