/* eslint-disable default-param-last */
/* eslint-disable no-underscore-dangle */
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import debounce from 'lodash/debounce';
import { prop, uniqBy } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Location } from 'services/location/interface';

const useLocationsAutocomplete = (
  searchParam: string,
  isLeaf: boolean,
  pagination: PaginationParams = { page: 1, pageSize: 999 },
  emptyResultsMsg?: string,
  handleSelect?: (value: Location | undefined) => void,
  additionalOptions?: Location[],
) => {
  // States
  const [locationsList, setLocationsList] = useState<Location[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [search, setSearch] = useState(searchParam);
  const [searchValue, setSearchValue] = useState(searchParam);

  useEffect(() => {
    setSearch(searchParam);
    setSearchValue(searchParam);
  }, [searchParam]);

  const searchLocation = useCallback((searchKey: string) => {
    setSearch(searchKey);
  }, []);

  const debouncedSearch = useMemo(
    () => debounce(searchLocation, 700),
    [searchLocation],
  );

  const handleSearchLocation = (searchKey: string) => {
    setSearchValue(searchKey);
    debouncedSearch(searchKey);
  };

  const onClear = () => {
    setSearch('');
  };
  const {
    readQuery: {
      mutateAsync: getLocationsAsync,
      isLoading: isLoadingLocations,
    },
  } = useModuleCRUD('location');

  const showWarningMsg = useMemo(
    () => !isLoadingLocations && !locationsList.length,
    [isLoadingLocations, locationsList.length],
  );

  const warningMsg = useMemo(() => {
    if (!isLoadingLocations && !locationsList.length) {
      return emptyResultsMsg;
    }

    return '';
  }, [emptyResultsMsg, isLoadingLocations, locationsList.length]);

  const selectOptions = useMemo(() => {
    const rawList = locationsList;
    if (additionalOptions) {
      rawList.unshift(...additionalOptions);
    }

    const iterableList = uniqBy(prop('_id'), rawList);

    return iterableList.map((locations) => ({
      value: locations._id,
      label: locations.name,
    }));
  }, [locationsList, additionalOptions]);

  const fetchLocations = useCallback(async () => {
    const locationsFetched = await getLocationsAsync({
      search,
      isLeaf,
      page: pagination.page + 1,
      pageSize: pagination.pageSize,
    });
    setLocationsList(locationsFetched.data);
  }, [getLocationsAsync, isLeaf, pagination.page, pagination.pageSize, search]);

  const handleSelectAutoComplete = (value: string) => {
    if (handleSelect) {
      const foundValue = locationsList.find((loc) => loc._id === value);
      setSearchValue(foundValue?.name ?? value);
      handleSelect(foundValue);
    }
  };

  // Effects
  useEffect(() => {
    if (search) {
      fetchLocations();
    } else {
      setLocationsList([]);
      setSelectedLocations([]);
    }
  }, [fetchLocations, search]);

  return {
    search: searchValue,
    isLoadingLocations,
    selectedLocations,
    selectOptions,
    showWarningMsg,
    warningMsg,
    handleSearchLocation,
    onClear,
    handleSelectAutoComplete,
  };
};
export default useLocationsAutocomplete;
