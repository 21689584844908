import { useMutation, useQuery } from '@tanstack/react-query';
import { isNotNil } from 'ramda';
import { useEffect } from 'react';
import {
  getBanners,
  updateBanners,
} from 'services/parameters/parameters.service';
import { bannersQueryKey } from '../constants';
import { BannersEventType, useBannersApiProps } from '../interface';

const useBannersApi = ({ state, dispatch }: useBannersApiProps) => {
  const { banners } = state;
  const { data, isLoading, refetch } = useQuery({
    queryFn: getBanners,
    queryKey: [bannersQueryKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
  });

  const { mutateAsync: updateBannersAsync, isLoading: updateLoading } =
    useMutation({
      mutationFn: updateBanners,
    });

  useEffect(() => {
    if (!isLoading && data?.banners?.data?.length) {
      dispatch({
        type: BannersEventType.SET_BANNERS,
        payload: data.banners.data,
      });
    }
  }, [data, isLoading, dispatch]);

  useEffect(() => {
    if (isNotNil(banners)) {
      dispatch({
        payload: banners,
        type: BannersEventType.SET_DRAFT_BANNERS,
      });
    }
  }, [banners, dispatch]);

  return {
    updateBannersAsync,
    updateLoading,
    data,
    isLoading,
    refetch,
  };
};

export default useBannersApi;
