/* eslint-disable no-underscore-dangle */
import { Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import LoaderWrapper from 'components/LoadingWrapper';
import { BaseColors } from 'helpers/constants/theme';
import useNotifications from 'helpers/hooks/useNotifications';
import { Title } from 'modules/WMS/Settings/components/SettingList.sty';
import moment from 'moment';
import 'moment/locale/es';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ShippingZonesWrapper,
  UpdateShippingZoneHeader,
  UpdateShippingZoneSection,
} from '../components/containers.sty';
import ShippingZoneForm from '../components/ShippingZoneForm/ShippingZoneForm';
import { useShippingZoneUpdate } from '../context/SettingUpdate.context';
import {
  shippingZoneFormToEntity,
  shippingZoneToFormInitial,
} from '../helpers';
import useUpdateShippingZoneService from '../hooks/useUpdateShippingZone';
import { IShippingZoneForm } from '../interface';
import { SHIPPING_ZONE_UPDATE_ACTION_ENUM } from '../reducer/interface';

moment.locale('es');
const UpdateShippingZoneContainer = () => {
  const { id: shippingZoneId } = useParams();
  const { state, dispatch } = useShippingZoneUpdate();
  const { t } = useTranslation();
  const [form] = useForm<IShippingZoneForm>();
  const { notifySuccess } = useNotifications({ translationFunction: t });

  const {
    shippingZone,
    isLoading,
    updateShippingZone,
    updateLoading,
    refetchShippingZone,
    getHolyDaysByRegion,
    onSearchCityCode,
    loadingStates,
  } = useUpdateShippingZoneService({
    id: shippingZoneId ?? '',
    dispatch,
  });

  const onTurnEditionMode = useCallback(() => {
    form.setFieldsValue(shippingZoneToFormInitial(shippingZone));
    dispatch({
      payload: !state.editionMode,
      type: SHIPPING_ZONE_UPDATE_ACTION_ENUM.TURN_EDITION_MODE,
    });
  }, [form, dispatch, state, shippingZone]);

  const onUpdateShippingZone = useCallback(async () => {
    const values = form.getFieldsValue();

    if (!shippingZone) return;

    const updateToSend = shippingZoneFormToEntity(
      values,
      shippingZone,
      state.transportList,
      state.regionList,
    );

    await updateShippingZone({
      id: shippingZone._id,
      payload: updateToSend,
    });

    await refetchShippingZone();
    notifySuccess();
    onTurnEditionMode();
  }, [
    form,
    state,
    shippingZone,
    updateShippingZone,
    refetchShippingZone,
    onTurnEditionMode,
    notifySuccess,
  ]);

  useEffect(() => {
    form.setFieldsValue(shippingZoneToFormInitial(shippingZone));
  }, [form, shippingZone]);

  return (
    <ShippingZonesWrapper>
      <LoaderWrapper loading={isLoading || updateLoading}>
        <UpdateShippingZoneHeader>
          <Title color={BaseColors.deep_cove}>
            {t('shippingZone.form.titles.update')}
          </Title>
          <UpdateShippingZoneSection>
            {state.editionMode && (
              <Button
                type="primary"
                onClick={form.submit}
                loading={updateLoading}
              >
                {t(`shippingZone.form.buttons.save`)}
              </Button>
            )}
            <Button
              type="primary"
              onClick={onTurnEditionMode}
              disabled={updateLoading}
            >
              {t(`shippingZone.form.buttons.editionMode.${state.editionMode}`)}
            </Button>
          </UpdateShippingZoneSection>
        </UpdateShippingZoneHeader>
        <ShippingZoneForm
          form={form}
          initialValues={state.shippingZone}
          citiesOptionList={state.citiesOptionList}
          regionOptionList={state.regionOptionList}
          transportOptionList={state.transportOptionList}
          disabled={!state.editionMode}
          onSubmit={onUpdateShippingZone}
          fetchRegionalConfig={getHolyDaysByRegion}
          searchCityCode={onSearchCityCode}
          loadingStates={loadingStates}
        />
      </LoaderWrapper>
    </ShippingZonesWrapper>
  );
};

export default UpdateShippingZoneContainer;
