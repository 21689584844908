import { cartAtom } from 'helpers/recoil/atoms/cart';
import { useRecoilState } from 'recoil';
import { CartVariant } from 'services/product/interface';

export const useAddProduct = () => {
  const [variantsAdded, setVariantsAdded] = useRecoilState(cartAtom);

  const onChangeQuantity = (variant: CartVariant, cartQuantity: number) => {
    if (!variantsAdded[variant.id]) {
      setVariantsAdded({
        ...variantsAdded,
        [variant.id]: { ...variant, cartQuantity },
      });

      return;
    }

    if (!cartQuantity) {
      setVariantsAdded((prev) => {
        const prevCart = { ...prev };
        delete prevCart[variant.id];
        return { ...prevCart };
      });
      return;
    }

    setVariantsAdded((prev) => ({
      ...prev,
      [variant.id]: {
        ...prev[variant.id],
        cartQuantity: Math.min(variant.stock?.available ?? 0, cartQuantity),
      },
    }));
  };

  return { variantsAdded, onChangeQuantity, setVariantsAdded };
};
