import { Button } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const SubmitButton = styled(Button)`
  width: 170px;
  height: 40px;
  background: ${LightTheme.color.primary};
  border-radius: 8px;
  border: none;
  color: ${LightTheme.color.white};
`;

export const MultiOrderActionButton = styled(Button)`
  width: 170px;
  height: 40px;
  background: ${LightTheme.color.primary};
  border-radius: 8px !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${LightTheme.color.white};
  &:hover, &:active, &:focus {
    color: ${LightTheme.color.gray_lighter};
    background: ${LightTheme.color.primary};
  }
`;

export const MultiOrderActionWrapper = styled.div`
  margin-top: ${LightTheme.spaceSizes.m};
  display: flex;
  flex-direction: row;
  gap: ${LightTheme.spaceSizes.m};
  z-index: 1000;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #eff3f8;
  border-radius: 6px;
  padding: 20px;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 1px;
  }
  .ant-table-row {
    cursor: pointer;
  }
`;
