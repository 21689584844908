/* eslint-disable react/jsx-props-no-spreading */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import { UserSummary } from 'components/UserSummary';
import { LocationValue } from 'containers/General/LocationInput/interface';
import LocationDisplay from 'containers/General/LocationInput/LocationDisplay';
import LocationInput from 'containers/General/LocationInput/LocationInput';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { Role, RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { arrayValidator, phoneValidator } from 'helpers/utils/formValidators';
import { getCoordinates } from 'helpers/utils/getCoordinates';
import validateUserDocTypeAntd from 'helpers/utils/validateUserDocTypeAntd';
import { LayoutContainer, Title, UpdateTitle } from 'pages/App/Layout.sty';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DocType,
  DocTypesObj,
  UserStatus,
  UserStatusEnum,
} from 'services/user/interface';
import UserAutocomplete from '../UserAutocomplete/UserAutocomplete';
import useUserForm from './hooks/useUserForm';
import { UserFormProps, UserFormValues } from './interface';

const { Option } = Select;

export const UserForm = ({
  title,
  initialValues,
  onSubmit,
  isLoading,
  layout,
  create,
  labelCol,
  wrapperCol,
  resetOnSubmit,
}: UserFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formId = `${title}-form`;
  const {
    nameLabel,
    showLastName,
    handleFormValuesChange,
    showModal: [showModal, setShowModal],
    modalLocation: [modalLocation, setModalLocation],
    locationValueIndex: [locationIndex, setLocationIndex],
    emptyLocation,
    locationMapRef,
    showParentStaffUser,
    staffMemberLabel,
    userQueryParams,
    showRoleInput,
    disableUserAutocomplete,
    addressSelectedState,
  } = useUserForm(form, initialValues);
  const [addressSelected] = addressSelectedState;
  const navigate = useNavigate();
  const { AccessWrapper: AccessWrapperRoles, roleEditOptions } =
    useRoleAccess('user.setRoles');
  const { AccessWrapper: AccessWrapperStatus } =
    useRoleAccess('user.setStatus');
  const handleSubmitAndReset = (values: UserFormValues) => {
    onSubmit(values);
    if (resetOnSubmit) {
      form.resetFields();
    }
  };

  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  const recenterMap = (inputValue: LocationValue) => {
    if (locationMapRef.current) {
      locationMapRef.current.setZoom(16);
      locationMapRef.current.setCenter(
        getCoordinates({
          latitude: inputValue.latitude,
          longitude: inputValue.longitude,
        }),
      );
    }
  };

  const handleCancel = () => {
    setModalLocation(emptyLocation);
    recenterMap(emptyLocation);
    setShowModal(false);
  };

  const handleLocationInputChange = (inputValue: LocationValue) => {
    setModalLocation(inputValue);
  };

  const handleConfirmChange = () => {
    let locations = form.getFieldValue('locations');
    if (modalLocation.address === '' || modalLocation.cityName === '') {
      notifyError('userForm.errorMessages.badLocation');
      return;
    }
    if (!addressSelected) {
      notifyError('userForm.errorMessages.badSelectedLocation');
      return;
    }
    if (locationIndex < 0) {
      if (!locations) {
        locations = [];
        form.setFieldsValue({ locations });
      }
      locations.push(modalLocation);
    } else if (locationIndex >= 0) {
      locations[locationIndex] = modalLocation;
      form.setFieldsValue({
        locations,
      });
    }
    setModalLocation(emptyLocation);
    recenterMap(emptyLocation);
    setShowModal(false);
  };

  const handleSelectLocation = (idx: number) => {
    setLocationIndex(idx);
    if (idx < 0) {
      setModalLocation(emptyLocation);
      recenterMap(emptyLocation);
      setShowModal(true);
      return;
    }
    const locations = form.getFieldValue('locations');
    setModalLocation(locations[idx]);
    recenterMap(locations[idx]);
    setShowModal(true);
  };
  return (
    <>
      <Modal
        title={t('addressInput.modal.title')}
        open={showModal}
        onCancel={handleCancel}
        onOk={handleConfirmChange}
      >
        <LocationInput
          onLoadMap={(map: google.maps.Map) => {
            locationMapRef.current = map;
          }}
          value={modalLocation}
          onChange={handleLocationInputChange}
          addressSelectedState={addressSelectedState}
        />
      </Modal>
      {create
        ? title && <Title level={1}>{t(title)}</Title>
        : title && <UpdateTitle level={1}>{t(title)}</UpdateTitle>}
      <LayoutContainer>
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          form={form}
          id={formId}
          layout={layout}
          name="create-user"
          onFinish={handleSubmitAndReset}
          onValuesChange={handleFormValuesChange}
          initialValues={initialValues}
          autoComplete="off"
          scrollToFirstError
        >
          {showRoleInput && (
            <AccessWrapperRoles>
              <Form.Item
                label={t('userForm.labels.role')}
                name="role"
                required
                rules={[
                  {
                    validator: (_, value) =>
                      arrayValidator(value, { required: true }, t),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder={t('userForm.placeholders.role')}
                >
                  {(roleEditOptions as Role[]).map((role: Role) => (
                    <Option key={role as string} value={role}>
                      {t(`roleTypes.${RoleEnum[role]}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </AccessWrapperRoles>
          )}
          {form.getFieldValue('sellerCode') && (
            <Form.Item
              name="sellerCode"
              label={t('userForm.labels.sellerCode')}
            >
              <Input disabled />
            </Form.Item>
          )}
          <Form.Item
            label={t('userForm.labels.documentType')}
            name="docType"
            rules={[
              {
                required: true,
                message: t('userForm.errorMessages.required'),
              },
            ]}
          >
            <Select placeholder={t('userForm.placeholders.docType')}>
              {(Object.keys(DocTypesObj) as DocType[]).map((doc: DocType) => (
                <Option key={doc} value={doc}>
                  {t(DocTypesObj[doc])}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="documentNumber"
            label={t('userForm.labels.documentNumber')}
            rules={[
              {
                required: true,
                message: t('userForm.errorMessages.required'),
              },
              validateUserDocTypeAntd,
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={t(nameLabel)}
            rules={[
              {
                required: true,
                message: t('userForm.errorMessages.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {showLastName && (
            <Form.Item
              name="lastName"
              label={t('userForm.labels.lastName')}
              rules={[
                {
                  required: showLastName,
                  message: t('userForm.errorMessages.required'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {showParentStaffUser && (
            <Form.Item name="parentStaffUser" label={t(staffMemberLabel)}>
              {!disableUserAutocomplete ? (
                <UserAutocomplete
                  disabled={disableUserAutocomplete}
                  queryParams={userQueryParams}
                  onSearchExhaust={() => navigate(ROUTES.createUser)}
                />
              ) : (
                <UserSummary user={form.getFieldValue('parentStaffUser')} />
              )}
            </Form.Item>
          )}
          <Form.Item
            name="email"
            label={t('userForm.labels.email')}
            rules={[
              {
                required: true,
                message: t('userForm.errorMessages.required'),
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            required
            name="phoneNumber"
            label={t('userForm.labels.phoneNumber')}
            rules={[
              {
                validator: (_, value) =>
                  phoneValidator(value, { minLength: 6, required: true }, t),
              },
            ]}
          >
            <Input type="tel" />
          </Form.Item>
          <AccessWrapperStatus>
            <Form.Item label={t('userForm.labels.status')} name="status">
              <Select placeholder={t('userForm.placeholders.status')}>
                {(Object.keys(UserStatusEnum) as UserStatus[]).map(
                  (status: UserStatus) => (
                    <Option key={status as string} value={status}>
                      {t(`userStatusTypes.${UserStatusEnum[status]}`)}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </AccessWrapperStatus>
          {initialValues?.store ? (
            <Form.Item name="store" label={t('userForm.labels.store')}>
              <Text>{initialValues?.store?.name}</Text>
            </Form.Item>
          ) : null}

          <Form.Item
            label={t('userForm.labels.locations')}
            rules={[
              {
                required: true,
                min: 1,
                type: 'array',
                message: t('userForm.errorMessages.badLocations'),
              },
            ]}
            name="locations"
          >
            <div>
              <Form.List name="locations">
                {(fields, { remove }, { errors }) => (
                  <>
                    {fields.map((field) => (
                      <div
                        key={field.key}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: '15px',
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name]}
                          style={{
                            margin: 0,
                          }}
                        >
                          <LocationDisplay
                            value={form.getFieldValue('locations')[field.name]}
                            onEdit={() => handleSelectLocation(field.name)}
                          />
                        </Form.Item>
                        <span style={{ marginLeft: '2ch' }}>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </span>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => handleSelectLocation(-1)}
                        block
                        icon={<PlusOutlined />}
                        id="adress_selector_to_scroll"
                      >
                        {t('userForm.actions.addLocation')}
                      </Button>
                      <Form.ErrorList errors={errors} helpStatus="error" />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 7, offset: 0 },
              sm: { span: 6, offset: 0 },
            }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {create ? t('userForm.title.create') : t('userForm.title.update')}
            </Button>
          </Form.Item>
        </Form>
      </LayoutContainer>
    </>
  );
};

UserForm.defaultProps = {
  title: 'userForm.title.create',
  labelCol: {
    xs: { span: 7 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 12 },
    xl: { span: 10 },
  },
  buttonCol: {
    xs: { span: 7, offset: 0 },
    sm: { span: 6, offset: 0 },
  },
};
