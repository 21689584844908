import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { t } from 'i18next';
import { FormInstance } from 'rc-field-form/lib/interface';

const validateUserDocTypeAntd = (form: FormInstance): RuleObject => ({
  validator(_: RuleObject, value: StoreValue) {
    const docType =
      (form.getFieldValue('docType') as string) ??
      form.getFieldValue('documentType');
    const documentNumber = value;
    switch (docType) {
      case 'NIT':
        if (
          parseInt(documentNumber, 10) < 10000000000 &&
          parseInt(documentNumber, 10) >= 100000000
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('BOORDS009')));
      case 'PS':
        if (/^(?!^0+$)[a-zA-Z0-9]{6,9}$/.test(documentNumber as string)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('BOORDS010')));
      default: {
        const docLength = (documentNumber as string).length;
        if (docLength > 6 && docLength < 12) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('BOORDS011')));
      }
    }
  },
});

export default validateUserDocTypeAntd;
