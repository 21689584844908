import { Form, Input, Row, Select, Spin, TreeSelect } from 'antd';
import TrashIcon from 'assets/images/Trash.svg';
import BrandsAutocomplete from 'components/BrandsAutocomplete/BrandsAutocomplete';
import { nullishProductBrand } from 'components/BrandsAutocomplete/interface';
import { TrashButton } from 'components/VariantVolDiscount/VariantVolDicount.sty';
import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import { getRevisionStatuses } from 'containers/Products/ProductList/utils/revisionStatus';
import { spaceSizes } from 'helpers/constants/theme';
import useCategoryList from 'helpers/hooks/useCategoryList/useCategoryList';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import { filterSelectByLabelText } from 'helpers/utils/filterSelect';
import { arrayValidator } from 'helpers/utils/formValidators';
import JoditEditor from 'jodit-react';
import { Title } from 'pages/App/Layout.sty';
import { ProductFormContent } from 'pages/Products/CreateProduct/interface';
import { useCallback, useMemo } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useAttributeLevelsInput from './hooks/useAttributeLevelsInput';
import useProductForm from './hooks/useProductForm';
import { DISABLED_RICHTEXT_PLUGINS, ProductFormProps } from './interface';
import {
  AttributeLevelContainer,
  AttributeLevelName,
  AttributeLevelTitle,
  CreateProductContainer,
  InnerTitle,
  RichTextEditor,
} from './ProductForm.sty';

const defaultAttributeType: AttributeTypeInterface = {
  name: 'color',
  description: '',
};

const defaultValues: ProductFormContent = {
  name: '',
  categoryId: '',
  imageUrl: '',
  description: '',
  brand: nullishProductBrand,
  attributeLevels: { 0: defaultAttributeType },
};
export const ProductForm = ({
  initialValues = defaultValues,
  onSubmit,
  formId,
  reset,
  title = 'Crear producto',
  submitButton,
  setBrandValue,
  isUpdate,
}: ProductFormProps) => {
  const {
    readQuery: { mutate: getAttributeList, data: attributeList },
  } = useModuleCRUD('attributes');
  const {
    translation: { t },
    form,
    editorState,
    handleEditorChange,
  } = useProductForm(getAttributeList, initialValues);
  const {
    parsedLevels,
    addLevel,
    removeLevel,
    canAdd,
    attributeTypeOptions,
    loadingAttributeTypeList,
  } = useAttributeLevelsInput(form, initialValues.attributeLevels);

  const { categoryTree } = useCategoryList();

  const submitWrapper = useCallback(
    (values: ProductFormContent) => {
      onSubmit({ ...values, description: editorState });
    },
    [onSubmit, editorState],
  );

  const attributeLevelsArray: AttributeTypeInterface[] = useMemo(
    () => Object.values(parsedLevels ?? {}),
    [parsedLevels],
  );

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: '',
      height: 480,
      disablePlugins: DISABLED_RICHTEXT_PLUGINS.join(','),
    }),
    [],
  );

  if (!attributeList || !categoryTree) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }

  return (
    <>
      {reset ? (
        <InnerTitle level={3}>{title || t('createProduct')}</InnerTitle>
      ) : (
        <Title level={1}>{title || t('createProduct')}</Title>
      )}
      <CreateProductContainer>
        <Form
          id={formId}
          labelCol={{ span: 6 }}
          wrapperCol={{
            xl: { span: 10 },
            lg: { span: 12 },
            md: { span: 15 },
          }}
          form={form}
          name="create-product"
          onFinish={submitWrapper}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label={t('productForm.labels.name')}
            rules={[
              {
                required: true,
                message: t('productForm.requiredField'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {isUpdate && (
            <Form.Item
              name="revisionStatus"
              label={t('productForm.labels.revisionStatus')}
              rules={[
                {
                  required: true,
                  message: t('productForm.requiredField'),
                },
              ]}
            >
              <Select
                placeholder={t('productList.filters.revisionStatus')}
                options={getRevisionStatuses(t)}
              />
            </Form.Item>
          )}
          <Form.Item
            name="categoryId"
            label={t('productForm.labels.categoryId')}
            rules={[
              {
                required: true,
                message: t('productForm.requiredField'),
              },
            ]}
          >
            <TreeSelect
              placeholder={t('productForm.categoryPlaceholder')}
              treeData={categoryTree || []}
              treeDefaultExpandAll
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('variantForm.labels.description')}
          >
            <RichTextEditor>
              <JoditEditor
                value={editorState}
                config={config}
                onChange={handleEditorChange}
              />
            </RichTextEditor>
          </Form.Item>
          {submitButton}
          <Form.Item
            name="brand"
            label={t('productForm.labels.brand')}
            rules={[{ required: false, message: t('BOGENE003') }]}
          >
            <BrandsAutocomplete
              form={form}
              setBrandValue={setBrandValue}
              initialValue={initialValues?.brand ?? nullishProductBrand}
            />
          </Form.Item>
          <Form.Item
            name="attributeLevels"
            required
            label={t('attributeLevels.title')}
            style={{ padding: `0 0 ${spaceSizes.xl7} 0` }}
            initialValue={initialValues.attributeLevels}
            rules={[
              {
                validator: (_, value) => {
                  if (isUpdate) {
                    return Promise.resolve();
                  }
                  return arrayValidator(
                    Object.keys(value ?? initialValues.attributeLevels ?? {}),
                    { required: true, minLength: 1, maxLength: 5 },
                    t,
                    {
                      minLength: 'attributeLevels.errors.minLength',
                    },
                  );
                },
              },
            ]}
          >
            <Select
              onSelect={addLevel}
              autoClearSearchValue
              loading={loadingAttributeTypeList}
              options={attributeTypeOptions}
              disabled={!canAdd}
              showSearch
              filterOption={filterSelectByLabelText}
            />
            {attributeLevelsArray?.map((selectedAttributeLevel, idx) => (
              <AttributeLevelContainer key={selectedAttributeLevel?.id}>
                <AttributeLevelTitle>
                  {t('attributeLevels.level', { levelNumber: idx + 1 })}
                </AttributeLevelTitle>
                <AttributeLevelName>
                  {selectedAttributeLevel?.name || ''}
                </AttributeLevelName>
                <TrashButton
                  src={TrashIcon}
                  alt="Trash"
                  onClick={() => removeLevel(selectedAttributeLevel?.id ?? '')}
                  onKeyUp={undefined}
                />
              </AttributeLevelContainer>
            ))}
          </Form.Item>
        </Form>
      </CreateProductContainer>
    </>
  );
};

ProductForm.defaultProps = {
  title: 'Crear producto',
  reset: true,
};
