import { useTranslation } from 'react-i18next';
import PandabonosUpload from 'containers/Pandabonos/PandabonosContent';
import {
  OrderContainer,
  OrderTitle,
  TitleColumn,
  TitleRow,
} from './Pandabonos.sty';

export const Pandabonos = () => {
  const { t } = useTranslation();

  return (
    <OrderContainer>
      <TitleRow>
        <TitleColumn
          span={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <OrderTitle level={1}>{t('menu.pandabonos')}</OrderTitle>
        </TitleColumn>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        />
      </TitleRow>
      <PandabonosUpload />
    </OrderContainer>
  );
};
