import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  ShippingZoneCreateAction,
  ShippingZoneCreateState,
} from '../reducer/interface';
import {
  INITIAL_SHIPPING_ZONE_CREATE_STATE,
  shippingZoneCreateReducer,
} from '../reducer/shipping-zones-create.reducer';

export interface ShippingZoneCreateContextTypes {
  state: ShippingZoneCreateState;
  dispatch: Dispatch<ShippingZoneCreateAction>;
}

const ShippingZoneCreateContext = createContext<ShippingZoneCreateContextTypes>(
  {} as ShippingZoneCreateContextTypes,
);

export const ShippingZoneCreateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    shippingZoneCreateReducer,
    INITIAL_SHIPPING_ZONE_CREATE_STATE,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <ShippingZoneCreateContext.Provider value={value}>
      {children}
    </ShippingZoneCreateContext.Provider>
  );
};

export const useShippingZoneCreate = () =>
  useContext(ShippingZoneCreateContext);
