import { Button, Form, Modal, Tabs, InputNumber, Col } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const CouponFormModal = styled(Modal)`
  .ant-modal-content {
    height: 85vh !important;
    max-height: 85vh !important;
    position: relative;
    overflow-y: hidden;

    .ant-collapse {
      border: none;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          background: none !important;
          border: 1px ${LightTheme.color.primary_dark} !important;
        }
      }
    }

    .ant-modal-footer {
      padding: ${LightTheme.spaceSizes.xl} ${LightTheme.spaceSizes.xl};
      background: ${LightTheme.color.white};
      opacity: 1 !important;
      box-sizing: border-box !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: fit-content;
    }
  }
  .ant-modal-body {
    height: calc(100% - 145px) !important;
    overflow-y: scroll;
  }
`;

export const CouponTitleEditButtonCol = styled(Col)`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${LightTheme.spaceSizes.xl3};
`;

export const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const CustomCouponTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${LightTheme.color.primary} !important;
    pointer-events: none;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        &:hover {
          color: inherit;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${LightTheme.color.primary} !important;
        }
      }
    }
  }
`;

export const SubmitButton = styled(Button)`
  width: 170px;
  height: 40px;
  background: ${LightTheme.color.primary};
  border-radius: 8px;
  border: none;
  color: ${LightTheme.color.white};
`;

export const FormItem = styled(Form.Item)`
  label {
    font-weight: bold;
  }
`;

export const DownloadButton = styled(Button)`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${LightTheme.color.primary};
  color: white;
`;
