import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCitiesOptions,
  getRegionalConfig,
} from 'services/cities/cities.service';
import { GetShippingRegionalConfigParams } from 'services/cities/interface';
import { getTransportList } from 'services/cities/transport.service';
import { getParameters } from 'services/parameters/parameters.service';
import { ShippingZoneLoadingStates } from '../interface';

const useFetchShippingZoneUtils = (params?: { id?: string }) => {
  // this is service only, is not needed to be used in a global state or state manager.
  const [searchTerm, setSearchTerm] = useState('');

  const { data: regionList, isLoading: isLoadingRegionList } = useQuery({
    queryFn: () => getParameters({ keys: 'regions' }),
    refetchOnWindowFocus: false,
    queryKey: ['regions', params?.id ?? 'create'],
  });

  const { data: transportList, isLoading: isLoadingTransportList } = useQuery({
    queryFn: () => getTransportList(),
    refetchOnWindowFocus: false,
    queryKey: ['transport', params?.id ?? 'create'],
  });

  const {
    data: citiesList,
    isLoading: isLoadingCitiesList,
    isFetching: isFetchingCities,
    refetch,
  } = useQuery({
    queryFn: (ctx) =>
      getCitiesOptions({
        search: String(ctx?.meta?.search),
        page: 1,
        pageSize: 1000,
      }),
    refetchOnWindowFocus: false,
    queryKey: ['cities', params?.id ?? 'create'],
    meta: {
      search: searchTerm,
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: (mutationParams: GetShippingRegionalConfigParams) =>
      getRegionalConfig(mutationParams),
  });

  const onSearchCityCode = useCallback((term: string) => {
    setSearchTerm(term ?? '');
  }, []);

  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  const loadingStates: ShippingZoneLoadingStates = useMemo(
    () => ({
      loadingCityCodes: isLoadingCitiesList || isFetchingCities,
    }),
    [isFetchingCities, isLoadingCitiesList],
  );

  return {
    isLoading:
      isLoadingTransportList || isLoadingRegionList || isLoadingCitiesList,
    citiesList,
    regionList,
    transportList,
    getHolyDaysByRegion: mutateAsync,
    onSearchCityCode,
    loadingStates,
  };
};

export default useFetchShippingZoneUtils;
