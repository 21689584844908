import { DefaultTable } from 'helpers/constants/userDocTypes';
import {
  WalletAction,
  WalletState,
  WalletTransaction,
  WalletViewEnum,
} from 'services/wallet/interface';

export interface WalletListProps extends DefaultTable<WalletTransaction> {
  id?: string;
}

export interface WalletFormProps {
  selectedTransaction: WalletTransaction;
  currentView: WalletViewEnum;
  loading: boolean;
  submitForm: (form: WalletTransaction) => void;
}

export interface WalletProps {
  state: WalletState;
  dispatch: React.Dispatch<WalletAction>;
  tableRef: any;
}

export enum TransactionStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Acepted',
  REJECTED = 'Rejected',
}

export const statusArray = Object.keys(TransactionStatus);
