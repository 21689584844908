/* eslint-disable no-underscore-dangle */
import { useQuery } from '@tanstack/react-query';
import AutoCompleteSupplierItem from 'components/AutoCompleteSupplierItem/AutoCompleteSupplierItem';
import debounce from 'lodash/debounce';
import { MpsToSupplier } from 'modules/Purchase/reducer/utils/mps2supplier';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MPS } from 'services/MPS/interface';
import { getMpsList } from 'services/MPS/mps.service';
import { Supplier } from 'services/purchase/interface';
import { SupplierAutocompleteOption } from '../interface';

const useSupplierAutocomplete = (
  triggerChange: (changedValue: Supplier) => void,
  value?: Supplier,
) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [supplierOptions, setSupplierOptions] = useState<
    SupplierAutocompleteOption[]
  >([]);

  useEffect(() => {
    if (value && Object.keys(value).length) {
      setSearchKey(
        `${value.name ?? ''}, ${
          value.documentNumber ?? value.phoneNumber ?? ''
        }`,
      );
    }
  }, [value]);

  const { data, isFetching } = useQuery({
    queryFn: () =>
      getMpsList({
        pagination: { page: 1, pageSize: 50 },
        search: searchFilter,
      }),
    queryKey: ['mps', 'suplpiers', searchFilter],
    onSuccess(mps) {
      const newOptions = mps.data.map((supplier) => ({
        value: supplier._id ?? supplier.id,
        label: <AutoCompleteSupplierItem supplier={supplier} />,
      }));
      setSupplierOptions(newOptions);
    },
    refetchOnWindowFocus: false,
  });

  const autocompleteResults = useMemo(() => data?.data ?? [], [data]);

  const handleSelectAutocomplete = (inputValue: string) => {
    const supplierToAdd = autocompleteResults.find(
      (result) => (result._id ?? result.id) === inputValue,
    );
    if (supplierToAdd) {
      triggerChange(MpsToSupplier(supplierToAdd));
      setSearchKey(
        `${supplierToAdd.name ?? ''}, ${supplierToAdd.document ?? ''}`,
      );
    }
  };

  useEffect(() => {
    const debounced = debounce(() => {
      setSearchFilter(searchKey);
    }, 700);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [searchKey]);

  const handleSearchSuppliers = useCallback((search: string) => {
    setSearchKey(search);
  }, []);

  return {
    searchKey,
    autocompleteResults,
    supplierOptions,
    isLoadingAutocomplete: isFetching,
    handleSelectAutocomplete,
    handleSearchSuppliers,
  };
};

export default useSupplierAutocomplete;
