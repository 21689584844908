import { Method } from 'axios';

export const endpoints = {
  getOrderList: {
    method: 'GET' as Method,
    url: 'order-dom/orders',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createOrder: {
    method: 'POST' as Method,
    url: 'order-dom/orders/multi',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateOrderStatus: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/{params.orderId}/status-change',
    baseURL: process.env.REACT_APP_ORDERS_API_URL,
  },
  updateOrderStatusBatch: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/batch-status-change',
    baseURL: process.env.REACT_APP_API_URL,
  },
  legalizeOrderBatch: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/batch-legalize',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateOrder: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/{params.orderId}',
    baseURL: process.env.REACT_APP_ORDERS_API_URL,
  },
  updateOrderPayment: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/{params.orderId}/payment',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getPackageByOrderId: {
    method: 'GET' as Method,
    url: 'order-dom/package/order/{params.orderId}',
    baseURL: process.env.REACT_APP_ORDER_API,
  },
  updatePackage: {
    method: 'PUT' as Method,
    url: 'order-dom/package/{params.packageId}',
    baseURL: process.env.REACT_APP_ORDER_API,
  },
  getOrderById: {
    method: 'GET' as Method,
    url: 'order-dom/orders/{params.orderId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  deleteOrderById: {
    method: 'DELETE' as Method,
    url: 'order-dom/orders/{params.orderId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  computeTotals: {
    method: 'POST' as Method,
    url: 'order-dom/orders/total',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getMpsWithPendingStockOrders: {
    method: 'GET' as Method,
    url: 'order-dom/orders/pending-stock-mps',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateOrderStock: {
    method: 'PUT' as Method,
    url: 'order-dom/orders/pending-stock/check/{params.orderId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  skipShippingPayment: {
    method: 'PUT' as Method,
    url: '/order-dom/orders/skip-shipping-payment',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getCollectionRecords: {
    method: 'POST' as Method,
    url: '/order-dom/package/collection-record',
    baseUrl: process.env.REACT_APP_ORDERS_API_URL,
  },
  getShippingGuides: {
    method: 'POST' as Method,
    url: '/order-dom/package/shipping-guide',
    baseUrl: process.env.REACT_APP_ORDERS_API_URL,
  },
};
