import { Button, Col, Divider, Form, Row, Statistic, Tooltip } from 'antd';
import useNotifications from 'helpers/hooks/useNotifications';
import LocationList from 'modules/WMS/components/LocationList';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Variant } from 'services/product/interface';
import {
  RelativeDivider,
  RelativeDividerOptions,
  StockInfoCard,
} from '../components/Containers.sty';
import VariantStockForm from '../components/VariantStockForm';
import { VariantStockLayout } from '../constants';
import { ShowedStockProperties } from '../constants/cards';
import { useVariantStockUpdate } from '../context/StockUpdate.context';
import useUpdateStock from '../hooks/useUpdateStock';
import { IVariantStockForm } from '../interface';
import { VARIANT_STOCK_UPDATE_ACTION_ENUM } from '../reducer/interface';

const VariantStockContainer = ({ variant }: { variant: Partial<Variant> }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IVariantStockForm>();
  const { state, dispatch } = useVariantStockUpdate();
  const { updateVariantStock, updateLoading } = useUpdateStock({ variant });
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  const stockInitialValues = useMemo(
    (): IVariantStockForm => ({
      cap: state?.stockInitialValues?.cap ?? 0,
      inWarehouse:
        (state?.stockInitialValues?.notAvailableInWarehouse ?? 0) +
        (state?.stockInitialValues?.availableInWarehouse ?? 0),
      cost: +(variant?.cost ?? 0).toFixed(2),
    }),
    [variant, state],
  );

  const turnEditionMode = useCallback(() => {
    dispatch({
      payload: !state.editionMode,
      type: VARIANT_STOCK_UPDATE_ACTION_ENUM.TURN_EDITION_MODE,
    });
    form.setFieldsValue(stockInitialValues);
  }, [dispatch, state, form, stockInitialValues]);

  const handleStockUpdate = useCallback(
    async (values: IVariantStockForm) => {
      try {
        const updated = form.getFieldsValue();
        await updateVariantStock(values);
        notifySuccess();
        turnEditionMode();

        dispatch({
          type: VARIANT_STOCK_UPDATE_ACTION_ENUM.SET_FORM_INITIAL_VALUE,
          payload: {
            availableInWarehouse: updated.inWarehouse,
            cap: updated.cap,
            cost: updated.cost,
          },
        });
      } catch {
        notifyError();
      }
    },
    [
      form,
      updateVariantStock,
      notifySuccess,
      turnEditionMode,
      dispatch,
      notifyError,
    ],
  );

  const onSaveChanges = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    if (variant.stock)
      dispatch({
        type: VARIANT_STOCK_UPDATE_ACTION_ENUM.SET_FORM_INITIAL_VALUE,
        payload: variant.stock,
      });
  }, [dispatch, variant]);

  useEffect(
    () => form.setFieldsValue(stockInitialValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stockInitialValues],
  );

  return (
    <section>
      <RelativeDivider
        orientation={VariantStockLayout.divider.relativeOrientation}
      >
        {t('stock.container.subtitles.form')}
        <RelativeDividerOptions>
          {state.editionMode && (
            <Button onClick={onSaveChanges} loading={updateLoading}>
              {t('stock.form.buttons.save')}
            </Button>
          )}
          <Button
            type="primary"
            onClick={turnEditionMode}
            loading={updateLoading}
          >
            {t(`stock.form.buttons.editionMode.${state.editionMode}`)}
          </Button>
        </RelativeDividerOptions>
      </RelativeDivider>
      <VariantStockForm
        form={form}
        onSubmit={handleStockUpdate}
        initialValues={stockInitialValues}
        disabled={!state.editionMode}
        variant={variant}
      />
      <Divider>{t('stock.container.subtitles.about')}</Divider>
      <Row
        gutter={VariantStockLayout.row.gutter}
        justify={VariantStockLayout.row.justify}
      >
        {ShowedStockProperties.map((prop) => (
          <Col span={VariantStockLayout.col.span} key={prop.valuePath}>
            <Tooltip
              title={t(prop.tooltip)}
              placement={VariantStockLayout.card.tooltip.placement}
            >
              <StockInfoCard bordered>
                <Statistic
                  title={t(prop.title)}
                  value={variant?.stock?.[prop.valuePath] ?? 0}
                  valueStyle={{ color: prop.color }}
                  prefix={prop.icon}
                />
              </StockInfoCard>
            </Tooltip>
          </Col>
        ))}
      </Row>
      <Divider> {t('stock.container.subtitles.locations')} </Divider>
      <LocationList data={variant?.locations ?? []} />
    </section>
  );
};

export default VariantStockContainer;
