import {
  holyDay,
  Regional,
  ShippingZones,
  Transport,
} from 'services/cities/interface';
import { initialTransportState } from 'services/cities/interface/transport';

const convertZoneCity = (zoneCity: any) => {
  return zoneCity.map((item: any) => {
    return {
      code: item.value ?? item.code,
      name: item.label ?? item.name,
    };
  });
};

const saveTransport = (transport: any, list: Transport[]) => {
  let response: Transport;
  const transportObject = list.find(
    (transportSearch) => transportSearch.name === transport,
  );

  if (!transportObject) {
    response = initialTransportState.selectedTransport;
  } else {
    response = transportObject;
  }

  return response;
};

export const validateCitiesForm = (
  form: ShippingZones,
  transportList: Transport[],
  regionId: string,
  holyDays: holyDay[],
  regionList: Regional[],
) => {
  if (!form.zoneCity || !form.zoneCity.length) {
    return {
      form: {},
      error: true,
    };
  }
  const region = regionList.find((value) => {
    return value.id === regionId;
  });

  return {
    form: {
      ...form,
      regionalConfig: {
        regional: region ?? { value: '', id: '' },
        holyDays,
      },
      zoneCity: convertZoneCity(form.zoneCity) ?? [],
      transport: saveTransport(form.transport, transportList),
      leadTime: Number(form.leadTime ?? 0),
      maxWarrantyPrice: Number(form.maxWarrantyPrice ?? 0),
      minimumOrderAmount: Number(form.minimumOrderAmount ?? 0),
      shippingCost: Number(form.shippingCost ?? 0),
      freeShippingLimit: Number(form.freeShippingLimit ?? 0),
      costTakings: Number(form.costTakings ?? 0),
    },
    error: false,
  };
};

export const validateTransportForm = (form: Transport) => {
  return {
    form: {
      ...form,
      additionalPackageCost: Number(form.additionalPackageCost ?? 0),
      minimumVolumeWeight: Number(form.minimumVolumeWeight ?? 0),
      convertionFactor: Number(form.convertionFactor ?? 0),
    },
    error: false,
  };
};

export const extractDates = (object: any) => {
  return object?.map((item: any) => item.date);
};
