/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { Alert, Button, Col, Divider, Form, Row, Tooltip } from 'antd';
import LoaderWrapper from 'components/LoadingWrapper';
import { BaseColors } from 'helpers/constants/theme';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Behavior,
  IWmsSettingForm,
  RuleOption,
} from 'services/wms-settings/interface';
import BehaviorManager from '../components/BehaviorManager';
import { SettingWrapper, Title } from '../components/SettingList.sty';
import {
  SettingHeader,
  SettingHeaderSection,
  Subtitle,
} from '../components/SettingUpdate.sty';
import { BehaviorsKey, DefaultKey, RequiredInputRules } from '../constants';
import { useSettingUpdate } from '../context/SettingUpdate.context';
import ValueSelector from '../helpers/ValueSelector';
import useUpdateSetting from '../hooks/useUpdateSettingList';
import { SETTING_UPDATE_ACTION_ENUM } from '../reducer/interface';

const SettingUpdateContainer = () => {
  const { state, dispatch } = useSettingUpdate();
  const { id } = useParams();

  const {
    data: setting,
    isLoading,
    onSaveSetting,
  } = useUpdateSetting({
    id: id ?? '',
    state,
    dispatch,
  });
  const { t } = useTranslation();
  const [form] = Form.useForm<IWmsSettingForm>();
  const formBehaviors = Form.useWatch(BehaviorsKey, form) as Behavior[];

  useEffect(() => {
    if (!setting) {
      return;
    }
    form.setFieldsValue(setting);
    dispatch({
      payload: setting,
      type: SETTING_UPDATE_ACTION_ENUM.SET_SETTING,
    });
  }, [setting, dispatch, form]);

  const onSelectDefaultBehavior = useCallback(
    (value?: RuleOption) => {
      form.setFieldValue(DefaultKey, {
        value: value?.value,
        label: value?.label,
      });
    },
    [form],
  );

  const onTurnEditionMode = useCallback(() => {
    form.setFieldsValue(state.setting);

    dispatch({
      payload: !state.editionMode,
      type: SETTING_UPDATE_ACTION_ENUM.TURN_EDITION_MODE,
    });
  }, [dispatch, state, form]);

  return (
    <SettingWrapper>
      <LoaderWrapper loading={isLoading}>
        <SettingHeader>
          <Title color={BaseColors.deep_cove}>
            {t(`wms-settings.setting.${setting?.setting}.title`)}
          </Title>
          <SettingHeaderSection>
            {state.editionMode && (
              <Button type="primary" onClick={form.submit}>
                {t(`wms-settings.update.save`)}
              </Button>
            )}
            <Button type="primary" onClick={onTurnEditionMode}>
              {t(`wms-settings.update.editionMode.${state.editionMode}`)}
            </Button>
          </SettingHeaderSection>
        </SettingHeader>
        <Form
          form={form}
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 8 }}
          layout="vertical"
          onFinish={() => {
            const formValues = form.getFieldsValue();
            onSaveSetting(formValues);
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Subtitle>
                <Tooltip
                  color={BaseColors.dark_gray}
                  title={t('wms-settings.update.form.tooltips.default')}
                >
                  {t('wms-settings.update.form.labels.default')}
                </Tooltip>
              </Subtitle>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t(
                  `wms-settings.setting.${setting?.setting}.defaultValue.label`,
                )}
                name={DefaultKey}
                tooltip={t(
                  `wms-settings.setting.${setting?.setting}.defaultValue.tooltip`,
                )}
              >
                <ValueSelector
                  onSelectValue={onSelectDefaultBehavior}
                  setting={state.setting}
                  settingType={state.setting.setting}
                  defaultValue={state.setting.default}
                  disabled={!state.editionMode}
                />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={24}>
              {!formBehaviors?.length && (
                <Alert
                  type="error"
                  message={t('wms-settings.update.form.errors.zeroBehaviors')}
                  showIcon
                />
              )}
              <Subtitle>{t('wms-settings.update.form.labels.rules')}</Subtitle>
            </Col>
            <Form.Item name={BehaviorsKey} rules={RequiredInputRules} noStyle>
              <BehaviorManager
                dispatch={dispatch}
                form={form}
                formBehaviors={formBehaviors}
                state={state}
              />
            </Form.Item>
          </Row>
        </Form>
      </LoaderWrapper>
    </SettingWrapper>
  );
};

export default SettingUpdateContainer;
