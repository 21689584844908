import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { PrimaryButton } from 'components/PrimaryButton';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { t } from 'i18next';
import { CouponStatus, CouponStatusTagColor } from 'services/coupons/interface';
import { CouponTitleEditButtonCol } from './CouponModal.sty';
import { CouponModalTitleProps } from './interface';

const CouponModalTitle = ({
  coupon,
  title,
  canEdit,
  readOnly,
  onSetEditable,
}: CouponModalTitleProps) => {
  const { hasAccess: canSeeCode } = useRoleAccess('coupon.seeCode');
  return (
    <Row>
      <Col xs={24} md={12} style={{ textAlign: 'left' }}>
        <Space direction="vertical">
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            {t(title)}
          </Typography.Title>
          {coupon?.name && (
            <span style={{ fontWeight: 'bold' }}>{coupon?.name}</span>
          )}
          {canSeeCode && coupon?.id && (
            <span style={{ fontWeight: 'bold', opacity: '0.8' }}>
              {coupon?.id}
            </span>
          )}
          {readOnly && coupon?.status && (
            <CustomTag
              color={CouponStatusTagColor[CouponStatus[coupon.status]]}
            >
              {t(`couponStatus.${coupon.status}`)}
            </CustomTag>
          )}
        </Space>
      </Col>
      <CouponTitleEditButtonCol xs={24} md={12}>
        {canEdit && (
          <PrimaryButton
            type="primary"
            icon={<EditOutlined />}
            shape="circle"
            onClick={onSetEditable}
          />
        )}
      </CouponTitleEditButtonCol>
    </Row>
  );
};

export default CouponModalTitle;
