import { useState } from 'react';
import { Product } from 'services/product/interface';

const useCreateProduct = () => {
  const createStageState = useState<number>(0);
  const createdProductState = useState<Product | undefined>();

  return {
    createStageState,
    createdProductState,
  };
};

export default useCreateProduct;
