import { isNotNil } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import {
  ScrollDownButton,
  ScrollDownContainer,
  ScrollDownIcon,
} from './ScrollBottom.sty';

const ScrollBottom = ({ container }: { container: string }) => {
  const onClickScroll = useCallback(() => {
    const el = document.querySelector(container);

    el?.scrollTo({ top: el.scrollHeight });
  }, [container]);

  // set scroll behavior to container.
  useEffect(() => {
    const el = document.querySelector(container) as HTMLElement;

    if (isNotNil(el?.style?.scrollBehavior)) {
      el.style.scrollBehavior = 'smooth';
    }
  }, [container]);

  return (
    <ScrollDownContainer>
      <ScrollDownButton onClick={onClickScroll}>
        <ScrollDownIcon />
      </ScrollDownButton>
    </ScrollDownContainer>
  );
};

export default ScrollBottom;
