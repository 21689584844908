import { MPS } from 'services/MPS/interface';
import { StatusOrderEnum, UserOrders } from 'services/order/interface';
import { VariantImage } from 'services/product/interface';

export interface Package {
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  packageNumber: string;
  orderId: string;
  sellers: MPS[];
  status: PackageStatusEnum;
  delivery: PackageDelivery;
  costs?: PackageCosts;
  quantities: PackageQuantities;
  history: PackageHistory[];
  products: QuantityVariant[];
  total?: number;
}

export enum PackageStatusEnum {
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED',
  PACKED = 'PACKED',
  DISTRIBUTION = 'DISTRIBUTION',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
}

export const PackageToOrderStatus: Record<PackageStatusEnum, StatusOrderEnum> =
  {
    REJECTED: StatusOrderEnum.REJECTED,
    CANCELLED: StatusOrderEnum.CANCELLED,
    DELIVERED: StatusOrderEnum.DELIVERED,
    DISTRIBUTION: StatusOrderEnum.IN_TRANSIT,
    PACKED: StatusOrderEnum.PACKED,
    RECEIVED: StatusOrderEnum.RECEIVED,
    PENDING: StatusOrderEnum.PENDING,
  };

export const PackageStatusOptions: Record<
  PackageStatusEnum,
  PackageStatusEnum[]
> = {
  REJECTED: [PackageStatusEnum.REJECTED],
  PENDING: [PackageStatusEnum.PENDING],
  CANCELLED: [PackageStatusEnum.CANCELLED],
  DELIVERED: [PackageStatusEnum.DELIVERED],
  RECEIVED: [
    PackageStatusEnum.RECEIVED,
    PackageStatusEnum.PACKED,
    PackageStatusEnum.CANCELLED,
  ],
  PACKED: [
    PackageStatusEnum.PACKED,
    PackageStatusEnum.DISTRIBUTION,
    PackageStatusEnum.CANCELLED,
  ],
  DISTRIBUTION: [
    PackageStatusEnum.DISTRIBUTION,
    PackageStatusEnum.DELIVERED,
    PackageStatusEnum.CANCELLED,
  ],
};

export interface QuantityVariant {
  id: string;
  quantity: number;
  price: number;
  name: string;
  images?: VariantImage[];
}

export interface PackageDelivery {
  date: Date;
}

export interface PackageCosts {
  shipping?: number;
}

export interface PackageQuantities {
  requested: QuantityVariant[];
  packaged?: QuantityVariant[];
  delivered?: QuantityVariant[];
}

export interface PackageHistory {
  id: string;
  time: Date;
  user: AuthUser | UserOrders;
  type: PackageHistoryTypeEnum;
  metadata?: any;
  preservedDiff?: {
    old: Package;
    new: Package;
  };
}

export enum PackageHistoryTypeEnum {
  STATUS_CHANGE = 'STATUS_CHANGE',
  CREATION = 'CREATION',
  QUANTITY_UPDATE = 'QUANTITY_UPDATE',
}

export interface Change {
  oldState: Partial<Package>;
  newState: Partial<Package>;
}

export interface AuthUser {
  userId: string;
  name: string;
  picture?: string;
  aud?: string;
  auth_time?: number;
  user_id?: string;
  _id?: string;
  sub?: string;
  iat?: number;
  exp?: number;
  role?: string;
  email?: string;
  email_verified?: boolean;
  uid: string;
}
