import { Method } from 'axios';

export const endpoints = {
  getCouponsList: {
    method: 'GET' as Method,
    url: '/coupon-dom/coupon',
    baseURL: process.env.REACT_APP_COUPON_API_URL,
  },
  createCoupons: {
    method: 'POST' as Method,
    url: '/coupon-dom/coupon',
    baseURL: process.env.REACT_APP_COUPON_API_URL,
  },
  updateCoupons: {
    method: 'PUT' as Method,
    url: '/coupon-dom/coupon/{params.couponId}',
    baseURL: process.env.REACT_APP_COUPON_API_URL,
  },
  getCouponsById: {
    method: 'GET' as Method,
    url: '/coupon-dom/coupon/{params.couponId}',
    baseURL: process.env.REACT_APP_COUPON_API_URL,
  },
  deleteCouponsById: {
    method: 'DELETE' as Method,
    url: '/coupon-dom/coupon/{params.couponId}',
    baseURL: process.env.REACT_APP_COUPON_API_URL,
  },
};
