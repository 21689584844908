import { Button } from 'antd';
import { BaseColors, spaceSizes } from 'helpers/constants/theme';
import styled from 'styled-components';

export const RoutesFilterContainer = styled.div`
  display: flex;
  gap: ${spaceSizes.m};
  justify-content: center;
  padding: ${spaceSizes.xl};
`;

export const LayoutWrapper = styled.div`
  border-radius: 8px;
  width: 96%;
  margin: ${({ theme }) => theme.spaceSizes.xl5pm} auto;
  background-color: ${({ theme }) => theme.color.gray_not_that_light};
`;

export const UpdateStockContainer = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.spaceSizes.xl7};
`;
export const UpdateStockImage = styled.div`
  max-width: fit-content;
  margin-right: ${({ theme }) => theme.spaceSizes.xl4};
`;
export const UpdateStockImageItem = styled.img`
  max-height: ${({ theme }) => theme.spaceSizes.xl12};
  object-fit: contain;
  width: 120px;
`;
export const UpdateStockItems = styled.div`
  position: relative;
  max-width: 70%;
  width: -webkit-fill-available;
  display: flex;
  justify-content: left;
`;

export const SubtitleText = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.spaceSizes.lxl2};
`;
export const UploadButton = styled(Button)`
  border-radius: 8px;
  width: 342px;
  height: 38px;
  color: white;
  background-color: ${BaseColors.java};
`;
export const UpdatePandabonosWrapper = styled.div`

`;

export const UpdatePandabonosTitle = styled.div`
  
`;

export const PandabonosFilterContainer = styled.div`
  justify-content: flex-start;
`;
