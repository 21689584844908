import { DeleteFilled } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { LocationAutocomplete } from 'components/LocationsAutocomplete/LocationsAutocomplete';
import { useTranslation } from 'react-i18next';
import {
  MIN_TRANSFER_QUANTITY,
  TRANSFER_DELETE_BUTTON_SPAN,
  TRANSFER_LOCATION_INPUT_SPAN,
  TRANSFER_QTY_SPAN,
} from '../../constants';
import useTransfersItem from '../../hooks/useTransfersItem';
import { TransferCardProps } from '../../interface';
import {
  TransferCardWrapper,
  TransferDataWrapper,
  TransferDeleteButton,
  TransferInputLabel,
  TransferQuantityInput,
} from './TransferCard.sty';
import TransferCardInfo from './TransferCardInfo';

const TransferCard = ({ item }: TransferCardProps) => {
  const { t } = useTranslation();
  const { onChangeLocation, onChangeQuantity, onDelete } = useTransfersItem({
    item,
  });

  return (
    <TransferCardWrapper>
      <TransferCardInfo item={item} />
      <Row>
        <Col span={TRANSFER_LOCATION_INPUT_SPAN}>
          <TransferInputLabel>
            {t('transfers.cart.labels.finalLocation')}
          </TransferInputLabel>
          <LocationAutocomplete
            placeholder={t('wms.transfers.destinationLabel')}
            isLeaf
            handleSelect={onChangeLocation}
            allowClear={false}
            defaultValue={item.finalLocation}
          />
        </Col>
        <Col span={TRANSFER_QTY_SPAN}>
          <TransferInputLabel>
            {t('transfers.cart.labels.quantity')}
          </TransferInputLabel>
          <TransferQuantityInput
            max={item.maxQuantityAllowed}
            min={MIN_TRANSFER_QUANTITY}
            value={item.quantity}
            onChange={onChangeQuantity}
          />
        </Col>
        <TransferDataWrapper span={TRANSFER_DELETE_BUTTON_SPAN}>
          <TransferDeleteButton onClick={onDelete} danger>
            <DeleteFilled />
          </TransferDeleteButton>
        </TransferDataWrapper>
      </Row>
      <Divider />
    </TransferCardWrapper>
  );
};

export default TransferCard;
