import { Method } from 'axios';

export const endpoints = {
  getSettingList: {
    method: 'GET' as Method,
    url: '/wms-settings-dom/settings',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getSetting: {
    method: 'GET' as Method,
    url: '/wms-settings-dom/settings/{params.id}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateSetting: {
    method: 'PUT' as Method,
    url: '/wms-settings-dom/settings/{params.id}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
