/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'antd/lib/form/Form';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import buildCouponFromParams from 'helpers/utils/buildCouponFromParams';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getBrandsList } from 'services/brands/brands.service';
import { Coupon, CouponType, UserSegType } from 'services/coupons/interface';
import { getMpsList } from 'services/MPS/mps.service';
import { BillingType } from 'services/product/interface';
import useCategoryList from '../../../../helpers/hooks/useCategoryList/useCategoryList';
import { FormCoupon } from '../interface';

const productSegmentation = (coupon?: Coupon): string => {
  if (coupon?.categorySegmentation) return 'CATEGORY';
  if (coupon?.brandSegmentation) return 'BRAND';
  if (coupon?.isSponsoredCoupon) return 'SELLER';

  return 'NONE';
};

const useCouponModal = (
  coupon?: Coupon,
  isUpdate?: boolean,
  onSubmit?: (data: Partial<Coupon>) => void,
) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<FormCoupon>();
  const [segmentation, setSegmentation] = useState<string[]>();
  const [requireNominalValue, setRequireNominalValue] = useState<boolean>(true);
  const [isCategorySegmentation, setIsCategorySegmentation] = useState(false);
  const [isBrandSegmentation, setIsBrandSegmentation] = useState(false);
  const [isSponsoredCoupon, setIsSponsoredCoupon] = useState(false);
  const [isUserSegmented, setIsUserSegmented] = useState(false);
  const { hasAccess: canUpdate } = useRoleAccess('coupon.editActions');
  const { hasAccess: canCreate } = useRoleAccess('coupon.create');
  const [form] = useForm<FormCoupon>();
  const { getTargetCategoryList } = useCategoryList();

  const { data: brands } = useQuery({
    queryKey: ['brands', 'select'],
    queryFn: () => getBrandsList({ page: 1, pageSize: 999 }),
    refetchOnWindowFocus: false,
  });
  const { data: sellers } = useQuery({
    queryKey: ['sellers', 'select'],
    queryFn: () =>
      getMpsList({
        pagination: { page: 1, pageSize: 999 },
        isSupplier: false,
        billingType: BillingType.SERVICE,
      }),
    refetchOnWindowFocus: false,
  });

  const { getFieldValue, setFieldsValue, validateFields } = form;
  const {
    readQuery: { mutateAsync: getParameters },
  } = useModuleCRUD('parameters');

  const getSegmentation = useCallback(async () => {
    const segmentationValues = await getParameters({ keys: 'segmentation' });
    setSegmentation(segmentationValues?.segmentation?.data);
  }, [getParameters, setSegmentation]);

  useEffect(() => {
    getSegmentation();
  }, [getSegmentation]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setIsCategorySegmentation(coupon?.categorySegmentation ?? false);
    setIsBrandSegmentation(coupon?.brandSegmentation ?? false);
    setIsSponsoredCoupon(coupon?.isSponsoredCoupon ?? false);

    const localInitialValues: FormCoupon = {
      ...coupon,
      name: coupon?.name,
      id: coupon?.id,
      status: coupon?.status,
      startDate: coupon?.startDate ? moment(coupon?.startDate) : undefined,
      endDate: coupon?.endDate ? moment(coupon?.endDate) : undefined,
      saleType: coupon?.saleType,
      usesPerUser: coupon?.usesPerUser,
      uses: coupon?.uses,
      minimumPurchase: coupon?.minimumPurchase,
      maxDiscount: coupon?.maxDiscount,
      maxCouponSpent: coupon?.maxCouponSpent ?? -1,
      type: coupon?.type,
      segmentation: coupon?.segmentation,
      nominalValue: coupon?.nominalValue,
      percentageValue: coupon?.percentageValue,
      categorySegmentation: coupon?.categorySegmentation ?? false,
      brandSegmentation: coupon?.brandSegmentation ?? false,
      targetCategories: coupon?.targetCategories,
      targetBrands: coupon?.targetBrands,
      isSponsoredCoupon: coupon?.isSponsoredCoupon ?? false,
      userSegmentation: coupon?.userSegmentation ?? UserSegType.NONE,
      targetUsers: coupon?.targetUsers,
      productSegmentation: productSegmentation(coupon),
    };
    setFieldsValue(localInitialValues as unknown as any);
    setInitialValues(localInitialValues);
    setRequireNominalValue(coupon?.type === CouponType.NOMINAL);
    setIsUserSegmented(
      (coupon?.userSegmentation ?? false) &&
        coupon?.userSegmentation !== UserSegType.NONE,
    );
  }, [coupon, setFieldsValue]);

  const handleCanEdit = useCallback(() => {
    if ((!canUpdate && isUpdate) || (!canCreate && !isUpdate)) return;
    setReadOnly(false);
  }, [canUpdate, canCreate, isUpdate]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<FormCoupon>) => {
      if (Object.keys(changedValues).includes('type')) {
        if (changedValues.type === CouponType.PERCENTAGE) {
          setRequireNominalValue(false);
        }
        if (changedValues.type === CouponType.NOMINAL) {
          setRequireNominalValue(true);
        }
      }
      if (Object.keys(changedValues).includes('uses')) {
        validateFields(['usesPerUser']);
      }
      if (Object.keys(changedValues).includes('maxCouponSpent')) {
        validateFields(['maxCouponSpent']);
      }
      if (
        Object.keys(changedValues).includes('minimumPurchase') &&
        requireNominalValue
      ) {
        validateFields(['nominalValue']);
      }
      if (
        Object.keys(changedValues).includes('maxDiscount') &&
        requireNominalValue
      ) {
        validateFields(['nominalValue']);
      }
      if (Object.keys(changedValues).includes('productSegmentation')) {
        const category = changedValues.productSegmentation === 'CATEGORY';
        const brand = changedValues.productSegmentation === 'BRAND';
        const seller = changedValues.productSegmentation === 'SELLER';

        setIsCategorySegmentation(category);
        setIsBrandSegmentation(brand);
        setIsSponsoredCoupon(seller);
      }
      if (Object.keys(changedValues).includes('userSegmentation')) {
        setIsUserSegmented(changedValues.userSegmentation !== UserSegType.NONE);
      }
      if (Object.keys(changedValues).includes('selectedCategories')) {
        const targetCategoryList = getTargetCategoryList(
          getFieldValue('selectedCategories'),
        );
        setFieldsValue({ targetCategories: targetCategoryList });
      }
      if (Object.keys(changedValues).includes('selectedBrands')) {
        const selected = getFieldValue('selectedBrands');
        const selectedBrands =
          brands?.data?.filter((b) => selected.includes(b.id)) ?? [];
        setFieldsValue({
          targetBrands: selectedBrands?.map((b) => ({
            id: b.id,
            name: b.name,
            urlName: b.urlName,
          })),
        });
      }
      if (Object.keys(changedValues).includes('selectedSellers')) {
        const selected = getFieldValue('selectedSellers');
        const selectedSellers =
          sellers?.data?.filter((s) => selected.includes(s._id)) ?? [];
        setFieldsValue({
          targetSellers: selectedSellers?.map((s) => ({
            id: s._id,
            name: s.name,
          })),
        });
      }
    },
    [
      requireNominalValue,
      brands?.data,
      sellers?.data,
      validateFields,
      getTargetCategoryList,
      getFieldValue,
      setFieldsValue,
    ],
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setReadOnly(true);
  };

  const handleSubmit = (dtoValues: FormCoupon) => {
    onSubmit?.(buildCouponFromParams(dtoValues, coupon));
    if (!isUpdate) {
      form.resetFields();
    }
  };

  const handleSubmitForm = () => form.submit();

  const effectiveReadOnly = useMemo(
    () => readOnly || (!canUpdate && isUpdate) || (!canCreate && !isUpdate),
    [readOnly, canUpdate, canCreate, isUpdate],
  );
  const canEdit = useMemo(() => canUpdate && isUpdate, [canUpdate, isUpdate]);
  const effectiveCanCreate = useMemo(
    () => canCreate && !isUpdate,
    [canCreate, isUpdate],
  );

  return {
    canCreate: effectiveCanCreate,
    canEdit,
    form,
    initialValues,
    isCategorySegmentation,
    isBrandSegmentation,
    isSponsoredCoupon,
    isUserSegmented,
    openModal,
    readOnly: effectiveReadOnly,
    requireNominalValue,
    segmentation,
    brands,
    sellers,
    handleCanEdit,
    handleCloseModal,
    handleOpenModal,
    handleSubmit,
    handleSubmitForm,
    handleValuesChange,
  };
};

export default useCouponModal;
