/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import useFirebaseAuth from 'helpers/hooks/useFirebaseAuth';
import { createContext, useContext } from 'react';
import { User } from 'services/user/interface';

interface AuthUserContextTypes {
  authUser?: User;
  loading: boolean;
  authError: string;
  firebaseRoles: string;
  logOut: () => Promise<void>;
  loginWithGoogle: () => Promise<void>;
}

const AuthUserContext = createContext<AuthUserContextTypes>({
  authUser: undefined,
  loading: true,
  authError: '',
  firebaseRoles: '',
  logOut: async () => {},
  loginWithGoogle: async () => {},
});

export const AuthUserProvider: React.FC = ({ children }) => {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
};

export const useAuth = () => useContext(AuthUserContext);
