import { Space, Tag } from 'antd';
import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const MovementCardContainer = styled(Space)`
  width: 98%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MovementCardTitle = styled.p`
  margin: 0;
`;

export const MovementCardLabel = styled(MovementCardTitle)`
  font-size: 0.8rem;
`;

export const QuantityTag = styled(Tag)`
  height: fit-content !important;
`;
