/* eslint-disable no-param-reassign */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { ProductFigure } from 'components/AutoCompleteVariantItem/AutoCompleteVariantItem.sty';
import { numberToCurrency } from 'helpers/utils/currency';
import { useCallback } from 'react';
import {
  PurchaseVariant,
  PurchaseVariantInfo,
} from 'services/purchase/interface';
import {
  DecimalNumberInput,
  InputWrapper,
  NumberInput,
} from './PurchaseVariantList.sty';

export const VariantFigureRenderer = (variant: PurchaseVariantInfo) => {
  const { images, name } = variant;
  return (
    <ProductFigure>
      <img
        src={
          images?.length
            ? `${process.env.REACT_APP_IMAGE_BASE_URL}products/${images[0].url}`
            : `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`
        }
        alt={name}
        onError={({ currentTarget }) => {
          currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}products/no_image.jpeg`;
        }}
      />
    </ProductFigure>
  );
};

export const CostRenderer = ({
  cost,
  onChangeCost,
  readOnly,
  fallbackCost = 0,
}: {
  cost: number;
  onChangeCost: (cost: number) => void;
  readOnly: boolean;
  fallbackCost: number;
}) => {
  const handleParse = useCallback((value) => value?.replace(',', '.') || 0, []);
  const handleInputChange = useCallback(
    (value) => onChangeCost(Number(value)),
    [onChangeCost],
  );
  return !readOnly ? (
    <InputWrapper>
      <span style={{ marginRight: '5px' }}>$</span>
      <DecimalNumberInput
        style={{ width: 60 }}
        min={0}
        decimalSeparator="."
        controls={false}
        parser={handleParse}
        onChange={handleInputChange}
        value={cost}
      />
    </InputWrapper>
  ) : (
    <p>{cost ? numberToCurrency(cost || fallbackCost) : '-'}</p>
  );
};

export const TaxRenderer = ({
  tax,
  variant,
  onChangeTax,
  readOnly,
}: {
  tax: number;
  variant: PurchaseVariant;
  onChangeTax: (variant: PurchaseVariant, tax: number) => void;
  readOnly: boolean;
}) => {
  const handleSelectChange = useCallback(
    (value) => onChangeTax(variant, value),
    [onChangeTax, variant],
  );
  return !readOnly ? (
    <InputWrapper>
      <Select
        defaultValue={tax}
        style={{ width: 90 }}
        onChange={handleSelectChange}
      >
        <Select.Option value={0}>0%</Select.Option>
        <Select.Option value={19}>19%</Select.Option>
      </Select>
    </InputWrapper>
  ) : (
    <>{tax ?? 0}%</>
  );
};

export const QuantityRenderer = ({
  readOnly,
  quantity,
  onChangeQuantity,
  fallback = 0,
  minValue,
}: {
  quantity: number;
  readOnly: boolean;
  onChangeQuantity: (estimatedQuantity: number) => void;
  fallback: number;
  minValue: number | undefined;
}) => {
  const handleInputChange = useCallback(
    (e) => onChangeQuantity(Number(e.currentTarget.value)),
    [onChangeQuantity],
  );
  return !readOnly ? (
    <NumberInput
      type="number"
      min={minValue ?? 0}
      style={{ width: 60 }}
      value={quantity}
      onChange={handleInputChange}
    />
  ) : (
    <span>{quantity ? quantity || fallback : '-'}</span>
  );
};

export const DeleteButtonRenderer = ({
  variant,
  onRemovePurchaseVariant,
}: {
  variant: PurchaseVariant;
  onRemovePurchaseVariant: (variant: PurchaseVariant) => void;
}) => {
  const handleRemoveVariant = useCallback(
    () => onRemovePurchaseVariant(variant),
    [onRemovePurchaseVariant, variant],
  );
  return (
    <Button
      key={`${variant.id}-update-button`}
      onClick={handleRemoveVariant}
      danger
    >
      <DeleteOutlined />
    </Button>
  );
};
