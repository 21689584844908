import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const CreateUserOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px !important;
  font-size: ${LightTheme.spaceSizes.l};
  font-weight: bold;
  width: 100%;
`;

export const IconSpan = styled.span`
  margin-right: ${LightTheme.spaceSizes.l};
`;
