import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { SupplierForm } from 'services/MPS/interface';
import { createMps, getMpsList, updateMps } from 'services/MPS/mps.service';
import { parseSupplierFormToMPS } from '../parser';
import { SuppliersState } from '../reducer/supplier.reducer';

interface UseSupplierServiceProps {
  state: SuppliersState;
}

const useSupplierService = ({ state }: UseSupplierServiceProps) => {
  // LIST
  const filters = useMemo(
    () => ({
      pagination: state.pagination,
      search: state.search,
      isSupplier: state.isSupplier,
      billingType: state.billingType,
    }),
    [state],
  );
  const {
    data: listData,
    isFetching: isLoadingSuppliers,
    refetch,
  } = useQuery({
    queryFn: () => getMpsList(filters),
    queryKey: ['mps', 'suppliers', filters],
    refetchOnWindowFocus: false,
  });

  const suppliersData = useMemo(() => listData?.data ?? [], [listData]);
  const totalData = useMemo(() => listData?.metadata.length ?? 0, [listData]);

  // CREATE
  const { mutateAsync: Create, isLoading: createLoading } = useMutation({
    mutationFn: createMps,
  });

  const createSupplier = useCallback(
    async (values: SupplierForm) => {
      const parsedDto = parseSupplierFormToMPS(values);
      await Create(parsedDto);
    },
    [Create],
  );

  // UPDATE
  const { mutateAsync: Update, isLoading: updateLoading } = useMutation({
    mutationFn: updateMps,
  });

  const updateSupplier = useCallback(
    async (values: SupplierForm) => {
      const parsedDto = parseSupplierFormToMPS(values);
      await Update(parsedDto);
    },
    [Update],
  );

  return {
    suppliersData,
    isLoadingSuppliers,
    totalData,
    refetch,

    createLoading,
    createSupplier,

    updateLoading,
    updateSupplier,
  };
};

export default useSupplierService;
