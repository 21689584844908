import { CodeSandboxOutlined, RollbackOutlined } from '@ant-design/icons';
import { WmsSettingEnum } from 'services/wms-settings/interface';

const settingToIconMap = (setting: WmsSettingEnum) => {
  if (setting === WmsSettingEnum.SALE_ORDER_RETURN_LOCATIONS) {
    return <RollbackOutlined />;
  }

  return <CodeSandboxOutlined />;
};

export default settingToIconMap;
