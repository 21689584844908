/* eslint-disable no-bitwise */
import {
  LightTheme,
  spaceSizes,
  UserLogoColors,
} from 'helpers/constants/theme';
import styled from 'styled-components';
import { MpsLogoProps } from './interface';

type ColorIndex = keyof typeof UserLogoColors;

export const nameToHex = (name: string) => {
  if (Object.keys(UserLogoColors).includes(name[0].toUpperCase())) {
    return UserLogoColors[name[0].toUpperCase() as ColorIndex];
  }
  return UserLogoColors.Z;
};

export const MpsLogo = styled.div<MpsLogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${LightTheme.spaceSizes.xl};
  width: ${spaceSizes.xl5};
  height: ${spaceSizes.xl5};
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${({ name }) => nameToHex(name || '')};
  font-weight: bold;
  color: ${LightTheme.color.white};
`;

export const MpsPicture = styled.div`
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  padding-right: ${LightTheme.spaceSizes.m};
`;

export const ItemParagraph = styled.div`
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const BoldItemParagraph = styled(ItemParagraph)`
  font-weight: bold;
`;
