import {
  Purchase,
  PurchaseStatus,
  PurchaseVariant,
} from 'services/purchase/interface';

export const clonePurchase = (
  prevPurchase: Purchase,
  newPurchase: Purchase,
): { applyNewPurchase: boolean; purchaseToCreate?: Purchase } => {
  let applyNewPurchase =
    prevPurchase.status === PurchaseStatus.PENDING &&
    newPurchase.status === PurchaseStatus.IN_TRANSIT;

  if (!applyNewPurchase) {
    return {
      applyNewPurchase: false,
    };
  }
  const purchaseToCreate: Purchase = { ...newPurchase };

  const newDetail: PurchaseVariant[] =
    prevPurchase.detail?.map((oldVariant) => {
      const updatedVariant = newPurchase.detail?.find(
        (det) => det.id === oldVariant.id,
      );

      const qtyDelta =
        oldVariant.values.orderedQuantity -
        (updatedVariant?.values.clearedQuantity ??
          oldVariant.values.orderedQuantity);

      return {
        id: oldVariant.id,
        values: {
          tax: oldVariant.values.tax,
          taxType: oldVariant.values.taxType,
          estimatedCogsVat: oldVariant.values.estimatedCogsVat,
          estimatedCogs: oldVariant.values.estimatedCogs,
          orderedQuantity: qtyDelta,
          purchasePrice: oldVariant.values.purchasePrice,
          purchasePriceCurrency: oldVariant.values.purchasePriceCurrency,
        },
        variant: oldVariant.variant,
      };
    }) ?? [];

  purchaseToCreate.metadata.linkedPurchase = {
    id: prevPurchase.id ?? '',
    purchaseNumber: prevPurchase.purchaseNumber,
  };
  purchaseToCreate.status = PurchaseStatus.PENDING;
  purchaseToCreate.metadata.isMarketplace =
    prevPurchase.metadata.isMarketplace ?? false;
  purchaseToCreate.detail = newDetail.filter(
    (detail) => detail.values.orderedQuantity > 0,
  );

  if (!purchaseToCreate.detail.length) {
    applyNewPurchase = false;
  }

  return {
    applyNewPurchase,
    purchaseToCreate,
  };
};
