import styled from 'styled-components';

export const PackageHeader = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;

export const PackageHeaderTitle = styled.h2`
  margin-right: auto;
`;
