import { SearchOutlined } from '@ant-design/icons';
import { CouponsContentList } from 'containers/Coupons/CouponsContentList/CouponsContentList';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleColumn } from '../../../modules/Order/components/OrderContent/OrderContent.sty';
import {
  CouponsContainer,
  CouponsTitle,
  SearchInput,
  TitleRow,
} from './CouponsList.sty';

export const CouponsList = () => {
  const { t } = useTranslation();
  const prefix = <SearchOutlined />;
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const onSearchWithDebounce = debounce((value: string) => {
    if (!value.length) {
      setSearchKey(undefined);
      return;
    }
    setSearchKey(value);
  }, 700);

  return (
    <CouponsContainer>
      <TitleRow>
        <TitleColumn
          span={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <CouponsTitle level={1}>{t('menu.counponsList')}</CouponsTitle>
        </TitleColumn>
      </TitleRow>
      <TitleRow style={{ width: '770px', marginBottom: 0 }}>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchInput
            placeholder={t('couponsSearchPlaceholder')}
            bordered={false}
            prefix={prefix}
            onChange={(e) => onSearchWithDebounce(e.target.value)}
          />
        </TitleColumn>
      </TitleRow>
      <TitleRow style={{ width: '800px' }}>
        <TitleColumn
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        />
      </TitleRow>
      <CouponsContentList searchKey={searchKey} />
    </CouponsContainer>
  );
};
