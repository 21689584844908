/* eslint-disable react/require-default-props */
import { Col, Image, Row } from 'antd';
import Error from 'assets/images/Error.svg';
import { buildOptionsDropdown } from 'helpers/utils/buildOptionsForDropdown';
import { t } from 'i18next';
import { useMemo } from 'react';
import {
  CustomLabel,
  CustomSelectLarge,
  DefaultBigCol,
  DefaultCol,
  ModalWrapper,
} from './ConfirmActionModal.sty';

export enum ActionModalEnum {
  RESCHEDULE = 'RESCHEDULE',
  CANCEL = 'CANCEL',
  BULKCANCEL = 'BULKCANCEL',
}

interface CancelOrderModalProps {
  onCancelCancel: () => void;
  setCancelReason: (reason: string) => void;
  onOkCancel: () => void;
  open: boolean;
  loading: boolean;
  cancelReason: string;
  orderNumber: string;
  reasonOptions: string[];
}

const CancelOrderModal = ({
  onCancelCancel,
  setCancelReason,
  onOkCancel,
  open,
  loading,
  cancelReason,
  orderNumber,
  reasonOptions,
}: CancelOrderModalProps) => {
  const type = useMemo(() => ActionModalEnum.CANCEL, []);
  return (
    <ModalWrapper
      open={open}
      color={type}
      disableButton={!cancelReason}
      onOk={onOkCancel}
      onCancel={onCancelCancel}
      okButtonProps={{
        disabled: !cancelReason,
        className: 'button-modal',
        loading,
      }}
      destroyOnClose
      centered
    >
      <Row>
        <Col>
          <Image alt="modal-icon" src={Error} width={60} />
        </Col>
        <CustomLabel color={type}>
          {t(`ActionModal.${type}`, { orderNumber })}
        </CustomLabel>
      </Row>
      <Row>
        <DefaultCol />
        <DefaultBigCol>
          <CustomSelectLarge
            placeholder={t('orderForm.modal.reason.cancel')}
            onSelect={(value) => setCancelReason(value as string)}
            options={buildOptionsDropdown(reasonOptions)}
          />
        </DefaultBigCol>
      </Row>
    </ModalWrapper>
  );
};

export default CancelOrderModal;
