import { CreditQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { PaginatedResponse } from 'services/global/interface';
import { CreditStatement } from './interface';

export const getCreditStatement = async (
  params: CreditQueryParams,
): Promise<PaginatedResponse<CreditStatement>> => {
  const response = await getAPIMethod({
    name: 'getCreditStatement',
    params: {
      page: params.page,
      pageSize: params.pageSize,
    },
    pathParams: {
      id: params.search ?? '',
    },
  });

  return response.data as PaginatedResponse<CreditStatement>;
};

export default {
  create: async () => ({}),
  read: getCreditStatement,
  update: async () => ({}),
  delete: async () => ({}),
  readByParam: getCreditStatement,
};
