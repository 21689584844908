import { Method } from 'axios';

export const endpoints = {
  getCreditList: {
    method: 'GET' as Method,
    url: '/finance-dom/credit-line',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getUserCredit: {
    method: 'GET' as Method,
    url: 'finance-dom/credit-line/user/{params.documentNumber}/validate',
    baseUrl: process.env.REACT_APP_API_URL,
  },
  getCreditById: {
    method: 'GET' as Method,
    url: '/finance-dom/credit-line/user/{params.userId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getCreditStatementOrder: {
    method: 'GET' as Method,
    url: '/finance-dom/credit-line/{params.id}/order',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getCreditStatement: {
    method: 'GET' as Method,
    url: '/finance-dom/credit-line/{params.id}/statement',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
