import { DropResult } from 'react-beautiful-dnd';
import { ColumnItem, IBoardColumn, IMovementIntegrity } from '../interface';

export function checkIfMovementIsValid(result: DropResult): boolean {
  const noDestination = result.destination === null;
  const sameColumn =
    result.source.droppableId === result.destination?.droppableId;

  if (noDestination || sameColumn) return false;

  return true;
}

export function checkMovementIntegrity<T extends ColumnItem>(
  result: DropResult,
  columns: IBoardColumn<T>[],
) {
  const sourceColumn = columns.find(
    (column: any) => column.id === result.source.droppableId,
  );
  const destinationColumn = columns.find(
    (column: any) => column.id === result.destination?.droppableId,
  );
  if (!sourceColumn || !destinationColumn) {
    console.error(
      `[ERROR] one column was not found | source: ${sourceColumn} - destination: ${destinationColumn}`,
    );
    return { error: true } as IMovementIntegrity<T>;
  }

  const movedItem = sourceColumn.items.find(
    (item: T) => item.id === result.draggableId,
  );

  if (!movedItem) {
    console.error(
      `[ERROR] moved item not found, please verify that the id property is defined`,
    );
    return { error: true } as IMovementIntegrity<T>;
  }

  return { sourceColumn, destinationColumn, movedItem, error: false };
}

export function swapItemsBetweenColumns<T extends ColumnItem>(
  result: DropResult,
  columns: IBoardColumn<T>[],
  sourceColumn: IBoardColumn<T>,
  destinationColumn: IBoardColumn<T>,
  movedItem: T,
): IBoardColumn<T>[] {
  const insertIndex = result.destination?.index ?? 0;
  const sourceItems = sourceColumn.items.filter(
    (order: T) => order.id !== movedItem.id,
  );

  const newColumns = columns.map((column: IBoardColumn<T>) => {
    if (column.id === sourceColumn.id) {
      return { ...column, items: sourceItems };
    }
    if (column.id === destinationColumn.id) {
      const destinationColumns = column.items;
      destinationColumns.splice(insertIndex, 0, movedItem);
      return { ...column, orders: destinationColumns };
    }

    return column;
  });

  return newColumns;
}
