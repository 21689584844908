/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import editIcon from 'assets/images/editIcon.svg';
import { CustomTag } from 'components/CustomTag';
import {
  DetailTitle,
  Header,
  IconImg,
  Title,
} from 'components/HaulierForm/HaulierForm.sty';
import { emailRegex } from 'helpers/constants/regex';
import {
  numberValidator,
  phoneValidator,
  stringValidator,
} from 'helpers/utils/formValidators';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogisticType, LogisticTypeColor } from 'services/MPS/interface';
import {
  mpsRequestStatusColors,
  mpsRequestStatusEnum,
  MPSUserFormInitialValues,
} from 'services/MPSUser/interface';
import { BillingType, BillingTypeColor } from 'services/product/interface';
import useMPSUserForm from './hooks/useMPSUserForm';
import { MPSUserFormProps } from './interface';

const MPSUserForm = ({
  setFormReference,
  initialValues = MPSUserFormInitialValues,
  closeModal,
  reLoadOnSuccess,
}: MPSUserFormProps) => {
  const { Option } = Select;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { submitForm } = useMPSUserForm(
    initialValues,
    closeModal,
    reLoadOnSuccess,
  );

  useEffect(() => {
    if (setFormReference) {
      setFormReference(form);
    }
  }, []);

  const LABEL_COL = {
    xl: { span: 6 },
    lg: { span: 8 },
    sm: { span: 11 },
  };
  const WRAPPER_COL = {
    xl: { span: 18, offset: 2 },
    lg: { span: 16, offset: 1 },
    sm: { span: 13, offset: 0 },
  };

  const is3PSelected = useMemo(() => {
    const is3p = values?.logisticType === LogisticType['3P'];
    if (is3p) form?.setFieldValue?.('billingType', BillingType.SERVICE);
    return is3p;
  }, [values?.logisticType, form]);

  return (
    <Form
      scrollToFirstError
      onFinish={submitForm}
      initialValues={initialValues}
      form={form}
    >
      <Header>
        <Title>{t(`MPSUserForm.titles.update`)}</Title>
        <IconImg alt="Update logo" src={editIcon} />
      </Header>

      <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
        <Col span={24}>
          <DetailTitle>
            <span>{t('MPSUserForm.titles.detail')}</span>
          </DetailTitle>
        </Col>
        <Col span={12}>
          <Form.Item
            required
            name="name"
            label={t('MPSUserForm.labels.name')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            required
            name="phoneNumber"
            label={t('MPSUserForm.labels.phone')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  phoneValidator(
                    value,
                    { minLength: 7, maxLength: 10, required: true },
                    t,
                  ),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            required
            name="email"
            label={t('MPSUserForm.labels.email')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(
                    value,
                    { minLength: 1, required: true, regexp: emailRegex },
                    t,
                  ),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            required
            name="delay"
            label={t('MPSUserForm.labels.delay')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  numberValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            required
            name="contactName"
            label={t('MPSUserForm.labels.contactName')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            required
            name="document"
            label={t('MPSUserForm.labels.document')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(
                    value,
                    { minLength: 6, maxLength: 11, required: true },
                    t,
                  ),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            required
            name="address"
            label={t('MPSUserForm.labels.address')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            required
            name="status"
            label={t('MPSUserForm.labels.manage')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Select
              disabled={initialValues.status !== mpsRequestStatusEnum.RECEIVED}
            >
              {(
                Object.keys(mpsRequestStatusEnum) as mpsRequestStatusEnum[]
              ).map((status: mpsRequestStatusEnum) => (
                <Option key={status} value={status}>
                  <CustomTag color={mpsRequestStatusColors[status]}>
                    {t(`mpsStatus.${mpsRequestStatusEnum[status]}`)}
                  </CustomTag>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <DetailTitle>
            <span>{t('MPSUserForm.titles.billing')}</span>
          </DetailTitle>
        </Col>

        <Col span={12}>
          <Form.Item
            required
            name="logisticType"
            label={t('MPSUserForm.labels.logisticType')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Select>
              {(Object.keys(LogisticType) as LogisticType[]).map(
                (logistic: LogisticType) => (
                  <Option key={logistic} value={logistic}>
                    <CustomTag color={LogisticTypeColor[logistic]}>
                      {t(`logisticType.${LogisticType[logistic]}`)}
                    </CustomTag>
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
          {values?.billingType === BillingType.SERVICE ? (
            <Form.Item
              required
              name="commission"
              label={t('MPSUserForm.labels.commission')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    numberValidator(
                      value,
                      { min: 0, max: 100, required: true },
                      t,
                    ),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          ) : (
            <Form.Item
              required
              name="takeRate"
              label={t('MPSUserForm.labels.takerate')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              rules={[
                {
                  validator: (_, value) =>
                    numberValidator(
                      value,
                      { min: 0, max: 100, required: true },
                      t,
                    ),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            required
            name="billingType"
            label={t('MPSUserForm.labels.billingType')}
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            rules={[
              {
                validator: (_, value) =>
                  stringValidator(value, { minLength: 1, required: true }, t),
              },
            ]}
          >
            <Select disabled={is3PSelected}>
              {(Object.keys(BillingType) as BillingType[]).map(
                (billType: BillingType) => (
                  <Option
                    key={billType}
                    value={is3PSelected ? BillingType.SERVICE : billType}
                  >
                    <CustomTag color={BillingTypeColor[billType]}>
                      {t(`billingType.${BillingType[billType]}`)}
                    </CustomTag>
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
          {values?.billingType === BillingType.SERVICE && (
            <Form.Item
              name="mandatedBilling"
              label={t('MPSUserForm.labels.mandatedBilling')}
              labelAlign="left"
              labelCol={LABEL_COL}
              wrapperCol={WRAPPER_COL}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
export default MPSUserForm;
