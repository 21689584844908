/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from '@tanstack/react-query';
import useNotifications from 'helpers/hooks/useNotifications';
import { Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IWmsSettingForm, WmsSetting } from 'services/wms-settings/interface';
import {
  getWmsSettingById,
  updateWmsSetting,
} from 'services/wms-settings/wms-settings.service';
import {
  SettingUpdateAction,
  SettingUpdateState,
  SETTING_UPDATE_ACTION_ENUM,
} from '../reducer/interface';

const useUpdateSetting = ({
  id,
  state,
  dispatch,
}: {
  id: string;
  state: SettingUpdateState;
  dispatch: Dispatch<SettingUpdateAction>;
}) => {
  const { t } = useTranslation();
  const { notifySuccess } = useNotifications({
    translationFunction: t,
  });
  const { data, isLoading } = useQuery({
    queryFn: () => getWmsSettingById({ id }),
    queryKey: ['setting', id],
    refetchOnWindowFocus: false,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateWmsSetting,
  });

  const onSaveSetting = useCallback(
    async (formValues: IWmsSettingForm) => {
      const body: WmsSetting = {
        ...state.setting,
        behaviors: formValues.behaviors,
        default: formValues.default,
      };

      const response = await mutateAsync({
        id: state.setting._id,
        payload: body,
      });

      dispatch({
        payload: response,
        type: SETTING_UPDATE_ACTION_ENUM.SET_SETTING,
      });
      dispatch({
        payload: false,
        type: SETTING_UPDATE_ACTION_ENUM.TURN_EDITION_MODE,
      });
      notifySuccess();
    },
    [state, mutateAsync, dispatch, notifySuccess],
  );

  return {
    data,
    isLoading,
    onSaveSetting,
  };
};

export default useUpdateSetting;
