import { Rule } from 'antd/lib/form';
import { emailRegex } from 'helpers/constants/regex';
import {
  numberValidator,
  phoneValidator,
  stringValidator,
} from 'helpers/utils/formValidators';
import validateUserDocTypeAntd from 'helpers/utils/validateUserDocTypeAntd';
import { TFunction } from 'i18next';

export function getSupplierFormValidationRules(t: TFunction) {
  return {
    name: [
      {
        required: true,
        message: t('userForm.errorMessages.required'),
      },
    ],
    contactName: [
      {
        required: true,
        message: t('userForm.errorMessages.required'),
      },
    ],
    documentNumber: [
      {
        required: true,
        message: t('userForm.errorMessages.required'),
      },
      validateUserDocTypeAntd,
    ],
    email: [
      {
        validator: (_: unknown, value: string) =>
          stringValidator(
            value,
            { minLength: 1, required: true, regexp: emailRegex },
            t,
          ),
      },
    ],
    phoneNumber: [
      {
        validator: (_: unknown, value: number) =>
          phoneValidator(
            value,
            { minLength: 7, maxLength: 10, required: true },
            t,
          ),
      },
    ],
    delay: [
      {
        validator: (_: unknown, value: number) =>
          numberValidator(value, { min: 0, max: 15, required: true }, t),
      },
    ],
    commission: [
      {
        validator: (_: unknown, value: number) =>
          numberValidator(value, { min: 0, max: 100, required: false }, t),
      },
    ],
    location: [
      {
        max: 1,
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        type: 'array' as 'array',
        message: t('userForm.errorMessages.badLocation'),
      },
    ],
    rut: [
      {
        message: t('userForm.errorMessages.required'),
        required: false,
      },
    ],
    publicPhoneNumber: [
      {
        validator: (_: unknown, value: number) =>
          phoneValidator(
            value,
            { minLength: 7, maxLength: 10, required: false },
            t,
          ),
      },
    ],
  };
}
