import { InputNumber } from 'antd';
import styled from 'styled-components';

export const CustomNumberInput = styled(InputNumber)`
  height: 30px;
  width: 60px;
  border-radius: 6px;
  padding: 18px, 16px, 18px, 16px;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #eff3f8;
  border-radius: 6px;
  padding: 20px;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #1acec3;
    border-radius: 8px;
  }
`;
