import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';
import { useMemo } from 'react';
import { PurchaseStatus } from 'services/purchase/interface';
import { getPurchaseList } from 'services/purchase/purchase.service';

interface Props {
  filters: { search: string; status?: PurchaseStatus };
}

const useReadPurchase = ({ filters }: Props) => {
  // ------------- TABLE --------------- //
  const {
    list: purchasesList,
    meta: purchasesListMeta,
    isFetching: purchasesListLoading,
    nextPage: nextPurchasesList,
    listError: purchasesListError,
  } = useInfiniteScroll({
    filters,
    fetchData: getPurchaseList,
    name: ['purchase', 'list'],
  });

  // ------------- BOARD --------------- //
  const {
    list: pendingPurchases,
    meta: pendingPurchasesMeta,
    nextPage: nextPendingPurchases,
    listError: pendingPurchasesError,
  } = useInfiniteScroll({
    filters: { ...filters, status: PurchaseStatus.PENDING },
    fetchData: getPurchaseList,
    name: ['purchase', 'board'],
  });

  const {
    list: transitPurchases,
    meta: transitPurchasesMeta,
    nextPage: nextTransitPurchases,
    listError: transitPurchasesError,
  } = useInfiniteScroll({
    filters: { ...filters, status: PurchaseStatus.IN_TRANSIT },
    fetchData: getPurchaseList,
    name: ['purchase', 'board'],
  });

  const {
    list: deliveredPurchases,
    meta: deliveredPurchasesMeta,
    nextPage: nextDeliveredPurchases,
    listError: deliveredPurchasesError,
  } = useInfiniteScroll({
    filters: { ...filters, status: PurchaseStatus.DELIVERED },
    fetchData: getPurchaseList,
    name: ['purchase', 'board'],
  });

  const {
    list: cancelledPurchases,
    meta: cancelledPurchasesMeta,
    nextPage: nextCancelledPurchases,
    listError: cancelledPurchasesError,
  } = useInfiniteScroll({
    filters: { ...filters, status: PurchaseStatus.CANCELLED },
    fetchData: getPurchaseList,
    name: ['purchase', 'board'],
  });

  const purchasesError = useMemo(
    () =>
      purchasesListError ??
      pendingPurchasesError ??
      transitPurchasesError ??
      deliveredPurchasesError ??
      cancelledPurchasesError,
    [
      purchasesListError,
      pendingPurchasesError,
      transitPurchasesError,
      deliveredPurchasesError,
      cancelledPurchasesError,
    ],
  );

  const purchaseBoard = {
    [PurchaseStatus.PENDING]: {
      data: pendingPurchases,
      meta: pendingPurchasesMeta,
    },
    [PurchaseStatus.IN_TRANSIT]: {
      data: transitPurchases,
      meta: transitPurchasesMeta,
    },
    [PurchaseStatus.DELIVERED]: {
      data: deliveredPurchases,
      meta: deliveredPurchasesMeta,
    },
    [PurchaseStatus.CANCELLED]: {
      data: cancelledPurchases,
      meta: cancelledPurchasesMeta,
    },
  };

  const purchasesBoardNext = {
    [PurchaseStatus.PENDING]: nextPendingPurchases,
    [PurchaseStatus.IN_TRANSIT]: nextTransitPurchases,
    [PurchaseStatus.DELIVERED]: nextDeliveredPurchases,
    [PurchaseStatus.CANCELLED]: nextCancelledPurchases,
  };

  return {
    purchasesList,
    purchasesListMeta,
    purchasesListLoading,
    nextPurchasesList,

    purchaseBoard,
    purchasesBoardNext,

    purchasesError,
  };
};

export default useReadPurchase;
