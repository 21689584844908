/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import { useMutation } from '@tanstack/react-query';
import { Attribute } from 'containers/Products/Attributes/interface';
import { useEffect, useMemo } from 'react';
import { getAttributesBytTypeList } from 'services/attribute/attribute.service';
import { AttributeLevel } from 'services/product/interface';

export const useGetAttributeOptions = (
  attributeLevels: AttributeLevel | undefined,
) => {
  const attributeTypeNames = useMemo(
    () => Object.values(attributeLevels || {}).map((type) => type.name),
    [attributeLevels],
  );
  const attributeLevelMap: Record<string, string | number> = useMemo(
    () =>
      attributeLevels
        ? Object.keys(attributeLevels).reduce(
            (acc, key) => ({ ...acc, [attributeLevels[key].name]: key }),
            {},
          )
        : {},
    [attributeLevels],
  );
  const {
    data: attributeOptions,
    mutateAsync: fetchAttributesByType,
    isLoading: loadingAttributesList,
  } = useMutation((types: string[]) => getAttributesBytTypeList(types));

  const defaultValues = useMemo<Attribute[]>(() => {
    if (!attributeOptions) return [] as Attribute[];

    const fetchedTypes = Object.keys(attributeOptions);
    if (fetchedTypes.length <= 0) return [] as Attribute[];

    const firstAttributeOptions = attributeOptions[fetchedTypes[0]];
    if (firstAttributeOptions.length <= 0) return [] as Attribute[];

    const defaultOps = Array(fetchedTypes.length).fill(
      firstAttributeOptions[0],
    ) as Attribute[];

    const hasNoLevelStructure =
      fetchedTypes[0] === 'noLevel' && fetchedTypes.length === 1;

    if (!hasNoLevelStructure) {
      fetchedTypes.forEach((type) => {
        const attrIdx = attributeLevelMap[type]
          ? Number(attributeLevelMap[type])
          : undefined;
        if (attrIdx && (attributeOptions[type]?.length || 0) > 0) {
          const [localOptions] = attributeOptions[type];
          defaultOps[Number(attrIdx)] = localOptions;
        }
      });
    }

    return defaultOps;
  }, [attributeLevelMap, attributeOptions]);

  useEffect(() => {
    fetchAttributesByType(attributeTypeNames);
  }, [attributeTypeNames, fetchAttributesByType]);

  return {
    defaultValues,
    loadingAttributesList,
    attributeOptions,
    attributeTypeNames,
  };
};
