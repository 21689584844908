/* eslint-disable no-underscore-dangle */
import { AutoComplete } from 'antd';
import { useTranslation } from 'react-i18next';
import { MPS } from 'services/MPS/interface';
import useMpsAutocomplete from './hooks/useMpsAutocomplete';
import { MpsAutocompleteProps } from './interface';
import { CustomSearch } from './MpsAutocomplete.sty';

const MpsAutocomplete = ({
  value = {} as MPS,
  onChange,
  disabled,
  icon = true,
  prefix = false,
  onClear,
  style,
}: MpsAutocompleteProps) => {
  const triggerChange = (changedValue: MPS) => {
    onChange?.({
      ...value,
      ...changedValue,
    });
  };

  const {
    searchKey,
    isLoadingAutocomplete,
    mpsOptions,
    handleSearchMps,
    handleSelectAutocomplete,
  } = useMpsAutocomplete(value, triggerChange, disabled);

  const { t } = useTranslation();

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={style}
      options={mpsOptions}
      onSelect={handleSelectAutocomplete}
      onSearch={handleSearchMps}
      value={searchKey}
      disabled={disabled}
      allowClear={prefix}
      onClear={onClear}
    >
      <CustomSearch
        size="large"
        placeholder={t('mpsAutocomplete.placeholder')}
        loading={isLoadingAutocomplete}
        icon={icon}
        prefix={prefix ? t('MPS.filters.label') : ''}
      />
    </AutoComplete>
  );
};

export default MpsAutocomplete;
