import { getAPIMethod } from 'helpers/libs/Http';
import { UpdateStatusDTO, WarrantyList } from './interface';

export const updateWarranty = async (
  updateStatusDto: UpdateStatusDTO,
): Promise<WarrantyList[]> => {
  const response = await getAPIMethod({
    name: 'updateWarranty',
    data: { ...updateStatusDto },
  });
  return response.data;
};
