import { ErrorMessageObject } from './development.errors';

export const haulierErrors: { [key: string]: ErrorMessageObject } = {
  HAUR500001: {
    translation: 'httpErrors.haulier.read',
    isBlocking: true,
  },
  HAUS400005: {
    translation: 'httpErrors.haulier.duplicated',
    isBlocking: true,
  },
  HAUC500001: {
    translation: 'httpErrors.haulier.failure',
    isBlocking: true,
  },
  HAUC400002: {
    translation: 'httpErrors.haulier.badPhone',
    isBlocking: true,
  },
  HAUC400003: {
    translation: 'httpErrors.haulier.badLicense',
    isBlocking: true,
  },
  HAUU500002: {
    translation: 'httpErrors.haulier.updateFail',
    isBlocking: true,
  },
};
