import { BannersAction, BannersEventType, BannersState } from '../interface';

export function bannersReducer(
  state: BannersState,
  action: BannersAction,
): BannersState {
  switch (action.type) {
    case BannersEventType.SET_BANNERS:
      return { ...state, banners: action.payload };

    case BannersEventType.SET_DRAFT_BANNERS:
      return { ...state, draftBanners: action.payload };

    case BannersEventType.SET_SELECTED_BANNER:
      return { ...state, selectedBanner: action.payload };

    case BannersEventType.SET_MODAL_VISIBLE:
      return { ...state, modalVisible: action.payload };

    case BannersEventType.SET_MODAL_TYPE:
      return { ...state, modalType: action.payload };

    default:
      return state;
  }
}
