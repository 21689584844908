/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
import { Table } from 'antd';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import { DEFAULT_PAGE_SIZE } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { transferUnitsAtom } from 'helpers/recoil/atoms/transfers';
import { cloneDeep } from 'lodash';
import useGenericInfiniteScroll from 'modules/Common/hooks/useGenericInfiniteScroll';
import { buildNewTransferItem } from 'modules/WMS/Transfers/builders';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState } from 'recoil';
import { Variant } from 'services/product/interface';
import { getUnitVariantsByLocation } from 'services/unit/unit.service';
import useUnitVariantTable from './hooks/useUnitVariantTable';
import { UnitVariantTableProps } from './interface';
import { generateRowKey } from './utils';

const UnitVariantTable = ({
  locationId,
  onSeeLocations,
  location,
  reload,
  setReload,
}: UnitVariantTableProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [transferItems, setTransferItems] = useRecoilState(transferUnitsAtom);
  const [refetch, setRefetch] = useState<boolean>(false);

  const onChangeQuantity = (
    variant: Variant,
    quantity: number,
    maxQuantity: number,
  ) => {
    const variantId = variant._id ?? variant.id;
    const path = `${variantId}|${locationId}|${variant.unitStatus}`;
    const clone = cloneDeep(transferItems);

    const builtTransfer = buildNewTransferItem(
      variant,
      variantId,
      quantity,
      maxQuantity,
      locationId,
      location,
    );

    clone[path] = builtTransfer;

    if (quantity <= 0) {
      delete clone[path];
    }

    setTransferItems(clone);
  };

  const fetch = useCallback(
    (page: number) => {
      return getUnitVariantsByLocation({
        pageSize: DEFAULT_PAGE_SIZE,
        locationId,
        page,
        search: searchValue,
        getAll: false,
      });
    },
    [locationId, searchValue],
  );

  const {
    data: unitList,
    isLoading,
    hasNext: hasNextPage,
    fetchNext,
    reloadFetch,
  } = useGenericInfiniteScroll<Variant>({
    pureFetchFn: fetch,
    fetchOnDemand: true,
  });

  const handleSearch = useCallback(async (selectedKeys: React.Key[]) => {
    setSearchValue((selectedKeys?.[0] ?? '') as string);
  }, []);

  const handleClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const { columns } = useUnitVariantTable({
    onChangeQuantity,
    handleSearch,
    handleClear,
    onSeeLocations,
  });

  /**
   * If the search value or the locationId changes, then we must re-fetch the page
   */
  useEffect(() => {
    if (locationId !== '') {
      setRefetch(true);
    }
  }, [searchValue, locationId]);

  /**
   * This is a expected behavior, we want to re-fetch the data when a new search or a origin location change event gets fired
   */
  useEffect(() => {
    if (refetch || reload) {
      reloadFetch();
      setReload(false);
      setRefetch(false);
    }
  }, [refetch, reloadFetch, reload, setReload]);

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={fetchNext}
        dataLength={unitList.length}
        scrollableTarget="scrollableDiv"
        hasMore={hasNextPage && !isLoading && unitList.length > 0}
        loader={null}
      >
        <Table
          id="unit-transfer-table"
          columns={columns}
          rowKey={generateRowKey}
          pagination={false}
          dataSource={unitList}
          loading={isLoading}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};

export default UnitVariantTable;
