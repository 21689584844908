import { Image, Tag } from 'antd';
import { StatusTagProps } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import styled, { css } from 'styled-components';

interface OrderCardContainerProps {
  border: string;
  background?: string;
}

export const OrderCardContainer = styled.div<OrderCardContainerProps>`
  transition: 0.2s;
  background-color: ${({ background }) =>
    background
      ? css`
          ${background}
        `
      : 'white'};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0px;
  width: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: ${({ border }) => css`0 0 5px 0 ${border};`};

  & p {
    margin: 0;
    margin-bottom: 2px;
    font-size: 0.8em;
    text-align: left;
  }

  & span.status-tag {
    margin: 0;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:focus {
    cursor: grab;
  }
`;

export const OrderCardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const OrderCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  width: 100%;
`;

export const OrderCardFooterAside = styled.div`
  display: flex;
  border: 1px solid black;
  border-radius: 100%;
  padding: 4px;
  margin: auto;
  cursor: pointer;
  background-color: rgba(26, 206, 195, 0.3);
  border: 2px solid #1acec3;
  background-color: rgba(26, 206, 195, 0.3);
  justify-content: flex-end;
  span {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const OrderCardGrayParagraph = styled.p`
  color: ${LightTheme.color.gray_dark};
  font-size: 1em;
`;

export const OrderCardClientName = styled.b`
  color: ${LightTheme.color.dark};
  margin-bottom: 5px;
`;

export const OrderCardTotal = styled.p`
  color: ${LightTheme.color.primary};
`;

export const OrderCardFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const TagContainer = styled.div<{ space?: boolean }>`
  ${({ space }) => (space ? 'margin: 5px 0;' : '')}
  .ant-tag {
    margin-right: 3px;
  }
`;

export const TagImage = styled(Image)`
  padding-right: 2px;
  margin-bottom: 2px;
  align-items: center;
  align-self: center;
`;

export const StatusTagImage = styled(Tag)<StatusTagProps>`
  background: ${({ color }) => `${color}`}26 !important;
  color: ${({ color }) => `${color}`};
  font-weight: bold;
  border-radius: 4px;
`;

export const PackageInfoWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 40px;
`;

export const OrdersInfoWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
`;

export const OrderNumberWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
