/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line camelcase
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import config from 'config';
import { ROUTES } from 'helpers/constants/routes';
import { chunk } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaginatedResponse } from 'services/global/interface';
import useFirebaseAuth from '../useFirebaseAuth';
import useNotifications from '../useNotifications';
import {
  DEFAULT_PAGE_SIZE,
  GenericParams,
  moduleNameType,
  modules,
} from './interface/useModuleCRUD';

const DEFAULT_INVALIDATION_TIMEOUT = 2400000;

const useInfiniteRead = <T = any>(
  moduleName: moduleNameType,
  filters?: GenericParams,
  callback?: () => void,
  invalidateTimeout?: boolean,
) => {
  const navigate = useNavigate();
  const { logOut } = useFirebaseAuth();
  const { t, i18n } = useTranslation();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });
  const module = modules[moduleName];

  const queryClient = useQueryClient();

  const handleRequestErrors = useCallback(
    (error: any) => {
      if (error.isAxiosError) {
        const errorCode =
          config.errorConfig[error?.response?.data?.code || '']?.translation ||
          'httpErrors.general.generic';
        const errorTranslation = i18n.exists(errorCode)
          ? errorCode
          : 'httpErrors.general.generic';
        notifyError(errorTranslation);
      }
      if (error.isAxiosError && error?.response?.data?.statusCode === 401) {
        logOut();
        navigate(ROUTES.login);
      }
    },
    [navigate, logOut, notifyError, i18n],
  );

  // INFINITE LIST QUERY

  const readInfiniteQueryKey = useMemo(
    () => [moduleName, filters],
    [filters, moduleName],
  );

  const moduleInfiniteRead = useCallback(
    ({ pageParam = 1 }): Promise<PaginatedResponse<T>> =>
      module.read({
        ...filters,
        pageSize: filters?.pageSize ?? DEFAULT_PAGE_SIZE,
        page: pageParam,
      }),
    [filters, module],
  );

  const readInfiniteQuery = useInfiniteQuery<PaginatedResponse<T>>({
    queryKey: readInfiniteQueryKey,
    queryFn: moduleInfiniteRead,
    getNextPageParam: (lastPage: PaginatedResponse<T>) => {
      const hasNext = lastPage?.metadata?.hasNext;
      const isNaNPage = Number.isNaN(lastPage?.metadata?.page);
      if (hasNext && !isNaNPage) return Number(lastPage?.metadata?.page) + 1;
      return undefined;
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
  });

  // MORTAL MUTATIONS

  const createMutation = useMutation((params: any) => module.create(params), {
    onError: (e: any) => {
      handleRequestErrors(e);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(readInfiniteQueryKey);
      callback?.();
    },
  });

  const updateMutation = useMutation((params: any) => module.update(params), {
    onError: (e: any, _updatedEntity, context) => {
      if (context?.previousListData) {
        queryClient.setQueryData(
          readInfiniteQueryKey,
          context.previousListData,
        );
      }
      handleRequestErrors(e);
    },
    onMutate: async (updatedEntity: any) => {
      await queryClient.cancelQueries(readInfiniteQueryKey);
      const previousListData =
        queryClient.getQueryData<InfiniteData<any>>(readInfiniteQueryKey);
      if (!previousListData?.pages) return { previousListData };
      // FIND WHERE TO REPLACE THE NEW DATA
      let replaceEntity = false;
      const numPages = previousListData.pages.length;
      const updatedListData = previousListData.pages.map(
        (page: PaginatedResponse<any>) => {
          if (!page) return page;
          const { data } = page;
          if (!data) return page;
          const updatedData = data.map((item: any) => {
            if (item?.id === updatedEntity?.id) {
              replaceEntity = true;
              return {
                ...item,
                ...updatedEntity,
              };
            }
            return item;
          });
          return updatedData;
        },
      );
      // SET QUERY DATA
      if (replaceEntity) {
        const newPages = chunk(updatedListData, numPages).map((piece, idx) => ({
          metadata: {
            page: idx + 1,
            pageSize: piece.length,
            hasNext: idx < numPages,
          },
          data: piece.flat(),
        }));
        queryClient.setQueryData(readInfiniteQueryKey, {
          ...previousListData,
          pages: newPages,
        });
      }
      callback?.();
      return { previousListData };
    },
    onSettled: () => {
      if (!invalidateTimeout) {
        setTimeout(
          () => queryClient.invalidateQueries(readInfiniteQueryKey),
          DEFAULT_INVALIDATION_TIMEOUT,
        );
      } else {
        queryClient.invalidateQueries(readInfiniteQueryKey);
      }
    },
  });

  const deleteMutation = useMutation((params: any) => module.delete(params), {
    onError: (e: any, _deletedEntity, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(readInfiniteQueryKey, context.previousData);
      }
      handleRequestErrors(e);
    },
    onMutate: (_updatedEntity: any) => {
      const previousData = queryClient.getQueryData(readInfiniteQueryKey);
      // here insert new data in list
      return { previousData };
    },
    onSettled: () => {
      queryClient.invalidateQueries(readInfiniteQueryKey);
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    readInfiniteQuery,
  };
};

export default useInfiniteRead;
