import { ColumnType } from 'antd/lib/table';
import { t } from 'i18next';
import { Variant } from 'services/product/interface';
import { UnitVariantResetFn, UnitVariantSearchFn } from '../interface';
import { UnitTableSearchIcon } from '../UnitVariantTable.sty';
import VariantFilterDropdown from '../VariantFilterDropdown';

export function NameColumn(
  handleReset: UnitVariantResetFn,
  handleSearch: UnitVariantSearchFn,
) {
  const r: ColumnType<Variant> = {
    title: t('productTableKeys.name'),
    dataIndex: 'name',
    width: '350px',
    filterDropdown: (props) => (
      <VariantFilterDropdown
        confirm={props.confirm}
        selectedKeys={props.selectedKeys}
        prefixCls={props.prefixCls}
        setSelectedKeys={props.setSelectedKeys}
        visible={props.visible}
        clearFilters={props.clearFilters}
        filters={props.filters}
        handleReset={handleReset}
        handleSearch={handleSearch}
        close={() => ({})}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <UnitTableSearchIcon filtered={filtered} />
    ),
  };

  return r;
}
