/* eslint-disable no-bitwise */
import { useQuery } from '@tanstack/react-query';
import { AutoComplete, Col, Input, Row, Tag } from 'antd';
import TrashIcon from 'assets/images/Trash.svg';
import { TrashButton } from 'components/VariantVolDiscount/VariantVolDicount.sty';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import { PaginatedResponse } from 'services/global/interface';
import { Order, WarrantyOrderDTO } from 'services/order/interface';
import { getOrderList } from 'services/order/order.service';

const WarrantyOrdersSelector = ({
  selectedWarrantyOptions,
  setSelectedWarrantyOptions,
}: {
  selectedWarrantyOptions: WarrantyOrderDTO[];
  setSelectedWarrantyOptions: (orders: WarrantyOrderDTO[]) => void;
}) => {
  const [warrantySearchKey, setWarrantySearchKey] = useState('');

  const { data: warrantyOrdersOptions } = useQuery(
    ['warrantyOrders', warrantySearchKey],
    {
      queryFn: () =>
        getOrderList({ search: warrantySearchKey, page: 1, pageSize: 100 }),
      enabled: warrantySearchKey.length > 2,
      select(data: PaginatedResponse<Order>) {
        const newData = uniqBy(data.data, 'orderNumber');
        return newData.map((ord) => ({
          value: ord.orderNumber,
          id: ord.id ?? '',
        }));
      },
    },
  );

  const handleSelectWarrantyOrder = (orderNumber: string) => {
    const data = (warrantyOrdersOptions ?? []).find(
      (opt) => opt.value === orderNumber,
    );
    if (data) {
      const prev = cloneDeep(selectedWarrantyOptions);
      prev.push({ id: data.id, orderNumber: data.value });
      const noDuplicatedMap: Record<string, WarrantyOrderDTO> = {};

      prev.forEach((o) => {
        noDuplicatedMap[o.id] = o;
      });

      setSelectedWarrantyOptions(Object.values(noDuplicatedMap));
    }
  };

  const handleSearchWarrantyOrders = debounce((search: string) => {
    setWarrantySearchKey(search);
  }, 700);

  const onDeleteOrderNumber = (id: string) => {
    const orders = cloneDeep(selectedWarrantyOptions);
    const idx = orders.findIndex((ord) => ord.id === id);
    if (~idx) orders.splice(idx, 1);

    setSelectedWarrantyOptions(orders);
  };

  return (
    <Row style={{ marginTop: '20px' }}>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <AutoComplete
          options={warrantyOrdersOptions}
          onSelect={handleSelectWarrantyOrder}
          onSearch={handleSearchWarrantyOrders}
          style={{ width: '100%' }}
        >
          <Input.Search size="large" placeholder="Buscar órdenes" />
        </AutoComplete>
      </Col>
      {selectedWarrantyOptions.map((ord) => (
        <Fragment key={ord.orderNumber}>
          <Col
            span={22}
            style={{
              marginBottom: '10px',
              width: '90%',
            }}
          >
            <Tag style={{ padding: '9px', width: '95%' }}>
              No. Orden &nbsp;
              {ord.orderNumber}
            </Tag>
          </Col>
          <Col span={2}>
            <TrashButton
              src={TrashIcon}
              alt="Trash"
              onClick={() => onDeleteOrderNumber(ord.id)}
            />
          </Col>
        </Fragment>
      ))}
    </Row>
  );
};

export default WarrantyOrdersSelector;
