import config from 'config';

const defaultCenter = config.googleMapsConfig.defaultLocation;

interface LatLngString {
  latitude?: string;
  longitude?: string;
}

export const getCoordinates = ({
  latitude,
  longitude,
}: LatLngString): google.maps.LatLngLiteral => ({
  lat: parseFloat(latitude || String(defaultCenter.lat)),
  lng: parseFloat(longitude || String(defaultCenter.lng)),
});
