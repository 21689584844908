import { Divider, Modal, PageHeader } from 'antd';
import BrandForm from 'components/BrandForm/BrandForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandModalProps } from './interface';

const BrandModal = ({
  isOpen,
  closeModal,
  modalActionType,
  initialValues,
  reLoadOnSuccess,
}: BrandModalProps) => {
  const [formReference, setFormReference] = useState<any>();

  const { t } = useTranslation();
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(true);

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      destroyOnClose
      onOk={formReference ? formReference.submit : null}
      confirmLoading={loadingConfirm}
    >
      <PageHeader
        title={t(`brandForm.titles.${modalActionType.toLowerCase()}`)}
      />
      <Divider />
      <BrandForm
        actionType={modalActionType}
        setFormReference={setFormReference}
        closeModal={closeModal}
        reLoadOnSuccess={reLoadOnSuccess}
        initialValues={initialValues}
        setLoadingConfirm={setLoadingConfirm}
      />
    </Modal>
  );
};

export default BrandModal;
