import { BaseColors } from 'helpers/constants/theme';
import { useMemo } from 'react';
import { DifferenceAddonProps } from '../interface';
import { DifferenceAddonStyled } from './DifferenceAddon.sty';

const DifferenceAddon = ({
  newValue = 0,
  initialValue = 0,
}: DifferenceAddonProps) => {
  const value = useMemo(
    () => newValue - initialValue,
    [newValue, initialValue],
  );
  const addonColor = useMemo(() => {
    if (value === 0) return BaseColors.darky_gray;
    if (value < 0) return BaseColors.error_red;
    return BaseColors.happy_emerald;
  }, [value]);

  const addonSign = useMemo(() => {
    if (value === 0) return '';
    if (value < 0) return '-';
    return '+';
  }, [value]);

  return (
    <DifferenceAddonStyled color={addonColor}>
      {addonSign}
      {Math.abs(value)}
    </DifferenceAddonStyled>
  );
};

export default DifferenceAddon;
