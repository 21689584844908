/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { clone, indexBy, prop } from 'ramda';
import { useCallback } from 'react';
import { Order } from 'services/order/interface';
import { PackageToOrderStatus } from 'services/package/interface';
import {
  getOrderPackages,
  updatePackage,
} from 'services/package/package.service';
import { getDeliveryDates } from 'services/shipping/shipping.service';
import { packageToOrder } from '../reducer/utils/pkg2order';

const useOrderPackageService = ({
  orderId,
  order,
}: {
  orderId: string;
  order: Order;
}) => {
  const { data: packages, isLoading: packagesFetchLoading } = useQuery({
    queryFn: () => getOrderPackages(orderId),
    queryKey: ['order', orderId, 'packages'],
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: fetchDeliveryDates } = useMutation({
    mutationFn: (id: string) => {
      const pkg = packages?.find((p) => p._id === id);
      if (!pkg) throw new Error('Invalid Package');

      return getDeliveryDates({
        order: packageToOrder(pkg, order),
        isUpdate: true,
      });
    },
  });

  const { mutateAsync: updatePackageMt, isLoading: updateLoading } =
    useMutation({
      mutationFn: updatePackage,
    });

  return {
    packages,
    packagesFetchLoading,
    fetchPackageDeliveryDates: fetchDeliveryDates,
    updatePackage: updatePackageMt,
    updateLoading,
  };
};

export default useOrderPackageService;
