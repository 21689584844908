import { Method } from 'axios';

export const endpoints = {
  getProductVariantList: {
    method: 'GET' as Method,
    url: 'product-dom/product-variant/list',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getVariantById: {
    method: 'GET' as Method,
    url: 'product-dom/product-variant/{params.variantId}?{params.withComboDetail}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  createProductVariant: {
    method: 'POST' as Method,
    url: 'product-dom/product-variant',
    baseURL: process.env.REACT_APP_API_URL,
  },
  recountVariant: {
    method: 'PUT' as Method,
    url: '/stock-dom/stock/{params.variantId}?forceCache=true',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateVariantById: {
    method: 'PUT' as Method,
    url: 'product-dom/product-variant/{params.variantId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getVariantHistory: {
    method: 'GET' as Method,
    url: 'product-dom/variant-history/{params.variantId}?page={params.page}&pageSize={params.pageSize}&inStock={params.inStock}&mpsId={params.mpsId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
