import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { ColumnItem, UseBoardProps } from '../interface';
import {
  checkIfMovementIsValid,
  checkMovementIntegrity,
  swapItemsBetweenColumns,
} from '../utils';

function useBoard<T extends ColumnItem>({
  columnChangeEventConfig,
  columns,
  setColumns,
  handleColumnChange,
}: UseBoardProps<T>) {
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!checkIfMovementIsValid(result)) return;
      const { destinationColumn, error, movedItem, sourceColumn } =
        checkMovementIntegrity<T>(result, columns);
      if (error) return;
      await handleColumnChange(sourceColumn, destinationColumn, movedItem);

      // if optimistic update is disabled then do nothing
      if (!columnChangeEventConfig?.optimisticUpdate) return;

      const newColumns = swapItemsBetweenColumns(
        result,
        columns,
        sourceColumn,
        destinationColumn,
        movedItem,
      );
      setColumns(newColumns);
    },
    [columns, columnChangeEventConfig, setColumns, handleColumnChange],
  );
  return { onDragEnd };
}

export default useBoard;
