import PurchaseCreateContainer from 'modules/Purchase/containers/PurchaseCreateContainer';
import { PurchaseProvider } from 'modules/Purchase/context/Purchase.context';

export const PurchaseCreatePage = () => {
  return (
    <PurchaseProvider>
      <PurchaseCreateContainer />
    </PurchaseProvider>
  );
};
