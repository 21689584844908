import { Tag, Tooltip } from 'antd';
import { RoleTagColorCode } from 'helpers/hooks/useRoleAccess/interface';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitRegistry } from './interface';

const UnitMovementHeader = ({
  historyItem,
  userRole,
  userName,
}: {
  historyItem: UnitRegistry;
  userRole: string;
  userName: string;
}) => {
  const { t } = useTranslation();
  const toolTipText = useMemo(() => {
    if (historyItem?.user?.id) {
      return t('variantUnitMovements.tooltips.userId', {
        id: historyItem.user.id,
      });
    }
    return t('variantUnitMovements.tooltips.user');
  }, [historyItem, t]);

  return (
    <>
      <h4>
        {t('variantUnitMovements.date', {
          date: new Date(historyItem?.time).toLocaleString(),
        })}
      </h4>
      <Tooltip title={toolTipText} placement="bottomLeft">
        <h4>
          <Tag color={RoleTagColorCode[userRole ?? '']}>{userRole}</Tag>
          {t('variantUnitMovements.userName', {
            userName,
          })}
        </h4>
      </Tooltip>
    </>
  );
};

export default UnitMovementHeader;
