import { Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { SupplierForm } from 'services/MPS/interface';

const useSupplierForm = (initialValues?: SupplierForm) => {
  const [form] = Form.useForm<SupplierForm>();

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialValues?.rut ?? [],
  );

  useEffect(() => {
    setFileList(initialValues?.rut ?? []);
  }, [initialValues]);

  const handleRutUpload = (file: UploadFile): void => {
    const fileListToSet = [...fileList, file];
    setFileList(fileListToSet);
    form.setFieldsValue({ rut: fileListToSet });
  };

  const handleRutDelete = (): void => {
    setFileList([]);
    form.setFieldsValue({ rut: [] });
  };

  return {
    form,
    fileList,
    handleRutUpload,
    handleRutDelete,
  };
};

export default useSupplierForm;
