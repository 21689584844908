import {
  CodeSandboxOutlined,
  DropboxOutlined,
  MergeCellsOutlined,
  SwapOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { UnitMovementCategoryEnum, UnitRegistryMovement } from './interface';

export const UnitMovementIcon = ({
  movement,
}: {
  movement: UnitRegistryMovement;
}) => {
  if (!movement) {
    return <SwapOutlined />;
  }

  switch (movement.category) {
    case UnitMovementCategoryEnum.PATCH:
      return <ToolOutlined />;
    case UnitMovementCategoryEnum.MERGE:
      return <MergeCellsOutlined />;
    case UnitMovementCategoryEnum.TRANSFER:
      return <SwapOutlined />;
    case UnitMovementCategoryEnum.PURCHASE:
      return <DropboxOutlined />;
    case UnitMovementCategoryEnum.SALE:
      return <CodeSandboxOutlined />;
    case UnitMovementCategoryEnum.WARRANTY:
      return <DropboxOutlined />;
    default:
      return <SwapOutlined />;
  }
};

export default UnitMovementIcon;
