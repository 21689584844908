import AttributeForm from 'containers/Products/Attributes/AttributeForm/AttributeForm';
import { AttributeFormValues } from 'containers/Products/Attributes/AttributeForm/interface';
import { ROUTES } from 'helpers/constants/routes';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  name: '',
  type: '',
  typeId: '',
  description: '',
};

const CreateAttribute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    createMutation: { mutateAsync: createAttribute, isLoading },
  } = useModuleCRUD('attributes');
  const customMessage = {
    success: {
      message: 'attributesForm.modal.actions.success',
      description: 'attributesForm.modal.message.atributo.createSuccess',
    },
    error: {
      message: 'attributesForm.modal.actions.error',
      description: 'attributesForm.modal.message.atributo.createError',
    },
  };
  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const handleSubmit = useCallback(
    async (values: AttributeFormValues) => {
      try {
        await createAttribute(values);
        notifySuccess();
        navigate(ROUTES.attributes);
      } catch (error) {
        notifyError();
        throw new Error('Failed creation request');
      }
    },
    [createAttribute, navigate, notifyError, notifySuccess],
  );

  return (
    <AttributeForm
      title="attributesForm.title.create"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
    />
  );
};

export default CreateAttribute;
