import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Empty } from 'antd';
import { TransferItemsContainer } from 'pages/WMS/Transfer/Transfer.sty';
import { useTranslation } from 'react-i18next';
import { TRANSFER_ITEMS_CONTAINER_SPAN } from '../../constants';
import useTransfers from '../../hooks/useTransfers';
import useTransfersCart from '../../hooks/useTransfersCart';
import TransferCard from '../TransferCard/TransferCard';
import { TransferCartWrapper } from './TransferItems.sty';
import TransferItemsFooter from './TransferItemsFooter';
import TransferItemsHeader from './TransferItemsHeader';
import TransferLoader from './TransferLoader';
import TransferResume from './TransferResume';

const TransferItems = ({ onTransfer }: { onTransfer: () => void }) => {
  const [parent] = useAutoAnimate();
  const [gParent] = useAutoAnimate();
  const { t } = useTranslation();
  const {
    transferItems,
    transferLoading,
    transfersResult,
    clearTransferResult,
  } = useTransfers({ onTransferCb: onTransfer });
  const { clearCart, cartIsEmpty, canTransfer, iterableItems } =
    useTransfersCart();

  return (
    <TransferItemsContainer span={TRANSFER_ITEMS_CONTAINER_SPAN} ref={gParent}>
      <TransferResume
        clearTransferResult={clearTransferResult}
        transfersResult={transfersResult}
      />
      <TransferLoader transferLoading={transferLoading} />
      <TransferItemsHeader cartIsEmpty={cartIsEmpty} clearCart={clearCart} />
      <TransferItemsFooter
        canTransfer={canTransfer}
        transferItems={transferItems}
        transferLoading={transferLoading}
      />
      <TransferCartWrapper ref={parent}>
        {cartIsEmpty ? (
          <Empty description={t('transfers.cart.empty')} />
        ) : (
          iterableItems.map((item) => (
            <TransferCard key={item.path} item={item} />
          ))
        )}
      </TransferCartWrapper>
    </TransferItemsContainer>
  );
};

export default TransferItems;
