import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { stringValidator } from 'helpers/utils/formValidators';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export const AttributeTypeForm = forwardRef((_props, ref: any) => {
  const { t } = useTranslation();
  return (
    <Form layout="vertical" ref={ref}>
      <Form.Item
        label={t('attributesTypeForm.labels.name')}
        name="name"
        required
        rules={[
          {
            validator: (_, value) =>
              stringValidator(
                value,
                {
                  required: true,
                  minLength: 1,
                },
                t,
              ),
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label={t('attributesTypeForm.labels.description')}
        name="description"
        required
        rules={[
          {
            validator: (_, value) =>
              stringValidator(
                value,
                {
                  required: true,
                  minLength: 1,
                },
                t,
              ),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );
});
