/* eslint-disable react/jsx-no-useless-fragment */
import { Col, Input, Row, Select } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { t } from 'i18next';
import { CouponStatus, CouponStatusTagColor } from 'services/coupons/interface';
import { FormItem } from './CouponModal.sty';
import {
  requiredRule,
  validateCouponCode,
  validateCouponName,
} from './CouponValidations.schema';
import { CouponNameInfoSubFormProps } from './interface';

const CouponNameInfoSubForm = ({
  readOnly,
  isUpdate,
}: CouponNameInfoSubFormProps) => {
  if (readOnly) return <></>;
  return (
    <Row gutter={[16, 8]}>
      <Col xs={24} md={12}>
        {!isUpdate && (
          <FormItem
            label={t('couponModal.form.labels.name')}
            name="name"
            rules={[requiredRule, validateCouponName]}
          >
            <Input type="text" />
          </FormItem>
        )}
        {isUpdate && (
          <FormItem name="status" label="Estado" rules={[requiredRule]}>
            <Select disabled={readOnly}>
              {(Object.keys(CouponStatus) as CouponStatus[]).map(
                (status: CouponStatus) => (
                  <Select.Option value={CouponStatus[status]} key={status}>
                    <CustomTag
                      color={CouponStatusTagColor[CouponStatus[status]]}
                    >
                      {t(`couponStatus.${status}`)}
                    </CustomTag>
                  </Select.Option>
                ),
              )}
            </Select>
          </FormItem>
        )}
      </Col>
      <Col xs={24} md={12}>
        {!isUpdate && (
          <FormItem
            label={t('couponModal.form.labels.code')}
            name="id"
            rules={[requiredRule, validateCouponCode]}
          >
            <Input type="text" />
          </FormItem>
        )}
      </Col>
    </Row>
  );
};

export default CouponNameInfoSubForm;
