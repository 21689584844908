import { PARAMS_BANNERS_KEY } from 'containers/BannerList/constants';
import { getAPIMethod } from 'helpers/libs/Http';
import {
  BannersResponse,
  IBanner,
  ParametersQueryParams,
  UpdateParamResponse,
} from './interface';

export const getParameters = async (queryParams: ParametersQueryParams) => {
  const response = await getAPIMethod({
    name: 'getParameters',
    params: {
      keys: queryParams?.keys,
    },
  });
  return response.data;
};

export const getBanners = async (): Promise<BannersResponse> => {
  const response = await getAPIMethod({
    name: 'getParameters',
    params: {
      keys: PARAMS_BANNERS_KEY,
    },
  });

  return response.data;
};

export const updateBanners = async (
  banners: IBanner[],
): Promise<UpdateParamResponse<IBanner[]>> => {
  const response = await getAPIMethod({
    name: 'updateParameter',
    data: {
      id: PARAMS_BANNERS_KEY,
      data: banners,
    },
  });

  return response.data;
};

export default {
  read: getParameters,
  create: getParameters,
  readByParam: getParameters,
  delete: getParameters,
  update: getParameters,
};
