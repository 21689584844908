/* eslint-disable react/jsx-props-no-spreading */
import { message, Upload as UploadAnt } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import 'helpers/firebase';
import { deburr } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFile } from 'services/files/files.service';
import { UploadParams } from './interface';
import UploadContent from './UploadContent';

const UploadItem = ({
  folder,
  format = '',
  onUpload,
  onRemove,
  fileList = [],
  maxLength = 1,
  fileType = 'image',
}: UploadParams) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const beforeUpload = (file: UploadFile) => {
    if (format) {
      let isSpecificFormat = true;
      if (typeof format === 'string') {
        isSpecificFormat = file.type === format;
      } else if (Array.isArray(format) && format.length > 0) {
        const fileTypeToEval = file?.type?.split('/')[1];
        isSpecificFormat = format.includes(fileTypeToEval ?? '');
      }
      if (!isSpecificFormat) {
        message.error(t('upload.formatError', { name: file.name, format }));
      }
      return isSpecificFormat || UploadAnt.LIST_IGNORE;
    }
    return true;
  };

  const onChangeUpload = async (info: UploadChangeParam) => {
    const file = await info.file.originFileObj?.arrayBuffer();
    const { name: fileName, type, uid } = info.file;
    const name = deburr(fileName).replaceAll(' ', '_').toLocaleLowerCase();
    if (file) {
      setLoading(true);

      const res = await uploadFile(
        {
          file: new File([new Blob([file])], name),
          folder,
        },
        fileType,
      );

      const fileUrlString = `${process.env.REACT_APP_IMAGE_BASE_URL}${folder}/${res.images[0]}`;

      if (onUpload) {
        onUpload({
          uid,
          name: res.images[0],
          status: 'done',
          url: fileUrlString,
        });
      }
      setLoading(false);
    }
  };

  const onRemoveAction = async (file: UploadFile) => {
    if (onRemove) {
      onRemove(file);
    }
  };

  return (
    <UploadAnt
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      fileList={fileList}
      beforeUpload={beforeUpload}
      onRemove={onRemoveAction}
      onChange={onChangeUpload}
    >
      {fileList.length < maxLength ? <UploadContent isLoading={loading} /> : ''}
    </UploadAnt>
  );
};

export default UploadItem;
