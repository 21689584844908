import { Space } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import dateFormat from 'helpers/utils/dateFormat';
import { t } from 'i18next';
import { VariantContent } from '../interface';
import { ItemLabel, ItemParagraph } from '../VariantTable.sty';

const HiddenDescription = ({
  variant,
  VariantActions,
}: {
  variant: VariantContent;
  VariantActions: (variant: VariantContent) => JSX.Element | null;
}) => {
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);

  return (
    <Space direction="vertical">
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.productId')}
          :&nbsp;
        </ItemLabel>
        {variant.id}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.description')}
          :&nbsp;
        </ItemLabel>
        {variant.description}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.urlName')}
          :&nbsp;
        </ItemLabel>
        {variant.urlName}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.stockValues.totalWarehouse')}
          :&nbsp;
        </ItemLabel>
        {`${
          (variant.stock?.availableInWarehouse ?? 0) +
          (variant.stock?.notAvailableInWarehouse ?? 0)
        } | ${variant.stock?.availableInWarehouse ?? 0} | ${
          variant.stock?.notAvailableInWarehouse ?? 0
        }`}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.stockValues.total')}
          :&nbsp;
        </ItemLabel>
        {`${variant.stock?.available ?? 0} | ${variant.stock?.cap ?? 0}`}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.stockValues.unavailable')}
          :&nbsp;
        </ItemLabel>
        {`${variant.stock?.block ?? 0} | ${variant.stock?.sold ?? 0}`}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.status')}
          :&nbsp;
        </ItemLabel>
        {variant.status}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.price')}
          :&nbsp;
        </ItemLabel>
        {variant.price}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.createdAt')}
          :&nbsp;
        </ItemLabel>
        {dateFormat(variant.createdAt || '')}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('productTableKeys.updatedAt')}
          :&nbsp;
        </ItemLabel>
        {dateFormat(variant.updatedAt || '')}
      </ItemParagraph>
      {isMobile && VariantActions(variant)}
    </Space>
  );
};

export default HiddenDescription;
