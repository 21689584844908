import { Form } from 'antd';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import PurchaseForm from 'modules/Purchase/components/PurchaseForm';
import {
  CREATE_PURCHASE_ERROR,
  CREATE_PURCHASE_SUCCESS,
  CUSTOM_MESSAGE,
  EMPTY_PURCHASE_DETAIL,
  INVALID_PURCHASE_DETAIL,
  MPS_ORDERS_PURCHASE_ERROR,
  NO_MPS_ORDERS_ERROR,
} from 'modules/Purchase/constants';
import { usePurchase } from 'modules/Purchase/context/Purchase.context';
import useWritePurchase from 'modules/Purchase/hooks/useWritePurchase';
import {
  PURCHASE_ACTION_ENUM,
  PURCHASE_VIEWS_ENUM,
} from 'modules/Purchase/reducer/interface';
import { createNewPurchaseVariant } from 'modules/Purchase/reducer/utils/newPurchaseVariant';
import { pendingStockVariant2purchaseVariant } from 'modules/Purchase/reducer/utils/pendingStockVariant2purchaseVariant';
import { validatePurchaseVariantDetail } from 'modules/Purchase/reducer/utils/validatePurchaseVariantDetail';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Variant } from 'services/product/interface';
import {
  Purchase,
  PurchaseVariant,
  Supplier,
  VariantValues,
} from 'services/purchase/interface';

const PurchaseCreateContainer = () => {
  const [form] = Form.useForm<Purchase>();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();

  const { state, dispatch } = usePurchase();
  const {
    createPurchase,
    isLoadingCreate,
    createError,
    getMpsOrders,
    mpsOrdersLoading,
    mpsOrdersError,
    stockPendingMps,
    stockPedingMpsLoading,
  } = useWritePurchase({
    id: '',
    isOndemand: values?.metadata?.isMarketplace,
  });

  const { notifySuccess, notifyError, notifyWarning } = useNotifications({
    customMessage: CUSTOM_MESSAGE,
    translationFunction: t,
  });

  useEffect(() => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_CURRENT_VIEW,
      payload: PURCHASE_VIEWS_ENUM.CREATE,
    });
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_READONLY,
      payload: false,
    });
  }, [dispatch]);
  useEffect(() => {
    if (createError) {
      notifyError(t(CREATE_PURCHASE_ERROR, { code: createError.code }));
    }
  }, [createError, notifyError]);
  useEffect(() => {
    if (mpsOrdersError) {
      notifyWarning(
        t(MPS_ORDERS_PURCHASE_ERROR, { code: mpsOrdersError.code }),
      );
    }
  }, [mpsOrdersError, notifyWarning]);
  useEffect(() => {
    if (values?.supplier?.id) {
      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_AUTOCOMPLETE_SUGGESTED,
        payload: false,
      });
    }
  }, [values?.supplier?.id, dispatch]);

  const handlePickVariant = useCallback(
    (variant: Variant) => {
      const isMarketplace = values?.metadata?.isMarketplace ?? false;
      const newDetail = [...(values?.detail ?? [])];
      const newPurchaseVariant = createNewPurchaseVariant(
        variant,
        isMarketplace,
        true,
      );
      newDetail.push(newPurchaseVariant);

      form.setFieldValue('detail', newDetail);

      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_PURCHASE_TOTAL,
        payload: newDetail,
      });
    },
    [form, values?.metadata?.isMarketplace, values?.detail, dispatch],
  );
  const handleRemovePurchaseVariant = useCallback(
    (variant: PurchaseVariant) => {
      let newDetail = [...(values?.detail ?? [])];
      newDetail = newDetail.filter((newDet) => newDet.id !== variant.id);

      form.setFieldValue('detail', newDetail);
    },
    [form, values?.detail],
  );
  const handleChangeValues = useCallback(
    (
      variant: PurchaseVariant,
      field: VariantValues,
      value: number | string,
    ) => {
      const newDetail = [...(values?.detail ?? [])];
      const variantIdx = newDetail.findIndex((v) => v.id === variant.id);

      if (variantIdx >= 0) {
        (newDetail[variantIdx].values[field] as string | number) = value;
        // This set the variant cost in all accepted fields for backend
        if (field === 'ondemandPrice') {
          newDetail[variantIdx].values.estimatedCogs = value as number;
          newDetail[variantIdx].values.estimatedCogsVat = value as number;
          newDetail[variantIdx].values.importCost = value as number;
          newDetail[variantIdx].values.importCostVat = value as number;
        }

        const cogsVat = Math.max(
          0,
          (newDetail[variantIdx].values.importCostVat ?? 0) -
            newDetail[variantIdx].values.tax,
        );
        const cogs = Math.max(
          0,
          (newDetail[variantIdx].values.importCost ?? 0) -
            newDetail[variantIdx].values.tax,
        );

        newDetail[variantIdx].values.cogsVat = cogsVat;
        newDetail[variantIdx].values.cogs = cogs;

        form.setFieldValue('detail', newDetail);
      }

      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_PURCHASE_TOTAL,
        payload: newDetail,
      });
    },
    [dispatch, values, form],
  );

  const setAutocompleteMpsOrders = useCallback(async () => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_AUTOCOMPLETE_SUGGESTED,
      payload: true,
    });

    if (values?.supplier?.id) {
      const mpsOrders = await getMpsOrders(values.supplier.id);
      if (!mpsOrders.length) {
        notifyWarning(t(NO_MPS_ORDERS_ERROR));
        return;
      }

      const suggestedDetail: PurchaseVariant[] = mpsOrders[0].variants.map(
        (v) => pendingStockVariant2purchaseVariant(v),
      );

      form.setFieldValue('detail', suggestedDetail);
    }
  }, [dispatch, form, getMpsOrders, notifyWarning, values?.supplier?.id]);

  const handleSubmit = useCallback(async () => {
    if (state.step === 1) {
      dispatch({
        type: PURCHASE_ACTION_ENUM.SET_STEP,
        payload: 2,
      });

      if (values?.metadata?.isMarketplace && values?.supplier?.id) {
        setAutocompleteMpsOrders();
      }

      return;
    }

    if (!values.detail.length) {
      notifyError(t(EMPTY_PURCHASE_DETAIL));
      return;
    }
    const isValid = validatePurchaseVariantDetail(
      values.detail,
      true,
      values?.metadata?.isMarketplace ?? false,
      state.formInitialValues.status,
    );
    if (!isValid) {
      notifyError(t(INVALID_PURCHASE_DETAIL));
      return;
    }

    const newPurchase: Purchase = {
      ...values,
      delivery: {
        ...values.delivery,
        date: moment(values.delivery?.date).toISOString(),
      },
    };

    if (newPurchase.payment?.date) {
      newPurchase.payment.date = moment(newPurchase.payment.date).toISOString();
    }

    const created = await createPurchase(newPurchase);
    navigate(ROUTES.purchase);
    notifySuccess(
      t(CREATE_PURCHASE_SUCCESS, { purchaseNumber: created.purchaseNumber }),
    );
  }, [
    createPurchase,
    notifyError,
    notifySuccess,
    state.formInitialValues.status,
    values,
    dispatch,
    state.step,
    navigate,
    setAutocompleteMpsOrders,
  ]);

  const selectSupplier = useCallback(
    (value?: Supplier) => {
      form.setFieldValue('supplier', value);
    },
    [form],
  );

  return (
    <PurchaseForm
      showClonePurchaseModal={false}
      isLoadingClonePurchase={false}
      form={form}
      formInitialValues={state.formInitialValues}
      values={values}
      readOnly={state.readOnly}
      currentView={state.currentView}
      step={state.step}
      purchaseTotal={state.purchaseTotal}
      isLoading={isLoadingCreate || stockPedingMpsLoading}
      mpsOrdersLoading={mpsOrdersLoading}
      autocompletedSuggested={state.autocompletedSuggested}
      stockPendingMps={stockPendingMps}
      setAutocompleteMpsOrders={setAutocompleteMpsOrders}
      onSubmit={handleSubmit}
      handlePickVariant={handlePickVariant}
      onRemovePurchaseVariant={handleRemovePurchaseVariant}
      onChangeValues={handleChangeValues}
      selectSupplier={selectSupplier}
    />
  );
};

export default PurchaseCreateContainer;
