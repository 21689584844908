import { Drawer } from 'antd';
import { UserFormValues } from 'containers/Users/UserForm/interface';
import { UserForm } from 'containers/Users/UserForm/UserForm';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { RoleEnum } from 'helpers/hooks/useRoleAccess/interface';
import buildUserFromParams from 'helpers/utils/buildUserFormParams';
import { useTranslation } from 'react-i18next';
import { UserDrawerProps } from './interface';

const UserDrawer = ({ user, showDrawer, onClose }: UserDrawerProps) => {
  const userFormData = {
    ...user,
    ...user?.info,
    seller: user.seller || '',
    sellerCode: user.sellerCode || '',
    sellerCoordinator: user.sellerCode || '',
    phoneNumber: user.mainPhone
      ? user?.mainPhone?.replace('+57', '')
      : user.info?.phoneNumber?.replace('+57', ''),
    role: user?.role,
    parentStaffUser: user?.role?.includes(RoleEnum.SELLER)
      ? user?.sellerCoordinator
      : user?.seller,
  } as UserFormValues;
  const customMessage = {
    success: {
      message: 'userForm.modal.actions.success',
      description: 'userForm.modal.message.success',
    },
    error: {
      message: 'userForm.modal.actions.error',
      description: 'userForm.modal.message.error',
    },
  };
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });
  const {
    updateMutation: { mutateAsync: updateOrder, isLoading },
  } = useModuleCRUD('user');
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);

  const handleSubmit = async (values: UserFormValues) => {
    try {
      await updateOrder(buildUserFromParams(values, user));
      onClose(true);
      notifySuccess();
    } catch (error) {
      notifyError();
      console.error(error);
      throw new Error('Failed update request');
    }
  };
  return (
    <Drawer
      placement="right"
      open={showDrawer}
      onClose={() => onClose()}
      width={isMobile ? '100%' : 450}
    >
      <UserForm
        title="userForm.title.update"
        initialValues={userFormData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        layout="vertical"
        wrapperCol={{
          xs: { span: 21 },
        }}
        buttonCol={{
          xs: { span: 21, offset: 0 },
        }}
      />
    </Drawer>
  );
};

export default UserDrawer;
