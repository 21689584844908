import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'services/user/interface';
import { AddressModal } from '../AddressModal';
import useAddressSelect from './hooks/useAddressSelect';
import { LocationSelectProps, LocationValue } from './interface';

const emptyLocation = {
  address: '',
  details: '',
  latitude: '',
  longitude: '',
};

const AddressSelect = ({
  value,
  onChange,
  disabled,
  locationOptions,
}: LocationSelectProps) => {
  const { t } = useTranslation();
  const {
    extendedLocationOptionsState: [
      extendedLocationOptions,
      setExtendedLocationOptions,
    ],
    addressState: [address, _setAddress],
    addressIndexState: [addressIndex, _setAddressIndex],
    addressModalState: [showModal, setShowModal],
  } = useAddressSelect(
    value,
    locationOptions,
  );

  const triggerChange = (changedValue: LocationValue) => {
    onChange?.({
      ...address,
      ...value,
      ...changedValue,
    });
  };

  const handleSelectAddress = (selectValue: number) => {
    triggerChange({
      ...extendedLocationOptions[selectValue],
    });
  };

  const handleConfirmChange = (newAddress: Location) => {
    setExtendedLocationOptions((prev) => ([
      ...prev,
      newAddress,
    ]));
    triggerChange({
      ...newAddress,
    });
    setShowModal(false);
  };

  const handleCancelChange = () => {
    setShowModal(false);
  };

  return (
    <>
      <AddressModal
        visible={showModal}
        initialValue={emptyLocation}
        onConfirmChange={handleConfirmChange}
        onCancelChange={handleCancelChange}
      />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder={t('userForm.placeholders.address')}
          filterOption={(input, option) =>
            String(option?.children).toLowerCase().includes(input.toLowerCase())}
          onSelect={handleSelectAddress}
          disabled={disabled}
          value={addressIndex}
          style={{
            minWidth: '350px',
            width: '100%',
          }}
        >
          {extendedLocationOptions.map((addressOption, idx) => (
            <Select.Option
              key={`${addressOption.longitude}-${addressOption.latitude}`}
              value={idx}
            >
              {addressOption.address}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ width: '100%' }}
          onClick={() => setShowModal(true)}
          disabled={disabled}
        >
          {t('userForm.actions.addLocation')}
        </Button>
      </Space>
    </>
  );
};

export default AddressSelect;
