import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

const TableTabsWrapper = styled.div`
  .ant-tabs-tab-btn {
    color: #fff;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${LightTheme.color.primary};
  }
  .ant-tabs-ink-bar {
    background: ${LightTheme.color.primary} !important;
  }
`;

export default TableTabsWrapper;
