/* eslint-disable react/jsx-props-no-spreading */
import chroma from 'chroma-js';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ColumnCard,
  ColumnContainer,
  ColumnItemsList,
  ColumnTitle,
  ColumnTitleWrapper,
  DroppableWrapper,
} from './Board.sty';
import {
  BAD_INFINITE_SCROLL_CONFIG,
  COLUMN_DEFAULT_COLOR,
  COLUMN_SCROLL_TARGET_PREFIX,
} from './constants';
import { BoardColumnProps, ColumnItem } from './interface';

function Column<T>({
  animation,
  infiniteScrollConfig,
  column,
  renderCard,
}: Readonly<BoardColumnProps<T>>) {
  if (
    infiniteScrollConfig?.enabled &&
    (isNil(infiniteScrollConfig.onFetchNextPage) ||
      isNil(column.infiniteScrollData?.hasNext))
  ) {
    throw new Error(BAD_INFINITE_SCROLL_CONFIG);
  }

  const handleFetchNextPage = useCallback(async () => {
    if (infiniteScrollConfig?.enabled && infiniteScrollConfig.onFetchNextPage) {
      await infiniteScrollConfig?.onFetchNextPage(column);
    }
  }, [column, infiniteScrollConfig]);

  const scrollableColumnId = `${COLUMN_SCROLL_TARGET_PREFIX}-${column.id}`;
  const columnColor = column?.color ?? COLUMN_DEFAULT_COLOR;
  const animationColor = chroma(columnColor).alpha(0.5).css();

  return (
    <Droppable key={column.id} droppableId={column.id}>
      {(provided, snapshot) => (
        <DroppableWrapper>
          <ColumnTitleWrapper>
            <ColumnTitle color={columnColor}>{column.name}</ColumnTitle>
          </ColumnTitleWrapper>
          <ColumnContainer
            id={scrollableColumnId}
            {...provided.droppableProps}
            ref={provided.innerRef}
            color={column.color ?? ''}
          >
            <InfiniteScroll
              loader={
                !column.items.length ? undefined : infiniteScrollConfig?.loader
              }
              next={handleFetchNextPage}
              hasMore={column.infiniteScrollData?.hasNext ?? false}
              dataLength={column.items.length}
              scrollableTarget={scrollableColumnId}
            >
              <ColumnItemsList
                animationEnabled={animation?.enabled ?? false}
                animationColor={animationColor}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {column.items.map((item: T & ColumnItem, idx: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={idx}>
                    {(providedDrag, snapshotDrag) => (
                      <ColumnCard
                        {...providedDrag.dragHandleProps}
                        {...providedDrag.draggableProps}
                        ref={providedDrag.innerRef}
                      >
                        {renderCard({
                          isDragging: snapshotDrag.isDragging,
                          data: item,
                          color: column.color,
                        })}
                      </ColumnCard>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ColumnItemsList>
            </InfiniteScroll>
          </ColumnContainer>
        </DroppableWrapper>
      )}
    </Droppable>
  );
}

export default Column;
