import { Method } from 'axios';

export const endpoints = {
  validateShipping: {
    method: 'POST' as Method,
    url: 'shipping-dom/shipping/validate',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getDeliveryDates: {
    method: 'POST' as Method,
    url: 'shipping-dom/shipping/delivery-dates',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
