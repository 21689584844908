import useNotifications from 'helpers/hooks/useNotifications';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'services/user/interface';

const useAddressModal = (initialValue: Location) => {
  const mapRef = useRef<google.maps.Map>();
  const inputValue = useState<Location>(initialValue);
  const setInputValue = inputValue[1];
  const addressSelectedState = useState<boolean>(false);

  const { t } = useTranslation();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue, setInputValue]);

  return {
    mapRef,
    inputValue,
    addressSelectedState,
    notifyError,
  };
};

export default useAddressModal;
