import styled from 'styled-components';

export const SupplierLayoutContainer = styled.div`
  padding: 40px 20px;
  background-color: #eff3f8;
  border-radius: 6px;
  height: calc(100% - 100px);
  width: 100%;
  overflow: scroll;
  position: relative;
`;
