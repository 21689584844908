/* eslint-disable react/jsx-no-constructed-context-values */
import { TransportActionEnum } from 'config/Constants/actions';
import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import {
  initialTransportState,
  TransportAction,
  TransportState,
} from 'services/cities/interface/transport';

function TransportReducer(
  state: TransportState,
  action: TransportAction,
): TransportState {
  switch (action.type) {
    case TransportActionEnum.SET_TRANSPORT_LIST:
      return { ...state, transportList: action.payload };
    case TransportActionEnum.SET_CURRENT_TRANSPORT:
      return { ...state, selectedTransport: action.payload };
    case TransportActionEnum.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case TransportActionEnum.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page + 1,
          pageSize: action.payload.pageSize,
          hasNext: action.payload.hasNext,
        },
      };
    default:
      return state;
  }
}

const TransportContext = createContext<{
  state: TransportState;
  dispatch: React.Dispatch<TransportAction>;
}>({
  state: initialTransportState,
  dispatch: () => null,
});

interface TransportProviderProps {
  children: ReactNode;
}

export const TransportProvider: React.FC<TransportProviderProps> = ({
  children,
}: TransportProviderProps) => {
  const [state, dispatch] = useReducer(TransportReducer, initialTransportState);
  return (
    <TransportContext.Provider value={{ state, dispatch }}>
      {children}
    </TransportContext.Provider>
  );
};

export const useTransportsContext = () => useContext(TransportContext);
