import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import { t } from 'i18next';
import { Variant } from 'services/product/interface';

export function ImageColumn() {
  return {
    title: t('productTableKeys.image'),
    render: (variant: Variant) => <ThumbnailRender variant={variant} />,
  };
}
