import { PurchaseStatus } from 'services/purchase/interface';

export const changePurchaseStatusAccess: {
  [key: string]: string[];
} = {
  CREATE: [PurchaseStatus.PENDING],
  [PurchaseStatus.PENDING]: [
    PurchaseStatus.PENDING,
    PurchaseStatus.IN_TRANSIT,
    PurchaseStatus.CANCELLED,
  ],
  [PurchaseStatus.IN_TRANSIT]: [
    PurchaseStatus.IN_TRANSIT,
    PurchaseStatus.PENDING,
    PurchaseStatus.DELIVERED,
    PurchaseStatus.CANCELLED,
  ],
  [PurchaseStatus.DELIVERED]: [PurchaseStatus.DELIVERED],
  [PurchaseStatus.CANCELLED]: [PurchaseStatus.CANCELLED],
};
