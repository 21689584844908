import { useEffect, useState } from 'react';
import { GenericOption } from '../interface';

const useObjectSelect = (value?: GenericOption) => {
  const localId = useState<string>(value?.id || '');
  const [localIdState, setLocalId] = localId;
  useEffect(() => {
    setLocalId(value?.id || '');
  }, [value?.id, setLocalId]);
  return { localId };
};

export default useObjectSelect;
