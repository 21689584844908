/* eslint-disable no-param-reassign */
import { Table } from 'antd';
import useVariantTable from './hooks/useVariantTable';
import { VariantContent } from './interface';

export const VariantTable = ({
  data,
  onDeleteVariant,
  onUpdateVariant,
  onGoToPWA,
}: {
  data: VariantContent[];
  onDeleteVariant: (productId: string, variantId: string) => void;
  onUpdateVariant: (v: VariantContent) => void;
  onGoToPWA: (productUrlName: string) => void;
}) => {
  const { columns, hiddenDescription } = useVariantTable({
    onDeleteVariant,
    onUpdateVariant,
    onGoToPWA,
  });

  return (
    <Table
      columns={columns}
      rowKey="id"
      expandable={{
        expandedRowRender: hiddenDescription,
        rowExpandable: () => true,
      }}
      dataSource={data}
    />
  );
};
