import { nullishProductBrand } from 'components/BrandsAutocomplete/interface';
import {
  defaultVariantFormValues,
  VariantFormValues,
} from 'components/VariantForm/interface';
import { VariantContent } from 'containers/Products/VariantList/interface';
import { useGetAttributeOptions } from 'helpers/hooks/useGetAttributeOptions/useGetAttributeOptions';
import { ProductFormContent } from 'pages/Products/CreateProduct/interface';
import { useEffect, useState } from 'react';
import {
  Product,
  StockObject,
  VariantStatus,
} from 'services/product/interface';
import { DEFAULT_VAT_PERCENTAGE } from '../../../../helpers/constants/variantForm';
import { DisplayVariant } from '../interface';

const defaultProductValues: ProductFormContent = {
  name: '',
  categoryId: '',
  imageUrl: '',
  description: '',
};

const defaultVariant: DisplayVariant = {
  id: '',
  name: '',
  variantSku: '',
  stock: {
    available: 0,
    availableInWarehouse: 0,
    notAvailableInWarehouse: 0,
    inWarehouse: 0,
    block: 0,
    sold: 0,
    cap: 0,
  },
  price: 0,
  discount: 0,
  cost: 0,
  volumePrice: [],
  urlName: '',
  productId: '',
  attributeId: '',
  description: '',
  rack: '',
  hall: '',
  status: VariantStatus.ACTIVE,
  position: '',
  level: '',
  images: [],
  tags: [],
  designTags: '',
  commission: 0,
  dimensions: {
    width: 0,
    length: 0,
    height: 0,
    weight: 0,
  },
  brand: nullishProductBrand,
  isTaxExcluded: false,
  vatPercentage: DEFAULT_VAT_PERCENTAGE,
};

const useUpdateProduct = (
  id: string,
  getProductByUrlName: (id: string) => void,
  product: Product | undefined,
) => {
  const productFormContentState =
    useState<ProductFormContent>(defaultProductValues);
  const variantFormValuesState = useState<VariantFormValues>(
    defaultVariantFormValues,
  );
  const variantDataState = useState<VariantContent[]>([]);
  const targetVariantState = useState<VariantContent>();
  const showVariantDrawerState = useState<boolean>(false);
  const createVariantState = useState<any>(false);
  const createComboState = useState<boolean>(false);
  const updateComboState = useState<boolean>(false);
  const stockChangeState = useState<{
    variantId: string;
    stock: StockObject;
  }>();
  const changedAttributeLevelsState = useState<boolean>(false);
  const attributeParams = useGetAttributeOptions(product?.attributeLevels);

  useEffect(() => {
    getProductByUrlName(String(id));
  }, [getProductByUrlName, id]);
  const setProductFormContent = productFormContentState[1];
  const [_variantData, setVariantData] = variantDataState;
  const setVariantFormValues = variantFormValuesState[1];
  const setTargetVariant = targetVariantState[1];
  const [stockChange, setStockChange] = stockChangeState;

  useEffect(() => {
    if (product) {
      const variants = product.variants ?? [];
      const baseVariant = variants?.[0] ?? defaultVariant;
      if (baseVariant) {
        setTargetVariant(baseVariant);
        setVariantFormValues({
          name: baseVariant.name,
          variantSku: baseVariant.variantSku,
          attributes: baseVariant.attributes,
          stock: {
            ...baseVariant?.stock,
            inWarehouse:
              (baseVariant.stock?.availableInWarehouse ?? 0) +
              (baseVariant.stock?.notAvailableInWarehouse ?? 0),
          },
          description: baseVariant.description,
          tags: baseVariant.tags,
          designTags: baseVariant.designTags,
          price: baseVariant.price,
          cost: baseVariant.cost,
          rack: baseVariant.rack,
          volumePrice: baseVariant.volumePrice,
          level: baseVariant.level,
          position: baseVariant.position,
          hall: baseVariant.hall,
          status: baseVariant.status,
          ranking: baseVariant.ranking,
          brand: baseVariant.brand,
          video: baseVariant.video,
          mps: baseVariant.mps,
          cap: baseVariant.cap,
          isTaxExcluded: baseVariant.isTaxExcluded,
          vatPercentage: baseVariant.vatPercentage,
          commission: baseVariant.commission,
        });
      }
      setProductFormContent({
        name: product.name,
        categoryId: product.categoryId,
        imageUrl: product.imageUrl,
        description: product.description,
        brand: product.brand,
        attributeLevels: product.attributeLevels ?? {},
        revisionStatus: product?.revisionStatus,
      });
      setVariantData(variants || []);
    }
  }, [
    product,
    setProductFormContent,
    setVariantData,
    setVariantFormValues,
    setTargetVariant,
  ]);

  useEffect(() => {
    if (!product?.variants) return;
    if (stockChange?.variantId) {
      setVariantData((prev) => {
        const varIdx = prev.findIndex(
          (variant) => variant.id === stockChange?.variantId,
        );
        if (varIdx > -1) {
          const productVariants = [...(product.variants || [])];
          productVariants[varIdx].stock = stockChange.stock;
          return productVariants;
        }
        return prev;
      });
    }
  }, [product, setVariantData, stockChange?.stock, stockChange?.variantId]);

  return {
    productFormContentState,
    variantFormValuesState,
    variantDataState,
    targetVariantState,
    showVariantDrawerState,
    createVariantState,
    createComboState,
    updateComboState,
    changedAttributeLevelsState,
    attributeParams,
    setStockChange,
  };
};

export default useUpdateProduct;
