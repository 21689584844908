import { Divider, Input, Table } from 'antd';
import { TableWrapper } from 'components/TableWrapper';
import { directPurchaseVariantSearchFilter } from 'helpers/utils/directVariantSearchFilter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseVariant } from 'services/purchase/interface';
import { VariantsTableProps } from './interface';
import { columns, COLUMNS_TO_SHOW } from './VariantTableColumns';

const PurchaseVariantList = ({
  list = [],
  readOnly,
  isCreate,
  isMarketplace,
  purchaseStatus,
  onRemovePurchaseVariant,
  onChangeValues,
}: VariantsTableProps) => {
  const [search, setSearch] = useState<string>('');
  const [filteredList, setFilteredList] = useState<PurchaseVariant[]>(list);
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredList(directPurchaseVariantSearchFilter(list, search));
  }, [list, setFilteredList, search]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      setFilteredList(directPurchaseVariantSearchFilter(list, val));
      setSearch(val);
    },
    [list, setFilteredList, setSearch],
  );

  const tableColumns = useMemo(() => {
    const rawColumns = columns({
      onChangeValues,
      onRemovePurchaseVariant,
      readOnly,
      isCreate,
      purchaseStatus,
    });
    return rawColumns.filter((col) => {
      const orderType = isMarketplace ? 'MARKETPLACE' : 'REGULAR';
      const view = isCreate ? 'CREATE' : 'UPDATE';
      const validColumns = COLUMNS_TO_SHOW[orderType][view][purchaseStatus];

      return validColumns.includes(col.id);
    });
  }, [
    onChangeValues,
    onRemovePurchaseVariant,
    readOnly,
    isCreate,
    purchaseStatus,
    isMarketplace,
  ]);

  const getRecordId = useCallback(
    (record: PurchaseVariant) => record.id || '',
    [],
  );

  return (
    <>
      <Input.Search
        style={{ width: '100%', minWidth: '100%' }}
        value={search}
        onChange={handleSearch}
        placeholder={t('variantSearch.placeholder')}
      />
      <Divider />
      <TableWrapper>
        <Table
          rowKey={getRecordId}
          columns={tableColumns.filter((col) =>
            readOnly
              ? col.title !== t('purchaseForm.variantTable.columns.actions')
              : true,
          )}
          dataSource={filteredList}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 500, x: 1000 }}
        />
      </TableWrapper>
    </>
  );
};

export default PurchaseVariantList;
