import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';
import { TransferCardWrapper } from './TransferCard.sty';

export const ResultCardWrapper = styled(TransferCardWrapper)`
  padding: 20px 0;
  border-bottom: 1px solid ${BaseColors.darky_gray};
`;

export const TransferCardInfoWrapper = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 0 5px;
`;

export const ResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const ResultLabel = styled.p`
  margin: 0;
`;

export const ErrorIcon = styled(CloseCircleOutlined)`
  color: ${BaseColors.error_red};
`;

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: ${BaseColors.success_green};
`;
