/* eslint-disable no-underscore-dangle */
import { MPS } from 'services/MPS/interface';
import { Supplier } from 'services/purchase/interface';

export const MpsToSupplier = (mps: MPS): Supplier => ({
  id: mps._id,
  name: mps.name,
  documentNumber: mps.document,
  location: {
    address: mps.address,
    cityCode: mps.cityCode,
    cityName: mps.city,
    departmentCode: mps.departmentCode,
    departmentName: mps.department,
  },
  contactName: mps.contactName,
  email: mps.email,
  phoneNumber: mps.phoneNumber,
  leadTime: mps.delay ?? 0,
});
