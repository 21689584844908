import React from 'react';

const value = {
  urlProductAPI: process.env.REACT_APP_API_URL,
};
const GlobalContext = React.createContext(value);

const GlobalContextProvider: React.FC = ({ children }) => (
  <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
);

export const useGlobalContext = () => React.useContext(GlobalContext);

export default GlobalContextProvider;
