/* eslint-disable max-len */
import { Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Attribute } from 'containers/Products/Attributes/interface';
import { parseVariantName } from 'helpers/utils/parseVariantName';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VariantFormValues } from '../interface';

const useVariantForm = (
  initialValues: VariantFormValues,
  productName: string | undefined,
  attributeParams: {
    defaultValues: Attribute[];
    loadingAttributesList: boolean;
    attributeOptions: Record<string, Attribute[]> | undefined;
    attributeTypeNames: string[];
  },
) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const translation = useTranslation();

  const fileListState = useState<UploadFile[]>(initialValues.images || []);
  const setFileList = fileListState[1];

  useEffect(() => {
    const { attributes } = initialValues;
    const defaultAttributes =
      (attributes?.length || 0) > 0
        ? attributes
        : attributeParams.defaultValues;

    form.setFieldsValue({
      ...initialValues,
      name:
        initialValues?.name ||
        parseVariantName(productName || '', defaultAttributes || []),
      discount:
        initialValues.price && initialValues.discount
          ? initialValues.discount
          : 0,
      attributes: defaultAttributes,
    });
    setFileList(initialValues.images || []);
  }, [
    initialValues,
    form,
    setFileList,
    productName,
    attributeParams.loadingAttributesList,
    attributeParams.defaultValues,
  ]);

  const onAttributesChange = useCallback(
    (values: Partial<VariantFormValues>, allValues: VariantFormValues) => {
      if (values.attributes) {
        setFieldsValue({
          name: parseVariantName(productName || '', allValues.attributes || []),
        });
      }
    },
    [setFieldsValue, productName],
  );

  return {
    translation,
    fileListState,
    setFieldsValue,
    onAttributesChange,
    form,
  };
};

export default useVariantForm;
