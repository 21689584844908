import { Col, Form, InputNumber, Row } from 'antd';
import { CreditForm } from 'containers/Users/Credit/interface';
import UserAutocomplete from 'containers/Users/UserAutocomplete/UserAutocomplete';
import { numberToCurrency } from 'helpers/utils/currency';
import { useTranslation } from 'react-i18next';
import { LABEL_COL, WRAPPER_COL } from '../constants';
import { CreditFormProps } from '../interface';

const CreditLineForm = ({ initialValues }: CreditFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CreditForm>();

  return (
    <Form scrollToFirstError initialValues={initialValues} form={form}>
      <Row gutter={[64, 0]} style={{ paddingLeft: '24px' }}>
        <Col span={12}>
          <Form.Item
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            name="user"
            label={t('creditsForm.labels.userName')}
            rules={[{ required: true, message: t('BOGENE003') }]}
          >
            <UserAutocomplete
              queryParams={{
                pagination: {
                  page: 1,
                  pageSize: 10,
                },
              }}
              disabled
            />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={LABEL_COL}
            wrapperCol={WRAPPER_COL}
            name="authorizedCredit"
            label={t('creditsForm.labels.authorizedCredit')}
            rules={[{ required: true, message: t('BOGENE003') }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              disabled
              formatter={(value) => numberToCurrency(Number(value ?? ''))}
              parser={(value) => numberToCurrency(Number(value ?? ''))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelAlign="left"
            wrapperCol={{
              xl: { span: 16 },
              lg: { span: 14 },
              sm: { span: 11 },
            }}
            labelCol={{
              sm: { span: 6 },
            }}
            name="fee"
            label={t('creditsForm.labels.fee')}
            rules={[{ required: true, message: t('BOGENE003') }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}%`}
              parser={(value) => Number(value?.replace('%', ''))}
              disabled
            />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            wrapperCol={{
              xl: { span: 16 },
              lg: { span: 14 },
              sm: { span: 11 },
            }}
            labelCol={{
              sm: { span: 6 },
            }}
            name="cupo"
            label={t('creditsForm.labels.cupo')}
            rules={[{ required: true, message: t('BOGENE003') }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              disabled
              formatter={(value) => numberToCurrency(Number(value ?? ''))}
              parser={(value) => numberToCurrency(Number(value ?? ''))}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditLineForm;
