import { Method } from 'axios';

export const endpoints = {
  getMpsUserRequest: {
    method: 'GET' as Method,
    url: 'mps-user-dom/mps-user/request',
    baseURL: process.env.REACT_APP_API_URL,
  },
  updateMpsUserRequest: {
    method: 'PUT' as Method,
    url: 'mps-user-dom/mps-user/request',
    baseURL: process.env.REACT_APP_API_MPS_REQUEST_URL,
  },
};
