/* eslint-disable react/require-default-props */
import { Form, FormInstance, Tabs, TabsProps } from 'antd';
import { shippingZoneToFormInitial } from 'modules/ShippingZones/helpers';
import {
  IShippingZoneForm,
  ShippingZoneLoadingStates,
} from 'modules/ShippingZones/interface';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  City,
  GetShippingRegionalConfigParams,
  RegionalConfig,
  ShippingZones,
} from 'services/cities/interface';
import { ShippingZoneFormTabs } from './interface';
import RegionalConfigInputs from './RegionalConfigInputs';
import ShippingZoneInputs from './ShippingZoneInputs';
import { TabVisibilityWrapper } from './ShippingZoneInputs.sty';

const ShippingZoneForm = ({
  form,
  initialValues,
  disabled = false,
  citiesOptionList,
  transportOptionList,
  regionOptionList,
  onSubmit,
  fetchRegionalConfig,
  searchCityCode,
  loadingStates,
}: {
  form: FormInstance<IShippingZoneForm>;
  citiesOptionList: LabelValue<string, City>[];
  transportOptionList: LabelValue<string>[];
  regionOptionList: LabelValue<string>[];
  disabled?: boolean;
  initialValues?: ShippingZones;
  onSubmit: () => void;
  fetchRegionalConfig: (
    params: GetShippingRegionalConfigParams,
  ) => Promise<RegionalConfig>;
  searchCityCode: (term: string) => void;
  loadingStates: ShippingZoneLoadingStates;
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<ShippingZoneFormTabs>(
    ShippingZoneFormTabs.shippingZone,
  );
  const formInitial = useMemo(
    () => shippingZoneToFormInitial(initialValues),
    [initialValues],
  );

  const tabs = useMemo(
    (): TabsProps['items'] => [
      {
        key: ShippingZoneFormTabs.shippingZone,
        label: t('shippingZone.form.tabs.shippingZoneConfig'),
      },
      {
        key: ShippingZoneFormTabs.regional,
        label: t('shippingZone.form.tabs.regionalConfig'),
      },
    ],
    [t],
  );

  const onChangeTab = useCallback((cTab: string) => {
    setTab(cTab as ShippingZoneFormTabs);
  }, []);

  return (
    <Form<IShippingZoneForm>
      form={form}
      initialValues={formInitial}
      layout="vertical"
      disabled={disabled}
      onFinish={onSubmit}
    >
      <Tabs items={tabs} onChange={onChangeTab} />
      <TabVisibilityWrapper visible={tab === ShippingZoneFormTabs.shippingZone}>
        <ShippingZoneInputs
          form={form}
          citiesOptionList={citiesOptionList}
          transportOptionList={transportOptionList}
          searchCityCode={searchCityCode}
          cityCodesLoading={loadingStates.loadingCityCodes}
          disabled={disabled}
        />
      </TabVisibilityWrapper>
      <TabVisibilityWrapper visible={tab === ShippingZoneFormTabs.regional}>
        <RegionalConfigInputs
          form={form}
          regionOptionList={regionOptionList}
          disabled={disabled}
          fetchRegionalConfig={fetchRegionalConfig}
        />
      </TabVisibilityWrapper>
    </Form>
  );
};

export default ShippingZoneForm;
