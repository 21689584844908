/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
import { DollarOutlined, FieldTimeOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import ComplexMultiSelect from 'components/ComplexMultiSelect/ComplexMultiSelect';
import { DefaultStatusEnum } from 'helpers/constants/Tags';
import { BaseColors } from 'helpers/constants/theme';
import {
  getCurrencyInputRules,
  getDefaultInputRules,
  getDeliveryDayOptions,
  getFreeShippingLimitValidators,
  getNumberInputRules,
  getStringInputRules,
  InputNumberDefProps,
} from 'modules/ShippingZones/constants';
import {
  cityToLabelValue,
  currencyParser,
  dayParser,
  formatCurrency,
  formatDay,
  getCostOperatorSelectValues,
} from 'modules/ShippingZones/helpers';
import { IShippingZoneForm } from 'modules/ShippingZones/interface';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { useTranslation } from 'react-i18next';
import { City } from 'services/cities/interface';
import {
  FormItemLSize,
  FormItemSSize,
  OptionStatusWrapper,
} from './ShippingZoneInputs.sty';

const ShippingZoneInputs = ({
  form,
  citiesOptionList,
  transportOptionList,
  searchCityCode,
  cityCodesLoading,
  disabled,
}: {
  form: FormInstance<IShippingZoneForm>;
  citiesOptionList: LabelValue<string, City>[];
  transportOptionList: LabelValue<string>[];
  searchCityCode: (term: string) => void;
  cityCodesLoading: boolean;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const defaultInputRules = getDefaultInputRules(t);
  const numberInputRules = getNumberInputRules(t);
  const stringInputRules = getStringInputRules(t);
  const currencyInputRules = getCurrencyInputRules(t);
  const deliveryDayOptions = getDeliveryDayOptions(t);
  const costOperatorOptions = getCostOperatorSelectValues(t);

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          rules={stringInputRules}
          name="name"
          label={t('shippingZone.form.label.name')}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="zoneCity"
          rules={defaultInputRules}
          label={t('shippingZone.form.label.cityCodes')}
          required
          getValueFromEvent={() => form.getFieldValue('zoneCity')}
        >
          <ComplexMultiSelect<City, IShippingZoneForm>
            form={form}
            formValueName="zoneCity"
            indexDataProp="_id"
            optionList={citiesOptionList}
            loadingData={cityCodesLoading}
            handleSearch={searchCityCode}
            renderValue={(city) => cityToLabelValue(city).label}
            selectProps={{
              filterOption: false,
            }}
            formDisabled={disabled}
            placeholder={t('shippingZone.form.label.searchCity')}
          />
        </Form.Item>
        <p>{t('shippingZone.form.label.earnCost')}</p>
        <Row>
          <FormItemSSize
            name={['earnCost', 'operator']}
            required
            tooltip={t('shippingZone.form.tooltip.freeShippingEnabled')}
          >
            <Select options={costOperatorOptions} />
          </FormItemSSize>
          <FormItemLSize
            name={['earnCost', 'value']}
            required
            tooltip={t('shippingZone.form.tooltip.freeShippingEnabled')}
          >
            <InputNumber
              style={InputNumberDefProps.style}
              formatter={formatCurrency}
              parser={currencyParser}
            />
          </FormItemLSize>
        </Row>
        <p>{t('shippingZone.form.label.subsidy')}</p>
        <Row>
          <FormItemSSize
            name={['shippingSubsidy', 'operator']}
            required
            tooltip={t('shippingZone.form.tooltip.freeShippingEnabled')}
          >
            <Select options={costOperatorOptions} />
          </FormItemSSize>
          <FormItemLSize
            name={['shippingSubsidy', 'value']}
            required
            tooltip={t('shippingZone.form.tooltip.freeShippingEnabled')}
          >
            <InputNumber
              style={InputNumberDefProps.style}
              formatter={formatCurrency}
              parser={currencyParser}
            />
          </FormItemLSize>
        </Row>
        <Form.Item
          name="freeShippingEnabled"
          label={t('shippingZone.form.label.freeShippingEnabled')}
          valuePropName="checked"
          required
          tooltip={t('shippingZone.form.tooltip.freeShippingEnabled')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          rules={getFreeShippingLimitValidators(t, form)}
          name="freeShippingLimit"
          label={t('shippingZone.form.label.freeShippingLimit')}
          required
          dependencies={['minimumOrderAmount']}
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<DollarOutlined />}
            formatter={formatCurrency}
            parser={currencyParser}
          />
        </Form.Item>
        <Form.Item
          rules={currencyInputRules}
          name="minimumOrderAmount"
          label={t('shippingZone.form.label.minimumOrderValue')}
          required
          dependencies={['freeShippingLimit']}
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<DollarOutlined />}
            formatter={formatCurrency}
            parser={currencyParser}
          />
        </Form.Item>
        <Form.Item
          rules={currencyInputRules}
          name="maxWarrantyPrice"
          label={t('shippingZone.form.label.maxWarrantyValue')}
          required
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<DollarOutlined />}
            formatter={formatCurrency}
            parser={currencyParser}
          />
        </Form.Item>
        <Form.Item
          rules={currencyInputRules}
          name="shippingCost"
          label={t('shippingZone.form.label.shippingCost')}
          required
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<DollarOutlined />}
            formatter={formatCurrency}
            parser={currencyParser}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={defaultInputRules}
          name="status"
          label={t('shippingZone.form.label.status')}
          required
        >
          <Select>
            <Select.Option
              value={DefaultStatusEnum.ACTIVE}
              key={DefaultStatusEnum.ACTIVE}
            >
              <OptionStatusWrapper color={BaseColors.success_green}>
                {t('shippingZone.form.values.activeZone')}
              </OptionStatusWrapper>
            </Select.Option>
            <Select.Option
              value={DefaultStatusEnum.INACTIVE}
              key={DefaultStatusEnum.INACTIVE}
            >
              <OptionStatusWrapper color={BaseColors.error_red}>
                {t('shippingZone.form.values.inactiveZone')}
              </OptionStatusWrapper>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="deliveryDays"
          rules={defaultInputRules}
          label={t('shippingZone.form.label.deliveryDays')}
          required
        >
          <Select mode="multiple" options={deliveryDayOptions ?? []} />
        </Form.Item>
        <Form.Item
          name="transport"
          rules={defaultInputRules}
          label={t('shippingZone.form.label.transporter')}
          required
        >
          <Select options={transportOptionList ?? []} />
        </Form.Item>
        <Form.Item
          name="costTakings"
          label={t('shippingZone.form.label.costTakings')}
          required
          rules={numberInputRules}
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<DollarOutlined />}
            formatter={formatCurrency}
            parser={currencyParser}
          />
        </Form.Item>
        <Form.Item
          name="leadTime"
          label={t('shippingZone.form.label.leadTime')}
          required
          rules={numberInputRules}
        >
          <InputNumber
            style={InputNumberDefProps.style}
            prefix={<FieldTimeOutlined />}
            formatter={(n) => formatDay(t, n)}
            parser={dayParser}
          />
        </Form.Item>
        <Form.Item
          name="mediumMille"
          label={t('shippingZone.form.label.midMille')}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="isFreeCollectionCost"
          label={t('shippingZone.form.label.freeCollectionCost')}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ShippingZoneInputs;
