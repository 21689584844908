import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { PurchaseAction, PurchaseState } from '../reducer/interface';
import {
  INITIAL_PURCHASE_REDUCER,
  purchaseReducer,
} from '../reducer/purchase.reducer';

interface PurchaseContextTypes {
  state: PurchaseState;
  dispatch: Dispatch<PurchaseAction>;
}

const PurchaseContext = createContext<PurchaseContextTypes>(
  {} as PurchaseContextTypes,
);

export const PurchaseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    purchaseReducer,
    INITIAL_PURCHASE_REDUCER,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchase = () => useContext(PurchaseContext);
