/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, FormInstance, Input, Switch } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { NumberInput } from 'components/NumberInput';
import UploadItem from 'components/Upload/UploadItem';
import LocationDisplay from 'containers/General/LocationInput/LocationDisplay';
import LocationInput from 'containers/General/LocationInput/LocationInput';
import { numberValidator } from 'helpers/utils/formValidators';
import ScrollBottom from 'modules/Common/components/ScrollBottom';
import { getSupplierFormValidationRules } from 'modules/Suppliers/helpers/validationRules';
import { LocationSelectorProps } from 'modules/Suppliers/hooks/useLocationSelector';
import { Title } from 'pages/App/Layout.sty';
import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { SupplierForm as SupplierDTO } from 'services/MPS/interface';
import { BillingType } from 'services/product/interface';
import { SupplierLayoutContainer } from './SupplierForm.sty';

const labelCol = {
  xs: { span: 7 },
  sm: { span: 6 },
};
const wrapperCol = {
  xs: { span: 16 },
  sm: { span: 12 },
  xl: { span: 10 },
};

interface SupplierFormProps {
  isUpdate: boolean;
  initialValues?: SupplierDTO;
  t: TFunction;
  handleSubmit: (values: SupplierDTO) => void;
  handleRutUpload: (file: UploadFile) => void;
  handleRutDelete: (file: UploadFile) => void;
  isLoading: boolean;
  fileList: UploadFile[];
  locationSelectorProps: LocationSelectorProps;
  form: FormInstance;
}

export const SupplierForm = ({
  isUpdate,
  isLoading,
  form,
  fileList,
  initialValues,
  handleSubmit,
  handleRutUpload,
  handleRutDelete,
  t,
  locationSelectorProps: {
    showModal,
    modalLocation,
    handleCancel,
    handleLocationInputChange,
    handleConfirmChange,
    handleSelectLocation,
    addressSelectedState,
    locationMapRef,
  },
}: SupplierFormProps) => {
  const validationRules = useMemo(() => getSupplierFormValidationRules(t), [t]);

  return (
    <>
      <Modal
        title={t('addressInput.modal.title')}
        open={showModal}
        onCancel={handleCancel}
        onOk={handleConfirmChange}
      >
        <LocationInput
          onLoadMap={(map: google.maps.Map) => {
            locationMapRef.current = map;
          }}
          value={modalLocation}
          onChange={handleLocationInputChange}
          addressSelectedState={addressSelectedState}
        />
      </Modal>
      <Title level={1}>
        {t(`supplierForm.title.${isUpdate ? 'update' : 'create'}`)}
      </Title>
      <SupplierLayoutContainer id="supplier-form-container">
        <ScrollBottom container="#supplier-form-container" />
        <Form
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          form={form}
          id="supplier-form"
          name="create-supplier"
          onFinish={handleSubmit}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label={t('supplierForm.labels.name')}
            rules={validationRules.name}
          >
            <Input type="string" />
          </Form.Item>
          <Form.Item
            name="contactName"
            label={t('supplierForm.labels.contact')}
            rules={validationRules.contactName}
          >
            <Input type="string" />
          </Form.Item>
          <Form.Item
            name="document"
            label={t('supplierForm.labels.nit')}
            rules={validationRules.documentNumber}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('supplierForm.labels.email')}
            rules={validationRules.email}
          >
            <Input disabled={isUpdate} type="email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={t('supplierForm.labels.phoneNumber')}
            rules={validationRules.phoneNumber}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            name="delay"
            label={t('supplierForm.labels.delay')}
            rules={validationRules.delay}
          >
            <NumberInput type="number" />
          </Form.Item>
          {initialValues?.billingType === BillingType.SERVICE && (
            <>
              <Form.Item
                name="commission"
                label={t('supplierForm.labels.commission')}
                rules={[
                  {
                    validator: (_, value) =>
                      numberValidator(
                        value,
                        { min: 0, max: 100, required: false },
                        t,
                      ),
                  },
                ]}
              >
                <NumberInput type="number" />
              </Form.Item>
              <Form.Item
                name="mandatedBilling"
                label={t('supplierForm.labels.mandatedBilling')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </>
          )}
          <Form.Item
            label={<>{t('supplierForm.labels.locations')}</>}
            name="location"
            rules={validationRules.location}
          >
            <Form.List name="location">
              {(fields, { remove }) => (
                <>
                  {fields.map((field) => (
                    <div
                      key={field.key}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '15px',
                      }}
                    >
                      <Form.Item
                        {...field}
                        name={[field.name]}
                        style={{
                          margin: 0,
                        }}
                      >
                        <LocationDisplay
                          value={form.getFieldValue('location')[field.name]}
                          onEdit={() => handleSelectLocation(field.name)}
                        />
                      </Form.Item>
                      <span style={{ marginLeft: '2ch' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </span>
                    </div>
                  ))}
                  {(form.getFieldValue('location')?.length ?? 0) === 0 ? (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => handleSelectLocation(-1)}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t('userForm.actions.addLocation')}
                      </Button>
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            name="rut"
            label={t('supplierForm.labels.rut')}
            rules={validationRules.rut}
          >
            <UploadItem
              folder="RUTS"
              fileList={fileList}
              onUpload={handleRutUpload}
              onRemove={handleRutDelete}
              fileType="doc"
            />
          </Form.Item>
          <Divider>{t('supplierForm.subtitle.publicContact')}</Divider>
          <Form.Item
            name={['publicContact', 'phone']}
            label={t('supplierForm.labels.phoneNumber')}
            rules={validationRules.publicPhoneNumber}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 7, offset: 0 },
              sm: { span: 6, offset: 0 },
            }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isUpdate
                ? t('supplierForm.title.update')
                : t('supplierForm.title.create')}
            </Button>
          </Form.Item>

          <Form.Item name="_id" hidden />
          <Form.Item name="logisticType" hidden />
          <Form.Item name="billingType" hidden />
        </Form>
      </SupplierLayoutContainer>
    </>
  );
};
