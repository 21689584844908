import { CategoryForm } from 'containers/Categories/CategoryForm/CategoryForm';
import { CategoryFormValues } from 'containers/Categories/CategoryForm/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useTranslation } from 'react-i18next';
import useNotifications from 'helpers/hooks/useNotifications';
import { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import useUpdateCategory from './hooks/useUpdateCategory';

const initialValues: CategoryFormValues = {
  name: '',
  parentId: '',
  description: '',
  urlName: '',
  industry: '',
  imageUrl: '',
  isParent: false,
  landingSubcategory: false,
};

export const UpdateCategory = () => {
  const {
    readByParamQuery: { mutate: getCategoryByUrlName, data: defaultCategory },
    updateMutation: {
      mutateAsync: updateCategoryAsync,
      isLoading: isLoadingDelete,
    },
  } = useModuleCRUD('categories');
  const {
    categoryState: [categoryInitialValues],
    loadingState: [isLoading],
    navigate,
  } = useUpdateCategory(initialValues, getCategoryByUrlName, defaultCategory);
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.updateCategory}`);
  const { t } = useTranslation();
  const { notifySuccess } = useNotifications({
    translationFunction: t,
  });

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  const onSubmit = useCallback(
    async (values: CategoryFormValues) => {
      const reqBody = {
        id: defaultCategory.id,
        name: values.name,
        description: values.description,
        urlName: values.urlName,
        industry: values.industry,
        imageUrl: values.imageUrl,
        parentId: !values.isParent ? values.parentId : null,
        landingSubcategory: values.landingSubcategory,
      };
      await updateCategoryAsync(reqBody);
      navigate(`${ROUTES.categoryList}`);
    },
    [defaultCategory, navigate, updateCategoryAsync],
  );

  if (isLoading) return null;

  return (
    <CategoryForm
      updateCategory
      title="updateCategory"
      initialValues={categoryInitialValues}
      onSubmit={onSubmit}
      isLoading={isLoadingDelete}
    />
  );
};
