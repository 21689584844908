import {
  AlertOutlined,
  BlockOutlined,
  DropboxOutlined,
  LockOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { BaseColors } from 'helpers/constants/theme';
import { StockObject } from 'services/product/interface';

export interface IStockProp {
  icon: React.ReactNode;
  title: string;
  valuePath: keyof StockObject;
  tooltip: string;
  color: string;
}

export const ShowedStockProperties: IStockProp[] = [
  {
    icon: <ShoppingCartOutlined />,
    title: 'stock.container.labels.activeUnits',
    valuePath: 'available',
    tooltip: 'stock.container.tooltip.activeUnits',
    color: BaseColors.jelly_bean,
  },
  {
    icon: <LockOutlined />,
    title: 'stock.container.labels.blockedUnits',
    valuePath: 'block',
    tooltip: 'stock.container.tooltip.blockedUnits',
    color: BaseColors.dark_gray,
  },
  {
    icon: <AlertOutlined />,
    title: 'stock.container.labels.inactiveUnits',
    valuePath: 'notAvailableInWarehouse',
    tooltip: 'stock.container.tooltip.inactiveUnits',
    color: BaseColors.error_red,
  },
  {
    icon: <DropboxOutlined />,
    title: 'stock.container.labels.warehouseUnits',
    valuePath: 'availableInWarehouse',
    tooltip: 'stock.container.tooltip.warehouseUnits',
    color: BaseColors.deep_blue,
  },
  {
    icon: <BlockOutlined />,
    title: 'stock.container.labels.capUnits',
    valuePath: 'cap',
    tooltip: 'stock.container.tooltip.capUnits',
    color: BaseColors.light_yellow,
  },
];
