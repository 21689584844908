import useNotifications from 'helpers/hooks/useNotifications';
import { transferUnitsAtom } from 'helpers/recoil/atoms/transfers';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ITransferUnits, TransferUnitsDTO } from 'services/transfers/interface';
import { unitBatchTransfer } from 'services/transfers/transfer.service';
import { ITransferToDTO } from '../pipes';

const useTransfers = ({ onTransferCb }: { onTransferCb?: () => void }) => {
  // ================================ states & hooks =================================
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [transfersResult, setTransfersResult] = useState<
    (TransferUnitsDTO & ITransferUnits)[] | null
  >(null);
  const [items, setItems] = useRecoilState(transferUnitsAtom);
  const { t } = useTranslation();
  const { notifyError } = useNotifications({ translationFunction: t });

  // ================================== callbacks ====================================
  const transferItems = useCallback(async () => {
    setTransferLoading(true);
    const transferItemsValues = Object.values(items);
    try {
      const transfers = transferItemsValues.map((item) => ITransferToDTO(item));
      const transferResult = (await unitBatchTransfer(
        transfers,
      )) as TransferUnitsDTO[];

      const syncedResults = transferResult.map((item) => {
        const path1 = `${item.variantId}|${item.originLocation}|${item.status}`;
        const foundItem = items[path1] ?? {};
        return {
          ...foundItem,
          ...item,
        };
      });

      setItems({});
      setTransfersResult(syncedResults);
    } catch (error) {
      console.error(error);
      notifyError();
    } finally {
      onTransferCb?.();
      setTransferLoading(false);
    }
  }, [setTransferLoading, items, notifyError, setItems, onTransferCb]);

  const clearTransferResult = useCallback(() => {
    setTransfersResult(null);
  }, [setTransfersResult]);

  return {
    transferItems,
    transferLoading,
    transfersResult,
    clearTransferResult,
  };
};

export default useTransfers;
