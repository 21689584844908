import { ErrorMessageObject } from './development.errors';

export const wmsErrors: { [key: string]: ErrorMessageObject } = {
  'WMS-LOC013': {
    translation: 'httpErrors.wms.transfersBetweenWarehouses',
    isBlocking: false,
  },
  'WMS-UNT007': {
    translation: 'httpErrors.wms.locationVariantListEmpty',
    isWarning: true,
    isBlocking: false,
  },
};
