import { Modal, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import Paragraph from 'antd/lib/typography/Paragraph';
import { BrandsModalAction } from 'components/BrandForm/interface';
import { BrandModalProps } from './interface';
import useBrandForm from '../BrandForm/hooks/useBrandForm';

const DeleteModal = ({
  isOpen,
  closeModal,
  initialValues,
  reLoadOnSuccess,
}: BrandModalProps) => {
  const { t } = useTranslation();

  const { onDeleteModal } = useBrandForm(
    BrandsModalAction.DELETE,
    initialValues?.id,
    closeModal,
    reLoadOnSuccess,
  );

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      destroyOnClose
      onOk={onDeleteModal}
    >
      <PageHeader title={t('brandForm.titles.delete')} />
      <Paragraph>
        {t('brandForm.subtitle.deleteModal', {
          brandName: initialValues?.name,
        })}
      </Paragraph>
    </Modal>
  );
};

export default DeleteModal;
