import { Tag } from 'antd';
import {
  RuleDefinition,
  RuleOption,
  RuleTypeEnum,
  WmsSetting,
} from 'services/wms-settings/interface';

const RuleResume = ({
  setting,
  rule,
}: {
  setting: WmsSetting;
  rule: RuleDefinition;
}) => {
  const optionsMap: Record<string, RuleOption> = {};

  setting.allowedRuleProps.forEach((ruleDef) => {
    ruleDef.options.forEach((option) => {
      optionsMap[option.value] = option;
    });
  });

  if (rule.evaluatorType === RuleTypeEnum.STR_ARR) {
    return (
      <div>
        {(rule.evaluator as string[]).map((evaluator) => (
          <Tag key={evaluator}>{optionsMap[evaluator].label}</Tag>
        ))}
      </div>
    );
  }

  return <Tag>{optionsMap[rule.evaluator as string].label}</Tag>;
};

export default RuleResume;
