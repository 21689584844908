import {
  City,
  Regional,
  ShippingZones,
  Transport,
} from 'services/cities/interface';

export interface LabelValue<T, A = unknown, L = string> {
  label: L;
  value: T;
  addon?: A;
}

export enum SHIPPING_ZONE_UPDATE_ACTION_ENUM {
  TURN_EDITION_MODE = 'TURN_EDITION_MODE',
  SET_SHIPPING_ZONE = 'SET_SHIPPING_ZONE',
  SET_TRANSPORT_LIST = 'SET_TRANSPORT_LIST',
  SET_REGION_LIST = 'SET_REGION_LIST',
  SET_CITIES_LIST = 'SET_CITIES_LIST',
}

export enum SHIPPING_ZONE_CREATE_ACTION_ENUM {
  SET_TRANSPORT_LIST = 'SET_TRANSPORT_LIST',
  SET_REGION_LIST = 'SET_REGION_LIST',
  SET_CITIES_LIST = 'SET_CITIES_LIST',
}

export type ShippingZoneUpdateAction = {
  type: SHIPPING_ZONE_UPDATE_ACTION_ENUM;
  payload: any;
};

export type ShippingZoneCreateAction = {
  type: SHIPPING_ZONE_CREATE_ACTION_ENUM;
  payload: any;
};

export interface ShippingZoneUpdateState {
  shippingZone: ShippingZones;
  editionMode: boolean;
  regionOptionList: LabelValue<string>[];
  regionList: Regional[];
  transportOptionList: LabelValue<string>[];
  transportList: Transport[];
  citiesOptionList: LabelValue<string, City>[];
  citiesList: City[];
}

export type ShippingZoneCreateState = Omit<
  ShippingZoneUpdateState,
  'shippingZone' | 'editionMode'
>;
