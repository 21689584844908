/* eslint-disable react/prop-types */
import { createContext, useContext } from 'react';
import {
  ITransfersCsvContext,
  TRANSFERS_CSV_CONTEXT_DEFAULT_VALUES,
} from '../interface';
import useTransfersCsvContext from './useTransfersCsvContext';

const TransfersCsvContext = createContext<ITransfersCsvContext>(
  TRANSFERS_CSV_CONTEXT_DEFAULT_VALUES,
);

export const TransfersCsvProvider: React.FC = ({ children }) => {
  const transfers = useTransfersCsvContext();

  return (
    <TransfersCsvContext.Provider value={transfers}>
      {children}
    </TransfersCsvContext.Provider>
  );
};

export const useTransfersCsv = () => useContext(TransfersCsvContext);
