import Modal from 'antd/lib/modal/Modal';
import CreditLineFormContainer from 'containers/CreditLine/CreditLineFormContainer';
import { LightTheme } from 'helpers/constants/theme';
import { useTranslation } from 'react-i18next';
import { CreditModalProps } from '../interface';

const CreditModal = ({
  isOpen,
  closeModal,
  initialValues,
}: CreditModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="95%"
      visible={isOpen}
      onCancel={closeModal}
      onOk={closeModal}
      destroyOnClose
      okText={t('creditsForm.okButton')}
      okButtonProps={{
        style: {
          border: 'none',
          borderRadius: LightTheme.spaceSizes.xs,
          backgroundColor: LightTheme.color.primary,
          fontWeight: 'bold',
          color: LightTheme.color.white,
        },
      }}
    >
      <CreditLineFormContainer initialValues={initialValues} />
    </Modal>
  );
};

export default CreditModal;
