/* eslint-disable no-console */
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseToBrandEntity } from '../functions';
import { BrandFormInterface, BrandsModalAction } from '../interface';

const useBrandForm = (
  actionType?: BrandsModalAction,
  brandId?: string,
  closeModal?: () => void,
  reLoadOnSuccess?: () => void,
) => {
  const {
    createMutation: { mutateAsync: createBrand },
    updateMutation: { mutateAsync: updateBrand },
    deleteMutation: { mutateAsync: deleteBrand },
  } = useModuleCRUD('brands');

  const { t } = useTranslation();

  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });
  const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

  const onDeleteModal = async () => {
    try {
      await deleteBrand(brandId);
      notifySuccess();
      if (reLoadOnSuccess) reLoadOnSuccess();
      if (closeModal) closeModal();
    } catch (error: any) {
      console.error(error);
      notifyError();
    }
  };

  const submitForm = async (values: BrandFormInterface) => {
    const sendableBrand = parseToBrandEntity(values);
    if (actionType === BrandsModalAction.CREATE) {
      try {
        setIsLoadingAction(true);
        await createBrand(sendableBrand);
        notifySuccess();
        if (reLoadOnSuccess) reLoadOnSuccess();
        if (closeModal) closeModal();
      } catch (error: any) {
        console.error(error);
        setIsLoadingAction(false);
      }
      return;
    }
    try {
      setIsLoadingAction(true);
      await updateBrand({ id: brandId, ...sendableBrand });
      notifySuccess();
      if (reLoadOnSuccess) reLoadOnSuccess();
      if (closeModal) closeModal();
    } catch (error) {
      setIsLoadingAction(false);
      console.error(error);
      notifyError();
    }
  };
  return {
    submitForm,
    onDeleteModal,
    isLoadingAction,
  };
};

export default useBrandForm;
