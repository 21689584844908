import { ErrorMessageObject } from './development.errors';

export const creditErrors: { [key: string]: ErrorMessageObject } = {
  FNCC400001: {
    translation: 'httpErrors.credit.userHasCredit',
    isBlocking: true,
  },
  FNCC500002: {
    translation: 'httpErrors.credit.saveCreditFailed',
    isBlocking: true,
  },
  FNCR500001: {
    translation: 'httpErrors.credit.fetchFailed',
    isBlocking: true,
  },
  FNCU400001: {
    translation: 'httpErrors.credit.invalidAmortization',
    isBlocking: true,
  },
  FNCU400003: {
    translation: 'httpErrors.credit.invalidCreditParams',
    isBlocking: true,
  },
  FNCU500002: {
    translation: 'httpErrors.credit.updateCreditFailed',
    isBlocking: true,
  },
};
