import config from 'config';
import { VariantQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { Variant } from 'services/product/interface';

const getVariantList = async (queryParams?: VariantQueryParams) => {
  const response = await getAPIMethod({
    name: 'getProductVariantList',
    params: {
      search: queryParams?.search,
      ...queryParams?.pagination,
      inStock: queryParams?.inStock,
      mpsId: queryParams?.mpsId,
      logisticType: queryParams?.logisticType,
    },
  });
  return response.data.data;
};

const createVariant = async (variant: Variant) => {
  const response = await getAPIMethod({
    name: 'createProductVariant',
    data: variant,
  });
  return response.data;
};

export const recountVariant = async (variantId: string) => {
  const response = await getAPIMethod({
    name: 'recountVariant',
    pathParams: { variantId },
    headers: {
      password: config.httpPassword,
    },
  });
  return response.data;
};

const updateVariant = async (variant: Variant) => {
  const response = await getAPIMethod({
    name: 'updateVariantById',
    pathParams: { variantId: variant.id },
    data: variant,
  });
  return response.data;
};

const deleteVariant = async ({
  productId,
  variantId,
}: {
  productId: string;
  variantId: string;
}) => {
  const response = await getAPIMethod({
    name: 'deleteVariantById',
    pathParams: { productId, variantId },
  });
  return response.data;
};

const readByParam = async ({
  variantId,
  history,
  page,
  pageSize,
  withComboDetail,
}: {
  variantId: string;
  history?: boolean;
  page: string;
  pageSize: string;
  withComboDetail: string;
}) => {
  let response;

  if (history) {
    response = await getAPIMethod({
      name: 'getVariantHistory',
      pathParams: { variantId, page, pageSize },
    });
  } else {
    response = await getAPIMethod({
      name: 'getVariantById',
      pathParams: { variantId, withComboDetail },
    });
  }

  return response.data;
};

export default {
  create: createVariant,
  read: getVariantList,
  update: updateVariant,
  delete: deleteVariant,
  readByParam,
};
