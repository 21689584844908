import { SALE } from 'helpers/constants/Tags';

export const expectFromTagArray = (
  tags?: string[],
  expected?: string,
): boolean => {
  if (!tags) return false;
  const arrayHasExpected = tags.filter((tag) => tag === expected);
  return arrayHasExpected.length > 0;
};

export const getEffectiveDiscount = (
  tags?: string[],
  price?: number,
  discount?: number,
): number => {
  const someIsSale = expectFromTagArray(tags, SALE);
  if (someIsSale) {
    return Number((price || 0) - (discount || price || 0));
  }

  return 0;
};
