/* eslint-disable react/jsx-props-no-spreading */
import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { RefObject } from 'react';
import { Coupon } from 'services/coupons/interface';
import useCouponsTable from './hooks/useCouponsTable';

const TABLE_HEADER_HEIGHT = 160;

export const CouponsTable = ({
  data,
  isLoading,
  onScrollToEnd,
  reachedEndOfData = false,
  tableRef,
  onViewDetail,
}: {
  data: any[];
  isLoading: boolean;
  onScrollToEnd: (params?: any) => void;
  onViewDetail: (couponRow: Coupon) => void;
  reachedEndOfData: boolean;
  tableRef: RefObject<{ scrollToTop: () => void }>;
}) => {
  const { columns } = useCouponsTable();

  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="coupons-table"
      isLoading={isLoading}
      onRow={(record) => ({
        onClick: () => {
          onViewDetail(record as Coupon);
        },
      })}
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={data || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};
