/* eslint-disable no-underscore-dangle */
import { LocationAutocomplete } from 'components/LocationsAutocomplete/LocationsAutocomplete';
import { useTranslation } from 'react-i18next';
import { LOCATION_VALUE_CONFIGS } from '../constants';
import { ValueSelectorProps } from '../interface';
import { optionsToLocation, optionToLocation } from './optionParser';

const ValueSelector = ({
  settingType,
  setting,
  onSelectValue,
  defaultValue: defaultOption,
  disabled = false,
}: ValueSelectorProps) => {
  const { t } = useTranslation();

  if (LOCATION_VALUE_CONFIGS.includes(settingType)) {
    const defaultValue = defaultOption
      ? optionToLocation(defaultOption)
      : undefined;

    return (
      <LocationAutocomplete
        defaultValue={defaultValue}
        emptyResultsMsg={t(
          `wms-settings.setting.${setting?.setting}.addons.noSearchResults`,
        )}
        placeholder={t(
          `wms-settings.setting.${setting?.setting}.addons.searchPlaceholder`,
        )}
        isLeaf
        handleSelect={(location) =>
          onSelectValue({
            label: location?.name ?? '',
            value: location?._id ?? '',
          })
        }
        additionalOptions={optionsToLocation(setting?.otherAllowedValues ?? [])}
        disabled={disabled}
      />
    );
  }

  return null;
};

export default ValueSelector;
