/* eslint-disable react/jsx-wrap-multilines */
import { Collapse, Form } from 'antd';
import { t } from 'i18next';
import { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { useCategoryList } from '../../../helpers/hooks/useCategoryList/useCategoryList';
import CouponDetailsSubForm from './CouponDetailsSubForm';
import CouponDurationSubForm from './CouponDurationSubForm';
import { CouponFormModal } from './CouponModal.sty';
import CouponModalFooter from './CouponModalFooter';
import CouponModalTitle from './CouponModalTitle';
import CouponNameInfoSubForm from './CouponNameInfoSubForm';
import CouponOthersInfoSubForm from './CouponOthersInfoSubForm';
import { CouponProductSegSubForm } from './CouponProductSegSubForm';
import { CouponUserSegSubForm } from './CouponUserSegSubForm';
import useCouponModal from './hooks/useCouponModal';
import { CouponModalProps } from './interface';

const { Panel } = Collapse;

const CouponModalRef = (
  { coupon, isUpdate, onSubmit, isLoading }: CouponModalProps,
  ref: ForwardedRef<{
    openModal: () => void;
    closeModal: () => void;
    triggerEditable: () => void;
  }>,
) => {
  const {
    openModal,
    readOnly,
    canEdit,
    requireNominalValue,
    form,
    segmentation,
    initialValues,
    isCategorySegmentation,
    isBrandSegmentation,
    isSponsoredCoupon,
    sellers,
    brands,
    isUserSegmented,
    handleCanEdit,
    handleOpenModal,
    handleCloseModal,
    handleSubmitForm,
    handleValuesChange,
    handleSubmit,
  } = useCouponModal(coupon, isUpdate, onSubmit);

  const { categoryTree } = useCategoryList();

  useImperativeHandle(ref, () => ({
    openModal() {
      handleOpenModal();
    },
    closeModal() {
      handleCloseModal();
    },
    triggerEditable() {
      handleCanEdit();
    },
  }));

  return (
    <CouponFormModal
      title={
        <CouponModalTitle
          coupon={coupon}
          title={
            isUpdate ? 'couponModal.titles.update' : 'couponModal.titles.create'
          }
          readOnly={readOnly}
          canEdit={canEdit}
          onSetEditable={handleCanEdit}
        />
      }
      footer={
        <CouponModalFooter
          isLoading={isLoading}
          readOnly={readOnly}
          isUpdate={isUpdate}
          onClickFooter={handleSubmitForm}
        />
      }
      open={openModal}
      onCancel={handleCloseModal}
      width="95%"
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        name={`coupon-form-${coupon?.id ?? 'create'}`}
        autoComplete="off"
        onValuesChange={handleValuesChange}
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <CouponNameInfoSubForm readOnly={readOnly} isUpdate={isUpdate} />
        <Collapse defaultActiveKey={['1', '2', '3', '4']}>
          <Panel header={t('couponModal.form.tabs.titles.duration')} key="1">
            <CouponDurationSubForm
              readOnly={readOnly}
              getFieldValue={form.getFieldValue}
            />
          </Panel>
          <Panel header={t('couponModal.form.tabs.titles.details')} key="2">
            <CouponDetailsSubForm
              segmentation={segmentation}
              readOnly={readOnly}
              isUpdate={isUpdate}
              getFieldValue={form.getFieldValue}
              requireNominalValue={requireNominalValue}
            />
          </Panel>
          <Panel
            header={t('couponModal.form.tabs.titles.productSegmentation')}
            key="3"
          >
            <CouponProductSegSubForm
              isCategorySegmentation={isCategorySegmentation}
              isBrandSegmentation={isBrandSegmentation}
              isUpdate={isUpdate}
              brands={brands?.data ?? []}
              isSponsoredCoupon={isSponsoredCoupon}
              sellers={sellers?.data ?? []}
              readOnly={readOnly}
              getFieldValue={form.getFieldValue}
              categoryTree={categoryTree}
            />
          </Panel>
          <Panel
            header={t('couponModal.form.tabs.titles.userSegmentation')}
            key="4"
          >
            <CouponUserSegSubForm
              readOnly={readOnly}
              isUpdate={isUpdate}
              isUserSegmented={isUserSegmented}
              form={form as any}
              userIds={initialValues?.targetUsers ?? []}
            />
          </Panel>
          {coupon?.createdBy && (
            <Panel header={t('couponModal.form.tabs.titles.others')} key="5">
              <CouponOthersInfoSubForm coupon={coupon} />
            </Panel>
          )}
        </Collapse>
      </Form>
    </CouponFormModal>
  );
};

const CouponModal = forwardRef(CouponModalRef);

export default CouponModal;
