import { Modal, Row, Spin } from 'antd';
import { ROUTES } from 'helpers/constants/routes';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { Title } from 'pages/App/Layout.sty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CategoryTable } from './CategoryTable';
import useCategory from './hooks/useCategory';

export const CategoryContent = () => {
  const {
    readQuery: {
      mutate: getCategoryList,
      data: categoryList,
      isLoading: isLoadingList,
      isError: isErrorList,
    },
    deleteMutation: {
      mutateAsync: deleteCategoryAsync,
      isError: isErrorDelete,
      isLoading: isLoadingDelete,
    },
  } = useModuleCRUD('categories');

  const {
    deleteIdState: [deleteId, setDeleteId],
    visibleConfirmationState: [visibleConfirmation, setVisibleConfirmation],
  } = useCategory(getCategoryList);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const customMessage = {
    success: {
      message: 'categoryForm.modal.actions.success',
      description: 'categoryForm.modal.message.deleteSuccess',
    },
    error: {
      message: 'categoryForm.modal.actions.error',
      description: 'categoryForm.modal.message.deleteError',
    },
    cancel: {
      message: 'categoryForm.modal.actions.cancel',
      description: 'categoryForm.modal.message.cancel',
    },
  };
  const { notifySuccess, notifyError, notifyCancel } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const onDeleteCategory = async () => {
    try {
      await deleteCategoryAsync(deleteId);
    } catch (error) {
      notifyError();
      setVisibleConfirmation(false);
      return;
    }
    getCategoryList(undefined);
    setVisibleConfirmation(false);
    if (!isErrorDelete) {
      notifySuccess();
    } else if (isErrorDelete) {
      notifyError();
    }
  };

  const onCancel = () => {
    setDeleteId('');
    setVisibleConfirmation(false);
    notifyCancel();
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setVisibleConfirmation(true);
  };

  const onGoToUpdateCategory = (urlName: string) => {
    navigate(`${ROUTES.updateCategory}/${urlName}`);
  };

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <Modal
        visible={visibleConfirmation}
        onOk={onDeleteCategory}
        onCancel={onCancel}
        okText={t('categoryForm.modal.buttonTexts.ok')}
        cancelText={t('categoryForm.modal.buttonTexts.cancel')}
        title={t('categoryForm.modal.delete')}
        okButtonProps={{
          loading: isLoadingDelete,
        }}
      >
        {t('categoryForm.modal.content')}
      </Modal>
      <Title level={1}>{t('categoryList')}</Title>

      {isLoadingList || !categoryList || isLoadingDelete ? (
        <Row justify="center">
          <Spin tip={t('loading')} />
        </Row>
      ) : (
        !isErrorList && (
          <CategoryTable
            data={categoryList || []}
            onDeleteCategory={handleDelete}
            onGoToUpdateCategory={onGoToUpdateCategory}
          />
        )
      )}
    </React.Suspense>
  );
};
