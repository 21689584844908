import { ITransferCsvStatus } from 'containers/TransfersCsv/interface';
import { getAPIMethod } from 'helpers/libs/Http';
import { TransferUnitsDTO } from './interface';

export const getTransferCsvTemplate = async () => {
  const response = await getAPIMethod({ name: 'getTransferCsvTemplate' });
  return response;
};

export const getVariantsCsvStatus = async (): Promise<ITransferCsvStatus> => {
  const response = await getAPIMethod({ name: 'getVariantsCsvStatus' });
  return response.data as ITransferCsvStatus;
};

export const unitBatchTransfer = async (data: TransferUnitsDTO[]) => {
  const response = await getAPIMethod({
    name: 'unitBatchTransfer',
    data: { transfers: data },
  });
  return response.data;
};

export default {
  update: unitBatchTransfer,
  read: unitBatchTransfer,
  create: unitBatchTransfer,
  delete: unitBatchTransfer,
  readByParam: unitBatchTransfer,
};
