function StringToDateTime(str: string, withTime = false): Date {
  const divisionByFormat = str.split('T');

  if (divisionByFormat.length > 1) {
    if (withTime) {
      return new Date(str);
    }
    return new Date(divisionByFormat[0].replace(/-/g, '/'));
  }
  return new Date(str.replace(/-/g, '/'));
}

function Capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function ToColDate(
  date: string,
  withTime = false,
  boostTime = true,
): Date {
  let finalDate = StringToDateTime(date, withTime);

  if (boostTime) {
    const timeDiff = finalDate.getTimezoneOffset() + 1 * 60000;
    const adjustedDate = new Date(finalDate.valueOf() + timeDiff);

    finalDate = adjustedDate;
  }

  return finalDate;
}

export function ToColDateString(
  date: string | Date,
  withTime = false,
  boostTime = true,
) {
  const mutableDate = ToColDate(String(date), withTime, boostTime);

  const dateFormatQuery: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };

  if (withTime) {
    dateFormatQuery.hour = '2-digit';
    dateFormatQuery.minute = '2-digit';
    dateFormatQuery.hour12 = true;
  }

  return Capitalize(
    mutableDate.toLocaleDateString('es-ES', dateFormatQuery).replace(',', ''),
  );
}
