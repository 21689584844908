import { BaseColors } from 'helpers/constants/theme';
import { BoldLabel } from 'modules/Order/components/HistoryOrder/HistoryOrder.sty';
import { useTranslation } from 'react-i18next';
import { UnitMovementDefault, UnitRegistry } from './interface';

const OldMovementContent = ({ historyItem }: { historyItem: UnitRegistry }) => {
  const { t } = useTranslation();

  return (
    <>
      <BoldLabel color={BaseColors.deep_cove}>
        {t('variantUnitMovements.to', {
          destination: historyItem?.to ?? UnitMovementDefault.NO_STATUS,
        })}
      </BoldLabel>
      <br />
      <BoldLabel color={BaseColors.deep_cove}>
        {t('variantUnitMovements.quantity', {
          quantity: historyItem?.quantity ?? UnitMovementDefault.ZERO_QUANTITY,
        })}
      </BoldLabel>
    </>
  );
};

export default OldMovementContent;
