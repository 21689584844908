// Lib
// Components
import { useCallback } from 'react';
import { IUseUnitVariantTable } from '../interface';
import useUnitTableColumns from './useUnitVariantTableColumns';

const useUnitVariantTable = ({
  onChangeQuantity,
  handleSearch,
  handleClear,
  onSeeLocations,
}: IUseUnitVariantTable) => {
  const handleReset = useCallback(
    (clearFilters?: VoidFunction, confirm?: VoidFunction) => {
      clearFilters?.();
      confirm?.();
      handleClear();
    },
    [handleClear],
  );

  const columns = useUnitTableColumns({
    onChangeQuantity,
    handleSearch,
    handleReset,
    onSeeLocations,
  });

  return {
    columns,
  };
};

export default useUnitVariantTable;
