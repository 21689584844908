/* eslint-disable react/jsx-wrap-multilines */
import { useCallback, useState } from 'react';
import { Button, Row, Spin } from 'antd';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { ProductForm } from 'components/ProductForm/ProductForm';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import { ProductFormContent } from 'pages/Products/CreateProduct/interface';
import { Product } from 'services/product/interface';
import { useTranslation } from 'react-i18next';
import useNotifications from 'helpers/hooks/useNotifications';
import { ProductFormFooter } from './CreateOrUpdateProduct.sty';

const CreateAndUpdateProduct = ({
  onFinishStep,
  setSelectedBrand,
}: {
  onFinishStep: (product: Product) => void;
  setSelectedBrand: (brand: BrandDBEntity) => void;
}) => {
  const {
    createMutation: { mutateAsync: createProduct, isLoading: isLoadingProduct },
  } = useModuleCRUD('products');
  const { t } = useTranslation();

  const defaultProductValues: ProductFormContent = {
    name: '',
    categoryId: '',
    imageUrl: '',
    description: '',
  };

  const customMessage = {
    success: {
      message: 'productForm.modal.actions.success',
      description: 'productForm.modal.message.product.createSuccess',
    },
    error: {
      message: 'productForm.modal.actions.error',
      description: 'productForm.modal.message.product.createError',
    },
  };

  const { notifySuccess, notifyError } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const [brandValue, setBrandValue] = useState<BrandDBEntity>(
    defaultProductValues.brand as BrandDBEntity,
  );

  const onSubmitProductCreation = useCallback(
    async (product: ProductFormContent) => {
      try {
        const createdProduct = await createProduct({
          inventoryId: '',
          ...product,
          brand: brandValue.id ? brandValue : {},
        });
        notifySuccess();
        onFinishStep(createdProduct);
        setSelectedBrand(brandValue);
      } catch (error) {
        notifyError();
      }
    },
    [
      brandValue,
      notifyError,
      createProduct,
      notifySuccess,
      onFinishStep,
      setSelectedBrand,
    ],
  );

  if (isLoadingProduct) {
    return (
      <Row justify="center">
        <Spin tip="Cargando" />
      </Row>
    );
  }

  return (
    <ProductForm
      isUpdate={false}
      initialValues={defaultProductValues}
      formId="create-product"
      reset={false}
      onSubmit={onSubmitProductCreation}
      submitButton={
        <ProductFormFooter>
          <Button type="primary" size="large" htmlType="submit">
            {t('createProduct')}
          </Button>
        </ProductFormFooter>
      }
      setBrandValue={setBrandValue}
    />
  );
};

export default CreateAndUpdateProduct;
