/* eslint-disable react-hooks/exhaustive-deps */
import { PaginationParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ResponseMetadata } from 'services/global/interface';
import { getHaulierHistory } from 'services/haulier/haulier.service';
import { HaulierHistory } from 'services/haulier/interface';

const PAGE_SIZE = 10;

export const useHaulierFormContainer = (haulierNumber?: string) => {
  const [historyList, setHistoryList] = useState<HaulierHistory[]>([]);
  const [responseMetadata, setResponseMetadata] = useState<ResponseMetadata>();

  const { mutateAsync: fetchHaulierHistory, isLoading: loadingHistory } =
    useMutation({
      mutationFn: async ({
        haulierNum,
        pageParams,
      }: {
        haulierNum: string;
        pageParams: PaginationParams;
      }) => {
        const data = await getHaulierHistory(haulierNum, pageParams);
        return data;
      },
      onSuccess: (response) => {
        setHistoryList((prev) => [...prev, ...response.data]);
        setResponseMetadata(response.metadata);
      },
    });

  const fetchHaulierHistoryPage = useCallback(async () => {
    await fetchHaulierHistory({
      haulierNum: haulierNumber || '',
      pageParams: {
        page: (responseMetadata?.page ?? 0) + 1,
        pageSize: responseMetadata?.pageSize ?? PAGE_SIZE,
      },
    });
  }, [fetchHaulierHistory, haulierNumber, responseMetadata]);

  useEffect(() => {
    fetchHaulierHistoryPage();
  }, []);

  return {
    currentPage: responseMetadata?.page ?? 1,
    historyList,
    loadingHistory,
    hasNext: responseMetadata?.hasNext ?? true,
    fetchHaulierHistoryPage,
  };
};
