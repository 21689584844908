/* eslint-disable no-underscore-dangle */
import { LogisticType, MPS, SupplierForm } from 'services/MPS/interface';
import { BillingType } from 'services/product/interface';

export const parseSupplierFormToMPS = (values: SupplierForm): MPS => ({
  _id: values._id ?? '',
  name: values.name,
  contactName: values.contactName,
  address: values.location?.[0].address ?? '',
  document: String(values.document),
  email: values.email,
  delay: Number(values.delay ?? 0),
  phoneNumber: `+57${values.phoneNumber}`,
  logisticType: values.logisticType ?? LogisticType.TODAY,
  department: values.location?.[0]?.departmentName ?? '',
  departmentCode: values.location?.[0]?.departmentCode ?? '',
  city: values.location?.[0]?.cityName ?? '',
  cityCode: values.location?.[0]?.cityCode ?? '',
  rut: values.rut?.[0],
  isMarketplace: false,
  commission: values.commission,
  isSupplier: values.isSupplier,
  billingType: values.billingType ?? BillingType.PRODUCT,
  publicContact: values?.publicContact,
  mandatedBilling:
    values.billingType === BillingType.SERVICE
      ? values.mandatedBilling
      : undefined,
});

export const parseMPSToSupplierForm = (values: MPS): SupplierForm => {
  const parsed = {
    _id: values._id,
    name: values.name,
    contactName: values.contactName,
    document: values.document,
    email: values.email,
    delay: Number(values.delay ?? 0),
    logisticType: values.logisticType ?? LogisticType.TODAY,
    isSupplier: values.isSupplier,
    billingType: values.billingType ?? BillingType.PRODUCT,
    mandatedBilling: values.mandatedBilling,
    commission: values.commission,
    phoneNumber: values.phoneNumber.replace('+57', ''),
    location: [
      {
        address: values.address,
        cityCode: values.cityCode,
        cityName: values.city,
        departmentCode: values.departmentCode,
        departmentName: values.department,
      },
    ],
    rut: values.rut ? [values.rut] : [],
    publicContact: values?.publicContact,
  };

  return parsed;
};
