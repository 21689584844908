import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { t } from 'i18next';
import { uniq, uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  OrderType,
  PandasPaymentMethod,
  StatusOrderEnum,
} from 'services/order/interface';
import { updateOrderStatusBatch } from 'services/order/order.service';
import { getParameters } from 'services/parameters/parameters.service';
import { accessFromCurrentStatus } from '../constants/orderStatusMachine';
import { OrderContextTypes } from '../context/Order.context';
import { ORDER_ACTION_ENUM } from '../reducer/interface';

const { confirm } = Modal;

interface UseMultiStatusChangeProps extends OrderContextTypes {
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
}

const useMultiStatusChange = ({
  state,
  dispatch,
  notifySuccess,
  notifyError,
}: UseMultiStatusChangeProps) => {
  const currentStatus = useMemo(
    () => state.selectedOrders?.[0]?.status as StatusOrderEnum,
    [state.selectedOrders],
  );

  const [newStatus, setNewStatus] = useState<StatusOrderEnum>(
    state.selectedOrders?.[0]?.status as StatusOrderEnum,
  );

  const { mutateAsync: batchUpdateStatus, isLoading: loadingMassiveUpdate } =
    useMutation({
      mutationFn: updateOrderStatusBatch,
    });

  const { data } = useQuery({
    queryFn: () => getParameters({ keys: 'reasons' }),
    queryKey: ['cancel-reasons'],
    refetchOnWindowFocus: false,
  });

  const onOkCancel = useCallback(async () => {
    if (!newStatus) return;

    const unPaidOrders = state.selectedOrders?.filter(
      (s) =>
        s.paymentMethod === PandasPaymentMethod.BANK_TRANSFER &&
        !s.bankTransferPaid,
    );
    if (unPaidOrders.length && newStatus === StatusOrderEnum.IN_TRANSIT) {
      notifyError(
        t('order.changeStatus.bankTransferErrorMulti', {
          orders: unPaidOrders.map((o) => o.orderNumber).join(', '),
        }),
      );
      return;
    }

    const response = await batchUpdateStatus({
      orders: state.selectedOrders?.map((o) => ({
        id: o.id,
        observations: t('massiveStatusUpdate.message', {
          newStatus,
          currentStatus,
        }),
      })),
      newStatus,
      saleOrderId: '',
      cancelReason: state.cancelReason,
    });

    const errors = response?.filter((res: any) => !!res?.orderId);
    if (errors?.length) {
      notifyError(t(errors[0].code));
      return;
    }

    notifySuccess('massiveStatusSuccess');
    dispatch({
      type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
      payload: false,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_MULTI_STATUS_CHANGE,
      payload: false,
    });
  }, [
    dispatch,
    batchUpdateStatus,
    notifyError,
    currentStatus,
    newStatus,
    notifySuccess,
    state.cancelReason,
    state.selectedOrders,
  ]);

  const handleOpenMultiStatusChange = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_MULTI_STATUS_CHANGE,
      payload: true,
    });
  }, [dispatch]);
  const closeMultiStatusChange = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_OPEN_MULTI_STATUS_CHANGE,
      payload: false,
    });
  }, [dispatch]);

  const onCancelCancel = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
      payload: false,
    });
  }, [dispatch]);

  const newStatusOptions = useMemo(() => {
    if (!currentStatus) return [];

    const statuses = accessFromCurrentStatus[currentStatus];
    setNewStatus(statuses[0] as StatusOrderEnum);
    return statuses;
  }, [currentStatus]);

  const confirmMassiveUpdate = useCallback(async () => {
    if (newStatus === StatusOrderEnum.CANCELLED) {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE,
        payload: true,
      });
      return;
    }

    confirm({
      icon: null,
      title: (
        <span
          style={{
            color: LightTheme.color.danger,
            fontWeight: 'bold',
          }}
        >
          {t('massiveStatusChange')}
        </span>
      ),
      content: t('massiveStatusUpdate.irreversibleAction', {
        numOrders: state.selectedOrders?.length ?? 0,
        currentStatus: t(`orderStatus.${currentStatus}`).toUpperCase(),
        newStatus: t(`orderStatus.${newStatus}`).toUpperCase(),
      }),
      onOk: onOkCancel,
      okButtonProps: {
        style: {
          backgroundColor: LightTheme.color.danger,
          border: 'none',
        },
      },
      cancelText: t('cancel'),
      okText: t('confirm'),
    });
  }, [dispatch, onOkCancel, state, currentStatus, newStatus]);

  const onChangeNewStatus = useCallback(
    (status: StatusOrderEnum) => {
      setNewStatus(status);
    },
    [setNewStatus],
  );

  const setCancelReason = useCallback(
    (reason: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_CANCEL_REASON,
        payload: reason,
      });
    },
    [dispatch],
  );

  const enabledMultiOrderStatusAction = useMemo(() => {
    // Check if there are just 1 status in orders and has available status to change to
    const statuses = uniqBy(state.selectedOrders ?? [], (o) => o.status);
    const enabledByStatuses =
      statuses.length === 1 &&
      accessFromCurrentStatus[statuses[0].status].length > 0;
    const logistics = uniq(
      (state.selectedOrders ?? []).map(
        (o) => o?.orderType ?? OrderType.REGULAR,
      ),
    );

    const enabledByLogistic =
      logistics.length === 1 && logistics[0] === OrderType.REGULAR;

    return enabledByStatuses && enabledByLogistic;
  }, [state.selectedOrders]);

  return {
    currentStatus,
    newStatus,
    loadingMassiveUpdate,
    newStatusOptions,
    disabledMultiOrderStatusAction: !enabledMultiOrderStatusAction,
    reasonOptions: data?.reasons?.data ?? [],
    closeMultiStatusChange,
    onOkCancel,
    confirmMassiveUpdate,
    onCancelCancel,
    onChangeNewStatus,
    setCancelReason,
    handleOpenMultiStatusChange,
  };
};

export default useMultiStatusChange;
