export enum ShippingZonesActionEnum {
  SET_SEARCH = 'SET_SEARCH',
  SET_DEPARTMENTS_FILTER = 'SET_DEPARTMENTS_FILTER',
  SET_SHIPPING_ZONE_LIST = 'SET_SHIPPING_ZONE_LIST',
  SET_CURRENT_SHIPPING_ZONE = 'SET_CURRENT_SHIPPING_ZONE',
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_CITIES_OPTIONS = 'SET_CITIES_OPTIONS',
  SET_TRANSPORT_LIST = 'SET_TRANSPORT_LIST',
  SET_CITY_SEARCH = 'SET_CITY_SEARCH',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_REGIONAL_LIST = 'SET_REGIONAL_LIST',
  SET_SELECTED_REGION = 'SET_SELECTED_REGION',
  SET_HOLY_DAYS = 'SET_HOLY_DAYS',
  ADD_HOLY_DAYS = 'ADD_HOLY_DAYS',
  REMOVE_HOLY_DAYS = 'REMOVE_HOLY_DAYS',
}

export enum TransportActionEnum {
  SET_TRANSPORT_LIST = 'SET_TRANSPORT_LIST',
  SET_CURRENT_TRANSPORT = 'SET_CURRENT_TRANSPORT',
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_PAGINATION = 'SET_PAGINATION',
}
