/* eslint-disable react/require-default-props */
import { Tabs } from 'antd';
import TableTabsWrapper from 'components/TableTabsWrapper';
import { TableWrapper } from 'components/TableWrapper';
import {
  MultiOrderActionButton,
  MultiOrderActionWrapper,
} from 'components/TableWrapper/TableWrapper.sty';
import { t } from 'i18next';
import React, { useState } from 'react';
import OrderFilters, { OrderFiltersProps } from '../OrderFilters';
import {
  FiltersAndButtonContainer,
  OrderContainer,
  OrderTitle,
  SearchInput,
  TitleColumn,
  TitleRow,
} from './OrderContent.sty';

const { TabPane } = Tabs;

interface OrderContentProps extends OrderFiltersProps {
  Board: React.ReactElement;
  List: React.ReactElement;
  search?: string;

  onViewMultipleRemission: () => void;
  handleOpenMultiStatusChange: () => void;
  openLegalizeModal: () => void;
  multiLegalizeDisabled: boolean;
  disabledMultiOrderStatusAction: boolean;
  disabledMultiRemission: boolean;
  handleSearch: (search: string) => void;
  handle3PFilter: (value: string) => void;
  onGenerateCollectionRecord: () => void;
  onPrintShippingGuides: () => void;
}

export const OrderContent = ({
  handleChangeDeliveryDateFilter,
  handleChangeCreationDateFilter,
  handleStatusFilterChange,
  handleDepartmentFilterChange,
  handleMpsFilterChange,
  onUnselectAllClick,
  deliveryDateFilter,
  creationDateFilter,
  statusFilter,
  departmentFilter,
  search,
  mpsFilter,
  departments,
  mpsUsers,

  Board,
  List,
  onViewMultipleRemission,
  handleOpenMultiStatusChange,
  openLegalizeModal,
  onGenerateCollectionRecord,
  onPrintShippingGuides,
  multiLegalizeDisabled,
  disabledMultiRemission,
  disabledMultiOrderStatusAction,
  handleSearch,
  handle3PFilter,
  orderTypeFilter,
}: OrderContentProps) => {
  const [activeKey, setActiveKey] = useState('1');
  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <OrderContainer>
        <TitleRow>
          <TitleColumn
            span={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <OrderTitle level={1}>{t('orderList')}</OrderTitle>
          </TitleColumn>
          <TitleColumn
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <SearchInput
              placeholder={t('orderSearchPlaceholder')}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          </TitleColumn>
        </TitleRow>
        <TableTabsWrapper>
          <FiltersAndButtonContainer>
            <OrderFilters
              key={1}
              handleChangeDeliveryDateFilter={handleChangeDeliveryDateFilter}
              handleChangeCreationDateFilter={handleChangeCreationDateFilter}
              handleStatusFilterChange={handleStatusFilterChange}
              handleDepartmentFilterChange={handleDepartmentFilterChange}
              handleMpsFilterChange={handleMpsFilterChange}
              onUnselectAllClick={onUnselectAllClick}
              handle3PFilter={handle3PFilter}
              orderTypeFilter={orderTypeFilter}
              deliveryDateFilter={deliveryDateFilter}
              creationDateFilter={creationDateFilter}
              statusFilter={statusFilter}
              renderFilterState={activeKey === '2'}
              departmentFilter={departmentFilter}
              mpsFilter={mpsFilter}
              departments={departments}
              mpsUsers={mpsUsers}
            />
          </FiltersAndButtonContainer>
          <Tabs
            defaultActiveKey="1"
            onChange={(key: string) => setActiveKey(key)}
          >
            <TabPane key="1" tab={t('order.board')}>
              {Board}
            </TabPane>
            <TabPane key="2" tab={t('order.list')}>
              <TableWrapper>{List}</TableWrapper>
            </TabPane>
          </Tabs>
          {activeKey === '2' && (
            <MultiOrderActionWrapper>
              <MultiOrderActionButton
                onClick={onViewMultipleRemission}
                disabled={disabledMultiRemission}
              >
                {t('printRemissions')}
              </MultiOrderActionButton>
              <MultiOrderActionButton
                onClick={handleOpenMultiStatusChange}
                disabled={disabledMultiOrderStatusAction}
              >
                {t('changeStatus')}
              </MultiOrderActionButton>
              <MultiOrderActionButton
                onClick={openLegalizeModal}
                disabled={multiLegalizeDisabled}
              >
                {t('multiLegalize.buttonText')}
              </MultiOrderActionButton>
              <MultiOrderActionButton
                onClick={onGenerateCollectionRecord}
                disabled={false}
              >
                {t('collectionRecord')}
              </MultiOrderActionButton>
              <MultiOrderActionButton
                onClick={onPrintShippingGuides}
                disabled={false}
              >
                {t('shippingGuides')}
              </MultiOrderActionButton>
            </MultiOrderActionWrapper>
          )}
        </TableTabsWrapper>
      </OrderContainer>
    </React.Suspense>
  );
};
