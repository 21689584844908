import { format } from 'date-fns';
import { clone, indexBy, prop } from 'ramda';
import { Order } from 'services/order/interface';
import { Package, PackageToOrderStatus } from 'services/package/interface';

export function packageToOrder(pkg: Package, order: Order): Order {
  const payload = clone(order);
  const pkgProductsIndex = indexBy(prop('id'), pkg.products);
  const formattedDelivery = format(new Date(pkg.delivery.date), 'yyyy-MM-dd');

  payload.delivery = {
    ...payload.delivery,
    updatedDate: formattedDelivery,
    date: formattedDelivery,
  };

  payload.status = PackageToOrderStatus[pkg.status];
  payload.detail = payload.detail.filter((detail) => {
    const detailInPkg = pkgProductsIndex[detail.id];
    if (!detailInPkg) return null;

    return {
      ...detail,
      cartQuantity: detailInPkg.quantity,
    };
  });

  return payload;
}
