/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import { useLoadScript } from '@react-google-maps/api';
import { Divider, Empty, Modal, Row, Spin, Typography } from 'antd';
import { TableWrapper } from 'components/TableWrapper';
import config from 'config';
import UserDrawer from 'containers/Users/UserDrawer/UserDrawer';
import useUserModule from 'containers/Users/UserList/hooks/useUserModule';
import { UserContent } from 'containers/Users/UserList/interface';
import { UserTable } from 'containers/Users/UserList/UserTable';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'services/user/interface';
import { Title } from 'pages/App/Layout.sty';

type Libraries = 'places';

const libraries = ['places'] as Libraries[];

const UserList = () => {
  const {
    readQuery: {
      mutate: getUserList,
      data: userList,
      isLoading: isLoadingList,
      isError: isErrorList,
      error: userListError,
    },
    deleteMutation: {
      mutateAsync: deleteUserAsync,
      isError: isErrorDelete,
      isLoading: isLoadingDelete,
    },
  } = useModuleCRUD('user');

  const {
    deleteConfirmationModalVisibilityState: [
      isDeleteConfirmationModalVisible,
      setDeleteConfirmationModalVisible,
    ],
    deleteUserIdState: [deleteUserId, setDeleteUserId],
    drawerVisibilityState: [isDrawerVisible, setDrawerVisible],
    userToUpdateState: [userToUpdate, setUserToUpdate],
    paginationState: [pagination, setPagination],
    searchState: [search, setSearch],
    roleFilterState: [roleFilter, setRoleFilter],
  } = useUserModule({ getUserList });
  const { isLoaded: isLoadedMaps } = useLoadScript({
    googleMapsApiKey: config.googleMapsConfig.apiKey,
    libraries,
  });
  const onSearchWithDebounce = debounce((value: string) => {
    setSearch(value);
  }, 700);
  const { t } = useTranslation();
  const customMessage = {
    success: {
      message: 'user.modal.actions.success',
      description: 'user.modal.message.deleteSuccess',
    },
    error: {
      message: 'user.modal.actions.error',
      description: 'user.modal.message.deleteError',
    },
    cancel: {
      message: 'user.modal.actions.cancel',
      description: 'user.modal.message.cancel',
    },
  };
  const { notifySuccess, notifyError, notifyCancel } = useNotifications({
    customMessage,
    translationFunction: t,
  });

  const onDeleteUser = async () => {
    try {
      await deleteUserAsync(deleteUserId);
    } catch (error) {
      notifyError();
      setDeleteConfirmationModalVisible(false);
      return;
    }
    getUserList({ pagination, search, roleFilter });
    setDeleteConfirmationModalVisible(false);
    if (!isErrorDelete) {
      notifySuccess();
    } else if (isErrorDelete) {
      notifyError();
    }
  };

  const onCancel = () => {
    setDeleteUserId('');
    setDeleteConfirmationModalVisible(false);
    notifyCancel();
  };

  const onCloseDrawer = (isSubmit?: boolean) => {
    setDrawerVisible(false);
    setUserToUpdate(undefined);
    if (isSubmit) {
      getUserList({ pagination, search, roleFilter });
    }
  };

  const handleDelete = (user: UserContent) => {
    if (user.id) {
      setDeleteUserId(user.id);
      setDeleteConfirmationModalVisible(true);
    } else {
      notifyError();
    }
  };

  const onUpdateUser = (user: UserContent) => {
    setUserToUpdate(user as User);
    setDrawerVisible(true);
  };

  if (userListError) {
    const error = JSON.parse(JSON.stringify(userListError));
    return (
      <Empty
        style={{ whiteSpace: 'pre-line' }}
        description={
          <p>
            {error.status === 401
              ? t('noAccessText.youDontHaveAccess')
              : error.status}
          </p>
        }
      />
    );
  }

  if (!isLoadedMaps) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }

  return (
    <React.Suspense fallback={<div>{t('loading')}</div>}>
      <Modal
        visible={isDeleteConfirmationModalVisible}
        onOk={onDeleteUser}
        onCancel={onCancel}
        okText={t('user.modal.buttonTexts.ok')}
        cancelText={t('user.modal.buttonTexts.cancel')}
        title={t('user.modal.delete')}
        okButtonProps={{
          loading: isLoadingDelete,
        }}
      >
        {t('user.modal.content', { userName: deleteUserId })}
      </Modal>
      <Title level={1}>{t('menu.userList')}</Title>
      {!isErrorList && (
        <UserTable
          data={userList || []}
          isLoading={isLoadingList}
          onDeleteUser={handleDelete}
          onUpdateUser={onUpdateUser}
          onSetPagination={setPagination}
          onSetRoleFilter={setRoleFilter}
          onChangeSearch={onSearchWithDebounce}
        />
      )}
      {userToUpdate && (
        <UserDrawer
          user={userToUpdate}
          onClose={onCloseDrawer}
          showDrawer={isDrawerVisible}
        />
      )}
    </React.Suspense>
  );
};

export default UserList;
