/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import {
  Order,
  OrderType,
  PandasPaymentMethod,
  StatusOrderEnum,
  UserOrders,
} from 'services/order/interface';
import {
  OrderAction,
  OrderState,
  ORDER_ACTION_ENUM,
  ORDER_VIEWS_ENUM,
} from './interface';
import { setAdminDates } from './utils/deliveryDates-augmentation';

const NOW = new Date().toISOString();

export const INITIAL_ORDER_REDUCER: OrderState = {
  currentView: ORDER_VIEWS_ENUM.BOARD,
  filters: {
    search: '',
    startDeliveryDate: format(new Date(), 'yyyy-MM-dd'),
    endDeliveryDate: format(new Date(), 'yyyy-MM-dd'),
    orderType: OrderType.REGULAR,
  },
  selectedOrders: [],
  selectedRowKeys: [],
  openMultiLegalize: false,
  openMultiStatusChange: false,
  cancelModalVisible: false,

  readOnly: true,
  step: 1,
  formInitialValues: {
    id: '',
    status: StatusOrderEnum.PENDING,
    user: {} as UserOrders,
    detail: [],
    delivered: [],
    picked: [],
    total: 0,
    createdAt: NOW,
    updatedAt: NOW,
    orderNumber: '',
    paymentMethod: PandasPaymentMethod.CASH,
  },
  openUpdateConfirmModal: false,
  updateStatusModalLoading: false,
  openUpdateStatusModal: false,
  disableMpsVariantAutocomplete: false,
  openRescheduleModal: false,
  maxDiscount: 0,
  deliveryRangeOptions: [],
  deliveryOptions: [],
  insufficientStockModalOpen: false,
  insufficientStockDetails: [],
  editableForStock: false,
};

export function orderReducer(
  state: OrderState,
  action: OrderAction,
): OrderState {
  switch (action.type) {
    case ORDER_ACTION_ENUM.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case ORDER_ACTION_ENUM.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case ORDER_ACTION_ENUM.SET_SEARCH_FILTER:
      return { ...state, searchFilter: action.payload };
    case ORDER_ACTION_ENUM.SET_SELECTED_ORDERS:
      return { ...state, selectedOrders: action.payload };
    case ORDER_ACTION_ENUM.SET_SELECTED_ROWKEYS:
      return { ...state, selectedRowKeys: action.payload };
    case ORDER_ACTION_ENUM.SET_OPEN_MULTI_LEGALIZE:
      return { ...state, openMultiLegalize: action.payload };
    case ORDER_ACTION_ENUM.SET_OPEN_MULTI_STATUS_CHANGE:
      return { ...state, openMultiStatusChange: action.payload };
    case ORDER_ACTION_ENUM.SET_CANCEL_MODAL_VISIBLE:
      return { ...state, cancelModalVisible: action.payload };
    case ORDER_ACTION_ENUM.SET_CANCEL_REASON:
      return { ...state, cancelReason: action.payload };
    case ORDER_ACTION_ENUM.SET_RESCHEDULE_REASON:
      return { ...state, rescheduleReason: action.payload };
    case ORDER_ACTION_ENUM.SET_OPEN_UPDATE_STATUS_MODAL:
      return { ...state, openUpdateStatusModal: action.payload };
    case ORDER_ACTION_ENUM.SET_NEW_STATUS:
      return { ...state, newStatus: action.payload };
    case ORDER_ACTION_ENUM.SET_ORDER_DRAGGED:
      return { ...state, orderDragged: action.payload };
    case ORDER_ACTION_ENUM.SET_OBSERVATIONS:
      return { ...state, observations: action.payload };
    case ORDER_ACTION_ENUM.SET_REPORT_MISSING:
      return { ...state, reportMissing: action.payload };
    case ORDER_ACTION_ENUM.SET_UPDATE_STATUS_MODAL_LOADING:
      return { ...state, updateStatusModalLoading: action.payload };

    // CREATE // UPDATE
    case ORDER_ACTION_ENUM.SET_OPEN_UPDATE_CONFIRM_MODAL:
      return { ...state, openUpdateConfirmModal: action.payload };
    case ORDER_ACTION_ENUM.SET_OPEN_RESCHEDULE_MODAL:
      return { ...state, openRescheduleModal: action.payload };
    case ORDER_ACTION_ENUM.SET_FORM_INITIAL_VALUES:
      return { ...state, formInitialValues: action.payload };
    case ORDER_ACTION_ENUM.SET_READONLY:
      return { ...state, readOnly: action.payload };
    case ORDER_ACTION_ENUM.SET_STEP:
      return { ...state, step: action.payload };
    case ORDER_ACTION_ENUM.SET_MAX_DISCOUNT: {
      return { ...state, maxDiscount: (action.payload ?? 0) / 2 };
    }
    case ORDER_ACTION_ENUM.SET_PAYMENT_STATUS: {
      return { ...state, selectedPaymentStatus: action.payload };
    }
    case ORDER_ACTION_ENUM.SET_DELIVERY_DATE_OPTIONS: {
      const { deliveryRangeOptions, isAdmin } = action.payload;
      const options = deliveryRangeOptions[0].dates;

      const newDeliveryRangeOptions = setAdminDates(options, isAdmin);
      return {
        ...state,
        deliveryRangeOptions: newDeliveryRangeOptions,
        deliveryOptions: deliveryRangeOptions,
      };
    }
    case ORDER_ACTION_ENUM.SET_DISABLE_VARIANT_AUTOCOMPLETE: {
      const { order }: { order: Order } = action.payload;

      let disabled = false;
      if (
        order?.status === StatusOrderEnum.RECEIVED ||
        order?.status === StatusOrderEnum.STOCK_PENDING ||
        (order?.status === StatusOrderEnum.DELIVERED && order?.reportMissing)
      ) {
        disabled = !!order?.payment?.online;
      }
      return { ...state, disableMpsVariantAutocomplete: action.payload };
    }
    case ORDER_ACTION_ENUM.SET_OPEN_INSUFFICIENT_STOCK_MODAL:
      return { ...state, insufficientStockModalOpen: action.payload };
    case ORDER_ACTION_ENUM.SET_INSUFFICIENT_STOCK_DETAILS:
      return { ...state, insufficientStockDetails: action.payload };
    case ORDER_ACTION_ENUM.SET_DETAIL_EDITABLE_FOR_STOCK:
      return { ...state, editableForStock: action.payload };
    default:
      return state;
  }
}
