import { List, Spin } from 'antd';
import useGenericInfiniteScroll from 'modules/Common/hooks/useGenericInfiniteScroll';
import { NoHistory } from 'pages/Products/UpdateProduct/UpdateProduct.sty';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getUnitMovementsByVariant } from 'services/unit/unit.service';
import LogoImage from '../../assets/images/logo.svg';
import { UnitRegistry } from './interface';
import UnitMovement from './UnitMovement';

const DEFAULT_PAGE_SIZE = 10;

const UnitMovements = ({ variantId }: { variantId: string }) => {
  const { t } = useTranslation();
  const fetchHistory = useCallback(
    (page: number) => {
      return getUnitMovementsByVariant({
        pageSize: DEFAULT_PAGE_SIZE,
        variantId,
        page,
      });
    },
    [variantId],
  );

  const {
    data: movements,
    isLoading: loadingMovements,
    hasNext,
    fetchNext,
    reloadFetch,
  } = useGenericInfiniteScroll({
    pureFetchFn: fetchHistory,
    fetchOnDemand: true,
  });

  useEffect(() => {
    reloadFetch();
  }, []);

  return (
    <InfiniteScroll
      next={fetchNext}
      dataLength={movements.length}
      hasMore={hasNext}
      loader={<Spin tip={t('loading')} />}
      scrollableTarget="scrollable-container"
      style={{ overflow: 'hidden' }}
      endMessage={
        !loadingMovements && movements.length ? (
          <NoHistory>
            <img alt="pandas logo" src={LogoImage} />
            <p>{t('variantHistory.noMoreToLoad')}</p>
          </NoHistory>
        ) : null
      }
    >
      <List
        dataSource={movements}
        renderItem={(historyItem: UnitRegistry) => (
          <UnitMovement historyItem={historyItem} />
        )}
        loading={loadingMovements && movements.length === 0}
      />
    </InfiniteScroll>
  );
};

export default UnitMovements;
