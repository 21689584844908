import { FormInstance } from 'antd';
import { LocationValue } from 'containers/General/LocationInput/interface';
import { getCoordinates } from 'helpers/utils/getCoordinates';
import { MutableRefObject, SetStateAction, useRef, useState } from 'react';

export interface SupplierLocation {
  address?: string;
  details?: string;
  latitude?: string;
  longitude?: string;
  cityCode?: string;
}

const emptyLocation = {
  address: '',
  details: '',
  latitude: '',
  longitude: '',
  cityCode: '',
  departmentCode: '',
};

export interface LocationSelectorProps {
  showModal: boolean;
  handleCancel: () => void;
  handleLocationInputChange: (inputValue: LocationValue) => void;
  handleConfirmChange: () => void;
  handleSelectLocation: (idx: number) => void;
  addressSelectedState: [boolean, React.Dispatch<SetStateAction<boolean>>];
  modalLocation: SupplierLocation;
  locationMapRef: MutableRefObject<google.maps.Map | null>;
}

const useLocationSelector = ({
  form,
  notifyError,
}: {
  form: FormInstance;
  notifyError: (message?: string) => void;
}): LocationSelectorProps => {
  const [modalLocation, setModalLocation] =
    useState<SupplierLocation>(emptyLocation);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [locationIndex, setLocationIndex] = useState<number>(-1);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);
  const locationMapRef = useRef<google.maps.Map | null>(null);

  const recenterMap = (inputValue: LocationValue) => {
    if (locationMapRef.current) {
      locationMapRef.current.setZoom(16);
      locationMapRef.current.setCenter(
        getCoordinates({
          latitude: inputValue.latitude,
          longitude: inputValue.longitude,
        }),
      );
    }
  };

  const handleCancel = () => {
    setModalLocation(emptyLocation);
    recenterMap(emptyLocation);
    setShowModal(false);
  };

  const handleLocationInputChange = (inputValue: LocationValue) => {
    setModalLocation(inputValue);
  };

  const handleConfirmChange = () => {
    if (!addressSelected) {
      notifyError('userForm.errorMessages.badSelectedLocation');
      return;
    }
    const location = form.getFieldValue('location') ?? [];
    if (locationIndex < 0) {
      location.push(modalLocation);
    } else if (locationIndex >= 0) {
      location[locationIndex] = modalLocation;
    }
    form.setFieldsValue({
      location,
    });
    setModalLocation(emptyLocation);
    recenterMap(emptyLocation);
    setShowModal(false);
  };

  const handleSelectLocation = (idx: number) => {
    setLocationIndex(idx);
    if (idx < 0) {
      setModalLocation(emptyLocation);
      recenterMap(emptyLocation);
      setShowModal(true);
      return;
    }
    const location = form.getFieldValue('location') ?? [];
    setModalLocation(location[idx]);
    recenterMap(location[idx]);
    setShowModal(true);
  };

  return {
    showModal,
    modalLocation,
    handleCancel,
    handleLocationInputChange,
    handleConfirmChange,
    handleSelectLocation,
    addressSelectedState: [addressSelected, setAddressSelected],
    locationMapRef,
  };
};

export default useLocationSelector;
