/* eslint-disable react/destructuring-assignment */
import styled from 'styled-components';
import { Space } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { useTranslation } from 'react-i18next';
import { UserSummaryProps } from './interface';

export const ItemParagraph = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  max-width: 450px;
  flex-wrap: wrap;
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  margin-right: ${LightTheme.spaceSizes.m};
`;

export const UserSummary = ({ user }: UserSummaryProps) => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical" size={2}>
      <ItemParagraph>
        <ItemLabel>
          {t('user.userListColumns.fullName')}
          :
        </ItemLabel>
        {`${user?.info?.name || 'N/A'} ${user?.info?.lastName || ''}`}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('user.userListColumns.phoneNumber')}
          :
        </ItemLabel>
        {user?.info?.phoneNumber}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('user.userListColumns.email')}
          :
        </ItemLabel>
        {user?.info?.email}
      </ItemParagraph>
      <ItemParagraph>
        <ItemLabel>
          {t('userForm.labels.sellerCode')}
          :
        </ItemLabel>
        {user?.sellerCode}
      </ItemParagraph>
    </Space>
  );
};
