/* eslint-disable react/jsx-props-no-spreading */
import { Table } from 'antd';
import useTableSize from 'helpers/hooks/useTableSize/useTableSize';
import useCreditTable from './hooks/useCreditTable';
import { CreditLine, CreditTableProps } from './interface';

const TABLE_HEADER_HEIGHT = 150;

const CreditTable = ({
  data,
  pagination,
  loading,
  onUpdateCredit,
  onChange,
  setTablePagination,
}: CreditTableProps) => {
  const tableSize = useTableSize(TABLE_HEADER_HEIGHT);
  const { columns, hiddenDescription } = useCreditTable({ onUpdateCredit });
  return (
    <Table
      {...tableSize}
      loading={loading}
      columns={columns}
      rowKey="_id"
      expandable={{
        expandedRowRender: (record: CreditLine) => hiddenDescription(record),
        rowExpandable: () => true,
      }}
      dataSource={data?.data ?? []}
      pagination={{
        ...pagination,
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '100', '500', '1000'].filter(
          (p) => Number(p) <= (pagination.total ?? 0),
        ),
        onChange(page, pageSize) {
          setTablePagination({
            total: data?.metadata.length,
            current: page,
            pageSize,
          });
        },
      }}
      onChange={onChange}
    />
  );
};

export default CreditTable;
