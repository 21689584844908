import { ErrorMessageObject } from './development.errors';

export const userErrors: { [key: string]: ErrorMessageObject } = {
  USRS401001: {
    translation: 'httpErrors.user.noAuth',
    isBlocking: true,
  },
  USRS403002: {
    translation: 'httpErrors.user.noAccess',
    isBlocking: true,
  },
  USRS403003: {
    translation: 'httpErrors.user.undefinedPermissions',
    isBlocking: true,
  },
  USRS400004: {
    translation: 'httpErrors.user.userNoFound',
    isBlocking: true,
  },
  USRC400001: {
    translation: 'httpErrors.user.create.duplicateEmailOrPhone',
    isBlocking: true,
  },
  USRU400001: {
    translation: 'httpErrors.user.update.duplicateEmailOrPhone',
    isBlocking: true,
  },
};
