import { getDay, isAfter, subDays } from 'date-fns';
import {
  dateToDeliveryOption,
  holidays,
  SUNDAY,
} from 'helpers/utils/deliveryDates';
import { cloneDeep } from 'lodash';
import { DeliveryRangeOption } from 'services/shipping/interface';

export const setAdminDates = (
  dates: DeliveryRangeOption[],
  isAdmin: boolean,
): DeliveryRangeOption[] => {
  if (!dates.length) {
    return [];
  }
  const newDates = cloneDeep(dates);

  newDates[0].start.isFirst = true;
  const firstDate = newDates[0].start.date;

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const firstDelayedDate = new Date(firstDate);

  while (isAfter(firstDelayedDate, tomorrow)) {
    const previousDate = subDays(firstDelayedDate, 1);
    if (!holidays.includes(previousDate) && getDay(previousDate) !== SUNDAY) {
      newDates.unshift({
        start: dateToDeliveryOption(previousDate, !isAdmin),
      });
    }
    firstDelayedDate.setDate(firstDelayedDate.getDate() - 1);
  }

  return newDates;
};
