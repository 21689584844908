/* eslint-disable no-underscore-dangle */
import { Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import LoaderWrapper from 'components/LoadingWrapper';
import { ROUTES } from 'helpers/constants/routes';
import { BaseColors } from 'helpers/constants/theme';
import useNotifications from 'helpers/hooks/useNotifications';
import { Title } from 'modules/WMS/Settings/components/SettingList.sty';
import moment from 'moment';
import 'moment/locale/es';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ShippingZonesWrapper,
  UpdateShippingZoneHeader,
  UpdateShippingZoneSection,
} from '../components/containers.sty';
import ShippingZoneForm from '../components/ShippingZoneForm/ShippingZoneForm';
import { useShippingZoneCreate } from '../context/SettingCreate.context';
import { shippingZoneFormToCreationEntity } from '../helpers';
import useCreateShippingZone from '../hooks/useCreateShippingZone';
import { IShippingZoneForm } from '../interface';

moment.locale('es');
const CreateShippingZoneContainer = () => {
  const { state, dispatch } = useShippingZoneCreate();
  const { t } = useTranslation();
  const [form] = useForm<IShippingZoneForm>();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  const {
    isLoading,
    createLoading,
    createShippingZone,
    getHolyDaysByRegion,
    onSearchCityCode,
    loadingStates,
  } = useCreateShippingZone({
    dispatch,
  });

  const onGoBackToList = useCallback(() => {
    navigate(ROUTES.shippingZones);
  }, [navigate]);

  const onCreateShippingZone = useCallback(async () => {
    const values = form.getFieldsValue();

    const creationZone = shippingZoneFormToCreationEntity(
      values,
      state.transportList,
      state.regionList,
    );

    try {
      await createShippingZone(creationZone);
      notifySuccess();
      onGoBackToList();
    } catch (error) {
      let err = '';
      if (axios.isAxiosError(error)) {
        err = error?.response?.data?.code ?? '';
      }
      notifyError(err || undefined);
    }
  }, [
    form,
    state,
    createShippingZone,
    notifySuccess,
    onGoBackToList,
    notifyError,
  ]);

  return (
    <ShippingZonesWrapper>
      <LoaderWrapper loading={isLoading}>
        <UpdateShippingZoneHeader>
          <Title color={BaseColors.deep_cove}>
            {t('shippingZone.form.titles.create')}
          </Title>
          <UpdateShippingZoneSection>
            <Button
              type="primary"
              onClick={form.submit}
              loading={createLoading}
            >
              {t(`shippingZone.form.buttons.create`)}
            </Button>
          </UpdateShippingZoneSection>
        </UpdateShippingZoneHeader>
        <ShippingZoneForm
          form={form}
          citiesOptionList={state.citiesOptionList}
          regionOptionList={state.regionOptionList}
          transportOptionList={state.transportOptionList}
          onSubmit={onCreateShippingZone}
          fetchRegionalConfig={getHolyDaysByRegion}
          searchCityCode={onSearchCityCode}
          loadingStates={loadingStates}
        />
      </LoaderWrapper>
    </ShippingZonesWrapper>
  );
};

export default CreateShippingZoneContainer;
