import {
  CategoryNode,
  TargetCategory,
} from 'containers/Categories/CategoryForm/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import { isEqual, uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { Category } from 'services/category/interface';

export const useCategoryList = () => {
  const {
    readQuery: { mutate: getCategoryList, data: categoryList },
  } = useModuleCRUD('categories');

  useEffect(() => {
    getCategoryList(undefined);
  }, [getCategoryList]);

  const [categoryTree, setCategoryTree] = useState<CategoryNode[]>([]);

  useEffect(() => {
    if (categoryList) {
      const parentCategories = categoryList.filter(
        (category: Category) => !category.parentId,
      );
      setCategoryTree(
        parentCategories.map((parent: Category) => ({
          title: parent.name,
          value: parent.id,
          children: uniqWith(
            categoryList
              .filter((category: Category) => category.parentId === parent.id)
              .map((child: Category) => ({
                title: child.name,
                value: child.id,
              })),
            isEqual,
          ),
        })),
      );
    }
  }, [categoryList]);

  const getTargetCategoryList = (
    categoryIdList: string[],
  ): TargetCategory[] => {
    const targetCategoryList = categoryList
      .filter((category: Category) => categoryIdList.includes(category.id))
      .map((category: Category) => ({
        id: category.id,
        name: category.name,
        urlName: category.urlName,
      }));

    return targetCategoryList;
  };

  return {
    categoryList,
    categoryTree,
    getTargetCategoryList,
  };
};

export default useCategoryList;
