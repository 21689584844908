import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const TotalSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.xl};
  font-weight: bold;
`;

export const SaleOrderDiscount = styled.span`
  font-size: ${LightTheme.spaceSizes.xl};
  font-weight: bold;
`;

export const SaleOrderDiscountContainer = styled.div`
  margin-top: -${LightTheme.spaceSizes.xl};
`;

export const DiscountSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.l};
  span {
    color: ${LightTheme.color.danger};
  }
`;

export const SubtotalSpan = styled.span`
  display: flex;
  align-items: center;
  gap: ${LightTheme.spaceSizes.m};
  font-size: ${LightTheme.spaceSizes.l};
`;
