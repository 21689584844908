import { LightTheme } from 'helpers/constants/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import './helpers/translations/i18n';
import './index.css';
import App from './pages/App/App';

ReactDOM.render(
  // <React.StrictMode>
  <RecoilRoot>
    <ThemeProvider theme={LightTheme}>
      <App />
    </ThemeProvider>
  </RecoilRoot>, // </React.StrictMode>,

  document.getElementById('root'),
);
