import { SupplierForm } from 'services/MPS/interface';
import { BillingType } from 'services/product/interface';
import { parseMPSToSupplierForm } from '../parser';

export type SuppliersAction = {
  type: SUPPLIER_ACTION_ENUM;
  payload: any;
};

export enum SUPPLIER_ACTION_ENUM {
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_SEARCH = 'SET_SEARCH',
  SET_SUPPLIER_FILTER = 'SET_SUPPLIER_FILTER',
  SET_BILLING_TYPE_FILTER = 'SET_BILLING_TYPE_FILTER',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_CURRENT_SUPPLIER = 'SET_CURRENT_SUPPLIER',
}

export enum SUPPLIER_PAGE_ENUM {
  LIST = 'LIST',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export interface SuppliersState {
  currentView: SUPPLIER_PAGE_ENUM;
  currentSupplier?: SupplierForm;
  search: string;
  isSupplier?: boolean;
  billingType?: BillingType;
  pagination: { page: number; pageSize: number };
}

export function SuppliersReducer(
  state: SuppliersState,
  action: SuppliersAction,
): SuppliersState {
  switch (action.type) {
    case SUPPLIER_ACTION_ENUM.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case SUPPLIER_ACTION_ENUM.SET_SEARCH:
      return { ...state, search: action.payload };
    case SUPPLIER_ACTION_ENUM.SET_SUPPLIER_FILTER:
      return { ...state, isSupplier: action.payload };
    case SUPPLIER_ACTION_ENUM.SET_BILLING_TYPE_FILTER:
      return { ...state, billingType: action.payload };
    case SUPPLIER_ACTION_ENUM.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case SUPPLIER_ACTION_ENUM.SET_CURRENT_SUPPLIER: {
      if (!action.payload) {
        return { ...state, currentSupplier: undefined };
      }

      const parsedSupplier = parseMPSToSupplierForm(action.payload);
      return { ...state, currentSupplier: parsedSupplier };
    }
    default:
      return state;
  }
}

export const INITIAL_REDUCER = {
  currentView: SUPPLIER_PAGE_ENUM.LIST,
  search: '',
  pagination: { page: 1, pageSize: 50 },
};
