import { UploadProps } from 'antd';
import config from 'config';

export const TRANSFER_CSV_UPLOAD_BASE_PROPS: UploadProps = {
  name: 'file',
  multiple: true,
  method: 'POST',
  action: config.transferCsvUpload.actionURL,
  accept: '.csv',
};

export const TRANSFER_CSV_FORMAT_FILE = 'transfer_template.csv';
