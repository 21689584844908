import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import BannerPicItem from './BannerPictureItem';
import { GridSortableContainer, HelpAlert } from './BannerSort.sty';
import useBannersSorting from './hooks/useBannersSorting';
import { BannerSortProps } from './interface';
import SortableItem from './SortableItem';

const BannerSort = ({
  items,
  setItems,
  defaultSelectedId,
}: BannerSortProps) => {
  const { t } = useTranslation();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const {
    activeId,
    activeBanner,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
  } = useBannersSorting({
    setItems,
    items,
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <HelpAlert
          closable
          type="info"
          showIcon
          description={t('banners.tooltips.order')}
        />
        <GridSortableContainer>
          {items.map((banner) => (
            <SortableItem
              preSelected={banner.id === defaultSelectedId}
              image={banner.image}
              key={banner.id}
              id={banner.id}
            />
          ))}
        </GridSortableContainer>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {!!activeId && (
          <BannerPicItem image={activeBanner?.image ?? ''} id={activeId} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default BannerSort;
