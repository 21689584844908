/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const TableActions = ({
  entity,
  onUpdate,
  onDelete,
}: {
  entity: any;
  onUpdate?: (entity: any) => void;
  onDelete?: (entity: any) => void;
}) => (
  <>
    {onDelete && (
      <Button
        key={`${entity.id}-delete-button`}
        danger
        onClick={() => onDelete(entity)}
      >
        <DeleteOutlined />
      </Button>
    )}
    {onUpdate && (
      <Button
        key={`${entity.id}-update-button`}
        onClick={() => onUpdate(entity)}
      >
        <EditOutlined />
      </Button>
    )}
  </>
);

export default TableActions;
