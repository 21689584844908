import { Select } from 'antd';
import { CustomTag } from 'components/CustomTag';
import { DefaultTitle } from 'components/DefaultTitle/DefaultTitle';
import UserAutocomplete from 'containers/Users/UserAutocomplete/UserAutocomplete';
import { useWalletContext } from 'context/WalletContext/Wallet.Context';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { numberToCurrency } from 'helpers/utils/currency';
import { validateWalletForm } from 'helpers/utils/Wallet';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { DefaultContainer } from 'pages/Brands/BrandsList/BrandsList.sty';
import { DEBOUNCED_SEARCH_MS } from 'pages/Products/UpdateProduct/constants';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns } from 'services/cities/interface';
import { CouponStatusTagColor } from 'services/coupons/interface';
import { User } from 'services/user/interface';
import {
  initialWalletState,
  WalletActionEnum,
  WalletTransaction,
  WalletViewEnum,
} from 'services/wallet/interface';
import {
  createWalletTransactions,
  updateWalletTransactions,
} from 'services/wallet/wallet.service';
import { WalletForm } from './Components/WalletForm';
import { WalletTable } from './Components/WalletTable';
import useWalletApi from './hooks/useWalletApi';
import { statusArray } from './interface';

const WalletContainer = () => {
  const { state, dispatch } = useWalletContext();
  const { walletTransactionList, currentTransaction, currentView } = state;
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });
  const navigate = useNavigate();

  const { hasAccess } = useRoleAccess('wallet.all');
  useEffect(() => {
    if (!hasAccess) {
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate]);

  const { fetchNextPage, hasNextPage, refetch } = useWalletApi({
    dispatch,
    state,
    tableRef,
  });

  const GoToCreate = () => {
    dispatch({
      type: WalletActionEnum.SET_CURRENT_VIEW,
      payload: WalletViewEnum.CREATE,
    });
  };

  const GoToUpdate = useCallback(
    (transaction: WalletTransaction) => {
      dispatch({
        type: WalletActionEnum.SET_CURRENT_TRANSACTION,
        payload: transaction,
      });

      dispatch({
        type: WalletActionEnum.SET_CURRENT_VIEW,
        payload: WalletViewEnum.UPDATE,
      });
    },
    [dispatch],
  );

  const handleStatusFilter = useCallback(
    (e: any) => {
      dispatch({
        type: WalletActionEnum.SET_STATUS_FILTER,
        payload: e.value,
      });
    },
    [dispatch],
  );

  const handleUserFilter = useCallback(
    (value: User) => {
      dispatch({
        type: WalletActionEnum.SET_USER_FILTER,
        payload: value.id,
      });
    },
    [dispatch],
  );

  const GoToList = useCallback(async () => {
    dispatch({
      type: WalletActionEnum.SET_CURRENT_VIEW,
      payload: WalletViewEnum.LIST,
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentView === WalletViewEnum.LIST) {
      refetch();
    }
  }, [currentView, refetch]);

  const handleSubmitFrom = useCallback(
    async (form: WalletTransaction) => {
      try {
        const validatedFrom = validateWalletForm(
          form,
          currentTransaction,
          currentView,
        );
        if (!validatedFrom.valid) {
          notifyError('wallet.notifications.create.error');
          return;
        }

        if (!validatedFrom.reason) {
          notifyError('wallet.notifications.create.reason');
          return;
        }
        const response =
          currentView === WalletViewEnum.UPDATE
            ? await updateWalletTransactions(validatedFrom.data)
            : await createWalletTransactions(validatedFrom.data);

        dispatch({
          type: WalletActionEnum.SET_CURRENT_TRANSACTION,
          payload: response,
        });

        const successMessageKey =
          currentView === WalletViewEnum.UPDATE
            ? 'wallet.notifications.update.success'
            : 'wallet.notifications.create.success';

        notifySuccess(successMessageKey);

        if (currentView !== WalletViewEnum.UPDATE) {
          GoToUpdate(response);
        } else {
          GoToList();
        }
      } catch (error) {
        notifyError('wallet.notifications.create.error');
      }
    },
    [
      GoToList,
      GoToUpdate,
      currentTransaction,
      currentView,
      dispatch,
      notifyError,
      notifySuccess,
    ],
  );

  const handleSearchWithDebounce = useMemo(
    () =>
      debounce((value: string) => {
        dispatch({
          type: WalletActionEnum.SET_SEARCH,
          payload: value,
        });
      }, DEBOUNCED_SEARCH_MS),
    [dispatch],
  );

  const handleSearch = useCallback(
    (value: string) => {
      handleSearchWithDebounce(value);
    },
    [handleSearchWithDebounce],
  );

  const FiltersMemoized = useMemo(() => {
    return (
      <>
        <Select
          defaultValue="PENDING"
          labelInValue
          onChange={(e: string) => handleStatusFilter(e)}
        >
          <Select.Option value="">
            {t('cityTableKeys.departmentPlaceholder')}
          </Select.Option>
          {statusArray.map((item) => (
            <Select.Option key={item} value={item}>
              {t(item)}
            </Select.Option>
          ))}
        </Select>
        <UserAutocomplete
          size="middle"
          onChange={(e: User) => handleUserFilter(e)}
          queryParams={{
            pagination: {
              page: 1,
              pageSize: 10,
            },
          }}
        />
      </>
    );
  }, [handleStatusFilter, handleUserFilter]);

  const columns: Columns[] = [
    {
      title: t('wallet.name'),
      dataIndex: ['user', 'name'],
      width: '100%',
      render: (item: string) => {
        const words = item.split(' ');

        const formatedText = words
          .map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
          )
          .join(' ');

        return formatedText;
      },
    },
    {
      title: t('wallet.email'),
      dataIndex: ['user', 'email'],
      width: '100%',
    },
    {
      title: t('wallet.transactionType'),
      dataIndex: 'transactionType',
      width: '100%',
      render: (item: string) => t(`wallet.${item}`),
    },
    {
      title: t('wallet.total'),
      dataIndex: 'total',
      width: '100%',
      render: (value: number) => numberToCurrency(Number(value)),
    },
    {
      title: t('wallet.status'),
      dataIndex: 'status',
      width: '100%',
      render: (item: string) => {
        return (
          <CustomTag color={CouponStatusTagColor[item]}>
            {t(`cityTableKeys.statusItem.${item}`) || `${item}` || ''}
          </CustomTag>
        );
      },
    },
  ];

  return (
    <DefaultContainer>
      <DefaultTitle
        createButton={GoToCreate}
        returnButton={GoToList}
        search={handleSearch}
        filters={FiltersMemoized}
        currentView={currentView}
        initialTitle="wallet"
        createButtonText={t('wallet.createButton')}
        returnButtonText={t('wallet.returnButton')}
      />
      {(() => {
        switch (currentView) {
          case WalletViewEnum.LIST:
            return (
              <WalletTable
                list={walletTransactionList}
                tableRef={tableRef}
                loading={false}
                onViewDetail={GoToUpdate}
                columns={columns}
                reachedEndOfData={!hasNextPage}
                onScrollToEnd={fetchNextPage}
              />
            );
          case WalletViewEnum.CREATE:
            return (
              <WalletForm
                selectedTransaction={initialWalletState.currentTransaction}
                currentView={currentView}
                loading={false}
                submitForm={handleSubmitFrom}
              />
            );
          case WalletViewEnum.UPDATE:
            return (
              <WalletForm
                selectedTransaction={currentTransaction}
                currentView={currentView}
                loading={false}
                submitForm={handleSubmitFrom}
              />
            );
          default:
            return (
              <WalletTable
                list={[]}
                tableRef={tableRef}
                loading={false}
                onViewDetail={GoToUpdate}
                columns={columns}
                reachedEndOfData={!hasNextPage}
                onScrollToEnd={fetchNextPage}
              />
            );
        }
      })()}
    </DefaultContainer>
  );
};

export default WalletContainer;
