/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce, DebouncedFunc } from 'lodash';
import { EmptyFunction } from 'modules/ShippingZones/constants';
import { LabelValue } from 'modules/ShippingZones/reducer/interface';
import { useCallback, useEffect, useMemo } from 'react';
import { debounceSearchFn, useComplexSelectProps } from '../interface';

function useComplexSelect<VT, FT>({
  form,
  optionList,
  handleSearch,
  labelProp,
  valueProp,
  indexDataProp,
  formValueName,
}: useComplexSelectProps<VT, FT>) {
  const debouncedSearch: DebouncedFunc<debounceSearchFn> = useMemo(
    () => debounce(handleSearch ?? EmptyFunction, 700),
    [handleSearch],
  );

  const handleDSearch = useCallback(
    (searchTerm: string) => debouncedSearch(searchTerm),
    [debouncedSearch],
  );

  const handleSelect = useCallback(
    (_, opt: LabelValue<string, VT>) => {
      const actualVal = (form.getFieldValue(formValueName) ?? []) as VT[];
      const valueToAppend = opt?.addon;

      const deduplicatedArray = actualVal.filter(
        (v) =>
          v?.[indexDataProp ?? ''] !== valueToAppend?.[indexDataProp ?? ''],
      );

      form.setFieldValue(formValueName, [...deduplicatedArray, valueToAppend]);
    },
    [form, formValueName, indexDataProp],
  );

  const handleDeselect = useCallback(
    (item: VT) => {
      const actualVal = (form.getFieldValue(formValueName) ?? []) as VT[];
      const id = item?.[indexDataProp] ?? item ?? '';

      const filtered = actualVal.filter((act) => {
        const compared = act?.[indexDataProp] ?? '';

        return id !== compared;
      });

      form.setFieldValue(formValueName, filtered);
      handleSearch?.('');
    },
    [form, formValueName, handleSearch, indexDataProp],
  );

  const options: LabelValue<string, VT>[] = useMemo(() => {
    const allAreLabels = optionList?.every((o: any) => o.label && o.value);
    if (allAreLabels) return optionList as LabelValue<string, VT>[];

    const castedToLabelValue = optionList.map(
      (o: any): LabelValue<string, VT> => ({
        label: o?.[labelProp ?? Object.keys(o)?.[0] ?? ''] ?? '',
        value: o?.[valueProp ?? Object.keys(o)?.[0] ?? ''] ?? '',
        addon: o,
      }),
    );

    return castedToLabelValue;
  }, [labelProp, optionList, valueProp]);

  // This effect cleans the damaged values.
  useEffect(() => {
    const actualValues = (form.getFieldValue(formValueName) ?? []) as any[];
    const cleanValues = actualValues.filter((act) => {
      const compared = act?.[indexDataProp] ?? null;
      return compared;
    });

    form.setFieldValue(formValueName, cleanValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    options,
    handleSearch: handleDSearch,
    handleSelect,
    handleDeselect,
  };
}

export default useComplexSelect;
