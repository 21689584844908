/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import {
  City,
  Regional,
  ShippingZones,
  Transport,
} from 'services/cities/interface';
import { PaginatedResponse } from 'services/global/interface';
import {
  LabelValue,
  ShippingZoneUpdateAction,
  ShippingZoneUpdateState,
  SHIPPING_ZONE_UPDATE_ACTION_ENUM,
} from './interface';

export const INITIAL_SHIPPING_ZONE_UPDATE_STATE: ShippingZoneUpdateState = {
  shippingZone: {} as unknown as ShippingZones,
  regionList: [],
  regionOptionList: [],
  transportList: [],
  transportOptionList: [],
  citiesList: [],
  citiesOptionList: [],
  editionMode: false,
};

export function shippingZoneUpdateReducer(
  state: ShippingZoneUpdateState,
  action: ShippingZoneUpdateAction,
): ShippingZoneUpdateState {
  switch (action.type) {
    case SHIPPING_ZONE_UPDATE_ACTION_ENUM.TURN_EDITION_MODE:
      return {
        ...state,
        editionMode: action.payload,
      };
    case SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_SHIPPING_ZONE:
      return {
        ...state,
        shippingZone: action.payload,
      };
    case SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_TRANSPORT_LIST:
      const paginatedTransports =
        action.payload as PaginatedResponse<Transport>;

      const transportsParsedToForm = paginatedTransports.data.map(
        (t): LabelValue<string> => ({
          label: t.name,
          value: t._id,
        }),
      );

      return {
        ...state,
        transportOptionList: transportsParsedToForm,
        transportList: paginatedTransports.data,
      };
    case SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_REGION_LIST:
      const regionsParsedToForm = action.payload?.regions?.data?.map(
        (region: Regional): LabelValue<string> => ({
          label: region.value,
          value: region.id,
        }),
      );

      return {
        ...state,
        regionOptionList: regionsParsedToForm ?? [],
        regionList: action.payload?.regions?.data ?? [],
      };
    case SHIPPING_ZONE_UPDATE_ACTION_ENUM.SET_CITIES_LIST:
      const paginatedCities = action.payload as PaginatedResponse<City>;

      const citiesParsedToForm = paginatedCities.data.map(
        (t): LabelValue<string, City> => ({
          label: `[${t.departmentCode}] - ${t.name}`,
          value: `[${t.departmentCode}] - ${t.name}`,
          addon: t,
        }),
      );

      return {
        ...state,
        citiesList: paginatedCities.data,
        citiesOptionList: citiesParsedToForm,
      };
    default:
      return state;
  }
}
