import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const ProductFigure = styled.figure`
    width: 50px;
    max-height: 50px;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    & > img {
        width: 100%;
        object-fit: contain;
    }
`;

export const ItemLabel = styled.span`
    font-weight: bold;
    padding-right: ${LightTheme.spaceSizes.m};
`;

export const ItemParagraph = styled.div`
    margin: ${LightTheme.spaceSizes.s} 0;
`;
