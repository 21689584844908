import { FormInstance } from 'antd';
import { AttributeTypeInterface } from 'containers/Products/AttributesType/interface';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import { createRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useAttributeType = () => {
  const [attributeModalOpen, setAttributeModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    createMutation: {
      mutateAsync: createAttributeType,
      isLoading: createAttributeTypeLoading,
    },
  } = useModuleCRUD('attributeType');
  const { notifySuccess } = useNotifications({
    translationFunction: t,
  });
  const attributeTypeFormRef =
    createRef<FormInstance<AttributeTypeInterface>>();

  const handleOpenAttributeTypeModal = useCallback(() => {
    setAttributeModalOpen(true);
  }, []);

  const handleCloseAttributeTypeModal = useCallback(() => {
    setAttributeModalOpen(false);
  }, []);

  const handleAttributeTypeFormSubmit = async () => {
    try {
      const values = attributeTypeFormRef.current?.getFieldsValue();
      await createAttributeType(values);
      notifySuccess(t('attributesTypeForm.notifications.success'));
      handleCloseAttributeTypeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    handleCloseAttributeTypeModal,
    handleOpenAttributeTypeModal,
    attributeModalOpen,
    attributeTypeFormRef,
    handleAttributeTypeFormSubmit,
    createAttributeTypeLoading,
  };
};
