import { Divider, Select, Space, Tabs } from 'antd';
import editIcon from 'assets/images/editIcon.svg';
import CreditLineForm from 'components/Credit/CreditForm/CreditForm';
import {
  CreditListStatement,
  CreditListStatementOrders,
} from 'components/Credit/CreditListStatement';
import { CustomTabs } from 'components/CustomTabs/CustomTabs';
import { creditFormInitial } from 'containers/Users/Credit/interface';
import { numberToCurrency } from 'helpers/utils/currency';
import { useTranslation } from 'react-i18next';
import {
  BalanceInFavor,
  Header,
  IconImg,
  StatusFlag,
  StatusItemContainer,
  Title,
} from './CreditLineFormContainer.sty';
import {
  CreditFormContainerProps,
  CreditStatusColor,
  CreditStatusEnum,
} from './interface';

const { Option } = Select;
const { TabPane } = Tabs;

const CreditLineFormContainer = ({
  initialValues = creditFormInitial,
}: CreditFormContainerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <Title>{t('creditsForm.titles.detail')}</Title>
        <IconImg alt="Update logo" src={editIcon} />
      </Header>
      <StatusItemContainer>
        <Select
          value={initialValues?.status ?? CreditStatusEnum.ACTIVE}
          disabled
        >
          <Option value={CreditStatusEnum.ACTIVE} key={CreditStatusEnum.ACTIVE}>
            <Space direction="horizontal">
              <StatusFlag color={CreditStatusColor[CreditStatusEnum.ACTIVE]} />
              {t('creditsForm.labels.status.active')}
            </Space>
          </Option>
          <Option
            value={CreditStatusEnum.INACTIVE}
            key={CreditStatusEnum.INACTIVE}
          >
            <Space direction="horizontal">
              <StatusFlag
                color={CreditStatusColor[CreditStatusEnum.INACTIVE]}
              />
              {t('creditsForm.labels.status.inactive')}
            </Space>
          </Option>
        </Select>
      </StatusItemContainer>
      <Divider />
      <CustomTabs>
        <TabPane tab={t('creditsForm.creditDetail')} key={1}>
          <CreditLineForm initialValues={initialValues} />
        </TabPane>
        <TabPane tab={t('creditsForm.orders')} key={2}>
          <CreditListStatementOrders userId={initialValues.user?.id ?? ''} />
        </TabPane>
        <TabPane tab={t('creditsForm.statement')} key={3}>
          <CreditListStatement userId={initialValues.user?.id ?? ''} />
        </TabPane>
      </CustomTabs>
      {!!initialValues.balanceInFavor && (
        <BalanceInFavor>
          {t('creditsForm.balanceInFavor')}
          {numberToCurrency(initialValues.balanceInFavor ?? 0)}
        </BalanceInFavor>
      )}
    </>
  );
};

export default CreditLineFormContainer;
