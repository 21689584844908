import { Button } from 'antd';
import { SearchInput } from 'modules/Order/components/OrderContent/OrderContent.sty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultTitleText,
  ListWrapper,
  TitleColumnDefault,
  TitleRowDefault,
} from './DefaultTitle.sty';
import { DefaultTitleProps } from './interface';

export const DefaultTitle = ({
  createButton,
  returnButton,
  search,
  filters,
  createButtonText,
  returnButtonText,
  currentView,
  initialTitle,
}: DefaultTitleProps) => {
  const { t } = useTranslation();
  return (
    <TitleRowDefault>
      <TitleColumnDefault>
        {
          {
            list: (
              <>
                <DefaultTitleText level={1}>
                  {t(`${initialTitle}.title.${currentView}`)}
                </DefaultTitleText>
                <ListWrapper>
                  {search && (
                    <SearchInput
                      placeholder={t('textSearchPlaceholder')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        search(e.target.value)
                      }
                      style={{ height: '40px' }}
                    />
                  )}
                  {filters && filters}
                  <Button onClick={createButton}>{createButtonText}</Button>
                </ListWrapper>
              </>
            ),
            create: (
              <>
                <DefaultTitleText level={1}>
                  {t(`${initialTitle}.title.${currentView}`)}
                </DefaultTitleText>

                <Button onClick={returnButton}>{returnButtonText}</Button>
              </>
            ),
            update: (
              <>
                <DefaultTitleText level={1}>
                  {t(`${initialTitle}.title.${currentView}`)}
                </DefaultTitleText>

                <Button onClick={returnButton}>{returnButtonText}</Button>
              </>
            ),
          }[currentView]
        }
      </TitleColumnDefault>
    </TitleRowDefault>
  );
};
