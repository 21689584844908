/* eslint-disable no-underscore-dangle */
import { AxiosResponse } from 'axios';
import { CitiesQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import {
  GetShippingRegionalConfigParams,
  RegionalConfig,
  ShippingZones,
} from './interface';

export const getShippingZoneList = async (queryParams?: CitiesQueryParams) => {
  const response = await getAPIMethod({
    name: 'getShippingZoneList',
    params: {
      search: queryParams?.search,
      departmentCode: queryParams?.departmentCode,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const getRegionalConfig = async ({
  id,
}: GetShippingRegionalConfigParams): Promise<RegionalConfig> => {
  const response = await getAPIMethod({
    name: 'getRegionalConfig',
    params: {
      regionId: id,
    },
  });
  return response.data;
};

export const getCitiesOptions = async (queryParams?: CitiesQueryParams) => {
  const response = await getAPIMethod({
    name: 'getCitiesOptions',
    params: {
      search: queryParams?.search,
      departmentCode: queryParams?.departmentCode,
    },
  });
  return response.data;
};

export const updateShippingZone = async (params: {
  id: string;
  payload: ShippingZones;
}): Promise<ShippingZones> => {
  const response: AxiosResponse<ShippingZones> = await getAPIMethod({
    name: 'updateShippingZone',
    pathParams: {
      id: params.id,
    },
    data: params.payload,
  });
  return response.data;
};

export const createShippingZone = async (
  ShippingZone: Partial<ShippingZones>,
) => {
  const response = await getAPIMethod({
    name: 'createShippingZone',
    data: ShippingZone,
  });
  return response.data;
};
