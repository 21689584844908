/* eslint-disable no-underscore-dangle */
import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { LogisticType } from 'services/MPS/interface';
import { Variant } from 'services/product/interface';
import { UpdateVariantStock } from 'services/stock/interface';
import { updateVariantStock } from 'services/stock/stock.service';
import { IVariantStockForm } from '../interface';

const useUpdateStock = ({ variant }: { variant: Partial<Variant> }) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (body: UpdateVariantStock) => updateVariantStock(body),
  });

  const warehouseId = useMemo(() => {
    if (variant?.mps?.logisticType === LogisticType['3P']) {
      return variant?.mps.wms?.mainWarehouse?._id || null;
    }
    return null;
  }, [variant]);

  const onUpdateVariantStock = useCallback(
    async (values: IVariantStockForm) => {
      const newStock: UpdateVariantStock = {
        cap: values?.cap ?? 0,
        inWarehouse: values.inWarehouse,
        variantId: variant.id ?? variant._id ?? '',
        inventoryChangeReason: values.reason ?? '',
        cost: values.cost ?? 0,
        warehouseId,
        logisticType: variant?.mps?.logisticType || LogisticType.TODAY,
      };

      await mutateAsync(newStock);
    },
    [variant, warehouseId, mutateAsync],
  );

  return {
    updateVariantStock: onUpdateVariantStock,
    updateLoading: isLoading,
  };
};

export default useUpdateStock;
