import { InputNumber } from 'antd';
import { t } from 'i18next';
import { Variant } from 'services/product/interface';
import { UnitTableQuantityChange } from '../interface';

const QuantityColumn = (onChangeQuantity: UnitTableQuantityChange) => {
  return {
    title: t('productTableKeys.quantity'),
    render: (variant: Variant) => (
      <>
        <InputNumber
          style={{ width: 60 }}
          onChange={(value) => {
            onChangeQuantity(variant, value ?? 0, variant?.unitsLength ?? 0);
          }}
          max={variant?.unitsLength ?? 0}
          min={0}
          type="number"
        />
        {` / ${variant.unitsLength ?? 0}`}
      </>
    ),
  };
};

export default QuantityColumn;
