import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const WarrantyTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
`;

export const WarrantyCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WarrantyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const PendingWarrantyCounter = styled.span`
  background-color: ${LightTheme.color.primary};
  color: ${LightTheme.color.white};
  border-radius: 50%;
  display: inline-grid;
  place-content: center;
  width: 20px;
  height: 20px;
`;
