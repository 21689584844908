import { Tag } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled, { css } from 'styled-components';

interface CardContainerProps {
  border: string;
  background?: string;
}
export interface PurchaseStatusTagProps {
  color: string;
}

export const PurchaseCardContainer = styled.div<CardContainerProps>`
  transition: 0.2s;
  background-color: ${({ background }) =>
    background ? css`background` : 'white'};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0px;
  width: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: ${({ border }) => css`0 0 5px 0 ${border};`};

  & p {
    margin: 0;
    margin-bottom: 2px;
    font-size: 0.8em;
    text-align: left;
  }

  & span.status-tag {
    margin: 0;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:focus {
    cursor: grab;
  }
`;

export const PurchaseCardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const PurchaseCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  width: 100%;
`;

export const PurchaseCardFooterAside = styled.div`
  display: flex;
  border: 1px solid black;
  border-radius: 100%;
  padding: 4px;
  margin: auto;
  cursor: pointer;
  background-color: rgba(26, 206, 195, 0.3);
  border: 2px solid #1acec3;
  background-color: rgba(26, 206, 195, 0.3);
  justify-content: flex-end;
  span {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const PurchaseCardGrayParagraph = styled.p`
  color: ${LightTheme.color.gray_dark};
  font-size: 1em;
`;

export const PurchaseCardClientName = styled.b`
  color: ${LightTheme.color.dark};
  margin-bottom: 5px;
`;

export const PurchaseCardTotal = styled.p`
  color: ${LightTheme.color.primary};
`;

export const PurchaseCardFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const TagContainer = styled.div<{ space?: boolean }>`
  ${({ space }) => (space ? 'margin: 5px 0;' : '')}
  .ant-tag {
    margin-right: 3px;
  }
`;

export const PurchaseStatusTag = styled(Tag)<PurchaseStatusTagProps>`
  background: ${({ color }) => `${color}`}26 !important;
  color: ${({ color }) => `${color}`};
  font-weight: bold;
`;
