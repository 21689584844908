import { DatePickerProps, Select } from 'antd';
import { CsvInput } from 'components/CsvInput/CsvInput';
import { CustomTag } from 'components/CustomTag';
import config from 'config';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD';
import dateFormatCol from 'helpers/utils/dateFormatCol';
import { CustomRangePicker } from 'modules/Order/components/OrderContent/OrderContent.sty';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusTagColor } from 'services/order/interface';
import { getRoutesStatusFilters } from 'services/routes/routes.service';
import { RoutesFilterContainer } from './Routes.sty';

const RoutesCsvInput = () => {
  const {
    readQuery: { mutateAsync: fetchFormat, isLoading: isLoadingFormat },
  } = useModuleCRUD('routes');

  const { t } = useTranslation();

  const dateFormatNow = 'DD/MM/YYYY';
  const [deliveryDateFilter, setDeliveryDateFilter] = useState<string[]>();
  const [filters, setFilters] = useState<string[]>();
  const [statusFilter, setStatusFilter] = useState<string[]>();

  const getFilter = async () => {
    const response: string[] = await getRoutesStatusFilters();
    setFilters(response);
  };

  useEffect(() => {
    getFilter();
  }, []);

  const customFormat: DatePickerProps['format'] = useCallback(
    (value) => `${value.format(dateFormatNow)}`,
    [],
  );

  const handleChangeDeliveryDateFilter = useCallback(
    (_: any, dateString: string[]) => {
      const startDate = dateFormatCol(dateString[0]);
      const endDate = dateFormatCol(dateString[1]);
      setDeliveryDateFilter([startDate, endDate]);
    },
    [],
  );

  const handleStatusFilterChange = useCallback((status: string[]) => {
    setStatusFilter(status);
  }, []);

  const routesFilters = (
    <RoutesFilterContainer>
      <CustomRangePicker
        format={customFormat}
        allowClear
        onChange={handleChangeDeliveryDateFilter}
        value={
          deliveryDateFilter?.[0]
            ? [moment(deliveryDateFilter?.[0]), moment(deliveryDateFilter?.[1])]
            : undefined
        }
        placeholder={[
          t('orderTableFilters.deliveryDateOne'),
          t('orderTableFilters.deliveryDateTwo'),
        ]}
      />

      <Select
        style={{ minWidth: 160, width: 'fit-content' }}
        placeholder={t('orderTableFilters.status')}
        value={statusFilter}
        mode="multiple"
        onChange={handleStatusFilterChange}
      >
        {filters?.map((statusOption) => (
          <Select.Option value={statusOption} key={statusOption}>
            <CustomTag color={StatusTagColor[statusOption]}>
              {t(`orderStatus.${statusOption}`) || `${statusOption}` || ''}
            </CustomTag>
          </Select.Option>
        ))}
      </Select>
    </RoutesFilterContainer>
  );

  return (
    <CsvInput
      fetchFormat={fetchFormat}
      isLoadingFormat={isLoadingFormat}
      draggerName="routes"
      disabled={!deliveryDateFilter}
      filtersValue={{ deliveryDateFilter, statusFilter }}
      filters={routesFilters}
      actionUrl={config.routesCsvUpload.actionURL}
      fileName="routes.csv"
      title="routesCsvInput.modal.content"
      csvInputHint="routesCsvInput.hint"
    />
  );
};

export default RoutesCsvInput;
