import { useMutation } from '@tanstack/react-query';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { PandasError } from 'config/Errors/error';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import { isString } from 'lodash';
import InvoiceReportsUpload from 'modules/Invoice/components/InvoiceReportsUpload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useCallback, useMemo } from 'react';
import { updateInvoiceReports } from 'services/wallet/wallet.service';

const InvoiceReportsContainer = () => {
  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  function downloadExcel(file: Blob) {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'reporte.xlsx'); // O cualquier otro nombre de archivo
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationFn: updateInvoiceReports,
  });
  const actionStatus = useMemo(() => {
    if (isError) return 'exception';
    if (isSuccess) return 'success';
    if (isLoading) return 'active';
    return 'normal';
  }, [isError, isSuccess, isLoading]);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      const { name } = file;
      const extension = name.split('.').pop();
      if (extension !== 'xlsx') {
        notifyError(t('invoiceReportsUpload.errors.extension'));
        return false;
      }

      return true;
    },
    [notifyError],
  );
  const handleRequest = useCallback(
    async (options: UploadRequestOption) => {
      const formData = new FormData();
      formData.append('file', options.file);

      try {
        const res = await mutateAsync(formData);
        downloadExcel(res);
        notifySuccess('invoiceReportsUpload.success');
      } catch (error: unknown) {
        const { code } = error as PandasError;
        notifyError(t(`invoiceReportsUpload.errors.${code}`, { code }));
      }
    },
    [mutateAsync, notifyError, notifySuccess],
  );
  return (
    <InvoiceReportsUpload
      status={actionStatus}
      beforeUpload={beforeUpload}
      handleRequest={handleRequest}
    />
  );
};

export default InvoiceReportsContainer;
