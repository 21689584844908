import { Method } from 'axios';

export const endpoints = {
  getTransportList: {
    method: 'GET' as Method,
    url: 'shipping-dom/transport',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  updateTransport: {
    method: 'PUT' as Method,
    url: 'shipping-dom/transport/{params.id}',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  createTransport: {
    method: 'POST' as Method,
    url: 'shipping-dom/transport',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
};
