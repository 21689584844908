import { DownCircleOutlined } from '@ant-design/icons';
import { BaseColors } from 'helpers/constants/theme';
import styled from 'styled-components';

export const ScrollDownButton = styled.button`
  background: ${BaseColors.deep_blue};
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 5px 0px #0000003c;
  z-index: 2;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s;
  color: #cfcfcf;
  font-size: 1em;

  &:hover {
    transform: scale(1.1);
    font-size: 1.2em;
    color: white;
  }
`;

export const ScrollDownIcon = styled(DownCircleOutlined)`
  color: inherit;
  font-size: inherit;
`;

export const ScrollDownContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  justify-content: flex-end;
  align-items: center;
  height: 0px;
  overflow: visible;
  display: flex;
`;
