import { Method } from 'axios';

export const endpoints = {
  getPandabonosById: {
    method: 'GET' as Method,
    url: 'finance-dom/pandabonos/user/{params.userId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  uploadPandabonosCsv: {
    method: 'POST' as Method,
    url: 'finance-dom/pandabonos/upload',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getPandabonosHistory: {
    method: 'GET' as Method,
    url: 'finance-dom/pandabonos/uploads',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
