import { Package } from 'services/package/interface';
import { DeliveryRangeOption } from 'services/shipping/interface';

export enum ORDER_PACKAGE_ACTION_ENUM {
  SET_PACKAGES = 'SET_PACKAGES',
  SET_PACKAGE_DELIVERY_OPTIONS = 'SET_PACKAGE_DELIVERY_OPTIONS',
}

export type OrderPackageAction = {
  type: ORDER_PACKAGE_ACTION_ENUM;
  payload: any;
};

export interface OrderPackageState {
  packages: Package[];
  deliveryOptions: Record<string, DeliveryRangeOption[]>;
}
