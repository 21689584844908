import { Method } from 'axios';

export const endpoints = {
  uploadDoc: {
    method: 'POST' as Method,
    url: '/files-dom/doc',
    baseURL: process.env.REACT_APP_API_URL,
  },
  uploadImage: {
    method: 'POST' as Method,
    url: '/files-dom/image',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
