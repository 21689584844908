/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import { CustomTag } from 'components/CustomTag';
import { DefaultTitle } from 'components/DefaultTitle/DefaultTitle';
import { ShippingZonesActionEnum } from 'config/Constants/actions';
import { useShippingZonesContext } from 'context/ShippingZonesContext/ShippingZones.Context';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import { debounce } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { DefaultContainer } from 'pages/Brands/BrandsList/BrandsList.sty';
import { DEBOUNCED_SEARCH_MS } from 'pages/Products/UpdateProduct/constants';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getShippingZoneList } from 'services/cities/cities.service';
import {
  Columns,
  ShippingZones,
  ShippingZonesViewEnum,
} from 'services/cities/interface';
import { CouponStatusTagColor } from 'services/coupons/interface';
import { ShippingZonesList } from '../components/ShippingZonesList/ShippingZones';

moment.locale('es');
const ShippingZonesContainer = () => {
  const { state, dispatch } = useShippingZonesContext();
  const { currentView, search, departmentCode, pagination, ShippingZoneList } =
    state;
  const tableRef = useRef<{ scrollToTop: () => void }>(null);
  const navigate = useNavigate();
  const { notifyError } = useNotifications({
    translationFunction: t,
  });

  const GoToCreate = useCallback(() => {
    navigate(ROUTES.shippingZoneCreate);
  }, [navigate]);

  const GoToList = () => {
    dispatch({
      type: ShippingZonesActionEnum.SET_CURRENT_VIEW,
      payload: ShippingZonesViewEnum.LIST,
    });

    dispatch({
      type: ShippingZonesActionEnum.SET_SHIPPING_ZONE_LIST,
      payload: null,
    });
  };

  const GoToUpdate = useCallback(
    (shippingZone: ShippingZones) => {
      navigate(`${ROUTES.shippingZones}/${shippingZone._id}`);
    },
    [navigate],
  );

  const handleSearchWithDebounce = useMemo(
    () =>
      debounce((value: string) => {
        dispatch({
          type: ShippingZonesActionEnum.SET_SEARCH,
          payload: value,
        });
      }, DEBOUNCED_SEARCH_MS),
    [dispatch],
  );

  const handleSearch = useCallback(
    (value: string) => {
      handleSearchWithDebounce(value);
    },
    [handleSearchWithDebounce],
  );

  const handlePagination = () => {
    dispatch({
      type: ShippingZonesActionEnum.SET_PAGINATION,
      payload: {},
    });
  };

  const fetchShippingZones = useCallback(async () => {
    try {
      const response = await getShippingZoneList({
        search,
        departmentCode,
        page: pagination.page,
        pageSize: pagination.pageSize,
      });

      dispatch({
        type: ShippingZonesActionEnum.SET_SHIPPING_ZONE_LIST,
        payload: response.data,
      });
    } catch (error) {
      notifyError('cityTableKeys.notifications.list.error');
    }
  }, [search, departmentCode, currentView]);

  useEffect(() => {
    fetchShippingZones();
  }, [fetchShippingZones]);

  const columns: Columns[] = [
    {
      title: t('cityTableKeys.name'),
      dataIndex: 'name',
      width: '100%',
    },
    {
      title: t('cityTableKeys.labels.freeShippingLimit'),
      dataIndex: 'freeShippingLimit',
      width: '100%',
    },
    {
      title: t('cityTableKeys.leadTime'),
      dataIndex: 'leadTime',
      width: '100%',
    },
    {
      title: t('cityTableKeys.minimumOrderAmount'),
      dataIndex: 'minimumOrderAmount',
      width: '100%',
    },
    {
      title: t('cityTableKeys.shippingCost'),
      dataIndex: 'shippingCost',
      width: '100%',
    },
    {
      title: t('cityTableKeys.transport'),
      dataIndex: 'transport.name',
      width: '100%',
    },
    {
      title: t('cityTableKeys.status'),
      dataIndex: 'status',
      width: '100%',
      render: (item: string, _: ShippingZones) => {
        return (
          <CustomTag color={CouponStatusTagColor[_.status]}>
            {t(`cityTableKeys.statusItem.${item}`) || `${item}` || ''}
          </CustomTag>
        );
      },
    },
  ];

  return (
    <DefaultContainer>
      <DefaultTitle
        createButton={GoToCreate}
        returnButton={GoToList}
        search={handleSearch}
        currentView={currentView}
        initialTitle="cityTableKeys"
        createButtonText={t('cityTableKeys.createButton')}
        returnButtonText={t('cityTableKeys.returnButton')}
      />
      <ShippingZonesList
        list={ShippingZoneList}
        tableRef={tableRef}
        loading={false}
        onViewDetail={GoToUpdate}
        columns={columns}
        reachedEndOfData={pagination.hasNext}
        onScrollToEnd={handlePagination}
      />
    </DefaultContainer>
  );
};

export default ShippingZonesContainer;
