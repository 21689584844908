import { Location } from 'services/location/interface';
import { RuleOption } from 'services/wms-settings/interface';

const baseLocation: Location = {
  _id: '',
  createdAt: new Date(),
  deletedAt: null,
  hierarchy: 1,
  isParent: false,
  isWarehouse: false,
  name: '',
  parentId: '',
  properties: [],
  updatedAt: new Date(),
  updatedBy: {
    email: '',
    id: '',
    name: '',
    roles: [],
  },
};

export function optionToLocation(option: RuleOption): Location {
  return {
    ...baseLocation,
    _id: option.value,
    name: option.label,
  };
}

export function optionsToLocation(options: RuleOption[]): Location[] {
  return options.map((option) => ({
    ...baseLocation,
    _id: option.value,
    name: option.label,
  }));
}
