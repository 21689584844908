import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import { debounce } from 'lodash';
import PurchaseBoard from 'modules/Purchase/components/PurchaseBoard';
import PurchasContent from 'modules/Purchase/components/PurchasesContent';
import PurchaseTable from 'modules/Purchase/components/PurchaseTable';
import {
  CUSTOM_MESSAGE,
  LIST_PURCHASE_ERROR,
} from 'modules/Purchase/constants';
import { usePurchase } from 'modules/Purchase/context/Purchase.context';
import useReadPurchase from 'modules/Purchase/hooks/useReadPurchase';
import { PURCHASE_ACTION_ENUM } from 'modules/Purchase/reducer/interface';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Purchase,
  PurchaseStatus,
  purchaseStatusColors,
} from 'services/purchase/interface';

const PurchaseListContainer = () => {
  const navigate = useNavigate();
  const { state, dispatch } = usePurchase();
  const {
    purchasesList,
    purchasesListLoading,
    purchaseBoard,
    purchasesBoardNext,
    purchasesError,
    nextPurchasesList,
  } = useReadPurchase({
    filters: state.filters,
  });

  const { notifyError } = useNotifications({
    customMessage: CUSTOM_MESSAGE,
    translationFunction: t,
  });

  // -------- Callbacks ---------- //
  const onViewDetail = useCallback(
    (purchase: Purchase) => {
      navigate(`${ROUTES.purchase}/${purchase.id}`);
    },
    [navigate],
  );
  const handleGoToCreate = useCallback(() => {
    navigate(ROUTES.purchaseCreate);
  }, [navigate]);

  const handleSearch = debounce((value: string) => {
    dispatch({
      type: PURCHASE_ACTION_ENUM.SET_SEARCH_PURCHASES,
      payload: value,
    });
  }, 700);

  const boardData = useMemo(
    () =>
      Object.keys(PurchaseStatus).map((status) => ({
        id: status,
        name: t(`purchaseStatus.${status}`),
        items: purchaseBoard[status as PurchaseStatus].data,
        infiniteScrollData: {
          hasNext:
            purchaseBoard[status as PurchaseStatus]?.meta?.hasNext ?? false,
          page: purchaseBoard[status as PurchaseStatus]?.meta?.page ?? 1,
        },
        color: purchaseStatusColors[status],
      })),
    [purchaseBoard],
  );

  // ================== EFFECTS =================
  useEffect(() => {
    if (purchasesError) {
      notifyError(t(LIST_PURCHASE_ERROR));
    }
  }, [purchasesError, notifyError]);

  return (
    <PurchasContent
      onSearch={handleSearch}
      onCreatePurchase={handleGoToCreate}
      Board={
        <PurchaseBoard
          columns={boardData}
          purchasesBoardNext={purchasesBoardNext}
          onClick={onViewDetail}
          handleDragCard={() => ({})}
        />
      }
      List={
        <PurchaseTable
          data={purchasesList}
          isLoading={purchasesListLoading}
          onViewDetail={onViewDetail}
          onScrollToEnd={nextPurchasesList}
        />
      }
    />
  );
};

export default PurchaseListContainer;
