/* eslint-disable react/jsx-no-useless-fragment */
import { Tabs } from 'antd';
import { CustomTabs } from 'components/CustomTabs/CustomTabs';
import { useTranslation } from 'react-i18next';
import { useHaulierFormContainer } from './hooks/useHaulierFormContainer';
import { HaulierFormContainerProps } from './interface';

const { TabPane } = Tabs;

export const HaulierFormContainer = ({
  haulierNumber,
  children,
}: HaulierFormContainerProps) => {
  const { t } = useTranslation();
  const { historyList, fetchHaulierHistoryPage, hasNext, loadingHistory } =
    useHaulierFormContainer(haulierNumber);
  if (!haulierNumber) return <>{children}</>;
  return (
    <CustomTabs>
      <TabPane tab={t('haulier.tabs.form')} key="1">
        {children}
      </TabPane>

      {/*  <TabPane tab={t('haulier.tabs.history')}>
        <HistoryContainer id="scrollableDiv">
          <InfiniteScroll
            next={fetchHaulierHistoryPage}
            dataLength={historyList?.length || 0}
            hasMore={hasNext}
            loader={<Spin tip={t('loading')} />}
            scrollableTarget="scrollableDiv"
            endMessage={
              !loadingHistory && historyList.length ? (
                <NoHistory>
                  <img alt="pandas logo" src={LogoImage} />
                  <p>{t('variantHistory.noMoreToLoad')}</p>
                </NoHistory>
              ) : null
            }
          >
            <List
              dataSource={historyList}
              renderItem={(historyItem) => (
                <HaulierHistoryItem
                  log={historyItem}
                  key={historyItem?.routeNumber}
                  hasDivider
                />
              )}
            />
          </InfiniteScroll>
        </HistoryContainer>
      </TabPane> */}
    </CustomTabs>
  );
};
