import { intersectionBy } from 'lodash';
import { Behavior } from 'services/wms-settings/interface';

export function findBehavior(
  list: Behavior[],
  behaviorToSearch: Behavior,
): Behavior | undefined {
  return list.find((behavior) => {
    const ruleSetsIntersection = intersectionBy(
      behavior.ruleSet,
      behaviorToSearch.ruleSet,
      (rule) => {
        return rule.evaluation + String(rule.evaluator) + rule.field;
      },
    );

    const equalRuleSets = ruleSetsIntersection.length;

    return equalRuleSets === behaviorToSearch.ruleSet.length;
  });
}

export function checkIfBehaviorExists(
  list: Behavior[],
  behaviorToSearch: Behavior,
): boolean {
  return !!findBehavior(list, behaviorToSearch);
}
