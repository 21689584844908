import { getAPIMethod } from 'helpers/libs/Http';
import { capitalize } from 'lodash';
import { UploadParams, UploadResponse } from './interface';

export const uploadFile = async (
  params: UploadParams,
  rawType: 'doc' | 'image',
): Promise<UploadResponse> => {
  const form = new FormData();
  form.append('folder', params.folder);
  form.append('bucket', process.env.REACT_APP_ASSETS_BUCKET ?? '');
  form.append('file', params.file);

  const type = `${capitalize(rawType)}`;

  const response = await getAPIMethod({
    name: `upload${type}`,
    data: form,
  });
  return response.data;
};
