import { CartVariant } from 'services/product/interface';

export const computeVolumeDiscount = (variant: CartVariant): number => {
  if (Array.isArray(variant?.volumePrice) && variant?.volumePrice?.length) {
    if (variant.cartQuantity === 0) {
      return Math.max(...variant.volumePrice.map((z) => z.price));
    }

    const price = variant.volumePrice.find(
      (range) =>
        (variant.cartQuantity ?? 0) >= range.min &&
        (variant.cartQuantity ?? 0) <=
          (range.max === -1 ? Infinity : range.max),
    )?.price;

    return price ?? 0;
  }
  return variant.price - (variant.discount ?? 0);
};
