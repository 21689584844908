import styled from 'styled-components';
import { LightTheme } from 'helpers/constants/theme';

export const ItemParagraph = styled.div`
  display: flex;
  margin: ${LightTheme.spaceSizes.s} 0;
  flex-wrap: wrap;
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  margin-right: ${LightTheme.spaceSizes.m};
`;
