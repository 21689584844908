import { CreditForm } from 'containers/Users/Credit/interface';
import { LightTheme } from 'helpers/constants/theme';

export type CreditFormContainerProps = {
  initialValues?: CreditForm;
};

export enum CreditModalAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum CreditStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const CreditStatusColor: { [key: string]: string } = {
  [CreditStatusEnum.ACTIVE]: LightTheme.color.soft_link,
  [CreditStatusEnum.INACTIVE]: LightTheme.color.danger,
};
