/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { CustomTag } from 'components/CustomTag';
import TableActions from 'components/TableActions/TableActions';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { CouponStatusTagColor } from 'services/coupons/interface';
import { Haulier } from 'services/haulier/interface';

const tableColumns = (
  t: TFunction<'translation', undefined>,
  HaulierActions: (haulier: any) => JSX.Element,
) => [
  {
    title: t('haulier.haulierNumber'),
    dataIndex: 'haulierNumber',
    width: '140px',
  },
  {
    title: t('haulier.name'),
    dataIndex: 'name',
    width: '180px',
  },
  {
    title: t('haulier.phone'),
    dataIndex: 'phone',
    width: '180px',
  },
  {
    title: t('haulier.carType'),
    dataIndex: 'carType',
    width: '140px',
  },
  {
    title: t('haulier.licensePlate'),
    dataIndex: 'licensePlate',
    width: '140px',
  },
  {
    title: t('haulier.company'),
    dataIndex: 'company',
    width: '140px',
  },
  {
    title: t('coupons.status'),
    dataIndex: 'status',
    width: '120px',
    render: (haulier: Haulier, _: any) => (
      <CustomTag color={CouponStatusTagColor[_.status]}>
        {t(`couponStatus.${haulier}`) || `${haulier}` || ''}
      </CustomTag>
    ),
  },
  {
    title: t('haulier.actions'),
    dataIndex: '',
    width: '120px',
    render: (haulier: any) => HaulierActions(haulier),
  },
];

const useHaulierTable = ({
  onDeleteHaulier,
  onUpdateHaulier,
}: {
  onDeleteHaulier: (haulier: any) => void;
  onUpdateHaulier: (haulier: any) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('haulier.editActions');

  const HaulierActions = (haulier: any) => (
    <AccessWrapper
      fallback={
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {t('noAccess')}
        </Tag>
      }
    >
      <TableActions
        entity={haulier}
        onDelete={onDeleteHaulier}
        onUpdate={onUpdateHaulier}
      />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(t, HaulierActions);
  const columns = useMemo(() => defaultColumns, [defaultColumns]);

  return {
    columns,
    t,
  };
};

export default useHaulierTable;
