import { Method } from 'axios';

export const endpoints = {
  getLocationList: {
    method: 'GET' as Method,
    url: '/location-dom/location',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getLocationTree: {
    method: 'GET' as Method,
    url: '/location-dom/location/{params.locationId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
  getLocationsWithUnitsByVariantID: {
    method: 'GET' as Method,
    url: '/location-dom/location/variant-locations/{params.variantId}',
    baseURL: process.env.REACT_APP_API_URL,
  },
};
