import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoContainer, ViewMoreButton } from './CollapsibleContainer.sty';

export interface CollapsibleContainerProps {
  children: React.ReactNode[];
}

export const CollapsibleContainer = ({
  children,
}: CollapsibleContainerProps) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <InfoContainer toggled={showContent}>
        {children[0]}
        <ViewMoreButton
          type="link"
          onClick={() => setShowContent((prev: boolean) => !prev)}
        >
          {t(showContent ? 'viewLess' : 'viewMore')}
          {showContent ? <UpOutlined /> : <DownOutlined />}
        </ViewMoreButton>
      </InfoContainer>
      {showContent && children.slice(1)}
    </>
  );
};
