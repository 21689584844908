import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  VariantStockUpdateAction,
  VariantStockUpdateState,
} from '../reducer/interface';
import {
  INITIAL_VARIANT_STOCK_UPDATE_STATE,
  shippingZoneCreateReducer,
} from '../reducer/stock-update.reducer';

export interface VariantStockUpdateContextTypes {
  state: VariantStockUpdateState;
  dispatch: Dispatch<VariantStockUpdateAction>;
}

const VariantStockUpdateContext = createContext<VariantStockUpdateContextTypes>(
  {} as VariantStockUpdateContextTypes,
);

export const VariantStockUpdateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    shippingZoneCreateReducer,
    INITIAL_VARIANT_STOCK_UPDATE_STATE,
  );

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <VariantStockUpdateContext.Provider value={value}>
      {children}
    </VariantStockUpdateContext.Provider>
  );
};

export const useVariantStockUpdate = () =>
  useContext(VariantStockUpdateContext);
