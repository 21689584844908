import { Rule } from 'antd/lib/form';
import { internetLinkRegex } from 'helpers/constants/regex';
import { stringValidator } from 'helpers/utils/formValidators';
import { t } from 'i18next';
import { BAD_LINK_REGEX_ERROR } from '../constants';

export const BannerImageRules: Rule[] = [
  {
    required: true,
    validator: (_, value) => stringValidator(value, { required: true }, t),
  },
];

export const BannerLinkRules: Rule[] = [
  {
    required: true,
    validator: (_, value) =>
      stringValidator(
        String(value).toLowerCase(),
        { minLength: 10, required: true, regexp: internetLinkRegex },
        t,
        {
          badRegex: BAD_LINK_REGEX_ERROR,
        },
      ),
  },
];
