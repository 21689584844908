import { TFunction } from 'i18next';

const errorMessageArrayOrder: (keyof ErrorMessage)[] = [
  'sku',
  'origin',
  'target',
  'quantity',
  'error',
];

export interface ErrorMessage {
  sku: string;
  origin: string;
  target: string;
  quantity: number;
  error: string;
}

export function getErrorMessage(
  err: string,
  tFunction: TFunction,
): [string, string] {
  const infoArr = err.split('~');

  const obj = infoArr.reduce((accObj, value, index) => {
    const posKey = errorMessageArrayOrder[index];
    return {
      ...accObj,
      [posKey]: value,
    };
  }, {}) as ErrorMessage;

  return [
    tFunction('transfersCsv.error.template', obj),
    infoArr[infoArr.length - 1] ?? '',
  ];
}
