/* eslint-disable no-underscore-dangle */
import { WalletTransaction, WalletViewEnum } from 'services/wallet/interface';

export const validateWalletForm = (
  form: WalletTransaction,
  selectedTransaction: WalletTransaction,
  currentView: WalletViewEnum,
) => {
  if (form.total && form.transactionType) {
    const data: WalletTransaction = {
      ...form,
      total: Number(form.total),
    };
    if (currentView === WalletViewEnum.UPDATE) {
      data.user = selectedTransaction.user;
      data._id = selectedTransaction._id;
      data.userId = selectedTransaction.user.id;
    }

    if (currentView === WalletViewEnum.CREATE && !form.reason) {
      return {
        valid: false,
        reason: false,
        data,
      };
    }

    return {
      valid: true,
      reason: true,
      data,
    };
  }
  return {
    valid: false,
    reason: false,
    data: form,
  };
};
