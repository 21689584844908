import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';

export const ItemParagraph = styled.div`
  margin: ${LightTheme.spaceSizes.s} 0;
`;

export const ItemLabel = styled.span`
  font-weight: bold;
  margin-right: ${LightTheme.spaceSizes.m};
`;

export const ThumbnailImageWrapper = styled.figure<{ width?: string }>`
  width: ${({ width }) => width ?? '40px'};
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  & > img {
    width: 100%;
    object-fit: fill;
  }
`;

export const InfiniteTableLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
