/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line camelcase
import { useMutation } from '@tanstack/react-query';
import config from 'config';
import { ROUTES } from 'helpers/constants/routes';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFirebaseAuth from '../useFirebaseAuth';
import useNotifications from '../useNotifications';
import { moduleNameType, modules } from './interface/useModuleCRUD';

const useModuleCRUD = (
  moduleName: moduleNameType,
  onSuccess?: (
    data: any,
    variables: any,
    context: unknown,
  ) => void | Promise<unknown>,
) => {
  const navigate = useNavigate();
  const { logOut } = useFirebaseAuth();
  const { t, i18n } = useTranslation();
  const { notifyError, notifyWarning } = useNotifications({
    translationFunction: t,
  });
  const module = useMemo(() => modules[moduleName], [moduleName]);

  const handleRequestErrors = useCallback(
    (error: any) => {
      if (error.isAxiosError) {
        const isInvisible =
          config.errorConfig[error?.response?.data?.code || '']?.invisible;
        const isWarning =
          config.errorConfig[error?.response?.data?.code || '']?.isWarning;
        const errorCode =
          config.errorConfig[error?.response?.data?.code || '']?.translation ||
          'httpErrors.general.generic';
        const errorTranslation = i18n.exists(errorCode)
          ? errorCode
          : 'httpErrors.general.generic';
        if (!isInvisible) {
          if (isWarning) {
            notifyWarning(errorTranslation);
          } else {
            notifyError(errorTranslation);
          }
        }
      }
      if (error.isAxiosError && error?.response?.data?.statusCode === 401) {
        logOut();
        navigate(ROUTES.login);
      }
    },
    [i18n, notifyWarning, notifyError, logOut, navigate],
  );

  // MORTAL MUTATIONS

  const createMutation = useMutation((params: any) => module.create(params), {
    onError: handleRequestErrors,
    onSuccess,
  });

  const updateMutation = useMutation((params: any) => module.update(params), {
    onError: handleRequestErrors,
    onSuccess,
  });

  const deleteMutation = useMutation((params: any) => module.delete(params), {
    onError: handleRequestErrors,
    onSuccess,
  });

  // UNNECESSARY QUERIES (SHOULD BE QUERY)

  const readQuery = useMutation((params: any) => module.read(params), {
    onError: handleRequestErrors,
    onSuccess,
  });

  const readByParamQuery = useMutation(
    (params: any) => module.readByParam(params),
    {
      onError: handleRequestErrors,
      onSuccess,
    },
  );

  return {
    createMutation,
    readQuery,
    updateMutation,
    deleteMutation,
    readByParamQuery,
  };
};

export default useModuleCRUD;
