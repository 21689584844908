/* eslint-disable indent */
import { Space } from 'antd';
import { CustomTag } from 'components/CustomTag';
import TableActions from 'components/TableActions/TableActions';
import { CreditStatusColor } from 'containers/CreditLine/interface';
import { LightTheme } from 'helpers/constants/theme';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { numberToCurrency } from 'helpers/utils/currency';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ItemLabel, ItemParagraph } from '../CreditTable.sty';
import { CreditLine } from '../interface';

const tableColumns = (
  attributeActions: (credit: CreditLine) => JSX.Element | null,
  t: TFunction<'translation', undefined>,
) => [
  {
    title: t('creditTableKeys.userId'),
    dataIndex: 'userId',
  },
  {
    title: t('creditTableKeys.userName'),
    dataIndex: 'userName',
  },
  {
    title: t('creditTableKeys.authorizedCredit'),
    dataIndex: 'authorizedCredit',
    render: (authCrd: number) => numberToCurrency(authCrd),
  },
  {
    title: t('creditTableKeys.debt'),
    dataIndex: 'debt',
    render: (debt: number) => numberToCurrency(debt),
  },
  {
    title: t('creditTableKeys.status'),
    dataIndex: 'status',
    render: (status: string) => (
      <CustomTag color={CreditStatusColor[status.toUpperCase()]}>
        {t(`creditStatus.${status}`) || `${status}` || ''}
      </CustomTag>
    ),
  },
  {
    title: t('creditTableKeys.actions'),
    render: (credit: CreditLine) => attributeActions(credit),
  },
];

const hiddenDescription = ({
  credit,
  t,
  isMobile,
  creditActions,
}: {
  credit: CreditLine;
  t: TFunction<'translation', undefined>;
  isMobile: boolean;
  creditActions: (credit: CreditLine) => JSX.Element | null;
}) => (
  <Space direction="vertical">
    <ItemParagraph>
      <ItemLabel>
        {t('creditTableKeys.userId')}
        :&nbsp;
      </ItemLabel>
      {credit.userId}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('creditTableKeys.userName')}
        :&nbsp;
      </ItemLabel>
      {credit.userName}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('creditTableKeys.authorizedCredit')}
        :&nbsp;
      </ItemLabel>
      {numberToCurrency(credit.authorizedCredit)}
    </ItemParagraph>
    <ItemParagraph>
      <ItemLabel>
        {t('creditTableKeys.debt')}
        :&nbsp;
      </ItemLabel>
      {numberToCurrency(credit.debt)}
    </ItemParagraph>
    {isMobile && creditActions(credit)}
  </Space>
);

const useCreditTable = ({
  onUpdateCredit,
}: {
  onUpdateCredit: (credit: CreditLine) => void;
}) => {
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('credit.editActions');

  const creditActions = (credit: CreditLine) => (
    <AccessWrapper>
      <TableActions
        entity={credit}
        onUpdate={(cred: CreditLine) => onUpdateCredit(cred)}
      />
    </AccessWrapper>
  );

  const defaultColumns = tableColumns(creditActions, t);

  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('creditTableKeys.userName'),
      t('creditTableKeys.status'),
    ];
    const filteredColumns = isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
    return filteredColumns;
  }, [defaultColumns, isMobile, t]);

  return {
    columns,
    hiddenDescription: (credit: CreditLine) =>
      hiddenDescription({
        credit,
        t,
        isMobile,
        creditActions,
      }),
  };
};

export default useCreditTable;
