import Crown from 'assets/images/crown.svg';
import { CustomTag } from 'components/CustomTag';
import { LightTheme } from 'helpers/constants/theme';
import { t } from 'i18next';
import { useMemo } from 'react';
import {
  Order,
  OrderType,
  PandasPaymentMethod,
  PaymentConcepts,
  PaymentMethodStatus,
  ShippingLogistic,
  StatusOrderEnum,
  StatusTagColor,
} from 'services/order/interface';
import { StatusTagImage, TagImage } from '../OrderCard/OrderCard.sty';

const OrderTags = ({
  order,
  type,
}: {
  order: Order;
  type: 'header' | 'footer' | 'all';
}) => {
  const { header, footer } = useMemo(() => {
    if (type === 'all') return { header: true, footer: true };
    if (type === 'header') return { header: true, footer: false };
    return { header: false, footer: true };
  }, [type]);

  const shippingPayment = useMemo(
    () =>
      order.paymentData?.find((p) => p.concept === PaymentConcepts.SHIPPING),
    [order],
  );

  return (
    <>
      {header && (
        <>
          {order.isDropShipper && (
            <CustomTag color={StatusTagColor.DROPSHIPPING}>
              {t('orderStatus.dropshipping')}
            </CustomTag>
          )}
          {order.isFirstBuy && (
            <CustomTag color={StatusTagColor.FIRST_ORDER}>
              {t(`orderStatus.newClient`)}
            </CustomTag>
          )}
          {order?.user.isPrime && (
            <StatusTagImage color={StatusTagColor.PRIME}>
              <TagImage src={Crown} />
              {t(`orderStatus.PRIME`)}
            </StatusTagImage>
          )}
          {order?.reportMissing && (
            <StatusTagImage color={StatusTagColor.MISSING}>
              {t(`orderStatus.MISSING`)}
            </StatusTagImage>
          )}
          {!order.legalized && order.status === StatusOrderEnum.DELIVERED ? (
            <CustomTag color={StatusTagColor.LEGALIZED}>
              {t('orderStatus.NOT_LEGALIZED')}
            </CustomTag>
          ) : null}
        </>
      )}
      {footer && (
        <>
          {order.orderType === OrderType['3P'] && (
            <CustomTag color={StatusTagColor['3P']}>
              {t('orderForm.tags.3p').toUpperCase()}
            </CustomTag>
          )}
          {shippingPayment?.status === PaymentMethodStatus.PENDING && (
            <CustomTag color={StatusTagColor.PENDING_SHIPPING_PAYMENT}>
              {t('orderForm.tags.pendingPaymentShipping')}
            </CustomTag>
          )}
          {shippingPayment?.status === PaymentMethodStatus.REJECTED && (
            <CustomTag color={StatusTagColor.REJECTED_SHIPPING_PAYMENT}>
              {t('orderForm.tags.rejectedPaymentShipping')}
            </CustomTag>
          )}
          {order.isWarranty && (
            <CustomTag color="gold">
              {t('orderForm.tags.isWarranty').toUpperCase()}
            </CustomTag>
          )}
          {(order.payment?.credit?.amount ?? 0) > 0 && (
            <CustomTag color={StatusTagColor.CREDIT}>
              {t(`orderStatus.credit`)}
            </CustomTag>
          )}
          {(order.payment?.online?.amount ?? 0) > 0 && (
            <CustomTag color={StatusTagColor.ONLINE}>
              {t(`orderStatus.online`)}
            </CustomTag>
          )}
          {order?.paymentMethod === PandasPaymentMethod.BANK_TRANSFER && (
            <>
              <CustomTag color={StatusTagColor.BANK_DEPOSIT}>
                {t(`orderStatus.bankDeposit`)}
              </CustomTag>
              {order?.bankTransferPaid ? (
                <CustomTag color={LightTheme.color.info}>
                  {t('orderForm.tags.paid')}
                </CustomTag>
              ) : (
                <CustomTag color={LightTheme.color.danger}>
                  {t('orderForm.tags.notPaid')}
                </CustomTag>
              )}
            </>
          )}
          {order?.shippingLogistic === ShippingLogistic.PANDAS &&
            order.orderType === OrderType['3P'] && (
              <CustomTag color={StatusTagColor.BANK_DEPOSIT}>
                {t(`orderStatus.pandasLogistic`)}
              </CustomTag>
            )}
        </>
      )}
    </>
  );
};

export default OrderTags;
