export enum WalletViewEnum {
  UPDATE = 'update',
  CREATE = 'create',
  LIST = 'list',
}

export interface WalletState {
  walletTransactionList: WalletTransaction[];
  currentView: WalletViewEnum;
  currentTransaction: WalletTransaction;
  search: string;
  user: string;
  status: string;
}

export type WalletAction = {
  type: WalletActionEnum;
  payload: any;
};

export enum WalletActionEnum {
  SET_SEARCH = 'SET_SEARCH',
  SET_STATUS_FILTER = 'SET_STATUS_FILTER',
  SET_USER_FILTER = 'SET_USER_FILTER',
  SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION',
  SET_TRANSACTION_LIST = 'SET_TRANSACTION_LIST',
  SET_USER = 'SET_USER',
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
}

export interface TransactionUser {
  id: string;
  name: string;
  documentType: string;
  documentNumber: string;
  email: string;
  phoneNumber: string;
  bank: string;
  bankAccount: string;
  accountType: string;
}

export interface TransactionSummary {
  available: number;
  pending: number;
  total: number;
  withdrawn: number;
}

export interface WalletTransaction {
  _id: string;
  user: TransactionUser;
  orderNumbers: string;
  saleOrderId: string;
  total: number;
  userId?: string;
  summary: TransactionSummary;
  transactionType: TransactionTypeEnum;
  status: string;
  details?: string;
  createdAt: string;
  reason?: string;
  updatedAt: string;
}

export enum TransactionTypeEnum {
  GAIN = 'GAIN',
  WITHDRAW = 'WITHDRAW',
}

export const transactionTypes = Object.keys(TransactionTypeEnum);

export enum TransactionStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export const DEFAULT_SUMMARY = {
  available: 0,
  pending: 0,
  total: 0,
  withdrawn: 0,
};

export const initialWalletState: WalletState = {
  walletTransactionList: [],
  currentView: WalletViewEnum.LIST,
  search: '',
  user: '',
  status: 'PENDING',
  currentTransaction: {
    _id: '',
    user: {
      id: '',
      name: '',
      documentType: '',
      documentNumber: '',
      email: '',
      phoneNumber: '',
      bank: '',
      bankAccount: '',
      accountType: '',
    },
    orderNumbers: '',
    saleOrderId: '',
    total: 0,
    summary: {
      available: 0,
      pending: 0,
      total: 0,
      withdrawn: 0,
    },
    transactionType: TransactionTypeEnum.WITHDRAW,
    status: '',
    details: '',
    createdAt: '',
    updatedAt: '',
  },
};
