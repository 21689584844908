import { LightTheme } from 'helpers/constants/theme';
import { Location as wmsLocation } from 'services/location/interface';
import { BillingType } from 'services/product/interface';
import { Location } from 'services/user/interface';

export enum LogisticType {
  '3P' = '3P',
  'P2P' = 'P2P',
  'ON-DEMAND' = 'ON-DEMAND',
  'TODAY' = 'TODAY',
}

export enum ProductLogistic {
  CROSS_DOCKING = 'CROSS_DOCKING',
  '3P' = '3P',
}

export const LogisticTypeColor: { [key: string]: string } = {
  [LogisticType['ON-DEMAND']]: LightTheme.color.primary,
  [LogisticType.TODAY]: LightTheme.color.kiwi,
  [LogisticType.P2P]: LightTheme.color.soft_danger,
  [LogisticType['3P']]: LightTheme.color.light_yellow,
};

export interface Rut {
  name: string;
  uid: string;
  url?: string;
}

export enum TaxRegime {
  GREATCONTRIBUTOR = 'GREATCONTRIBUTOR',
  COMUN = 'COMUN',
  SIMPLE = 'SIMPLE',
}

export interface PublicContactData {
  phone: string;
}

export interface MPS {
  id?: string;
  _id: string;
  name: string;
  contactName: string;
  address: string;
  document: string;
  email: string;
  phoneNumber: string;
  role?: string[];
  delay?: number;
  updatedAt?: string;
  logisticType: LogisticType;
  billingType?: BillingType;
  mandatedBilling?: boolean;
  department?: string;
  departmentCode?: string;
  city?: string;
  cityCode?: string;
  documentType?: DocumentType;
  isTaxWithholding?: boolean;
  taxRegime?: TaxRegime;
  commission?: number;
  takeRate?: number;
  additionalInfo?: Record<string, any>;
  isSupplier?: boolean;
  createdAt?: string;
  rut?: Rut;
  isMarketplace?: boolean;
  wms?: {
    mainLocation?: wmsLocation;
    mainWarehouse?: wmsLocation;
  };
  publicContact?: PublicContactData;
}

export enum SupplierStatus {
  ACTIVE = 'ACTIVE',
  DEPRECATED = 'DEPRECATED',
}

export enum SupplierStatusColorCode {
  ACTIVE = 'green',
  DEPRECATED = 'red',
}

export interface SupplierForm {
  _id: string;
  name: string;
  contactName: string;
  document: string;
  email: string;
  phoneNumber: string;
  location: Location[];
  delay: number;
  logisticType: LogisticType;
  billingType: BillingType;
  mandatedBilling?: boolean;
  commission?: number;
  isSupplier?: boolean;
  rut?: Rut[];
  publicContact?: {
    phone: string;
  };
}
