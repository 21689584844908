/* eslint-disable no-confusing-arrow */
import { Variant } from 'services/product/interface';
import {
  PurchaseVariant,
  PurchaseVariantInfo,
} from 'services/purchase/interface';

const variantMatchesString = (
  variant: Variant | PurchaseVariantInfo | undefined,
  search: string,
): boolean => {
  const compareString =
    `${variant?.name}${variant?.variantSku}${variant?.urlName}`.toLowerCase();
  return compareString.includes(search.toLowerCase());
};

export const directVariantSearchFilter = (
  variantList: Variant[],
  search: string,
) =>
  search.length <= 0 || variantList.length === 0
    ? [...variantList]
    : variantList.filter((item) => variantMatchesString(item, search));

export const directPurchaseVariantSearchFilter = (
  variantList: PurchaseVariant[],
  search: string,
) =>
  search.length <= 0 || variantList.length === 0
    ? [...variantList]
    : variantList.filter((item) => variantMatchesString(item.variant, search));
