import { useForm } from 'antd/lib/form/Form';
import useNotifications from 'helpers/hooks/useNotifications';
import { filter, isNotNil, mergeAll } from 'ramda';
import { useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { IBanner, IBannerForm } from 'services/parameters/interface';
import { CreateBannerFormInitialValues } from '../constants';
import useBannersApi from '../hooks/useBannersApi';
import {
  BannerModalTypeEnum,
  BannersEventType,
  BannersStateInitialValues,
  IBannerContext,
} from '../interface';
import { bannersReducer } from '../reducers';
import {
  BannerToFormBanner,
  FilterBannerByPropsPredicate,
  FormBannerToBanner,
} from '../utils';

function useBannerContext(): IBannerContext {
  const [state, dispatch] = useReducer(
    bannersReducer,
    BannersStateInitialValues,
  );
  const { banners, draftBanners, modalType, modalVisible, selectedBanner } =
    state;

  // ==================== HOOKS ====================
  const { isLoading, refetch, updateBannersAsync, updateLoading } =
    useBannersApi({ dispatch, state });

  const { t } = useTranslation();
  const [form] = useForm<IBannerForm>();
  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });

  const turnModal = useCallback(
    (type?: BannerModalTypeEnum) => {
      dispatch({
        type: BannersEventType.SET_MODAL_VISIBLE,
        payload: !modalVisible,
      });

      if (isNotNil(type)) {
        dispatch({
          type: BannersEventType.SET_MODAL_TYPE,
          payload: type,
        });
      }
    },
    [modalVisible],
  );

  const closeModal = useCallback(() => {
    turnModal();
  }, [turnModal]);

  const openCreateModal = useCallback(() => {
    turnModal(BannerModalTypeEnum.create);
    form.setFieldsValue(CreateBannerFormInitialValues);
  }, [turnModal, form]);

  const openEditModal = useCallback(
    (banner: IBanner) => {
      turnModal(BannerModalTypeEnum.update);
      dispatch({
        payload: banner,
        type: BannersEventType.SET_SELECTED_BANNER,
      });
      form.setFieldsValue(BannerToFormBanner(banner));
    },
    [turnModal, form],
  );

  const openOrderModal = useCallback(
    (banner: IBanner) => {
      turnModal(BannerModalTypeEnum.order);
      dispatch({
        payload: banner,
        type: BannersEventType.SET_SELECTED_BANNER,
      });
    },
    [turnModal],
  );

  // ================== CALLBACKS ==================
  const updateBannersAction = useCallback(
    async (newBanners: IBanner[], resetModal = true) => {
      try {
        await updateBannersAsync(newBanners);
        notifySuccess();
      } catch (err) {
        notifyError();
      } finally {
        await refetch();
        if (resetModal) turnModal();
        form.setFieldsValue(CreateBannerFormInitialValues);
      }
    },
    [form, notifyError, notifySuccess, refetch, updateBannersAsync, turnModal],
  );

  const onBannersPositionUpdate = useCallback(() => {
    updateBannersAction(draftBanners);
  }, [draftBanners, updateBannersAction]);

  const onBannerDelete = useCallback(
    async (banner: IBanner): Promise<void> => {
      const pred = FilterBannerByPropsPredicate(banner, true);
      const newBanners = filter(pred, banners);
      updateBannersAction(newBanners, false);
    },
    [banners, updateBannersAction],
  );

  const onBannerCreate = useCallback(
    async (banner: IBannerForm): Promise<void> => {
      const newBanners = [...banners, FormBannerToBanner(banner)];
      updateBannersAction(newBanners);
    },
    [banners, updateBannersAction],
  );

  const onBannerEdit = useCallback(
    async (banner: IBannerForm): Promise<void> => {
      if (!selectedBanner) return;
      const newBanner = mergeAll([selectedBanner, FormBannerToBanner(banner)]);
      newBanner.id = selectedBanner.id;
      const newBanners = banners.map((b) =>
        b.id === newBanner.id ? newBanner : b,
      );

      updateBannersAction(newBanners);
    },
    [selectedBanner, banners, updateBannersAction],
  );

  const formAction = useCallback(
    (banner: IBannerForm) => {
      if (modalType === BannerModalTypeEnum.update) {
        return onBannerEdit(banner);
      }
      return onBannerCreate(banner);
    },
    [modalType, onBannerCreate, onBannerEdit],
  );

  const setDraftBanners = useCallback(
    (newDraftBannners: IBanner[]) => {
      dispatch({
        type: BannersEventType.SET_DRAFT_BANNERS,
        payload: newDraftBannners,
      });
    },
    [dispatch],
  );

  // ================== MEMOS ==================
  const bannerFormInitialValues = useMemo(() => {
    if (modalType === BannerModalTypeEnum.update && !!selectedBanner) {
      return BannerToFormBanner(selectedBanner);
    }
    return CreateBannerFormInitialValues;
  }, [modalType, selectedBanner]);

  const onModalOk = useMemo(() => {
    if (modalType === BannerModalTypeEnum.order) {
      return onBannersPositionUpdate;
    }
    return form.submit;
  }, [modalType, form, onBannersPositionUpdate]);

  return {
    form,
    banners,
    isLoading,
    updateLoading,
    modalVisible,
    selectedBanner,
    bannerFormInitialValues,
    modalType,
    draftBanners,
    setDraftBanners,
    onBannerDelete,
    onModalOk,
    formAction,
    closeModal,
    openCreateModal,
    openOrderModal,
    openEditModal,
  };
}

export default useBannerContext;
