import { Col, Modal, Select } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import styled from 'styled-components';
import { ActionModalEnum } from '.';

export const CustomLabel = styled.h2<{ color: string }>`
  color: ${({ color }) =>
    color === ActionModalEnum.RESCHEDULE
      ? LightTheme.color.light_yellow
      : LightTheme.color.error_red};
  text-align: left;
  padding: 0px 20px;
  font-weight: bolder;
  width: 86%;
  font-size: 14px;
`;

export const ModalWrapper = styled(Modal)<{
  color: string;
  disableButton: boolean;
}>`
  .button-modal {
    background: ${({ disableButton }) =>
      disableButton ? LightTheme.color.gray_light : ''} !important;
    background: ${({ color, disableButton }) =>
      !disableButton && color === ActionModalEnum.RESCHEDULE
        ? LightTheme.color.light_yellow
        : LightTheme.color.danger};
    border: none;
    width: ${LightTheme.spaceSizes.xx};
    color: white;
  }
`;

export const CustomSelectLarge = styled(Select)`
  min-width: 100%;
`;
export const DefaultCol = styled(Col)`
  width: 16%;
`;
export const DefaultBigCol = styled(Col)`
  width: 80%;
`;
