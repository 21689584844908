import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { BaseColors } from 'helpers/constants/theme';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Behavior, WmsSetting } from 'services/wms-settings/interface';
import RuleResume from '../helpers/RuleResume';
import { BehaviorStyledCard } from './SettingUpdate.sty';

const BehaviorCard = ({
  behavior,
  setting,
  idx,
  onDeleteBehavior,
  onEditBehavior,
  disabled,
}: {
  behavior: Behavior<any>;
  setting: WmsSetting;
  idx: number;
  onDeleteBehavior: (idx: number) => void;
  onEditBehavior: (idx: number) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    onDeleteBehavior(idx);
  }, [onDeleteBehavior, idx]);

  const handleEdit = useCallback(() => {
    onEditBehavior(idx);
  }, [onEditBehavior, idx]);

  const actions = useMemo(() => {
    if (disabled) {
      return undefined;
    }

    return [
      <DeleteOutlined key="delete-rule" onClick={handleDelete} />,
      <EditOutlined key="edit-rule" onClick={handleEdit} />,
    ];
  }, [disabled, handleDelete, handleEdit]);

  return (
    <BehaviorStyledCard actions={actions}>
      {behavior.ruleSet.map((rule) => (
        <p key={rule.field}>
          {t(
            `wms-settings.setting.${setting.setting}.rules.resume.${rule.name}`,
          )}
          <br />
          <RuleResume rule={rule} setting={setting} />
        </p>
      ))}
      <p>{t(`wms-settings.setting.${setting.setting}.rules.resume.value`)}</p>
      <Tag color={BaseColors.happy_emerald}>{behavior.label}</Tag>
    </BehaviorStyledCard>
  );
};

export default BehaviorCard;
