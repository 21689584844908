import { transferUnitsAtom } from 'helpers/recoil/atoms/transfers';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Location } from 'services/location/interface';
import { ITransferUnits } from 'services/transfers/interface';
import { changeSubObjectProperty } from '../utils';

const useTransfersItem = ({ item }: { item: ITransferUnits }) => {
  const [transferItems, setTransferItems] = useRecoilState(transferUnitsAtom);

  // ===================================== memos =====================================
  const itemPath = useMemo(() => item.path ?? '', [item]);

  // ================================== callbacks ====================================
  const onDelete = useCallback(() => {
    const cloned = cloneDeep(transferItems);

    if (cloned[itemPath]) {
      delete cloned[itemPath];
      setTransferItems({
        ...cloned,
      });
    }
  }, [setTransferItems, transferItems, itemPath]);

  const onChangeQuantity = useCallback(
    (newQuantity: unknown) => {
      setTransferItems(
        changeSubObjectProperty(
          transferItems,
          itemPath,
          'quantity',
          newQuantity,
        ),
      );
    },
    [itemPath, setTransferItems, transferItems],
  );

  const onChangeLocation = useCallback(
    (newLocation?: Location) => {
      setTransferItems(
        changeSubObjectProperty(
          transferItems,
          itemPath,
          'finalLocation',
          newLocation,
        ),
      );
    },
    [itemPath, setTransferItems, transferItems],
  );

  return {
    onDelete,
    onChangeLocation,
    onChangeQuantity,
  };
};

export default useTransfersItem;
