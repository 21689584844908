/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { ColumnsType } from 'antd/lib/table';
import { InfiniteScrollTable } from 'containers/General/InfiniteScrollTable/InfiniteScrollTable';
import { RefObject } from 'react';

const TABLE_HEADER_HEIGHT = 160;

export const MPSUserTable = ({
  data,
  isLoading,
  columns,
  onScrollToEnd,
  reachedEndOfData = false,
  tableRef,
}: {
  data: any[];
  isLoading: boolean;
  columns: ColumnsType<any>;
  onScrollToEnd: (params?: any) => void;
  reachedEndOfData?: boolean;
  tableRef: RefObject<{ scrollToTop: () => void }>;
}) => {
  return (
    <InfiniteScrollTable
      ref={tableRef}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      id="mps-user-table"
      isLoading={isLoading}
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={data || []}
      reachedEndOfData={reachedEndOfData}
      onScrollToEnd={onScrollToEnd}
    />
  );
};
