import PurchaseListContainer from 'modules/Purchase/containers/PurchaseListContainer';
import { PurchaseProvider } from 'modules/Purchase/context/Purchase.context';

export const PurchaseListPage = () => {
  return (
    <PurchaseProvider>
      <PurchaseListContainer />
    </PurchaseProvider>
  );
};
