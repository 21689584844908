import { Input } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

export const CustomSearch = styled(Search)<{ icon: boolean }>`
  & .ant-input-group-addon {
    ${({ icon }) => !icon && 'display: none'}
  }

  #order-mps-filter {
    border: 1px solid red;
  }
`;
