import { Col, Row, Select } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { Title } from 'pages/App/Layout.sty';
import styled from 'styled-components';

export const TitleRowDefault = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl};
`;

export const TitleColumnDefault = styled(Col)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const DefaultTitleText = styled(Title)`
  margin: 0 !important;
`;

export const ListWrapper = styled.div`
  display: flex;
  text-align: center;
  gap: 10px;
`;

export const MediumSelect = styled(Select)`
  min-width: 160;
  width: fit-content;
`;
