import { Col, DatePicker, Input, Row, Select } from 'antd';
import { LightTheme, spaceSizes } from 'helpers/constants/theme';
import { Title } from 'pages/App/Layout.sty';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

const { Search } = Input;
const { RangePicker } = DatePicker;

export const OrderContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const TitleRow = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl};
`;

export const TitleColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

export const OrderTitle = styled(Title)`
  margin: 0 !important;
`;

export const SearchInput = styled(Search)`
  max-width: 400px;
`;

export const FiltersAndButtonContainer = styled(Row)`
  margin-bottom: ${LightTheme.spaceSizes.xl3};
  color: ${LightTheme.color.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: calc(100%-200px);

  .ant-select {
    .ant-select-selector {
      width: 225px;
      display: flex;
      align-items: center;
      height: 35px;
      border-radius: 8px;
      .ant-select-selection-search {
        input {
          color: ${LightTheme.color.link} !important;
          font-weight: bold !important;
        }
        input::placeholder {
          color: ${LightTheme.color.link} !important;
          font-weight: bold !important;
        }
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: ${LightTheme.color.link} !important;
        font-weight: bold !important;
      }
    }
  }
`;

export const SelectOverflowH = styled(Select)`
  display: flex;
  flex-direction: row;
  overflow: auto;
  & .ant-select-selector {
    overflow: auto;
    padding-right: ${spaceSizes.xl};
  }
  & .ant-select-selection-overflow {
    flex-wrap: initial;
    overflow: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 200px;
`;

export const FilterCol = styled(ScrollContainer)`
  width: fit-content;
  overflow: scroll;
  display: flex;
  color: white;
  padding: 0;
  flex-direction: row;
  gap: ${LightTheme.spaceSizes.m};

  & .ant-select-auto-complete .ant-select-selector {
    width: 100%;
    min-width: 250px;
  }
`;

export const CustomRangePicker = styled(RangePicker)`
  min-width: 245px;
  height: 35px;
  border-radius: 8px;

  .ant-picker-input {
    .ant-picker-suffix {
      color: ${LightTheme.color.link} !important;
    }
    input {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
    input::placeholder {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
  }
`;
export const CustomDatePicker = styled(DatePicker)`
  min-width: 245px;
  height: 35px;
  border-radius: 8px;

  .ant-picker-input {
    .ant-picker-suffix {
      color: ${LightTheme.color.link} !important;
    }
    input {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
    input::placeholder {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
  }
`;

export const CustomSelect = styled(Select)`
  min-width: 245px;
  height: 35px;
  border-radius: 8px;

  .ant-picker-input {
    .ant-picker-suffix {
      color: ${LightTheme.color.link} !important;
    }
    input {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
    input::placeholder {
      color: ${LightTheme.color.link} !important;
      font-weight: bold !important;
    }
  }
`;
