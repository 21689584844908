/* eslint-disable react/require-default-props */
import { Select, Space } from 'antd';
import dateFormat from 'helpers/utils/dateFormat';
import { t } from 'i18next';
import {
  Order,
  StatusOrderEnum,
  StatusTagColor,
} from 'services/order/interface';
import CancelOrderModal from '../CancelOrderModal';
import ModalOrderTable from './ModalOrderTable';
import {
  DeliveryDateLabel,
  DeliveryDateWrapper,
  FooterRow,
  OrderCountTag,
  OrderFormModal,
  StatusContainer,
  StatusFlag,
  StatusItemContainer,
  SubmitButton,
  TitleSpan,
  TitleTextWrapper,
  TitleWrapper,
} from './MultiOrderStatusChangeModal.sty';

const { Option } = Select;

export interface MultiOrderStatusChangeProps {
  title?: string;
  orders: Order[];
  open: boolean;
  cancelModalVisible: boolean;
  loadingMassiveUpdate: boolean;
  currentStatus: StatusOrderEnum;
  newStatus: StatusOrderEnum;
  cancelReason: string;
  newStatusOptions: string[];
  reasonOptions: string[];
  onOkCancel: () => void;
  confirmMassiveUpdate: () => void;
  closeMultiStatusChange: () => void;
  onCancelCancel: () => void;
  onChangeNewStatus: (status: StatusOrderEnum) => void;
  setCancelReason: (reason: string) => void;
}

const MultiOrderStatusChangeModal = ({
  title,
  orders,
  open,
  cancelModalVisible,
  loadingMassiveUpdate,
  currentStatus,
  newStatus,
  cancelReason,
  newStatusOptions,
  reasonOptions,
  onOkCancel,
  confirmMassiveUpdate,
  closeMultiStatusChange,
  onCancelCancel,
  onChangeNewStatus,
  setCancelReason,
}: MultiOrderStatusChangeProps) => {
  return (
    <OrderFormModal
      open={open}
      width="95%"
      title={
        <TitleWrapper>
          <TitleTextWrapper>
            <TitleSpan>{t(title ?? '')}</TitleSpan>
            {orders.length > 1 && (
              <OrderCountTag>
                {t('orders', {
                  orderCount: orders.length ?? 0,
                })}
              </OrderCountTag>
            )}
          </TitleTextWrapper>
          <DeliveryDateWrapper>
            <DeliveryDateLabel>{t('deliveryDate')}:</DeliveryDateLabel>
            {orders.length > 0
              ? dateFormat(orders[0].delivery?.date ?? '')
              : ''}
          </DeliveryDateWrapper>
        </TitleWrapper>
      }
      footer={
        <FooterRow>
          <SubmitButton
            onClick={confirmMassiveUpdate}
            loading={loadingMassiveUpdate}
          >
            {t('confirm')}
          </SubmitButton>
        </FooterRow>
      }
      onCancel={closeMultiStatusChange}
    >
      <Space direction="horizontal">
        <CancelOrderModal
          onOkCancel={onOkCancel}
          orderNumber={orders.map((o) => o.orderNumber).join(',')}
          reasonOptions={reasonOptions}
          onCancelCancel={onCancelCancel}
          setCancelReason={setCancelReason}
          open={cancelModalVisible}
          loading={loadingMassiveUpdate}
          cancelReason={cancelReason}
        />
        <StatusItemContainer>
          <span>{t('currentStatus')}</span>
          <StatusContainer>
            <StatusFlag color={StatusTagColor[currentStatus]} />
            {t(`orderStatus.${currentStatus}`) ?? currentStatus ?? ''}
          </StatusContainer>
        </StatusItemContainer>
        <StatusItemContainer>
          <span>{t('newStatus')}</span>
          <Select
            value={newStatus}
            onChange={onChangeNewStatus}
            disabled={loadingMassiveUpdate}
          >
            {newStatusOptions.map((status: string) => (
              <Option value={status} key={status}>
                <Space direction="horizontal">
                  <StatusFlag
                    color={StatusTagColor[status as StatusOrderEnum]}
                  />
                  {t(`orderStatus.${status}`) ?? status ?? ''}
                </Space>
              </Option>
            ))}
          </Select>
        </StatusItemContainer>
      </Space>
      <ModalOrderTable orders={orders} />
    </OrderFormModal>
  );
};

export default MultiOrderStatusChangeModal;
