/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useMutation } from '@tanstack/react-query';
import useNotifications from 'helpers/hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { MPSUserFormValues, MPSUSERRequest } from 'services/MPSUser/interface';
import { updateMpsUserRequest } from 'services/MPSUser/mps-user-request.service';

const useMPSUserForm = (
  initialValues: MPSUserFormValues,
  closeModal?: () => void,
  reLoadOnSuccess?: () => void,
) => {
  const { mutateAsync } = useMutation({
    mutationFn: updateMpsUserRequest,
  });

  const { t } = useTranslation();

  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });

  const submitForm = async (values: MPSUSERRequest) => {
    const data = {
      ...values,
      delay: Number(values.delay),
      takeRate: Number(values.takeRate || 0),
      commission: Number(values.commission ?? 0),
    };

    try {
      data._id = initialValues._id;
      await mutateAsync(data);
      notifySuccess(t('MPSUserForm.notifications.updateSuccess'));
      closeModal?.();
      reLoadOnSuccess?.();
    } catch (error) {
      notifyError(t('MPSUserForm.notifications.updateError'));
      console.error(error);
    }
  };

  return {
    submitForm,
  };
};

export default useMPSUserForm;
