/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ROUTES } from 'helpers/constants/routes';
import useNotifications from 'helpers/hooks/useNotifications';
import { t } from 'i18next';
import { debounce } from 'lodash';
import CancelOrderModal from 'modules/Order/components/Modals/CancelOrderModal';
import MultiLegalizeModal from 'modules/Order/components/Modals/MultiLegalizeModal';
import MultiOrderStatusChangeModal from 'modules/Order/components/Modals/MultiOrderStatusChangeModal';
import OrderBoard from 'modules/Order/components/OrderCard';
import OrderConfirmationModal from 'modules/Order/components/OrderConfirmationModal';
import { OrderContent } from 'modules/Order/components/OrderContent';
import { OrderTable } from 'modules/Order/components/OrderTable';
import { useOrder } from 'modules/Order/context/Order.context';
import { openHtmlContent } from 'modules/Order/helpers/html';
import useMultiLegalize from 'modules/Order/hooks/useMultiLegalize';
import useMultiStatusChange from 'modules/Order/hooks/useMultiStatusChange';
import { useOrderStatus } from 'modules/Order/hooks/useOrderStatus';
import useOrderStatusChange from 'modules/Order/hooks/useOrderStatusChange';
import useReadOrder from 'modules/Order/hooks/useReadOrder';
import { ORDER_ACTION_ENUM } from 'modules/Order/reducer/interface';
import { CUSTOM_MESSAGE } from 'modules/Purchase/constants';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MPS } from 'services/MPS/interface';
import {
  Order,
  StatusOrderEnum,
  StatusOrderName,
  StatusTagColor,
} from 'services/order/interface';
import {
  getCollectionRecords,
  getShippingGuides,
} from 'services/package/package.service';

const { confirm } = Modal;

const OrderListContainer = () => {
  const navigate = useNavigate();
  const { state: previousFilters } = useLocation();
  const { state, dispatch } = useOrder();
  const {
    ordersList,
    nextListOrdersPage,
    ordersListLoading,
    // ordersListError,
    ordersBoard,
    ordersBoardMeta,
    ordersBoardNext,
    ordersBoardLoading,
    // ordersBoardError,
    departments,
    mpsUsers,
    refetchOrders,
  } = useReadOrder({
    filters: state.filters,
  });

  const { notifySuccess, notifyError, notifyWarning } = useNotifications({
    customMessage: CUSTOM_MESSAGE,
    translationFunction: t,
  });
  const { openLegalizeModal, multiLegalizeDisabled, ...multiLegalizeProps } =
    useMultiLegalize({
      state,
      dispatch,
      notifyError,
      notifySuccess,
    });
  const {
    handleOpenMultiStatusChange,
    disabledMultiOrderStatusAction,
    ...multiStatusChangeProps
  } = useMultiStatusChange({
    state,
    dispatch,
    notifyError,
    notifySuccess,
  });
  const { changeStatus, loadingStatusUpdate, reasonOptions } = useOrderStatus({
    editForStock: false,
  });
  const {
    handleDragCard,
    handleCommentChange,
    setCancelReason,
    setReportMissing,
    closeStatusChangeModal,
    handleStatusChange,
  } = useOrderStatusChange({
    state,
    dispatch,
    changeStatus,
    notifyWarning,
    notifyError,
    refetchOrders,
  });

  const tableRef = useRef<{
    scrollToTop: () => void;
  }>(null);

  // -------- Callbacks ---------- //
  const onViewDetail = useCallback(
    (order: Order) => {
      navigate(`${ROUTES.order}/${order.id}`, { state: state.filters });
    },
    [navigate, state.filters],
  );

  const handleSearch = debounce((value: string) => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_FILTERS,
      payload: { search: value },
    });
  }, 700);

  const onChangeSearchValue = (value: string) => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_SEARCH_FILTER,
      payload: value,
    });

    handleSearch(value);
  };

  const boardData = useMemo(
    () =>
      Object.keys(StatusOrderEnum).map((status) => ({
        id: status,
        name: StatusOrderName[status],
        items: ordersBoard[status as StatusOrderEnum],
        infiniteScrollData: {
          hasNext: ordersBoardMeta[status as StatusOrderEnum]?.hasNext ?? false,
          page: ordersBoardMeta[status as StatusOrderEnum]?.page ?? 1,
        },
        color: StatusTagColor[status],
      })),
    [ordersBoard, ordersBoardMeta],
  );

  const handleChangeDeliveryDateFilter = useCallback(
    (_, dates) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { startDeliveryDate: dates[0], endDeliveryDate: dates[1] },
      });
    },
    [dispatch],
  );
  const handleChangeCreationDateFilter = useCallback(
    (_, dates) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { startCreationDate: dates[0], endCreationDate: dates[1] },
      });
    },
    [dispatch],
  );
  const handleStatusFilterChange = useCallback(
    (status) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { status },
      });
    },
    [dispatch],
  );
  const handleDepartmentFilterChange = useCallback(
    (department: string[]) => {
      const newDepartments = department.length
        ? department.join(',')
        : undefined;
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { department: newDepartments },
      });
    },
    [dispatch],
  );
  const handleMpsFilterChange = useCallback(
    (mps: MPS[]) => {
      const newMps = mps.length ? mps.join(',') : undefined;
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { mpsId: newMps },
      });
    },
    [dispatch],
  );
  const onUnselectAllClick = useCallback(() => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_FILTERS,
      payload: { mpsId: undefined },
    });
  }, [dispatch]);

  const onViewMultipleRemission = () => {
    const ids = state.selectedOrders.map((order) => order.id).join(',');
    window.open(
      `${process.env.REACT_APP_API_URL}/order-dom/orders/${ids}/bulk-remission`,
    );
  };

  const onChangeRowSelection = (
    currentSelectedRowKeys: React.Key[],
    selectedRows: Order[],
  ) => {
    dispatch({
      type: ORDER_ACTION_ENUM.SET_SELECTED_ROWKEYS,
      payload: currentSelectedRowKeys,
    });
    dispatch({
      type: ORDER_ACTION_ENUM.SET_SELECTED_ORDERS,
      payload: selectedRows,
    });
  };

  useEffect(() => {
    if (previousFilters) {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: previousFilters,
      });
      dispatch({
        type: ORDER_ACTION_ENUM.SET_SEARCH_FILTER,
        payload: (previousFilters as any).search,
      });
    }
  }, [previousFilters, dispatch]);

  const handle3PFilter = useCallback(
    (value: string) => {
      dispatch({
        type: ORDER_ACTION_ENUM.SET_FILTERS,
        payload: { orderType: value },
      });
    },
    [dispatch],
  );

  const generateCollRecords = useCallback(async () => {
    const ordersIds = state.selectedOrders?.map((o) => o.id) ?? [];
    const sellersIds = state.filters?.mpsId?.split(',') ?? [];

    const res = await getCollectionRecords(ordersIds, sellersIds);

    if (!res) {
      notifyError(t('order.collectionRecord.emptyOrders'));
      return;
    }

    openHtmlContent(res);
  }, [state.selectedOrders, state.filters.mpsId, notifyError]);

  const generateShippingGuides = useCallback(async () => {
    const ordersIds = state.selectedOrders?.map((o) => o.id) ?? [];
    const sellersIds = state.filters?.mpsId?.split(',') ?? [];
    let res = '';

    try {
      res = await getShippingGuides(ordersIds, sellersIds);
    } catch (e) {
      notifyError(t('order.shippingGuide.error'));
      return;
    }

    if (!res) {
      notifyError(t('order.shippingGuide.emptyGuides'));
      return;
    }

    openHtmlContent(res);
  }, [state.selectedOrders, state.filters.mpsId, notifyError]);

  const onGenerateCollectionRecord = useCallback(async () => {
    confirm({
      title: t('order.collectionRecord.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('order.collectionRecord.content'),
      onOk: generateCollRecords,
    });
  }, [generateCollRecords]);

  const onPrintShippingGuides = useCallback(async () => {
    confirm({
      title: t('order.shippingGuide.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('order.shippingGuide.content'),
      onOk: generateShippingGuides,
    });
  }, [generateShippingGuides]);

  return (
    <>
      <OrderConfirmationModal
        statusUpdate
        open={state.openUpdateStatusModal}
        loadingUpdate={
          loadingStatusUpdate ||
          ordersListLoading ||
          ordersBoardLoading ||
          state.updateStatusModalLoading
        }
        loadingTotals={false}
        updateComment={state.observations ?? ''}
        status={state.newStatus ?? StatusOrderEnum.RECEIVED}
        submit={handleStatusChange}
        cancelUpdate={closeStatusChangeModal}
        handleCommentChange={handleCommentChange}
        setReportMissing={setReportMissing}
      />
      <CancelOrderModal
        open={state.cancelModalVisible}
        loading={
          loadingStatusUpdate ||
          ordersListLoading ||
          ordersBoardLoading ||
          state.updateStatusModalLoading
        }
        cancelReason={state.cancelReason ?? ''}
        orderNumber={state.orderDragged?.orderNumber ?? ''}
        onOkCancel={handleStatusChange}
        reasonOptions={reasonOptions}
        onCancelCancel={closeStatusChangeModal}
        setCancelReason={setCancelReason}
      />
      <MultiOrderStatusChangeModal
        title="statusChange"
        orders={state.selectedOrders}
        open={state.openMultiStatusChange}
        cancelModalVisible={state.cancelModalVisible}
        cancelReason={state.cancelReason ?? ''}
        {...multiStatusChangeProps}
      />
      <MultiLegalizeModal
        orders={state.selectedOrders}
        openMultiLegalize={state.openMultiLegalize}
        {...multiLegalizeProps}
      />
      <OrderContent
        handleSearch={onChangeSearchValue}
        search={state.searchFilter}
        handleChangeDeliveryDateFilter={handleChangeDeliveryDateFilter}
        handleChangeCreationDateFilter={handleChangeCreationDateFilter}
        handleStatusFilterChange={handleStatusFilterChange}
        handleDepartmentFilterChange={handleDepartmentFilterChange}
        handleMpsFilterChange={handleMpsFilterChange}
        onUnselectAllClick={onUnselectAllClick}
        handle3PFilter={handle3PFilter}
        orderTypeFilter={state.filters.orderType}
        deliveryDateFilter={[
          state.filters?.startDeliveryDate ?? '',
          state.filters?.endDeliveryDate ?? '',
        ]}
        creationDateFilter={[
          state.filters?.startCreationDate ?? '',
          state.filters?.endCreationDate ?? '',
        ]}
        statusFilter={state.filters?.status}
        departmentFilter={state.filters?.department}
        mpsFilter={state.filters?.mpsId}
        departments={departments}
        mpsUsers={mpsUsers}
        onViewMultipleRemission={onViewMultipleRemission}
        handleOpenMultiStatusChange={handleOpenMultiStatusChange}
        openLegalizeModal={openLegalizeModal}
        multiLegalizeDisabled={multiLegalizeDisabled}
        disabledMultiOrderStatusAction={disabledMultiOrderStatusAction}
        disabledMultiRemission={!state.selectedOrders.length}
        onGenerateCollectionRecord={onGenerateCollectionRecord}
        onPrintShippingGuides={onPrintShippingGuides}
        Board={
          <OrderBoard
            columns={boardData}
            ordersBoardNext={ordersBoardNext}
            handleDragCard={handleDragCard}
            onClick={onViewDetail}
          />
        }
        List={
          <OrderTable
            tableRef={tableRef}
            data={ordersList}
            isLoading={ordersListLoading}
            onViewDetail={onViewDetail}
            onScrollToEnd={nextListOrdersPage}
            rowSelection={{
              selectedRowKeys: state.selectedRowKeys,
              onChange: onChangeRowSelection,
              getCheckboxProps: () => ({ disabled: false }),
            }}
          />
        }
      />
    </>
  );
};

export default OrderListContainer;
