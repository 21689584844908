import { ArrowRightOutlined } from '@ant-design/icons';
import { Modal, Tag } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { t } from 'i18next';
import { BoldItemParagraph } from 'modules/Purchase/components/PurchaseForm/PurchaseForm.sty';
import { Order } from 'services/order/interface';
import {
  MultiLegalizeItem,
  MultiLegalizeOrdersWrapper,
} from './MultiLegalizeModal.sty';

export interface MultiLegalizeModalProps {
  orders: Order[];
  closeLegalizeModal: () => void;
  onMultiLegalize: () => void;
  openMultiLegalize: boolean;
  isLoadingMultiLegalize: boolean;
}

const MultiLegalizeModal = ({
  orders,
  openMultiLegalize,
  closeLegalizeModal,
  onMultiLegalize,
  isLoadingMultiLegalize,
}: MultiLegalizeModalProps) => {
  return (
    <Modal
      destroyOnClose
      onCancel={closeLegalizeModal}
      onOk={onMultiLegalize}
      okText={t('multiLegalize.modal.buttons.ok')}
      cancelText={t('multiLegalize.modal.buttons.cancel')}
      title={t('multiLegalize.modal.heading')}
      open={openMultiLegalize}
      confirmLoading={isLoadingMultiLegalize}
    >
      <Tag color={LightTheme.color.gray_dark}>
        {t('multiLegalize.modal.tags.ordersNumber', {
          number: orders.length,
        })}
      </Tag>
      {/* !TODO: This Bold... should be shared component */}
      <BoldItemParagraph>
        {t('multiLegalize.modal.titles.main')}
      </BoldItemParagraph>
      <MultiLegalizeOrdersWrapper>
        {orders.map((order) => (
          <MultiLegalizeItem key={order.id}>
            <p>{order.orderNumber}</p>
            <Tag color={LightTheme.color.danger}>
              {t('multiLegalize.modal.tags.NOT_LEGALIZED')}
            </Tag>
            <ArrowRightOutlined />
            <Tag color={LightTheme.color.info}>
              {t('multiLegalize.modal.tags.LEGALIZED')}
            </Tag>
          </MultiLegalizeItem>
        ))}
      </MultiLegalizeOrdersWrapper>
    </Modal>
  );
};

export default MultiLegalizeModal;
