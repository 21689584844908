import { isEmpty } from 'ramda';
import { AttributeLevel } from 'services/product/interface';

export const compareAttributeLevels = (
  l1: AttributeLevel,
  l2: AttributeLevel,
): boolean => {
  if (isEmpty(l1 ?? {}) && isEmpty(l2 ?? {})) {
    return false;
  }
  if (!l1 || !l2) {
    return true;
  }
  const types1 = Object.values(l1);
  const types2 = Object.values(l2);

  if (types1.length !== types2.length) return true;
  for (let idx = 0; idx < types1.length; idx += 1) {
    if (types1[idx].id !== types2[idx].id) return true;
  }
  return false;
};
