/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import { City, Regional, Transport } from 'services/cities/interface';
import { PaginatedResponse } from 'services/global/interface';
import { cityToLabelValue } from '../helpers';
import {
  LabelValue,
  ShippingZoneCreateAction,
  ShippingZoneCreateState,
  SHIPPING_ZONE_CREATE_ACTION_ENUM,
} from './interface';

export const INITIAL_SHIPPING_ZONE_CREATE_STATE: ShippingZoneCreateState = {
  regionList: [],
  regionOptionList: [],
  transportList: [],
  transportOptionList: [],
  citiesList: [],
  citiesOptionList: [],
};

export function shippingZoneCreateReducer(
  state: ShippingZoneCreateState,
  action: ShippingZoneCreateAction,
): ShippingZoneCreateState {
  switch (action.type) {
    case SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_TRANSPORT_LIST:
      const paginatedTransports =
        action.payload as PaginatedResponse<Transport>;

      const transportsParsedToForm = paginatedTransports.data.map(
        (t): LabelValue<string> => ({
          label: t.name,
          value: t._id,
        }),
      );

      return {
        ...state,
        transportOptionList: transportsParsedToForm,
        transportList: paginatedTransports.data,
      };
    case SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_REGION_LIST:
      const regionsParsedToForm = action.payload?.regions?.data?.map(
        (region: Regional): LabelValue<string> => ({
          label: region.value,
          value: region.id,
        }),
      );

      return {
        ...state,
        regionOptionList: regionsParsedToForm ?? [],
        regionList: action.payload?.regions?.data ?? [],
      };
    case SHIPPING_ZONE_CREATE_ACTION_ENUM.SET_CITIES_LIST:
      const paginatedCities = action.payload as PaginatedResponse<City>;

      const citiesParsedToForm = paginatedCities.data.map(cityToLabelValue);

      return {
        ...state,
        citiesList: paginatedCities.data,
        citiesOptionList: citiesParsedToForm,
      };
    default:
      return state;
  }
}
