import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { OrderAction, OrderState } from '../reducer/interface';
import { INITIAL_ORDER_REDUCER, orderReducer } from '../reducer/order.reducer';

export interface OrderContextTypes {
  state: OrderState;
  dispatch: Dispatch<OrderAction>;
}

const OrderContext = createContext<OrderContextTypes>({} as OrderContextTypes);

export const OrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(orderReducer, INITIAL_ORDER_REDUCER);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
