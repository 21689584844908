/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-duplicates */
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Checkbox, Form, InputNumber, Select, Table } from 'antd';
import { CustomTag } from 'components/CustomTag';
import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import useNotifications from 'helpers/hooks/useNotifications';
import { numberToCurrency } from 'helpers/utils/currency';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { LogisticType } from 'services/MPS/interface';
import {
  WarrantyMathodColor,
  WarrantyStatusTagColor,
} from 'services/order/interface';
import { updateWarranty } from 'services/warranty';
import {
  ValidWarrantyStatusChange,
  WarrantyDetailDTO,
  WarrantyList,
  WarrantyStatus,
} from 'services/warranty/interface';
import { ButtonCol } from '../OrderForm/OrderForm.sty';
import { WarrantyCardHeader, WarrantyTitleWrapper } from './WarrantyList.sty';

export const WarrantyCard = ({
  warranty,
  refetch,
}: {
  warranty: WarrantyList;
  refetch?: (warrantyList: WarrantyList[]) => void;
}) => {
  const [detail, setDetail] = useState(warranty.detail);
  const [newStatus, setNewStatus] = useState(warranty.status);

  const { notifyError, notifySuccess } = useNotifications({
    translationFunction: t,
  });

  const onChangeProcessedQty = useCallback(
    ({ value, variant }: { value: number; variant: WarrantyDetailDTO }) => {
      const newDetail = cloneDeep(detail);
      const idx = newDetail.findIndex((v) => v.id === variant.id);

      if (idx !== -1) {
        newDetail[idx].processedQuantity = Number(value);
      }
      setDetail(newDetail);
    },
    [detail],
  );

  const onChangeProcessed = useCallback(
    ({ value, variant }: { value: boolean; variant: WarrantyDetailDTO }) => {
      const newDetail = cloneDeep(detail);
      const idx = newDetail.findIndex((v) => v.id === variant.id);

      if (idx !== -1) {
        newDetail[idx].processed = Boolean(value);
      }
      setDetail(newDetail);
    },
    [detail],
  );

  const columns = useMemo(
    () => [
      {
        title: t('productTableKeys.image'),
        render: (variant: WarrantyDetailDTO) => (
          <ThumbnailRender variant={variant} />
        ),
      },
      {
        title: t('productTableKeys.name'),
        dataIndex: 'name',
      },
      {
        title: t('productTableKeys.sku'),
        dataIndex: 'variantSku',
        key: 'variantSku',
      },
      {
        title: t('productTableKeys.warrantyQuantity'),
        dataIndex: 'warrantyQuantity',
        key: 'warrantyQuantity',
      },
      {
        title: t('productTableKeys.price'),
        render: (variant: WarrantyDetailDTO) =>
          numberToCurrency(
            (variant.price - variant.discount) *
              (variant.processedQuantity ?? variant.warrantyQuantity),
          ),
      },
      {
        title: t('productTableKeys.warrantyReason'),
        dataIndex: 'warrantyReason',
        key: 'warrantyReason',
      },
      {
        title: t('productTableKeys.processedQuantity'),
        render: (variant: WarrantyDetailDTO) => (
          <InputNumber
            min={0}
            max={variant.warrantyQuantity}
            value={variant.processedQuantity}
            disabled={warranty.status !== WarrantyStatus.PENDING}
            onChange={(value) =>
              onChangeProcessedQty({ value: value ?? 0, variant })
            }
          />
        ),
      },
      {
        title: t('productTableKeys.processed'),
        render: (variant: WarrantyDetailDTO) => (
          <Checkbox
            checked={variant.processed ?? false}
            disabled={
              warranty.detail.find((d) => d.id === variant.id)?.processed
            }
            onChange={(e) =>
              onChangeProcessed({ value: e.target.checked, variant })
            }
          />
        ),
      },
    ],
    [onChangeProcessed, onChangeProcessedQty, warranty.detail, warranty.status],
  );

  const { mutateAsync: UpdateWarranty, isLoading } = useMutation({
    mutationFn: updateWarranty,
    onSuccess: (data: WarrantyList[]) => {
      refetch?.(data);
      notifySuccess(
        t('warranty.message.updateSuccess', {
          warranty: warranty.warrantyNumber,
        }),
      );
    },
    onError: (_, data) => {
      notifyError(
        t('warranty.message.updateError', { warranty: data.warrantyNumber }),
      );
    },
  });

  const handleStatusChange = useCallback(async (status: string) => {
    setNewStatus(status as WarrantyStatus);
  }, []);

  const onUpdateWarranty = useCallback(async () => {
    const invalid = detail.some((d) => !d.processedQuantity);
    const invalidStatusChange =
      warranty.status === WarrantyStatus.PENDING &&
      newStatus === WarrantyStatus.PENDING;

    if (newStatus !== WarrantyStatus.REJECTED && invalid) {
      notifyError(t('warranty.message.processedQuantity'));
      return;
    }

    if (!invalid && invalidStatusChange) {
      notifyError(t('warranty.message.newStatus'));
      return;
    }
    await UpdateWarranty({
      newStatus,
      detail: detail.map((d) => ({
        ...d,
        mps: {
          ...(d.mps ?? {}),
          _id: d.mps?._id ?? '',
          logisticType: d.mps?.logisticType ?? LogisticType.TODAY,
        },
      })),
      orderNumber: warranty.orderNumber,
      warrantyNumber: warranty.warrantyNumber,
    });
  }, [UpdateWarranty, detail, newStatus, notifyError, warranty]);

  return (
    <Card
      title={
        <WarrantyTitleWrapper>
          <span>{warranty.warrantyNumber}</span>
          <Form.Item name="newStatus">
            <Select
              loading={isLoading}
              placeholder={
                <CustomTag color={WarrantyStatusTagColor[warranty.status]}>
                  {t(`warrantyStatus.${warranty.status}`)}
                </CustomTag>
              }
              disabled={!ValidWarrantyStatusChange[warranty.status].length}
              onSelect={handleStatusChange}
              value={newStatus}
            >
              {ValidWarrantyStatusChange[warranty.status].map((status) => (
                <Select.Option key={status} value={status}>
                  <CustomTag color={WarrantyStatusTagColor[status]}>
                    {t(`warrantyStatus.${status}`)}
                  </CustomTag>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </WarrantyTitleWrapper>
      }
      style={{ width: 950 }}
    >
      <WarrantyCardHeader>
        <span>
          <b>{t('warranty.card.requestDate')} </b>
          {format(new Date(warranty.requestDate), 'PPpp', {
            locale: es,
          })}
        </span>
        <CustomTag color={WarrantyMathodColor[warranty.method]}>
          {t(`warrantyMethod.${warranty.method}`)}
        </CustomTag>
      </WarrantyCardHeader>
      <p>
        <b>{t('warranty.card.total')} </b>
        {numberToCurrency(warranty.warrantyTotal)}
      </p>
      <Table
        dataSource={detail}
        columns={columns}
        pagination={false}
        scroll={{ y: 250 }}
      />
      <ButtonCol span={24}>
        <Button
          type="primary"
          htmlType="button"
          style={{ marginTop: '20px' }}
          onClick={onUpdateWarranty}
        >
          {t('warranty.update')}
        </Button>
      </ButtonCol>
    </Card>
  );
};
