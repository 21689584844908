/* eslint-disable spaced-comment */
import { CouponQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { Coupon } from './interface';

export const updateCoupons = async (coupon: Partial<Coupon>) => {
  const response = await getAPIMethod({
    name: 'updateCoupons',
    data: coupon,
    pathParams: { couponId: coupon?.id || '' },
  });
  return response.data;
};

export const createCoupons = async (coupon: Partial<Coupon>) => {
  const response = await getAPIMethod({
    name: 'createCoupons',
    data: coupon,
  });
  return response.data;
};

export const getCouponsList = async (queryParams?: CouponQueryParams) => {
  const response = await getAPIMethod({
    name: 'getCouponsList',
    params: {
      search: queryParams?.search,
      startDate: queryParams?.startDate,
      status: queryParams?.status,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
    },
  });
  return response.data;
};

export const deleteCoupons = async () => {
  //getCoupons
};

export const getCouponsById = async () => {
  //getCoupons
};

const getCouponByParam = async () => {
  //getByParams
};

export default {
  create: createCoupons,
  read: getCouponsList,
  update: updateCoupons,
  delete: deleteCoupons,
  readByParam: getCouponByParam,
};
