import { notification } from 'antd';
import { TransfersCsvProvider } from 'containers/TransfersCsv/context/transfers.context';
import TransfersCsvContainer from 'containers/TransfersCsv/TransfersCsvContainer';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const TransfersCsv = () => {
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.transfersCsv}`);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  return (
    <TransfersCsvProvider>
      <TransfersCsvContainer />;
    </TransfersCsvProvider>
  );
};
