import { Method } from 'axios';

export const endpoints = {
  getCitiesOptions: {
    method: 'GET' as Method,
    url: 'shipping-dom/cities',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  getShippingZoneList: {
    method: 'GET' as Method,
    url: 'shipping-dom/shipping-zones',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  createShippingZone: {
    method: 'POST' as Method,
    url: 'shipping-dom/shipping-zones',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  updateShippingZone: {
    method: 'PUT' as Method,
    url: 'shipping-dom/shipping-zones/{params.id}',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  getShippingZoneById: {
    method: 'GET' as Method,
    url: 'shipping-dom/shipping-zones/{params.id}',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
  getRegionalConfig: {
    method: 'GET' as Method,
    url: 'shipping-dom/shipping-zones/region',
    baseURL: process.env.REACT_APP_CITIES_URL,
  },
};
