import { t } from 'i18next';
import { DeliveryRangeOption } from 'services/shipping/interface';

export const range2DateOption = (option: DeliveryRangeOption) => {
  if (!option.end) {
    return {
      ...option.start,
    };
  }
  return {
    ...option.start,
    label: t('deliveryDates.marketPlace', {
      start: option.start.label,
      end: option.end.label,
    }),
  };
};
