import { PlusOutlined } from '@ant-design/icons';
import AutoCompleteUserItem from 'components/AutoCompleteUserItem/AutoCompleteUserItem';
import { QueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { Person, User } from 'services/user/interface';
import {
  UserAutocompleteOption,
  UserAutoCompleteQueryParams,
} from '../interface';
import { CreateUserOption, IconSpan } from '../UserAutocomplete.sty';

const useUserAutocomplete = (
  value: User,
  getUsersAsync: UseMutateAsyncFunction<
    any,
    unknown,
    QueryParams | undefined,
    unknown
  >,
  queryParams?: UserAutoCompleteQueryParams,
) => {
  const renderText = (userInfo: Person | undefined): string => {
    let text = '';
    if (!userInfo) return '';
    if (userInfo.name) text += `${userInfo.name} `;
    if (userInfo.lastName) text += `${userInfo.lastName}`;
    if (userInfo.docType) text += `, ${userInfo.docType}`;
    if (userInfo.documentNumber) text += `. ${userInfo.documentNumber}`;
    return text;
  };

  const searchKey = useState<string>(renderText(value?.info));
  const autocompleteResults = useState<User[]>([]);
  const userOptions = useState<UserAutocompleteOption[]>([]);
  const { t } = useTranslation();

  const setSearchKey = searchKey[1];
  const setAutocompleteResults = autocompleteResults[1];
  const setUserOptions = userOptions[1];

  useEffect(() => {
    setSearchKey(renderText(value?.info));
  }, [value?.info, setSearchKey]);

  const searchResult = useCallback(
    async (inputValue: string) => {
      const results = await getUsersAsync({
        search: inputValue,
        ...queryParams,
      });
      setAutocompleteResults(results.data);
      const newOptions = results.data.map((user: User) => ({
        value: user.id,
        label: <AutoCompleteUserItem user={user} />,
      }));
      newOptions.push({
        value: 'create-new-user',
        label: (
          <CreateUserOption>
            <IconSpan>
              <PlusOutlined />
            </IconSpan>
            &nbsp;
            {t('userAutocomplete.add')}
          </CreateUserOption>
        ),
      });
      setUserOptions(newOptions);
    },
    [getUsersAsync, setAutocompleteResults, t, setUserOptions, queryParams],
  );

  const debouncedSearch = useMemo(
    () => debounce(searchResult, 700),
    [searchResult],
  );

  const handleSearchUsers = useCallback(
    async (search: string) => {
      setSearchKey(search);
      await debouncedSearch(search);
    },
    [debouncedSearch, setSearchKey],
  );

  return {
    searchKey,
    autocompleteResults,
    userOptions,
    handleSearchUsers,
    t,
    renderText,
  };
};

export default useUserAutocomplete;
