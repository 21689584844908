import { ColumnsType } from 'antd/lib/table';
import { Variant } from 'services/product/interface';
import {
  ImageColumn,
  LocationsColumn,
  NameColumn,
  StatusColumn,
} from '../columns';
import QuantityColumn from '../columns/quantity';
import { IUnitVariantTableColumns } from '../interface';

export const useUnitTableColumns = ({
  handleReset,
  handleSearch,
  onChangeQuantity,
  onSeeLocations,
}: IUnitVariantTableColumns): ColumnsType<Variant> => {
  const imgCol = ImageColumn();
  const nameCol = NameColumn(handleReset, handleSearch);
  const statusCol = StatusColumn();
  const quantityCol = QuantityColumn(onChangeQuantity);
  const locationsCol = LocationsColumn(onSeeLocations);

  return [imgCol, nameCol, statusCol, quantityCol, locationsCol];
};

export default useUnitTableColumns;
